/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserById = /* GraphQL */ `
  query GetUserById($userId: ID!) {
    getUserById(userId: $userId) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      settings
      createdAt
      updatedAt
    }
  }
`;
export const getDepartement = /* GraphQL */ `
  query GetDepartement($id: ID!) {
    getDepartement(id: $id) {
      id
      name
      managerID
      manager {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      employees {
        items {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDepartements = /* GraphQL */ `
  query ListDepartements(
    $filter: ModelDepartementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        managerID
        manager {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      employeeDepartementId
      name
      age
      departement {
        id
        name
        managerID
        manager {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      projects {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      employees {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      description
      domain
      emailsupport
      lang
      message
      name
      url
      welcomeMessage
      image
      courses {
        items {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      consultations {
        items {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      description
      id
      image
      name
      published
      urlProduct
      courseSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      name
      description
      image
      published
      open
      courseWorkspacesId
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaceSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listWorkspaces = /* GraphQL */ `
  query ListWorkspaces(
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      name
      description
      published
      workspaceTopicsId
      workspace {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      userTopicsId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      topicSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message
      description
      published
      topicMessagesId
      topic {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        description
        published
        topicMessagesId
        topic {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        userMessagesId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const me = /* GraphQL */ `
  query Me($id: ID!) {
    me(id: $id) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      settings
      createdAt
      updatedAt
    }
  }
`;
export const getFAQCategory = /* GraphQL */ `
  query GetFAQCategory($id: ID!) {
    getFAQCategory(id: $id) {
      id
      name
      description
      published
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listFAQCategories = /* GraphQL */ `
  query ListFAQCategories(
    $filter: ModelFAQCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFAQTag = /* GraphQL */ `
  query GetFAQTag($id: ID!) {
    getFAQTag(id: $id) {
      id
      name
      description
      published
      questions {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listFAQTags = /* GraphQL */ `
  query ListFAQTags(
    $filter: ModelFAQTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        published
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      questionAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      answerAuthorId
      answerAuthor {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      rewording
      description
      questionVideoUrl
      thumb
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      category {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      file
      answer {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      answerUrl
      answerContent
      answerVideoId
      answerVideoProvider
      status
      questionsSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      fAQConsultationQuestionsId
      faqConsultation {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userQuestionsId
      questionCategoryId
      questionAnswerId
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      description
      published
      commentQuestionId
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      commentAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userCommentsId
      questionCommentsId
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        published
        commentQuestionId
        question {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        commentAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userCommentsId
        questionCommentsId
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      userAnswersId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      description
      urlVideo
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFAQConsultation = /* GraphQL */ `
  query GetFAQConsultation($id: ID!) {
    getFAQConsultation(id: $id) {
      id
      title
      description
      status
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      consultationSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listFAQConsultations = /* GraphQL */ `
  query ListFAQConsultations(
    $filter: ModelFAQConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      name
      description
      messages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      contributors {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserChatRoom = /* GraphQL */ `
  query GetUserChatRoom($id: ID!) {
    getUserChatRoom(id: $id) {
      id
      userId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      chatRoomId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatRoomsId
      chatRoomContributorsId
    }
  }
`;
export const listUserChatRooms = /* GraphQL */ `
  query ListUserChatRooms(
    $filter: ModelUserChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        chatRoomId
        chatRoom {
          id
          name
          description
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userChatRoomsId
        chatRoomContributorsId
      }
      nextToken
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      message
      description
      published
      chatRoomMessagesId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatMessagesId
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        description
        published
        chatRoomMessagesId
        chatRoom {
          id
          name
          description
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        userMessagesId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userChatMessagesId
      }
      nextToken
    }
  }
`;
export const getUserCourses = /* GraphQL */ `
  query GetUserCourses($id: ID!) {
    getUserCourses(id: $id) {
      id
      courseID
      userID
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const listUserCourses = /* GraphQL */ `
  query ListUserCourses(
    $filter: ModelUserCoursesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseID
        userID
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        editors
      }
      nextToken
    }
  }
`;
export const getQuestionTags = /* GraphQL */ `
  query GetQuestionTags($id: ID!) {
    getQuestionTags(id: $id) {
      id
      fAQTagID
      questionID
      fAQTag {
        id
        name
        description
        published
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const listQuestionTags = /* GraphQL */ `
  query ListQuestionTags(
    $filter: ModelQuestionTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fAQTagID
        questionID
        fAQTag {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        question {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        createdAt
        updatedAt
        owner
        editors
      }
      nextToken
    }
  }
`;
export const schoolByName = /* GraphQL */ `
  query SchoolByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schoolByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const coursesBySchool = /* GraphQL */ `
  query CoursesBySchool(
    $courseSchoolId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesBySchool(
      courseSchoolId: $courseSchoolId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const workspacesByCourse = /* GraphQL */ `
  query WorkspacesByCourse(
    $courseWorkspacesId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesByCourse(
      courseWorkspacesId: $courseWorkspacesId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const workspacesBySchool = /* GraphQL */ `
  query WorkspacesBySchool(
    $workspaceSchoolId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesBySchool(
      workspaceSchoolId: $workspaceSchoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicsByWorkspace = /* GraphQL */ `
  query TopicsByWorkspace(
    $workspaceTopicsId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicsByWorkspace(
      workspaceTopicsId: $workspaceTopicsId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicsByUser = /* GraphQL */ `
  query TopicsByUser(
    $userTopicsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicsByUser(
      userTopicsId: $userTopicsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicsBySchool = /* GraphQL */ `
  query TopicsBySchool(
    $topicSchoolId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicsBySchool(
      topicSchoolId: $topicSchoolId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByTopic = /* GraphQL */ `
  query MessagesByTopic(
    $topicMessagesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByTopic(
      topicMessagesId: $topicMessagesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        description
        published
        topicMessagesId
        topic {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        userMessagesId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByUser = /* GraphQL */ `
  query MessagesByUser(
    $userMessagesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByUser(
      userMessagesId: $userMessagesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        description
        published
        topicMessagesId
        topic {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        userMessagesId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersBySchool = /* GraphQL */ `
  query UsersBySchool(
    $userSchoolId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBySchool(
      userSchoolId: $userSchoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByAuthor = /* GraphQL */ `
  query QuestionsByAuthor(
    $questionAuthorId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByAuthor(
      questionAuthorId: $questionAuthorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      nextToken
    }
  }
`;
export const questionsByAnswerAuthor = /* GraphQL */ `
  query QuestionsByAnswerAuthor(
    $answerAuthorId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByAnswerAuthor(
      answerAuthorId: $answerAuthorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      nextToken
    }
  }
`;
export const questionsByDate = /* GraphQL */ `
  query QuestionsByDate(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByDate(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      nextToken
    }
  }
`;
export const questionsBySchool = /* GraphQL */ `
  query QuestionsBySchool(
    $questionsSchoolId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsBySchool(
      questionsSchoolId: $questionsSchoolId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      nextToken
    }
  }
`;
export const questionsBySchoolTitle = /* GraphQL */ `
  query QuestionsBySchoolTitle(
    $questionsSchoolId: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsBySchoolTitle(
      questionsSchoolId: $questionsSchoolId
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      nextToken
    }
  }
`;
export const questionsByConsultation = /* GraphQL */ `
  query QuestionsByConsultation(
    $fAQConsultationQuestionsId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByConsultation(
      fAQConsultationQuestionsId: $fAQConsultationQuestionsId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      nextToken
    }
  }
`;
export const commentsByQuestion = /* GraphQL */ `
  query CommentsByQuestion(
    $commentQuestionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByQuestion(
      commentQuestionId: $commentQuestionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        published
        commentQuestionId
        question {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        commentAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userCommentsId
        questionCommentsId
      }
      nextToken
    }
  }
`;
export const commentsByAuthor = /* GraphQL */ `
  query CommentsByAuthor(
    $commentAuthorId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByAuthor(
      commentAuthorId: $commentAuthorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        published
        commentQuestionId
        question {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        commentAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userCommentsId
        questionCommentsId
      }
      nextToken
    }
  }
`;
export const answersByAuthor = /* GraphQL */ `
  query AnswersByAuthor(
    $userAnswersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByAuthor(
      userAnswersId: $userAnswersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const consultationsBySchool = /* GraphQL */ `
  query ConsultationsBySchool(
    $consultationSchoolId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFAQConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationsBySchool(
      consultationSchoolId: $consultationSchoolId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatRoomsByUser = /* GraphQL */ `
  query ChatRoomsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        chatRoomId
        chatRoom {
          id
          name
          description
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userChatRoomsId
        chatRoomContributorsId
      }
      nextToken
    }
  }
`;
export const usersByChatRoom = /* GraphQL */ `
  query UsersByChatRoom(
    $chatRoomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByChatRoom(
      chatRoomId: $chatRoomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        chatRoomId
        chatRoom {
          id
          name
          description
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userChatRoomsId
        chatRoomContributorsId
      }
      nextToken
    }
  }
`;
export const messagesByChatRoom = /* GraphQL */ `
  query MessagesByChatRoom(
    $chatRoomMessagesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChatRoom(
      chatRoomMessagesId: $chatRoomMessagesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        description
        published
        chatRoomMessagesId
        chatRoom {
          id
          name
          description
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        userMessagesId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userChatMessagesId
      }
      nextToken
    }
  }
`;
export const chatMessagesByUser = /* GraphQL */ `
  query ChatMessagesByUser(
    $userMessagesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatMessagesByUser(
      userMessagesId: $userMessagesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        description
        published
        chatRoomMessagesId
        chatRoom {
          id
          name
          description
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        userMessagesId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userChatMessagesId
      }
      nextToken
    }
  }
`;
