import * as React from 'react'
import {useAuth} from '../../../context/AuthContext'
import {useNavigate, useLocation} from 'react-router'
import {PinConfirmRegistrationform} from '../../../components/Forms/PinConfirmRegistrationform'
import {AuthContainerSimple, AuthPageType} from './AuthContainer'
import {useGlobalContext} from '../../../context/GlobalContext'
import {useSearchParams} from 'react-router-dom'
import {RegisterPinFormValues} from '../../../types'
export const ConfirmSignup = (props: any) => {
  const {setWarning, setError: setGlobalError} = useGlobalContext()
  const [searchParams] = useSearchParams()
  const emailQueryParam = searchParams.get('email')
  const codeQueryParam = searchParams.get('code')

  const [username, setUsername] = React.useState<string>(emailQueryParam ?? '')

  const navigate = useNavigate()
  const location = useLocation()
  const {confirmSignUp, loading, logout} = useAuth()
  React.useEffect(() => {
    logout() //in case you create new account and allready login
    //@ts-ignore
    const {email} = location?.state ?? ''
    //if we want set email by query param
    const emailQueryParam = searchParams.get('email')
    setUsername(email ?? emailQueryParam)
  }, [location, logout, searchParams])

  const handleSubmit = (data: any) => {
    const code = `${data.pin1}${data.pin2}${data.pin3}${data.pin4}${data.pin5}${data.pin6}`
    if (username) {
      confirmSignUp({
        data: {username, pin: code, navigate},
        onError: handleErrors,
      })
    } else {
      setWarning('email not set')
    }
  }

  const handleErrors = (error: any) => {
    // console.log('handleErrors', error)
    setGlobalError(error.message ?? 'une erreur est survenue')
  }

  const pinCode: RegisterPinFormValues = {
    pin1: codeQueryParam?.[0] ?? '',
    pin2: codeQueryParam?.[1] ?? '',
    pin3: codeQueryParam?.[2] ?? '',
    pin4: codeQueryParam?.[3] ?? '',
    pin5: codeQueryParam?.[4] ?? '',
    pin6: codeQueryParam?.[5] ?? '',
  }

  return (
    <AuthContainerSimple
      label="Entrer le code confirmation"
      pageType={AuthPageType.pinConfirmation}
    >
      <PinConfirmRegistrationform
        onSubmit={handleSubmit}
        isLoading={loading}
        initialValues={pinCode}
      />
      {/* {loading ? 'connexion ...' : null} */}
      {/* {error ? 'error' : null} */}
    </AuthContainerSimple>
  )
}
export default ConfirmSignup
