import {Center, VStack, Wrap, WrapItem} from '@chakra-ui/react'
import {MdBlock, MdDelete} from 'react-icons/md'
import {FaCrown} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import {User} from '../../commons/types/API'
import {ROUTE_SCHOOL_USER} from '../../constantes'
import {UserStatus} from '../../types'
import {
  Box,
  Avatar,
  Button,
  Heading,
  IconButton,
  Text,
  Container,
  Stack,
  HStack,
  Icon,
} from './../ui'
import {Card} from './Card'
import {displayNameForAvatar, isAdmin} from '../../commons'

// on liste les props dans un type : CourseListProps
type CardUserProps = {
  user: User //prop user obligatoire de type User
  util?: string //prop util optionnel (?) de type syting
  domaine: string //props en attendant évolution back sur User
  suppUser?: (e: React.MouseEvent, value: string) => void
  disableUser?: (e: React.MouseEvent, value: string) => void
}

//dans le composant on detail les props 'util' et 'user' et le type 'CourseListProps'
export const CardUser = ({
  user,
  util,
  domaine,
  suppUser = () => {},
  disableUser = () => {},
}: CardUserProps) => {
  const navigate = useNavigate()

  let displayCommande = 'inline-block'

  if (util) {
    displayCommande = 'none'
  }
  const suppUserCommand = (e: React.MouseEvent, value: string) => {
    suppUser(e, value)
  }

  //Mike todo : hook call hook : useCoursesByUserId
  const courses = [
    {name: 'BootCamp ReactJS', by: 'MikeCodeur'},
    {name: 'GitHub Started', by: 'MikeCodeur'},
    {name: 'API REST', by: 'MikeCodeur'},
  ]
  return (
    <Card
      data={
        <Box w="full">
          <Container variant="headerUser"></Container>
          <Stack util="cardUser">
            <Center>
              <VStack>
                <Avatar
                  name={displayNameForAvatar(user)}
                  src={user?.image as string}
                  variant="user"
                  user={user}
                />
              </VStack>
            </Center>
            <Stack>
              <Center>
                <Heading variant="title">{user?.username}</Heading>
              </Center>
              <Center>
                <Heading variant="subTitle">{domaine}</Heading>
              </Center>
            </Stack>
            <Center>
              <Text display={displayCommande}>{courses.length} formations</Text>
            </Center>

            <Wrap
              display={displayCommande}
              justify="center"
              spacing={2}
              w="full"
            >
              <WrapItem>
                <Button
                  variant="ovale"
                  onClick={() => navigate(`${ROUTE_SCHOOL_USER}/${user.id}`)}
                >
                  Voir Profil
                </Button>
              </WrapItem>
              <WrapItem>
                <HStack spacing={2}>
                  <IconButton
                    icon={
                      <Icon
                        variant="generalIcons"
                        as={MdBlock}
                        color={
                          user.status !== UserStatus.CREATED
                            ? 'red.500'
                            : 'gray.500'
                        }
                      />
                    }
                    aria-label="block"
                    variant="outline"
                    onClick={e => disableUser(e, user.id)}
                  />
                  <IconButton
                    icon={<MdDelete fontSize="24px" />}
                    aria-label="block"
                    variant="outline"
                    onClick={e => suppUserCommand(e, user.id)}
                  />
                </HStack>
              </WrapItem>
            </Wrap>
          </Stack>
        </Box>
      }
    />
  )
}

export default CardUser
