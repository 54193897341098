import React, {useState} from 'react'
import {AspectRatio, Flex, useColorMode} from '@chakra-ui/react'
import {MdEdit, MdWorkspaces, MdGroups, MdDelete} from 'react-icons/md'
import {bgSidebarColor} from '../../theme/app-constantes'
import {Link as DomRouterLink} from 'react-router-dom'
import {Text, Container, HStack, Icon, IconButton, Link} from './../ui'
import {Card} from './Card'
import {Course} from '../../commons/types/API'
import {LINK_IMG_DEFAULT_COURSE} from '../../constantes'

type CardCourseProps = {
  course: Course //prop user obligatoire de type User
  suppCourse: (e: React.MouseEvent, value: string) => void
  editCourse: (e: React.MouseEvent, value: string) => void
  // util?: string //prop util optionnel (?) de type syting
  // domaine: string //props en attendant évolution back sur User
}

export const CardProgramme = ({
  course,
  editCourse,
  suppCourse,
}: CardCourseProps) => {
  const [image, setImage] = React.useState(course?.image)
  const {colorMode} = useColorMode()

  const handleOpenUpdate = (e: React.MouseEvent, value: string) => {
    editCourse(e, value)
  }
  const suppCourseCommand = (e: React.MouseEvent, value: string) => {
    suppCourse(e, value)
  }
  if (!image) {
    setImage(LINK_IMG_DEFAULT_COURSE)
  }
  const [hoverDisplayed, setHoverDisplayed] = useState(false)
  const handleMouseEnter = () => setHoverDisplayed(true)
  const handleMouseLeave = () => setHoverDisplayed(false)
  //console.log('course', course)
  return (
    <AspectRatio
      ratio={1.7}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card
        bgImage={image as string}
        bgSize="cover"
        data={
          <Container
            variant="hoverCardProg"
            maxW="full"
            minWidth="full"
            transition={'0.3s'}
            opacity={hoverDisplayed ? 1 : 0}
            transform={hoverDisplayed ? 'translateY(0%)' : 'translateY(100%)'}
          >
            <Flex justifyContent="space-between" w="full">
              <Text
                mt={1}
                isTruncated
                variant="cardProg"
                color={bgSidebarColor({colorMode})}
              >
                {course?.name}
              </Text>
              <HStack spacing={0}>
                <IconButton
                  size="sm"
                  aria-label="Modifier programme"
                  icon={
                    <Icon as={MdEdit} color={bgSidebarColor({colorMode})} />
                  }
                  onClick={e => handleOpenUpdate(e, course.id)}
                />
                {/* Liens vers Workspace(s) du programme */}
                <Link as={DomRouterLink} to={`/school/topic/`}>
                  <IconButton
                    size="sm"
                    aria-label="Call Segun"
                    icon={
                      <Icon
                        as={MdWorkspaces}
                        color={bgSidebarColor({colorMode})}
                      />
                    }
                  />
                </Link>
                {/* Liens vers list des users du programme ou popup list Users */}
                <Link as={DomRouterLink} to={`/school-admin/students`}>
                  <IconButton
                    size="sm"
                    aria-label="Call Segun"
                    icon={
                      <Icon as={MdGroups} color={bgSidebarColor({colorMode})} />
                    }
                  />
                </Link>
                <IconButton
                  size="sm"
                  aria-label="Supprimer programme"
                  icon={
                    <Icon as={MdDelete} color={bgSidebarColor({colorMode})} />
                  }
                  onClick={e => suppCourseCommand(e, course.id)}
                />
              </HStack>
            </Flex>
          </Container>
        }
      />
    </AspectRatio>
  )
}

export default CardProgramme
