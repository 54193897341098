import * as React from 'react'
import {Stack, HStack, Button, PinInput, PinInputField} from '../ui'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {RegisterPinFormValues} from '../../types'

type RegisterFormProps = {
  initialValues?: RegisterPinFormValues
  onSubmit: (formValue: RegisterPinFormValues) => void
  isLoading: boolean
}

export const PinConfirmRegistrationform = ({
  initialValues,
  onSubmit,
  isLoading,
}: RegisterFormProps) => {
  const SignInSchema = Yup.object().shape({
    pin1: Yup.string().required('Champ obligatoire'),
    pin2: Yup.string().required('Champ obligatoire'),
    pin3: Yup.string().required('Champ obligatoire'),
    pin4: Yup.string().required('Champ obligatoire'),
    pin5: Yup.string().required('Champ obligatoire'),
    pin6: Yup.string().required('Champ obligatoire'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      pin1: '',
      pin2: '',
      pin3: '',
      pin4: '',
      pin5: '',
      pin6: '',
    },
    onSubmit: (values: RegisterPinFormValues) => {
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  const distributInFields = (code: string) => {
    formik.setValues({
      pin1: code?.[0],
      pin2: code?.[1],
      pin3: code?.[2],
      pin4: code?.[3],
      pin5: code?.[4],
      pin6: code?.[5],
    })
  }

  const pinToString = (pin?: RegisterPinFormValues) => {
    if (pin) {
      return `${pin.pin1}${pin.pin2}${pin.pin3}${pin.pin4}${pin.pin5}${pin.pin6}`
    }
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing="6">
        <HStack spacing="10px">
          <PinInput
            size="lg"
            onComplete={code => distributInFields(code)}
            defaultValue={pinToString(initialValues)}
          >
            <PinInputField
              type="number"
              id="pin1"
              name="pin1"
              onChange={formik.handleChange}
              value={formik.values?.pin1}
            />
            <PinInputField
              type="number"
              id="pin2"
              name="pin2"
              onChange={formik.handleChange}
              value={formik.values?.pin2}
            />
            <PinInputField
              type="number"
              id="pin3"
              name="pin3"
              onChange={formik.handleChange}
              value={formik.values?.pin3}
            />
            <PinInputField
              type="number"
              id="pin4"
              name="pin4"
              onChange={formik.handleChange}
              value={formik.values?.pin4}
            />
            <PinInputField
              type="number"
              id="pin5"
              name="pin5"
              onChange={formik.handleChange}
              value={formik.values?.pin5}
            />
            <PinInputField
              type="number"
              id="pin6"
              name="pin6"
              onChange={formik.handleChange}
              value={formik.values?.pin6}
            />
          </PinInput>
        </HStack>
        <Button
          type="submit"
          color="currentColor"
          size="lg"
          fontSize="md"
          isLoading={isLoading}
        >
          Valider le code
        </Button>
      </Stack>
    </form>

    // <Box>
    //   <form onSubmit={formik.handleSubmit}>
    //     <Box mt={10}>
    //       <HStack spacing="10px">
    //         <PinInput size="lg" onComplete={code => distributInFields(code)}>
    //           <PinInputField
    //             type="number"
    //             id="pin1"
    //             name="pin1"
    //             onChange={formik.handleChange}
    //             value={formik.values?.pin1}
    //           />
    //           <PinInputField
    //             type="number"
    //             id="pin2"
    //             name="pin2"
    //             onChange={formik.handleChange}
    //             value={formik.values?.pin2}
    //           />
    //           <PinInputField
    //             type="number"
    //             id="pin3"
    //             name="pin3"
    //             onChange={formik.handleChange}
    //             value={formik.values?.pin3}
    //           />
    //           <PinInputField
    //             type="number"
    //             id="pin4"
    //             name="pin4"
    //             onChange={formik.handleChange}
    //             value={formik.values?.pin4}
    //           />
    //           <PinInputField
    //             type="number"
    //             id="pin5"
    //             name="pin5"
    //             onChange={formik.handleChange}
    //             value={formik.values?.pin5}
    //           />
    //           <PinInputField
    //             type="number"
    //             id="pin6"
    //             name="pin6"
    //             onChange={formik.handleChange}
    //             value={formik.values?.pin6}
    //           />
    //         </PinInput>
    //       </HStack>
    //       <Flex width={'100%'} justifyContent="center" mt={5}>
    //         <Button type="submit" variant="solid">
    //           Envoyer
    //         </Button>
    //       </Flex>
    //     </Box>
    //   </form>
    // </Box>
  )
}
export default PinConfirmRegistrationform
