import {IconButton, Icon, Stack, Link} from '../ui'
import {Card} from '../Card/Card'
import {User} from '../../commons/types/API'
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaGlobe,
  FaYoutube,
  FaGithub,
} from 'react-icons/fa'
import {generateUrlSocial} from '../../commons/helpers/screen'

type CardReseauxProps = {
  user?: User
}

export const CardReseaux = ({user}: CardReseauxProps) => {
  return (
    <Card
      title="réseaux sociaux"
      data={
        <Stack spacing={-2}>
          {user?.website && (
            <Link href={generateUrlSocial(user).website} isExternal>
              <IconButton
                icon={<Icon as={FaGlobe} variant="generalIcons" />}
                aria-label={'website'}
                variant="ghost"
              ></IconButton>
            </Link>
          )}
          {user?.facebookId && (
            <Link href={generateUrlSocial(user).facebook} isExternal>
              <IconButton
                icon={<Icon as={FaFacebookSquare} variant="generalIcons" />}
                aria-label={'facebook'}
                variant="ghost"
              ></IconButton>
            </Link>
          )}
          {user?.instagramId && (
            <Link href={generateUrlSocial(user).instagram} isExternal>
              <IconButton
                icon={<Icon as={FaInstagram} variant="generalIcons" />}
                aria-label={'instagram'}
                variant="ghost"
              ></IconButton>
            </Link>
          )}
          {user?.linkedInId && (
            <Link href={generateUrlSocial(user).linkedin} isExternal>
              <IconButton
                icon={<Icon as={FaLinkedinIn} variant="generalIcons" />}
                aria-label={'linkedin'}
                variant="ghost"
              ></IconButton>
            </Link>
          )}
          {user?.twitterId && (
            <Link href={generateUrlSocial(user).twitter} isExternal>
              <IconButton
                icon={<Icon as={FaTwitter} variant="generalIcons" />}
                aria-label={'twitter'}
                variant="ghost"
              ></IconButton>
            </Link>
          )}
          {user?.youtubeId && (
            <Link href={generateUrlSocial(user).youtube} isExternal>
              <IconButton
                icon={<Icon as={FaYoutube} variant="generalIcons" />}
                aria-label={'youtube'}
                variant="ghost"
              ></IconButton>
            </Link>
          )}
          {user?.githubId && (
            <Link href={generateUrlSocial(user).github} isExternal>
              <IconButton
                icon={<Icon as={FaGithub} variant="generalIcons" />}
                aria-label={'github'}
                variant="ghost"
              ></IconButton>
            </Link>
          )}
        </Stack>
      }
    ></Card>
  )
}

export default CardReseaux
