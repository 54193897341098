import {AvatarGroup, useColorMode} from '@chakra-ui/react'
import {format} from 'date-fns'
import {
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '../ui'
import {primaryColor, bgSidebarColor} from '../../theme/app-constantes'
import {fr} from 'date-fns/locale'
import React from 'react'
import {dateFormat_ddMMyyyyHHmm} from '../../commons/constantes'
import {Topic} from '../../commons/types/API'
import {Link as DomRouterLink} from 'react-router-dom'
import {PrincipalHeading} from '../Heading/PrincipalHeading'

import ReactMarkDownChakra from '../ui/ReactMarkDownChakra'
import {ROUTE_SCHOOL_USER} from '../../constantes'
import {extractUsersFromTopicExcludeUser} from '../../commons/helpers/topicHelper'
import {displayNameForAvatar, displayUserNameAndName} from '../../commons'

type CardPresentationTopicProps = {
  topic: Topic
}
export const CardPresentationTopic = ({topic}: CardPresentationTopicProps) => {
  const usersExclude = extractUsersFromTopicExcludeUser(topic, [topic.user])

  const {colorMode} = useColorMode()
  const subjectDate = (date: any) => {
    let created = '01/01/2022'
    if (date) {
      created = format(new Date(date), dateFormat_ddMMyyyyHHmm, {
        locale: fr,
      })
    }
    return created
  }

  const bcItem1 = {label: 'Forum', to: '/school/topic/'}
  const bcItem2 = {
    label: `${topic?.workspace?.name}`,
    to: '/school/topic/' + topic?.workspace?.id ?? '',
  }
  const bcItem3 = {
    label: `${topic?.name}`,
    // to: '/school/topic/' + topic?.workspace?.id + '/' + topic?.workspace?.id,
    to: `/school/topic/${topic?.workspace?.id}/${topic?.id}`,
  }

  return (
    <Box>
      <PrincipalHeading
        title={bcItem1.label}
        bcItem1={bcItem1}
        bcItem2={bcItem2}
        bcItem3={bcItem3}
      />
      <Container variant="cardSection" maxW={'full'} rounded="md">
        <Box bgColor={primaryColor({colorMode})} roundedTop="md">
          <Text fontWeight="medium" color={bgSidebarColor({colorMode})} p={4}>
            Forum &gt; {topic?.workspace?.name}
          </Text>
        </Box>
        <Flex justifyContent={'space-between'} p={4}>
          <Box w="full">
            <SimpleGrid columns={{base: 1, md: 2}} spacing={4} w="full" mb={8}>
              <Heading
                sx={{
                  '&::first-letter': {
                    textTransform: 'uppercase',
                  },
                }}
                variant="question"
              >
                {topic?.name}
              </Heading>
              <Flex justifyContent={'flex-end'} pr={4}>
                <Stack>
                  <Flex mt={2}>
                    <Text fontSize={'sm'} fontWeight={'light'} mr={1}>
                      Ouvert par
                    </Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {displayUserNameAndName(topic?.user)}
                    </Text>
                  </Flex>
                  <Text fontSize="sm" fontStyle="italic" textAlign={'right'}>
                    le {subjectDate(topic?.createdAt)}
                  </Text>
                </Stack>
                <Box ml={3}>
                  <Link
                    as={DomRouterLink}
                    to={`${ROUTE_SCHOOL_USER}/${topic?.user?.id}`}
                  >
                    <AvatarGroup size="md" max={2}>
                      <Avatar
                        size="md"
                        src={topic?.user?.image ?? ''}
                        name={displayNameForAvatar(topic.user)}
                        user={topic?.user}
                      />
                      {usersExclude?.map(user => (
                        <Avatar
                          key={user.id}
                          size="md"
                          src={user?.image ?? ''}
                          name={displayNameForAvatar(user)}
                          user={user}
                        />
                      ))}
                    </AvatarGroup>
                  </Link>
                </Box>
              </Flex>
            </SimpleGrid>

            <ReactMarkDownChakra>
              {topic?.description ?? ''}
            </ReactMarkDownChakra>
          </Box>
          {/* <Heading
            variant={'rotate'}
            display={'flex'}
            ml={'5'}
            justifyContent={'end'}
          >
            Sujet
          </Heading> */}
        </Flex>
      </Container>
    </Box>
  )
}

export default CardPresentationTopic
