import {format} from 'date-fns'
import {fr} from 'date-fns/locale'
import * as React from 'react'
import {MdDelete, MdEdit} from 'react-icons/md'
import {useCanUpdateMessage} from '../../context/AuthContext'
import {Card} from '../Card/Card'
import {Link as DomRouterLink} from 'react-router-dom'

import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Stack,
  Text,
  Link,
  HStack,
} from '../ui'
import {Message, UpdateMessageInput} from '../../commons/types/API'
import {dateFormat_ddMMyyyyHHmm} from '../../commons/constantes'
import {ROUTE_SCHOOL_USER} from '../../constantes'
import ReactMarkDownChakra from '../ui/ReactMarkDownChakra'
import {TopicMessageForm} from '../Forms/TopicMessageForm'
import {displayNameForAvatar, displayUserNameAndName} from '../../commons'

type TopicCommentCardProps = {
  item: Message | null
  topicId: any
  onDeleteMessage?: (e: React.MouseEvent, value: string) => void
  onUpdateMessage?: (formValue: UpdateMessageInput) => void
  isLoading?: boolean
}

export const TopicCommentCard = ({
  item,
  topicId,
  onDeleteMessage,
  onUpdateMessage,
  isLoading,
}: TopicCommentCardProps) => {
  const canUpdateMessage = useCanUpdateMessage(item as Message)

  const [username, setUsername] = React.useState<string>('username')
  const [image, setImage] = React.useState<string>('')
  const [createdAt, setCreatedAt] = React.useState<string>('')
  const [message, setMessage] = React.useState<string>('')

  const [textAreaOpen, setTextAreaOpen] = React.useState<boolean>(false)

  const handleSubmit = (formValue: UpdateMessageInput) => {
    onUpdateMessage?.(formValue)
    setTextAreaOpen(false)
  }

  // todo Mike : il serait pas bon de mettre les commentaires par ordre chronologique ?
  const reponseDate = (date: any) => {
    let created = '01/01/2022'
    if (date) {
      created = format(new Date(date), dateFormat_ddMMyyyyHHmm, {
        locale: fr,
      })
    }
    return created
  }

  React.useEffect(() => {
    setUsername(displayUserNameAndName(item?.user))
    setImage(item?.user?.image ?? '')
    const createdAtData = reponseDate(item?.createdAt)
    setCreatedAt(createdAtData)
    setMessage(item?.message ?? '')
  }, [item])

  const handleAnswer = () => {
    window.scrollTo(0, document.body.scrollHeight)
  }
  return (
    <Stack spacing={2}>
      <Card
        data={
          <Box p={4} pb={0}>
            <Flex mb={4}>
              <Link
                as={DomRouterLink}
                to={`${ROUTE_SCHOOL_USER}/${item?.user?.id}`}
              >
                <Avatar
                  mr={3}
                  size="md"
                  src={image}
                  name={displayNameForAvatar(item?.user)}
                  user={item?.user}
                />
              </Link>
              <Stack>
                <Flex mt={2}>
                  <Text fontSize={'sm'} fontWeight={'light'}>
                    Réponse de
                  </Text>
                  <Text fontSize={'sm'} fontWeight={'bold'} ml={1}>
                    {username}
                  </Text>
                </Flex>
                <Text fontSize="sm" fontStyle="italic">
                  le {createdAt}
                </Text>
              </Stack>
            </Flex>
            <ReactMarkDownChakra>{message}</ReactMarkDownChakra>
            <Flex w="full" justifyContent="space-between">
              <HStack spacing={-1}>
                <IconButton
                  hidden={!canUpdateMessage}
                  size="sm"
                  aria-label="Call Segun"
                  variant="ghost"
                  icon={
                    <Icon variant="generalIcons" as={MdEdit} color="gray.500" />
                  }
                  onClick={() => setTextAreaOpen(!textAreaOpen)}
                />
                <Icon
                  hidden={!canUpdateMessage}
                  cursor={'pointer'}
                  w={6}
                  h={6}
                  as={MdDelete}
                  onClick={(e: React.MouseEvent) =>
                    onDeleteMessage?.(e, item?.id ?? '')
                  }
                />
              </HStack>
              <Button variant="link" onClick={handleAnswer}>
                Répondre
              </Button>
            </Flex>
            {textAreaOpen && (
              <TopicMessageForm
                resetForm={false}
                initialValues={{
                  ...(item as Message),
                  message: item?.message,
                }}
                onSubmit={e => handleSubmit(e as UpdateMessageInput)}
                isLoading={isLoading}
              ></TopicMessageForm>
            )}
          </Box>
        }
      />
    </Stack>
  )
}

export default TopicCommentCard
