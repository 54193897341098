import * as React from 'react'
import {
  Stack,
  Heading,
  Flex,
  Box,
  Button,
  FormInput,
  HStack,
  FormTextArea,
  Avatar,
  IconButton,
  Text,
  Badge,
  Divider,
} from '../ui'
import {DownloadIcon} from '@chakra-ui/icons'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useDropzone} from 'react-dropzone'
import {
  CreateQuestionInput,
  Question,
  UpdateQuestionInput,
  User,
} from '../../commons/types/API'
import {EStatus} from '../../types/graphQL'
import {useAuthDetails} from '../../context/AuthContext'

import {
  Alert,
  AlertIcon,
  Progress,
  Switch,
  useColorMode,
} from '@chakra-ui/react'
import SelectConsultations from '../Selects/select-consultations'
import MultiSelectTags from '../Selects/multiselect-tags'
import SelectStatus from '../Selects/select-status'
import SelectCategories from '../Selects/select-categories'
import {buildTagsObject} from '../../commons/helpers/dataHelper'
import {
  ImageObjectType,
  IResizeImageOptions,
  resizeImage,
  uploadFile,
} from '../../commons/helpers/FilesHelper'
import {MAX_REZISE_IMAGE_THUMB} from '../../commons/constantes'
import {useGlobalContext} from '../../context/GlobalContext'
import {AlertHiddenFields} from './AlertHiddenFields'
import {Eroles} from '../../types'
import {hasRoles} from '../../commons'
import MDEditor from '@uiw/react-md-editor'
import MultiSelectUsers from '../Selects/multiselect-users'

type QuestionFormProps = {
  initialValues?: Question &
    UpdateQuestionInput &
    CreateQuestionInput & {tagsData?: any[]}

  onSubmit: (
    formValue: UpdateQuestionInput & CreateQuestionInput & {tagsData?: any[]},
  ) => void
  isLoading?: boolean
  onClose?: () => void
  isEdit?: boolean
  isModeConsultationStep: boolean
}

export const QuestionForm = ({
  initialValues,
  onSubmit,
  isLoading,
  onClose,
  isEdit = false,
  isModeConsultationStep = false,
}: QuestionFormProps) => {
  const {schoolGroupId, schoolId, uid, authUser} = useAuthDetails()
  const {setError} = useGlobalContext()
  const [mustSaveImage, setMustSaveImage] = React.useState(false)
  const [uploadingImg, setUploadingImg] = React.useState(false)
  const [status, setStatus] = React.useState<EStatus>()
  const [fAQConsultationQuestionsId, setFAQConsultationQuestionsId] =
    React.useState<string | null>()
  const [selectedAnswerAuthor, setSelectedAnswerAuthor] = React.useState<
    User | null | undefined
  >(initialValues?.answerAuthor ?? authUser?.me)

  const [selectedQuestionAuthor, setSelectedQuestionAuthor] = React.useState<
    User | null | undefined
  >(initialValues?.author)

  const canSetAnswerAuthor =
    hasRoles([Eroles.ADMIN], authUser) ||
    hasRoles([Eroles.SCHOOLOWNER], authUser)

  const SignInSchema = Yup.object().shape({
    title: Yup.string()
      .min(15, 'Minimum 15 caractères')
      .max(170, 'Maximum 170 caractères')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(20, 'Minimum 20 caractères')
      .max(4000, 'Maximum 4000 caractères')
      .required('Champ obligatoire'),
    status: !fAQConsultationQuestionsId
      ? Yup.string().notOneOf(
          [EStatus.PUBLISHED],
          'La status publié est interdit sans consultation',
        )
      : Yup.string().required('Un status est obligatoire'),
    answerUrl:
      status !== EStatus.PUBLISHED
        ? Yup.string().notRequired()
        : Yup.string()
            .url('Entrez une URL valide')
            .required('Entrez une URL de vidéo'),
    questionAuthorId: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required("Il manque l'auteur"),
    answerAuthorId: canSetAnswerAuthor
      ? Yup.string()
          .min(2, 'Minimum 2 caractères')
          .max(50, 'Maximum 50 caractères')
          .required('Un auteur est obligatoire')
      : Yup.string().min(1000, `Vous n'avez pas les droits pour répondre`),
    questionCategoryId: Yup.string()
      .min(1, 'Minimum 1 caractère')
      .max(2000, 'Maximum 2000 caractères')
      .required('Selectionnez une catégorie'),
    groupsCanAccess: Yup.array()
      .min(1, 'Il manque groupsCanAccess')
      .required('Champ obligatoire'),
    thumb:
      status !== EStatus.PUBLISHED
        ? Yup.string().notRequired()
        : Yup.string().required('Insérez une image'),
    questionVideoUrl: Yup.string().url('Entrez une URL de vidéo valide'),
    //thumbAnserAuthor: Yup.string().notRequired(),
  })

  const originalTags = initialValues?.tagsData
  const {colorMode} = useColorMode()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      title: initialValues?.title ?? '',
      rewording: initialValues?.rewording ?? initialValues?.title ?? '',
      description: initialValues?.description ?? '',
      status:
        isModeConsultationStep === true
          ? EStatus.PUBLISHED
          : initialValues?.status ?? EStatus.NEW,
      answerUrl: initialValues?.answerUrl ?? '',

      questionAuthorId:
        initialValues?.questionAuthorId ??
        initialValues?.author?.id ??
        uid ??
        '',
      answerAuthorId:
        initialValues?.answerAuthorId ??
        initialValues?.answerAuthor?.id ??
        uid ??
        '',
      questionsSchoolId: initialValues?.questionsSchoolId ?? schoolId,
      questionCategoryId: initialValues?.questionCategoryId ?? '',
      fAQConsultationQuestionsId:
        initialValues?.fAQConsultationQuestionsId ?? undefined,
      thumb: initialValues?.thumb ?? '',
      questionVideoUrl: initialValues?.questionVideoUrl ?? '',
      tagsData: initialValues?.tagsData ?? [],
      //access
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (
      values: UpdateQuestionInput & CreateQuestionInput & {tagsData?: any[]},
    ) => {
      setMustSaveImage(false)
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  React.useEffect(() => {
    setStatus(formik.values?.status as EStatus)
  }, [formik.values?.status])

  React.useEffect(() => {
    setFAQConsultationQuestionsId(formik.values?.fAQConsultationQuestionsId)
  }, [formik.values?.fAQConsultationQuestionsId])
  /**
   * We need to know wich tags must be deleted and added to question
   * prepare object with the following shape
   * {tags, toDel, toAdd}
   * tags : Selected Tags (not enought to know if need to be added to question or not)
   * toDel : tags todel from the question
   * toAdd : tags need to add to question
   * @param tags
   */
  const handleTagsChange = (tags: any) => {
    const data = buildTagsObject(tags, originalTags)
    formik.setFieldValue('tagsData', data)
  }

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles: any) => {
      const fileOriginal = acceptedFiles[0]
      setUploadingImg(true)
      const fileToresize: IResizeImageOptions = {
        maxSize: MAX_REZISE_IMAGE_THUMB,
        file: fileOriginal,
      }

      resizeImage(fileToresize)
        .then(newFiles => {
          formik.setFieldValue('thumb', URL.createObjectURL(newFiles.blob))
          uploadFileAndSubmit(newFiles)
        })
        .catch(err => {
          console.error(err)
          setUploadingImg(false)
          setError('erreur durant transformation de l image')
        })
    },
  })
  const uploadFileAndSubmit = async (file: any) => {
    const image = await uploadFile(
      file,
      ImageObjectType.question,
      initialValues?.id as string,
    )
    formik.setFieldValue('thumb', image)
    setUploadingImg(false)
    setMustSaveImage(true)
  }
  const handleChangeImage = () => {
    // TODO A FAIRE
  }
  // const handleDeleteImage = () => {
  // TODO A FAIRE
  //}

  const handleMarkdownChange = (value: string) => {
    formik.setFieldValue('description', value)
  }

  colorMode === 'light'
    ? document.documentElement.setAttribute('data-color-mode', 'light')
    : document.documentElement.setAttribute('data-color-mode', 'dark')
  const [isMarkdown, setIsMarkdown] = React.useState(false)

  const handleAnswerAuthorChange = (user: any) => {
    setSelectedAnswerAuthor(user.value)
    formik.setFieldValue('answerAuthorId', user.value.id)
  }

  const handleQuestionAuthorChange = (user: any) => {
    setSelectedQuestionAuthor(user.value)
    formik.setFieldValue('questionAuthorId', user.value.id)
  }

  React.useEffect(() => {
    setSelectedQuestionAuthor(initialValues?.author)
  }, [initialValues?.author])

  React.useEffect(() => {
    setSelectedAnswerAuthor(initialValues?.answerAuthor)
  }, [initialValues?.answerAuthor])

  React.useEffect(() => {
    setSelectedAnswerAuthor(authUser.me)
  }, [authUser.me])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing="2">
        <Flex justifyContent="center" align="center">
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {uploadingImg && <Progress size="xs" isIndeterminate />}

            <Avatar
              variant="prog"
              src={formik.values?.thumb as string}
              border={formik.values?.thumb ? '' : '2px solid #FC8181'}
              boxShadow={
                formik.values?.thumb ? '' : 'box-shadow: 0 0 0 1px #fc8181;'
              }
            />
            <Box>
              <IconButton
                onClick={() => handleChangeImage()}
                variant="solid"
                aria-label=""
                size="sm"
                mt={-12}
                ml={20}
                icon={<DownloadIcon />}
              >
                Change photo
              </IconButton>
            </Box>
          </div>
        </Flex>
        <Text w={'100%'} textAlign="center" color="red">
          {formik?.errors?.thumb}
        </Text>
        {mustSaveImage && (
          <Alert status="warning">
            <AlertIcon />
            veuillez sauvegarder
          </Alert>
        )}

        <Heading pt={10} variant="h3">
          Auteur de la question
        </Heading>
        <Divider />
        <HStack>
          <Avatar
            src={selectedQuestionAuthor?.image ?? ''}
            name={selectedQuestionAuthor?.username}
          />
          <Text>
            {selectedQuestionAuthor?.username ?? `Pas encore d'auteur`}
          </Text>
        </HStack>
        <MultiSelectUsers
          isMulti={false}
          my={3}
          label={'Auteur de la question'}
          placeholder={`Sélectionnez l'auteur de la question`}
          onChange={handleQuestionAuthorChange}
          //users={filteredUsers as User[]}

          errorMessage={formik.errors.questionAuthorId}
        />
        <Alert status="warning">
          <AlertIcon />
          Ne pas modifier l'auteur sans raison
        </Alert>

        <Heading pt={10} variant="h3">
          Détails
        </Heading>
        <Divider />
        <FormInput
          placeholder="Comment ..."
          type="title"
          label="Question"
          name="title"
          id="title"
          onChange={formik.handleChange}
          value={formik.values?.title?.toLocaleLowerCase()}
          errorMessage={formik?.errors?.title}
        />
        <FormInput
          placeholder="rewording ..."
          label="Reformulation de la question"
          name="rewording"
          id="rewording"
          onChange={formik.handleChange}
          value={formik.values?.rewording}
          errorMessage={formik?.errors?.rewording}
        />
        <Flex gap={3} alignItems="center">
          <Switch
            size="md"
            onChange={() => setIsMarkdown(!isMarkdown)}
            isChecked={!isMarkdown}
          />

          <Text variant={'label'}>
            Détail de la question {!isMarkdown ? <Badge>MarkDown</Badge> : ''}
          </Text>
        </Flex>
        {isMarkdown ? (
          <FormTextArea
            placeholder="description ..."
            name="description"
            id="description"
            onChange={formik.handleChange}
            value={formik.values?.description}
            errorMessage={formik?.errors?.description}
            height="250px"
          />
        ) : (
          <>
            <MDEditor
              value={formik.values.description ?? '**ok**'}
              //attention à bien passer le overflow en false sinon le overflow body passe en hidden...Oui ca n'a pas de sens...
              overflow={false}
              onChange={value => handleMarkdownChange(value ?? '')}
              preview="live"
              textareaProps={{
                placeholder: 'description ...',
                name: 'description',
                id: 'description',
                value: formik.values.description || '',
                onChange: formik.handleChange,
              }}
            />
            {formik.touched.description && formik.errors.description && (
              <Text fontSize="sm" color="#e53e3e">
                {formik.errors.description}
              </Text>
            )}
          </>
        )}
        {isModeConsultationStep === false && (
          <SelectConsultations
            label="Consultation"
            name="fAQConsultationQuestionsId"
            id="fAQConsultationQuestionsId"
            onChange={formik.handleChange}
            value={formik.values?.fAQConsultationQuestionsId as string}
            errorMessage={formik?.errors?.fAQConsultationQuestionsId}
            status={EStatus.PUBLISHED}
          />
        )}
        <MultiSelectTags
          placeholder="select tag ..."
          label="Tags"
          name="tags"
          id="tags"
          onChange={handleTagsChange}
          value={formik.values?.tagsData?.map(q => q?.fAQTagID)}
          //value={(formik.values?.tags?.items as []) ?? ([] as [])}
          //errorMessage={formik?.errors?.tags}
        />
        {isModeConsultationStep === false && (
          <SelectStatus
            label="Etats"
            name="status"
            id="status"
            onChange={formik.handleChange}
            value={formik.values?.status as string}
            errorMessage={formik?.errors?.status}
          />
        )}
        <FormInput
          placeholder="https://..."
          type="questionVideoUrl"
          label="URL de la question vidéo (optionel)"
          name="questionVideoUrl"
          id="questionVideoUrl"
          onChange={formik.handleChange}
          value={formik.values?.questionVideoUrl}
          errorMessage={formik?.errors?.questionVideoUrl}
        />
        <SelectCategories
          label="Categories"
          name="questionCategoryId"
          id="questionCategoryId"
          onChange={formik.handleChange}
          value={formik.values?.questionCategoryId as string}
          errorMessage={formik?.errors?.questionCategoryId}
        />

        <Heading pt={10} variant="h3">
          Réponse
        </Heading>
        <Divider />
        <FormInput
          placeholder="https://www."
          label="URL de la reponse VIDEO ..."
          name="answerUrl"
          id="answerUrl"
          onChange={formik.handleChange}
          value={formik.values?.answerUrl}
          errorMessage={formik?.errors?.answerUrl}
        />
        <Heading mt={4} variant="question">
          Auteur de la réponse
        </Heading>
        <HStack>
          <Avatar
            src={selectedAnswerAuthor?.image ?? ''}
            name={selectedAnswerAuthor?.username}
          />
          <Text>{selectedAnswerAuthor?.username ?? `Pas encore d'auteur`}</Text>
        </HStack>

        <MultiSelectUsers
          isMulti={false}
          my={3}
          label={'Auteur de la réponse'}
          placeholder={`Sélectionnez l'auteur de la réponse`}
          onChange={handleAnswerAuthorChange}
          //users={filteredUsers as User[]}

          onlyAdmin={true}
          errorMessage={formik.errors.answerAuthorId}
        />
        <Flex w="full" justifyContent={'flex-end'}>
          <HStack>
            {isModeConsultationStep ?? (
              <Button variant="outline" onClick={onClose}>
                Annuler
              </Button>
            )}

            <Button
              type="submit"
              fontSize="md"
              isLoading={isLoading}
              disabled={uploadingImg}
            >
              {isModeConsultationStep === true
                ? 'Répondre'
                : isEdit
                ? 'Modifier'
                : 'Valider'}
            </Button>
          </HStack>
        </Flex>
      </Stack>

      <AlertHiddenFields
        formik={formik}
        fields={[
          'questionAuthorId',
          'owner',
          'editors',
          'groupsCanAccess',
          'answerAuthorId',
        ]}
      />
    </form>
  )
}
export default QuestionForm
