import {SkeletonText} from '@chakra-ui/react'
import {Box, ContainerPage} from '../ui'

export const TopicListSkeleton = () => {
  return (
    <ContainerPage>
      <Box padding="6" boxShadow="lg">
        <SkeletonText mt="4" noOfLines={8} spacing="4" />
      </Box>
    </ContainerPage>
  )
}
export default TopicListSkeleton
