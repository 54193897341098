import {
  CreateMessageInput,
  Message,
  UpdateMessageInput,
} from '../../commons/types/API'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAuthDetails} from '../../context/AuthContext'
import {Box, Button, Flex, FormTextArea} from '../ui'
import {AlertHiddenFields} from './AlertHiddenFields'

type TopicMessageFormProps = {
  isEdit?: boolean
  initialValues?: Partial<UpdateMessageInput> & Partial<CreateMessageInput>
  topic?: Message
  onSubmit?: (formValue: UpdateMessageInput | CreateMessageInput) => void
  isLoading?: boolean
  resetForm?: boolean
  onClose?: () => void
}

export const TopicMessageForm = ({
  initialValues,
  topic,
  onSubmit,
  isLoading,
  onClose,
  isEdit,
  resetForm,
}: TopicMessageFormProps) => {
  const {schoolGroupId, uid} = useAuthDetails()

  const SignInSchema = Yup.object().shape({
    message: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(2000, 'Maximum 2000 caractères')
      .required('Champ obligatoire'),
    topicMessagesId: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Un identifiant de topic est obligatoire'),
    groupsCanAccess: Yup.array()
      .min(1, 'Il manque groupsCanAccess')
      .required('Champ obligatoire'),
    editors: Yup.array()
      .min(1, 'Il manque editor')
      .required('Champ obligatoire'),
    owner: Yup.string().test('owner', 'Il manque owner', value => !!value),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? undefined,
      description: initialValues?.description ?? '',
      topicMessagesId: initialValues?.topicMessagesId ?? '',
      message: initialValues?.message ?? '',
      userMessagesId: initialValues?.userMessagesId ?? uid ?? '',
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (values: UpdateMessageInput | CreateMessageInput) => {
      onSubmit?.(values)
      if (resetForm) {
        formik.resetForm()
      }
    },
    validationSchema: SignInSchema,
  })

  return (
    <>
      <Box px={4} pt={4} mb={-2}>
        <FormTextArea
          placeholder="Laissez votre message ici ..."
          label="Laisser un message"
          name="message"
          id="message"
          onChange={formik.handleChange}
          value={formik.values?.message as string}
          errorMessage={formik?.errors?.message as string}
          onKeyPress={e => {
            if (e.key === 'Enter' && e.ctrlKey) {
              formik.submitForm()
            }
          }}
        />
        <Flex justifyContent={'flex-end'}>
          <Button
            onClick={formik.submitForm}
            mt={2}
            variant={'solid'}
            isLoading={isLoading}
          >
            Valider
          </Button>
        </Flex>
      </Box>
      <AlertHiddenFields
        formik={formik}
        fields={[
          'userMessagesId',
          'topicMessagesId',
          'owner',
          'editors',
          'groupsCanAccess',
        ]}
      />
    </>
  )
}
