import {User} from '../commons/types/API'

// TODO REMPLACER TOUS LES TYPES DOIVENT VENIR DE GRAPHQL !!!
export enum Eroles {
  ADMIN = 'Admin',
  SCHOOLOWNER = 'schoolowner',
  STUDIENT = 'schoolstudient',
  USER = 'USER',
}
export interface IUser {
  username: string
  password?: string
  token?: string
  roles?: string[]
}

export interface AuthUser {
  me?: User //UpdateUserInput
  authUser: any
  roles: Eroles[]
  username?: string
}

export enum SignupType {
  STUDIENT = 'schoolstudient',
  OWNER = 'schoolowner',
}

export enum UserStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  DISABLED = 'DISABLED',
}

export enum UserActions {
  CREATE = 'CREATE',
  DISABLE = 'DISABLE',
}
