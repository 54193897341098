import {DownloadIcon} from '@chakra-ui/icons'
import {useFormik} from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import {UpdateUserInput} from '../../commons/types/API'
import {useDropzone} from 'react-dropzone'
import {ImageObjectType, uploadFile} from '../../commons/helpers/FilesHelper'
import {
  resizeImage,
  IResizeImageOptions,
} from '../../commons/helpers/FilesHelper'
import {
  Avatar,
  Box,
  Button,
  Container,
  DatePickerChakra,
  Flex,
  FormInput,
  FormTextArea,
  SimpleGrid,
  Heading,
  Stack,
  Text,
  IconButton,
  Grid,
  Badge,
} from '../ui'
import {MAX_REZISE_IMAGE} from '../../commons/constantes'
import {Settings, useGlobalContext} from '../../context/GlobalContext'
import {Card} from '../Card/Card'
import {
  Alert,
  AlertIcon,
  GridItem,
  Switch,
  useColorMode,
  Progress,
} from '@chakra-ui/react'

import MDEditor from '@uiw/react-md-editor'
import {useAuth, useAuthDetails} from '../../context/AuthContext'
export const gridRight = {
  xs: 12,
  sm: 12,
  md: 8,
  lg: 8,
  xl: 8,
  xxl: 8,
}
export const gridLeft = {
  xs: 12,
  sm: 12,
  md: 4,
  lg: 4,
  xl: 4,
  xxl: 4,
}

type RegisterFormProps = {
  initialValues?: UpdateUserInput
  onSubmit: (formValue: UpdateUserInput) => void
  isLoading?: boolean
  onClose?: () => void
}

export const UserAccountForm = ({
  initialValues,
  onSubmit,
  isLoading,
  onClose,
}: RegisterFormProps) => {
  const {setError, setMute} = useGlobalContext()

  const {authUser} = useAuthDetails()

  // const [tempImage, setTempImage] = React.useState('')

  const [uploadingImg, setUploadingImg] = React.useState(false)
  const [mustSaveImage, setMustSaveImage] = React.useState(false)

  const SignInSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    lastname: Yup.string().max(50, 'Maximum 50 caractères'),
    notes: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(2000, 'Maximum 2000 caractères')
      .required('Champ obligatoire'),
    facebookId: Yup.string().matches(
      /^((?!http).)*$/,
      'Nom du compte sans le HTTP ',
    ),
    twitterId: Yup.string().matches(
      /^((?!http).)*$/,
      'Nom du compte sans le HTTP ',
    ),
    instagramId: Yup.string().matches(
      /^((?!http).)*$/,
      'Nom du compte sans le HTTP ',
    ),
    linkedInId: Yup.string().matches(
      /^((?!http).)*$/,
      'Nom du compte sans le HTTP ',
    ),
    youtubeId: Yup.string().matches(
      /^((?!http).)*$/,
      'Nom du compte sans le HTTP ',
    ),
    githubId: Yup.string().matches(
      /^((?!http).)*$/,
      'Nom du compte sans le HTTP ',
    ),
    website: Yup.string().matches(
      /^((?!http).)*$/,
      'Nom du compte sans le HTTP ',
    ),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cognitoId: initialValues?.cognitoId ?? '',
      id: initialValues?.id ?? '',
      username: initialValues?.username ?? '',
      lastname: initialValues?.lastname ?? '',
      firstname: initialValues?.firstname ?? '',
      address: initialValues?.address ?? '',
      email: initialValues?.email ?? '',
      contact: initialValues?.contact ?? '',
      birthday: initialValues?.birthday ?? '2020',
      website: initialValues?.website ?? '',
      company: initialValues?.company ?? '',
      notes: initialValues?.notes ?? '',
      label: initialValues?.label ?? 0,
      facebookId: initialValues?.facebookId ?? '',
      githubId: initialValues?.githubId ?? '',
      twitterId: initialValues?.twitterId ?? '',
      instagramId: initialValues?.instagramId ?? '',
      linkedInId: initialValues?.linkedInId ?? '',
      youtubeId: initialValues?.youtubeId ?? '',
      googleId: initialValues?.googleId ?? '',
      image: initialValues?.image ?? '',
      roles: authUser.roles ?? [],
      settings: initialValues?.settings ?? null,
    },
    onSubmit: (values: UpdateUserInput) => {
      if (values.settings !== initialValues?.settings) {
        setMute()
      }
      setMustSaveImage(false)
      //@ts-ignore
      values.id = values.cognitoId //chelou, ne devrait pas etre obligatoire chez appsync
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  const handleChangeImage = () => {}

  const handleDeleteImage = () => {
    // TODO A FAIRE
  }

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles: any) => {
      setUploadingImg(true)
      const fileOriginal = acceptedFiles[0]
      const fileToresize: IResizeImageOptions = {
        maxSize: MAX_REZISE_IMAGE,
        file: fileOriginal,
      }

      resizeImage(fileToresize)
        .then(async newFiles => {
          formik.setFieldValue('image', URL.createObjectURL(newFiles.blob))
          await uploadFileAndSubmit(newFiles)
        })
        .catch(err => {
          setUploadingImg(false)
          console.error(err)
          setError(`Erreur durant le transfert de l'image`)
          formik.setFieldValue('image', initialValues?.image)
          formik.setFieldError('image', `Erreur durant le transfert de l'image`)
        })
    },
  })
  const uploadFileAndSubmit = async (file: any) => {
    const image = await uploadFile(
      file,
      ImageObjectType.user,
      initialValues?.cognitoId as string,
    )
    formik.setFieldValue('image', image)
    setUploadingImg(false)
    setMustSaveImage(true)
  }

  const {colorMode} = useColorMode()

  const handleMarkdownChange = (value: string) => {
    formik.setFieldValue('notes', value)
  }

  const [settings, setSettings] = React.useState<Settings>(
    JSON.parse(initialValues?.settings ?? '{"isMute":false}'),
  )
  const handleIsMute = () => {
    const values = {...settings, isMute: !settings.isMute}
    setSettings(values)
    formik.setFieldValue('settings', JSON.stringify(values))
  }

  colorMode === 'light'
    ? document.documentElement.setAttribute('data-color-mode', 'light')
    : document.documentElement.setAttribute('data-color-mode', 'dark')
  const [isMarkdown, setIsMarkdown] = React.useState(false)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid templateColumns={{md: 'repeat(2,1fr)'}} gap={4}>
        <GridItem colSpan={1}>
          <Container variant="generalBg" width="full">
            <SimpleGrid columns={2} gap={4}>
              <Flex justifyContent={'flex-start'} align="center">
                <Heading>Mon Compte</Heading>
              </Flex>
              <Flex justifyContent="center" align="center">
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  {uploadingImg && <Progress size="xs" isIndeterminate />}
                  <Avatar variant="user" src={formik.values?.image as string} />
                  <Box>
                    <IconButton
                      onClick={() => handleChangeImage()}
                      variant="solid"
                      aria-label=""
                      size="sm"
                      mt={-12}
                      ml={20}
                      icon={<DownloadIcon />}
                    >
                      Change photo
                    </IconButton>
                    <Text w={'100%'} textAlign="center" color="red">
                      {formik?.errors?.image}
                    </Text>
                  </Box>
                </div>
              </Flex>
            </SimpleGrid>
            {mustSaveImage && (
              <Alert status="warning">
                <AlertIcon />
                Veuillez sauvegarder
              </Alert>
            )}
            <FormInput
              isDisabled={false}
              placeholder="Pseudo"
              label="Pseudo"
              name="username"
              id="username"
              onChange={formik.handleChange}
              value={formik.values?.username}
              errorMessage={formik?.errors?.username as string}
            />
            <FormInput
              placeholder="Prénom"
              label="Prénom"
              name="firstname"
              id="lastname"
              onChange={formik.handleChange}
              value={formik.values?.firstname as string}
              errorMessage={formik?.errors?.firstname as string}
            />
            <FormInput
              placeholder="Nom"
              label="Nom"
              name="lastname"
              id="lastname"
              onChange={formik.handleChange}
              value={formik.values?.lastname as string}
              errorMessage={formik?.errors?.lastname as string}
            />

            <DatePickerChakra
              type="date"
              label="Date de naissance"
              name="birthday"
              id="birthday"
              value={formik?.values?.birthday as string}
              date={new Date(formik?.values?.birthday as string)}
              onDateChange={val => {
                formik.setFieldValue('birthday', (val as Date).toISOString())
              }}
              errorMessage={formik?.errors?.birthday as string}
            />
            <FormInput
              placeholder="email"
              type="email"
              label="Email de contact"
              name="email"
              id="email"
              onChange={formik.handleChange}
              value={formik.values?.email as string}
              errorMessage={formik?.errors?.email as string}
            />
            <Flex gap={3} alignItems="center" mb={2}>
              <Switch
                size="md"
                onChange={() => handleIsMute()}
                isChecked={!settings.isMute}
              />

              <Text variant={'label'}>Son des notifications</Text>
            </Flex>

            <Flex gap={3} alignItems="center" mb={2}>
              <Switch
                size="md"
                onChange={() => setIsMarkdown(!isMarkdown)}
                isChecked={!isMarkdown}
              />

              <Text variant={'label'}>
                Description {''}
                {!isMarkdown ? <Badge>MarkDown</Badge> : ''}
              </Text>
            </Flex>
            {isMarkdown ? (
              <FormTextArea
                placeholder="Laissez une petite description de vous"
                name="notes"
                id="notes"
                onChange={formik.handleChange}
                value={formik.values?.notes as string}
                errorMessage={formik?.errors?.notes as string}
              />
            ) : (
              <>
                <MDEditor
                  value={formik.values?.notes as string}
                  //attention à bien passer le overflow en false sinon le overflow body passe en hidden...Oui ca n'a pas de sens...
                  overflow={false}
                  onChange={value => handleMarkdownChange(value ?? '')}
                  preview="live"
                  textareaProps={{
                    placeholder: 'description ...',
                    name: 'notes',
                    id: 'notes',
                    value: formik.values?.notes || '',
                    onChange: formik.handleChange,
                  }}
                />
                {formik.touched.notes && formik?.errors?.notes && (
                  <Text fontSize="sm" color="#e53e3e">
                    {formik?.errors?.notes as string}
                  </Text>
                )}
              </>
            )}
          </Container>
        </GridItem>
        <GridItem colSpan={1}>
          {/* <Box> */}
          <Card
            title="Réseaux sociaux"
            data={
              <Stack spacing={2}>
                <FormInput
                  placeholder="website"
                  label="Site internet"
                  name="website"
                  id="website"
                  onChange={formik.handleChange}
                  value={formik.values?.website as string}
                  errorMessage={formik?.errors?.website as string}
                />
                <FormInput
                  placeholder="UserName GitHub"
                  label="GitHub"
                  name="githubId"
                  id="githubId"
                  onChange={formik.handleChange}
                  value={formik.values?.githubId as string}
                  errorMessage={formik?.errors?.githubId as string}
                />
                <FormInput
                  placeholder="facebookId"
                  label="Facebook"
                  name="facebookId"
                  id="facebookId"
                  onChange={formik.handleChange}
                  value={formik.values?.facebookId as string}
                  errorMessage={formik?.errors?.facebookId as string}
                />
                <FormInput
                  placeholder="twitterId"
                  label="Twitter"
                  name="twitterId"
                  id="twitterId"
                  onChange={formik.handleChange}
                  value={formik.values?.twitterId as string}
                  errorMessage={formik?.errors?.twitterId as string}
                />
                <FormInput
                  placeholder="instagramId"
                  label="Instagram"
                  name="instagramId"
                  id="instagramId"
                  onChange={formik.handleChange}
                  value={formik.values?.instagramId as string}
                  errorMessage={formik?.errors?.instagramId as string}
                />
                <FormInput
                  placeholder="linkedInId"
                  label="LinkedIn"
                  name="linkedInId"
                  id="linkedInId"
                  onChange={formik.handleChange}
                  value={formik.values?.linkedInId as string}
                  errorMessage={formik?.errors?.linkedInId as string}
                />
                <FormInput
                  placeholder="youtubeId"
                  label="Youtube"
                  name="youtubeId"
                  id="youtubeId"
                  onChange={formik.handleChange}
                  value={formik.values?.youtubeId as string}
                  errorMessage={formik?.errors?.youtubeId as string}
                />
              </Stack>
            }
          />
          <Flex justifyContent={'flex-end'} mt={6}>
            {/* <Button variant="outline" onClick={onClose}>
              Annuler
            </Button> */}
            <Button
              type="submit"
              ml={4}
              isLoading={isLoading}
              disabled={uploadingImg}
            >
              Mettre à jour
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </form>
  )
}
export default UserAccountForm
