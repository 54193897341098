import {EStatus} from './graphQL'

export type ColorStatus = {
  key: EStatus
  value: string
  label: string
}

export enum CreateConsultationSteps {
  create = 'create',
  select_questions = 'select_questions',
  answer = 'answer',
  check = 'check',
  publish = 'publish',
}

export enum View {
  grid = 'grid',
  list = 'list',
}
export enum SearchType {
  STATUS_AND_FILTER,
  FILTER_ONLY,
}
