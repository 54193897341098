import {Modal, ModalContent, ModalOverlay} from '@chakra-ui/react'
import React from 'react'
import {Box, Grid, Heading} from '../ui'

type ModalFormProps = {
  children: React.ReactChild
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  title: string
  size?: string
}
export const ModalForm: React.FC<ModalFormProps> = ({
  children,
  isOpen,
  onOpen,
  onClose,
  title = 'modal',
  size = 'md',
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <Grid autoFlow="column" gap={4} p={4} w="full">
          <Box minW={{base: '240px', sm: '368px'}}>{children}</Box>
          <Heading variant="rotate">{title}</Heading>
        </Grid>
      </ModalContent>
    </Modal>
  )
}
