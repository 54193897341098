/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Message = {
  __typename: "Message",
  id: string,
  message: string,
  description?: string | null,
  published?: boolean | null,
  topicMessagesId: string,
  topic?: Topic | null,
  userMessagesId: string,
  user: User,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type Topic = {
  __typename: "Topic",
  id: string,
  name: string,
  description?: string | null,
  published?: boolean | null,
  workspaceTopicsId: string,
  workspace?: Workspace | null,
  messages?: ModelMessageConnection | null,
  userTopicsId: string,
  user: User,
  topicSchoolId: string,
  school?: School | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type Workspace = {
  __typename: "Workspace",
  id: string,
  name: string,
  description?: string | null,
  image?: string | null,
  published?: boolean | null,
  open?: boolean | null,
  courseWorkspacesId?: string | null,
  course?: Course | null,
  topics?: ModelTopicConnection | null,
  workspaceSchoolId: string,
  school?: School | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type Course = {
  __typename: "Course",
  description: string,
  id: string,
  image?: string | null,
  name: string,
  published?: boolean | null,
  urlProduct?: string | null,
  courseSchoolId: string,
  school?: School | null,
  workspaces?: ModelWorkspaceConnection | null,
  users?: ModelUserCoursesConnection | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type School = {
  __typename: "School",
  id: string,
  description: string,
  domain?: string | null,
  emailsupport?: string | null,
  lang?: string | null,
  message?: string | null,
  name: string,
  url?: string | null,
  welcomeMessage?: string | null,
  image?: string | null,
  courses?: ModelCourseConnection | null,
  workspaces?: ModelWorkspaceConnection | null,
  topics?: ModelTopicConnection | null,
  consultations?: ModelFAQConsultationConnection | null,
  questions?: ModelQuestionConnection | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection",
  items:  Array<Course | null >,
  nextToken?: string | null,
};

export type ModelWorkspaceConnection = {
  __typename: "ModelWorkspaceConnection",
  items:  Array<Workspace | null >,
  nextToken?: string | null,
};

export type ModelTopicConnection = {
  __typename: "ModelTopicConnection",
  items:  Array<Topic | null >,
  nextToken?: string | null,
};

export type ModelFAQConsultationConnection = {
  __typename: "ModelFAQConsultationConnection",
  items:  Array<FAQConsultation | null >,
  nextToken?: string | null,
};

export type FAQConsultation = {
  __typename: "FAQConsultation",
  id: string,
  title: string,
  description?: string | null,
  status?: string | null,
  questions?: ModelQuestionConnection | null,
  consultationSchoolId: string,
  school?: School | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  questionAuthorId: string,
  author: User,
  answerAuthorId?: string | null,
  answerAuthor?: User | null,
  title: string,
  rewording?: string | null,
  description?: string | null,
  questionVideoUrl?: string | null,
  thumb?: string | null,
  comments?: ModelCommentConnection | null,
  category?: FAQCategory | null,
  tags?: ModelQuestionTagsConnection | null,
  file?: string | null,
  answer?: Answer | null,
  answerUrl?: string | null,
  answerContent?: string | null,
  answerVideoId?: string | null,
  answerVideoProvider?: string | null,
  status: string,
  questionsSchoolId: string,
  school?: School | null,
  fAQConsultationQuestionsId?: string | null,
  faqConsultation?: FAQConsultation | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
  userQuestionsId?: string | null,
  questionCategoryId?: string | null,
  questionAnswerId?: string | null,
};

export type User = {
  __typename: "User",
  cognitoId: string,
  id: string,
  userSchoolId?: string | null,
  topics?: ModelTopicConnection | null,
  messages?: ModelMessageConnection | null,
  questions?: ModelQuestionConnection | null,
  answers?: ModelAnswerConnection | null,
  comments?: ModelCommentConnection | null,
  courses?: ModelUserCoursesConnection | null,
  chatRooms?: ModelUserChatRoomConnection | null,
  chatMessages?: ModelChatMessageConnection | null,
  address?: string | null,
  firstname?: string | null,
  lastname?: string | null,
  username: string,
  birthday?: string | null,
  company?: string | null,
  contact?: string | null,
  email?: string | null,
  image?: string | null,
  website?: string | null,
  instagramId?: string | null,
  facebookId?: string | null,
  linkedInId?: string | null,
  twitterId?: string | null,
  youtubeId?: string | null,
  googleId?: string | null,
  githubId?: string | null,
  isFrequent?: boolean | null,
  isStarred?: boolean | null,
  label?: number | null,
  notes?: string | null,
  registered?: boolean | null,
  website2?: string | null,
  status: string,
  owner: string,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  roles?: Array< string | null > | null,
  settings?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
};

export type ModelAnswerConnection = {
  __typename: "ModelAnswerConnection",
  items:  Array<Answer | null >,
  nextToken?: string | null,
};

export type Answer = {
  __typename: "Answer",
  id: string,
  userAnswersId: string,
  author: User,
  title?: string | null,
  description?: string | null,
  urlVideo?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  description?: string | null,
  published?: boolean | null,
  commentQuestionId: string,
  question?: Question | null,
  commentAuthorId: string,
  author: User,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
  userCommentsId?: string | null,
  questionCommentsId?: string | null,
};

export type ModelUserCoursesConnection = {
  __typename: "ModelUserCoursesConnection",
  items:  Array<UserCourses | null >,
  nextToken?: string | null,
};

export type UserCourses = {
  __typename: "UserCourses",
  id: string,
  courseID: string,
  userID: string,
  course: Course,
  user: User,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  editors?: string | null,
};

export type ModelUserChatRoomConnection = {
  __typename: "ModelUserChatRoomConnection",
  items:  Array<UserChatRoom | null >,
  nextToken?: string | null,
};

export type UserChatRoom = {
  __typename: "UserChatRoom",
  id: string,
  userId?: string | null,
  user?: User | null,
  chatRoomId?: string | null,
  chatRoom?: ChatRoom | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt?: string | null,
  userChatRoomsId?: string | null,
  chatRoomContributorsId?: string | null,
};

export type ChatRoom = {
  __typename: "ChatRoom",
  id: string,
  name: string,
  description?: string | null,
  messages?: ModelChatMessageConnection | null,
  contributors?: ModelUserChatRoomConnection | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt?: string | null,
};

export type ModelChatMessageConnection = {
  __typename: "ModelChatMessageConnection",
  items:  Array<ChatMessage | null >,
  nextToken?: string | null,
};

export type ChatMessage = {
  __typename: "ChatMessage",
  id: string,
  message: string,
  description?: string | null,
  published?: boolean | null,
  chatRoomMessagesId: string,
  chatRoom?: ChatRoom | null,
  userMessagesId: string,
  user: User,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt?: string | null,
  userChatMessagesId?: string | null,
};

export type FAQCategory = {
  __typename: "FAQCategory",
  id: string,
  name: string,
  description?: string | null,
  published?: boolean | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type ModelQuestionTagsConnection = {
  __typename: "ModelQuestionTagsConnection",
  items:  Array<QuestionTags | null >,
  nextToken?: string | null,
};

export type QuestionTags = {
  __typename: "QuestionTags",
  id: string,
  fAQTagID: string,
  questionID: string,
  fAQTag: FAQTag,
  question: Question,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  editors?: string | null,
};

export type FAQTag = {
  __typename: "FAQTag",
  id: string,
  name: string,
  description?: string | null,
  published?: boolean | null,
  questions?: ModelQuestionTagsConnection | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type ModelFAQConsultationFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  consultationSchoolId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFAQConsultationFilterInput | null > | null,
  or?: Array< ModelFAQConsultationFilterInput | null > | null,
  not?: ModelFAQConsultationFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  questionAuthorId?: ModelIDInput | null,
  answerAuthorId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  rewording?: ModelStringInput | null,
  description?: ModelStringInput | null,
  questionVideoUrl?: ModelStringInput | null,
  thumb?: ModelStringInput | null,
  file?: ModelStringInput | null,
  answerUrl?: ModelStringInput | null,
  answerContent?: ModelStringInput | null,
  answerVideoId?: ModelStringInput | null,
  answerVideoProvider?: ModelStringInput | null,
  status?: ModelStringInput | null,
  questionsSchoolId?: ModelIDInput | null,
  fAQConsultationQuestionsId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
  userQuestionsId?: ModelIDInput | null,
  questionCategoryId?: ModelIDInput | null,
  questionAnswerId?: ModelIDInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelWorkspaceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  open?: ModelBooleanInput | null,
  courseWorkspacesId?: ModelIDInput | null,
  workspaceSchoolId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelWorkspaceFilterInput | null > | null,
  or?: Array< ModelWorkspaceFilterInput | null > | null,
  not?: ModelWorkspaceFilterInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelUserFilterInput = {
  cognitoId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  userSchoolId?: ModelStringInput | null,
  address?: ModelStringInput | null,
  firstname?: ModelStringInput | null,
  lastname?: ModelStringInput | null,
  username?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  company?: ModelStringInput | null,
  contact?: ModelStringInput | null,
  email?: ModelStringInput | null,
  image?: ModelStringInput | null,
  website?: ModelStringInput | null,
  instagramId?: ModelStringInput | null,
  facebookId?: ModelStringInput | null,
  linkedInId?: ModelStringInput | null,
  twitterId?: ModelStringInput | null,
  youtubeId?: ModelStringInput | null,
  googleId?: ModelStringInput | null,
  githubId?: ModelStringInput | null,
  isFrequent?: ModelBooleanInput | null,
  isStarred?: ModelBooleanInput | null,
  label?: ModelIntInput | null,
  notes?: ModelStringInput | null,
  registered?: ModelBooleanInput | null,
  website2?: ModelStringInput | null,
  status?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelTopicFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  workspaceTopicsId?: ModelIDInput | null,
  userTopicsId?: ModelIDInput | null,
  topicSchoolId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTopicFilterInput | null > | null,
  or?: Array< ModelTopicFilterInput | null > | null,
  not?: ModelTopicFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type CreateDepartementInput = {
  id?: string | null,
  name?: string | null,
  managerID: string,
};

export type ModelDepartementConditionInput = {
  name?: ModelStringInput | null,
  managerID?: ModelIDInput | null,
  and?: Array< ModelDepartementConditionInput | null > | null,
  or?: Array< ModelDepartementConditionInput | null > | null,
  not?: ModelDepartementConditionInput | null,
};

export type Departement = {
  __typename: "Departement",
  id: string,
  name?: string | null,
  managerID: string,
  manager?: Employee | null,
  employees?: ModelEmployeeConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Employee = {
  __typename: "Employee",
  id: string,
  employeeDepartementId: string,
  name: string,
  age?: number | null,
  departement?: Departement | null,
  projects?: ModelEmployeeProjectsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelEmployeeProjectsConnection = {
  __typename: "ModelEmployeeProjectsConnection",
  items:  Array<EmployeeProjects | null >,
  nextToken?: string | null,
};

export type EmployeeProjects = {
  __typename: "EmployeeProjects",
  id: string,
  projectEmployeeId: string,
  employeeProjectId: string,
  employee?: Employee | null,
  project?: Project | null,
  createdAt: string,
  updatedAt: string,
};

export type Project = {
  __typename: "Project",
  id: string,
  name?: string | null,
  employees?: ModelEmployeeProjectsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelEmployeeConnection = {
  __typename: "ModelEmployeeConnection",
  items:  Array<Employee | null >,
  nextToken?: string | null,
};

export type UpdateDepartementInput = {
  id: string,
  name?: string | null,
  managerID?: string | null,
};

export type DeleteDepartementInput = {
  id: string,
};

export type CreateEmployeeInput = {
  id?: string | null,
  employeeDepartementId: string,
  name: string,
  age?: number | null,
};

export type ModelEmployeeConditionInput = {
  employeeDepartementId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  age?: ModelIntInput | null,
  and?: Array< ModelEmployeeConditionInput | null > | null,
  or?: Array< ModelEmployeeConditionInput | null > | null,
  not?: ModelEmployeeConditionInput | null,
};

export type UpdateEmployeeInput = {
  id: string,
  employeeDepartementId?: string | null,
  name?: string | null,
  age?: number | null,
};

export type DeleteEmployeeInput = {
  id: string,
};

export type CreateEmployeeProjectsInput = {
  id?: string | null,
  projectEmployeeId: string,
  employeeProjectId: string,
};

export type ModelEmployeeProjectsConditionInput = {
  projectEmployeeId?: ModelIDInput | null,
  employeeProjectId?: ModelIDInput | null,
  and?: Array< ModelEmployeeProjectsConditionInput | null > | null,
  or?: Array< ModelEmployeeProjectsConditionInput | null > | null,
  not?: ModelEmployeeProjectsConditionInput | null,
};

export type UpdateEmployeeProjectsInput = {
  id: string,
  projectEmployeeId?: string | null,
  employeeProjectId?: string | null,
};

export type DeleteEmployeeProjectsInput = {
  id: string,
};

export type CreateProjectInput = {
  id?: string | null,
  name?: string | null,
};

export type ModelProjectConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
};

export type UpdateProjectInput = {
  id: string,
  name?: string | null,
};

export type DeleteProjectInput = {
  id: string,
};

export type CreateSchoolInput = {
  id?: string | null,
  description: string,
  domain?: string | null,
  emailsupport?: string | null,
  lang?: string | null,
  message?: string | null,
  name: string,
  url?: string | null,
  welcomeMessage?: string | null,
  image?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
};

export type ModelSchoolConditionInput = {
  description?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  emailsupport?: ModelStringInput | null,
  lang?: ModelStringInput | null,
  message?: ModelStringInput | null,
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  welcomeMessage?: ModelStringInput | null,
  image?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelSchoolConditionInput | null > | null,
  or?: Array< ModelSchoolConditionInput | null > | null,
  not?: ModelSchoolConditionInput | null,
};

export type UpdateSchoolInput = {
  id: string,
  description?: string | null,
  domain?: string | null,
  emailsupport?: string | null,
  lang?: string | null,
  message?: string | null,
  name?: string | null,
  url?: string | null,
  welcomeMessage?: string | null,
  image?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteSchoolInput = {
  id: string,
};

export type CreateCourseInput = {
  description: string,
  id?: string | null,
  image?: string | null,
  name: string,
  published?: boolean | null,
  urlProduct?: string | null,
  courseSchoolId: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
};

export type ModelCourseConditionInput = {
  description?: ModelStringInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  urlProduct?: ModelStringInput | null,
  courseSchoolId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelCourseConditionInput | null > | null,
  or?: Array< ModelCourseConditionInput | null > | null,
  not?: ModelCourseConditionInput | null,
};

export type UpdateCourseInput = {
  description?: string | null,
  id: string,
  image?: string | null,
  name?: string | null,
  published?: boolean | null,
  urlProduct?: string | null,
  courseSchoolId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteCourseInput = {
  id: string,
};

export type CreateWorkspaceInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  image?: string | null,
  published?: boolean | null,
  open?: boolean | null,
  courseWorkspacesId?: string | null,
  workspaceSchoolId: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
};

export type ModelWorkspaceConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  open?: ModelBooleanInput | null,
  courseWorkspacesId?: ModelIDInput | null,
  workspaceSchoolId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelWorkspaceConditionInput | null > | null,
  or?: Array< ModelWorkspaceConditionInput | null > | null,
  not?: ModelWorkspaceConditionInput | null,
};

export type UpdateWorkspaceInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  image?: string | null,
  published?: boolean | null,
  open?: boolean | null,
  courseWorkspacesId?: string | null,
  workspaceSchoolId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteWorkspaceInput = {
  id: string,
};

export type CreateTopicInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  published?: boolean | null,
  workspaceTopicsId: string,
  userTopicsId: string,
  topicSchoolId: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt?: string | null,
};

export type ModelTopicConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  workspaceTopicsId?: ModelIDInput | null,
  userTopicsId?: ModelIDInput | null,
  topicSchoolId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTopicConditionInput | null > | null,
  or?: Array< ModelTopicConditionInput | null > | null,
  not?: ModelTopicConditionInput | null,
};

export type UpdateTopicInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  published?: boolean | null,
  workspaceTopicsId?: string | null,
  userTopicsId?: string | null,
  topicSchoolId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteTopicInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  message: string,
  description?: string | null,
  published?: boolean | null,
  topicMessagesId: string,
  userMessagesId: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
};

export type ModelMessageConditionInput = {
  message?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  topicMessagesId?: ModelIDInput | null,
  userMessagesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type UpdateMessageInput = {
  id: string,
  message?: string | null,
  description?: string | null,
  published?: boolean | null,
  topicMessagesId?: string | null,
  userMessagesId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateUserInput = {
  cognitoId: string,
  id?: string | null,
  userSchoolId?: string | null,
  address?: string | null,
  firstname?: string | null,
  lastname?: string | null,
  username: string,
  birthday?: string | null,
  company?: string | null,
  contact?: string | null,
  email?: string | null,
  image?: string | null,
  website?: string | null,
  instagramId?: string | null,
  facebookId?: string | null,
  linkedInId?: string | null,
  twitterId?: string | null,
  youtubeId?: string | null,
  googleId?: string | null,
  githubId?: string | null,
  isFrequent?: boolean | null,
  isStarred?: boolean | null,
  label?: number | null,
  notes?: string | null,
  registered?: boolean | null,
  website2?: string | null,
  status: string,
  owner: string,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  roles?: Array< string | null > | null,
  settings?: string | null,
};

export type ModelUserConditionInput = {
  cognitoId?: ModelIDInput | null,
  userSchoolId?: ModelStringInput | null,
  address?: ModelStringInput | null,
  firstname?: ModelStringInput | null,
  lastname?: ModelStringInput | null,
  username?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  company?: ModelStringInput | null,
  contact?: ModelStringInput | null,
  email?: ModelStringInput | null,
  image?: ModelStringInput | null,
  website?: ModelStringInput | null,
  instagramId?: ModelStringInput | null,
  facebookId?: ModelStringInput | null,
  linkedInId?: ModelStringInput | null,
  twitterId?: ModelStringInput | null,
  youtubeId?: ModelStringInput | null,
  googleId?: ModelStringInput | null,
  githubId?: ModelStringInput | null,
  isFrequent?: ModelBooleanInput | null,
  isStarred?: ModelBooleanInput | null,
  label?: ModelIntInput | null,
  notes?: ModelStringInput | null,
  registered?: ModelBooleanInput | null,
  website2?: ModelStringInput | null,
  status?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  cognitoId?: string | null,
  id: string,
  userSchoolId?: string | null,
  address?: string | null,
  firstname?: string | null,
  lastname?: string | null,
  username?: string | null,
  birthday?: string | null,
  company?: string | null,
  contact?: string | null,
  email?: string | null,
  image?: string | null,
  website?: string | null,
  instagramId?: string | null,
  facebookId?: string | null,
  linkedInId?: string | null,
  twitterId?: string | null,
  youtubeId?: string | null,
  googleId?: string | null,
  githubId?: string | null,
  isFrequent?: boolean | null,
  isStarred?: boolean | null,
  label?: number | null,
  notes?: string | null,
  registered?: boolean | null,
  website2?: string | null,
  status?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
  roles?: Array< string | null > | null,
  settings?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateFAQCategoryInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  published?: boolean | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
};

export type ModelFAQCategoryConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelFAQCategoryConditionInput | null > | null,
  or?: Array< ModelFAQCategoryConditionInput | null > | null,
  not?: ModelFAQCategoryConditionInput | null,
};

export type UpdateFAQCategoryInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  published?: boolean | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteFAQCategoryInput = {
  id: string,
};

export type CreateFAQTagInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  published?: boolean | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
};

export type ModelFAQTagConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelFAQTagConditionInput | null > | null,
  or?: Array< ModelFAQTagConditionInput | null > | null,
  not?: ModelFAQTagConditionInput | null,
};

export type UpdateFAQTagInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  published?: boolean | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteFAQTagInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  questionAuthorId: string,
  answerAuthorId?: string | null,
  title: string,
  rewording?: string | null,
  description?: string | null,
  questionVideoUrl?: string | null,
  thumb?: string | null,
  file?: string | null,
  answerUrl?: string | null,
  answerContent?: string | null,
  answerVideoId?: string | null,
  answerVideoProvider?: string | null,
  status: string,
  questionsSchoolId: string,
  fAQConsultationQuestionsId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt?: string | null,
  userQuestionsId?: string | null,
  questionCategoryId?: string | null,
  questionAnswerId?: string | null,
};

export type ModelQuestionConditionInput = {
  questionAuthorId?: ModelIDInput | null,
  answerAuthorId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  rewording?: ModelStringInput | null,
  description?: ModelStringInput | null,
  questionVideoUrl?: ModelStringInput | null,
  thumb?: ModelStringInput | null,
  file?: ModelStringInput | null,
  answerUrl?: ModelStringInput | null,
  answerContent?: ModelStringInput | null,
  answerVideoId?: ModelStringInput | null,
  answerVideoProvider?: ModelStringInput | null,
  status?: ModelStringInput | null,
  questionsSchoolId?: ModelIDInput | null,
  fAQConsultationQuestionsId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
  userQuestionsId?: ModelIDInput | null,
  questionCategoryId?: ModelIDInput | null,
  questionAnswerId?: ModelIDInput | null,
};

export type UpdateQuestionInput = {
  id: string,
  questionAuthorId?: string | null,
  answerAuthorId?: string | null,
  title?: string | null,
  rewording?: string | null,
  description?: string | null,
  questionVideoUrl?: string | null,
  thumb?: string | null,
  file?: string | null,
  answerUrl?: string | null,
  answerContent?: string | null,
  answerVideoId?: string | null,
  answerVideoProvider?: string | null,
  status?: string | null,
  questionsSchoolId?: string | null,
  fAQConsultationQuestionsId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
  createdAt?: string | null,
  userQuestionsId?: string | null,
  questionCategoryId?: string | null,
  questionAnswerId?: string | null,
};

export type DeleteQuestionInput = {
  id: string,
};

export type CreateCommentInput = {
  id?: string | null,
  description?: string | null,
  published?: boolean | null,
  commentQuestionId: string,
  commentAuthorId: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt?: string | null,
  userCommentsId?: string | null,
  questionCommentsId?: string | null,
};

export type ModelCommentConditionInput = {
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  commentQuestionId?: ModelIDInput | null,
  commentAuthorId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
  userCommentsId?: ModelIDInput | null,
  questionCommentsId?: ModelIDInput | null,
};

export type UpdateCommentInput = {
  id: string,
  description?: string | null,
  published?: boolean | null,
  commentQuestionId?: string | null,
  commentAuthorId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
  createdAt?: string | null,
  userCommentsId?: string | null,
  questionCommentsId?: string | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateAnswerInput = {
  id?: string | null,
  userAnswersId: string,
  title?: string | null,
  description?: string | null,
  urlVideo?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
};

export type ModelAnswerConditionInput = {
  userAnswersId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  urlVideo?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelAnswerConditionInput | null > | null,
  or?: Array< ModelAnswerConditionInput | null > | null,
  not?: ModelAnswerConditionInput | null,
};

export type UpdateAnswerInput = {
  id: string,
  userAnswersId?: string | null,
  title?: string | null,
  description?: string | null,
  urlVideo?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteAnswerInput = {
  id: string,
};

export type CreateFAQConsultationInput = {
  id?: string | null,
  title: string,
  description?: string | null,
  status?: string | null,
  consultationSchoolId: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt?: string | null,
};

export type ModelFAQConsultationConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  status?: ModelStringInput | null,
  consultationSchoolId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelFAQConsultationConditionInput | null > | null,
  or?: Array< ModelFAQConsultationConditionInput | null > | null,
  not?: ModelFAQConsultationConditionInput | null,
};

export type UpdateFAQConsultationInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  status?: string | null,
  consultationSchoolId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
  createdAt?: string | null,
};

export type DeleteFAQConsultationInput = {
  id: string,
};

export type CreateChatRoomInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelChatRoomConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatRoomConditionInput | null > | null,
  or?: Array< ModelChatRoomConditionInput | null > | null,
  not?: ModelChatRoomConditionInput | null,
};

export type UpdateChatRoomInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteChatRoomInput = {
  id: string,
};

export type CreateUserChatRoomInput = {
  id?: string | null,
  userId?: string | null,
  chatRoomId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt?: string | null,
  updatedAt?: string | null,
  userChatRoomsId?: string | null,
  chatRoomContributorsId?: string | null,
};

export type ModelUserChatRoomConditionInput = {
  userId?: ModelIDInput | null,
  chatRoomId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserChatRoomConditionInput | null > | null,
  or?: Array< ModelUserChatRoomConditionInput | null > | null,
  not?: ModelUserChatRoomConditionInput | null,
  userChatRoomsId?: ModelIDInput | null,
  chatRoomContributorsId?: ModelIDInput | null,
};

export type UpdateUserChatRoomInput = {
  id: string,
  userId?: string | null,
  chatRoomId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userChatRoomsId?: string | null,
  chatRoomContributorsId?: string | null,
};

export type DeleteUserChatRoomInput = {
  id: string,
};

export type CreateChatMessageInput = {
  id?: string | null,
  message: string,
  description?: string | null,
  published?: boolean | null,
  chatRoomMessagesId: string,
  userMessagesId: string,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess: Array< string | null >,
  createdAt?: string | null,
  updatedAt?: string | null,
  userChatMessagesId?: string | null,
};

export type ModelChatMessageConditionInput = {
  message?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  chatRoomMessagesId?: ModelIDInput | null,
  userMessagesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatMessageConditionInput | null > | null,
  or?: Array< ModelChatMessageConditionInput | null > | null,
  not?: ModelChatMessageConditionInput | null,
  userChatMessagesId?: ModelIDInput | null,
};

export type UpdateChatMessageInput = {
  id: string,
  message?: string | null,
  description?: string | null,
  published?: boolean | null,
  chatRoomMessagesId?: string | null,
  userMessagesId?: string | null,
  owner?: string | null,
  editors?: Array< string | null > | null,
  groupsCanAccess?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userChatMessagesId?: string | null,
};

export type DeleteChatMessageInput = {
  id: string,
};

export type CreateUserCoursesInput = {
  id?: string | null,
  courseID: string,
  userID: string,
};

export type ModelUserCoursesConditionInput = {
  courseID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelUserCoursesConditionInput | null > | null,
  or?: Array< ModelUserCoursesConditionInput | null > | null,
  not?: ModelUserCoursesConditionInput | null,
};

export type UpdateUserCoursesInput = {
  id: string,
  courseID?: string | null,
  userID?: string | null,
};

export type DeleteUserCoursesInput = {
  id: string,
};

export type CreateQuestionTagsInput = {
  id?: string | null,
  fAQTagID: string,
  questionID: string,
};

export type ModelQuestionTagsConditionInput = {
  fAQTagID?: ModelIDInput | null,
  questionID?: ModelIDInput | null,
  and?: Array< ModelQuestionTagsConditionInput | null > | null,
  or?: Array< ModelQuestionTagsConditionInput | null > | null,
  not?: ModelQuestionTagsConditionInput | null,
};

export type UpdateQuestionTagsInput = {
  id: string,
  fAQTagID?: string | null,
  questionID?: string | null,
};

export type DeleteQuestionTagsInput = {
  id: string,
};

export type ModelDepartementFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  managerID?: ModelIDInput | null,
  and?: Array< ModelDepartementFilterInput | null > | null,
  or?: Array< ModelDepartementFilterInput | null > | null,
  not?: ModelDepartementFilterInput | null,
};

export type ModelDepartementConnection = {
  __typename: "ModelDepartementConnection",
  items:  Array<Departement | null >,
  nextToken?: string | null,
};

export type ModelEmployeeFilterInput = {
  id?: ModelIDInput | null,
  employeeDepartementId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  age?: ModelIntInput | null,
  and?: Array< ModelEmployeeFilterInput | null > | null,
  or?: Array< ModelEmployeeFilterInput | null > | null,
  not?: ModelEmployeeFilterInput | null,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
};

export type ModelSchoolFilterInput = {
  id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  emailsupport?: ModelStringInput | null,
  lang?: ModelStringInput | null,
  message?: ModelStringInput | null,
  name?: ModelStringInput | null,
  url?: ModelStringInput | null,
  welcomeMessage?: ModelStringInput | null,
  image?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelSchoolFilterInput | null > | null,
  or?: Array< ModelSchoolFilterInput | null > | null,
  not?: ModelSchoolFilterInput | null,
};

export type ModelSchoolConnection = {
  __typename: "ModelSchoolConnection",
  items:  Array<School | null >,
  nextToken?: string | null,
};

export type ModelCourseFilterInput = {
  description?: ModelStringInput | null,
  id?: ModelIDInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  urlProduct?: ModelStringInput | null,
  courseSchoolId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelCourseFilterInput | null > | null,
  or?: Array< ModelCourseFilterInput | null > | null,
  not?: ModelCourseFilterInput | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  message?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  topicMessagesId?: ModelIDInput | null,
  userMessagesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelFAQCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelFAQCategoryFilterInput | null > | null,
  or?: Array< ModelFAQCategoryFilterInput | null > | null,
  not?: ModelFAQCategoryFilterInput | null,
};

export type ModelFAQCategoryConnection = {
  __typename: "ModelFAQCategoryConnection",
  items:  Array<FAQCategory | null >,
  nextToken?: string | null,
};

export type ModelFAQTagFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelFAQTagFilterInput | null > | null,
  or?: Array< ModelFAQTagFilterInput | null > | null,
  not?: ModelFAQTagFilterInput | null,
};

export type ModelFAQTagConnection = {
  __typename: "ModelFAQTagConnection",
  items:  Array<FAQTag | null >,
  nextToken?: string | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  commentQuestionId?: ModelIDInput | null,
  commentAuthorId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
  userCommentsId?: ModelIDInput | null,
  questionCommentsId?: ModelIDInput | null,
};

export type ModelAnswerFilterInput = {
  id?: ModelIDInput | null,
  userAnswersId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  urlVideo?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelAnswerFilterInput | null > | null,
  or?: Array< ModelAnswerFilterInput | null > | null,
  not?: ModelAnswerFilterInput | null,
};

export type ModelChatRoomFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatRoomFilterInput | null > | null,
  or?: Array< ModelChatRoomFilterInput | null > | null,
  not?: ModelChatRoomFilterInput | null,
};

export type ModelChatRoomConnection = {
  __typename: "ModelChatRoomConnection",
  items:  Array<ChatRoom | null >,
  nextToken?: string | null,
};

export type ModelUserChatRoomFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  chatRoomId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserChatRoomFilterInput | null > | null,
  or?: Array< ModelUserChatRoomFilterInput | null > | null,
  not?: ModelUserChatRoomFilterInput | null,
  userChatRoomsId?: ModelIDInput | null,
  chatRoomContributorsId?: ModelIDInput | null,
};

export type ModelChatMessageFilterInput = {
  id?: ModelIDInput | null,
  message?: ModelStringInput | null,
  description?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  chatRoomMessagesId?: ModelIDInput | null,
  userMessagesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChatMessageFilterInput | null > | null,
  or?: Array< ModelChatMessageFilterInput | null > | null,
  not?: ModelChatMessageFilterInput | null,
  userChatMessagesId?: ModelIDInput | null,
};

export type ModelUserCoursesFilterInput = {
  id?: ModelIDInput | null,
  courseID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelUserCoursesFilterInput | null > | null,
  or?: Array< ModelUserCoursesFilterInput | null > | null,
  not?: ModelUserCoursesFilterInput | null,
};

export type ModelQuestionTagsFilterInput = {
  id?: ModelIDInput | null,
  fAQTagID?: ModelIDInput | null,
  questionID?: ModelIDInput | null,
  and?: Array< ModelQuestionTagsFilterInput | null > | null,
  or?: Array< ModelQuestionTagsFilterInput | null > | null,
  not?: ModelQuestionTagsFilterInput | null,
};

export type ModelSubscriptionDepartementFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  managerID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionDepartementFilterInput | null > | null,
  or?: Array< ModelSubscriptionDepartementFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionEmployeeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  employeeDepartementId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  age?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionEmployeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmployeeFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionEmployeeProjectsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectEmployeeId?: ModelSubscriptionIDInput | null,
  employeeProjectId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionEmployeeProjectsFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmployeeProjectsFilterInput | null > | null,
};

export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
};

export type ModelSubscriptionSchoolFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  emailsupport?: ModelSubscriptionStringInput | null,
  lang?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  welcomeMessage?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSchoolFilterInput | null > | null,
  or?: Array< ModelSubscriptionSchoolFilterInput | null > | null,
};

export type ModelSubscriptionCourseFilterInput = {
  description?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  image?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  urlProduct?: ModelSubscriptionStringInput | null,
  courseSchoolId?: ModelSubscriptionIDInput | null,
  editors?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCourseFilterInput | null > | null,
  or?: Array< ModelSubscriptionCourseFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionWorkspaceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  open?: ModelSubscriptionBooleanInput | null,
  courseWorkspacesId?: ModelSubscriptionIDInput | null,
  workspaceSchoolId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionWorkspaceFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkspaceFilterInput | null > | null,
};

export type ModelSubscriptionTopicFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  workspaceTopicsId?: ModelSubscriptionIDInput | null,
  userTopicsId?: ModelSubscriptionIDInput | null,
  topicSchoolId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTopicFilterInput | null > | null,
  or?: Array< ModelSubscriptionTopicFilterInput | null > | null,
};

export type ModelSubscriptionMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  topicMessagesId?: ModelSubscriptionIDInput | null,
  userMessagesId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionMessageFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  cognitoId?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  userSchoolId?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  firstname?: ModelSubscriptionStringInput | null,
  lastname?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  birthday?: ModelSubscriptionStringInput | null,
  company?: ModelSubscriptionStringInput | null,
  contact?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  website?: ModelSubscriptionStringInput | null,
  instagramId?: ModelSubscriptionStringInput | null,
  facebookId?: ModelSubscriptionStringInput | null,
  linkedInId?: ModelSubscriptionStringInput | null,
  twitterId?: ModelSubscriptionStringInput | null,
  youtubeId?: ModelSubscriptionStringInput | null,
  googleId?: ModelSubscriptionStringInput | null,
  githubId?: ModelSubscriptionStringInput | null,
  isFrequent?: ModelSubscriptionBooleanInput | null,
  isStarred?: ModelSubscriptionBooleanInput | null,
  label?: ModelSubscriptionIntInput | null,
  notes?: ModelSubscriptionStringInput | null,
  registered?: ModelSubscriptionBooleanInput | null,
  website2?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  roles?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionFAQCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionFAQCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionFAQCategoryFilterInput | null > | null,
};

export type ModelSubscriptionFAQTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionFAQTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionFAQTagFilterInput | null > | null,
};

export type ModelSubscriptionQuestionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  questionAuthorId?: ModelSubscriptionIDInput | null,
  answerAuthorId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  rewording?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  questionVideoUrl?: ModelSubscriptionStringInput | null,
  thumb?: ModelSubscriptionStringInput | null,
  file?: ModelSubscriptionStringInput | null,
  answerUrl?: ModelSubscriptionStringInput | null,
  answerContent?: ModelSubscriptionStringInput | null,
  answerVideoId?: ModelSubscriptionStringInput | null,
  answerVideoProvider?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  questionsSchoolId?: ModelSubscriptionIDInput | null,
  fAQConsultationQuestionsId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionFilterInput | null > | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  commentQuestionId?: ModelSubscriptionIDInput | null,
  commentAuthorId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
};

export type ModelSubscriptionAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userAnswersId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  urlVideo?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAnswerFilterInput | null > | null,
  or?: Array< ModelSubscriptionAnswerFilterInput | null > | null,
};

export type ModelSubscriptionFAQConsultationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  consultationSchoolId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFAQConsultationFilterInput | null > | null,
  or?: Array< ModelSubscriptionFAQConsultationFilterInput | null > | null,
};

export type ModelSubscriptionChatRoomFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatRoomFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatRoomFilterInput | null > | null,
};

export type ModelSubscriptionUserChatRoomFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  chatRoomId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserChatRoomFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserChatRoomFilterInput | null > | null,
};

export type ModelSubscriptionChatMessageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  chatRoomMessagesId?: ModelSubscriptionIDInput | null,
  userMessagesId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChatMessageFilterInput | null > | null,
  or?: Array< ModelSubscriptionChatMessageFilterInput | null > | null,
};

export type ModelSubscriptionUserCoursesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  courseID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUserCoursesFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserCoursesFilterInput | null > | null,
};

export type ModelSubscriptionQuestionTagsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  fAQTagID?: ModelSubscriptionIDInput | null,
  questionID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionQuestionTagsFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuestionTagsFilterInput | null > | null,
};

export type BatchDeleteMessagesCustomMutationVariables = {
  ids?: Array< string | null > | null,
};

export type BatchDeleteMessagesCustomMutation = {
  batchDeleteMessages?:  Array< {
    __typename: "Message",
    id: string,
  } | null > | null,
};

export type BatchDeleteTopicsMessagesCustomMutationVariables = {
  topicsIds: Array< string | null >,
  messagesIds: Array< string | null >,
};

export type BatchDeleteTopicsMessagesCustomMutation = {
  batchDeleteTopicsMessages?:  Array< {
    __typename: "Topic",
    id: string,
  } | null > | null,
};

export type getTopicCustomQueryVariables = {
  id: string,
};

export type getTopicCustomQuery = {
  getTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        createdAt: string,
        updatedAt: string,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        user:  {
          __typename: "User",
          id: string,
          username: string,
          image?: string | null,
          roles?: Array< string | null > | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      cognitoId: string,
      userSchoolId?: string | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      facebookId?: string | null,
      image?: string | null,
      instagramId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      linkedInId?: string | null,
      notes?: string | null,
      registered?: boolean | null,
      twitterId?: string | null,
      website?: string | null,
      website2?: string | null,
      createdAt: string,
      updatedAt: string,
      roles?: Array< string | null > | null,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFAQConsultationsCustomQueryVariables = {
  filter?: ModelFAQConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFAQConsultationsCustomQuery = {
  listFAQConsultations?:  {
    __typename: "ModelFAQConsultationConnection",
    items:  Array< {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          author:  {
            __typename: "User",
            id: string,
            cognitoId: string,
            userSchoolId?: string | null,
            firstname?: string | null,
            lastname?: string | null,
            username: string,
            email?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          title: string,
          rewording?: string | null,
          description?: string | null,
          answerUrl?: string | null,
          category?:  {
            __typename: "FAQCategory",
            id: string,
            name: string,
            description?: string | null,
            published?: boolean | null,
          } | null,
          tags?:  {
            __typename: "ModelQuestionTagsConnection",
            items:  Array< {
              __typename: "QuestionTags",
              id: string,
              fAQTagID: string,
              questionID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              editors?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          thumb?: string | null,
          file?: string | null,
          answer?:  {
            __typename: "Answer",
            id: string,
            title?: string | null,
            description?: string | null,
            urlVideo?: string | null,
            owner?: string | null,
            editors?: Array< string | null > | null,
            groupsCanAccess: Array< string | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          status: string,
          faqConsultation?:  {
            __typename: "FAQConsultation",
            id: string,
            title: string,
            description?: string | null,
            status?: string | null,
            owner?: string | null,
            editors?: Array< string | null > | null,
            groupsCanAccess: Array< string | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          groupsCanAccess: Array< string | null >,
          createdAt: string,
          updatedAt: string,
        } | null >,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListQuestionsCustomQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsCustomQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      author:  {
        __typename: "User",
        id: string,
        cognitoId: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        facebookId?: string | null,
        image?: string | null,
        instagramId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        linkedInId?: string | null,
        notes?: string | null,
        registered?: boolean | null,
        twitterId?: string | null,
        website?: string | null,
        website2?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title: string,
      rewording?: string | null,
      description?: string | null,
      thumb?: string | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        items:  Array< {
          __typename: "QuestionTags",
          id: string,
          fAQTagID: string,
          questionID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      status: string,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getFAQConsultationCustomQueryVariables = {
  id: string,
};

export type getFAQConsultationCustomQuery = {
  getFAQConsultation?:  {
    __typename: "FAQConsultation",
    id: string,
    title: string,
    description?: string | null,
    status?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        title: string,
        rewording?: string | null,
        description?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
        author:  {
          __typename: "User",
          id: string,
          cognitoId: string,
          userSchoolId?: string | null,
          address?: string | null,
          firstname?: string | null,
          lastname?: string | null,
          username: string,
        },
        tags?:  {
          __typename: "ModelQuestionTagsConnection",
          items:  Array< {
            __typename: "QuestionTags",
            id: string,
            fAQTagID: string,
            questionID: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            editors?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        thumb?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type WorkspacesBySchoolCustomQueryVariables = {
  workspaceSchoolId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkspaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WorkspacesBySchoolCustomQuery = {
  workspacesBySchool?:  {
    __typename: "ModelWorkspaceConnection",
    items:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
        items:  Array< {
          __typename: "Topic",
          id: string,
          name: string,
          description?: string | null,
          published?: boolean | null,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            cognitoId: string,
            address?: string | null,
            firstname?: string | null,
            lastname?: string | null,
            username: string,
            birthday?: string | null,
            company?: string | null,
            contact?: string | null,
            email?: string | null,
            facebookId?: string | null,
            image?: string | null,
            instagramId?: string | null,
            isFrequent?: boolean | null,
            isStarred?: boolean | null,
            label?: number | null,
            linkedInId?: string | null,
            notes?: string | null,
            registered?: boolean | null,
            twitterId?: string | null,
            website?: string | null,
            createdAt: string,
            updatedAt: string,
            roles?: Array< string | null > | null,
          },
          messages?:  {
            __typename: "ModelMessageConnection",
            items:  Array< {
              __typename: "Message",
              id: string,
              message: string,
              description?: string | null,
              published?: boolean | null,
              createdAt: string,
              updatedAt: string,
              user:  {
                __typename: "User",
                id: string,
                username: string,
                image?: string | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type usersBySchoolCustomQueryVariables = {
  userSchoolId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type usersBySchoolCustomQuery = {
  usersBySchool?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoId: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TopicsBySchoolCustomQueryVariables = {
  topicSchoolId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TopicsBySchoolCustomQuery = {
  topicsBySchool?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
        items:  Array< {
          __typename: "Message",
          id: string,
          message: string,
          description?: string | null,
          published?: boolean | null,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            username: string,
            image?: string | null,
            roles?: Array< string | null > | null,
          },
        } | null >,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        cognitoId: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        createdAt: string,
        updatedAt: string,
        roles?: Array< string | null > | null,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TopicsByWorkspaceCustomQueryVariables = {
  workspaceTopicsId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TopicsByWorkspaceCustomQuery = {
  topicsByWorkspace?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        items:  Array< {
          __typename: "Message",
          id: string,
          message: string,
          description?: string | null,
          published?: boolean | null,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            id: string,
            username: string,
            image?: string | null,
            roles?: Array< string | null > | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        cognitoId: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        createdAt: string,
        updatedAt: string,
        roles?: Array< string | null > | null,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConsultationsBySchoolCustomQueryVariables = {
  consultationSchoolId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFAQConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationsBySchoolCustomQuery = {
  consultationsBySchool?:  {
    __typename: "ModelFAQConsultationConnection",
    items:  Array< {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          author:  {
            __typename: "User",
            id: string,
            cognitoId: string,
            userSchoolId?: string | null,
            firstname?: string | null,
            lastname?: string | null,
            username: string,
            email?: string | null,
            image?: string | null,
            createdAt: string,
            updatedAt: string,
          },
          title: string,
          rewording?: string | null,
          description?: string | null,
          answerUrl?: string | null,
          category?:  {
            __typename: "FAQCategory",
            id: string,
            name: string,
            description?: string | null,
            published?: boolean | null,
          } | null,
          tags?:  {
            __typename: "ModelQuestionTagsConnection",
            items:  Array< {
              __typename: "QuestionTags",
              id: string,
              fAQTagID: string,
              questionID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
              editors?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          file?: string | null,
          answer?:  {
            __typename: "Answer",
            id: string,
            title?: string | null,
            description?: string | null,
            urlVideo?: string | null,
            owner?: string | null,
            editors?: Array< string | null > | null,
            groupsCanAccess: Array< string | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          status: string,
          faqConsultation?:  {
            __typename: "FAQConsultation",
            id: string,
            title: string,
            description?: string | null,
            status?: string | null,
            owner?: string | null,
            editors?: Array< string | null > | null,
            groupsCanAccess: Array< string | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          groupsCanAccess: Array< string | null >,
          createdAt: string,
          updatedAt: string,
        } | null >,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getQuestionCustomQueryVariables = {
  id: string,
};

export type getQuestionCustomQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    questionAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      roles?: Array< string | null > | null,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
    },
    title: string,
    answerAuthorId?: string | null,
    answerAuthor?:  {
      __typename: "User",
      cognitoId: string,
      roles?: Array< string | null > | null,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    rewording?: string | null,
    description?: string | null,
    questionVideoUrl?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        author:  {
          __typename: "User",
          cognitoId: string,
          roles?: Array< string | null > | null,
          id: string,
          userSchoolId?: string | null,
          address?: string | null,
          firstname?: string | null,
          lastname?: string | null,
          username: string,
          birthday?: string | null,
          company?: string | null,
          contact?: string | null,
          email?: string | null,
          image?: string | null,
          website?: string | null,
          instagramId?: string | null,
          facebookId?: string | null,
          linkedInId?: string | null,
          twitterId?: string | null,
          youtubeId?: string | null,
          googleId?: string | null,
          githubId?: string | null,
          isFrequent?: boolean | null,
          isStarred?: boolean | null,
          label?: number | null,
          notes?: string | null,
          registered?: boolean | null,
          website2?: string | null,
          owner: string,
          createdAt: string,
          updatedAt: string,
        },
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?: string | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        roles?: Array< string | null > | null,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    answerUrl?: string | null,
    answerContent?: string | null,
    answerVideoId?: string | null,
    answerVideoProvider?: string | null,
    status: string,
    questionsSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    fAQConsultationQuestionsId?: string | null,
    faqConsultation?:  {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userQuestionsId?: string | null,
    questionCategoryId?: string | null,
    questionAnswerId?: string | null,
    thumb?: string | null,
  } | null,
};

export type QuestionsByAuthorCustomQueryVariables = {
  questionAuthorId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByAuthorCustomQuery = {
  questionsByAuthor?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        roles?: Array< string | null > | null,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        owner: string,
        createdAt: string,
        updatedAt: string,
      },
      title: string,
      rewording?: string | null,
      description?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        items:  Array< {
          __typename: "QuestionTags",
          id: string,
          fAQTagID: string,
          questionID: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          editors?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type meCustomChatRoomQueryVariables = {
  id: string,
};

export type meCustomChatRoomQuery = {
  me?:  {
    __typename: "User",
    cognitoId: string,
    roles?: Array< string | null > | null,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        userChatRoomsId?: string | null,
        chatRoom?:  {
          __typename: "ChatRoom",
          id: string,
          name: string,
          description?: string | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          groupsCanAccess: Array< string | null >,
          createdAt: string,
          updatedAt?: string | null,
          contributors?:  {
            __typename: "ModelUserChatRoomConnection",
            items:  Array< {
              __typename: "UserChatRoom",
              id: string,
              userId?: string | null,
              userChatRoomsId?: string | null,
              createdAt: string,
              updatedAt?: string | null,
              owner?: string | null,
              editors?: Array< string | null > | null,
              user?:  {
                __typename: "User",
                cognitoId: string,
                id: string,
                firstname?: string | null,
                lastname?: string | null,
                username: string,
                birthday?: string | null,
                company?: string | null,
                contact?: string | null,
                email?: string | null,
                image?: string | null,
                website?: string | null,
                owner: string,
                editors?: Array< string | null > | null,
                groupsCanAccess: Array< string | null >,
                roles?: Array< string | null > | null,
                createdAt: string,
                updatedAt: string,
              } | null,
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null,
        createdAt: string,
        updatedAt?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetChatRoomCustomQueryVariables = {
  id: string,
};

export type GetChatRoomCustomQuery = {
  getChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    name: string,
    description?: string | null,
    messages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
        user:  {
          __typename: "User",
          cognitoId: string,
          id: string,
          username: string,
          firstname?: string | null,
          lastname?: string | null,
          image?: string | null,
          roles?: Array< string | null > | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    contributors?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        userChatRoomsId?: string | null,
        createdAt: string,
        updatedAt?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        user?:  {
          __typename: "User",
          cognitoId: string,
          id: string,
          firstname?: string | null,
          lastname?: string | null,
          username: string,
          birthday?: string | null,
          company?: string | null,
          contact?: string | null,
          email?: string | null,
          image?: string | null,
          website?: string | null,
          owner: string,
          editors?: Array< string | null > | null,
          groupsCanAccess: Array< string | null >,
          roles?: Array< string | null > | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type GetUserByIdCustomQueryVariables = {
  userId: string,
};

export type GetUserByIdCustomQuery = {
  getUserById?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        course:  {
          __typename: "Course",
          description: string,
          id: string,
          image?: string | null,
          name: string,
          published?: boolean | null,
          urlProduct?: string | null,
          courseSchoolId: string,
          school?:  {
            __typename: "School",
            id: string,
            description: string,
            domain?: string | null,
            emailsupport?: string | null,
            lang?: string | null,
            message?: string | null,
            name: string,
            url?: string | null,
            welcomeMessage?: string | null,
            image?: string | null,
            owner?: string | null,
            editors?: Array< string | null > | null,
            groupsCanAccess: Array< string | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          workspaces?:  {
            __typename: "ModelWorkspaceConnection",
            nextToken?: string | null,
          } | null,
          users?:  {
            __typename: "ModelUserCoursesConnection",
            nextToken?: string | null,
          } | null,
          owner?: string | null,
          editors?: Array< string | null > | null,
          groupsCanAccess: Array< string | null >,
          createdAt: string,
          updatedAt: string,
        },
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type BatchDeleteMessagesMutationVariables = {
  ids?: Array< string | null > | null,
};

export type BatchDeleteMessagesMutation = {
  batchDeleteMessages?:  Array< {
    __typename: "Message",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    topicMessagesId: string,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type BatchDeleteTopicsMessagesMutationVariables = {
  topicsIds: Array< string | null >,
  messagesIds: Array< string | null >,
};

export type BatchDeleteTopicsMessagesMutation = {
  batchDeleteTopicsMessages?:  Array< {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspaceTopicsId: string,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    userTopicsId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    topicSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type CreateDepartementMutationVariables = {
  input: CreateDepartementInput,
  condition?: ModelDepartementConditionInput | null,
};

export type CreateDepartementMutation = {
  createDepartement?:  {
    __typename: "Departement",
    id: string,
    name?: string | null,
    managerID: string,
    manager?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employees?:  {
      __typename: "ModelEmployeeConnection",
      items:  Array< {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDepartementMutationVariables = {
  input: UpdateDepartementInput,
  condition?: ModelDepartementConditionInput | null,
};

export type UpdateDepartementMutation = {
  updateDepartement?:  {
    __typename: "Departement",
    id: string,
    name?: string | null,
    managerID: string,
    manager?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employees?:  {
      __typename: "ModelEmployeeConnection",
      items:  Array< {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDepartementMutationVariables = {
  input: DeleteDepartementInput,
  condition?: ModelDepartementConditionInput | null,
};

export type DeleteDepartementMutation = {
  deleteDepartement?:  {
    __typename: "Departement",
    id: string,
    name?: string | null,
    managerID: string,
    manager?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employees?:  {
      __typename: "ModelEmployeeConnection",
      items:  Array< {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEmployeeMutationVariables = {
  input: CreateEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type CreateEmployeeMutation = {
  createEmployee?:  {
    __typename: "Employee",
    id: string,
    employeeDepartementId: string,
    name: string,
    age?: number | null,
    departement?:  {
      __typename: "Departement",
      id: string,
      name?: string | null,
      managerID: string,
      manager?:  {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employees?:  {
        __typename: "ModelEmployeeConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projects?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEmployeeMutationVariables = {
  input: UpdateEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type UpdateEmployeeMutation = {
  updateEmployee?:  {
    __typename: "Employee",
    id: string,
    employeeDepartementId: string,
    name: string,
    age?: number | null,
    departement?:  {
      __typename: "Departement",
      id: string,
      name?: string | null,
      managerID: string,
      manager?:  {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employees?:  {
        __typename: "ModelEmployeeConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projects?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEmployeeMutationVariables = {
  input: DeleteEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type DeleteEmployeeMutation = {
  deleteEmployee?:  {
    __typename: "Employee",
    id: string,
    employeeDepartementId: string,
    name: string,
    age?: number | null,
    departement?:  {
      __typename: "Departement",
      id: string,
      name?: string | null,
      managerID: string,
      manager?:  {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employees?:  {
        __typename: "ModelEmployeeConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projects?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEmployeeProjectsMutationVariables = {
  input: CreateEmployeeProjectsInput,
  condition?: ModelEmployeeProjectsConditionInput | null,
};

export type CreateEmployeeProjectsMutation = {
  createEmployeeProjects?:  {
    __typename: "EmployeeProjects",
    id: string,
    projectEmployeeId: string,
    employeeProjectId: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    project?:  {
      __typename: "Project",
      id: string,
      name?: string | null,
      employees?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEmployeeProjectsMutationVariables = {
  input: UpdateEmployeeProjectsInput,
  condition?: ModelEmployeeProjectsConditionInput | null,
};

export type UpdateEmployeeProjectsMutation = {
  updateEmployeeProjects?:  {
    __typename: "EmployeeProjects",
    id: string,
    projectEmployeeId: string,
    employeeProjectId: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    project?:  {
      __typename: "Project",
      id: string,
      name?: string | null,
      employees?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEmployeeProjectsMutationVariables = {
  input: DeleteEmployeeProjectsInput,
  condition?: ModelEmployeeProjectsConditionInput | null,
};

export type DeleteEmployeeProjectsMutation = {
  deleteEmployeeProjects?:  {
    __typename: "EmployeeProjects",
    id: string,
    projectEmployeeId: string,
    employeeProjectId: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    project?:  {
      __typename: "Project",
      id: string,
      name?: string | null,
      employees?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    employees?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    employees?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    employees?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSchoolMutationVariables = {
  input: CreateSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type CreateSchoolMutation = {
  createSchool?:  {
    __typename: "School",
    id: string,
    description: string,
    domain?: string | null,
    emailsupport?: string | null,
    lang?: string | null,
    message?: string | null,
    name: string,
    url?: string | null,
    welcomeMessage?: string | null,
    image?: string | null,
    courses?:  {
      __typename: "ModelCourseConnection",
      items:  Array< {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultations?:  {
      __typename: "ModelFAQConsultationConnection",
      items:  Array< {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSchoolMutationVariables = {
  input: UpdateSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type UpdateSchoolMutation = {
  updateSchool?:  {
    __typename: "School",
    id: string,
    description: string,
    domain?: string | null,
    emailsupport?: string | null,
    lang?: string | null,
    message?: string | null,
    name: string,
    url?: string | null,
    welcomeMessage?: string | null,
    image?: string | null,
    courses?:  {
      __typename: "ModelCourseConnection",
      items:  Array< {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultations?:  {
      __typename: "ModelFAQConsultationConnection",
      items:  Array< {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSchoolMutationVariables = {
  input: DeleteSchoolInput,
  condition?: ModelSchoolConditionInput | null,
};

export type DeleteSchoolMutation = {
  deleteSchool?:  {
    __typename: "School",
    id: string,
    description: string,
    domain?: string | null,
    emailsupport?: string | null,
    lang?: string | null,
    message?: string | null,
    name: string,
    url?: string | null,
    welcomeMessage?: string | null,
    image?: string | null,
    courses?:  {
      __typename: "ModelCourseConnection",
      items:  Array< {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultations?:  {
      __typename: "ModelFAQConsultationConnection",
      items:  Array< {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCourseMutationVariables = {
  input: CreateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type CreateCourseMutation = {
  createCourse?:  {
    __typename: "Course",
    description: string,
    id: string,
    image?: string | null,
    name: string,
    published?: boolean | null,
    urlProduct?: string | null,
    courseSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCourseMutationVariables = {
  input: UpdateCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type UpdateCourseMutation = {
  updateCourse?:  {
    __typename: "Course",
    description: string,
    id: string,
    image?: string | null,
    name: string,
    published?: boolean | null,
    urlProduct?: string | null,
    courseSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCourseMutationVariables = {
  input: DeleteCourseInput,
  condition?: ModelCourseConditionInput | null,
};

export type DeleteCourseMutation = {
  deleteCourse?:  {
    __typename: "Course",
    description: string,
    id: string,
    image?: string | null,
    name: string,
    published?: boolean | null,
    urlProduct?: string | null,
    courseSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWorkspaceMutationVariables = {
  input: CreateWorkspaceInput,
  condition?: ModelWorkspaceConditionInput | null,
};

export type CreateWorkspaceMutation = {
  createWorkspace?:  {
    __typename: "Workspace",
    id: string,
    name: string,
    description?: string | null,
    image?: string | null,
    published?: boolean | null,
    open?: boolean | null,
    courseWorkspacesId?: string | null,
    course?:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaceSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWorkspaceMutationVariables = {
  input: UpdateWorkspaceInput,
  condition?: ModelWorkspaceConditionInput | null,
};

export type UpdateWorkspaceMutation = {
  updateWorkspace?:  {
    __typename: "Workspace",
    id: string,
    name: string,
    description?: string | null,
    image?: string | null,
    published?: boolean | null,
    open?: boolean | null,
    courseWorkspacesId?: string | null,
    course?:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaceSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWorkspaceMutationVariables = {
  input: DeleteWorkspaceInput,
  condition?: ModelWorkspaceConditionInput | null,
};

export type DeleteWorkspaceMutation = {
  deleteWorkspace?:  {
    __typename: "Workspace",
    id: string,
    name: string,
    description?: string | null,
    image?: string | null,
    published?: boolean | null,
    open?: boolean | null,
    courseWorkspacesId?: string | null,
    course?:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaceSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTopicMutationVariables = {
  input: CreateTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type CreateTopicMutation = {
  createTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspaceTopicsId: string,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    userTopicsId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    topicSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTopicMutationVariables = {
  input: UpdateTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type UpdateTopicMutation = {
  updateTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspaceTopicsId: string,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    userTopicsId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    topicSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTopicMutationVariables = {
  input: DeleteTopicInput,
  condition?: ModelTopicConditionInput | null,
};

export type DeleteTopicMutation = {
  deleteTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspaceTopicsId: string,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    userTopicsId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    topicSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    topicMessagesId: string,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    topicMessagesId: string,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    topicMessagesId: string,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFAQCategoryMutationVariables = {
  input: CreateFAQCategoryInput,
  condition?: ModelFAQCategoryConditionInput | null,
};

export type CreateFAQCategoryMutation = {
  createFAQCategory?:  {
    __typename: "FAQCategory",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFAQCategoryMutationVariables = {
  input: UpdateFAQCategoryInput,
  condition?: ModelFAQCategoryConditionInput | null,
};

export type UpdateFAQCategoryMutation = {
  updateFAQCategory?:  {
    __typename: "FAQCategory",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFAQCategoryMutationVariables = {
  input: DeleteFAQCategoryInput,
  condition?: ModelFAQCategoryConditionInput | null,
};

export type DeleteFAQCategoryMutation = {
  deleteFAQCategory?:  {
    __typename: "FAQCategory",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFAQTagMutationVariables = {
  input: CreateFAQTagInput,
  condition?: ModelFAQTagConditionInput | null,
};

export type CreateFAQTagMutation = {
  createFAQTag?:  {
    __typename: "FAQTag",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    questions?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFAQTagMutationVariables = {
  input: UpdateFAQTagInput,
  condition?: ModelFAQTagConditionInput | null,
};

export type UpdateFAQTagMutation = {
  updateFAQTag?:  {
    __typename: "FAQTag",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    questions?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFAQTagMutationVariables = {
  input: DeleteFAQTagInput,
  condition?: ModelFAQTagConditionInput | null,
};

export type DeleteFAQTagMutation = {
  deleteFAQTag?:  {
    __typename: "FAQTag",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    questions?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    questionAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    answerAuthorId?: string | null,
    answerAuthor?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    rewording?: string | null,
    description?: string | null,
    questionVideoUrl?: string | null,
    thumb?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?: string | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    answerUrl?: string | null,
    answerContent?: string | null,
    answerVideoId?: string | null,
    answerVideoProvider?: string | null,
    status: string,
    questionsSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    fAQConsultationQuestionsId?: string | null,
    faqConsultation?:  {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userQuestionsId?: string | null,
    questionCategoryId?: string | null,
    questionAnswerId?: string | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    questionAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    answerAuthorId?: string | null,
    answerAuthor?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    rewording?: string | null,
    description?: string | null,
    questionVideoUrl?: string | null,
    thumb?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?: string | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    answerUrl?: string | null,
    answerContent?: string | null,
    answerVideoId?: string | null,
    answerVideoProvider?: string | null,
    status: string,
    questionsSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    fAQConsultationQuestionsId?: string | null,
    faqConsultation?:  {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userQuestionsId?: string | null,
    questionCategoryId?: string | null,
    questionAnswerId?: string | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    questionAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    answerAuthorId?: string | null,
    answerAuthor?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    rewording?: string | null,
    description?: string | null,
    questionVideoUrl?: string | null,
    thumb?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?: string | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    answerUrl?: string | null,
    answerContent?: string | null,
    answerVideoId?: string | null,
    answerVideoProvider?: string | null,
    status: string,
    questionsSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    fAQConsultationQuestionsId?: string | null,
    faqConsultation?:  {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userQuestionsId?: string | null,
    questionCategoryId?: string | null,
    questionAnswerId?: string | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    description?: string | null,
    published?: boolean | null,
    commentQuestionId: string,
    question?:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null,
    commentAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userCommentsId?: string | null,
    questionCommentsId?: string | null,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    description?: string | null,
    published?: boolean | null,
    commentQuestionId: string,
    question?:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null,
    commentAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userCommentsId?: string | null,
    questionCommentsId?: string | null,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    description?: string | null,
    published?: boolean | null,
    commentQuestionId: string,
    question?:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null,
    commentAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userCommentsId?: string | null,
    questionCommentsId?: string | null,
  } | null,
};

export type CreateAnswerMutationVariables = {
  input: CreateAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type CreateAnswerMutation = {
  createAnswer?:  {
    __typename: "Answer",
    id: string,
    userAnswersId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    title?: string | null,
    description?: string | null,
    urlVideo?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAnswerMutationVariables = {
  input: UpdateAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type UpdateAnswerMutation = {
  updateAnswer?:  {
    __typename: "Answer",
    id: string,
    userAnswersId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    title?: string | null,
    description?: string | null,
    urlVideo?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAnswerMutationVariables = {
  input: DeleteAnswerInput,
  condition?: ModelAnswerConditionInput | null,
};

export type DeleteAnswerMutation = {
  deleteAnswer?:  {
    __typename: "Answer",
    id: string,
    userAnswersId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    title?: string | null,
    description?: string | null,
    urlVideo?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFAQConsultationMutationVariables = {
  input: CreateFAQConsultationInput,
  condition?: ModelFAQConsultationConditionInput | null,
};

export type CreateFAQConsultationMutation = {
  createFAQConsultation?:  {
    __typename: "FAQConsultation",
    id: string,
    title: string,
    description?: string | null,
    status?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultationSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFAQConsultationMutationVariables = {
  input: UpdateFAQConsultationInput,
  condition?: ModelFAQConsultationConditionInput | null,
};

export type UpdateFAQConsultationMutation = {
  updateFAQConsultation?:  {
    __typename: "FAQConsultation",
    id: string,
    title: string,
    description?: string | null,
    status?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultationSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFAQConsultationMutationVariables = {
  input: DeleteFAQConsultationInput,
  condition?: ModelFAQConsultationConditionInput | null,
};

export type DeleteFAQConsultationMutation = {
  deleteFAQConsultation?:  {
    __typename: "FAQConsultation",
    id: string,
    title: string,
    description?: string | null,
    status?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultationSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateChatRoomMutationVariables = {
  input: CreateChatRoomInput,
  condition?: ModelChatRoomConditionInput | null,
};

export type CreateChatRoomMutation = {
  createChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    name: string,
    description?: string | null,
    messages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contributors?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type UpdateChatRoomMutationVariables = {
  input: UpdateChatRoomInput,
  condition?: ModelChatRoomConditionInput | null,
};

export type UpdateChatRoomMutation = {
  updateChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    name: string,
    description?: string | null,
    messages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contributors?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type DeleteChatRoomMutationVariables = {
  input: DeleteChatRoomInput,
  condition?: ModelChatRoomConditionInput | null,
};

export type DeleteChatRoomMutation = {
  deleteChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    name: string,
    description?: string | null,
    messages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contributors?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type CreateUserChatRoomMutationVariables = {
  input: CreateUserChatRoomInput,
  condition?: ModelUserChatRoomConditionInput | null,
};

export type CreateUserChatRoomMutation = {
  createUserChatRoom?:  {
    __typename: "UserChatRoom",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    chatRoomId?: string | null,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatRoomsId?: string | null,
    chatRoomContributorsId?: string | null,
  } | null,
};

export type UpdateUserChatRoomMutationVariables = {
  input: UpdateUserChatRoomInput,
  condition?: ModelUserChatRoomConditionInput | null,
};

export type UpdateUserChatRoomMutation = {
  updateUserChatRoom?:  {
    __typename: "UserChatRoom",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    chatRoomId?: string | null,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatRoomsId?: string | null,
    chatRoomContributorsId?: string | null,
  } | null,
};

export type DeleteUserChatRoomMutationVariables = {
  input: DeleteUserChatRoomInput,
  condition?: ModelUserChatRoomConditionInput | null,
};

export type DeleteUserChatRoomMutation = {
  deleteUserChatRoom?:  {
    __typename: "UserChatRoom",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    chatRoomId?: string | null,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatRoomsId?: string | null,
    chatRoomContributorsId?: string | null,
  } | null,
};

export type CreateChatMessageMutationVariables = {
  input: CreateChatMessageInput,
  condition?: ModelChatMessageConditionInput | null,
};

export type CreateChatMessageMutation = {
  createChatMessage?:  {
    __typename: "ChatMessage",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    chatRoomMessagesId: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatMessagesId?: string | null,
  } | null,
};

export type UpdateChatMessageMutationVariables = {
  input: UpdateChatMessageInput,
  condition?: ModelChatMessageConditionInput | null,
};

export type UpdateChatMessageMutation = {
  updateChatMessage?:  {
    __typename: "ChatMessage",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    chatRoomMessagesId: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatMessagesId?: string | null,
  } | null,
};

export type DeleteChatMessageMutationVariables = {
  input: DeleteChatMessageInput,
  condition?: ModelChatMessageConditionInput | null,
};

export type DeleteChatMessageMutation = {
  deleteChatMessage?:  {
    __typename: "ChatMessage",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    chatRoomMessagesId: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatMessagesId?: string | null,
  } | null,
};

export type CreateUserCoursesMutationVariables = {
  input: CreateUserCoursesInput,
  condition?: ModelUserCoursesConditionInput | null,
};

export type CreateUserCoursesMutation = {
  createUserCourses?:  {
    __typename: "UserCourses",
    id: string,
    courseID: string,
    userID: string,
    course:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type UpdateUserCoursesMutationVariables = {
  input: UpdateUserCoursesInput,
  condition?: ModelUserCoursesConditionInput | null,
};

export type UpdateUserCoursesMutation = {
  updateUserCourses?:  {
    __typename: "UserCourses",
    id: string,
    courseID: string,
    userID: string,
    course:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type DeleteUserCoursesMutationVariables = {
  input: DeleteUserCoursesInput,
  condition?: ModelUserCoursesConditionInput | null,
};

export type DeleteUserCoursesMutation = {
  deleteUserCourses?:  {
    __typename: "UserCourses",
    id: string,
    courseID: string,
    userID: string,
    course:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type CreateQuestionTagsMutationVariables = {
  input: CreateQuestionTagsInput,
  condition?: ModelQuestionTagsConditionInput | null,
};

export type CreateQuestionTagsMutation = {
  createQuestionTags?:  {
    __typename: "QuestionTags",
    id: string,
    fAQTagID: string,
    questionID: string,
    fAQTag:  {
      __typename: "FAQTag",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      questions?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    question:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type UpdateQuestionTagsMutationVariables = {
  input: UpdateQuestionTagsInput,
  condition?: ModelQuestionTagsConditionInput | null,
};

export type UpdateQuestionTagsMutation = {
  updateQuestionTags?:  {
    __typename: "QuestionTags",
    id: string,
    fAQTagID: string,
    questionID: string,
    fAQTag:  {
      __typename: "FAQTag",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      questions?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    question:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type DeleteQuestionTagsMutationVariables = {
  input: DeleteQuestionTagsInput,
  condition?: ModelQuestionTagsConditionInput | null,
};

export type DeleteQuestionTagsMutation = {
  deleteQuestionTags?:  {
    __typename: "QuestionTags",
    id: string,
    fAQTagID: string,
    questionID: string,
    fAQTag:  {
      __typename: "FAQTag",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      questions?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    question:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type GetUserByIdQueryVariables = {
  userId: string,
};

export type GetUserByIdQuery = {
  getUserById?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetDepartementQueryVariables = {
  id: string,
};

export type GetDepartementQuery = {
  getDepartement?:  {
    __typename: "Departement",
    id: string,
    name?: string | null,
    managerID: string,
    manager?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employees?:  {
      __typename: "ModelEmployeeConnection",
      items:  Array< {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDepartementsQueryVariables = {
  filter?: ModelDepartementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartementsQuery = {
  listDepartements?:  {
    __typename: "ModelDepartementConnection",
    items:  Array< {
      __typename: "Departement",
      id: string,
      name?: string | null,
      managerID: string,
      manager?:  {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employees?:  {
        __typename: "ModelEmployeeConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEmployeeQueryVariables = {
  id: string,
};

export type GetEmployeeQuery = {
  getEmployee?:  {
    __typename: "Employee",
    id: string,
    employeeDepartementId: string,
    name: string,
    age?: number | null,
    departement?:  {
      __typename: "Departement",
      id: string,
      name?: string | null,
      managerID: string,
      manager?:  {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employees?:  {
        __typename: "ModelEmployeeConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projects?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEmployeesQueryVariables = {
  filter?: ModelEmployeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmployeesQuery = {
  listEmployees?:  {
    __typename: "ModelEmployeeConnection",
    items:  Array< {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    employees?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      name?: string | null,
      employees?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSchoolQueryVariables = {
  id: string,
};

export type GetSchoolQuery = {
  getSchool?:  {
    __typename: "School",
    id: string,
    description: string,
    domain?: string | null,
    emailsupport?: string | null,
    lang?: string | null,
    message?: string | null,
    name: string,
    url?: string | null,
    welcomeMessage?: string | null,
    image?: string | null,
    courses?:  {
      __typename: "ModelCourseConnection",
      items:  Array< {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultations?:  {
      __typename: "ModelFAQConsultationConnection",
      items:  Array< {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSchoolsQueryVariables = {
  filter?: ModelSchoolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSchoolsQuery = {
  listSchools?:  {
    __typename: "ModelSchoolConnection",
    items:  Array< {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCourseQueryVariables = {
  id: string,
};

export type GetCourseQuery = {
  getCourse?:  {
    __typename: "Course",
    description: string,
    id: string,
    image?: string | null,
    name: string,
    published?: boolean | null,
    urlProduct?: string | null,
    courseSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCoursesQueryVariables = {
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCoursesQuery = {
  listCourses?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWorkspaceQueryVariables = {
  id: string,
};

export type GetWorkspaceQuery = {
  getWorkspace?:  {
    __typename: "Workspace",
    id: string,
    name: string,
    description?: string | null,
    image?: string | null,
    published?: boolean | null,
    open?: boolean | null,
    courseWorkspacesId?: string | null,
    course?:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaceSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWorkspacesQueryVariables = {
  filter?: ModelWorkspaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkspacesQuery = {
  listWorkspaces?:  {
    __typename: "ModelWorkspaceConnection",
    items:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTopicQueryVariables = {
  id: string,
};

export type GetTopicQuery = {
  getTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspaceTopicsId: string,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    userTopicsId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    topicSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTopicsQueryVariables = {
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTopicsQuery = {
  listTopics?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    topicMessagesId: string,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      message: string,
      description?: string | null,
      published?: boolean | null,
      topicMessagesId: string,
      topic?:  {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      userMessagesId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MeQueryVariables = {
  id: string,
};

export type MeQuery = {
  me?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetFAQCategoryQueryVariables = {
  id: string,
};

export type GetFAQCategoryQuery = {
  getFAQCategory?:  {
    __typename: "FAQCategory",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFAQCategoriesQueryVariables = {
  filter?: ModelFAQCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFAQCategoriesQuery = {
  listFAQCategories?:  {
    __typename: "ModelFAQCategoryConnection",
    items:  Array< {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFAQTagQueryVariables = {
  id: string,
};

export type GetFAQTagQuery = {
  getFAQTag?:  {
    __typename: "FAQTag",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    questions?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFAQTagsQueryVariables = {
  filter?: ModelFAQTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFAQTagsQuery = {
  listFAQTags?:  {
    __typename: "ModelFAQTagConnection",
    items:  Array< {
      __typename: "FAQTag",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      questions?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    questionAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    answerAuthorId?: string | null,
    answerAuthor?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    rewording?: string | null,
    description?: string | null,
    questionVideoUrl?: string | null,
    thumb?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?: string | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    answerUrl?: string | null,
    answerContent?: string | null,
    answerVideoId?: string | null,
    answerVideoProvider?: string | null,
    status: string,
    questionsSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    fAQConsultationQuestionsId?: string | null,
    faqConsultation?:  {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userQuestionsId?: string | null,
    questionCategoryId?: string | null,
    questionAnswerId?: string | null,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    description?: string | null,
    published?: boolean | null,
    commentQuestionId: string,
    question?:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null,
    commentAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userCommentsId?: string | null,
    questionCommentsId?: string | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      description?: string | null,
      published?: boolean | null,
      commentQuestionId: string,
      question?:  {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null,
      commentAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userCommentsId?: string | null,
      questionCommentsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAnswerQueryVariables = {
  id: string,
};

export type GetAnswerQuery = {
  getAnswer?:  {
    __typename: "Answer",
    id: string,
    userAnswersId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    title?: string | null,
    description?: string | null,
    urlVideo?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAnswersQueryVariables = {
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersQuery = {
  listAnswers?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFAQConsultationQueryVariables = {
  id: string,
};

export type GetFAQConsultationQuery = {
  getFAQConsultation?:  {
    __typename: "FAQConsultation",
    id: string,
    title: string,
    description?: string | null,
    status?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultationSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFAQConsultationsQueryVariables = {
  filter?: ModelFAQConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFAQConsultationsQuery = {
  listFAQConsultations?:  {
    __typename: "ModelFAQConsultationConnection",
    items:  Array< {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChatRoomQueryVariables = {
  id: string,
};

export type GetChatRoomQuery = {
  getChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    name: string,
    description?: string | null,
    messages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contributors?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type ListChatRoomsQueryVariables = {
  filter?: ModelChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatRoomsQuery = {
  listChatRooms?:  {
    __typename: "ModelChatRoomConnection",
    items:  Array< {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserChatRoomQueryVariables = {
  id: string,
};

export type GetUserChatRoomQuery = {
  getUserChatRoom?:  {
    __typename: "UserChatRoom",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    chatRoomId?: string | null,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatRoomsId?: string | null,
    chatRoomContributorsId?: string | null,
  } | null,
};

export type ListUserChatRoomsQueryVariables = {
  filter?: ModelUserChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserChatRoomsQuery = {
  listUserChatRooms?:  {
    __typename: "ModelUserChatRoomConnection",
    items:  Array< {
      __typename: "UserChatRoom",
      id: string,
      userId?: string | null,
      user?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      chatRoomId?: string | null,
      chatRoom?:  {
        __typename: "ChatRoom",
        id: string,
        name: string,
        description?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
      userChatRoomsId?: string | null,
      chatRoomContributorsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetChatMessageQueryVariables = {
  id: string,
};

export type GetChatMessageQuery = {
  getChatMessage?:  {
    __typename: "ChatMessage",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    chatRoomMessagesId: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatMessagesId?: string | null,
  } | null,
};

export type ListChatMessagesQueryVariables = {
  filter?: ModelChatMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatMessagesQuery = {
  listChatMessages?:  {
    __typename: "ModelChatMessageConnection",
    items:  Array< {
      __typename: "ChatMessage",
      id: string,
      message: string,
      description?: string | null,
      published?: boolean | null,
      chatRoomMessagesId: string,
      chatRoom?:  {
        __typename: "ChatRoom",
        id: string,
        name: string,
        description?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      userMessagesId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
      userChatMessagesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserCoursesQueryVariables = {
  id: string,
};

export type GetUserCoursesQuery = {
  getUserCourses?:  {
    __typename: "UserCourses",
    id: string,
    courseID: string,
    userID: string,
    course:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type ListUserCoursesQueryVariables = {
  filter?: ModelUserCoursesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserCoursesQuery = {
  listUserCourses?:  {
    __typename: "ModelUserCoursesConnection",
    items:  Array< {
      __typename: "UserCourses",
      id: string,
      courseID: string,
      userID: string,
      course:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      },
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      editors?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionTagsQueryVariables = {
  id: string,
};

export type GetQuestionTagsQuery = {
  getQuestionTags?:  {
    __typename: "QuestionTags",
    id: string,
    fAQTagID: string,
    questionID: string,
    fAQTag:  {
      __typename: "FAQTag",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      questions?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    question:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type ListQuestionTagsQueryVariables = {
  filter?: ModelQuestionTagsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionTagsQuery = {
  listQuestionTags?:  {
    __typename: "ModelQuestionTagsConnection",
    items:  Array< {
      __typename: "QuestionTags",
      id: string,
      fAQTagID: string,
      questionID: string,
      fAQTag:  {
        __typename: "FAQTag",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      },
      question:  {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      editors?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SchoolByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSchoolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SchoolByNameQuery = {
  schoolByName?:  {
    __typename: "ModelSchoolConnection",
    items:  Array< {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CoursesBySchoolQueryVariables = {
  courseSchoolId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCourseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CoursesBySchoolQuery = {
  coursesBySchool?:  {
    __typename: "ModelCourseConnection",
    items:  Array< {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WorkspacesByCourseQueryVariables = {
  courseWorkspacesId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkspaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WorkspacesByCourseQuery = {
  workspacesByCourse?:  {
    __typename: "ModelWorkspaceConnection",
    items:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WorkspacesBySchoolQueryVariables = {
  workspaceSchoolId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkspaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WorkspacesBySchoolQuery = {
  workspacesBySchool?:  {
    __typename: "ModelWorkspaceConnection",
    items:  Array< {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TopicsByWorkspaceQueryVariables = {
  workspaceTopicsId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TopicsByWorkspaceQuery = {
  topicsByWorkspace?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TopicsByUserQueryVariables = {
  userTopicsId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TopicsByUserQuery = {
  topicsByUser?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TopicsBySchoolQueryVariables = {
  topicSchoolId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTopicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TopicsBySchoolQuery = {
  topicsBySchool?:  {
    __typename: "ModelTopicConnection",
    items:  Array< {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessagesByTopicQueryVariables = {
  topicMessagesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByTopicQuery = {
  messagesByTopic?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      message: string,
      description?: string | null,
      published?: boolean | null,
      topicMessagesId: string,
      topic?:  {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      userMessagesId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessagesByUserQueryVariables = {
  userMessagesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByUserQuery = {
  messagesByUser?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      message: string,
      description?: string | null,
      published?: boolean | null,
      topicMessagesId: string,
      topic?:  {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      userMessagesId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersBySchoolQueryVariables = {
  userSchoolId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersBySchoolQuery = {
  usersBySchool?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  usersByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByAuthorQueryVariables = {
  questionAuthorId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByAuthorQuery = {
  questionsByAuthor?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByAnswerAuthorQueryVariables = {
  answerAuthorId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByAnswerAuthorQuery = {
  questionsByAnswerAuthor?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByDateQueryVariables = {
  status: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByDateQuery = {
  questionsByDate?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsBySchoolQueryVariables = {
  questionsSchoolId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsBySchoolQuery = {
  questionsBySchool?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsBySchoolTitleQueryVariables = {
  questionsSchoolId: string,
  title?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsBySchoolTitleQuery = {
  questionsBySchoolTitle?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByConsultationQueryVariables = {
  fAQConsultationQuestionsId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByConsultationQuery = {
  questionsByConsultation?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByQuestionQueryVariables = {
  commentQuestionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByQuestionQuery = {
  commentsByQuestion?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      description?: string | null,
      published?: boolean | null,
      commentQuestionId: string,
      question?:  {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null,
      commentAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userCommentsId?: string | null,
      questionCommentsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByAuthorQueryVariables = {
  commentAuthorId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByAuthorQuery = {
  commentsByAuthor?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      description?: string | null,
      published?: boolean | null,
      commentQuestionId: string,
      question?:  {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null,
      commentAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userCommentsId?: string | null,
      questionCommentsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AnswersByAuthorQueryVariables = {
  userAnswersId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnswersByAuthorQuery = {
  answersByAuthor?:  {
    __typename: "ModelAnswerConnection",
    items:  Array< {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConsultationsBySchoolQueryVariables = {
  consultationSchoolId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFAQConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsultationsBySchoolQuery = {
  consultationsBySchool?:  {
    __typename: "ModelFAQConsultationConnection",
    items:  Array< {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatRoomsByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatRoomsByUserQuery = {
  chatRoomsByUser?:  {
    __typename: "ModelUserChatRoomConnection",
    items:  Array< {
      __typename: "UserChatRoom",
      id: string,
      userId?: string | null,
      user?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      chatRoomId?: string | null,
      chatRoom?:  {
        __typename: "ChatRoom",
        id: string,
        name: string,
        description?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
      userChatRoomsId?: string | null,
      chatRoomContributorsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByChatRoomQueryVariables = {
  chatRoomId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByChatRoomQuery = {
  usersByChatRoom?:  {
    __typename: "ModelUserChatRoomConnection",
    items:  Array< {
      __typename: "UserChatRoom",
      id: string,
      userId?: string | null,
      user?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      chatRoomId?: string | null,
      chatRoom?:  {
        __typename: "ChatRoom",
        id: string,
        name: string,
        description?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
      userChatRoomsId?: string | null,
      chatRoomContributorsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MessagesByChatRoomQueryVariables = {
  chatRoomMessagesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByChatRoomQuery = {
  messagesByChatRoom?:  {
    __typename: "ModelChatMessageConnection",
    items:  Array< {
      __typename: "ChatMessage",
      id: string,
      message: string,
      description?: string | null,
      published?: boolean | null,
      chatRoomMessagesId: string,
      chatRoom?:  {
        __typename: "ChatRoom",
        id: string,
        name: string,
        description?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      userMessagesId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
      userChatMessagesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChatMessagesByUserQueryVariables = {
  userMessagesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatMessagesByUserQuery = {
  chatMessagesByUser?:  {
    __typename: "ModelChatMessageConnection",
    items:  Array< {
      __typename: "ChatMessage",
      id: string,
      message: string,
      description?: string | null,
      published?: boolean | null,
      chatRoomMessagesId: string,
      chatRoom?:  {
        __typename: "ChatRoom",
        id: string,
        name: string,
        description?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
      } | null,
      userMessagesId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
      userChatMessagesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateDepartementSubscriptionVariables = {
  filter?: ModelSubscriptionDepartementFilterInput | null,
};

export type OnCreateDepartementSubscription = {
  onCreateDepartement?:  {
    __typename: "Departement",
    id: string,
    name?: string | null,
    managerID: string,
    manager?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employees?:  {
      __typename: "ModelEmployeeConnection",
      items:  Array< {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDepartementSubscriptionVariables = {
  filter?: ModelSubscriptionDepartementFilterInput | null,
};

export type OnUpdateDepartementSubscription = {
  onUpdateDepartement?:  {
    __typename: "Departement",
    id: string,
    name?: string | null,
    managerID: string,
    manager?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employees?:  {
      __typename: "ModelEmployeeConnection",
      items:  Array< {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDepartementSubscriptionVariables = {
  filter?: ModelSubscriptionDepartementFilterInput | null,
};

export type OnDeleteDepartementSubscription = {
  onDeleteDepartement?:  {
    __typename: "Departement",
    id: string,
    name?: string | null,
    managerID: string,
    manager?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    employees?:  {
      __typename: "ModelEmployeeConnection",
      items:  Array< {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
};

export type OnCreateEmployeeSubscription = {
  onCreateEmployee?:  {
    __typename: "Employee",
    id: string,
    employeeDepartementId: string,
    name: string,
    age?: number | null,
    departement?:  {
      __typename: "Departement",
      id: string,
      name?: string | null,
      managerID: string,
      manager?:  {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employees?:  {
        __typename: "ModelEmployeeConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projects?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
};

export type OnUpdateEmployeeSubscription = {
  onUpdateEmployee?:  {
    __typename: "Employee",
    id: string,
    employeeDepartementId: string,
    name: string,
    age?: number | null,
    departement?:  {
      __typename: "Departement",
      id: string,
      name?: string | null,
      managerID: string,
      manager?:  {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employees?:  {
        __typename: "ModelEmployeeConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projects?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
};

export type OnDeleteEmployeeSubscription = {
  onDeleteEmployee?:  {
    __typename: "Employee",
    id: string,
    employeeDepartementId: string,
    name: string,
    age?: number | null,
    departement?:  {
      __typename: "Departement",
      id: string,
      name?: string | null,
      managerID: string,
      manager?:  {
        __typename: "Employee",
        id: string,
        employeeDepartementId: string,
        name: string,
        age?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      employees?:  {
        __typename: "ModelEmployeeConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projects?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEmployeeProjectsSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeProjectsFilterInput | null,
};

export type OnCreateEmployeeProjectsSubscription = {
  onCreateEmployeeProjects?:  {
    __typename: "EmployeeProjects",
    id: string,
    projectEmployeeId: string,
    employeeProjectId: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    project?:  {
      __typename: "Project",
      id: string,
      name?: string | null,
      employees?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmployeeProjectsSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeProjectsFilterInput | null,
};

export type OnUpdateEmployeeProjectsSubscription = {
  onUpdateEmployeeProjects?:  {
    __typename: "EmployeeProjects",
    id: string,
    projectEmployeeId: string,
    employeeProjectId: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    project?:  {
      __typename: "Project",
      id: string,
      name?: string | null,
      employees?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmployeeProjectsSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeProjectsFilterInput | null,
};

export type OnDeleteEmployeeProjectsSubscription = {
  onDeleteEmployeeProjects?:  {
    __typename: "EmployeeProjects",
    id: string,
    projectEmployeeId: string,
    employeeProjectId: string,
    employee?:  {
      __typename: "Employee",
      id: string,
      employeeDepartementId: string,
      name: string,
      age?: number | null,
      departement?:  {
        __typename: "Departement",
        id: string,
        name?: string | null,
        managerID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      projects?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    project?:  {
      __typename: "Project",
      id: string,
      name?: string | null,
      employees?:  {
        __typename: "ModelEmployeeProjectsConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    employees?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    employees?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    employees?:  {
      __typename: "ModelEmployeeProjectsConnection",
      items:  Array< {
        __typename: "EmployeeProjects",
        id: string,
        projectEmployeeId: string,
        employeeProjectId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSchoolSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolFilterInput | null,
  owner?: string | null,
};

export type OnCreateSchoolSubscription = {
  onCreateSchool?:  {
    __typename: "School",
    id: string,
    description: string,
    domain?: string | null,
    emailsupport?: string | null,
    lang?: string | null,
    message?: string | null,
    name: string,
    url?: string | null,
    welcomeMessage?: string | null,
    image?: string | null,
    courses?:  {
      __typename: "ModelCourseConnection",
      items:  Array< {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultations?:  {
      __typename: "ModelFAQConsultationConnection",
      items:  Array< {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSchoolSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSchoolSubscription = {
  onUpdateSchool?:  {
    __typename: "School",
    id: string,
    description: string,
    domain?: string | null,
    emailsupport?: string | null,
    lang?: string | null,
    message?: string | null,
    name: string,
    url?: string | null,
    welcomeMessage?: string | null,
    image?: string | null,
    courses?:  {
      __typename: "ModelCourseConnection",
      items:  Array< {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultations?:  {
      __typename: "ModelFAQConsultationConnection",
      items:  Array< {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSchoolSubscriptionVariables = {
  filter?: ModelSubscriptionSchoolFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSchoolSubscription = {
  onDeleteSchool?:  {
    __typename: "School",
    id: string,
    description: string,
    domain?: string | null,
    emailsupport?: string | null,
    lang?: string | null,
    message?: string | null,
    name: string,
    url?: string | null,
    welcomeMessage?: string | null,
    image?: string | null,
    courses?:  {
      __typename: "ModelCourseConnection",
      items:  Array< {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultations?:  {
      __typename: "ModelFAQConsultationConnection",
      items:  Array< {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
  owner?: string | null,
};

export type OnCreateCourseSubscription = {
  onCreateCourse?:  {
    __typename: "Course",
    description: string,
    id: string,
    image?: string | null,
    name: string,
    published?: boolean | null,
    urlProduct?: string | null,
    courseSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCourseSubscription = {
  onUpdateCourse?:  {
    __typename: "Course",
    description: string,
    id: string,
    image?: string | null,
    name: string,
    published?: boolean | null,
    urlProduct?: string | null,
    courseSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCourseSubscriptionVariables = {
  filter?: ModelSubscriptionCourseFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCourseSubscription = {
  onDeleteCourse?:  {
    __typename: "Course",
    description: string,
    id: string,
    image?: string | null,
    name: string,
    published?: boolean | null,
    urlProduct?: string | null,
    courseSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    workspaces?:  {
      __typename: "ModelWorkspaceConnection",
      items:  Array< {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionWorkspaceFilterInput | null,
  owner?: string | null,
};

export type OnCreateWorkspaceSubscription = {
  onCreateWorkspace?:  {
    __typename: "Workspace",
    id: string,
    name: string,
    description?: string | null,
    image?: string | null,
    published?: boolean | null,
    open?: boolean | null,
    courseWorkspacesId?: string | null,
    course?:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaceSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionWorkspaceFilterInput | null,
  owner?: string | null,
};

export type OnUpdateWorkspaceSubscription = {
  onUpdateWorkspace?:  {
    __typename: "Workspace",
    id: string,
    name: string,
    description?: string | null,
    image?: string | null,
    published?: boolean | null,
    open?: boolean | null,
    courseWorkspacesId?: string | null,
    course?:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaceSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionWorkspaceFilterInput | null,
  owner?: string | null,
};

export type OnDeleteWorkspaceSubscription = {
  onDeleteWorkspace?:  {
    __typename: "Workspace",
    id: string,
    name: string,
    description?: string | null,
    image?: string | null,
    published?: boolean | null,
    open?: boolean | null,
    courseWorkspacesId?: string | null,
    course?:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    workspaceSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTopicSubscriptionVariables = {
  filter?: ModelSubscriptionTopicFilterInput | null,
  owner?: string | null,
};

export type OnCreateTopicSubscription = {
  onCreateTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspaceTopicsId: string,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    userTopicsId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    topicSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTopicSubscriptionVariables = {
  filter?: ModelSubscriptionTopicFilterInput | null,
  owner?: string | null,
};

export type OnUpdateTopicSubscription = {
  onUpdateTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspaceTopicsId: string,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    userTopicsId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    topicSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTopicSubscriptionVariables = {
  filter?: ModelSubscriptionTopicFilterInput | null,
  owner?: string | null,
};

export type OnDeleteTopicSubscription = {
  onDeleteTopic?:  {
    __typename: "Topic",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    workspaceTopicsId: string,
    workspace?:  {
      __typename: "Workspace",
      id: string,
      name: string,
      description?: string | null,
      image?: string | null,
      published?: boolean | null,
      open?: boolean | null,
      courseWorkspacesId?: string | null,
      course?:  {
        __typename: "Course",
        description: string,
        id: string,
        image?: string | null,
        name: string,
        published?: boolean | null,
        urlProduct?: string | null,
        courseSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      workspaceSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    userTopicsId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    topicSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
  owner?: string | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    topicMessagesId: string,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
  owner?: string | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    topicMessagesId: string,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  filter?: ModelSubscriptionMessageFilterInput | null,
  owner?: string | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    topicMessagesId: string,
    topic?:  {
      __typename: "Topic",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      workspaceTopicsId: string,
      workspace?:  {
        __typename: "Workspace",
        id: string,
        name: string,
        description?: string | null,
        image?: string | null,
        published?: boolean | null,
        open?: boolean | null,
        courseWorkspacesId?: string | null,
        workspaceSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      userTopicsId: string,
      user:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      topicSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    cognitoId: string,
    id: string,
    userSchoolId?: string | null,
    topics?:  {
      __typename: "ModelTopicConnection",
      items:  Array< {
        __typename: "Topic",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        workspaceTopicsId: string,
        userTopicsId: string,
        topicSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        topicMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "ModelAnswerConnection",
      items:  Array< {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    courses?:  {
      __typename: "ModelUserCoursesConnection",
      items:  Array< {
        __typename: "UserCourses",
        id: string,
        courseID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatRooms?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chatMessages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    address?: string | null,
    firstname?: string | null,
    lastname?: string | null,
    username: string,
    birthday?: string | null,
    company?: string | null,
    contact?: string | null,
    email?: string | null,
    image?: string | null,
    website?: string | null,
    instagramId?: string | null,
    facebookId?: string | null,
    linkedInId?: string | null,
    twitterId?: string | null,
    youtubeId?: string | null,
    googleId?: string | null,
    githubId?: string | null,
    isFrequent?: boolean | null,
    isStarred?: boolean | null,
    label?: number | null,
    notes?: string | null,
    registered?: boolean | null,
    website2?: string | null,
    status: string,
    owner: string,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    roles?: Array< string | null > | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFAQCategorySubscriptionVariables = {
  filter?: ModelSubscriptionFAQCategoryFilterInput | null,
  owner?: string | null,
};

export type OnCreateFAQCategorySubscription = {
  onCreateFAQCategory?:  {
    __typename: "FAQCategory",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFAQCategorySubscriptionVariables = {
  filter?: ModelSubscriptionFAQCategoryFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFAQCategorySubscription = {
  onUpdateFAQCategory?:  {
    __typename: "FAQCategory",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFAQCategorySubscriptionVariables = {
  filter?: ModelSubscriptionFAQCategoryFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFAQCategorySubscription = {
  onDeleteFAQCategory?:  {
    __typename: "FAQCategory",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFAQTagSubscriptionVariables = {
  filter?: ModelSubscriptionFAQTagFilterInput | null,
  owner?: string | null,
};

export type OnCreateFAQTagSubscription = {
  onCreateFAQTag?:  {
    __typename: "FAQTag",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    questions?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFAQTagSubscriptionVariables = {
  filter?: ModelSubscriptionFAQTagFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFAQTagSubscription = {
  onUpdateFAQTag?:  {
    __typename: "FAQTag",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    questions?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFAQTagSubscriptionVariables = {
  filter?: ModelSubscriptionFAQTagFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFAQTagSubscription = {
  onDeleteFAQTag?:  {
    __typename: "FAQTag",
    id: string,
    name: string,
    description?: string | null,
    published?: boolean | null,
    questions?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    questionAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    answerAuthorId?: string | null,
    answerAuthor?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    rewording?: string | null,
    description?: string | null,
    questionVideoUrl?: string | null,
    thumb?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?: string | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    answerUrl?: string | null,
    answerContent?: string | null,
    answerVideoId?: string | null,
    answerVideoProvider?: string | null,
    status: string,
    questionsSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    fAQConsultationQuestionsId?: string | null,
    faqConsultation?:  {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userQuestionsId?: string | null,
    questionCategoryId?: string | null,
    questionAnswerId?: string | null,
  } | null,
};

export type OnUpdateQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    questionAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    answerAuthorId?: string | null,
    answerAuthor?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    rewording?: string | null,
    description?: string | null,
    questionVideoUrl?: string | null,
    thumb?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?: string | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    answerUrl?: string | null,
    answerContent?: string | null,
    answerVideoId?: string | null,
    answerVideoProvider?: string | null,
    status: string,
    questionsSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    fAQConsultationQuestionsId?: string | null,
    faqConsultation?:  {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userQuestionsId?: string | null,
    questionCategoryId?: string | null,
    questionAnswerId?: string | null,
  } | null,
};

export type OnDeleteQuestionSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    questionAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    answerAuthorId?: string | null,
    answerAuthor?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    rewording?: string | null,
    description?: string | null,
    questionVideoUrl?: string | null,
    thumb?: string | null,
    comments?:  {
      __typename: "ModelCommentConnection",
      items:  Array< {
        __typename: "Comment",
        id: string,
        description?: string | null,
        published?: boolean | null,
        commentQuestionId: string,
        commentAuthorId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userCommentsId?: string | null,
        questionCommentsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "FAQCategory",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?:  {
      __typename: "ModelQuestionTagsConnection",
      items:  Array< {
        __typename: "QuestionTags",
        id: string,
        fAQTagID: string,
        questionID: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        editors?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    file?: string | null,
    answer?:  {
      __typename: "Answer",
      id: string,
      userAnswersId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      title?: string | null,
      description?: string | null,
      urlVideo?: string | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    answerUrl?: string | null,
    answerContent?: string | null,
    answerVideoId?: string | null,
    answerVideoProvider?: string | null,
    status: string,
    questionsSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    fAQConsultationQuestionsId?: string | null,
    faqConsultation?:  {
      __typename: "FAQConsultation",
      id: string,
      title: string,
      description?: string | null,
      status?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      consultationSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userQuestionsId?: string | null,
    questionCategoryId?: string | null,
    questionAnswerId?: string | null,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    description?: string | null,
    published?: boolean | null,
    commentQuestionId: string,
    question?:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null,
    commentAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userCommentsId?: string | null,
    questionCommentsId?: string | null,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    description?: string | null,
    published?: boolean | null,
    commentQuestionId: string,
    question?:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null,
    commentAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userCommentsId?: string | null,
    questionCommentsId?: string | null,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    description?: string | null,
    published?: boolean | null,
    commentQuestionId: string,
    question?:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    } | null,
    commentAuthorId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    userCommentsId?: string | null,
    questionCommentsId?: string | null,
  } | null,
};

export type OnCreateAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
  owner?: string | null,
};

export type OnCreateAnswerSubscription = {
  onCreateAnswer?:  {
    __typename: "Answer",
    id: string,
    userAnswersId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    title?: string | null,
    description?: string | null,
    urlVideo?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
  owner?: string | null,
};

export type OnUpdateAnswerSubscription = {
  onUpdateAnswer?:  {
    __typename: "Answer",
    id: string,
    userAnswersId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    title?: string | null,
    description?: string | null,
    urlVideo?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionAnswerFilterInput | null,
  owner?: string | null,
};

export type OnDeleteAnswerSubscription = {
  onDeleteAnswer?:  {
    __typename: "Answer",
    id: string,
    userAnswersId: string,
    author:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    title?: string | null,
    description?: string | null,
    urlVideo?: string | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFAQConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionFAQConsultationFilterInput | null,
  owner?: string | null,
};

export type OnCreateFAQConsultationSubscription = {
  onCreateFAQConsultation?:  {
    __typename: "FAQConsultation",
    id: string,
    title: string,
    description?: string | null,
    status?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultationSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFAQConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionFAQConsultationFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFAQConsultationSubscription = {
  onUpdateFAQConsultation?:  {
    __typename: "FAQConsultation",
    id: string,
    title: string,
    description?: string | null,
    status?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultationSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFAQConsultationSubscriptionVariables = {
  filter?: ModelSubscriptionFAQConsultationFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFAQConsultationSubscription = {
  onDeleteFAQConsultation?:  {
    __typename: "FAQConsultation",
    id: string,
    title: string,
    description?: string | null,
    status?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        questionAuthorId: string,
        answerAuthorId?: string | null,
        title: string,
        rewording?: string | null,
        description?: string | null,
        questionVideoUrl?: string | null,
        thumb?: string | null,
        file?: string | null,
        answerUrl?: string | null,
        answerContent?: string | null,
        answerVideoId?: string | null,
        answerVideoProvider?: string | null,
        status: string,
        questionsSchoolId: string,
        fAQConsultationQuestionsId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
        userQuestionsId?: string | null,
        questionCategoryId?: string | null,
        questionAnswerId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consultationSchoolId: string,
    school?:  {
      __typename: "School",
      id: string,
      description: string,
      domain?: string | null,
      emailsupport?: string | null,
      lang?: string | null,
      message?: string | null,
      name: string,
      url?: string | null,
      welcomeMessage?: string | null,
      image?: string | null,
      courses?:  {
        __typename: "ModelCourseConnection",
        nextToken?: string | null,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      consultations?:  {
        __typename: "ModelFAQConsultationConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChatRoomSubscriptionVariables = {
  filter?: ModelSubscriptionChatRoomFilterInput | null,
  owner?: string | null,
};

export type OnCreateChatRoomSubscription = {
  onCreateChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    name: string,
    description?: string | null,
    messages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contributors?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateChatRoomSubscriptionVariables = {
  filter?: ModelSubscriptionChatRoomFilterInput | null,
  owner?: string | null,
};

export type OnUpdateChatRoomSubscription = {
  onUpdateChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    name: string,
    description?: string | null,
    messages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contributors?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteChatRoomSubscriptionVariables = {
  filter?: ModelSubscriptionChatRoomFilterInput | null,
  owner?: string | null,
};

export type OnDeleteChatRoomSubscription = {
  onDeleteChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    name: string,
    description?: string | null,
    messages?:  {
      __typename: "ModelChatMessageConnection",
      items:  Array< {
        __typename: "ChatMessage",
        id: string,
        message: string,
        description?: string | null,
        published?: boolean | null,
        chatRoomMessagesId: string,
        userMessagesId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatMessagesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contributors?:  {
      __typename: "ModelUserChatRoomConnection",
      items:  Array< {
        __typename: "UserChatRoom",
        id: string,
        userId?: string | null,
        chatRoomId?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt?: string | null,
        userChatRoomsId?: string | null,
        chatRoomContributorsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateUserChatRoomSubscriptionVariables = {
  filter?: ModelSubscriptionUserChatRoomFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserChatRoomSubscription = {
  onCreateUserChatRoom?:  {
    __typename: "UserChatRoom",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    chatRoomId?: string | null,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatRoomsId?: string | null,
    chatRoomContributorsId?: string | null,
  } | null,
};

export type OnUpdateUserChatRoomSubscriptionVariables = {
  filter?: ModelSubscriptionUserChatRoomFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserChatRoomSubscription = {
  onUpdateUserChatRoom?:  {
    __typename: "UserChatRoom",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    chatRoomId?: string | null,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatRoomsId?: string | null,
    chatRoomContributorsId?: string | null,
  } | null,
};

export type OnDeleteUserChatRoomSubscriptionVariables = {
  filter?: ModelSubscriptionUserChatRoomFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserChatRoomSubscription = {
  onDeleteUserChatRoom?:  {
    __typename: "UserChatRoom",
    id: string,
    userId?: string | null,
    user?:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    chatRoomId?: string | null,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatRoomsId?: string | null,
    chatRoomContributorsId?: string | null,
  } | null,
};

export type OnCreateChatMessageSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessageFilterInput | null,
  owner?: string | null,
};

export type OnCreateChatMessageSubscription = {
  onCreateChatMessage?:  {
    __typename: "ChatMessage",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    chatRoomMessagesId: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatMessagesId?: string | null,
  } | null,
};

export type OnUpdateChatMessageSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessageFilterInput | null,
  owner?: string | null,
};

export type OnUpdateChatMessageSubscription = {
  onUpdateChatMessage?:  {
    __typename: "ChatMessage",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    chatRoomMessagesId: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatMessagesId?: string | null,
  } | null,
};

export type OnDeleteChatMessageSubscriptionVariables = {
  filter?: ModelSubscriptionChatMessageFilterInput | null,
  owner?: string | null,
};

export type OnDeleteChatMessageSubscription = {
  onDeleteChatMessage?:  {
    __typename: "ChatMessage",
    id: string,
    message: string,
    description?: string | null,
    published?: boolean | null,
    chatRoomMessagesId: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      name: string,
      description?: string | null,
      messages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      contributors?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    userMessagesId: string,
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    editors?: Array< string | null > | null,
    groupsCanAccess: Array< string | null >,
    createdAt: string,
    updatedAt?: string | null,
    userChatMessagesId?: string | null,
  } | null,
};

export type OnCreateUserCoursesSubscriptionVariables = {
  filter?: ModelSubscriptionUserCoursesFilterInput | null,
  owner?: string | null,
  editors?: string | null,
};

export type OnCreateUserCoursesSubscription = {
  onCreateUserCourses?:  {
    __typename: "UserCourses",
    id: string,
    courseID: string,
    userID: string,
    course:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type OnUpdateUserCoursesSubscriptionVariables = {
  filter?: ModelSubscriptionUserCoursesFilterInput | null,
  owner?: string | null,
  editors?: string | null,
};

export type OnUpdateUserCoursesSubscription = {
  onUpdateUserCourses?:  {
    __typename: "UserCourses",
    id: string,
    courseID: string,
    userID: string,
    course:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type OnDeleteUserCoursesSubscriptionVariables = {
  filter?: ModelSubscriptionUserCoursesFilterInput | null,
  owner?: string | null,
  editors?: string | null,
};

export type OnDeleteUserCoursesSubscription = {
  onDeleteUserCourses?:  {
    __typename: "UserCourses",
    id: string,
    courseID: string,
    userID: string,
    course:  {
      __typename: "Course",
      description: string,
      id: string,
      image?: string | null,
      name: string,
      published?: boolean | null,
      urlProduct?: string | null,
      courseSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      workspaces?:  {
        __typename: "ModelWorkspaceConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    user:  {
      __typename: "User",
      cognitoId: string,
      id: string,
      userSchoolId?: string | null,
      topics?:  {
        __typename: "ModelTopicConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: "ModelAnswerConnection",
        nextToken?: string | null,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      courses?:  {
        __typename: "ModelUserCoursesConnection",
        nextToken?: string | null,
      } | null,
      chatRooms?:  {
        __typename: "ModelUserChatRoomConnection",
        nextToken?: string | null,
      } | null,
      chatMessages?:  {
        __typename: "ModelChatMessageConnection",
        nextToken?: string | null,
      } | null,
      address?: string | null,
      firstname?: string | null,
      lastname?: string | null,
      username: string,
      birthday?: string | null,
      company?: string | null,
      contact?: string | null,
      email?: string | null,
      image?: string | null,
      website?: string | null,
      instagramId?: string | null,
      facebookId?: string | null,
      linkedInId?: string | null,
      twitterId?: string | null,
      youtubeId?: string | null,
      googleId?: string | null,
      githubId?: string | null,
      isFrequent?: boolean | null,
      isStarred?: boolean | null,
      label?: number | null,
      notes?: string | null,
      registered?: boolean | null,
      website2?: string | null,
      status: string,
      owner: string,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      roles?: Array< string | null > | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type OnCreateQuestionTagsSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTagsFilterInput | null,
  owner?: string | null,
};

export type OnCreateQuestionTagsSubscription = {
  onCreateQuestionTags?:  {
    __typename: "QuestionTags",
    id: string,
    fAQTagID: string,
    questionID: string,
    fAQTag:  {
      __typename: "FAQTag",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      questions?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    question:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type OnUpdateQuestionTagsSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTagsFilterInput | null,
  owner?: string | null,
};

export type OnUpdateQuestionTagsSubscription = {
  onUpdateQuestionTags?:  {
    __typename: "QuestionTags",
    id: string,
    fAQTagID: string,
    questionID: string,
    fAQTag:  {
      __typename: "FAQTag",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      questions?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    question:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};

export type OnDeleteQuestionTagsSubscriptionVariables = {
  filter?: ModelSubscriptionQuestionTagsFilterInput | null,
  owner?: string | null,
};

export type OnDeleteQuestionTagsSubscription = {
  onDeleteQuestionTags?:  {
    __typename: "QuestionTags",
    id: string,
    fAQTagID: string,
    questionID: string,
    fAQTag:  {
      __typename: "FAQTag",
      id: string,
      name: string,
      description?: string | null,
      published?: boolean | null,
      questions?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    },
    question:  {
      __typename: "Question",
      id: string,
      questionAuthorId: string,
      author:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      answerAuthorId?: string | null,
      answerAuthor?:  {
        __typename: "User",
        cognitoId: string,
        id: string,
        userSchoolId?: string | null,
        address?: string | null,
        firstname?: string | null,
        lastname?: string | null,
        username: string,
        birthday?: string | null,
        company?: string | null,
        contact?: string | null,
        email?: string | null,
        image?: string | null,
        website?: string | null,
        instagramId?: string | null,
        facebookId?: string | null,
        linkedInId?: string | null,
        twitterId?: string | null,
        youtubeId?: string | null,
        googleId?: string | null,
        githubId?: string | null,
        isFrequent?: boolean | null,
        isStarred?: boolean | null,
        label?: number | null,
        notes?: string | null,
        registered?: boolean | null,
        website2?: string | null,
        status: string,
        owner: string,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        roles?: Array< string | null > | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      title: string,
      rewording?: string | null,
      description?: string | null,
      questionVideoUrl?: string | null,
      thumb?: string | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "FAQCategory",
        id: string,
        name: string,
        description?: string | null,
        published?: boolean | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      tags?:  {
        __typename: "ModelQuestionTagsConnection",
        nextToken?: string | null,
      } | null,
      file?: string | null,
      answer?:  {
        __typename: "Answer",
        id: string,
        userAnswersId: string,
        title?: string | null,
        description?: string | null,
        urlVideo?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      answerUrl?: string | null,
      answerContent?: string | null,
      answerVideoId?: string | null,
      answerVideoProvider?: string | null,
      status: string,
      questionsSchoolId: string,
      school?:  {
        __typename: "School",
        id: string,
        description: string,
        domain?: string | null,
        emailsupport?: string | null,
        lang?: string | null,
        message?: string | null,
        name: string,
        url?: string | null,
        welcomeMessage?: string | null,
        image?: string | null,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      fAQConsultationQuestionsId?: string | null,
      faqConsultation?:  {
        __typename: "FAQConsultation",
        id: string,
        title: string,
        description?: string | null,
        status?: string | null,
        consultationSchoolId: string,
        owner?: string | null,
        editors?: Array< string | null > | null,
        groupsCanAccess: Array< string | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      editors?: Array< string | null > | null,
      groupsCanAccess: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      userQuestionsId?: string | null,
      questionCategoryId?: string | null,
      questionAnswerId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    editors?: string | null,
  } | null,
};
