import {useMutation, useQuery} from 'react-query'
import {queryClient} from '../../config/react-query'
import {
  getMe,
  resetPassword,
  resetPasswordSubmitCode,
  signIn,
  signOut,
  updateUserMe,
} from '../clients'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types'

export const useSignIn = () => {
  const {setError: setGlobalError} = useGlobalContext()
  return useQuery('signIn', signIn as any, {
    onSuccess: (data: any) => {
      queryClient.setQueryData('auth', data?.user)
    },
    onError: (error: Error) => {
      console.error('erreur lors de la mutation useUpdateMe', error)
      setGlobalError(error.message)
    },
  })
}

export const useMe = () => {
  const query = useQuery('me', getMe, {retry: false})
  useGlobalLoading(query.isLoading)
  return {...query, me: query.data}
}

export const useUpdateMe = () => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateUserMe, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch

      // todo : commenté a cause du problème IMAGE AVATAR dans account
      // todo : verifier les impacts
      queryClient.invalidateQueries('me')
      queryClient.invalidateQueries('currentAuthenticatedUser')
      fetchEnd()
      setMessage('Votre profil a été mis à jour')
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation useUpdateMe', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
    },
    onSettled: (data, error, variables, context) => {},
  })
  return mutation
}

export const useResetpassword = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(resetPassword, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: async () => {
      // Invalidate and refetch
      await signOut()
      queryClient.invalidateQueries()
      fetchEnd()
      setMessage('Vérifiez votre boite email')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation useUpdateMe', error)
      fetchEnd()
      setGlobalError(error.message)
    },
    onSettled: (data, error, variables, context) => {},
  })
  return mutation
}

export const useResetpasswordSubmitCode = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(resetPasswordSubmitCode, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: async () => {
      // Invalidate and refetch
      await signOut()
      queryClient.invalidateQueries()

      fetchEnd()
      setMessage('Nouveau mot de passe mis à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation useUpdateMe', error)
      fetchEnd()
      setGlobalError(error.message)
    },
    onSettled: (data, error, variables, context) => {},
  })
  return mutation
}
