import ts from 'typescript'
import {EStatus} from '../../types'
import {
  ChatRoom,
  ModelUserChatRoomConnection,
  UserChatRoom,
  UserCourses,
  Workspace,
} from '../types/API'
import {
  GraphQLCourseCommonResult,
  GraphQLUserCommonResult,
} from '../types/graphQLCommonTypes'
import {
  errorGql,
  responseGeneric,
  responseList,
  responseListUserChatRooms,
  responseListUserCourse,
} from './zodHelper'

export const extractTopics = (workspaces: Workspace[]) => {
  const topics = workspaces?.reduce((allFeatures: any, workspace) => {
    const topicsWs = workspace?.topics?.items ?? []
    return [...allFeatures, ...topicsWs]
  }, [])
  return topics
}

//filter on groupsCanAccess field
export const buildFilterBySchool = (schoolGroupId: string) => {
  return {
    groupsCanAccess: {
      contains: `${schoolGroupId}`,
    },
  }
}
//filter on questionsSchoolId field
export const buildFilterByQuestionsSchoolId = (schoolId: string) => {
  return {
    questionsSchoolId: {
      eq: `${schoolId}`,
    },
  }
}

export const buildFilterById = (id?: string) => {
  //return {...(id && {id: {eq: id}})} //conditionnal object property pattern - empty objecty
  return id && {...{id: {eq: id}}} //conditionnal object property pattern - undefined
}

export const buildFilterOwner = (ownerId?: string) => {
  return {
    owner: {
      contains: `${ownerId}`,
    },
  }
}

export const buildFilterName = (name?: string) => {
  if (name) {
    return {
      name: {
        contains: `${name?.toLocaleLowerCase()}`,
      },
    }
  }
}

export const buildFilterSearchQuestion = (searchTerm?: string) => {
  if (searchTerm) {
    return {
      title: {
        contains: `${searchTerm?.toLocaleLowerCase()}`,
      },
      // description: {
      //   contains: `${searchTerm}`,
      // },
    }
  }
}

export const buildFilterSearchUser = (searchTerm?: string) => {
  if (searchTerm?.includes('@')) {
    return {
      email: {
        contains: `${searchTerm?.toLocaleLowerCase()}`,
      },
    }
  } else {
    return {
      username: {
        contains: `${searchTerm?.toLocaleLowerCase()}`,
      },
    }
  }
}

//ajoute la prorpieté status pour le filtre ou rien si status est undefined
// s'utilise ensuite ...buildFilterBySatus(status),
export const buildFilterBySatus = (status?: EStatus) => {
  return status && {...{status: {eq: status}}}
}

//GRAPHQL ERRORS HANDLING

// - parse error avec zod
// - Check la presense d'une ou plusieurs erreur en particulier a traiter
// - filer null row
export const handleUserCourseError = (
  errAndData: unknown,
  errorsCheck: string[],
) => {
  const resp = responseListUserCourse.parse(errAndData)
  // y a-t-il des errors après le filtre ?
  if (containErrorAfterFilter(resp, errorsCheck)) {
    // oui ? on ne fait rien, on remonte les errors
    console.warn('Intercept GraphQL Error, aucun traitement ', resp)
    return Promise.reject(resp)
  } else if (checkGraphqlError(resp.errors, errorsCheck)) {
    //contient uniquement les erreurs spécifiées, on va pouvoir traiter
    console.warn(
      'Intercept GraphQL Error, supression des erreurs ',
      errorsCheck,
    )
    const data = filterNullRowUserCourse(resp.data as GraphQLCourseCommonResult)
    return Promise.resolve(data.listUserCourses)
  } else {
    //default case
    return Promise.reject(resp.errors)
  }
}

export const handleUserChatRoomsError = (
  errAndData: any,
  errorsCheck: string[],
) => {
  //Cas d'une error strandard (non API)
  if (errAndData?.message === 'No current user') {
    return Promise.resolve({items: []})
  }
  const resp = responseListUserChatRooms.parse(errAndData)
  // y a-t-il des errors après le filtre ?
  if (containErrorAfterFilter(resp, errorsCheck)) {
    // oui ? on ne fait rien, on remonte les errors
    console.warn('Intercept GraphQL Error, aucun traitement ', resp)
    return Promise.reject(resp)
  } else if (checkGraphqlError(resp.errors, errorsCheck)) {
    //contient uniquement les erreurs spécifiées, on va pouvoir traiter
    console.warn(
      'Intercept GraphQL Error, supression des erreurs ',
      errorsCheck,
    )

    filterNullRow<UserChatRoom>(resp.data.me.chatRooms)
    return Promise.resolve(resp.data.me.chatRooms)
  } else {
    //default case
    return Promise.reject(resp.errors)
  }
}
/**
 *
 * @param errAndData
 * @param errorsCheck
 * @param itemName
 * @returns
 */
export const handleGraphQLError = <T extends object>(
  errAndData: unknown,
  errorsCheck: string[],
  itemName: string,
) => {
  const resp = responseGeneric.parse(errAndData)
  // y a-t-il des errors après le filtre ?
  if (containErrorAfterFilter(resp, errorsCheck)) {
    // oui ? on ne fait rien, on remonte les errors
    console.warn('Intercept GraphQL Error, aucun traitement ', resp)
    return Promise.reject(resp)
  } else if (checkGraphqlError(resp.errors, errorsCheck)) {
    //contient uniquement les erreurs spécifiées, on va pouvoir traiter
    console.warn(
      'Intercept GraphQL Error, supression des erreurs ',
      errorsCheck,
    )
    const data = resp.data[itemName]
    if (Array.isArray(data)) {
      filterNullRow<T>(resp.data[itemName])
    }
    return Promise.resolve(resp.data[itemName])
  } else {
    //default case
    return Promise.reject(resp.errors)
  }
}

export const checkGraphqlError = (err: any, errorsCheck: string[]) => {
  const errors = errorGql.parse(err)
  let errFound = false
  errorsCheck.some(msg => {
    errFound = errors.some(err => err?.message?.includes(msg))
    if (errFound === true) return false
  })
  return errFound
}

export const filterGraphqlError = (err: any, errorsCheck: string[]) => {
  const errors = errorGql.parse(err)
  //console.log('errors', errors)
  const filteredErrors: any[] = []
  errorsCheck.map(msg => {
    return filteredErrors.push(
      ...errors.filter(err => !err?.message?.includes(msg)),
    )
  })
  return filteredErrors
}

export const containErrorAfterFilter = (err: any, errorsCheck: string[]) => {
  const resp = responseList.parse(err)
  const filteredErrors: any[] = []
  if (checkGraphqlError(resp.errors, errorsCheck)) {
    filteredErrors.push(...filterGraphqlError(resp.errors as any, errorsCheck))
    return filteredErrors.length > 0
  } else {
    return resp.errors.length > 0
  }
}

//todo rendre generics
export const filterNullRowUserCourse = (data: GraphQLCourseCommonResult) => {
  const items = data?.listUserCourses?.items.filter(course => course !== null)
  if (data?.listUserCourses?.items) {
    data.listUserCourses.items = items as [UserCourses]
  }
  return data
}

export const filterNullRowChatRooms = (data: GraphQLUserCommonResult) => {
  const items = data?.me?.chatRooms?.items.filter(course => course !== null)
  if (data?.me?.chatRooms?.items) {
    data.me.chatRooms.items = items as UserChatRoom[]
  }
  return data
}

export const filterNullRow = <T extends object>(obj: {items: Array<T>}) => {
  const items = obj.items.filter(item => item !== null)
  obj.items = items
}

export interface AwsIDObject {
  id: string
}

/**
 * fonctionne pour un object contenant 'items' qui contiens un tableau d'object contenant un sous object : exemple 'chatRoom'
 * exemple
 * data = {items: [{chatRoom: {id: '1'}}, {chatRoom: {id: '1'}}, {chatRoom: {id: '2'}}]}
 * const chatRooms = filterDuplicateItemsSub(data, 'chatRoom')
 *
 * @param arr
 * @param objectName
 * @returns
 */
export const filterDuplicateItemsSub = (
  arr?: {items?: AwsIDObject[]},
  objectName?: string,
): AwsIDObject[] | undefined => {
  let filtered
  if (objectName) {
    filtered = arr?.items?.filter((item, index, self) => {
      return (
        index ===
        self.findIndex(
          other => other[objectName ?? ''].id === item[objectName ?? ''].id,
        )
      )
    })
  } else {
    filtered = arr?.items?.filter((item, index, self) => {
      return index === self.findIndex(other => other.id === item.id)
    })
  }

  if (arr?.items) {
    arr.items = filtered as AwsIDObject[]
  }
  return filtered
}
