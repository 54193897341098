import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  useColorModeValue,
} from '@chakra-ui/react'
import {Link, useNavigate} from 'react-router-dom'
import Searchbar from '../../../components/SearchBar/Searchbar'
import {Box, Text, Button} from '../../../components/ui'
import {useAuth} from '../../../context/AuthContext'

type RestrictedProps = {
  message?: string
}
export const Restricted = ({message}: RestrictedProps) => {
  const navigate = useNavigate()
  const handleOnSearch = e => {
    navigate(`/school/faq?question=${e}`)
  }
  const imageUrl = useColorModeValue(
    '/images/lock-light.png',
    '/images/lock-dark.png',
  )
  const {authUser} = useAuth()
  return (
    <>
      {
        <Box
          bgSize="cover"
          minH="70vh"
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={5}
        >
          <img src={imageUrl} alt="restricted" />
          <Text variant={'textTitle'}>Vous n'êtes pas autorisé ?</Text>
          <Text variant={'authorItalic'}>Zone Réservée</Text>
          {message ? (
            <Alert status="warning" maxW={500}>
              <AlertIcon />
              <AlertTitle mr={2}>Attention</AlertTitle>
              <AlertDescription>{message}</AlertDescription>
            </Alert>
          ) : null}
          {authUser ? (
            <>
              <Box w={{base: '300px', md: '400px'}}>
                <Searchbar
                  placeholder="Chercher une question..."
                  onSearch={handleOnSearch}
                  tooltipMessage="Cliquez ici pour rechercher"
                />
              </Box>

              <Link to="/">
                <Button>Retour à l'Accueil</Button>
              </Link>
            </>
          ) : (
            ''
          )}
        </Box>
      }
    </>
  )
}

export default Restricted
