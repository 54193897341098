import {EStatus} from '../../types/graphQL'
import {Select, SelectionProps} from '../ui'
import {getEStatusDetails} from '../../commons/helpers/screen'

export const SelectStatus = ({...rest}: SelectionProps) => {
  const statusArray = Object.values(EStatus)
  return (
    <Select maxW="368px" {...rest}>
      <option value="">Pas de filtre</option>
      {statusArray?.map((option, idx) => (
        <option key={idx} value={option}>
          {getEStatusDetails(option).label}
        </option>
      ))}
    </Select>
  )
}

export default SelectStatus
