import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from './components/ErrorFallback'
import {RouterPages} from './routes'
import {AppProviders} from './context/'
import AppSuspense from './components/AppSuspense'

export const App = () => {
  return (
    <Router>
      <AppProviders>
        <AppSuspense>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <RouterPages />
          </ErrorBoundary>
        </AppSuspense>
      </AppProviders>
    </Router>
  )
}

export default App
