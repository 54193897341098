import {ContainerPage} from '../../../components/ui'
import {useAuth} from '../../../context/AuthContext'
import {WorkspaceList as WorkspaceTable} from '../../../components/Workspaces'
import {Workspace} from '../../../commons/types/API'
import {useWorkspacesBySchoolId} from '../../../commons/api/hooks/workspace'

export const WorkspaceList = () => {
  const {schoolId} = useAuth()
  const {workspaces, isLoading} = useWorkspacesBySchoolId({
    input: {workspaceSchoolId: schoolId},
    custom: false,
  })

  return (
    <ContainerPage>
      <WorkspaceTable
        workspaces={workspaces?.items as [Workspace]}
        isLoading={isLoading}
      />
    </ContainerPage>
  )
}
