import {Skeleton} from '@chakra-ui/react'
import {Container} from '../ui'

export const TextRowSkeleton = () => {
  const skeletonProps = {h: 3, my: 2}
  return (
    <Container my="2" px="6" py="4" variant="generalBg" boxShadow="lg">
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} w="80%" />
    </Container>
  )
}
export default TextRowSkeleton
