import React from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {ColorModeScript} from '@chakra-ui/react'
import theme from './theme'

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MSW === 'true'
) {
  const {worker} = require('./mocks/browser')
  worker.start()
} //To do implement ENV TEST

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
