import {GraphQLResult} from '@aws-amplify/api-graphql'
import {API, graphqlOperation} from 'aws-amplify'
import {
  getQuestionCustom,
  listQuestionsCustom,
  questionsByAuthorCustom,
} from '../../graphql/custom/queries'
import {
  createFAQTag as createFAQTagMutation,
  createQuestion as createQuestionMutation,
  createFAQCategory as createFAQCategoryMutation,
  deleteFAQTag as deleteFAQTagMutation,
  deleteQuestion as deleteQuestionMutation,
  deleteFAQCategory as deleteFAQCategoryMutation,
  updateFAQTag as updateFAQTagMutation,
  updateQuestion as updateQuestionMutation,
  updateFAQCategory as updateFAQCategoryMutation,
  createQuestionTags as createQuestionTagsMutation,
  deleteQuestionTags as deleteQuestionTagsMutation,
  updateQuestionTags as updateQuestionTagsMutation,
  createComment as createCommentMutation,
  deleteComment as deleteCommentMutation,
  updateComment as updateCommentMutation,
} from '../../graphql/mutations'
import {
  getFAQCategory,
  getFAQTag,
  getQuestion,
  listFAQCategories,
  listQuestionTags,
  listFAQTags,
  listQuestions,
  getQuestionTags as getQuestionTagsQuerie,
  questionsBySchool,
  questionsBySchoolTitle,
  questionsByAuthor,
  questionsByDate,
  questionsByConsultation,
} from '../../graphql/queries'
import {
  CreateFAQCategoryInput,
  CreateFAQTagInput,
  CreateQuestionTagsInput,
  CreateQuestionInput,
  FAQCategory,
  FAQTag,
  QuestionTags,
  ListFAQCategoriesQueryVariables,
  ListFAQTagsQueryVariables,
  ListQuestionsQueryVariables,
  Question,
  UpdateFAQCategoryInput,
  UpdateQuestionTagsInput,
  UpdateQuestionInput,
  ListQuestionTagsQueryVariables,
  DeleteQuestionTagsInput,
  QuestionsBySchoolQueryVariables,
  QuestionsBySchoolTitleQueryVariables,
  CreateCommentInput,
  UpdateCommentInput,
  Comment,
  QuestionsByAuthorQueryVariables,
  QuestionsByDateQueryVariables,
  ModelQuestionConnection,
  QuestionsByConsultationQueryVariables,
} from '../../types/API'

interface GraphQLWorkspaceCommonResult {
  listQuestions?: ModelQuestionConnection
  questionsBySchool?: {items: [Question]; nextToken: any}
  questionsByAuthor?: {items: [Question]; nextToken: any}
  questionsBySchoolTitle?: {items: [Question]; nextToken: any}
  questionsByDate?: ModelQuestionConnection
  questionsByConsultation?: ModelQuestionConnection
  getQuestion?: Question
  updateQuestion?: Question
  createQuestion?: Question
  deleteQuestion?: Question
  listFAQTags?: {items: [FAQTag]; nextToken: any}
  getFAQTag?: FAQTag
  updateFAQTag?: FAQTag
  createFAQTag?: FAQTag
  deleteFAQTag?: FAQTag
  listFAQCategories?: {items: [FAQCategory]; nextToken: any}
  getFAQCategory?: FAQCategory
  updateFAQCategory?: FAQCategory
  createFAQCategory?: FAQCategory
  deleteFAQCategory?: FAQCategory
  listFAQTagQuestionss?: {items: [QuestionTags]; nextToken: any}
  getQuestionTags?: QuestionTags
  updateQuestionTags?: QuestionTags
  createQuestionTags?: QuestionTags
  deleteQuestionTags?: QuestionTags
  updateComment?: Comment
  createComment?: Comment
  deleteComment?: Comment
}

export const getQuestions = async (input?: ListQuestionsQueryVariables) => {
  const isCustom = true
  const operation = isCustom ? listQuestionsCustom : listQuestions
  const data = await (API.graphql(
    graphqlOperation(operation, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.listQuestions
}

export const getQuestionById = async (id: string) => {
  const isCustom = true
  const operation = isCustom ? getQuestionCustom : getQuestion

  const data = await (API.graphql(graphqlOperation(operation, {id})) as Promise<
    GraphQLResult<GraphQLWorkspaceCommonResult>
  >)
  return data?.data?.getQuestion
}

export const getQuestionsByScoolId = async (
  input: QuestionsBySchoolQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(questionsBySchool, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.questionsBySchool
}

export const getQuestionsByAuthor = async (
  input: QuestionsByAuthorQueryVariables,
) => {
  const isCustom = true
  const operation = isCustom ? questionsByAuthorCustom : questionsByAuthor
  const data = await (API.graphql(
    graphqlOperation(operation, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.questionsByAuthor
}

export const getQuestionsByScoolIdtitle = async (
  input: QuestionsBySchoolTitleQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(questionsBySchoolTitle, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.questionsBySchoolTitle
}

export const getQuestionsByStatusSortByDate = async (
  input: QuestionsByDateQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(questionsByDate, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.questionsByDate
}
export const getQuestionsByConsultationId = async (
  input: QuestionsByConsultationQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(questionsByConsultation, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.questionsByConsultation
}

// export const getQuestionsByScoolId = async (schoolId: string) => {
//   const data = await (API.graphql(
//     graphqlOperation(getQuestion, {id: schoolId}),
//   ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
//   return data?.data?.getQuestion
// }

export const createQuestion = async (question?: CreateQuestionInput) => {
  delete question?.id
  const data = await (API.graphql(
    graphqlOperation(createQuestionMutation, {input: question}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.createQuestion
}
/**
 *
 * @param question Create a question with TAGS
 * @param tags
 * @returns
 */
export const createQuestionAndTags = async ({
  question,
  tags,
}: {
  question?: CreateQuestionInput
  tags?: CreateQuestionTagsInput[]
}) => {
  delete question?.id
  const data = await (API.graphql(
    graphqlOperation(createQuestionMutation, {input: question}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  const tagsAddResult: any[] = []
  tags?.map(async tag => {
    tag.questionID = data?.data?.createQuestion?.id as string
    const res = await createQuestionTags(tag)
    tagsAddResult.push(res)
  })

  return {
    createQuestion: data?.data?.createQuestion,
    tags: tagsAddResult,
  }
}

export const updateQuestion = async (question?: UpdateQuestionInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateQuestionMutation, {input: question}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.updateQuestion
}

export const deleteQuestion = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteQuestionMutation, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.deleteQuestion
}

//FAQTAG

export const getFAQTags = async (input?: ListFAQTagsQueryVariables) => {
  const data = await (API.graphql(
    graphqlOperation(listFAQTags, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.listFAQTags
}

export const getFAQTagById = async (id: string) => {
  const data = await (API.graphql(graphqlOperation(getFAQTag, {id})) as Promise<
    GraphQLResult<GraphQLWorkspaceCommonResult>
  >)
  return data?.data?.getFAQTag
}

export const getFAQTagByScoolId = async (schoolId: string) => {
  const data = await (API.graphql(
    graphqlOperation(getFAQTag, {id: schoolId}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getFAQTag
}

export const createFAQTag = async (question?: CreateFAQTagInput) => {
  delete question?.id
  const data = await (API.graphql(
    graphqlOperation(createFAQTagMutation, {input: question}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.createFAQTag
}

export const updateFAQTag = async (question?: CreateFAQTagInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateFAQTagMutation, {input: question}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.updateFAQTag
}

export const deleteFAQTag = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteFAQTagMutation, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.deleteFAQTag
}

//FAQCategory

export const getFAQCategories = async (
  input?: ListFAQCategoriesQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(listFAQCategories, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.listFAQCategories
}

export const getFAQCategoryById = async (id: string) => {
  const data = await (API.graphql(
    graphqlOperation(getFAQCategory, {id}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getFAQTag
}

export const getFAQCategoryByScoolId = async (schoolId: string) => {
  const data = await (API.graphql(
    graphqlOperation(listFAQCategories, {id: schoolId}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getFAQCategory
}

export const createFAQCategory = async (question?: CreateFAQCategoryInput) => {
  delete question?.id
  const data = await (API.graphql(
    graphqlOperation(createFAQCategoryMutation, {input: question}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.createFAQCategory
}

export const updateFAQCategory = async (question?: UpdateFAQCategoryInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateFAQCategoryMutation, {input: question}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.updateFAQCategory
}

export const deleteFAQCategory = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteFAQCategoryMutation, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.deleteFAQCategory
}

//QuestionTag

export const getQuestionsTag = async (
  input?: ListQuestionTagsQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(listQuestionTags, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.listFAQCategories
}

export const getQuestionTagsById = async (id: string) => {
  const data = await (API.graphql(
    graphqlOperation(getQuestionTagsQuerie, {id}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getFAQTag
}

export const getQuestionTagsByScoolId = async (schoolId: string) => {
  const data = await (API.graphql(
    graphqlOperation(listQuestionTags, {id: schoolId}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.listFAQTagQuestionss
}

export const createQuestionTags = async (input?: CreateQuestionTagsInput) => {
  const data = await (API.graphql(
    graphqlOperation(createQuestionTagsMutation, {input: input}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.createQuestionTags
}

export const updateQuestionTags = async (
  question?: UpdateQuestionTagsInput,
) => {
  const data = await (API.graphql(
    graphqlOperation(updateQuestionTagsMutation, {input: question}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.updateQuestionTags
}

export const deleteQuestionTags = async (input?: DeleteQuestionTagsInput) => {
  const data = await (API.graphql(
    graphqlOperation(deleteQuestionTagsMutation, {input}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.deleteQuestionTags
}

// Comment

export const createComment = async (comment?: CreateCommentInput) => {
  delete comment?.id
  const data = await (API.graphql(
    graphqlOperation(createCommentMutation, {input: comment}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.createComment
}

export const updateComment = async (Comment?: UpdateCommentInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateCommentMutation, {input: Comment}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.updateComment
}

export const deleteComment = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteCommentMutation, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.deleteComment
}
