import {CreateChatRoomInput} from '../../commons/types/API'
import {useAuthDetails} from '../../context/AuthContext'
import {
  useCreateRoomAndAddUsers,
  useCreateRoomAddUsersSendMessage,
} from '../../hooks/messagerieActions'
import ChatRoomForm from '../Forms/ChatRoomForm'
import {ModalForm} from '../Modals'
import {Box, Heading, Stack} from '../ui'

type MessagerieActionsProps = {
  modalForm: string | null
  onCloseModalForm: () => void
}

function MessagerieActions({
  onCloseModalForm,
  modalForm,
}: MessagerieActionsProps) {
  const {uid} = useAuthDetails()
  const isGroupForm = modalForm === 'group'

  const {handleCreateChatRoomWithUser, isLoading: isLoadingCreateRoom} =
    useCreateRoomAndAddUsers({
      onSuccess: () => {
        onCloseModalForm()
      },
    })
  const {handleCreateRoomAddUserSendMessage, isLoading} =
    useCreateRoomAddUsersSendMessage({
      onSuccess: () => {
        onCloseModalForm()
      },
    })

  //on ne mixe pas le input de la room et les autres données
  const handleCreateNewConversation = (
    createRoomInput: CreateChatRoomInput,
    message: string,
  ) => {
    createRoomInput?.editors?.push(uid as string)
    //console.log('formData', formData)

    if (isGroupForm) {
      handleCreateChatRoomWithUser({
        values: createRoomInput,
      })
    } else {
      handleCreateRoomAddUserSendMessage({
        values: createRoomInput,
        message: message,
      })
    }
  }
  return (
    <ModalForm
      isOpen={!!modalForm}
      onClose={onCloseModalForm}
      onOpen={() => {}}
      title={'messagerie'}
    >
      <Box>
        <Stack spacing={4} mb={5}>
          <Heading>
            {isGroupForm ? 'Nouveau groupe' : 'Contacter un membre'}
          </Heading>
        </Stack>
        <ChatRoomForm
          isGroupForm={isGroupForm}
          onSubmit={handleCreateNewConversation}
          onClose={onCloseModalForm}
          initialValues={{
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          }}
          isLoading={isLoading || isLoadingCreateRoom}
        />
      </Box>
    </ModalForm>
  )
}

export default MessagerieActions
