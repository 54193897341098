import {useParams} from 'react-router-dom'
import {QuestionPreview} from '../../../components/FaqVideo/QuestionPreview'
import {ContainerPage} from '../../../components/ui'

export const QuestionPreviewPage = () => {
  const {questionId} = useParams()
  return (
    <ContainerPage>
      <QuestionPreview questionId={questionId}></QuestionPreview>
    </ContainerPage>
  )
}
