import {Flex, Skeleton} from '@chakra-ui/react'
import React from 'react'

export default function ListMessagesSkeleton() {
  return (
    <>
      <Flex gap={2} flexDir="column" minH={'45vh'}>
        <Skeleton w={'200px'} h={'35px'} rounded={3} />
        <Skeleton w={'250px'} h={'35px'} rounded={3} />
        <Skeleton w={'200px'} h={'35px'} rounded={3} ml={'auto'} />
        <Skeleton w={'200px'} h={'35px'} rounded={3} ml={'auto'} />
        <Skeleton w={'200px'} h={'35px'} rounded={3} />
        <Skeleton w={'250px'} h={'35px'} rounded={3} ml={'auto'} />
      </Flex>
    </>
  )
}
