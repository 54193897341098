import {useEffect} from 'react'
import {useMutation, useQuery} from 'react-query'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types'
import {queryClient} from '../../config/react-query'
import {
  TopicsBySchoolQueryVariables,
  TopicsByWorkspaceQueryVariables,
} from '../../types/API'
import {
  updateTopicById,
  getTopicByWorkspaceId,
  getTopicId,
  createMessage,
  deleteTopicById,
  createTopic,
  getTopicBySchoolId,
  getTopicsByWorkspaceId,
  deleteMessageById,
  updateMessageById,
} from '../clients'
import {usePagination} from './pagination'

export const useTopic = (topicId: string) => {
  const enabled = topicId ? true : false //to avoid API on undefined

  const {isLoading, data} = useQuery(
    `getTopicById-${topicId}`,
    () => getTopicId(topicId),
    {enabled},
  )
  useGlobalLoading(isLoading)
  return {topic: data}
}

/**
 * @deprecated
 * @param workspaceId
 * @returns
 */
export const useTopicsByWorkspaceIdOld = (workspaceId: string) => {
  const enabled = workspaceId ? true : false //to avoid API on undefined
  //console.log('enabled', enabled)

  const {isLoading, data} = useQuery(
    `getTopicsByWorkspaceId-${workspaceId}`,
    () => getTopicByWorkspaceId(workspaceId),
    {enabled: enabled},
  )
  useGlobalLoading(isLoading)
  return {topics: data}
}
export const useTopicsByWorkspaceId = ({
  input,
  custom,
}: {
  input: TopicsByWorkspaceQueryVariables
  custom: boolean
}) => {
  const enabled = input.workspaceTopicsId ? true : false //to avoid API on undefined
  //console.log('enabled', enabled)

  const {isLoading, data} = useQuery(
    [
      `getTopicsByWorkspaceId`,
      input.workspaceTopicsId,
      input.limit,
      input.nextToken,
    ],
    () => getTopicsByWorkspaceId({input, custom}),
    {enabled: enabled},
  )
  useGlobalLoading(isLoading)
  return {topics: data}
}

export const useTopicsBySchoolId = ({
  input,
  custom,
}: {
  input: TopicsBySchoolQueryVariables
  custom: boolean
}) => {
  const enabled = input.topicSchoolId ? true : false //to avoid API on undefined
  //console.log('enabled', enabled)

  const {isLoading, data} = useQuery(
    [`getTopicsBySchoolId`, input.topicSchoolId, input.limit, input.nextToken],
    () => getTopicBySchoolId(input, custom),
    {enabled: enabled},
  )
  useGlobalLoading(isLoading)
  return {topics: data}
}

export const useUpdateTopic = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = useMutation(updateTopicById, {
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries(`getTopicsByWorkspaceId`)
      queryClient.invalidateQueries([`getTopicsByWorkspaceId`])

      queryClient.invalidateQueries(
        `getTopicsByWorkspaceId-${data?.workspaceTopicsId}`,
      )
      queryClient.invalidateQueries(`getWorkspacesBySchoolIdCustom`)
      queryClient.invalidateQueries(`getWorkspacesBySchoolId`)
      queryClient.invalidateQueries(`getTopicsBySchoolId`)
      setMessage('Votre sujet à été mis à jour')
      onSuccess(data)
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
    },
  })
  return mutation
}

export const useCreateTopic = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = useMutation(createTopic, {
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries(`getTopicsByWorkspaceId`)
      queryClient.invalidateQueries([`getTopicsByWorkspaceId`])

      queryClient.invalidateQueries(
        `getTopicsByWorkspaceId-${data?.workspaceTopicsId}`,
      )
      queryClient.invalidateQueries(`getWorkspacesBySchoolIdCustom`)
      queryClient.invalidateQueries(`getWorkspacesBySchoolId`)
      queryClient.invalidateQueries(`getTopicsBySchoolId`)

      setMessage('Votre sujet à été créé')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteTopic = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = useMutation(deleteTopicById, {
    // onMutate
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(`getTopicsByWorkspaceId`)
      queryClient.invalidateQueries(`getWorkspacesBySchoolIdCustom`)
      queryClient.invalidateQueries(`getTopicsBySchoolId`)
      setMessage('Votre sujet à été supprimé')
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
    },
  })
  return mutation
}

export const useCreateMessage = (topicId: string) => {
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = useMutation(createMessage, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(`getTopicById-${topicId}`)
      queryClient.invalidateQueries(`getTopicsBySchoolId`)

      setMessage('Votre commentaire à été envoyé')
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
    },
  })
  return mutation
}

export const useDeleteMessage = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = useMutation(deleteMessageById, {
    // onMutate
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries(`getTopicById-${data?.topicMessagesId}`)
      queryClient.invalidateQueries(`getTopicsByWorkspaceId`)
      queryClient.invalidateQueries(`getWorkspacesBySchoolIdCustom`)
      setMessage('Votre commentaire à supprimé')
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
    },
  })
  return mutation
}

export const useUpdateMessage = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = useMutation(updateMessageById, {
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      //console.log('useUpdateMessage data', variables)

      queryClient.invalidateQueries(
        `getTopicById-${variables?.topicMessagesId}`,
      )
      queryClient.invalidateQueries(`getTopicsByWorkspaceId`)
      queryClient.invalidateQueries([`getTopicsByWorkspaceId`])

      queryClient.invalidateQueries(
        `getTopicsByWorkspaceId-${data?.workspaceTopicsId}`,
      )
      queryClient.invalidateQueries(`getWorkspacesBySchoolIdCustom`)
      queryClient.invalidateQueries(`getWorkspacesBySchoolId`)
      queryClient.invalidateQueries(`getTopicsBySchoolId`)
      setMessage('Votre commentaire à mis à jour')
      onSuccess(data)
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
    },
  })
  return mutation
}

export const useTopicsQuery = ({
  workspaceTopicsId,
  topicSchoolId,
  createdAt,
  sortDirection,
  filter,
  limit,
  custom = true,
}: (
  | {
      workspaceTopicsId: TopicsByWorkspaceQueryVariables['workspaceTopicsId']
      topicSchoolId: undefined
    }
  | {
      workspaceTopicsId: undefined
      topicSchoolId: TopicsBySchoolQueryVariables['topicSchoolId']
    }
) &
  Omit<TopicsByWorkspaceQueryVariables, 'workspaceTopicsId' | 'nextToken'> &
  Omit<TopicsBySchoolQueryVariables, 'topicSchoolId' | 'nextToken'> & {
    custom?: boolean
  }) => {
  const {
    nextToken,
    updateNextToken,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = usePagination()
  const queryKeyBase = workspaceTopicsId
    ? ['getTopicsByWorkspaceId', workspaceTopicsId]
    : ['getTopicsBySchoolId', topicSchoolId]
  const queryFn = workspaceTopicsId
    ? () =>
        getTopicsByWorkspaceId({
          input: {
            workspaceTopicsId,
            createdAt,
            sortDirection,
            filter,
            limit,
            nextToken,
          },
          custom,
        })
    : () =>
        getTopicBySchoolId(
          {
            topicSchoolId: topicSchoolId ?? '',
            createdAt,
            sortDirection,
            filter,
            limit,
            nextToken,
          },
          custom,
        )
  const enabled = !!workspaceTopicsId || !!topicSchoolId //to avoid API on undefined

  const {isLoading, data} = useQuery(
    [...queryKeyBase, sortDirection, limit, nextToken, filter],
    queryFn,
    {enabled},
  )

  useEffect(() => {
    updateNextToken(data?.nextToken)
  }, [data?.nextToken, updateNextToken])

  useGlobalLoading(isLoading)

  return {
    topics: data?.items,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  }
}
