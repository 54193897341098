import {Consultations} from '../../../components/Consultations'
import {ContainerPage} from '../../../components/ui'

export const ConsultationCategoriesPage = () => {
  return (
    <ContainerPage>
      <Consultations tabIdx={2}></Consultations>
    </ContainerPage>
  )
}
