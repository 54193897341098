import {Stack, Flex, Button, FormInput, HStack, FormTextArea} from '../ui'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {
  CreateFAQConsultationInput,
  UpdateFAQConsultationInput,
} from '../../commons/types/API'
import {EStatus} from '../../types/graphQL'
import {useAuthDetails} from '../../context/AuthContext'
import SelectStatus from '../Selects/select-status'
import {AlertHiddenFields} from './AlertHiddenFields'
import {formatDate, formatDateTime} from '../../commons/helpers/dataHelper'
import {
  dateFormat_M_MMM_yyyy_date,
  dateFormat_M_MMM_yyyy_HHmm,
} from '../../commons/constantes'

type ConsultationFormProps = {
  isEdit?: boolean
  initialValues?: UpdateFAQConsultationInput & CreateFAQConsultationInput
  onSubmit: (
    formValue: UpdateFAQConsultationInput & CreateFAQConsultationInput,
  ) => void
  isLoading?: boolean
  onClose?: () => void
  isModeConsultationStep: boolean
  isDisabled?: boolean
}

export const ConsultationForm = ({
  initialValues,
  onSubmit,
  isLoading,
  onClose,
  isEdit,
  isModeConsultationStep,
  isDisabled,
}: ConsultationFormProps) => {
  const {schoolGroupId, schoolId, uid} = useAuthDetails()

  const SignInSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    status: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    groupsCanAccess: Yup.array()
      .min(1, 'Il manque groupsCanAccess')
      .required('Champ obligatoire'),
    editors: Yup.array()
      .min(1, 'Il manque editor')
      .required('Champ obligatoire'),
    owner: Yup.string().test('owner', 'Il manque owner', value => !!value),
  })
  // console.log('initialValues', initialValues)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      title:
        initialValues?.title ??
        `Consultation du ${formatDate(
          new Date().toISOString(),
          dateFormat_M_MMM_yyyy_date,
        )}`,
      description:
        initialValues?.description ??
        `Consultation du ${formatDateTime(
          new Date().toISOString(),
          dateFormat_M_MMM_yyyy_HHmm,
        )}`,
      status: initialValues?.status ?? EStatus.NEW,
      consultationSchoolId: initialValues?.consultationSchoolId ?? schoolId,
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (
      values: UpdateFAQConsultationInput & CreateFAQConsultationInput,
    ) => {
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing="2">
        <FormInput
          placeholder="Consultation du 20 mars 2022"
          type="title"
          label="Nom de la consultation vidéo"
          name="title"
          id="title"
          onChange={e =>
            formik.setFieldValue('title', e.target.value.toLowerCase())
          }
          value={formik.values?.title.toLowerCase()}
          errorMessage={formik?.errors?.title}
        />
        <FormTextArea
          placeholder="description ..."
          // type="description"
          label="Description"
          name="description"
          id="description"
          onChange={formik.handleChange}
          value={formik.values?.description}
          errorMessage={formik?.errors?.description}
        />
        {isModeConsultationStep === false && (
          <SelectStatus
            label="status"
            name="status"
            id="status"
            onChange={formik.handleChange}
            value={formik.values?.status as string}
            errorMessage={formik?.errors?.status}
          />
        )}
        <Flex w="full" justifyContent={'flex-end'}>
          <HStack>
            <Button variant="outline" onClick={onClose}>
              Annuler
            </Button>
            <Button type="submit" fontSize="md" isDisabled={isDisabled}>
              {isLoading ? 'Loading' : isEdit ? 'Modifier' : 'Valider'}
            </Button>
          </HStack>
        </Flex>
      </Stack>
      <AlertHiddenFields
        formik={formik}
        fields={[
          'commentAuthorId',
          'userTopicsId',
          'owner',
          'editors',
          'groupsCanAccess',
        ]}
      />
    </form>
  )
}
export default ConsultationForm
