import {FormControl, SelectionProps} from '.'
import {Select, OptionBase, GroupBase} from 'chakra-react-select'
import {Text} from './../ui'
import {ComponentPropsWithoutRef} from 'react'
import {displayUserNameAndName} from '../../commons'

interface FlavorOrColorOption extends OptionBase {
  label: string
  value: any
  color?: string
  rating?: string
  colorScheme?: string
}
export const colorOptions = [
  'blue',
  'purple',
  'red',
  'orange',
  'yellow',
  'green',
  'cyan',
  'teal',
  'pink',
  'gray',
  'linkedin',
  'facebook',
  'messenger',
  'whatsapp',
  'twitter',
  'telegram',
  'redAlpha',
]

export enum MultiSelectType {
  DEFAULT,
  USERS,
  CATEGORIES,
  TAGS,
}
export type MultiSelectProps = SelectionProps &
  ComponentPropsWithoutRef<typeof FormControl> & {
    data?: any[]
    randomColor?: boolean
    closeMenuOnSelect?: boolean
    isMulti?: boolean
    type?: MultiSelectType
  }
export const MultiSelect = ({
  label,
  value,
  data,
  randomColor = true,
  errorMessage,
  onChange,
  placeholder,
  closeMenuOnSelect = true,
  isMulti = true,
  type = MultiSelectType.DEFAULT,
  ...rest
}: MultiSelectProps) => {
  const getLabel = (item: any) => {
    let label = 'non définie'
    switch (type) {
      case MultiSelectType.USERS:
        label = displayUserNameAndName(item)
        break
      case MultiSelectType.DEFAULT:
        label = item.username ?? item.name ?? item.title ?? 'non définie'
    }
    return label.replace(/\( \)/g, '')
  }

  const options = data?.map((item, idx) => {
    const label = getLabel(item)

    return {
      label: label,
      value: item,
      ...(randomColor && {colorScheme: colorOptions[idx] ?? 'blue'}),
    }
  })
  // `value` contient un array d'IDs (les valeurs selectionnés)
  // on parcours les data (options) et on prend celles qui match avec les IDs
  const selectedValues = options?.filter(option =>
    (value as string[])?.includes(option.value.id),
  )
  const handleChange = (data: any) => {
    onChange?.(data)
  }

  return (
    <FormControl {...rest}>
      {/* <FormLabel>{label}</FormLabel> */}
      <Text variant="label" mb={1}>
        {label}
      </Text>
      {isMulti && (
        <Select<FlavorOrColorOption, true, GroupBase<FlavorOrColorOption>>
          isMulti
          defaultValue={selectedValues}
          name="colors"
          options={options}
          placeholder={placeholder}
          closeMenuOnSelect={closeMenuOnSelect}
          onChange={handleChange}
        />
      )}
      {!isMulti && (
        <Select<FlavorOrColorOption, true, GroupBase<FlavorOrColorOption>>
          defaultValue={selectedValues}
          name="colors"
          options={options}
          placeholder={placeholder}
          closeMenuOnSelect={closeMenuOnSelect}
          onChange={handleChange}
        />
      )}
    </FormControl>
  )
}

export default MultiSelect
