import * as React from 'react'
import {useAuth} from '../../../context/AuthContext'
import {useNavigate} from 'react-router'
import {AuthContainerSimple, AuthPageType} from './AuthContainer'
import ResetPasswordForm, {
  ResetPasswordFormValues,
} from '../../../components/Forms/ResetPasswordForm'
import {
  useResetpassword,
  useResetpasswordSubmitCode,
} from '../../../commons/api/hooks'
import {useSearchParams} from 'react-router-dom'
import {RegisterPinFormValues} from '../../../types'

export const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const emailQueryParam = searchParams.get('email')
  const codeQueryParam = searchParams.get('code')

  const navigate = useNavigate()
  const [initial, setInitial] = React.useState<boolean>(
    codeQueryParam ? false : true,
  )
  const mutationReset = useResetpassword({onSuccess: () => setInitial(false)})
  const mutationSubmitCode = useResetpasswordSubmitCode({
    onSuccess: () => navigate('/login'),
  })

  const isLoading = mutationReset.isLoading || mutationSubmitCode.isLoading

  const handleSubmit = (data: ResetPasswordFormValues) => {
    const code = `${data.pin1}${data.pin2}${data.pin3}${data.pin4}${data.pin5}${data.pin6}`
    if (initial) {
      mutationReset.mutate(data?.email ?? '')
    } else {
      mutationSubmitCode.mutate({
        email: data?.email ?? '',
        code: code ?? '',
        password: data.password ?? '',
      })
    }
  }
  const pinCode: RegisterPinFormValues = {
    pin1: codeQueryParam?.[0] ?? '',
    pin2: codeQueryParam?.[1] ?? '',
    pin3: codeQueryParam?.[2] ?? '',
    pin4: codeQueryParam?.[3] ?? '',
    pin5: codeQueryParam?.[4] ?? '',
    pin6: codeQueryParam?.[5] ?? '',
  }
  return (
    <AuthContainerSimple
      label="Mot de passe oublié "
      pageType={AuthPageType.reset}
    >
      <ResetPasswordForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        initial={initial}
        initialValues={{pin: pinCode, email: emailQueryParam ?? ''}}
      />
    </AuthContainerSimple>
  )
}
export default ResetPassword
