import React from 'react'
import {useDeleteQuestion, useQuestionsByAuthor} from '../../commons/api/hooks'
import {
  buildFilterBySchool,
  buildFilterOwner,
  buildFilterSearchQuestion,
} from '../../commons/helpers/graphQLHelper'

import {
  useAuthUserId,
  useUserDetailSchoolGroupId,
} from '../../context/AuthContext'
import ModalContainer from 'react-modal-promise'
import HeaderTable from '../Table/HeaderTable'
import {Box, Container, Stack, Text} from '../ui'
import QuestionEditable from './QuestionEditable'
import {modalYesNoPromise} from '../Modals/YesNo'
import Pagination from '../Table/Pagination'
import {PAGINATION_TABLE_QUESTION} from '../../commons/constantes'
import {ROUTE_SCHOOL_FAQ_QUESTION_EDITOR} from '../../constantes'
import {useNavigate} from 'react-router-dom'
import {ModelSortDirection} from '../../commons/types/API'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import {AlertInfo} from '../ui/Alert'

export const UserFaqVideoMyQuestion = () => {
  const uid = useAuthUserId()
  const [searchTerm, setSearchTerm] = React.useState('')
  //pagination

  const schoolGroupId = useUserDetailSchoolGroupId()

  const mutationDeleteQuestion = useDeleteQuestion()

  const {
    questions,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = useQuestionsByAuthor({
    questionAuthorId: uid as string,
    sortDirection: ModelSortDirection.DESC,
    limit: searchTerm.length > 0 ? 1000 : PAGINATION_TABLE_QUESTION,
    filter: {
      ...buildFilterBySchool(schoolGroupId),
      ...buildFilterOwner(uid),
      ...buildFilterSearchQuestion(searchTerm),
    },
  })

  const navigate = useNavigate()

  const handleOpenQuestionEditor = () => {
    navigate(ROUTE_SCHOOL_FAQ_QUESTION_EDITOR)
  }

  const handleOpenUpdateQuestion = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      navigate(`${ROUTE_SCHOOL_FAQ_QUESTION_EDITOR}/${value}`)
    },
    [navigate],
  )

  const handleDeleteQuestion = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      const data = questions?.items?.find(elm => elm.id === value)
      modalYesNoPromise({
        title: 'Supprimer',
        header: data?.title,
        confirmLabel: 'Supprimer',
        data,
        labelOfType: 'prog',
        children: <div>Souhaitez vous vraiment supprimer la question</div>,
      })
        .then(() => {
          mutationDeleteQuestion.mutate(value)
        })
        .catch(error => {
          console.error(error)
        })
    },
    [questions, mutationDeleteQuestion],
  )

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    resetPages()
  }

  const hasQuestions = !!questions?.items?.length || hasNextPage || hasPrevPage
  return (
    <>
      <Container variant="generalBg">
        <Stack spacing={4}>
          <HeaderTable
            util="myquestion"
            onAdd={handleOpenQuestionEditor}
            onSearch={handleSearch}
          />
          {isLoading && <TextRowSkeleton />}
          {!isLoading && !hasQuestions && (
            <Box my={2}>
              <AlertInfo
                title={'Aucune question trouvée'}
                description={
                  'Vous devez poser une question pour pouvoir la retrouver ici'
                }
              ></AlertInfo>
            </Box>
          )}
          {!isLoading && hasQuestions && (
            <>
              <QuestionEditable
                questions={questions?.items}
                onUpdateQuestion={handleOpenUpdateQuestion}
                onDeleteQuestion={handleDeleteQuestion}
              />
              <Pagination
                next={nextPage}
                prev={prevPage}
                hasNext={hasNextPage}
                hasPrev={hasPrevPage}
              />
            </>
          )}
        </Stack>
      </Container>
      <ModalContainer />
    </>
  )
}
export default UserFaqVideoMyQuestion
