import {useConsultations} from '../../commons/api/hooks'
import {sortByDate} from '../../commons/helpers/dataHelper'
import {buildFilterBySatus} from '../../commons/helpers/graphQLHelper'
import {ModelSortDirection} from '../../commons/types/API'
import {EStatus} from '../../types'
import {Select, SelectionProps} from '../ui'

type SelectConsultationsProps = SelectionProps & {
  status?: EStatus
}
export const SelectConsultations = ({
  status,
  ...rest
}: SelectConsultationsProps) => {
  const {consultations} = useConsultations({
    filter: {
      ...buildFilterBySatus(status),
    },
  })

  const consultationsByDateAsc = sortByDate({
    data: consultations?.items,
    direction: ModelSortDirection.ASC,
  })

  return (
    <Select maxW="368px" {...rest}>
      <option value="">Selectionner une consultation</option>
      {consultationsByDateAsc?.map((option, idx) => (
        <option key={idx} value={option?.id}>
          {option?.title}
        </option>
      ))}
    </Select>
  )
}

export default SelectConsultations
