import {useColorMode} from '@chakra-ui/react'
import {Link as DomRouterLink, useLocation} from 'react-router-dom'
import {
  primaryColor,
  activeMenuBg,
  bgSidebarColor,
} from '../../theme/app-constantes'
import {Item, NavItem} from '../../types'
import {Container, Link, Box, Icon} from '../ui'

type SidebarLinkProps = {
  content?: Item | NavItem
  isSubItem?: boolean
}

export const SidebarLink = ({content, isSubItem}: SidebarLinkProps) => {
  const {colorMode} = useColorMode()
  const bgLink = activeMenuBg({colorMode})
  const bgIcon = bgSidebarColor({colorMode})
  const location = useLocation()
  const pathname = location.pathname
  const pathElements = pathname.includes('consultation')
    ? pathname.split('/').slice(0, 4).join('/')
    : pathname.split('/').slice(0, 3).join('/')

  return (
    <Link
      style={{textDecoration: 'none'}}
      to={content?.href ?? '#'}
      as={DomRouterLink}
      _focus={{outline: 'none'}}
    >
      <Container
        variant={isSubItem ? 'sidebarSubLink' : 'sidebarLink'}
        role="group"
        bgColor={pathElements === content?.href ? bgLink : ''}
      >
        <Box
          bgColor={bgIcon}
          rounded="md"
          p={1}
          mr={2}
          _groupHover={{
            bgColor: primaryColor({colorMode}),
          }}
        >
          <Icon
            as={content?.icon}
            color={primaryColor({colorMode})}
            _groupHover={{color: bgLink}}
          />
        </Box>
        {content?.label}
      </Container>
    </Link>
  )
}

export default SidebarLink
