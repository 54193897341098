import {Dispatch, useEffect} from 'react'
import {SetStateAction} from 'react'
import {UserChatRoom} from '../../../commons/types/API'
import {DiscussionMobilePage} from './index'
import {useBreakpointValue} from '@chakra-ui/react'
import {useGlobalContext} from '../../../context/GlobalContext'

type MyConversationProps = {
  userChatRooms?: (UserChatRoom | null)[]
  searchDiscussion?: string
  setSearchDiscussion: Dispatch<SetStateAction<string>>
}
export default function MyConversation({
  userChatRooms,
  setSearchDiscussion,
  searchDiscussion,
}: MyConversationProps) {
  //const isMobile = useBreakpointValue({base: true, sm: false})

  // const {setHideFooter} = useGlobalContext()
  // useEffect(() => {
  //   setHideFooter(isMobile ?? false)
  //   return () => {
  //     setHideFooter(false)
  //   }
  // }, [isMobile, setHideFooter])
  return (
    <>
      <DiscussionMobilePage
        userChatRooms={userChatRooms}
        setSearchDiscussion={setSearchDiscussion}
        searchDiscussion={searchDiscussion}
      />
    </>
  )
}
