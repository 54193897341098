import {z} from 'zod'

export const errorGql = z.array(
  z
    .object({
      message: z.string().nullable(),
      locations: z.string().nullable(),
      path: z.any(),
    })
    .optional()
    .nullable(),
)
export const responseList = z.object({
  data: z.any().nullable(),
  errors: errorGql,
})
export const responseListUserCourse = z.object({
  data: z.object({
    listUserCourses: z.object({
      items: z.array(z.any().nullable()),
      nextToken: z.string().nullable(),
    }),
  }),
  errors: errorGql,
})

export const responseListUserChatRooms = z.object({
  data: z.object({
    me: z.object({
      chatRooms: z.object({
        items: z.array(z.any().nullable()),
        nextToken: z.string().nullable(),
      }),
    }),
  }),
  errors: errorGql,
})

export const responseGeneric = z.object({
  data: z.any().nullable(),
  errors: errorGql,
})
