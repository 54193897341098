import {Route, Routes} from 'react-router-dom'
import {UserDashboard} from './UserDashboard'
import {AuthAppTemplate} from '../../components/Templates/AuthAppTemplate'
import {CoursesPage} from './Courses'
import {FaqRouter} from './FaqVideo/FaqRouter'
import {UserDetailPage} from './Users'
import {Error404} from './../common/Error404'
import {TopicRouter} from './Topic/TopicRouter'
import {MessagerieRouteur} from './Messagerie/MessagerieRouter'

export const UserRouter = () => {
  return (
    <AuthAppTemplate>
      <Routes>
        <Route path="/" element={<UserDashboard />} />
        <Route path="dashboard" element={<UserDashboard />} />
        <Route path="user/:userId" element={<UserDetailPage />} />
        <Route path="faq/*" element={<FaqRouter />} />
        <Route path="messagerie/*" element={<MessagerieRouteur />} />
        <Route path="topic/*" element={<TopicRouter />} />
        <Route path="courses" element={<CoursesPage />} />
        <Route path="profil" element={<CoursesPage />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </AuthAppTemplate>
  )
}
