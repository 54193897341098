import {ArrowBackIcon, ArrowForwardIcon} from '@chakra-ui/icons'
import {Button, Flex, SimpleGrid, Text} from '../ui'

type PaginationProps = {
  next?: () => void
  prev?: () => void
  current?: any // TODO: a supprimer
  hasNext?: boolean
  hasPrev?: boolean
  isLoading?: boolean
  hidden?: boolean // TODO: a supprimer
  currentPageCount?: number
  totalPageCount?: number
  light?: boolean
}
export const Pagination = ({
  next = () => {},
  prev = () => {},
  hasNext = false,
  hasPrev = false,
  isLoading = false,
  hidden = false,
  currentPageCount = 0,
  totalPageCount = 0,
  light = false,
  ...props
}: PaginationProps) => {
  const pageCount =
    currentPageCount > 0
      ? `${
          totalPageCount >= currentPageCount
            ? 'Page ' + currentPageCount + '/' + totalPageCount
            : 'Page ' + currentPageCount
        }`
      : ''
  return (
    <Flex justifyContent="space-between" hidden={hidden} {...props}>
      <Text mt={2}>{pageCount}</Text>
      <SimpleGrid columns={2} spacing={2}>
        <Button
          leftIcon={<ArrowBackIcon />}
          variant={light ? 'ghost' : 'outline'}
          onClick={prev}
          isDisabled={!hasPrev || isLoading}
          size={light ? 'md' : 'md'}
        >
          {light ? '' : 'Précédent'}
        </Button>
        <Button
          rightIcon={<ArrowForwardIcon />}
          variant={light ? 'ghost' : 'outline'}
          onClick={next}
          isDisabled={!hasNext || isLoading}
          size={light ? 'md' : 'md'}
        >
          {light ? '' : 'Suivant'}
        </Button>
      </SimpleGrid>
    </Flex>
  )
}

export default Pagination
