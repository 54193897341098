import * as React from 'react'
import {format} from 'date-fns'
import {fr} from 'date-fns/locale'
// import React from 'react'
import {MdDelete, MdEdit, MdVisibility} from 'react-icons/md'
import {Box, Flex, HStack, Icon, Stack, Text} from '../ui'
import {Question} from '../../commons/types/API'
import {EStatus} from '../../types/graphQL'
import TagStatus from '../ui/TagStatus'
import {dateFormat_ddMMyyyyHHmm} from '../../commons/constantes'
import {questionTitleOrReword} from '../../commons/helpers/screen'

type QuestionCardProps = {
  question: Question | null
  onDeleteQuestion: (e: React.MouseEvent, value: string) => void
  onUpdateQuestion: (e: React.MouseEvent, value: string) => void
  onViewQuestion: (e: React.MouseEvent, value: string) => void
}

export const QuestionCard = ({
  question,
  onDeleteQuestion,
  onUpdateQuestion,
  onViewQuestion,
}: QuestionCardProps) => {
  const [username, setUsername] = React.useState<string>('username')
  const [createdAt, setCreatedAt] = React.useState<string>('')
  const [title, setTitle] = React.useState<string>('')

  const reponseDate = (date: any) => {
    let created = '01/01/2022'
    if (date) {
      created = format(new Date(date), dateFormat_ddMMyyyyHHmm, {
        locale: fr,
      })
    }
    return created
  }

  // Si la reformulation est vide, on affiche le titre original de la question
  const questionTitle = questionTitleOrReword(question)

  React.useEffect(() => {
    // //console.log('ta souer', question)
    setUsername(question?.author?.username ?? '')
    setTitle(questionTitle)
    const createdAtData = reponseDate(question?.createdAt)
    setCreatedAt(createdAtData)
    // setMessage(item?.message)
  }, [question, questionTitle])

  return (
    <Box pb={4} pt={2}>
      <Stack spacing={2}>
        <HStack spacing={3}>
          <TagStatus
            minW={'auto'}
            status={question?.status as EStatus}
          ></TagStatus>
          <Box>
            <HStack spacing={1}>
              <Text variant="subFormation">par</Text>
              <Text variant="authorItalic">{username}</Text>
            </HStack>
            <HStack spacing={1} pr={2}>
              <Text variant="subFormation" minW={'35px'}>
                sujet :
              </Text>
              <Text variant="subjectQuestion" noOfLines={2}>
                {title}
              </Text>
            </HStack>
          </Box>
        </HStack>
        <Flex justifyContent={'space-between'} w="full" align="center">
          <Text>le {createdAt}</Text>
          <HStack>
            <Icon
              as={MdVisibility}
              w={6}
              h={6}
              onClick={(e: any) => onViewQuestion(e, question?.id ?? '')}
              cursor="pointer"
            />
            <Icon
              as={MdEdit}
              w={6}
              h={6}
              onClick={(e: any) => onUpdateQuestion(e, question?.id ?? '')}
              cursor="pointer"
            />
            <Icon
              as={MdDelete}
              w={6}
              h={6}
              onClick={(e: any) => onDeleteQuestion(e, question?.id ?? '')}
              cursor="pointer"
            />
          </HStack>
        </Flex>
      </Stack>
    </Box>
  )
}

export default QuestionCard
