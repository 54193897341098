import {AuthUser, Eroles} from '../../types'
import {EStatus} from '../../types/graphQL'
import {
  Comment,
  Message,
  Question,
  Topic,
  FAQConsultation,
  ChatRoom,
  User,
} from '../types/API'

//possède tous les roles
export const hasAllRoles = (roles: string[], user: any) => {
  return user?.roles?.every((element: never) => roles.indexOf(element) > -1)
}
//possède un des roles
export const hasRoles = (roles: string[], user: any) => {
  return user?.roles?.some((element: never) => roles.indexOf(element) > -1)
}
// pour routeConfig
export const haveRouteRequiredRole = (routesRoles: any, user: any) => {
  let isValid = false
  if (routesRoles.length === 0) {
    // no permission required!
    return true
  }
  isValid = hasRoles(routesRoles, user)
  return isValid
}

export const getRoleName = (user: AuthUser) => {
  let roleName = 'unknown'
  if (user?.roles?.includes(Eroles.ADMIN)) {
    roleName = 'Administrateur'
  }
  if (user?.roles?.includes(Eroles.SCHOOLOWNER)) {
    roleName = 'Coach'
  }
  if (user?.roles?.includes(Eroles.STUDIENT)) {
    roleName = 'Eleve'
  }
  if (user?.roles?.includes(Eroles.USER)) {
    roleName = 'Invité'
  }
  return roleName
}

export const getUserName = (user: AuthUser) => {
  let username = 'unknown'
  username = user?.me?.username as string
  return username
}

export const getAvatar = (user: AuthUser) => {
  let avatar = 'unknown'
  avatar = user?.me?.image as string
  return avatar
}

export const hasRolesAdmin = (user: any) => {
  return hasRoles([Eroles.ADMIN], user)
}

export const hasAnyRolesAdminOwner = (user: any) => {
  return hasRoles([Eroles.ADMIN, Eroles.SCHOOLOWNER], user)
}

export const canUpdateTopic = (user: AuthUser, topic: Topic): boolean => {
  const hasRole =
    hasRoles([Eroles.SCHOOLOWNER], user as AuthUser) ||
    hasRoles([Eroles.ADMIN], user as AuthUser)
  // //console.log('topic?.user?.id', topic?.user?.id)
  // //console.log('user?.me?.id', user?.me?.id)
  // //console.log('hasRole', hasRole)
  return topic?.user?.id === user?.me?.id || hasRole
}

export const canUpdateComment = (user: AuthUser, comment: Comment): boolean => {
  const hasRole =
    hasRoles([Eroles.SCHOOLOWNER], user as AuthUser) ||
    hasRoles([Eroles.ADMIN], user as AuthUser)
  // //console.log('topic?.user?.id', topic?.user?.id)
  // //console.log('user?.me?.id', user?.me?.id)
  // //console.log('hasRole', hasRole)
  return comment?.author?.id === user?.me?.id || hasRole
}

export const canUpdateMessage = (user: AuthUser, message: Message): boolean => {
  const hasRole =
    hasRoles([Eroles.SCHOOLOWNER], user as AuthUser) ||
    hasRoles([Eroles.ADMIN], user as AuthUser)
  return message?.userMessagesId === user?.me?.id || hasRole
}

export const isAdminOrOwner = (user: AuthUser): boolean => {
  const hasRole = hasAnyRolesAdminOwner(user)
  return hasRole
}

export const canUpdateQuestion = (
  user: AuthUser,
  question: Question,
): boolean => {
  const hasRole =
    hasRoles([Eroles.SCHOOLOWNER], user as AuthUser) ||
    hasRoles([Eroles.ADMIN], user as AuthUser)
  return question?.questionAuthorId === user?.me?.id || hasRole
}

export const canUpdateMyQuestions = (
  user: AuthUser,
  question: Question,
): boolean => {
  const canUpdate = canUpdateQuestion(user, question)
  return (
    question?.status !== EStatus.PUBLISHED &&
    question?.status !== EStatus.REJECTED &&
    canUpdate
  )
}

export const canDeleteMyQuestions = (
  user: AuthUser,
  question: Question,
): boolean => {
  const canUpdate = canUpdateQuestion(user, question)
  return question?.status === EStatus.NEW && canUpdate
}

export const getBgColorByUserName = (firstName?: string): string => {
  const colors = [
    'blue.100',
    'blue.200',
    'blue.300',
    'blue.400',
    'blue.500',
    'cyan.100',
    'cyan.200',
    'cyan.300',
    'cyan.400',
    'cyan.500',
    'teal.100',
    'teal.200',
    'teal.300',
    'teal.400',
    'teal.500',
    'pink.100',
    'pink.200',
    'pink.300',
    'pink.400',
    'pink.500',
    'purple.100',
    'purple.200',
    'purple.300',
    'purple.400',
    'purple.500',
    'purple.600',
  ]
  const firstLetter = firstName?.[0]?.toLowerCase() ?? ''
  const letterCode = firstLetter?.charCodeAt(0) - 97
  const colorIndex = letterCode % 16
  return colors[colorIndex]
}

export const canDeleteConsult = (
  user: AuthUser,
  consultation: FAQConsultation,
): boolean => {
  if (!user?.roles) {
    throw new Error("Les datas de l'utilisateur introuvables")
  }
  if (!consultation?.id) {
    throw new Error('Les datas de la consultation sont introuvables')
  }
  const hasRole =
    hasRoles([Eroles.SCHOOLOWNER], user as AuthUser) ||
    hasRoles([Eroles.ADMIN], user as AuthUser)
  return (
    hasRole &&
    (!consultation?.questions ||
      (consultation?.questions?.items != null &&
        consultation?.questions?.items.length < 1))
  )
}

export const canReadChatRoom = (
  user: AuthUser,
  chatRoom: ChatRoom,
): boolean => {
  if (!user?.roles) {
    throw new Error("Les datas de l'utilisateur introuvables")
  }
  // if (!chatRoom?.id) {
  //   throw new Error('Les datas de la chatRoom sont introuvables')
  // }
  const hasRole =
    hasRoles([Eroles.SCHOOLOWNER], user as AuthUser) ||
    hasRoles([Eroles.ADMIN], user as AuthUser)

  const idExists = chatRoom?.contributors?.items.some(
    contributor => contributor?.userId === user?.me?.id,
  )

  return hasRole || (idExists ?? false)
}

export const canRemoveUserFromGroup = (user: AuthUser) => {
  return isAdminOrOwner(user)
}
const userUnedinedLabel = 'Utilisateur non défini'
/**
 * Generated by ChatGPT
 * @param user
 * @returns
 */
export const displayUserNameAndName = (user?: User | null): string => {
  if (!user || !user.username) {
    return userUnedinedLabel
  }

  const nameParts: string[] = []

  if (user.firstname) {
    nameParts.push(user.firstname)
  }

  if (user.lastname) {
    nameParts.push(user.lastname)
  }

  const fullName = nameParts.join(' ')

  return fullName ? `${user.username} (${fullName})` : user.username
}

export const displayNameInitial = (user?: User): string => {
  if (!user) {
    return userUnedinedLabel
  }

  const firstNameInitial = user.firstname
    ? user.firstname.charAt(0).toUpperCase()
    : ''
  const lastNameInitial = user.lastname
    ? user.lastname.charAt(0).toUpperCase()
    : ''

  let initials = ''
  if (firstNameInitial && lastNameInitial) {
    initials = ` (${firstNameInitial}-${lastNameInitial})`
  } else if (firstNameInitial) {
    initials = ` (${firstNameInitial})`
  } else if (lastNameInitial) {
    initials = ` (${lastNameInitial})`
  }

  return `${user.username ?? ''}${initials}`.trimLeft()
}

export const displayNameForAvatar = (user?: User): string => {
  if (displayUserNameAndName(user) === userUnedinedLabel) {
    return ''
  }
  return displayUserNameAndName(user).replaceAll('(', '').replaceAll(')', '')
}

export function isAdmin(user?: User) {
  // Check if the roles field exists and contains "schoolowner"
  return user?.roles && user.roles.includes(Eroles.SCHOOLOWNER)
}
