import {Link} from 'react-router-dom'
import {ROUTE_SCHOOL_TOPIC_EDITOR} from '../../constantes'
import Searchbar from '../SearchBar/Searchbar'
import {Button, HStack, Stack, Text} from '../ui'

type TopicActionProps = {
  onAdd: () => void
  onSearch: (value: string) => void
  workspaceId?: string
}

export const TopicAction = ({onSearch, workspaceId}: TopicActionProps) => {
  const routeByWorkspaceid = workspaceId
    ? ROUTE_SCHOOL_TOPIC_EDITOR + `?workspace=${workspaceId}`
    : ROUTE_SCHOOL_TOPIC_EDITOR

  //console.log('workspaceId', routeByWorkspaceid)

  return (
    <>
      <Stack spacing={2}>
        <Text>Vous pouvez créer un nouveau sujet :</Text>
        <Link to={routeByWorkspaceid}>
          <Button>Créer un nouveau sujet</Button>
        </Link>
        <Text>ou chercher parmis les sujets existants :</Text>
        <HStack spacing={2}>
          <Searchbar onSearch={onSearch} />
        </HStack>
      </Stack>
    </>
  )
}

export default TopicAction
