import React, {useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useQuestionsByStatusSortByDate} from '../../../commons/api/hooks'
import {PAGINATION_TABLE_QUESTION} from '../../../commons/constantes'
import {
  buildFilterSearchQuestion,
  buildFilterByQuestionsSchoolId,
} from '../../../commons/helpers/graphQLHelper'
import {ModelSortDirection} from '../../../commons/types/API'
import FAQResponsesTable from '../../../components/FaqVideo/user-faq-reponse-table'
import {ContainerPage} from '../../../components/ui'
import {useAuthDetails} from '../../../context/AuthContext'
import {EStatus} from '../../../types/graphQL'

const FaqVideoPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const questionQueryParam = searchParams.get('question')
  const viewQueryParam = searchParams.get('view')
  const {schoolId} = useAuthDetails()
  const [categoryId, setCategoryId] = useState('')

  const [sortDirection, setSortDirection] = React.useState<ModelSortDirection>(
    ModelSortDirection.DESC,
  )
  const [searchTerm, setSearchTerm] = React.useState<string>(
    questionQueryParam ?? '',
  )

  const {
    questions,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
    nextToken,
  } = useQuestionsByStatusSortByDate({
    status: EStatus.PUBLISHED,
    sortDirection: sortDirection,
    limit: searchTerm ? 10000 : PAGINATION_TABLE_QUESTION,
    filter: {
      ...buildFilterSearchQuestion(searchTerm),
      ...buildFilterByQuestionsSchoolId(schoolId),
      ...(categoryId ? {questionCategoryId: {eq: categoryId}} : {}),
    },
  })

  React.useEffect(() => {
    setSearchTerm(questionQueryParam ?? '')
  }, [questionQueryParam])
  return (
    <ContainerPage>
      <FAQResponsesTable
        view={viewQueryParam}
        setSearchParams={setSearchParams}
        questions={questions}
        next={nextPage}
        prev={prevPage}
        nextToken={nextToken}
        hasNext={hasNextPage}
        hasPrev={hasPrevPage}
        reset={resetPages}
        setSortDirection={setSortDirection}
        setCategoryId={setCategoryId}
        categoryId={categoryId}
        setSearchTerm={setSearchTerm}
        isLoading={isLoading}
      />
    </ContainerPage>
  )
}
export {FaqVideoPage}
