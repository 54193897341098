import {useEffect, useState} from 'react'

/**
 * Check la taille min en hauteur
 * @param minHeight
 * @returns
 */
export const useCheckWindowHeightBelow = minHeight => {
  const [isLessThanMinHeight, setIsLessThanMinHeight] = useState(
    window.innerHeight < minHeight,
  )

  useEffect(() => {
    const handleResize = () =>
      setIsLessThanMinHeight(window.innerHeight < minHeight)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [minHeight])

  return isLessThanMinHeight
}
