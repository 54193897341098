import React from 'react'
import {
  CreateWorkspaceInput,
  UpdateWorkspaceInput,
  Workspace,
} from '../../commons/types/API'
import {Avatar, Container, Flex, Icon, IconButton, Stack, Text} from '../ui'
import ReactTable from '../ui/ReactTable'
import {HeaderTable} from '../Table/HeaderTable'
import {Pagination} from '../Table/Pagination'
import {MdDelete, MdEdit, MdVisibility} from 'react-icons/md'
import ModalContainer from 'react-modal-promise'
import {ModalForm} from '../Modals'
import {useDisclosure, Checkbox, useBreakpoint} from '@chakra-ui/react'
import {useUserDetailSchoolGroupId} from '../../context/AuthContext'
import {
  useCreateWorkspace,
  useDeleteWorkspace,
  useUpdateWorkspace,
} from '../../commons/api/hooks/workspace'
import {WorkspaceForm} from '../Forms/WorkspaceForm'
import {modalYesNoPromise} from '../Modals/YesNo'
import {usePagination} from '../../hooks/commonActions'
import {useNavigate} from 'react-router-dom'
import {ROUTE_SCHOOL_TOPIC} from '../../constantes'
import {useGlobalContext} from '../../context/GlobalContext'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import {AlertInfo} from '../ui/Alert'

type WorkspaceListProps = {
  workspaces: Array<Workspace> | []
  isLoading: boolean
}

export const WorkspaceList = ({
  workspaces = [],
  isLoading,
}: WorkspaceListProps) => {
  const {hasNext, hasPrev} = usePagination()
  const [selectedWorkspace, setSelectedWorkspace] = React.useState<Workspace>()
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const schoolGroupId = useUserDetailSchoolGroupId()
  const navigate = useNavigate()
  //const {loading: isLoading} = useGlobalContext()

  const mutationDelete = useDeleteWorkspace()
  const mutationCreate = useCreateWorkspace({onSuccess: onClose})
  const mutationUpdate = useUpdateWorkspace({onSuccess: onClose})
  const data = React.useMemo(() => [...workspaces], [workspaces])

  const handleDelete = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()

      modalYesNoPromise({
        title: 'Supprimer',
        confirmLabel: 'Supprimer',
        infoMessage: 'Souhaitez vous vraiment supprimer cet espace ?',
        data: data.find(elm => elm.id === value)?.course,
        labelOfType: 'prog',
        children: <div>Voulez vous vraiment supprimer cet espace </div>,
      })
        .then(ok => {
          mutationDelete.mutate(value)
        })
        .catch(error => {})
    },
    [data, mutationDelete],
  )

  const handleModalSave = (
    values: UpdateWorkspaceInput & CreateWorkspaceInput,
  ) => {
    values.groupsCanAccess = [schoolGroupId]
    if (isEdit) {
      mutationUpdate.mutate(values)
    } else {
      mutationCreate.mutate(values)
    }
  }

  const handleOpenUpdate = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      const course = data.find(elm => elm.id === value)
      setSelectedWorkspace(course)
      setIsEdit(true)
      onOpen()
    },
    [data, onOpen],
  )

  const [hiddenCol, setHiddenCol] = React.useState([''])

  const breakpoint = useBreakpoint()

  React.useEffect(() => {
    const contain = ['base', 'sm'].includes(breakpoint as string)
    contain === true ? setHiddenCol(['course.name']) : setHiddenCol([''])
  }, [breakpoint])

  const handleOpenNew = React.useCallback(() => {
    setSelectedWorkspace(undefined)
    setIsEdit(false)
    onOpen()
  }, [onOpen])

  const columns = React.useMemo(
    () => [
      // {
      //   id: 'selection',
      //   accessor: 'selection',
      //   // gestion de la checkbox du header : SelectAll ligne
      //   // The header can use the table's getToggleAllRowsSelectedProps method
      //   // to render a checkbox
      //   Header: ({getToggleAllRowsSelectedProps}: any) => {
      //     const {indeterminate, ...rest} = getToggleAllRowsSelectedProps()
      //     return <Checkbox isIndeterminate={indeterminate ? 1 : 0} {...rest} />
      //   },
      //   // checkbox seletion d'une ligne
      //   // The cell can use the individual row's getToggleRowSelectedProps method
      //   // to the render a checkbox
      //   Cell: ({row}: any) => {
      //     //to avoid warning on indeterminate boolean
      //     const {indeterminate, ...rest} = row.getToggleRowSelectedProps()
      //     return <Checkbox isIndeterminate={indeterminate ? 1 : 0} {...rest} />
      //   },
      // },
      {
        Header: 'Espaces de travail',
        accessor: 'username',
        Cell: ({row}: any) => {
          return (
            <Flex align="center">
              <Avatar
                src={
                  row?.original?.course?.image ??
                  'https://cdn.iconscout.com/icon/free/png-256/workspace-1467359-1240505.png'
                }
                display={{base: 'none', md: 'flex'}}
                mr={2}
                variant="wsList"
              />
              <Text variant="label">{row?.original?.name}</Text>
            </Flex>
          )
        },
      },
      {
        Header: 'Cours',
        accessor: 'course.name',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row}: any) => (
          <Flex justifyContent="flex-end" w="full">
            <IconButton
              size="sm"
              aria-label="Editer Workspace"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdEdit} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) =>
                handleOpenUpdate(e, row?.original?.id)
              }
            />
            <IconButton
              size="sm"
              aria-label="Voir Workspace"
              variant="ghost"
              icon={
                <Icon
                  variant="generalIcons"
                  as={MdVisibility}
                  color="gray.500"
                />
              }
              onClick={() =>
                navigate(`${ROUTE_SCHOOL_TOPIC}/${row?.original?.id}`)
              }
            />
            <IconButton
              size="sm"
              aria-label="Supprimer Workspace"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdDelete} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) =>
                handleDelete(e, row?.original?.id)
              }
            />
          </Flex>
        ),
      },
    ],
    [handleDelete, handleOpenUpdate, navigate],
  )

  const cellProps = (cellInfo: any) => {
    switch (cellInfo.column.id) {
      case 'selection':
        return {
          _position: 'select',
          util: 'user',
        }
      case 'course.name':
        return {
          _position: 'domaine',
          util: 'user',
        }
      case 'actions':
        return {
          _position: 'actions',
          util: 'user',
        }
    }
  }

  //Props pour chaque ligne <Tr>
  const rowProps = (row: any) => ({
    // onClick: (e: React.MouseEvent) => handleOpenUpdate(e, row?.original?.id),
    // style: {
    //   cursor: 'pointer',
    // },
  })

  const hasWorkspaces = workspaces.length > 0

  return (
    <>
      <Container variant="generalBg" maxW="full">
        <Stack spacing={4}>
          <HeaderTable util="workspaces" onAdd={handleOpenNew} />
          {isLoading && <TextRowSkeleton />}
          {!isLoading && !hasWorkspaces && (
            <Flex p={4} alignItems="center" justifyContent="center" w={'100%'}>
              <AlertInfo
                title={'Aucun espace de travail trouvé'}
                description={
                  'Aucun espace de travail trouvé, créér en une en cliquant sur : Créer un espace de travail!'
                }
              ></AlertInfo>
            </Flex>
          )}
          {!isLoading && hasWorkspaces && (
            <>
              <ReactTable
                rowProps={rowProps}
                cellProps={cellProps}
                columns={columns}
                data={data}
                controlledPageCount={0}
                controlledPageIndex={0}
                isLoading={isLoading}
                isFetching={false}
                onFetchData={function (options: any): void {
                  // throw new Error('Function not implemented.')
                }}
                setPageIndex={function (pageIndex: number): void {
                  //throw new Error('Function not implemented.')
                }}
                setSelectedRowsId={function (selectedRowIds: any): void {
                  // throw new Error('Function not implemented.')
                }}
                totalCount={0}
                labelData={'Espaces de travail'}
                identifier={'id'}
                controlledHiddenColumns={hiddenCol}
                initialSortBy={[]}
                hideHeader={false}
              />
              <Pagination hidden={!hasPrev && !hasNext} />
            </>
          )}
        </Stack>
        <ModalForm
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          title={isEdit ? 'Modifier un espace' : 'Ajouter un espace'}
        >
          <WorkspaceForm
            initialValues={{
              //workspaceSchoolId: selectedWorkspace?.school?.id ?? schoolId,
              //workspaceCourseId: selectedWorkspace?.course?.id ?? schoolId,
              ...(selectedWorkspace as Workspace),
            }}
            onSubmit={handleModalSave}
            onClose={onClose}
            isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
          />
        </ModalForm>

        <ModalContainer />
      </Container>
    </>
  )
}
export default WorkspaceList
