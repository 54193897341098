import {Spinner} from '@chakra-ui/react'
import {useFAQTags} from '../../commons/api/hooks'
import {buildFilterBySchool} from '../../commons/helpers/graphQLHelper'
import {useUserDetailSchoolGroupId} from '../../context/AuthContext'
import MultiSelect, {MultiSelectProps} from '../ui/MultiSelect'

export const MultiSelectTags = ({
  label,
  value,
  onChange,
  selectFirstValue,
  ...rest
}: MultiSelectProps & {selectFirstValue?: boolean}) => {
  const schoolGroupId = useUserDetailSchoolGroupId()
  const {FAQTags} = useFAQTags({
    filter: {...buildFilterBySchool(schoolGroupId)},
  })
  const handleChange = (data: any) => {
    onChange?.(data)
  }
  //hack while MultiSelect dosen't support update 'defaultValue'
  if (!FAQTags?.items) {
    return <Spinner></Spinner>
  }

  // default value not good supported
  // const defaultValue = FAQTags?.items?.[0]?.id
  // const hasValue = (value as [])?.length > 0

  return (
    <MultiSelect
      //value={hasValue ? value : selectFirstValue ? defaultValue : undefined}
      value={value}
      data={FAQTags?.items}
      label={label}
      {...rest}
      onChange={handleChange}
    ></MultiSelect>
  )
}

export default MultiSelectTags
