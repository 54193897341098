import {
  Menu as ChakraMenu,
  MenuProps,
  MenuButton as ChakraMenuButton,
  MenuButtonProps,
  MenuList as ChakraMenuList,
  MenuItem as ChakraMenuItem,
  MenuItemProps,
  MenuListProps,
  MenuOptionGroup as ChakraMenuOptionGroup,
  MenuOptionGroupProps,
  MenuDivider as ChakraMenuDivider,
  MenuDividerProps,
  MenuItemOption as ChakraMenuItemOption,
  MenuItemOptionProps,
  useColorMode,
} from '@chakra-ui/react'
import {primaryColor, bgSidebarColor} from './../../theme/app-constantes'

export const Menu = (props: MenuProps) => {
  return <ChakraMenu {...props}></ChakraMenu>
}
export const MenuButton = (props: MenuButtonProps) => {
  return <ChakraMenuButton bgColor="transparent" {...props}></ChakraMenuButton>
}
export const MenuList = (props: MenuListProps) => {
  return (
    <ChakraMenuList
      alignItems={'center'}
      boxShadow={'none'}
      _hover={{boxShadow: 'none'}}
      mr={-2}
      {...props}
    ></ChakraMenuList>
  )
}
export const MenuOptionGroup = (props: MenuOptionGroupProps) => {
  return <ChakraMenuOptionGroup {...props}></ChakraMenuOptionGroup>
}
export const MenuDivider = (props: MenuDividerProps) => {
  return <ChakraMenuDivider {...props}></ChakraMenuDivider>
}
export const MenuItem = (props: MenuItemProps) => {
  const {colorMode} = useColorMode()
  return (
    <ChakraMenuItem
      color="gray.500"
      rounded="md"
      fontWeight="medium"
      _hover={{
        bg: primaryColor({colorMode}),
        color: bgSidebarColor({colorMode}),
      }}
      {...props}
    ></ChakraMenuItem>
  )
}
export const MenuItemOption = (props: MenuItemOptionProps) => {
  return <ChakraMenuItemOption {...props}></ChakraMenuItemOption>
}
export default Menu
