import {Skeleton} from '@chakra-ui/react'
import {Box} from '../ui'

export const VideoSkeleton = () => {
  return (
    <Box>
      <Skeleton height={'100%'} width={'100%'} borderRadius={'10'}>
        <div>contents wrapped</div>
      </Skeleton>
    </Box>
  )
}
export default VideoSkeleton
