import {GraphQLResult} from '@aws-amplify/api-graphql'
import {API, graphqlOperation} from 'aws-amplify'
import {getWorkspace, workspacesBySchool} from '../../graphql/queries'
import {
  createWorkspace as createWorkspaceMutation,
  deleteWorkspace as deleteWorkspaceMutation,
  updateWorkspace as updateWorkspaceMutation,
} from '../../graphql/mutations'
import {
  Workspace,
  UpdateWorkspaceInput,
  CreateWorkspaceInput,
  WorkspacesBySchoolQueryVariables,
  ModelWorkspaceConnection,
} from '../../types/API'
import {workspacesBySchoolCustom} from '../../graphql/custom/queries'

interface GraphQLWorkspaceCommonResult {
  getWorkspacesBySchoolId?: [Workspace]
  workspacesBySchool?: ModelWorkspaceConnection

  getWorkspace?: Workspace
  updateWorkspace?: Workspace
  createWorkspace?: Workspace
  deleteWorkspace?: Workspace
}

export const getWorkspaceById = async (id: string | undefined) => {
  const data = await (API.graphql(
    graphqlOperation(getWorkspace, {id: id}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getWorkspace
}

export const getWSByScoolId = async (
  input?: WorkspacesBySchoolQueryVariables,
) => {
  //console.log('getWSByScoolId', input)

  const data = await (API.graphql(
    //--> cutom resolver --> graphqlOperation(getWorkspacesBySchoolId, {schoolId}),
    graphqlOperation(workspacesBySchool, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  // return data?.data?.getWorkspacesBySchoolId
  return data?.data?.workspacesBySchool
}

export const getWSByScoolIdCustom = async (
  input?: WorkspacesBySchoolQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(workspacesBySchoolCustom, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.workspacesBySchool
}

// custom resolver version
// export const getWSByScoolIdCustomOld = async (schoolId: string) => {
//   const data = await (API.graphql(
//     graphqlOperation(getWorkspacesBySchoolIdCustom, {schoolId}),
//   ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
//   return data?.data?.getWorkspacesBySchoolId
// }

export const updateWorkspace = async (workspace?: UpdateWorkspaceInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateWorkspaceMutation, {input: workspace}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.updateWorkspace
}

export const createWorkspace = async (workspace?: CreateWorkspaceInput) => {
  delete workspace?.id
  const data = await (API.graphql(
    graphqlOperation(createWorkspaceMutation, {input: workspace}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.createWorkspace
}

export const deleteWorkspace = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteWorkspaceMutation, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.deleteWorkspace
}
