import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import {getEStatusDetails} from '../../../commons/helpers/screen'
import {Question} from '../../../commons/types/API'
import {ROUTE_ADMIN_FAQ_CREATE_CONSULTATION} from '../../../constantes'
import {useCreateConsultationStepper} from '../../../context/CreateConsultationContext'
import {useSaveQuestion} from '../../../hooks/questionsActions'
import {EStatus} from '../../../types/graphQL'
import {CreateConsultationSteps} from '../../../types/screen'
import QuestionForm from '../../Forms/QuestionForm'
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Tag,
  Text,
} from '../../ui'
import {HeaderStep} from './HeaderStep'

type StepAnswerConsultationProps = {
  step?: CreateConsultationSteps
}

export const StepAnswerConsultation = ({
  step = CreateConsultationSteps.create,
}: StepAnswerConsultationProps) => {
  const navigate = useNavigate()
  const {consultation, questions, addQuestion} = useCreateConsultationStepper()

  const isEdit = true

  const {handleSaveQuestion, isLoading} = useSaveQuestion({
    onSuccess: addQuestion,
    isEdit,
  })

  React.useEffect(() => {
    if (!consultation) {
      navigate(
        `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.create}`,
      )
    }
  })

  return (
    <>
      <HeaderStep
        title="Etape de reponse aux questions (STEP3)"
        consultation={consultation}
        questions={questions}
        step={3}
      />
      <Container variant="generalBg" maxW="full">
        <Heading variant="question" pb={4}>
          Question ajoutée a la consultation en cours
        </Heading>
        <Accordion>
          {questions.map((question: Question, idx: number) => (
            <Box key={idx}>
              <AccordionItem borderTop="none" py={1}>
                {/* <h2> */}
                <AccordionButton>
                  <Box w="full" textAlign="left">
                    <Grid templateColumns="repeat(7, 1fr)" gap={4}>
                      <GridItem colSpan={1}>
                        <Flex w="full" justifyContent={'center'}>
                          <Tag
                            bgColor={
                              getEStatusDetails(question?.status as EStatus)
                                .value
                            }
                            color={'white'}
                            fontWeight={'semibold'}
                          >
                            {question?.status}
                          </Tag>
                        </Flex>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <Text variant="label">{question.title}</Text>
                      </GridItem>
                    </Grid>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                {/* </h2> */}
                <AccordionPanel pb={4}>
                  <QuestionForm
                    isLoading={isLoading}
                    isModeConsultationStep={true}
                    isEdit={true}
                    initialValues={{
                      fAQConsultationQuestionsId: consultation?.id,

                      //questionAuthorId: question?.author?.id as string,
                      questionCategoryId: question?.category?.id,
                      questionAnswerId: question?.answer?.id,
                      // questionFaqConsultationId: question?.faqConsultation?.id,
                      tagsData: question?.tags?.items,
                      ...(question as Question),
                      //this pattern or inside the form ?
                      questionAuthorId: (question?.author?.id as string) ?? '',
                    }}
                    onSubmit={handleSaveQuestion}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Box>
          ))}
        </Accordion>
        <Flex w="full" justifyContent={'flex-end'} mt={2}>
          <HStack>
            {' '}
            <Button
              onClick={() =>
                navigate(
                  `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.select_questions}`,
                )
              }
            >
              Précédent
            </Button>
            <Button
              isLoading={isLoading}
              onClick={() =>
                navigate(
                  `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.publish}`,
                )
              }
            >
              Suivant
            </Button>
          </HStack>
        </Flex>
      </Container>
    </>
  )
}
