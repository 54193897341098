import React from 'react'
import {Navigate, RouteProps, Outlet, useLocation} from 'react-router-dom'
import {useAuth} from '../context/AuthContext'
import {hasRoles} from '../commons/helpers'
import {Eroles} from '../types'
import Loading from '../components/Loading'
type RouteAppProps = RouteProps & {
  roles: Array<Eroles>
  children?: any
}
//https://reacttraining.com/blog/react-router-v6-pre/#nested-routes-and-layouts
//https://codesandbox.io/s/react-router-v6-security-gojb0?file=/src/App.js:785-791
//https://ui.dev/react-router-nested-routes/

export const PrivateRoute: React.FC<RouteAppProps> = ({
  roles,
  children,
  ...rest
}) => {
  const {loading, authUser}: any = useAuth()
  const location = useLocation()
  if (loading) {
    return <Loading />
  }
  if (authUser) {
    if (hasRoles(roles, authUser)) {
      return children
    } else {
      return <Navigate to="/restricted" />
    }
  } else {
    //return <p>You are not logged in.</p>
    //return children
    return <Navigate to="/login" state={{from: location}} replace />
  }
}

export const PrivateRouteOutlet: React.FC<RouteAppProps> = ({
  roles,
  children,
  ...rest
}) => {
  const {loading, authUser}: any = useAuth()

  if (loading) {
    return <Loading />
  }
  if (authUser) {
    if (hasRoles(roles, authUser)) {
      return <Outlet />
    } else {
      return <Navigate to="/restricted" />
    }
  } else {
    //logout()
    return <Navigate to="/login" />
  }
}

export default PrivateRoute
