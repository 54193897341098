import {Route, Routes} from 'react-router-dom'
import {FaqVideoPage} from '.'
import {FaqConsultationListPage} from './FaqConsultationListPage'
import {FaqMyQuestionsPage} from './FaqMyQuestionsPage'
import {QuestionByIdPage} from './QuestionByIdPage'
import {Error404} from './../../common/Error404'
import {FaqQuestionEditor} from './FaqQuestionEditor'
import {FaqByConsultationPage} from './FaqByConsultationPage'

export const FaqRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<FaqVideoPage />} />
      <Route path="/my-questions" element={<FaqMyQuestionsPage />} />
      <Route path="/consultations" element={<FaqConsultationListPage />} />
      <Route
        path="/consultations/:consultationId"
        element={<FaqByConsultationPage />}
      />
      <Route path="/question/:questionId" element={<QuestionByIdPage />} />
      <Route path="question-editor" element={<FaqQuestionEditor />} />
      <Route
        path="question-editor/:questionId"
        element={<FaqQuestionEditor />}
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}
