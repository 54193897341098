import {
  FormControlProps,
  PinInputField as ChakraPinInputField,
  PinInputFieldProps,
} from '@chakra-ui/react'
import {FormControl} from '.'

export type PinInputProps = Partial<Omit<PinInputFieldProps, 'value'>> &
  PinInputFieldProps &
  FormControlProps & {
    value?: string | number | readonly string[] | undefined | null
    text?: string
    errorMessage?: string
  }
export const PinInputField = ({...props}: PinInputProps) => {
  const {errorMessage, ...rest} = props
  const isInvalid =
    props?.errorMessage !== undefined && props?.errorMessage !== ''
  return (
    <FormControl id={props?.id}>
      <ChakraPinInputField {...rest}></ChakraPinInputField>
      {/* provoque un decalage  */}
      {/* <FormHelperText color="red.500">{props?.errorMessage}</FormHelperText> */}
    </FormControl>
  )
}
export default PinInputField
