import * as React from 'react'
import {AddIcon} from '@chakra-ui/icons'
import {Center} from '@chakra-ui/react'
import {MdApps, MdOutlineList} from 'react-icons/md'
import SelectCourses from '../Selects/select-courses'
import Searchbar from '../SearchBar/Searchbar'
import NuageFiltre from './NuageFiltre'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
} from '../ui'
import {View} from '../../types/screen'

type HeaderTableProps = {
  util: string //prop util optionnel (?) de type syting
  onAdd?: () => void
  onSearch?: (value: string) => void
  onChange?: (value: string) => void
  view?: View
  setView?: (value: View) => void
  buttonCategoryId?: string
  setButtonCategoryId?: (value: string) => void
  setButtonSort?: (value: boolean) => void
  buttonSort?: boolean
  dataMapButton?: any
  customTitle?: string | undefined
}

export const HeaderTable = ({
  util,
  onAdd,
  onSearch,
  onChange,
  view = View.grid,
  setView = () => {},
  buttonCategoryId,
  setButtonCategoryId,
  setButtonSort,
  buttonSort,
  dataMapButton,
  customTitle,
}: HeaderTableProps) => {
  let fonction = ''
  let textButton = ''
  let widthOfLastLine = {base: 'full', md: '296px'}
  let selectPositionOne = {base: 'none', md: 'none'}
  let selectPositionTwo = {base: 'none', md: 'none'}
  let toogleList = 'flex'
  let hideButtonAction = false
  if (util === 'members') {
    fonction = 'Abonnés'
    textButton = 'Ajouter un membre'
    selectPositionOne = {base: 'inline-block', md: 'none'}
    selectPositionTwo = {base: 'none', md: 'inline-block'}
  }
  if (util === 'programmes') {
    fonction = 'Gestion des formations'
    textButton = 'Ajouter une formation'
    widthOfLastLine = {base: 'full', md: 'full'}
  }
  if (util === 'programmes-user') {
    hideButtonAction = true
    fonction = 'Mes formations'
    textButton = 'Ajouter une formation'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'workspaces') {
    fonction = 'Espaces de travail'
    textButton = 'Ajouter un espace'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'questions') {
    fonction = 'Questions'
    textButton = 'Poser une question'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'questions-admin') {
    fonction = 'Questions'
    textButton = 'Créer une consultation (process)'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'reponses') {
    fonction = 'Les dernières consultations'
    textButton = 'Poser une question'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'consultation') {
    fonction = 'Consultation'
    textButton = 'Créé une simple consultation'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'categories') {
    fonction = 'Catégories'
    textButton = 'Ajouter une categorie'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'tags') {
    fonction = 'Tags'
    textButton = 'Ajouter un tag'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'myquestion') {
    fonction = 'Mes questions en cours '
    textButton = 'Poser une question'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'questionsPage') {
    fonction = 'Réponses'
    textButton = 'Poser une question'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'flex'
  }
  if (util === 'question-editor') {
    fonction = 'Modifier ma question'
    textButton = 'Modifier ma question'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'question-categories-filtered') {
    fonction = customTitle ? customTitle : ''
    textButton = 'Poser une question'
    widthOfLastLine = {base: 'full', md: 'full'}
    toogleList = 'none'
  }
  if (util === 'questionsPage') {
    return (
      <Stack spacing={2} maxW={{md: 'full', lg: 'calc(100vw - 360px)'}}>
        <Flex
          display={'flex'}
          justifyContent={'flex-start'}
          gap={5}
          alignItems={'center'}
          flexDirection={{md: 'row', base: 'column'}}
        >
          <Heading>{fonction}</Heading>
          <Flex
            justifyContent={'space-between'}
            width={'100%'}
            gap={2}
            alignItems={'end'}
            flexWrap={'nowrap'}
            maxWidth={'full'}
          >
            <Flex gap={2} alignItems={'baseline'}>
              <Searchbar onSearch={onSearch} />
              <Button
                hidden={hideButtonAction}
                display={{base: 'none', md: 'flex'}}
                rightIcon={<AddIcon />}
                onClick={onAdd}
                marginTop={{base: 1, md: 1}}
              >
                {textButton}
              </Button>
              <IconButton
                hidden={hideButtonAction}
                icon={<AddIcon />}
                aria-label="Add membre"
                display={{md: 'none'}}
                onClick={onAdd}
                marginTop={{base: 0, md: 1}}
              />
            </Flex>
            <HStack spacing={0} display={toogleList} gap={2}>
              <IconButton
                icon={
                  <Icon
                    as={MdApps}
                    variant="listIcons"
                    isActive={view === View.grid}
                  />
                }
                aria-label="list"
                variant="ghost"
                border={'1px solid'}
                borderRadius={5}
                onClick={() => setView(View.grid)}
              ></IconButton>
              <IconButton
                icon={
                  <Icon
                    as={MdOutlineList}
                    variant="listIcons"
                    isActive={view === View.list}
                  />
                }
                aria-label="list"
                variant="ghost"
                border={'1px solid'}
                borderRadius={5}
                onClick={() => setView(View.list)}
              ></IconButton>
            </HStack>
          </Flex>
        </Flex>
        <Center w="full">
          <Box display={selectPositionOne}>
            <SelectCourses />
          </Box>
        </Center>
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          overflow={'hidden'}
          w="full"
        >
          <Box display={selectPositionTwo} mt={-1}>
            <SelectCourses />
          </Box>
          <NuageFiltre
            setButtonCategoryId={setButtonCategoryId}
            buttonCategoryId={buttonCategoryId}
            setButtonSort={setButtonSort}
            buttonSort={buttonSort}
            dataMapButton={dataMapButton}
          />
          {/* <HStack spacing={0} display={toogleList}>
            <IconButton
              icon={
                <Icon as={MdApps} variant="listIcons" isActive={iconListCard} />
              }
              aria-label="list"
              variant="ghost"
              onClick={e => {
                switchVue?.(e, 1)
                switchVueList(1)
              }}
            ></IconButton>
            <IconButton
              icon={
                <Icon
                  as={MdOutlineList}
                  variant="listIcons"
                  isActive={iconList}
                />
              }
              aria-label="list"
              variant="ghost"
              onClick={e => {
                switchVue?.(e, 2)
                switchVueList(2)
              }}
            ></IconButton>
          </HStack> */}
        </Flex>
      </Stack>
    )
  } else
    return (
      <Stack spacing={2}>
        <Flex justifyContent={'space-between'}>
          <Heading>{fonction}</Heading>

          <Button
            hidden={hideButtonAction}
            display={{base: 'none', md: 'flex'}}
            rightIcon={<AddIcon />}
            onClick={onAdd}
          >
            {textButton}
          </Button>
          <IconButton
            hidden={hideButtonAction}
            icon={<AddIcon />}
            aria-label="Add membre"
            display={{md: 'none'}}
            onClick={onAdd}
          />
        </Flex>
        <Center w="full">
          <Box display={selectPositionOne}>
            <SelectCourses />
          </Box>
        </Center>
        <Flex justifyContent={'space-between'}>
          <Box display={selectPositionTwo} mt={-1}>
            <SelectCourses />
          </Box>
          <Flex justifyContent={'space-between'} w={widthOfLastLine}>
            <Searchbar
              onSearch={onSearch ? onSearch : undefined}
              onChange={onChange ? onChange : undefined}
            />
            <HStack spacing={0} display={toogleList}>
              <IconButton
                icon={
                  <Icon
                    as={MdApps}
                    variant="listIcons"
                    isActive={view === View.grid}
                  />
                }
                aria-label="list"
                variant="ghost"
                onClick={() => setView(View.grid)}
              ></IconButton>
              <IconButton
                icon={
                  <Icon
                    as={MdOutlineList}
                    variant="listIcons"
                    isActive={view === View.list}
                  />
                }
                aria-label="list"
                variant="ghost"
                onClick={() => setView(View.list)}
              ></IconButton>
            </HStack>
          </Flex>
        </Flex>
      </Stack>
    )
}

export default HeaderTable
