import * as Yup from 'yup'
import {CreateTopicInput, UpdateTopicInput} from '../../commons/types/API'
import {Badge, Box, Button, Flex, FormInput, FormTextArea, HStack} from '../ui'
import {useFormik} from 'formik'
import {AlertHiddenFields} from './AlertHiddenFields'
import SelectWorkspaces from '../Selects/select-workspace'
import {useAuthDetails} from '../../context/AuthContext'
import {defautMarginTop} from '../../theme/app-constantes'
import 'react-markdown-editor-lite/lib/index.css'
import {Switch, Text, useColorMode} from '@chakra-ui/react'
import {useState} from 'react'
import MDEditor from '@uiw/react-md-editor'
import {useLocation} from 'react-router-dom'

type TopicFormProps = {
  initialValues: Partial<UpdateTopicInput> & Partial<CreateTopicInput>
  onSubmit: (formValue: UpdateTopicInput & CreateTopicInput) => void
  onClose: () => void
  isLoading?: boolean
  isModeWorkspace: boolean //dans le ws
  isEdit?: boolean
}

export const TopicForm: React.FC<TopicFormProps> = ({
  initialValues,
  onClose,
  onSubmit,
  isLoading,
  isModeWorkspace,
  isEdit,
}) => {
  const {search} = useLocation()
  const queryParams = new URLSearchParams(search)
  const queryWorkspaceTopicId = queryParams.get('workspace')

  // const schoolGroupId = useUserDetailSchoolGroupId()
  // const uid = useAuthUserId()
  // const {schoolId} = useAuth()
  const {uid, schoolId, schoolGroupId} = useAuthDetails()
  const SignInSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(80, 'Maximum 80 caractères')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(5000, 'Maximum 5000 caractères')
      .required('Champ obligatoire'),
    workspaceTopicsId: Yup.string().test(
      'workspace',
      'Veuillez selectionner un espace de travail',
      value => !!value,
    ),
    topicSchoolId: Yup.string().test(
      'workspace',
      'Veuillez selectionner une école',
      value => !!value,
    ),
    userTopicsId: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required("Nom d'utilisateur manquant"),
    groupsCanAccess: Yup.array()
      .min(1, 'Il manque groupsCanAccess')
      .required('Champ obligatoire'),
    editors: Yup.array()
      .min(1, 'Il manque editor')
      .required('Champ obligatoire'),
    owner: Yup.string().test('owner', 'Il manque owner', value => !!value),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      description: initialValues?.description ?? '',
      workspaceTopicsId:
        queryWorkspaceTopicId ?? initialValues?.workspaceTopicsId ?? '',
      userTopicsId: initialValues?.userTopicsId ?? uid ?? '',

      name: initialValues?.name ?? '',
      //important fields
      topicSchoolId: initialValues?.topicSchoolId ?? schoolId,
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (values: UpdateTopicInput & CreateTopicInput) => {
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  const handleMarkdownChange = (value: string) => {
    formik.setFieldValue('description', value)
  }
  const {colorMode} = useColorMode()

  colorMode === 'light'
    ? document.documentElement.setAttribute('data-color-mode', 'light')
    : document.documentElement.setAttribute('data-color-mode', 'dark')

  const [isMarkdown, setIsMarkdown] = useState(false)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="inline-flex" flexWrap="wrap" width="100%" gap={2}>
        <FormInput
          label="Titre du sujet (maximum 80 caractères)"
          name="name"
          id="name"
          onChange={e =>
            formik.setFieldValue('name', e.target.value.toLowerCase())
          }
          value={formik.values?.name}
          errorMessage={formik?.errors?.name as string}
        />

        <Flex gap={3} alignItems="center" justifyContent={'flex-start'}>
          <Switch
            size="md"
            onChange={() => setIsMarkdown(!isMarkdown)}
            isChecked={!isMarkdown}
          />
          <Text variant={'label'}>
            Description {!isMarkdown ? <Badge>MarkDown</Badge> : ''}
          </Text>
        </Flex>
        {isMarkdown ? (
          <FormTextArea
            placeholder="description ..."
            name="description"
            id="description"
            onChange={formik.handleChange}
            value={formik.values?.description}
            errorMessage={formik?.errors?.description}
            height="250px"
          />
        ) : (
          <>
            <MDEditor
              style={{width: '100%'}}
              //attention à bien passer le overflow en false sinon le overflow body passe en hidden...Oui ca n'a pas de sens...
              overflow={false}
              value={formik.values.description ?? '**ok**'}
              onChange={value => handleMarkdownChange(value ?? '')}
              preview="live"
              textareaProps={{
                placeholder: 'description ...',
                name: 'description',
                id: 'description',
                value: formik.values.description || 'ok',
                onChange: formik.handleChange,
              }}
            />
            {formik.touched.description && formik.errors.description && (
              <Text fontSize="sm" color="#e53e3e">
                {formik.errors.description}
              </Text>
            )}
          </>
        )}

        {isModeWorkspace === false && (
          <SelectWorkspaces
            label="Selectionner un espace de travail"
            name="workspaceTopicsId"
            id="workspaceTopicsId"
            onChange={formik.handleChange}
            value={formik.values?.workspaceTopicsId as string}
            errorMessage={formik?.errors?.workspaceTopicsId as string}
          />
        )}

        <Flex justifyContent={'flex-end'} w={'full'} mt={defautMarginTop}>
          <HStack>
            <Button onClick={onClose} variant="outline">
              Annuler
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={isLoading}
            >
              {isEdit ? 'Modifier' : 'Valider'}
            </Button>
          </HStack>
        </Flex>

        <AlertHiddenFields
          formik={formik}
          fields={['userTopicsId', 'owner', 'editors', 'groupsCanAccess']}
        />

        {/* {formik?.errors?.workspaceTopicsId && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Erreur!</AlertTitle>
            <AlertDescription>
              {formik?.errors?.workspaceTopicsId}
            </AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" />
          </Alert>
        )}
        {formik?.errors?.userTopicsId && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Erreur!</AlertTitle>
            <AlertDescription>{formik?.errors?.userTopicsId}</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" />
          </Alert>
        )} */}
      </Box>
    </form>
  )
}
