import {GraphQLResult} from '@aws-amplify/api-graphql'
import {API, graphqlOperation} from 'aws-amplify'
import {getUserById, usersBySchool} from '../../graphql/queries'
import {
  ModelUserConnection,
  User,
  usersBySchoolCustomQueryVariables,
} from '../../types/API'
import {getUserByIdCustom} from '../../graphql/custom/queries'
import {handleGraphQLError} from '../../helpers/graphQLHelper'
import {ErrorMessages} from '../../types/graphQLCommonTypes'

interface GraphQLWorkspaceCommonResult {
  usersBySchool?: ModelUserConnection
  getUserById?: User
  updateUser?: User
  createUser?: User
  deleteUser?: User
}

export const getUserByUserId = async (userId: string) => {
  let user
  try {
    const isCustom = true
    const operation = isCustom ? getUserByIdCustom : getUserById
    const data = await (API.graphql(
      graphqlOperation(operation, {userId}),
    ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
    user = data?.data?.getUserById
    return user
  } catch (err) {
    // console.log(err)
    // traitement des erreurs a laisser passer
    user = handleGraphQLError<User>(
      err,
      [ErrorMessages.ReturnNotNull],
      'getUserById',
    )
    return user
  }
}

export const getUsersBySchool = async (
  input: usersBySchoolCustomQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(usersBySchool, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.usersBySchool
}
