import {Consultations} from '../../../components/Consultations'
import {ContainerPage} from '../../../components/ui'

export const ConsultationTagsPage = () => {
  return (
    <ContainerPage>
      <Consultations tabIdx={3}></Consultations>
    </ContainerPage>
  )
}
