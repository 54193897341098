import {useParams} from 'react-router-dom'
import {toCreateConsultationStep} from '../../../commons/helpers/screen'
import {CreateConsultation} from '../../../components/Consultations/create'
import {ContainerPage} from '../../../components/ui'
import {CreateConsultationProvider} from '../../../context/CreateConsultationContext'

export const ConsultationCreatePage = () => {
  const {step, consultationId} = useParams()

  const stepEnum = toCreateConsultationStep(step)
  return (
    <ContainerPage>
      {/* vois CreateConsultationProvider comme Redux, ca stoke des 
      states accessible dans les compo enfants avec useCreateConsultationStepper() */}
      <CreateConsultationProvider>
        <CreateConsultation
          step={stepEnum}
          consultationId={consultationId}
        ></CreateConsultation>
      </CreateConsultationProvider>
    </ContainerPage>
  )
}
