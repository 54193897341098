import React, {useState} from 'react'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Text,
  useColorMode,
  HStack,
  Editable,
  EditablePreview,
  EditableInput,
  useEditableControls,
  ButtonGroup,
  IconButton,
  Flex,
  Input,
} from '@chakra-ui/react'

import {Link as DomRouterLink} from 'react-router-dom'
import {useUserById} from '../../commons/api/hooks'
import {customScrollbar} from '../../theme/app-constantes'
import {ChatRoom, User, UserChatRoom} from '../../commons/types/API'
import {displayNameForAvatar, displayNameInitial} from '../../commons'
import {ROUTE_SCHOOL_USER} from '../../constantes'
import {Avatar, Link} from '../ui'
import {useCanRemoveUserFromGroup} from '../../context/AuthContext'
import {MdDelete} from 'react-icons/md'
import {CheckIcon, CloseIcon, EditIcon} from '@chakra-ui/icons'
import {useUpdateChatRoom} from '../../commons/api/hooks/messagerie'
import {Eroles} from '../../types'

interface MessageDrawerProps {
  onQuitRoom: (e: React.MouseEvent, userId: string) => void
  isOpen: boolean
  onClose: () => void
  onOpenFormAddUser: () => void
  isGroup: boolean | undefined
  contributors?: (UserChatRoom | null)[]
  user?: User
  chatRoom?: ChatRoom
}

export default function MessageDrawer({
  onQuitRoom,
  isOpen,
  onClose,
  onOpenFormAddUser,
  isGroup,
  contributors,
  user,
  chatRoom,
}: MessageDrawerProps) {
  const {colorMode} = useColorMode()
  const scrollbarCss = customScrollbar({colorMode})
  const canRemoveUser = useCanRemoveUserFromGroup()

  const headerLabel = chatRoom?.name
  const subHeaderLabel = isGroup
    ? `${chatRoom?.description} : (${contributors?.length} membres) `
    : `Crée avec ${user?.username}`

  const [editedName, setEditedName] = useState(chatRoom?.name)

  const handleNameChange = (newName: string) => {
    setEditedName(newName)
  }
  const mutationUpdateChatRoom = useUpdateChatRoom()

  const handleNameSubmit = () => {
    if (editedName && editedName !== chatRoom?.name) {
      mutationUpdateChatRoom.mutate({
        id: chatRoom?.id ?? '',
        updatedAt: new Date().toISOString(),
        name: editedName,
      })
    }
  }

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label="valider"
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="annuler"
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          aria-label="éditer"
          variant={'ghost'}
          size="sm"
          icon={<EditIcon />}
          {...getEditButtonProps()}
        />
      </Flex>
    )
  }

  const headerLabelEditable = (
    <Editable
      defaultValue={headerLabel}
      onChange={handleNameChange}
      onSubmit={handleNameSubmit}
      isPreviewFocusable={false}
    >
      <Flex align="center" justifyContent={'space-between'}>
        <Heading>
          <EditablePreview />
        </Heading>
        <Input as={EditableInput} />
        <EditableControls />
      </Flex>
    </Editable>
  )

  const headerLabelNONEeditable = <Heading>{headerLabel}</Heading>
  const headerConv = isGroup ? headerLabelEditable : headerLabelNONEeditable

  // On trie les membres pour que les "schoolowner" viennent en premier
  contributors?.sort((a, b) => {
    // On vérifie si a ou b a le rôle "schoolowner"
    const aIsSchoolOwner = a?.user?.roles?.includes(Eroles.SCHOOLOWNER)
    const bIsSchoolOwner = b?.user?.roles?.includes(Eroles.SCHOOLOWNER)

    if (aIsSchoolOwner && !bIsSchoolOwner) {
      // Si a est "schoolowner" et b ne l'est pas, a doit venir en premier
      return -1
    } else if (!aIsSchoolOwner && bIsSchoolOwner) {
      // Si b est "schoolowner" et a ne l'est pas, b doit venir en premier
      return 1
    } else {
      // Si ni a ni b sont "schoolowner", ou si tous les deux le sont, leur ordre ne doit pas être changé
      return 0
    }
  })

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent justifyContent={'space-between'}>
          <DrawerHeader borderBottomWidth="1px">
            {headerConv}
            <Text fontSize="sm" color="gray.500">
              {subHeaderLabel}
            </Text>
          </DrawerHeader>
          <DrawerBody
            maxH={'calc(100vh - 210px)'}
            overflowY="scroll"
            css={scrollbarCss}
          >
            <Heading variant={'classikSmall'}>Membres</Heading>
            {contributors?.map((user, id) => (
              <UserComponentById
                key={id}
                id={user?.userId ?? ''}
                canRemoveUser={canRemoveUser}
                forceLeaveRoom={onQuitRoom}
              />
            ))}
          </DrawerBody>
          <DrawerFooter
            justifyContent="space-between"
            flexDir={'column'}
            gap={2}
          >
            <Button w={'80%'} onClick={onOpenFormAddUser}>
              Ajouter un membre
            </Button>
            <Button
              w={'80%'}
              colorScheme="red"
              onClick={e => onQuitRoom(e, user?.id ?? '')}
            >
              Quitter le groupe
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

const UserComponentById = ({
  id,
  canRemoveUser,
  forceLeaveRoom,
}: {
  id: string
  canRemoveUser: boolean
  forceLeaveRoom: (e: React.MouseEvent, userId: string) => void
}) => {
  const [isTextHover, setTextHover] = useState(false)

  const {user} = useUserById(id)

  return (
    <HStack
      p={2}
      m={2}
      bg={'#00000000'}
      _hover={{bg: '#00000020'}}
      rounded={'md'}
      justifyContent={'space-between'}
      onMouseEnter={() => setTextHover(true)}
      onMouseLeave={() => setTextHover(false)}
    >
      <HStack gap={4}>
        <Link as={DomRouterLink} to={`${ROUTE_SCHOOL_USER}/${user?.id}`}>
          <Avatar
            isChakraAvatar={true}
            src={user?.image ?? ''}
            name={displayNameForAvatar(user)}
            user={user as User}
          />
        </Link>
        <Text>
          {/* cette fonction permet d'afficher le nom et le prénom username de l'utilisateur */}
          {/* {displayUserNameAndName(user)} */}
          {displayNameInitial(user)}
          {/* {user?.firstname ?? ''} - {user?.lastname?.[0].toUpperCase() ?? ''} */}
        </Text>{' '}
        {isTextHover && canRemoveUser ? (
          <IconButton
            aria-label="supprimer"
            variant={'ghost'}
            size="lg"
            icon={<MdDelete />}
            onClick={e => forceLeaveRoom(e, user?.id ?? '')}
          />
        ) : // <Icon
        //   cursor={'pointer'}
        //   w={6}
        //   h={6}
        //   as={MdDelete}
        //   onClick={e => forceLeaveRoom(e, user?.id ?? '')}
        // />
        null}
      </HStack>
    </HStack>
  )
}
