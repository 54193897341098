import * as Yup from 'yup'
import * as React from 'react'
import {CreateCourseInput, UpdateCourseInput} from '../../commons/types/API'
import {
  Box,
  Button,
  FormInput,
  FormTextArea,
  Flex,
  Avatar,
  IconButton,
  Stack,
} from '../ui'
import {useFormik} from 'formik'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Progress,
} from '@chakra-ui/react'
import {DownloadIcon} from '@chakra-ui/icons'
import {useDropzone} from 'react-dropzone'
import {MAX_REZISE_IMAGE, URLregMatch} from '../../commons/constantes'
import {
  ImageObjectType,
  IResizeImageOptions,
  resizeImage,
  uploadFile,
} from '../../commons/helpers/FilesHelper'
import {useGlobalContext} from '../../context/GlobalContext'
import {
  useAuthDetails,
  useUserDetailSchoolGroupId,
} from '../../context/AuthContext'

type CourseFormProps = {
  initialValues: UpdateCourseInput
  onSubmit: (formValue: UpdateCourseInput & CreateCourseInput) => void
  onClose: () => void
  isLoading?: boolean
}

export const CourseForm: React.FC<CourseFormProps> = ({
  initialValues,
  onClose,
  onSubmit,
  isLoading,
}) => {
  // //console.log('log initialValues', initialValues)
  const SignInSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères!')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères!')
      .required('Champ obligatoire'),
    urlProduct: Yup.string()
      .matches(URLregMatch, 'URL incorrecte !')
      .required('Entrez une URL de vidéo'),
    courseSchoolId: Yup.string().test(
      'school',
      'Ecole non définie',
      value => !!value,
    ),
  })
  const schoolGroupId = useUserDetailSchoolGroupId()
  const {schoolId, uid} = useAuthDetails()
  const {setError} = useGlobalContext()
  const [uploadingImg, setUploadingImg] = React.useState(false)
  const [mustSaveImage, setMustSaveImage] = React.useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      name: initialValues?.name ?? '',
      image: initialValues?.image ?? '',
      description: initialValues?.description ?? '',
      courseSchoolId: initialValues?.courseSchoolId ?? schoolId,
      urlProduct: initialValues?.urlProduct ?? '',
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (values: UpdateCourseInput & CreateCourseInput) => {
      setMustSaveImage(false)
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  const handleChangeImage = () => {
    // TODO A FAIRE
  }

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles: any) => {
      setUploadingImg(true)
      const fileOriginal = acceptedFiles[0]
      const fileToresize: IResizeImageOptions = {
        maxSize: MAX_REZISE_IMAGE,
        file: fileOriginal,
      }

      resizeImage(fileToresize)
        .then(async newFiles => {
          formik.setFieldValue('image', URL.createObjectURL(newFiles.blob))
          await uploadFileAndSubmit(newFiles)
        })
        .catch(err => {
          setUploadingImg(false)
          console.error(err)
          setError(`Erreur durant le transfert de l'image`)
          formik.setFieldValue('image', initialValues?.image)
          formik.setFieldError('image', `Erreur durant le transfert de l'image`)
        })
    },
  })

  const uploadFileAndSubmit = async (file: any) => {
    const image = await uploadFile(
      file,
      ImageObjectType.course,
      initialValues?.id as string,
    )
    formik.setFieldValue('image', image)
    setUploadingImg(false)
    setMustSaveImage(true)
  }

  return (
    <Stack spacing={2}>
      <form onSubmit={formik.handleSubmit}>
        <Flex justifyContent="center" align="center">
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {uploadingImg && (
              <Progress size="xs" isIndeterminate aria-label="upload image" />
            )}
            <Avatar
              variant="prog"
              src={formik.values?.image as string}
              aria-label="avatar"
            />
            <Box>
              <IconButton
                onClick={() => handleChangeImage()}
                variant="solid"
                aria-label=""
                size="sm"
                mt={-12}
                ml={20}
                icon={<DownloadIcon />}
              >
                Change photo
              </IconButton>
            </Box>
          </div>
        </Flex>
        {mustSaveImage && (
          <Alert status="warning">
            <AlertIcon />
            Veuillez sauvegarder
          </Alert>
        )}
        <FormInput
          placeholder="name"
          label="Nom de la formation"
          name="name"
          id="name"
          onChange={formik.handleChange}
          value={formik.values?.name}
          errorMessage={formik?.errors?.name as string}
        />
        <FormInput
          placeholder="url de la formation"
          label="Lien"
          name="urlProduct"
          id="urlProduct"
          onChange={formik.handleChange}
          value={formik.values?.urlProduct}
          errorMessage={formik?.errors?.urlProduct as string}
        />
        <FormTextArea
          placeholder="Laissez une courte description de votre formation..."
          label="Description"
          name="description"
          id="description"
          onChange={formik.handleChange}
          value={formik.values?.description as string}
          errorMessage={formik?.errors?.description as string}
          // text={'Brief description for your profile. URLs are hyperlinked.'}
        />

        {/* <ModalFooter> */}
        <Flex py={2} justifyContent={'flex-end'} w="full">
          <Button onClick={onClose} variant="outline">
            Annuler
          </Button>
          <Button ml={4} type="submit" isLoading={isLoading}>
            Valider
          </Button>
        </Flex>
        {/* </ModalFooter> */}

        {formik?.errors?.courseSchoolId ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Erreur!</AlertTitle>
            <AlertDescription>
              {formik?.errors?.courseSchoolId}
            </AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" />
          </Alert>
        ) : null}
      </form>
    </Stack>
  )
}
