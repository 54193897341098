import {Flex} from '@chakra-ui/react'
import {Text, Container, Button, Link} from './../ui'
import {css} from '@emotion/react'
import {Card} from './Card'
import {useCourseById} from '../../commons/api/hooks'
import {LINK_IMG_DEFAULT_COURSE} from '../../constantes'

type CardCourseProps = {
  courseID?: string
}

export const CardProgrammeForUser = ({courseID}: CardCourseProps) => {
  const {course} = useCourseById(courseID ?? '')
  console.log('course by id', course)
  const image = course?.image ?? LINK_IMG_DEFAULT_COURSE

  return (
    <Card
      bgImage={image as string}
      bgSize="cover"
      h="200px"
      data={
        <Container
          variant="hoverCardProg"
          opacity={0}
          maxW="full"
          css={css`
            &:hover {
              opacity: 1;
            }
          `}
        >
          <Flex justifyContent="space-between" w="full">
            <Text mt={2} variant="cardProg">
              {course?.name}
            </Text>
            <Link href={course?.urlProduct as string} isExternal>
              <Button>Lien</Button>
            </Link>
          </Flex>
        </Container>
      }
    />
  )
}

export default CardProgrammeForUser
