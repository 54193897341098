import {useMutation, useQuery} from 'react-query'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types/hooks'
import {queryClient} from '../../config/react-query'
import {WorkspacesBySchoolQueryVariables} from '../../types/API'
import {
  createWorkspace,
  deleteWorkspace,
  getWorkspaceById,
  getWSByScoolId,
  getWSByScoolIdCustom,
  updateWorkspace,
} from '../clients'

// export const useWorkspacesBySchoolId = ({
//   schoolId,
//   custom = false,
// }: {
//   schoolId: string
//   custom?: boolean
// }) => {
//   const enabled = schoolId ? true : false
//   const {isLoading, data} = useQuery(
//     custom ? 'getWorkspacesBySchoolIdCustom' : 'getWorkspacesBySchoolId',
//     () => (custom ? getWSByScoolIdCustom(schoolId) : getWSByScoolId(schoolId)),
//     {enabled: enabled},
//   )
//   useGlobalLoading(isLoading)
//   return {workspaces: data}
// }

export const useWorkspacesBySchoolId = ({
  input,
  custom = false,
}: {
  input?: WorkspacesBySchoolQueryVariables
  custom?: boolean
}) => {
  const enabled = input?.workspaceSchoolId ? true : false
  const {isLoading, data} = useQuery(
    custom ? 'getWorkspacesBySchoolIdCustom' : 'getWorkspacesBySchoolId',
    () => (custom ? getWSByScoolIdCustom(input) : getWSByScoolId(input)),
    {enabled: enabled},
  )
  useGlobalLoading(isLoading)
  return {workspaces: data, isLoading}
}

export const useWorkspace = (workspaceId: string) => {
  const {isLoading, data} = useQuery(
    `getWorkspaceById-${workspaceId}`,
    () => getWorkspaceById(workspaceId),
    {
      enabled: workspaceId.trim().length > 0 && workspaceId !== 'all',
    },
  )
  useGlobalLoading(isLoading)
  return {workspace: data, isLoading}
}

export const useUpdateWorkspace = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateWorkspace, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getWorkspacesBySchoolIdCustom')
      queryClient.invalidateQueries('getWorkspacesBySchoolId')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useCreateWorkspace = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = useMutation(createWorkspace, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getWorkspacesBySchoolIdCustom')
      queryClient.invalidateQueries('getWorkspacesBySchoolId')
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteWorkspace = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = useMutation(deleteWorkspace, {
    // onMutate
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getWorkspacesBySchoolIdCustom')
      queryClient.invalidateQueries('getWorkspacesBySchoolId')
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}
