import {GraphQLResult} from '@aws-amplify/api-graphql'
import {API, graphqlOperation} from 'aws-amplify'
import {
  consultationsBySchoolCustom,
  getFAQConsultationCustom,
  listFAQConsultationsCustom,
} from '../../graphql/custom/queries'
import {
  createAnswer as createAnswerMutation,
  createFAQConsultation,
  deleteAnswer as deleteAnswerMutation,
  deleteFAQConsultation,
  updateAnswer as updateAnswerMutation,
  updateFAQConsultation,
} from '../../graphql/mutations'
import {
  consultationsBySchool,
  getAnswer,
  getFAQConsultation,
  listAnswers,
  listFAQConsultations,
} from '../../graphql/queries'
import {
  Answer,
  ConsultationsBySchoolQueryVariables,
  CreateAnswerInput,
  CreateFAQConsultationInput,
  FAQConsultation,
  ListFAQConsultationsQueryVariables,
  UpdateAnswerInput,
  UpdateFAQConsultationInput,
} from '../../types/API'

interface GraphQLWorkspaceCommonResult {
  listFAQConsultations?: {items: [FAQConsultation]; nextToken: any}
  consultationsBySchool?: {items: [FAQConsultation]; nextToken: any}
  getFAQConsultation?: FAQConsultation
  updateFAQConsultation?: FAQConsultation
  createFAQConsultation?: FAQConsultation
  deleteFAQConsultation?: FAQConsultation
  listAnswers?: {items: [Answer]}
  getAnswer?: Answer
  updateAnswer?: Answer
  createAnswer?: Answer
  deleteAnswer?: Answer
}

export const getConsultations = async (
  input?: ListFAQConsultationsQueryVariables,
  isCustom = true,
) => {
  //const isCustom = true
  const operation = isCustom ? listFAQConsultationsCustom : listFAQConsultations
  const data = await (API.graphql(
    graphqlOperation(operation, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.listFAQConsultations
}

export const getConsultationsBySchool = async (
  input?: ConsultationsBySchoolQueryVariables,
  isCustom = true,
) => {
  const operation = isCustom
    ? consultationsBySchoolCustom
    : consultationsBySchool
  const data = await (API.graphql(
    graphqlOperation(operation, input),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.consultationsBySchool
}

export const getConsultation = async (consultationId: string) => {
  const isCustom = true
  const operation =
    isCustom === true ? getFAQConsultationCustom : getFAQConsultation
  const data = await (API.graphql(
    graphqlOperation(operation, {id: consultationId}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getFAQConsultation
}
/**
 * @deprecated
 * @param schoolId @
 * @returns
 */
export const getConsultationByScoolId = async (schoolId: string) => {
  const data = await (API.graphql(
    graphqlOperation(getFAQConsultation, {id: schoolId}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getFAQConsultation
}

export const createConsultation = async (
  consultation?: CreateFAQConsultationInput,
) => {
  delete consultation?.id
  const data = await (API.graphql(
    graphqlOperation(createFAQConsultation, {input: consultation}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.createFAQConsultation
}

export const updateConsultation = async (
  workspace?: UpdateFAQConsultationInput,
) => {
  const data = await (API.graphql(
    graphqlOperation(updateFAQConsultation, {input: workspace}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.updateFAQConsultation
}

export const deleteConsultation = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteFAQConsultation, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.deleteFAQConsultation
}

//ANSWER

export const getAnswers = async () => {
  const data = await (API.graphql(graphqlOperation(listAnswers, {})) as Promise<
    GraphQLResult<GraphQLWorkspaceCommonResult>
  >)
  return data?.data?.listAnswers?.items
}

export const getAnswerById = async (AnswerId: string) => {
  const data = await (API.graphql(
    graphqlOperation(getAnswer, {id: AnswerId}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getAnswer
}

export const getAnswerByScoolId = async (schoolId: string) => {
  const data = await (API.graphql(
    graphqlOperation(getAnswer, {id: schoolId}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)
  return data?.data?.getAnswer
}

export const createAnswer = async (Answer?: CreateAnswerInput) => {
  delete Answer?.id
  const data = await (API.graphql(
    graphqlOperation(createAnswerMutation, {input: Answer}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.createAnswer
}

export const updateAnswer = async (workspace?: UpdateAnswerInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateAnswerMutation, {input: workspace}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.updateAnswer
}

export const deleteAnswer = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteAnswerMutation, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLWorkspaceCommonResult>>)

  return data?.data?.deleteAnswer
}
