import {ChatRoom, User} from '../types/API'

export const extractUsersUidsFromChatRoom = (chatRoom?: ChatRoom) => {
  const uids = chatRoom?.contributors?.items.reduce((uids, contributor) => {
    if (!uids.find(uid => uid === contributor?.userId) && contributor?.userId) {
      uids.push(contributor?.userId)
    }
    return uids
  }, [] as string[])
  return uids
}

export const extractUsersUidsFromChatRoomExcludeUser = (
  chatRoom?: ChatRoom,
  uidsExclude?: string[],
) => {
  const extractedUsers = extractUsersUidsFromChatRoom(chatRoom)
  const result = extractedUsers?.filter(user =>
    uidsExclude?.some(uid => uid !== user),
  )
  return result
}

/**
 * Find the best uid for the avatar of the chat room
 * Le but est de s'exclure de la liste des utilisateurs pour voir la personne avec qui on discute
 * s'il y a plus d'un utilisateur dans la conversation on affiche le premier utilisateur
 * @param chatRoom
 * @param me
 * @returns
 */
export const findBestUidForAvatarChatRoom = (
  chatRoom?: ChatRoom,
  me?: User,
) => {
  let uidAvatar = chatRoom?.owner ?? ''
  if ((chatRoom?.contributors?.items?.length ?? 0 > 1) && me) {
    const notMe = extractUsersUidsFromChatRoomExcludeUser(chatRoom, [me.id])
    const notMeLength = notMe?.length ?? 0
    if (notMeLength > 0) {
      uidAvatar = notMe?.[0] ?? chatRoom?.owner ?? ''
    }
  }
  return uidAvatar
}
