import {
  primaryColor,
  borderBottomColor,
  tableHeaderBgColor,
} from '../../theme/app-constantes'
import {Box, Flex, HStack, Icon, IconButton, Link, Tag, Text} from '../ui'
import {List, ListItem, Tooltip, useColorMode} from '@chakra-ui/react'
import React from 'react'
import {ResumeReponse} from './resumeReponse'
import {MdDelete, MdEdit} from 'react-icons/md'
import {EStatus} from '../../types/graphQL'
import {getEStatusDetails} from '../../commons/helpers/screen'
import {Link as DomRouterLink} from 'react-router-dom'
import {Question} from '../../commons/types/API'
import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons'
import {dateFormat_ddMMyyyyHHmm} from '../../commons/constantes'
import {fr} from 'date-fns/locale'
import {format} from 'date-fns'
import {ROUTE_SCHOOL_CONSULTATIONS} from '../../constantes'
import {View} from '../../types/screen'

import {useAuth, useCanDeleteConsultation} from '../../context/AuthContext'

type ConsultationCardProps = {
  idx?: number
  consultation: any
  withActionButton?: boolean
  showFirstQuestion?: boolean
  onUpdateConsultation?: (e: React.MouseEvent, value: string) => void
  onDeleteConsultation?: (e: React.MouseEvent, value: string) => void
  onUpdateReponse?: (e: React.MouseEvent, value: string) => void
  onDeleteReponse?: (e: React.MouseEvent, value: string) => void
}
export const ConsultationCard = ({
  idx,
  consultation,
  showFirstQuestion = false,
  onUpdateConsultation,
  onUpdateReponse,
  onDeleteConsultation,
  onDeleteReponse,

  withActionButton = true,
}: ConsultationCardProps) => {
  const {colorMode} = useColorMode()
  const iconColor = primaryColor({colorMode})

  const [showQuestion, setShowQuestion] = React.useState(
    showFirstQuestion ? (idx === 0 ? true : false) : false,
  )
  const viewQuestion = () => {
    setShowQuestion(!showQuestion)
  }
  const canDeleteItem = useCanDeleteConsultation(consultation)
  const consultationDate = new Date(consultation?.createdAt)
  return (
    <Box pb={8}>
      <Flex justifyContent="space-between" w="full">
        <Flex
          justifyContent={'space-between'}
          w="full"
          bgColor={tableHeaderBgColor({colorMode})}
          p={3}
          rounded="md"
        >
          <Text
            variant="nameFormation"
            color={primaryColor({colorMode})}
            display={'flex'}
            alignItems={{base: 'start', md: 'center'}}
            flexDirection={{base: 'column', md: 'row'}}
          >
            <Tag
              bgColor={getEStatusDetails(consultation?.status as EStatus).value}
              color={'white'}
              fontWeight={'semibold'}
              mr={1}
            >
              {getEStatusDetails(consultation?.status).label}
            </Tag>
            <Link
              to={`${ROUTE_SCHOOL_CONSULTATIONS}/${consultation.id}?view=${View.grid}`}
              as={DomRouterLink}
            >
              {consultation?.title}
            </Link>
          </Text>
          {withActionButton === true ? (
            <HStack>
              <IconButton
                icon={
                  showQuestion === false ? (
                    <TriangleDownIcon color={iconColor} />
                  ) : (
                    <TriangleUpIcon color={iconColor} />
                  )
                }
                aria-label="list"
                variant="ghost"
                mt={-3}
                onClick={viewQuestion}
              />
              <Icon
                color={iconColor}
                as={MdEdit}
                w={5}
                h={5}
                onClick={(e: any) =>
                  onUpdateConsultation?.(e, consultation?.id)
                }
                cursor="pointer"
              />
              <IconButton
                isDisabled={!canDeleteItem}
                aria-label="delete item"
                bg={'transparent'}
                onClick={(e: any) =>
                  onDeleteConsultation?.(e, consultation?.id)
                }
                cursor="pointer"
                _hover={{bg: 'transparent'}}
                icon={
                  <Icon
                    color={iconColor}
                    as={MdDelete}
                    w={5}
                    h={5}
                    cursor="pointer"
                  />
                }
              />
            </HStack>
          ) : (
            <></>
          )}
          {showFirstQuestion === false ? (
            <Link
              to={`${ROUTE_SCHOOL_CONSULTATIONS}/${consultation.id}?view=${View.grid}`}
              as={DomRouterLink}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'end'}
            >
              <Flex
                flexDirection={'column'}
                alignItems={'end'}
                textAlign={'right'}
              >
                <Text variant="nameFormation" color={primaryColor({colorMode})}>
                  {consultation?.questions?.items?.length} questions
                </Text>
                <Text
                  variant="dateCardTopic"
                  cursor={'pointer'}
                  color={primaryColor({colorMode})}
                >
                  Dernière mise à jour le :{' '}
                  {format(new Date(consultationDate), dateFormat_ddMMyyyyHHmm, {
                    locale: fr,
                  })}
                </Text>
              </Flex>
            </Link>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
      <List>
        {consultation?.questions?.items?.length > 0 && showQuestion === true ? (
          consultation?.questions?.items.map((question: Question, idx: any) => (
            <ListItem
              key={idx}
              borderBottom={'1px'}
              borderColor={borderBottomColor({colorMode})}
              _last={{border: 'none'}}
              px={2}
            >
              <ResumeReponse
                question={question}
                onUpdateReponse={onUpdateReponse ?? (() => {})}
                onDeleteReponse={onDeleteReponse ?? (() => {})}
              />
            </ListItem>
          ))
        ) : showQuestion === true ? (
          <Box p={2}>Il n'y a pas de question</Box>
        ) : (
          <></>
        )}
      </List>
    </Box>
  )
}

export default ConsultationCard
