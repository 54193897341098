import {ContainerPage, Box} from '../../../components/ui'
import {useAuthDetails} from '../../../context/AuthContext'
import LastQuestions from '../../../components/Questions/LastQuestions'
import {
  useQuestionsByStatusSortByDate,
  useSchool,
} from '../../../commons/api/hooks'
import {buildFilterByQuestionsSchoolId} from '../../../commons/helpers/graphQLHelper'
import {EStatus} from '../../../types/graphQL'
import {ModelSortDirection} from '../../../commons/types/API'

export const UserDashboard = () => {
  const {authUser, schoolId} = useAuthDetails()
  const {school} = useSchool(authUser?.me?.userSchoolId as string)

  const {questions} = useQuestionsByStatusSortByDate({
    limit: 100,
    status: EStatus.PUBLISHED,
    sortDirection: ModelSortDirection.DESC,
    filter: {
      ...buildFilterByQuestionsSchoolId(schoolId),
    },
  })

  return (
    <>
      <ContainerPage>
        <Box p={10}>
          <LastQuestions
            questions={questions?.items}
            welcomeMessage={school?.description}
            isAdmin={false}
          />
        </Box>
      </ContainerPage>
    </>
  )
}
