export const localStorageTokenKey = 'auth_token'
//export const localStorageLastChatRoom = 'last-chat-room-id'
export const localStorageLastMessage = 'last-chat-room-message-id'
export const MAX_REZISE_IMAGE = 500
export const MAX_REZISE_IMAGE_THUMB = 1000
//FRONT
export const PAGINATION_TABLE_DISPLAY = 10
export const PAGINATION_CONSULTATIONS_DISPLAY = 20
export const PAGINATION_TABLE_QUESTION = 20
export const PAGINATION_TOPIC_DISPLAY = 10
export const PAGINATION_MESSAGERIE_CONVERSATION = 10
//ADMIN
export const PAGINATION_ADMIN_CONSULTATIONS_DISPLAY = 10
export const PAGINATION_ADMIN_TABLE_QUESTION = 10
export const PAGINATION_ADMIN_USERS_DISPLAY = 20
export const PAGINATION_ADMIN_CATEGORIES_DISPLAY = 10
export const PAGINATION_ADMIN_TABLE_TAGS = 10
export const DASHBOARD_MAX_QUESTIONS = 4
export const NB_DAYS_OLD_TOPICS = 30
//
export const dateFormat_ddMMyyyy = 'dd/MM/yyyy'
export const dateFormat_ddMMyyyyHHmm = 'dd/MM/yyyy hh:mm'
export const dateFormat_M_MMM_yyyy = 'M MMMM yyyy à hh:mm'
export const dateFormat_M_MMM_yyyy_date = 'dd MMM yyyy'
export const dateFormat_M_MMM_yyyy_HHmm = 'dd MMM yyyy à hh:mm' // 25/05/2023 à 15h00
export const dateFormat_dMMMM_yyyy_HHmm = "d MMMM yyyy 'à' HH'h'mm" // 25 mai 2023 à 15h00
export const dateFormat_dMMMM_yyyy = 'd MMMM yyyy' // 25 mai 2023
export const defaultWorkspaceImageLight = '/images/workspace-bg.png'
export const defaultWorkspaceImageDark = '/images/workspace-bg-light.png'
export const defaultWorkspaceImage = '/images/workspace-bg.png'

export const wrongGdriveUrl = 'https://drive.google.com/file/d/'
export const defaultGdriveUrl = (id: string) =>
  `https://drive.google.com/uc?export=download&id=${id}`

export const heightsScreen = {
  IPhoneMax: 428,
  IPhone: 390,
}

export const sizes = {
  xs: 0,
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
}
// 12 = 100%
export const grid6 = {
  xs: 12, // EN XS  1 COL PREND 12 ( DONC 100%)
  sm: 12,
  md: 2, // EN MD  1 COL PREND 2 ( DONC 16.66*)
  lg: 2,
  xl: 2,
  xxl: 2,
}
export const grid4 = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 3,
  xxl: 3,
}
export const grid2 = {
  xs: 12, // EN XS  1 COL PREND 12 ( DONC 100%)
  sm: 12,
  md: 6, // EN MD  1 COL PREND 6 ( DONC 50%)
  lg: 6,
  xl: 6,
  xxl: 6,
}
export const grid1 = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12,
}
export const URLregMatch =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\\?[a-zA-Z-_%\\/@?]+)*([^\\/\w\\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/

export const headerHeight = '110px'
export const footerHeight = '98px'
export const minHeightPage = `calc(100vh - ${footerHeight} - ${headerHeight})`
export const customMinHeightPage = height => {
  return `calc(100vh - ${footerHeight} - ${headerHeight} - ${height})`
}
