import React from 'react'
import {CreateChatRoomInput, User} from '../../commons/types/API'
import MultiSelectUsers from '../Selects/multiselect-users'
import {Button, Flex, FormInput, FormTextArea, HStack} from '../ui'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuthDetails} from '../../context/AuthContext'

export type SubmitChatRoomFormValues = CreateChatRoomInput & {
  message?: string
}

type ChatRoomFormProps = {
  isGroupForm: boolean
  isLoading: boolean
  initialValues?: Partial<CreateChatRoomInput>
  onClose: () => void
  onSubmit: (createChatRoom: CreateChatRoomInput, message: string) => void
}

export const buildEditorsArray = (users: User[], originalUsers: any) => {
  const editorIds: string[] = []

  const newEditors = users.filter(
    (user: any) =>
      !originalUsers?.some(
        (originalUser: any) => originalUser.id === user.value.id,
      ),
  )
  const toDel = originalUsers?.filter(
    (userData: any) =>
      !users?.some(
        (originalUser: any) => originalUser.value.id === userData.id,
      ),
  )
  newEditors.map((user: any) => {
    return editorIds.push(user.value.id)
  })

  const data = [{users, toDel, toAdd: editorIds}]
  return data
}

function ChatRoomForm({
  initialValues,
  onSubmit,
  onClose,
  isGroupForm,
  isLoading,
}: ChatRoomFormProps) {
  const {schoolGroupId, uid} = useAuthDetails()
  // const {users} = useUsersBySchoolId({
  //   userSchoolId: schoolId,
  // })
  const maxUsers = isGroupForm ? 10 : 1
  const minUsers = isGroupForm ? 2 : 1

  const ChatRoomSchema = Yup.object().shape({
    name: !isGroupForm
      ? Yup.string().notRequired()
      : Yup.string()
          .min(6, 'Minimum 6 caractères')
          .max(50, 'Maximum 50 caractères')
          .required('Entrez un nom de groupe'),
    description: isGroupForm
      ? Yup.string().notRequired()
      : Yup.string()
          .min(6, 'Minimum 6 caractères')
          .max(100, 'Maximum 100 caractères')
          .required('Entrez une description'),
    message: isGroupForm
      ? Yup.string().notRequired()
      : Yup.string()
          .min(6, 'Minimum 6 caractères')
          .max(200, 'Maximum 200 caractères')
          .required('Entrez un message'),
    editors: Yup.array()
      .of(Yup.string())
      .min(
        minUsers,
        isGroupForm ? 'Minimum 2 participants' : 'Minimum 1 membre',
      )
      .max(
        maxUsers,
        isGroupForm ? 'Maximum 10 participants' : 'Maximum 1 membre',
      ) //A déterminer
      .required('Champ obligatoire'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: initialValues?.name ?? '',
      description: initialValues?.description ?? '',
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [], // ajout uid?
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      updatedAt: initialValues?.updatedAt ?? '',
      createdAt: initialValues?.createdAt ?? '',
      message: '',
    },
    onSubmit(values: SubmitChatRoomFormValues) {
      //on veut séparer le message du input de la room
      const message = values.message
      const {message: sup, ...createChatRoom} = values
      onSubmit(createChatRoom, message ?? '')
    },
    validationSchema: ChatRoomSchema,
  })

  //console.log('formik err', formik.errors)

  const originalUsers = initialValues?.editors

  const handleEditorsChange = (users: any) => {
    // console.log('users', users)
    // console.log('isGroupForm', isGroupForm)
    let description = ''
    let name = ''
    let editors
    if (isGroupForm) {
      const [data] = buildEditorsArray(users, originalUsers)
      //en mode 'creer un groupe ' : description = Groupe de Discussion créé le {date}
      name = 'Groupe de Discussion'
      description =
        'Groupe de Discussion créé le ' + new Date().toLocaleDateString()
      editors = data.toAdd
    } else {
      //en mode 'contacter un membre' : Nom du groupe = Discussion avec {username}
      name = 'Discussion avec ' + users.value.username
      description =
        'Discussion avec ' +
        users.value.username +
        ' créé le ' +
        new Date().toLocaleDateString()
      editors = [users.value.id]
      formik.setFieldValue('name', name)
    }
    formik.setFieldValue('description', description)
    formik.setFieldValue('editors', editors)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      {isGroupForm ? (
        <>
          <FormInput
            placeholder="Entrez un nom de groupe..."
            //helperText="Nom du groupe de discussion"
            label="Nom du groupe"
            name="name"
            id="name"
            onChange={formik.handleChange}
            value={formik.values?.name}
            errorMessage={formik.errors?.name}
          />
          <MultiSelectUsers
            my={3}
            label={'Participants'}
            placeholder="Sélectionnez les participants"
            //helperText="Sélectionnez les participants au groupe de discussion"
            onChange={handleEditorsChange}
            value={formik.values?.editors?.map(username => username as string)}
            //users={users?.items as User[]}
            excludeMe={true}
            errorMessage={formik.errors?.editors}
          />
          {/* <FormControl>
            <FormHelperText color="red.500" role="alert">
              {formik.errors.editors}
            </FormHelperText>
          </FormControl> */}
        </>
      ) : (
        <>
          <MultiSelectUsers
            isMulti={false}
            my={3}
            label={'Membre'}
            placeholder="Sélectionnez le membre"
            onChange={handleEditorsChange}
            value={formik.values?.editors?.map(username => username as string)}
            //users={users?.items as User[]}
            excludeMe={true}
            errorMessage={formik.errors?.editors}
          />
          {/* <FormControl>
            <FormHelperText color="red.500" role="alert">
              {formik.errors.editors}
            </FormHelperText>
          </FormControl> */}
          <FormTextArea
            placeholder="Ecrivez votre message..."
            label="Engagez la discussion"
            name="message"
            id="message"
            onChange={formik.handleChange}
            value={formik.values?.message}
            errorMessage={formik.errors?.message}
          />
        </>
      )}
      <Flex justifyContent="flex-end" mt={5}>
        <HStack>
          <Button variant="outline" onClick={onClose}>
            Annuler
          </Button>
          <Button type="submit" isLoading={isLoading}>
            {isGroupForm ? 'Créer' : 'Contacter'}
          </Button>
        </HStack>
      </Flex>
    </form>
  )
}

export default ChatRoomForm
