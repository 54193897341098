//contantes principales de colors utilisé par chakra
//experimental -> voir  AuthContainer
//to avoid magic string

// Comme vous aviez commencé j'ai tout regroupé par paire
export const bgColorLight = 'gray.50'
export const bgColorDark = 'gray.800'

export const bgColor = (props: any) => {
  return props.colorMode === 'light' ? bgColorLight : bgColorDark
}

export const primaryColorLight = 'blue.500'
export const primaryColorDark = 'blue.200'

export const primaryColor = (props: any) => {
  return props.colorMode === 'light' ? primaryColorLight : primaryColorDark
}

export const primaryColorRevert = (props: any) => {
  return props.colorMode === 'light' ? primaryColorDark : primaryColorLight
}

export const borderBottomColorLight = 'gray.100'
export const borderBottomColorDark = 'gray.500'

export const borderBottomColor = (props: any) => {
  return props.colorMode === 'light'
    ? borderBottomColorLight
    : borderBottomColorLight
}

export const tableHeaderBgColor = (props: any) => {
  return props.colorMode === 'light' ? 'blue.50' : 'blue.900'
}

export const textButtonColor = (props: any) => {
  return props.colorMode === 'light' ? 'white' : 'gray.800'
}

export const textButtonColorAlwaysLight = 'white'

export const hoverTransparentBgLight = 'blue.50'
export const hoverTransparentBgDark = 'blue.900'

export const hoverTransparentBg = (props: any) => {
  return props.colorMode === 'light'
    ? hoverTransparentBgLight
    : hoverTransparentBgDark
}
export const activeMenuBgLight = 'gray.200'
export const activeMenuBgDark = 'gray.700'

export const activeMenuBg = (props: any) => {
  return props.colorMode === 'light' ? activeMenuBgLight : activeMenuBgDark
}

export const alertColorLight = 'red.500'
export const alertColorDark = 'red.200'

export const alertColor = (props: any) => {
  return props.colorMode === 'light' ? alertColorLight : alertColorDark
}

export const titleRotateLight = 'gray.200'
export const titleRotateDark = 'gray.900'

export const titleRotateColor = (props: any) => {
  return props.colorMode === 'light' ? titleRotateLight : titleRotateDark
}

export const linkSidebarLight = 'gray.700'
export const linkSidebarDark = 'white'

export const linkSidebarColor = (props: any) => {
  return props.colorMode === 'light' ? linkSidebarLight : linkSidebarDark
}

export const linkSidebarColorRevert = (props: any) => {
  return props.colorMode === 'light' ? linkSidebarDark : linkSidebarLight
}

export const bgSidebarLight = 'white'
export const bgSidebarDark = 'gray.700'

export const bgSidebarColor = (props: any) => {
  return props.colorMode === 'light' ? bgSidebarLight : bgSidebarDark
}

export const primaryTextLight = 'gray.800'
export const primaryTextDark = 'white'

export const primaryTextColor = (props: any) => {
  return props.colorMode === 'light' ? primaryTextLight : primaryTextDark
}

export const primaryTextColorRevert = (props: any) => {
  return props.colorMode === 'light' ? primaryTextDark : primaryTextLight
}

export const textLinkLight = 'green.500'
export const textLinkDark = 'green.200'

export const textOverLight = 'green.600'
export const textOverDark = 'green.300'

export const defautMarginTop = '5'

export const customScrollbar = (props: any) => {
  return {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: props.colorMode === 'light' ? '#00000030' : '#ffffff50',
      borderRadius: '24px',
    },
  }
}
