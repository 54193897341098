import React from 'react'
import {Box, Container, Grid, GridItem, Heading, Stack} from '../ui'
import {CardProgrammeForUser} from '../Card/CardProgrammeForUser'
import {defautMarginTop} from '../../theme/app-constantes'
import {useAuth} from '../../context/AuthContext'
import {AlertInfo} from '../ui/Alert'

export const UserCourses = () => {
  const {authUser} = useAuth()

  const coursesId = React.useMemo(
    () =>
      authUser.me?.courses
        ? authUser.me?.courses.items.map(userCourse => userCourse?.courseID)
        : [],
    [authUser.me?.courses],
  )

  return (
    <Container variant="generalBg" maxW="full">
      <Stack spacing={4}>
        <Heading>Mes formations</Heading>
        <Box>
          {coursesId?.length > 0 ? (
            <Grid
              templateColumns={{md: 'repeat(2,1fr)', xl: 'repeat(3, 1fr)'}}
              gap={4}
              mt={defautMarginTop}
            >
              {coursesId.map((courseID, idx) => (
                <GridItem key={idx}>
                  <CardProgrammeForUser courseID={courseID} />
                </GridItem>
              ))}
            </Grid>
          ) : (
            <AlertInfo
              title={'Aucune formation pour le moment'}
              description={
                'Vous devez avoir souscrit à une formation pour la voir ici'
              }
            ></AlertInfo>
          )}
        </Box>
      </Stack>
    </Container>
  )
}

export default UserCourses
