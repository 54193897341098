import {Box, useBreakpointValue} from '@chakra-ui/react'
import {Dispatch, SetStateAction, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useChatRoomById} from '../../../commons/api/hooks/messagerie'
import {ChatRoom, UserChatRoom} from '../../../commons/types/API'
import {DiscussionListMobile, MessageriePage} from './index'
import {useAuthDetails, useCanReadChatRoom} from '../../../context/AuthContext'
import Restricted from '../../common/Restricted'
import {Error404} from '../../common/Error404'
import {useGlobalContext} from '../../../context/GlobalContext'

type DiscussionByIdProps = {
  userChatRooms?: (UserChatRoom | null)[]
  searchDiscussion?: string
  setSearchDiscussion: Dispatch<SetStateAction<string>>
}
export default function DiscussionById({
  userChatRooms,
  searchDiscussion,
  setSearchDiscussion,
}: DiscussionByIdProps) {
  const {uid} = useAuthDetails()
  const {chatRoomId} = useParams()
  const {chatRoom, isLoading} = useChatRoomById(chatRoomId ?? '')
  const canRead = useCanReadChatRoom(chatRoom as ChatRoom)

  const isMeInRoom = chatRoom?.contributors?.items.some(
    contributor => contributor?.userId === uid,
  )
  // const isMobile = useBreakpointValue({base: true, sm: false})

  // const {setHideFooter} = useGlobalContext()
  // useEffect(() => {
  //   setHideFooter(isMobile ?? false)
  //   return () => {
  //     setHideFooter(false)
  //   }
  // }, [isMobile, setHideFooter])

  if (chatRoom && !isLoading && !canRead) {
    return (
      <Restricted message="Vous ne pouvez accèder à cette discussion"></Restricted>
    )
  }
  if (chatRoom === null) {
    return <Error404 message="Cette discussion n'existe pas"></Error404>
  }
  return (
    <>
      <Box display={{base: 'none', md: 'inline'}}>
        <MessageriePage
          userChatRooms={userChatRooms}
          chatRoom={isMeInRoom ? chatRoom : undefined}
          setSearchDiscussion={setSearchDiscussion}
          searchDiscussion={searchDiscussion}
          isLoading={isLoading}
        />
      </Box>
      <Box display={{base: 'inline', md: 'none'}}>
        <DiscussionListMobile chatRoom={chatRoom} />
      </Box>
    </>
  )
}
