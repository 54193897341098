import {useEffect, useState} from 'react'
import {Route, Routes, useSearchParams} from 'react-router-dom'
import {useGetMeChatRooms} from '../../../commons/api/hooks/messagerie'
import {sortByDate} from '../../../commons/helpers/dataHelper'
import {ModelSortDirection} from '../../../commons/types/API'
import DiscussionById from './DiscussionById'
import {MessageriePage} from './index'

import MyDiscussion from './MyDiscussion'
import {useBreakpointValue} from '@chakra-ui/react'
import {useGlobalContext} from '../../../context/GlobalContext'
import {useCheckWindowHeightBelow} from '../../../hooks/screenHooks'
import {heightsScreen} from '../../../commons/constantes'

export const MessagerieRouteur = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const pageQueryPage = searchParams.get('page')
  const [searchDiscussion, setSearchDiscussion] = useState('')

  const {chatRooms, isLoading} = useGetMeChatRooms({noCache: false})
  //console.log('chatRooms', chatRooms)
  const filteredRooms = chatRooms?.items.filter(room =>
    room?.chatRoom?.name.toLowerCase().includes(searchDiscussion.toLowerCase()),
  )

  useEffect(() => {
    if (searchDiscussion && pageQueryPage !== '0') {
      setSearchParams({page: '0'})
    }
  }, [pageQueryPage, searchDiscussion, setSearchParams])

  //console.log(chatRooms)
  //const chatRoomsSorted = []
  const chatRoomsSorted = sortByDate({
    data: filteredRooms,
    direction: ModelSortDirection.ASC,
    filedName: 'updatedAt',
    subLevelName: 'chatRoom',
  })
  const underHeight = useCheckWindowHeightBelow(heightsScreen.IPhoneMax + 50)
  const isMobile = useBreakpointValue({base: true, sm: false})
  const needHide = isMobile || underHeight
  const {setHideFooter} = useGlobalContext()
  useEffect(() => {
    setHideFooter(needHide)
    return () => {
      setHideFooter(false)
    }
  }, [isMobile, needHide, setHideFooter])

  return (
    <Routes>
      <Route
        path="/"
        element={
          <MessageriePage
            userChatRooms={chatRoomsSorted}
            searchDiscussion={searchDiscussion}
            setSearchDiscussion={setSearchDiscussion}
            isLoading={isLoading}
          />
        }
      />

      <Route
        path="/conversations"
        element={
          <MyDiscussion
            userChatRooms={chatRoomsSorted}
            searchDiscussion={searchDiscussion}
            setSearchDiscussion={setSearchDiscussion}
          />
        }
      />
      <Route
        path="/conversations/:chatRoomId"
        element={
          <DiscussionById
            setSearchDiscussion={setSearchDiscussion}
            searchDiscussion={searchDiscussion}
            userChatRooms={chatRoomsSorted}
          />
        }
      />
    </Routes>
  )
}
