import React from 'react'
import {UseMutationResult} from 'react-query'
import {modalYesNoPromise} from '../components/Modals/YesNo'

/**
 * Do not forget to use this hook with sideEffect to update NextToken
 * 
 * React.useEffect(() => {
    setNextNextToken(questions?.nextToken)
  }, [questions, setNextNextToken])
 * @deprecated utiliser hooks/usePagination instead
 * @param param0 
 * @returns 
 */
export const usePagination = () => {
  const [nextToken, setNextToken] = React.useState<string | null>(null)
  const [nextNextToken, setNextNextToken] = React.useState<string | null>(null)
  const [previousTokens, setPreviousTokens] = React.useState<(string | null)[]>(
    [],
  )
  const hasNext = !!nextNextToken
  const hasPrev = !!previousTokens.length

  const next = () => {
    setPreviousTokens(prev => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }
  const prev = () => {
    setNextToken(previousTokens.pop() ?? null)
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  const reset = () => {
    setNextToken(null)
    setPreviousTokens([])
    setNextNextToken(null)
  }
  // React.useEffect(() => {
  //   setNextNextToken(data?.nextToken)
  // }, [data])

  return {
    next,
    prev,
    reset,
    nextToken,
    setNextToken,
    setNextNextToken,
    hasNext,
    hasPrev,
  }
}

// export const useSwitch = () => {
//   const [iconList, setIconList] = React.useState<boolean>(true)
//   const [iconListCard, setIconListCard] = React.useState<boolean>(false)
//   const switchVue = (value: number) => {
//     if (value === 1) {
//       setIconList(false)
//       setIconListCard(true)
//     } else {
//       setIconList(true)
//       setIconListCard(false)
//     }
//   }
//   return {switchVue, iconList, iconListCard}
// }

/**
 * pour fonctionner il faut donner l'emsemble des données de la vue : allData
 * ensuite passer un ID à handleDelete
 * attention : s'utilise avec <ModalContainer />
 * @param param0
 * @returns
 */

interface useOpenPopupDeleteParams<T> {
  allData?: (T | null)[] | undefined
  mutationDelete: UseMutationResult<T | undefined, any, string | undefined>
  children: React.ReactChild
  infoMessage?: string
  title?: string
  confirmLabel?: string
  header?: string
  labelOfType?: string
}
export const useOpenPopupDelete = <
  T extends {id?: string; title?: string; name?: string},
>({
  allData,
  mutationDelete,
  children,
  infoMessage,
  title,
  confirmLabel,
  header,
  labelOfType,
}: useOpenPopupDeleteParams<T>) => {
  const handleDelete = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      const data = allData?.find(elm => elm?.id === value)
      modalYesNoPromise({
        title: title ?? 'Supprimer',
        confirmLabel: confirmLabel ?? 'Supprimer',
        infoMessage: infoMessage ?? 'Voulez vous vraiment supprimer ?',
        header: header ?? data?.title ?? data?.name,
        data,
        labelOfType: labelOfType ?? 'prog',
        children,
      })
        .then(() => {
          mutationDelete.mutate(value)
        })
        .catch(() => {})
    },
    [allData, children, mutationDelete],
  )
  return handleDelete
}
