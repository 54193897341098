import {Select as ChakraSelect, SelectProps} from '@chakra-ui/react'
import {FormControl, FormHelperText, FormLabel} from './Input'
import {InputProps, FormControlProps} from '@chakra-ui/react'
export type SelectionProps = Pick<InputProps, 'id'> &
  SelectProps &
  Pick<FormControlProps, 'label'> & {
    text?: string
    errorMessage?: string
  }

export const Select = ({...props}: SelectionProps) => {
  const {errorMessage, ...rest} = props
  return (
    <FormControl id={props?.id}>
      <FormLabel htmlFor={props?.id} mb={1}>
        {props?.label}
      </FormLabel>
      <ChakraSelect {...rest}>{props.children}</ChakraSelect>
      <FormHelperText>{props?.text}</FormHelperText>
      <FormHelperText color="red.500">{props?.errorMessage}</FormHelperText>
    </FormControl>
  )
}
export default Select
