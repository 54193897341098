import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useQuestions} from '../../../commons/api/hooks'
import {Question} from '../../../commons/types/API'
import {ROUTE_ADMIN_FAQ_CREATE_CONSULTATION} from '../../../constantes'
import {useCreateConsultationStepper} from '../../../context/CreateConsultationContext'
import {EStatus} from '../../../types/graphQL'
import {CreateConsultationSteps} from '../../../types/screen'
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Text,
} from '../../ui'
import {HeaderStep} from './HeaderStep'

type StepSelectQuestionConsultationProps = {
  step?: CreateConsultationSteps
}

export const StepSelectQuestionConsultation = ({
  step = CreateConsultationSteps.create,
}: StepSelectQuestionConsultationProps) => {
  const navigate = useNavigate()
  const {consultation, questions, addQuestion, deleteQuestion} =
    useCreateConsultationStepper()
  // //console.log('consultation', consultation)
  // //console.log('questions', questions)
  React.useEffect(() => {
    if (!consultation) {
      navigate(
        `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.create}`,
      )
    }
  })
  const {questions: questionsToAdd} = useQuestions({
    limit: 10000,
    //nextToken: nextToken,
    filter: {status: {eq: EStatus.NEW}},
  })
  const [questionFiltered, setQuestionFiltered] = React.useState<
    (Question | null)[] | undefined
  >(questionsToAdd?.items ?? [])

  React.useEffect(() => {
    setQuestionFiltered(questionsToAdd?.items ?? [])
  }, [questionsToAdd])

  const handleAddToConsultation = (question: Question) => {
    addQuestion(question)

    const newQuestions = questionFiltered?.filter(item => item !== question)
    if (questionFiltered instanceof Array) {
      setQuestionFiltered(newQuestions)
    }
  }

  const handleDeleteFromConsultation = (question: Question) => {
    deleteQuestion(question)
    if (questionFiltered instanceof Array) {
      setQuestionFiltered([...questionFiltered, question])
    }
  }
  return (
    <>
      <HeaderStep
        title="Etape de selection des questions (STEP2)"
        consultation={consultation}
        questions={questions}
        step={2}
      />
      <Container variant="generalBg" maxW="full">
        <SimpleGrid columns={{base: 1, md: 2}} spacing={4}>
          <Box p={4} border={'2px'} rounded="md" borderColor="gray.200">
            <Heading variant="question">Questions à traiter</Heading>
            <Divider my={4}></Divider>
            {questionFiltered?.map((q: any, idx: number) => (
              <div key={idx}>
                <Flex w="full" my={2} align="center">
                  <Button
                    size={'sm'}
                    onClick={e => handleAddToConsultation(q)}
                    mr={3}
                  >
                    Add
                  </Button>
                  <Text variant="label">{q.title}</Text>
                </Flex>
              </div>
            ))}
          </Box>
          <Box p={4} border={'2px'} rounded="md" borderColor="gray.200">
            <Heading variant="question">
              Questions ajoutées à la consultation
            </Heading>
            <Divider my={4}></Divider>
            {questions.map((q: any, idx: number) => (
              <div key={idx}>
                <Flex w="full" my={2} align="center">
                  <Button
                    size={'sm'}
                    onClick={e => handleDeleteFromConsultation(q)}
                    mr={3}
                  >
                    Del
                  </Button>
                  <Text variant="label">{q.title}</Text>
                </Flex>
              </div>
            ))}
          </Box>
        </SimpleGrid>
        <Flex w="full" justifyContent={'flex-end'} mt={4}>
          <HStack>
            {' '}
            {/* <Button onClick={() => addQuestion(questionsToAdd?.items[0])}>
            ADD question
          </Button> */}
            <Button
              onClick={() =>
                navigate(
                  `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.create}`,
                )
              }
            >
              Précédent
            </Button>
            <Button
              disabled={questions.length === 0}
              onClick={() =>
                navigate(
                  `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.answer}`,
                )
              }
            >
              Suivant
            </Button>
          </HStack>
        </Flex>
      </Container>
    </>
  )
}
