import React from 'react'
import {
  Avatar,
  Box,
  Container,
  Flex,
  Icon,
  IconButton,
  Link,
  Stack,
  Grid,
  GridItem,
  Text,
} from '../ui'
import {
  Course,
  CreateCourseInput,
  UpdateCourseInput,
} from '../../commons/types/API'

import {Table as ReactTable} from '../ui/ReactTable'
import {HeaderTable} from '../Table/HeaderTable'
import {Pagination} from '../Table/Pagination'
import {MdDelete, MdEdit, MdGroups, MdWorkspaces} from 'react-icons/md'

import {
  useCoursesBySchoolId,
  useCreateCourse,
  useDeleteCourse,
  useUpdateCourse,
} from '../../commons/api/hooks/course'
import {modalYesNoPromise} from '../Modals/YesNo'
import ModalContainer from 'react-modal-promise'
import {ModalForm} from '../Modals'
import {useDisclosure, Checkbox} from '@chakra-ui/react'
import {CourseForm} from '../Forms/CourseForm'
import {useAuth, useUserDetailSchoolGroupId} from '../../context/AuthContext'
import CardProgramme from '../Card/CardProgramme'
import {PAGINATION_TABLE_DISPLAY} from '../../commons/constantes'
import {usePagination} from '../../hooks/commonActions'
import {View} from '../../types/screen'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import {AlertInfo} from '../ui/Alert'

// type CourseListProps = {
//   courses: Array<Course> | []
// }

export const CourseList = () => {
  const [selectedCourse, setSelectedCourse] = React.useState<Course>()
  const limit = PAGINATION_TABLE_DISPLAY
  const [searchTerm, setSearchTerm] = React.useState('')

  const {next, prev, nextToken, setNextNextToken, hasNext, hasPrev} =
    usePagination()
  const {courses, isLoading} = useCoursesBySchoolId({
    limit,
    nextToken: nextToken,
    filter: {
      name: {
        contains: searchTerm.toLocaleLowerCase(),
      },
    },
  })
  React.useEffect(() => {
    setNextNextToken(courses?.nextToken)
  }, [courses, setNextNextToken])

  const [isEdit, setIsEdit] = React.useState<boolean>(false)

  const [view, setView] = React.useState(View.grid)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const mutationDelete = useDeleteCourse()
  const mutationCreate = useCreateCourse({onSuccess: onClose})
  const mutationUpdate = useUpdateCourse({onSuccess: onClose})
  const data = React.useMemo(
    () => [...((courses?.items as Array<Course>) ?? [])],
    [courses],
  )

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    // reset()
  }

  const handleModalSave = (values: UpdateCourseInput & CreateCourseInput) => {
    // values.groupsCanAccess = [schoolGroupId]
    if (isEdit) {
      mutationUpdate.mutate(values)
    } else {
      mutationCreate.mutate(values)
    }
  }

  const handleDelete = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()

      modalYesNoPromise({
        title: 'Supprimer',
        confirmLabel: 'Supprimer',
        infoMessage: 'Souhaitez vous vraiment supprimer la formation ?',
        data: data.find(elm => elm.id === value),
        labelOfType: 'prog',
        // children: <div>Souhaitez vous vraiment supprimer la formation </div>,
      })
        .then(() => {
          mutationDelete.mutate(value)
        })
        .catch(() => {})
    },
    [mutationDelete],
  )

  const handleOpenUpdate = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      //console.log('value', value)

      const course = data.find(elm => elm.id === value)
      setSelectedCourse(course)
      setIsEdit(true)
      onOpen()
    },
    [data, onOpen],
  )

  const handleOpenNew = React.useCallback(() => {
    setSelectedCourse(undefined)
    setIsEdit(false)
    onOpen()
  }, [onOpen])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nom formation',
        accessor: 'username',
        Cell: ({row}: any) => {
          // //console.log('row', row)
          return (
            <Flex align="center">
              <Avatar
                src={
                  row?.original?.image ??
                  'https://cdn.iconscout.com/icon/free/png-256/workspace-1467359-1240505.png'
                }
                mr={2}
                variant="wsList"
              />
              <Text variant="label">{row?.original?.name}</Text>
            </Flex>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row}: any) => (
          <Flex justifyContent="flex-end" w="full">
            <IconButton
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdEdit} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) =>
                handleOpenUpdate(e, row?.original?.id)
              }
            />
            {/* LINK to workspaces de ce programme */}
            <Link to={'#'}>
              <IconButton
                size="sm"
                aria-label="Call Segun"
                variant="ghost"
                icon={
                  <Icon
                    variant="generalIcons"
                    as={MdWorkspaces}
                    color="gray.500"
                  />
                }
              />
            </Link>
            {/* Liens vers les membres triés par programmes ou popup alec list de user */}
            <Link to={'#'}>
              <IconButton
                size="sm"
                aria-label="Call Segun"
                variant="ghost"
                icon={
                  <Icon variant="generalIcons" as={MdGroups} color="gray.500" />
                }
              />
            </Link>
            <IconButton
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdDelete} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) =>
                handleDelete(e, row?.original?.id)
              }
            />
          </Flex>
        ),
      },
    ],
    [handleDelete, handleOpenUpdate],
  )

  //Props spécifiques pour chaque cellule <Td>
  const cellProps = (cellInfo: any) => {
    switch (cellInfo.column.id) {
      case 'selection':
        return {
          _position: 'select',
          util: 'user',
        }
      case 'actions':
        return {
          _position: 'actions',
          util: 'user',
        }
    }
  }

  //Props pour chaque ligne <Tr>
  // const rowProps = (row: any) => ({})
  const hasCourses = data && data.length > 0
  return (
    <>
      <Container variant="generalBg" maxW="full">
        <Stack spacing={4}>
          <HeaderTable
            util="programmes"
            onSearch={handleSearch}
            onAdd={handleOpenNew}
            view={view}
            setView={setView}
          />
          {isLoading && <TextRowSkeleton />}
          {!isLoading && !hasCourses && (
            <Flex p={4} alignItems="center" justifyContent="center" w={'100%'}>
              <AlertInfo
                title={'Aucune formation trouvée'}
                description={
                  'Aucune formation trouvée, créér en une en cliquant sur : Créer une formation!'
                }
              ></AlertInfo>
            </Flex>
          )}
          {!isLoading && hasCourses && view === View.list ? (
            <Box>
              <ReactTable
                // rowProps={rowProps}
                cellProps={cellProps}
                columns={columns}
                data={data}
                controlledPageCount={0}
                controlledPageIndex={0}
                isLoading={isLoading}
                isFetching={false}
                onFetchData={function (): void {
                  // throw new Error('Function not implemented.')
                }}
                setPageIndex={function (): void {
                  //throw new Error('Function not implemented.')
                }}
                setSelectedRowsId={function (): void {
                  //console.log('selectedRowIds', selectedRowIds)
                }}
                totalCount={0}
                labelData={'Formations'}
                identifier={'id'}
                controlledHiddenColumns={[]}
                initialSortBy={[]}
                hideHeader={false}
              />
            </Box>
          ) : (
            <Box mt={10}>
              <Grid
                templateColumns={{md: 'repeat(2,1fr)', xl: 'repeat(3, 1fr)'}}
                gap={4}
                mt={4}
              >
                {data ? (
                  data.map((course, idx) => (
                    <GridItem key={idx} aria-label="Carte de programme">
                      <CardProgramme
                        course={course}
                        suppCourse={(e: React.MouseEvent, value: string) =>
                          handleDelete(e, value)
                        }
                        editCourse={(e: React.MouseEvent, value: string) =>
                          handleOpenUpdate(e, value)
                        }
                      />
                    </GridItem>
                  ))
                ) : (
                  <Text
                    variant="popup"
                    children="Aucune formation pour le moment"
                  />
                )}
              </Grid>
            </Box>
          )}

          <Pagination
            next={next}
            prev={prev}
            current={nextToken}
            hasNext={hasNext}
            hasPrev={hasPrev}
            isLoading={false}
            hidden={!hasPrev && !hasNext}
          />
        </Stack>
        <ModalForm
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          title={isEdit ? 'Modifier une formation' : 'Ajouter une formation'}
        >
          <CourseForm
            initialValues={{
              //courseSchoolId: selectedCourse?.school?.id ?? schoolId,
              ...(selectedCourse as Course),
            }}
            onSubmit={handleModalSave}
            onClose={onClose}
            isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
          />
        </ModalForm>
        <ModalContainer />
      </Container>
    </>
  )
}

export default CourseList
