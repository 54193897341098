import {Consultations} from '../../../components/Consultations'
import {ContainerPage} from '../../../components/ui'

export const ConsultationQuestions = () => {
  return (
    <ContainerPage>
      <Consultations tabIdx={0}></Consultations>
    </ContainerPage>
  )
}
