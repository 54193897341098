import * as Yup from 'yup'
import {
  CreateWorkspaceInput,
  UpdateWorkspaceInput,
} from '../../commons/types/API'
import {Box, Button, FormInput, FormTextArea, Flex} from '../ui'
import {useFormik} from 'formik'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react'
import SelectCourses from '../Selects/select-courses'
import {useAuthDetails} from '../../context/AuthContext'

type WorkspaceFormProps = {
  initialValues: Partial<UpdateWorkspaceInput> & Partial<CreateWorkspaceInput>
  onSubmit: (formValue: UpdateWorkspaceInput & CreateWorkspaceInput) => void
  onClose: () => void
  isLoading?: boolean
}

export const WorkspaceForm: React.FC<WorkspaceFormProps> = ({
  initialValues,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const {schoolId, schoolGroupId} = useAuthDetails()
  //console.log('log initialValues', initialValues)

  const SignInSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(100, 'Maximum 100 caractères')
      .required('Champ obligatoire'),
    // workspaceCourseId: Yup.string().test(
    //   'school',
    //   'Veuillez selectionner une formation',
    //   value => !!value,
    // ),
    workspaceSchoolId: Yup.string().test(
      'school',
      'Veuillez selectionner une école',
      value => !!value,
    ),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      name: initialValues?.name ?? '',
      description: initialValues?.description ?? '',
      courseWorkspacesId: initialValues?.courseWorkspacesId,
      workspaceSchoolId: initialValues?.workspaceSchoolId ?? schoolId,
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
    },
    onSubmit: (values: UpdateWorkspaceInput & CreateWorkspaceInput) => {
      onSubmit(values)
      //console.log('submit WS values', values)
    },
    validationSchema: SignInSchema,
  })

  //console.log('ormik.values?.topicWorkspaceId', formik.values?.topicWorkspaceId)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        display="inline-flex"
        flexWrap="wrap"
        width="100%"
        justifyContent="center"
      >
        <FormInput
          placeholder="nom"
          label="Nom de l'espace de travail"
          name="name"
          id="name"
          onChange={formik.handleChange}
          value={formik.values?.name}
          errorMessage={formik?.errors?.name as string}
        />
        <SelectCourses
          label="Selectionner une formation"
          name="courseWorkspacesId"
          id="courseWorkspacesId"
          onChange={formik.handleChange}
          value={formik.values?.courseWorkspacesId as string}
          errorMessage={formik?.errors?.courseWorkspacesId as string}
        />
        <FormTextArea
          placeholder="Laissez une courte description du workspace..."
          label="Description"
          name="description"
          id="description"
          onChange={formik.handleChange}
          value={formik.values?.description as string}
          errorMessage={formik?.errors?.description as string}
          // text={'Brief description for your profile. URLs are hyperlinked.'}
        />

        <Flex py={2} justifyContent={'flex-end'} w="full">
          <Button onClick={onClose} variant="outline">
            Annuler
          </Button>
          <Button ml={4} type="submit" isLoading={isLoading}>
            Valider
          </Button>
        </Flex>
        {/* <ModalFooter>
          <Button colorScheme="blue" mr={3} type="submit" isLoading={isLoading}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter> */}

        {formik?.errors?.workspaceSchoolId ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Erreur!</AlertTitle>
            <AlertDescription>
              {formik?.errors?.workspaceSchoolId}
            </AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" />
          </Alert>
        ) : null}
      </Box>
    </form>
  )
}
