import {SunIcon, CloseIcon, HamburgerIcon} from '@chakra-ui/icons'
import {FiMoon} from 'react-icons/fi'
import {MdNotifications} from 'react-icons/md'

import {
  useColorMode,
  Center,
  Flex,
  useDisclosure,
  useBreakpointValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Image,
} from '@chakra-ui/react'
import {css} from '@emotion/react'
import {Link as DomRouterLink, useNavigate} from 'react-router-dom'
import {
  Heading,
  Avatar,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  IconButton,
  Box,
  Stack,
  Icon,
  Grid,
  Link,
} from '../ui'
import Nav from './nav'
import {
  getAvatar,
  haveRouteRequiredRole,
  displayUserNameAndName,
  displayNameForAvatar,
} from '../../commons'
import {useAuth} from '../../context/AuthContext'
import Searchbar from '../SearchBar/Searchbar'
import {RoutesConfig} from '../../routes/config'
import {Eroles} from '../../types'
import NavForum from './NavForum'
import {Logo} from '../../Logo'
import {useSchool} from '../../commons/api/hooks'
import {bgColor} from '../../theme/app-constantes'
import {useGlobalContext} from '../../context/GlobalContext'
import {ROUTE_MESSAGERIE_CONVERSATIONS} from '../../constantes'
import {useCallback, useEffect, useState} from 'react'

type NavbarProps = {
  menuRoleFilter: Eroles
}

export const Navbar = ({menuRoleFilter}: NavbarProps) => {
  const navigate = useNavigate()
  const {colorMode, toggleColorMode} = useColorMode()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {/*login, register,*/ logout, authUser, schoolId} = useAuth()
  const {school} = useSchool(schoolId)
  const menuConfig = RoutesConfig.find(menu => menu.id === 'rightmenu')?.menu
  const size = useBreakpointValue({base: 'sm', sm: 'md'})
  const [msgUrl, setMsgUrl] = useState(`${ROUTE_MESSAGERIE_CONVERSATIONS}`)
  const handleLogout = () => {
    logout()
    navigate('/')
  }

  const handleOnSearch = e => {
    navigate(`/school/faq?question=${e}`)
  }

  const {isNewChatMessage, lastChatMessage} = useGlobalContext()

  const handleMessageClick = useCallback(() => {
    navigate(msgUrl)
  }, [msgUrl, navigate])

  useEffect(() => {
    // console.log('lastChatMessage', lastChatMessage)
    setMsgUrl(
      `${ROUTE_MESSAGERIE_CONVERSATIONS}/${
        lastChatMessage?.chatRoomMessagesId ?? ''
      }`,
    )
  }, [lastChatMessage])

  return (
    <Box py={4} px={{base: 0, sm: 4}}>
      <Grid
        w={'full'}
        templateColumns={{
          base: 'repeat(3, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'none',
        }}
        gap={6}
        alignItems={'center'}
      >
        <Box mt={{base: 1, md: 2}} display={{lg: 'none'}}>
          {/* Cas Unique puisqu'il concerne le bouton menu en mobile donc j'ai préféré agir directement sur IconButton que de modifier un variant dont je n'aurai pas besoin ailleurs.*/}
          <IconButton
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{lg: 'none'}}
            onClick={onOpen}
            variant="ghost"
            mt={{md: -4}}
            _focus={{outline: 'none'}}
          />
          {/* Comme dans Sidebar les Heading logo sont là en attendant le logo */}
          <Heading display={{base: 'none', md: 'inline-block', lg: 'none'}}>
            <Center w={'150px'} maxHeight={'300px'} margin={'auto'}>
              {school?.image ? (
                <Image
                  src={school?.image}
                  alt="logo"
                  boxSize={'100%'}
                  objectFit={'cover'}
                />
              ) : (
                <Logo height={30} />
              )}
            </Center>
          </Heading>
        </Box>
        <Heading h={8} display={{md: 'none'}} mt={2} mb={'50px'}>
          <Center w={'150px'} maxHeight={'300px'} margin={'auto'}>
            {school?.image ? (
              <Image
                src={school?.image}
                alt="logo"
                boxSize={'100%'}
                objectFit={'cover'}
              />
            ) : (
              <Logo height={30} />
            )}
          </Center>
        </Heading>
        {/* Hormis quelques Flex bien particulières (placé plus tard en variant) il y aura quelques Flex au destin unique lol */}
        <Flex
          w={{base: 'auto', lg: 'full'}}
          justifyContent={{base: 'flex-end', lg: 'space-between'}}
        >
          <Box display={{base: 'none', md: 'inline-block'}}>
            <Searchbar
              onSearch={handleOnSearch}
              placeholder={'Rechercher'}
              tooltipMessage="Cliquez ici pour rechercher"
            />
          </Box>

          <Box>
            {isNewChatMessage && (
              <Button
                display={{base: 'none', sm: 'inline-block'}}
                size={size}
                variant="ghost"
                onClick={handleMessageClick}
                mt={1}
              >
                <Icon as={MdNotifications} variant={'generalIcons'} />
              </Button>
            )}

            <Button
              size={size}
              variant="ghost"
              onClick={toggleColorMode}
              mt={1}
              mx={1}
            >
              {colorMode === 'light' ? (
                <Icon as={FiMoon} variant={'generalIcons'} />
              ) : (
                <Icon as={SunIcon} variant={'generalIcons'} />
              )}
            </Button>
            {/* Au besoin ce passage peu être placé dans un composant à lui si besoin */}
            <Menu>
              <MenuButton rounded={'full'} cursor={'pointer'} minW={0}>
                <Avatar
                  name={displayNameForAvatar(authUser.me)}
                  src={getAvatar(authUser)}
                />
              </MenuButton>
              <MenuList
                p={2}
                w="220px"
                ml={{base: 0, '2xl': -180}}
                mr={{base: -8, '2xl': 0}}
              >
                <Center>
                  <Text variant="nameMenu">
                    {displayUserNameAndName(authUser.me)}
                  </Text>
                </Center>
                <MenuDivider mb={4} />
                {/* <MenuOptionGroup defaultValue="asc" title="Gestion du compte"> */}
                {menuConfig
                  ?.filter(item => haveRouteRequiredRole(item?.roles, authUser))
                  // ?.filter(item => item?.roles.includes(menuRoleFilter))
                  .map((navItem, idx) => (
                    <Link
                      key={idx}
                      to={navItem?.href}
                      style={{textDecoration: 'none'}}
                      as={DomRouterLink}
                    >
                      <MenuItem>{navItem?.label}</MenuItem>
                    </Link>
                  ))}
                {/* </MenuOptionGroup> */}
                <MenuDivider mb={4} />
                <MenuItem onClick={handleLogout} mt={4}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Grid>
      <Flex
        justifyContent={'center'}
        mt={2}
        display={{base: 'flex', md: 'none'}}
      >
        <Searchbar
          onSearch={handleOnSearch}
          placeholder={'Rechercher'}
          tooltipMessage="Cliquez ici pour rechercher"
        />
      </Flex>
      <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay backdropFilter={'blur(5px)'} />
        <DrawerContent
          bgColor={bgColor({colorMode})}
          px={4}
          border="2px"
          rounded="md"
          overflow="auto"
          css={css`
            &::-webkit-scrollbar {
              display: 'none';
            }
          `}
          borderColor={'blue.500'}
          onClick={onClose}
        >
          <Stack as={'nav'} spacing={2}>
            <Nav menuRoleFilter={menuRoleFilter} />
          </Stack>
          {menuRoleFilter === Eroles.STUDIENT && <NavForum />}
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default Navbar
