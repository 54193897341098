import * as Yup from 'yup'
import * as React from 'react'
import {CreateFAQTagInput, UpdateFAQTagInput} from '../../commons/types/API'
import {Button, FormInput, Stack, HStack, CloseButton, Flex} from '../ui'
import {useFormik} from 'formik'
import {
  useAuthUserId,
  useUserDetailSchoolGroupId,
} from '../../context/AuthContext'
import {Alert, AlertDescription, AlertIcon, AlertTitle} from '@chakra-ui/react'

type FAQTagsFormProps = {
  initialValues: UpdateFAQTagInput
  onSubmit: (formValue: UpdateFAQTagInput & CreateFAQTagInput) => void
  onClose: () => void
  isLoading?: boolean
  isEdit?: boolean
}

export const FAQTagsForm: React.FC<FAQTagsFormProps> = ({
  initialValues,
  onClose,
  onSubmit,
  isLoading,
  isEdit,
}) => {
  const uid = useAuthUserId()
  const schoolGroupId = useUserDetailSchoolGroupId()

  const SignInSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    groupsCanAccess: Yup.array()
      .min(1, 'Il manque groupsCanAccess')
      .required('Champ obligatoire'),
    owner: Yup.string().test('owner', 'Il manque owner', value => !!value),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      name: initialValues?.name ?? '',
      description: initialValues?.description ?? '',
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (values: UpdateFAQTagInput & CreateFAQTagInput) => {
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  //console.log('ormik.values?.topicWorkspaceId', formik.values?.topicWorkspaceId)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing="2">
        <FormInput
          placeholder="code"
          type="name"
          label="Nom du tag"
          name="name"
          id="name"
          onChange={formik.handleChange}
          value={formik.values?.name}
          errorMessage={formik?.errors?.name}
        />
        <FormInput
          placeholder="description ..."
          type="description"
          label="Desciption"
          name="description"
          id="description"
          onChange={formik.handleChange}
          value={formik.values?.description}
          errorMessage={formik?.errors?.description}
        />
        <Flex justifyContent="flex-end" w="full">
          <HStack>
            <Button variant="outline" onClick={onClose}>
              Annuler
            </Button>
            <Button type="submit" fontSize="md" isLoading={isLoading}>
              {isEdit ? 'Modifier' : 'Valider'}
            </Button>
          </HStack>
        </Flex>
      </Stack>

      {formik?.errors?.groupsCanAccess || formik?.errors?.owner ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Erreur!</AlertTitle>
          <AlertDescription>
            {formik?.errors?.groupsCanAccess || formik?.errors?.owner}
          </AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      ) : null}
    </form>
  )
}
