import React from 'react'
import {useDisclosure} from '@chakra-ui/react'
import {
  useCreateTopic,
  useDeleteMessage,
  useDeleteTopic,
  useUpdateTopic,
} from '../commons/api/hooks'
import {modalYesNoPromise} from '../components/Modals/YesNo'
import {
  CreateTopicInput,
  Message,
  Topic,
  UpdateTopicInput,
} from '../commons/types/API'
/**
 * Hook permettant de gerer le Save Question d'un formulaire
 * @param param0
 * @returns
 */
export const useSaveTopic = ({onSuccess, isEdit}: any) => {
  const mutationCreate = useCreateTopic({
    onSuccess: onSuccess,
  })
  const mutationUpdate = useUpdateTopic({
    onSuccess: onSuccess,
  })

  const handleSaveTopic = (values: UpdateTopicInput & CreateTopicInput) => {
    if (isEdit) {
      mutationUpdate.mutate(values)
    } else {
      mutationCreate.mutate(values)
    }
  }
  return {
    handleSaveTopic,
    isLoading: mutationCreate.isLoading || mutationUpdate.isLoading,
  }
}

/**
 * Custom hook to manage popup Add or Update Question
 * @param questions
 * @returns
 */
export const useOpenTopic = ({topics}: {topics?: [Topic]}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const [selectedTopic, setSelectedTopic] = React.useState<Topic>()

  const handleOpenNewTopic = React.useCallback(() => {
    setSelectedTopic(undefined)
    setIsEdit(false)
    onOpen()
  }, [onOpen])

  const handleOpenUpdateTopic = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      //console.log('value', value)

      const element = topics?.find(elm => elm.id === value)
      setSelectedTopic(element)
      setIsEdit(true)
      onOpen()
    },
    [topics, onOpen],
  )

  return {
    isEdit,
    selectedTopic,
    isOpen,
    onOpen,
    onClose,
    handleOpenNewTopic,
    handleOpenUpdateTopic,
  }
}

/**
 *
 * Attention ce hook s'utilise dans un composant contenant
 * <ModalContainer />
 * @param questions
 * @returns
 */
type popupDeleteProps = {
  topics?: Array<Topic | null>
}
export const usePopupDeleteTopic = ({topics}: popupDeleteProps) => {
  const mutationDeleteComment = useDeleteTopic()

  const handleOpenDeleteTopic = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      //console.log('value', value)

      e.stopPropagation()
      const data = topics?.find((elm: any) => elm.id === value)
      //console.log('data', data)
      modalYesNoPromise({
        title: 'Supprimer',
        header: `Utilisateur ${data?.user.username}`,
        confirmLabel: 'Supprimer',
        infoMessage: 'Supprimer le commentaire ?',
        data,
        labelOfType: 'prog',
        children: (
          <>
            <div>
              <b>Commentaires : </b>
              {data?.description}
            </div>
          </>
        ),
      })
        .then(ok => {
          mutationDeleteComment.mutate(value)
        })
        .catch(error => {})
    },
    [topics, mutationDeleteComment],
  )
  return {handleOpenDeleteTopic}
}

/**
 *
 * Attention ce hook s'utilise dans un composant contenant
 * <ModalContainer />
 * @param message
 * @returns
 */
type popupDeleteMessagesProps = {
  messages?: Array<Message | null>
}
export const usePopupDeleteMessage = ({messages}: popupDeleteMessagesProps) => {
  const mutationDeleteMessage = useDeleteMessage()

  const handleOpenDeleteMessage = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      //console.log('value', value)

      e.stopPropagation()
      const data = messages?.find((elm: any) => elm.id === value)
      //console.log('data', data)
      modalYesNoPromise({
        title: 'Supprimer',
        header: `Utilisateur ${data?.user.username}`,
        confirmLabel: 'Supprimer',
        infoMessage: 'Supprimer le message ?',
        data,
        labelOfType: 'prog',
        children: (
          <>
            <div>
              <b>Message à supprimer : </b>
              {data?.message}
            </div>
          </>
        ),
      })
        .then(ok => {
          mutationDeleteMessage.mutate(value)
        })
        .catch(error => {})
    },
    [messages, mutationDeleteMessage],
  )
  return {handleOpenDeleteMessage}
}
