import {ExternalLinkIcon} from '@chakra-ui/icons'
import {
  Input as InputChakra,
  InputProps,
  FormControl as FormControlChakra,
  FormControlProps,
  FormLabel as FormLabelChakra,
  FormLabelProps,
  FormHelperText as FormHelpersTextChakra,
  FormHelperTextProps,
  Textarea as ChakraTextArea,
  TextareaProps,
  Link,
} from '@chakra-ui/react'

// ;<FormControl id="email">
//   <FormLabel>Email address</FormLabel>
//   <Input type="email" />
//   <FormHelperText>We'll never share your email.</FormHelperText>
// </FormControl>

export const Textarea = (props: Partial<Omit<TextareaProps, 'value'>>) => {
  return <ChakraTextArea {...props}></ChakraTextArea>
}
export const Input = (props: Partial<Omit<InputProps, 'value'>>) => {
  return <InputChakra {...props}></InputChakra>
}
export const FormControl = (props: FormControlProps) => {
  return <FormControlChakra {...props}></FormControlChakra>
}
export const FormLabel = (props: FormLabelProps) => {
  return <FormLabelChakra {...props}></FormLabelChakra>
}
export const FormHelperText = (props: FormHelperTextProps) => {
  return <FormHelpersTextChakra {...props}></FormHelpersTextChakra>
}
export type FormInputProps = Partial<Omit<InputProps, 'value'>> &
  FormControlProps & {
    value?: string | number | readonly string[] | undefined | null
    helperText?: string
    errorMessage?: string
  }
export const FormInput = ({...props}: FormInputProps) => {
  const {errorMessage, ...rest} = props
  const isInvalid =
    props?.errorMessage !== undefined && props?.errorMessage !== ''

  return (
    <FormControl /*id={props?.id}*/ isInvalid={isInvalid} hidden={props.hidden}>
      {/* <FormLabel>{props?.label}</FormLabel> */}
      {/* <Text variant="label" mb={1} htmlFor={props?.id}>
        {props?.label}
      </Text> */}
      <FormLabel htmlFor={props?.id}>{props?.label}</FormLabel>
      <Input
        placeholder={props?.placeholder}
        type={props?.type}
        value={props?.value}
        // id={props?.id}
        {...rest}
      />
      <FormHelperText>{props?.helperText}</FormHelperText>
      <FormHelperText color="red.500" role="alert">
        {props?.errorMessage}
      </FormHelperText>
      {/* <FormErrorMessage>{props?.errorMessage}</FormErrorMessage> */}
    </FormControl>
  )
}
export type TextAreaProps = Partial<Omit<TextareaProps, 'value'>> &
  FormControlProps & {
    value?: string | number | readonly string[] | undefined | null
    text?: string
    errorMessage?: string
    extLinks?: string
    nameLinks?: string
  }
export const FormTextArea = ({...props}: TextAreaProps) => {
  const {errorMessage, ...rest} = props
  return (
    <FormControl id={props?.id}>
      <>
        <FormLabel htmlFor={props?.id} mb={1}>
          {props?.label}

          {props?.extLinks ? (
            <Link color="blue.300" href={props?.extLinks} isExternal>
              {props?.nameLinks}
              <ExternalLinkIcon mx="2px" />
            </Link>
          ) : (
            ''
          )}
        </FormLabel>
      </>
      <Textarea
        placeholder={props?.placeholder}
        value={props?.value}
        {...rest}
      />
      <FormHelperText>{props?.text}</FormHelperText>
      <FormHelperText color="red.500">{props?.errorMessage}</FormHelperText>
    </FormControl>
  )
}
export default Input
