import {ModelSortDirection, Workspace} from '../types/API'
import {format} from 'date-fns'
import {fr} from 'date-fns/locale'
import {
  dateFormat_ddMMyyyy,
  dateFormat_ddMMyyyyHHmm,
  defaultGdriveUrl,
  wrongGdriveUrl,
} from '../../commons/constantes'
import {VideoType} from '../../types/app'

type sortByDateProps = {
  data?: any[]
  direction?: ModelSortDirection
  filedName?: string
  subLevelName?: string
}
export const sortByDate = ({
  data = [],
  direction = ModelSortDirection.ASC,
  filedName = 'createdAt',
  subLevelName,
}: sortByDateProps) => {
  const arraySorted = data?.sort((a: any, b: any) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    let date1
    let date2
    if (subLevelName) {
      date1 = new Date(b[subLevelName][filedName]).getTime() // - new Date(a.date)
      date2 = new Date(a[subLevelName][filedName]).getTime() // - new Date(a.date)
    } else {
      date1 = new Date(b[filedName]).getTime() // - new Date(a.date)
      date2 = new Date(a[filedName]).getTime() // - new Date(a.date)
    }
    return ModelSortDirection.ASC === direction ? date1 - date2 : date2 - date1
  })
  return arraySorted
}

/**
 * We need to know wich tags must be deleted and added to question
 * prepare object with the following shape
 * {tags, toDel, toAdd}
 * tags : Selected Tags (not enought to know if need to be added to question or not)
 * toDel : tags todel from the question
 * toAdd : tags need to add to question
 * @param tags
 */
export const buildTagsObject = (tags: any, originalTags: any) => {
  //console.log('handleTagsChange', tags)
  //console.log('originalTags', originalTags)
  //add orignal value to know what tag must be deleted or added on submit

  //new tags since previous value
  const newTags = tags.filter(
    (tag: any) =>
      !originalTags?.some(
        (originalTag: any) => originalTag.fAQTagID === tag.value.id,
      ),
  )
  //tags need to be deleted
  const toDel = originalTags?.filter(
    (tagData: any) =>
      !tags?.some(
        (originalTag: any) => originalTag.value.id === tagData.fAQTagID,
      ),
    //return {...tagData, isForDel}
  )
  //console.log('newTags', newTags)
  //console.log('toDel', toDel)

  const data = [{tags, toDel, toAdd: newTags}]
  return data
}

export const validURL = (str: any) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return pattern.test(str)
}

//https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
export const youtubeParser = (url: string) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

export const formatDateTime = (
  date: string,
  pattern = dateFormat_ddMMyyyyHHmm,
) => {
  let convertedDate = ''
  if (date) {
    convertedDate = format(new Date(date), pattern, {
      locale: fr,
    })
  }
  return convertedDate
}

export const formatDate = (date: string, format = dateFormat_ddMMyyyy) => {
  return formatDateTime(date, format)
}

export const removeSpecialCaracs = (str: string) => {
  const regex = /[^\w\s]/gi
  return str.replace(regex, '')
}

export function convertStructureURL(
  originalURL: string,
  videoTypes: VideoType[],
): string {
  let convertedURL = originalURL
  videoTypes.forEach(videoType => {
    switch (videoType) {
      case VideoType.GDRIVE:
        if (originalURL.includes(wrongGdriveUrl)) {
          const id = originalURL.match(/\/file\/d\/(.+?)\//)?.[1]
          if (!id) {
            throw new Error(`l'url de la vidéo gdrive doit posséder un id`)
          }
          convertedURL = defaultGdriveUrl(id)
        }
        break
      case VideoType.DROPBOX:
        throw new Error(`${videoType} non implémenté`)
      case VideoType.VIMEO:
        throw new Error(`${videoType} non implémenté`)
      case VideoType.YOUTUBE:
        throw new Error(`${videoType} non implémenté`)
      default:
        throw new Error(`${videoType} non supporté`)
    }
  })
  return convertedURL
}

//pour avoir un affichage des worskapce sans les cours PUIS ceux avec les cours
export function sortWorkspaces(
  workspaces?: Array<Workspace | null>,
): Array<Workspace | null> {
  if (!workspaces) {
    return []
  }
  return workspaces.sort((a, b) => {
    if (!a) {
      return 1
    }
    if (!b) {
      return -1
    }
    // Si 'a' n'a pas de courseWorkspacesId et 'b' en a un, 'a' doit venir en premier
    if (!a.courseWorkspacesId && b.courseWorkspacesId) {
      return -1
    }

    // Si 'a' a un courseWorkspacesId et 'b' n'en a pas, 'b' doit venir en premier
    if (a.courseWorkspacesId && !b.courseWorkspacesId) {
      return 1
    }

    // Si les deux éléments ont un courseWorkspacesId, ou les deux n'en ont pas, on les compare en fonction de leur ID
    return a.id.localeCompare(b.id)
  })
}
export const codeReplaceLabel = '(Code masqué)'
export function markdownToText(markdown: string): string {
  const regexPatterns: [RegExp, string][] = [
    [/^#+\s+(.*)/gm, '$1'],
    [/\*{2}(.+?)\*{2}/g, '$1'],
    [/[_]{2}(.+?)[_]{2}/g, '$1'],
    [/~{2}(.+?)~{2}/g, '$1'],
    [/\[(.*?)\]\((.*?)\)/g, '$1'],
    [/^>\s+(.*)/gm, '$1'],
    [/^[-*+]\s+(.*)/gm, '$1'],
    [/```[\s\S]*?```/g, codeReplaceLabel], // Modifie la regex pour remplacer le contenu des blocs de code par "(code masqué)"
    [/<[^>]*>/g, ''],
    [/^[\s]+/gm, ''], // Supprime les espaces et les sauts de ligne en début de chaîne
  ]

  let text = markdown
  regexPatterns.forEach(([regex, replacement]) => {
    text = text.replace(regex, replacement)
  })

  return text.trim() // Supprime les espaces et les sauts de ligne en fin de chaîne
}
export function timeDiff(inputDate: string): string {
  const currentDate = new Date()
  const targetDate = new Date(inputDate)

  const msDifference = currentDate.getTime() - targetDate.getTime()
  const minuteDifference = Math.floor(msDifference / 1000 / 60)
  const hourDifference = Math.floor(minuteDifference / 60)
  const dayDifference = Math.floor(hourDifference / 24)

  if (minuteDifference === 0) {
    return `à l'instant`
  } else if (minuteDifference < 60) {
    return `${minuteDifference}min`
  } else if (hourDifference < 24) {
    return `${hourDifference}h`
  } else {
    return `${dayDifference}j`
  }
}

export function hashCode(str: string): number {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export function deterministicRandom(
  min: number,
  max: number,
  str: string,
): number {
  const hash = hashCode(str)
  const diff = max - min
  const rnd = Math.abs(hash) / 4294967295 // 4294967295 is the max positive value for a 32-bit number
  return Math.floor(rnd * (diff + 1)) + min
}

export function getTopicRandomView(str: string) {
  return deterministicRandom(4, 150, str)
}

export function getDefaultPage(pageQueryParam?: string | null) {
  const defaultPage =
    pageQueryParam === null ||
    Number.isNaN(Number(pageQueryParam)) ||
    Number(pageQueryParam) === 0
      ? 1
      : Number(pageQueryParam)
  return defaultPage
}
