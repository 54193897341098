import {EStatus} from '../../types/graphQL'
import {ColorStatus, CreateConsultationSteps} from '../../types/screen'
import {Question, User} from '../types/API'

//no need this normaly
export function stringToEStatus(status: string): EStatus {
  const statusArray = Object.values(EStatus)
  return statusArray.find(stat => stat === status) as EStatus
}
export function getEStatusDetails(status: EStatus): ColorStatus {
  switch (status) {
    case EStatus.NEW:
      return {key: status, value: 'pink.500', label: 'Nouveau'}
    case EStatus.PUBLISHED:
      return {key: status, value: 'green.500', label: 'Publié'}
    case EStatus.DELETED:
      return {key: status, value: 'red.500', label: 'Supprimé'}
    case EStatus.CANCELED:
      return {key: status, value: 'blue.500', label: 'Annulé'}
    case EStatus.REJECTED:
      return {key: status, value: 'red.500', label: 'Rejeté'}
    default:
      return {key: status, value: 'yellow.500', label: 'Inconnu'}
  }
}

export function toCreateConsultationStep(
  step?: string,
): CreateConsultationSteps {
  const createConsultationStepsArray = Object.values(CreateConsultationSteps)
  return createConsultationStepsArray.find(
    elm => elm?.toLocaleLowerCase() === step?.toLocaleLowerCase(),
  ) as CreateConsultationSteps
}

export type SocialUrl = {
  website: string
  youtube: string
  twitter: string
  instagram: string
  linkedin: string
  facebook: string
  github: string
}
export function generateUrlSocial(user?: User): SocialUrl {
  const websiteBase = 'https://'
  const website = `${websiteBase}${user?.website}`

  const youtubeBase = 'https://www.youtube.com/channel'
  const youtube = `${youtubeBase}/${user?.youtubeId}`

  const twitterBase = 'https://www.twitter.com'
  const twitter = `${twitterBase}/${user?.twitterId}`

  const instagramBase = 'https://www.instagram.com'
  const instagram = `${instagramBase}/${user?.instagramId}`

  const facebookBase = 'https://www.facebook.com'
  const facebook = `${facebookBase}/${user?.facebookId}`

  const linkedInBase = 'https://www.linkedin.com/in'
  const linkedin = `${linkedInBase}/${user?.linkedInId}`

  const githubBase = 'https://www.github.com'
  const github = `${githubBase}/${user?.githubId}`

  return {website, youtube, twitter, instagram, linkedin, facebook, github}
}
export const rewordPrefix = 'reformulée : '
export const questionTitleOrReword = (question?: Question | null) => {
  return (
    (question?.rewording
      ? `${rewordPrefix}${question.rewording}`
      : undefined || question?.title
      ? `${question?.title}`
      : undefined) ?? 'Pas de titre'
  )
}
