import {Eroles, Menus, NavItem} from '../../types'
import {
  MdDashboard,
  MdContentPaste,
  MdWorkspaces,
  MdGroups,
  MdLockOpen,
  MdOutlineTopic,
  MdOutlineStorefront,
} from 'react-icons/md'
import {FaQuestionCircle, FaPeopleArrows, FaComment} from 'react-icons/fa'
import {IoIosSchool} from 'react-icons/io'
import {
  ROUTE_ACCOUNT,
  ROUTE_ADMIN_COURSES,
  ROUTE_ADMIN_DASHBOARDS,
  ROUTE_ADMIN_FAQ,
  ROUTE_ADMIN_MEMBERS,
  ROUTE_ADMIN_SCHOOL_SETTINGS,
  ROUTE_ADMIN_WS,
  ROUTE_MESSAGERIE,
  ROUTE_SCHOOL,
  ROUTE_SCHOOL_ADMIN,
  ROUTE_SCHOOL_CONSULTATIONS,
  ROUTE_SCHOOL_COURSE,
  ROUTE_SCHOOL_DASHBOARD,
  ROUTE_SCHOOL_FAQ,
  ROUTE_SCHOOL_FAQ_QUESTION_EDITOR,
  ROUTE_SCHOOL_TOPIC,
  ROUTE_USER_FAQ_MYQUESTION,
} from '../../constantes'

/* 
- MainMenuConfig : Menu principal = config de l'ancien menu fonctionnant avec sous menu
- LeftMenuConfig pour le menu gauche (owner)
- RightMenuConfig pour le menu droit (studient)
 */

export const MainMenuConfig: Array<NavItem> = [
  // ATTENTION IL AFFICHE SUR LE FRONT DANS L'ODRE DE L'ARRAY
  // On garde dans l'ordre affichage priorité
  // admin, owner , user ,public

  // ROUTES ADMIN (super admin ne pas utiliser pour le moment !!!)
  {
    id: 'admin.stats',
    label: 'SuperAdmin Menu',
    icon: MdLockOpen,
    href: '/admin/stats',
    roles: [Eroles.ADMIN],
    //Sous menu (children) non supporté dans la version getup
    children: [
      {
        id: 'admin.stats.main',
        label: 'Voir toutes les statistiques',
        icon: MdLockOpen,
        subLabel: 'ROUTAGE ADMIN',
        href: '#',
      },
    ],
  },

  // ROUTES SCHOOLOWNER
  {
    id: 'schoolowner.account',
    label: 'Mon compte',
    icon: MdDashboard,
    href: '/account',
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
    //Sous menu (children) non supporté dans la version getup
    children: [
      {
        id: 'user.account.management',
        label: 'Mes informations',
        icon: MdContentPaste,
        subLabel: 'cognito Info',
        href: '/account',
      },
    ],
  },
  {
    id: 'schoolowner.school',
    label: 'Mon Ecole',
    icon: IoIosSchool,
    href: '/school-admin',
    roles: [Eroles.SCHOOLOWNER],
    //Sous menu (children) non supporté dans la version getup
    children: [
      {
        id: 'schoolowner.school.settings',
        label: "Paramètre de l'école",
        icon: IoIosSchool,
        subLabel: "informations sur l'ecole",
        href: '/school-admin/settings',
      },
      {
        id: 'schoolowner.school.course',
        label: 'Mes formations',
        icon: MdContentPaste,
        subLabel: 'Mes formations',
        href: '/school-admin/courses',
      },
      {
        id: 'schoolowner.school.workspace',
        label: 'Mes espaces de travail',
        icon: MdWorkspaces,
        subLabel: 'Gérer les espaces de travails',
        href: '/school-admin/workspaces',
      },
      {
        id: 'schoolowner.school.students',
        label: 'Mes éléves',
        icon: MdGroups,
        subLabel: 'Gérer mes élèves',
        href: '/school-admin/students',
      },
    ],
  },
  {
    id: 'user.communauté',
    label: 'Communauté',
    icon: MdDashboard,
    href: '#',
    roles: [Eroles.SCHOOLOWNER, Eroles.USER, Eroles.STUDIENT],
    //Sous menu (children) non supporté dans la version getup
    children: [
      {
        id: 'user.communauté',
        label: 'Forum',
        icon: MdOutlineTopic,
        subLabel: 'forum',
        href: '/school/topic',
      },
      // {
      //   id: 'user.communauté',
      //   label: 'Formations',
      //   icon: MdContentPaste,
      //   subLabel: 'Formations',
      //   href: `${ROUTE_SCHOOL_COURSE}`,
      // },
      {
        id: 'user.communauté',
        label: 'FAQ Video',
        icon: FaQuestionCircle,
        subLabel: 'FAQ Video',
        href: '/school/faq',
      },
    ],
  },
  {
    id: 'schoolowner.dashboard',
    label: 'Tableau de bord',
    icon: MdDashboard,
    href: '/school-admin/dashboard',
    roles: [Eroles.SCHOOLOWNER],
  },

  {
    id: 'public.about',
    label: 'About',
    icon: MdDashboard,
    href: '/about',
    roles: [],
  },
  {
    id: 'public.pricing',
    label: 'Offres',
    icon: MdDashboard,
    href: '/pricing',
    roles: [],
    children: [
      {
        id: 'schoolowner.students.management',
        label: 'Voir les offres',
        icon: MdDashboard,
        subLabel: 'ROUTAGE PUBLIC',
        href: '#',
      },
    ],
  },
]

export const RightMenuConfig: Array<NavItem> = [
  {
    id: 'mainmenu.profil',
    label: 'Mon compte',
    icon: MdDashboard,
    href: ROUTE_ACCOUNT,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
    children: [],
  },
  {
    id: 'user.communauté',
    label: 'Mes formations',
    icon: MdContentPaste,
    href: `${ROUTE_SCHOOL_COURSE}`,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
    children: [],
  },
  {
    id: 'mainmenu.messagerie',
    label: 'Mes messages',
    icon: MdDashboard,
    href: `${ROUTE_MESSAGERIE}`,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
    children: [],
  },
  {
    id: 'mainmenu.questions',
    label: 'Mes questions',
    icon: MdDashboard,
    href: `${ROUTE_USER_FAQ_MYQUESTION}`,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
    children: [],
  },
  {
    id: 'mainmenu.addquestions',
    label: 'Poser une question',
    icon: MdDashboard,
    href: `${ROUTE_SCHOOL_FAQ_QUESTION_EDITOR}`,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
    children: [],
  },
]

export const LeftMenuAdminConfig: Array<NavItem> = [
  {
    id: 'leftmenu.questions',
    label: 'Espace Membres',
    icon: MdOutlineStorefront,
    href: ROUTE_SCHOOL,
    roles: [Eroles.SCHOOLOWNER],
  },
  {
    id: 'leftmenu.dashboard',
    label: 'Dashboard Admin',
    icon: MdDashboard,
    href: ROUTE_ADMIN_DASHBOARDS,
    roles: [Eroles.SCHOOLOWNER],
  },
  {
    id: 'leftmenu.school',
    label: 'École',
    icon: IoIosSchool,
    href: ROUTE_ADMIN_SCHOOL_SETTINGS,
    roles: [Eroles.SCHOOLOWNER],
  },
  {
    id: 'leftmenu.courses',
    label: 'Programmes',
    icon: MdContentPaste,
    href: ROUTE_ADMIN_COURSES,
    roles: [Eroles.SCHOOLOWNER],
  },

  {
    id: 'leftmenu.workspaces',
    label: 'Espaces de travail',
    icon: MdWorkspaces,
    href: ROUTE_ADMIN_WS,
    roles: [Eroles.SCHOOLOWNER],
  },
  {
    id: 'leftmenu.users',
    label: 'Membres',
    icon: MdGroups,
    href: ROUTE_ADMIN_MEMBERS,
    roles: [Eroles.SCHOOLOWNER],
  },
  // {
  //   id: 'leftmenu.questions',
  //   label: 'Forum',
  //   icon: FaQuestionCircle,
  //   href: '/school-admin/topic',
  // },
  {
    id: 'leftmenu.questions',
    label: 'Questions',
    icon: FaQuestionCircle,
    href: ROUTE_ADMIN_FAQ,
    roles: [Eroles.SCHOOLOWNER],
  },
]

export const LeftMenuStudentConfig: Array<NavItem> = [
  {
    id: 'leftmenu.dashboard',
    label: 'Administration',
    icon: MdLockOpen,
    href: ROUTE_SCHOOL_ADMIN,
    roles: [Eroles.SCHOOLOWNER],
  },
  {
    id: 'leftmenu.dashboard',
    label: 'Accueil',
    icon: MdDashboard,
    href: ROUTE_SCHOOL_DASHBOARD,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
  },

  {
    id: 'leftmenu.forum',
    label: 'Communauté',
    icon: MdWorkspaces,
    href: ROUTE_SCHOOL_TOPIC,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
  },

  {
    id: 'leftmenu.questions',
    label: 'Questions',
    icon: FaQuestionCircle,
    href: ROUTE_SCHOOL_FAQ,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
  },
  {
    id: 'leftmenu.consultations',
    label: 'Consultations',
    icon: FaPeopleArrows,
    href: ROUTE_SCHOOL_CONSULTATIONS,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
  },
  {
    id: 'leftmenu.dashboard',
    label: 'Messages',
    icon: FaComment,
    href: ROUTE_MESSAGERIE,
    roles: [Eroles.SCHOOLOWNER, Eroles.STUDIENT],
  },
]

export const RoutesConfig: Array<Menus> = [
  {id: 'mainmenu', menu: MainMenuConfig},
  {id: 'leftMenuAdmin', menu: LeftMenuAdminConfig},
  {id: 'leftMenuStudent', menu: LeftMenuStudentConfig},
  {id: 'rightmenu', menu: RightMenuConfig},
]
