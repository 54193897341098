import {
  Box,
  ContainerPage,
  Stack,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
  Text,
  Select,
} from '../../components/ui'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpoint,
  useColorMode,
} from '@chakra-ui/react'
import {ConsultationsTab} from './consultationsTab'
import {QuestionsTabPanel} from './questionsTab'
import CategoriesTabPanel from './categoriesTab'
import TagsTabPanel from './tagsTab'
import Card from '../Card/Card'
import {useFAQCategories} from '../../commons/api/hooks'
import React from 'react'
import {
  ROUTE_ADMIN_FAQ_CATEGORIES,
  ROUTE_ADMIN_FAQ_CONSULTATIONS,
  ROUTE_ADMIN_FAQ_LISTCATEGORIES,
  ROUTE_ADMIN_FAQ_QUESTIONS,
  ROUTE_ADMIN_FAQ_TAGS,
} from '../../constantes'
import SimpleRowSkeleton from '../Skeletons/SimpleRowSkeleton'
import {primaryColor, textButtonColor} from '../../theme/app-constantes'
import {FilteredCategoriesTab} from './categoriesTabFiltered'
import {FAQCategory} from '../../commons/types/API'

type ConsultationsTabProp = {
  tabIdx: number
  categoryId?: string
}

export const Consultations = ({tabIdx, categoryId}: ConsultationsTabProp) => {
  const {colorMode} = useColorMode()
  const [tabIndex, setTabIndex] = React.useState(tabIdx)
  const {FAQCategories} = useFAQCategories()
  const tabListProps = ['Questions', 'Consultations', 'Catégories', 'Tags']
  const tabListMobileProps = ['Questions', 'Consultations']
  const size = useBreakpoint()
  const [isMobile, setIsMobile] = React.useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = React.useState<
    FAQCategory | undefined
  >(FAQCategories?.items.find(cat => cat.id === categoryId))
  const nbElementTabsFix = 5 // le nombre de Tabs fixe avant les categories (dynamique)

  React.useEffect(() => {
    setSelectedCategory(FAQCategories?.items.find(cat => cat.id === categoryId))
  }, [FAQCategories?.items, categoryId])

  React.useEffect(() => {
    switch (size) {
      case 'base':
        setIsMobile(true)
        break
      case 'sm':
        setIsMobile(true)
        break
      default:
        setIsMobile(false)
    }
  }, [size])

  const handleTabsChange = (index: number) => {
    setTabIndex(index)

    const category = FAQCategories?.items[index - nbElementTabsFix]
    setSelectedCategory(category)
    let newLocation = ''
    switch (index) {
      case 0:
        newLocation = ROUTE_ADMIN_FAQ_QUESTIONS
        break
      case 1:
        newLocation = ROUTE_ADMIN_FAQ_CONSULTATIONS
        break
      case 2:
        newLocation = ROUTE_ADMIN_FAQ_LISTCATEGORIES
        break
      case 3:
        newLocation = ROUTE_ADMIN_FAQ_TAGS
        break
      case 4:
        newLocation = ROUTE_ADMIN_FAQ_TAGS
        break
      default:
        newLocation = `${ROUTE_ADMIN_FAQ_CATEGORIES}/${category?.id}`
        break
    }
    window.history.replaceState(null, '', newLocation)
  }
  const selectCategory = (event: any) => {
    if (event.target.value === '') {
      setTabIndex(0)
    } else {
      let newIndex = parseInt(event.target.value)
      newIndex += nbElementTabsFix
      handleTabsChange(newIndex)
      // ici j'ai mis 5 car il y a 5 tabs avant d'arriver sur celles par catégories...
      // en mobile on ne voit pas 3  de ces tabs (catégorie, tags, et parametres)
    }
  }

  if (!FAQCategories) {
    return <SimpleRowSkeleton></SimpleRowSkeleton>
  }
  return (
    <ContainerPage>
      <Tabs index={tabIndex} onChange={handleTabsChange} variant={'unstyled'}>
        <Grid templateColumns={{md: 'repeat(3, 1fr)'}} gap={4}>
          {isMobile === true ? (
            <GridItem colSpan={2}>
              <Card
                data={
                  <Box px={4} pt={4} borderBottom={'none'}>
                    <Text mb={2} variant="label">
                      Trier les questions par
                    </Text>
                    <TabList borderBottom="none">
                      <Stack spacing={4} w="full">
                        <SimpleGrid columns={2} w="full">
                          {tabListMobileProps.map((tabMobile, idx) => (
                            <Tab
                              fontWeight="medium"
                              rounded="md"
                              key={idx}
                              _selected={{
                                color: textButtonColor({colorMode}),
                                bg: primaryColor({colorMode}),
                              }}
                            >
                              {tabMobile}
                            </Tab>
                          ))}
                        </SimpleGrid>
                        {/* Tab catégorie invisible en mobile */}
                        <Tab display="none"></Tab>
                        {/* Tab tag invisible en mobile */}
                        <Tab display="none"></Tab>
                        {/* Tab parametre invisible en mobile */}
                        <Tab display="none"></Tab>
                        <Select
                          placeholder="ou par catégories"
                          onChange={selectCategory}
                        >
                          {FAQCategories !== undefined ? (
                            FAQCategories?.items.map((category, idx) => (
                              <option key={idx} value={idx}>
                                {category.name}
                              </option>
                            ))
                          ) : (
                            <></>
                          )}
                        </Select>
                      </Stack>
                    </TabList>
                  </Box>
                }
              />
            </GridItem>
          ) : (
            <></>
          )}

          <GridItem colSpan={2}>
            <Card
              data={
                <Stack spacing={4} p={4}>
                  <TabPanels>
                    <TabPanel p={0}>
                      {tabIndex === 0 ? <QuestionsTabPanel /> : ''}
                    </TabPanel>

                    <TabPanel p={0}>
                      {tabIndex === 1 ? <ConsultationsTab /> : ''}
                    </TabPanel>

                    <TabPanel p={0}>
                      {tabIndex === 2 ? <CategoriesTabPanel /> : ''}
                    </TabPanel>
                    <TabPanel p={0}>
                      {tabIndex === 3 ? <TagsTabPanel /> : ''}
                    </TabPanel>
                    {/* <TabPanel p={0}>
                      <p>Gestions parameters</p>
                    </TabPanel> */}

                    {/* Le rendu de composants fonctionne, il faut créer les tabs pour chacune des catégories en appelant l'API et en filtrant les question */}
                    {FAQCategories ? (
                      FAQCategories?.items.map((category, idx) => (
                        <TabPanel
                          id={'table-panel' + category.id}
                          p={0}
                          key={idx}
                        >
                          {(tabIndex > 4 &&
                            category.id === selectedCategory?.id) ||
                          !selectedCategory ? (
                            <FilteredCategoriesTab filter={category} />
                          ) : (
                            ''
                          )}
                        </TabPanel>
                      ))
                    ) : (
                      <Box>Pas de categorie</Box>
                    )}
                  </TabPanels>
                </Stack>
              }
            />
          </GridItem>
          <GridItem colSpan={1} display={{base: 'none', md: 'table-cell'}}>
            <TabList>
              <Stack spacing={4} w="full">
                <Card
                  data={
                    <Box px={4} pt={4} borderBottom={'none'}>
                      <Text mb={2} variant="label">
                        Questions / Consultations :
                      </Text>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        borderBottom={'none'}
                      >
                        {tabListProps.map((tab, idx) => (
                          <Tab
                            fontWeight={'medium'}
                            key={idx}
                            borderBottom={'none'}
                            rounded="md"
                            _selected={{
                              color: textButtonColor({colorMode}),
                              bg: primaryColor({colorMode}),
                            }}
                          >
                            <Flex
                              w="full"
                              justifyContent={'flex-start'}
                              py={2}
                              align="left"
                            >
                              {/* <Checkbox mr={3} value={tab} /> */}
                              <Text cursor="pointer" variant="checkbox">
                                {tab}
                              </Text>
                            </Flex>
                          </Tab>
                        ))}
                      </Box>
                    </Box>
                  }
                />
                <Card
                  data={
                    <Box px={4} pt={4}>
                      <Text mb={2} variant="label">
                        Catégories :
                      </Text>
                      {FAQCategories !== undefined ? (
                        FAQCategories?.items.map((category, idx) => (
                          <Tab
                            fontWeight="medium"
                            rounded="md"
                            _selected={{
                              color: textButtonColor({colorMode}),
                              bg: primaryColor({colorMode}),
                            }}
                            key={idx}
                            borderBottom={'none'}
                            w="full"
                          >
                            <Flex
                              w="full"
                              justifyContent={'flex-start'}
                              py={2}
                              align="center"
                            >
                              <Text
                                fontSize="lg"
                                fontWeight={'light'}
                                cursor="pointer"
                              >
                                {category.name}
                              </Text>
                            </Flex>
                          </Tab>
                        ))
                      ) : (
                        <Box>Pas de catégorie</Box>
                      )}
                    </Box>
                  }
                />
              </Stack>
            </TabList>
          </GridItem>
        </Grid>
      </Tabs>
    </ContainerPage>
  )
}
