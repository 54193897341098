import {useState} from 'react'

type Token = string | null | undefined

export const usePagination = () => {
  const [nextToken, setNextToken] = useState<Token>()
  const [nextNextToken, setNextNextToken] = useState<Token>()
  const [previousTokens, setPreviousTokens] = useState<Token[]>([])

  const hasNextPage = !!nextNextToken
  const hasPrevPage = !!previousTokens.length
  const nextPage = () => {
    setPreviousTokens(prev => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(undefined)
  }
  const prevPage = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(undefined)
  }
  const resetPages = () => {
    setNextToken(undefined)
    setPreviousTokens([])
    setNextNextToken(undefined)
  }

  return {
    nextToken,
    updateNextToken: setNextNextToken,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  }
}
