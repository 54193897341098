import {Center, Image} from '@chakra-ui/react'
import {Box, Divider} from '../ui'
import Nav from './nav'
import {css} from '@emotion/react'
import NavForum from './NavForum'
import {Eroles} from '../../types'
import {Logo} from '../../Logo'
import {useAuth} from '../../context/AuthContext'
import {useSchool} from '../../commons/api/hooks'

type SidebarProps = {
  menuRoleFilter: Eroles
}

export const Sidebar = ({menuRoleFilter}: SidebarProps) => {
  const {schoolId} = useAuth()
  const {school} = useSchool(schoolId)

  return (
    <Box position="fixed" w="256px" overflow="hidden">
      <Box
        overflow="auto"
        height="100vh"
        w="full"
        css={css`
          &::-webkit-scrollbar {
            display: 'none';
          }
        `}
      >
        {/* ici la box sert juste à englober le composant comme une div et si je mets le logo avec un heading de merde c'est en attendant d'avoir le logo qui sera lui dans une image, je créerai le variant à cet occasion
      Pour ce qui est de Center, je ne pense jamais toucher à cette balise pour autre chose que mettre au centre */}

        <Center w={'200px'} maxHeight={'300px'} margin={'auto'}>
          {school?.image ? (
            <Image
              src={school?.image}
              alt="logo"
              boxSize={'100%'}
              objectFit={'cover'}
            />
          ) : (
            <Logo height={30} />
          )}
        </Center>
        <Divider />
        <Box>
          <Nav menuRoleFilter={menuRoleFilter} />
          {menuRoleFilter === Eroles.STUDIENT && <NavForum />}
        </Box>
      </Box>
    </Box>
  )
}

export default Sidebar
