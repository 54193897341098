import {useEffect} from 'react'
import {useMutation, useQuery} from 'react-query'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types/hooks'
import {queryClient} from '../../config/react-query'
import {
  ConsultationsBySchoolQueryVariables,
  ListFAQConsultationsQueryVariables,
} from '../../types/API'

import {
  createAnswer,
  createConsultation,
  deleteAnswer,
  deleteConsultation,
  getAnswerById,
  getAnswers,
  getConsultation,
  getConsultations,
  getConsultationsBySchool,
  updateAnswer,
  updateConsultation,
} from '../clients'
import {usePagination} from './pagination'

export const useConsultation = (consultationId: string) => {
  const enabled = consultationId ? true : false
  const {isLoading, data} = useQuery(
    [`getConsultationById`, consultationId],
    () => getConsultation(consultationId),
    {enabled: enabled},
  )
  useGlobalLoading(isLoading)
  return {consultation: data, isLoading}
}

export const useConsultations = (
  input?: ListFAQConsultationsQueryVariables,
  isCustom = true,
) => {
  const {
    nextToken,
    updateNextToken,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = usePagination()
  const {isLoading, data} = useQuery(
    [`getConsultations`, input?.limit, input?.filter, nextToken],
    () => getConsultations({...input, nextToken}, isCustom),
  )

  useEffect(() => {
    updateNextToken(data?.nextToken)
  }, [data?.nextToken, updateNextToken])

  useGlobalLoading(isLoading)
  return {
    consultations: data,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  }
}
export const useConsultationsBySchool = (
  input: ConsultationsBySchoolQueryVariables,
  isCustom = true,
) => {
  const {
    nextToken,
    updateNextToken,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = usePagination()

  const {isLoading, data} = useQuery(
    [`getConsultations`, input?.limit, input?.filter, nextToken, isCustom],
    () => getConsultationsBySchool({...input, nextToken}, isCustom),
  )
  useEffect(() => {
    updateNextToken(data?.nextToken)
  }, [data?.nextToken, updateNextToken])
  useGlobalLoading(isLoading)
  return {
    consultations: data,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  }
}

export const useCreateConsultation = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createConsultation, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getConsultations')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess(data)
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useUpdateConsultation = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateConsultation, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getConsultations')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess(data)
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteConsultation = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteConsultation, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getConsultations')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}
//ANSWER

export const useAnswer = (AnswerId: string) => {
  const {isLoading, data} = useQuery(`getAnswerById-${AnswerId}`, () =>
    getAnswerById(AnswerId),
  )
  useGlobalLoading(isLoading)
  return {workspace: data}
}

export const useAnswers = () => {
  const {isLoading, data} = useQuery(`getAnswers`, getAnswers)
  useGlobalLoading(isLoading)
  return {Answers: data}
}

export const useCreateAnswer = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createAnswer, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getAnswers')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useUpdateAnswer = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateAnswer, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getAnswers')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteAnswer = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteAnswer, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getAnswers')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}
