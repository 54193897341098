import {
  Box,
  ContainerPage,
  Grid,
  GridItem,
  // SimpleGrid,
  Stack,
} from '../../components/ui'
import CardUser from './../Card/CardUser'
import {useUserById, useUserCourses} from '../../commons/api/hooks'
import {User} from '../../commons/types/API'
import {CardCours} from '../ProfilUser/CardCours'

import {CardDescription} from '../ProfilUser/CardDescription'
import {CardReseaux} from '../ProfilUser/CardReseaux'
import UserDetailSkeleton from '../Skeletons/UserDetailSkeleton'
import {Image} from '@chakra-ui/react'

type UserDetailsProps = {
  userId: string
}
export const UserDetails = ({userId}: UserDetailsProps) => {
  const {user} = useUserById(userId)

  const courses = user?.courses?.items //userCourses?.items?.map(uc => uc.course) ?? []

  if (!user) {
    return <UserDetailSkeleton />
  }

  return (
    <ContainerPage>
      <Box w="full" mt={4}>
        <Grid
          templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(5, 1fr)'}}
          gap={4}
        >
          <GridItem colSpan={{base: 1, sm: 4}}>
            <Stack spacing={4}>
              <CardUser
                user={user as User}
                util={'pageProfil'}
                domaine={`${user?.firstname ?? ''} ${user?.lastname ?? ''} `}
              />
              <CardDescription userDesc={user?.notes ?? 'Pas de description'} />
              <CardCours userName={user?.username} userCours={courses} />
              {user?.githubId && (
                <Image
                  src={`https://github-readme-stats.vercel.app/api?username=${user?.githubId}&show_icons=true&theme=highcontrast`}
                />
              )}
            </Stack>
          </GridItem>
          <GridItem colSpan={1}>
            <CardReseaux user={user} />
          </GridItem>
        </Grid>
        {/* <SimpleGrid columns={breakpointValue} spacing={4}>
          <Stack spacing={4}>
            <CardUser
              user={user as User}
              util={'pageProfil'}
              domaine={`${user?.firstname} ${user?.lastname}`}
              suppUser={(e: React.MouseEvent) => handleDelete(e, user?.id)}
            />
            <CardCours userName={user?.lastname} userCours={courses} />
          </Stack>
          <Stack spacing={4}>
            <SimpleGrid columns={5} spacing={4}>
              <GridItem colSpan={3}>
                <CardCompetences userCompetences={specials} />
              </GridItem>
              <GridItem colSpan={2}>
                <CardReseaux user={user} />
              </GridItem>
            </SimpleGrid>
            <CardDescription userDesc={user?.notes} />
          </Stack>
        </SimpleGrid> */}
      </Box>
    </ContainerPage>
  )
}
