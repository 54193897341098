import {Route, Routes} from 'react-router-dom'
import {ConsultationPage} from '.'
import {ConsultationQuestions} from './ConsultationQuestionsPage'
import {ConsultationCategoriesPage} from './ConsultationCategoriesPage'
import {ConsultationTagsPage} from './ConsultationTagsPage'
import {ConsultationQuestionByCategoriePage} from './ConsultationQuestionByCategoriePage'
import {ConsultationStepRouter} from './ConsultationRouter'
import {Error404} from './../../common/Error404'
import {AdminFaqQuestionEditor} from './AdminFaqQuestionEditor'
import {QuestionPreviewPage} from './QuestionPreviewPage'

export const AdminFaqRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ConsultationQuestions />} />
      <Route
        path="create-consultation/*"
        element={<ConsultationStepRouter />}
      />
      <Route path="questions/*" element={<ConsultationQuestions />} />
      <Route path="consultations/*" element={<ConsultationPage />} />
      <Route
        path="categories/:categoryId"
        element={<ConsultationQuestionByCategoriePage />}
      />
      <Route
        path="list-categories/*"
        element={<ConsultationCategoriesPage />}
      />
      <Route path="tags/*" element={<ConsultationTagsPage />} />
      <Route path="question-editor" element={<AdminFaqQuestionEditor />} />
      <Route
        path="question-editor/:questionId"
        element={<AdminFaqQuestionEditor />}
      />{' '}
      <Route
        path="question-preview/:questionId"
        element={<QuestionPreviewPage />}
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}
