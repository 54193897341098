import {useParams} from 'react-router-dom'
import UserFaqVideoMyQuestion from '../../../components/FaqVideo/user-faq-question-editor'
import {ContainerPage} from '../../../components/ui'

const FaqQuestionEditor = () => {
  const {consultationId} = useParams()
  return (
    <ContainerPage>
      <UserFaqVideoMyQuestion consultationId={consultationId} />
    </ContainerPage>
  )
}
export {FaqQuestionEditor}
