/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchDeleteMessages = /* GraphQL */ `
  mutation BatchDeleteMessages($ids: [ID]) {
    batchDeleteMessages(ids: $ids) {
      id
      message
      description
      published
      topicMessagesId
      topic {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const batchDeleteTopicsMessages = /* GraphQL */ `
  mutation BatchDeleteTopicsMessages($topicsIds: [ID]!, $messagesIds: [ID]!) {
    batchDeleteTopicsMessages(
      topicsIds: $topicsIds
      messagesIds: $messagesIds
    ) {
      id
      name
      description
      published
      workspaceTopicsId
      workspace {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      userTopicsId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      topicSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createDepartement = /* GraphQL */ `
  mutation CreateDepartement(
    $input: CreateDepartementInput!
    $condition: ModelDepartementConditionInput
  ) {
    createDepartement(input: $input, condition: $condition) {
      id
      name
      managerID
      manager {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      employees {
        items {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDepartement = /* GraphQL */ `
  mutation UpdateDepartement(
    $input: UpdateDepartementInput!
    $condition: ModelDepartementConditionInput
  ) {
    updateDepartement(input: $input, condition: $condition) {
      id
      name
      managerID
      manager {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      employees {
        items {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDepartement = /* GraphQL */ `
  mutation DeleteDepartement(
    $input: DeleteDepartementInput!
    $condition: ModelDepartementConditionInput
  ) {
    deleteDepartement(input: $input, condition: $condition) {
      id
      name
      managerID
      manager {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      employees {
        items {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      employeeDepartementId
      name
      age
      departement {
        id
        name
        managerID
        manager {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      projects {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      employeeDepartementId
      name
      age
      departement {
        id
        name
        managerID
        manager {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      projects {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      employeeDepartementId
      name
      age
      departement {
        id
        name
        managerID
        manager {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      projects {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEmployeeProjects = /* GraphQL */ `
  mutation CreateEmployeeProjects(
    $input: CreateEmployeeProjectsInput!
    $condition: ModelEmployeeProjectsConditionInput
  ) {
    createEmployeeProjects(input: $input, condition: $condition) {
      id
      projectEmployeeId
      employeeProjectId
      employee {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployeeProjects = /* GraphQL */ `
  mutation UpdateEmployeeProjects(
    $input: UpdateEmployeeProjectsInput!
    $condition: ModelEmployeeProjectsConditionInput
  ) {
    updateEmployeeProjects(input: $input, condition: $condition) {
      id
      projectEmployeeId
      employeeProjectId
      employee {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployeeProjects = /* GraphQL */ `
  mutation DeleteEmployeeProjects(
    $input: DeleteEmployeeProjectsInput!
    $condition: ModelEmployeeProjectsConditionInput
  ) {
    deleteEmployeeProjects(input: $input, condition: $condition) {
      id
      projectEmployeeId
      employeeProjectId
      employee {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      employees {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      employees {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      employees {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      description
      domain
      emailsupport
      lang
      message
      name
      url
      welcomeMessage
      image
      courses {
        items {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      consultations {
        items {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      description
      domain
      emailsupport
      lang
      message
      name
      url
      welcomeMessage
      image
      courses {
        items {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      consultations {
        items {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      description
      domain
      emailsupport
      lang
      message
      name
      url
      welcomeMessage
      image
      courses {
        items {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      consultations {
        items {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      description
      id
      image
      name
      published
      urlProduct
      courseSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      description
      id
      image
      name
      published
      urlProduct
      courseSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      description
      id
      image
      name
      published
      urlProduct
      courseSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $input: CreateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    createWorkspace(input: $input, condition: $condition) {
      id
      name
      description
      image
      published
      open
      courseWorkspacesId
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaceSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkspace = /* GraphQL */ `
  mutation UpdateWorkspace(
    $input: UpdateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    updateWorkspace(input: $input, condition: $condition) {
      id
      name
      description
      image
      published
      open
      courseWorkspacesId
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaceSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkspace = /* GraphQL */ `
  mutation DeleteWorkspace(
    $input: DeleteWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    deleteWorkspace(input: $input, condition: $condition) {
      id
      name
      description
      image
      published
      open
      courseWorkspacesId
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaceSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      name
      description
      published
      workspaceTopicsId
      workspace {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      userTopicsId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      topicSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      name
      description
      published
      workspaceTopicsId
      workspace {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      userTopicsId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      topicSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      id
      name
      description
      published
      workspaceTopicsId
      workspace {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      userTopicsId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      topicSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      message
      description
      published
      topicMessagesId
      topic {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      message
      description
      published
      topicMessagesId
      topic {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      message
      description
      published
      topicMessagesId
      topic {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      settings
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      settings
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      settings
      createdAt
      updatedAt
    }
  }
`;
export const createFAQCategory = /* GraphQL */ `
  mutation CreateFAQCategory(
    $input: CreateFAQCategoryInput!
    $condition: ModelFAQCategoryConditionInput
  ) {
    createFAQCategory(input: $input, condition: $condition) {
      id
      name
      description
      published
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateFAQCategory = /* GraphQL */ `
  mutation UpdateFAQCategory(
    $input: UpdateFAQCategoryInput!
    $condition: ModelFAQCategoryConditionInput
  ) {
    updateFAQCategory(input: $input, condition: $condition) {
      id
      name
      description
      published
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteFAQCategory = /* GraphQL */ `
  mutation DeleteFAQCategory(
    $input: DeleteFAQCategoryInput!
    $condition: ModelFAQCategoryConditionInput
  ) {
    deleteFAQCategory(input: $input, condition: $condition) {
      id
      name
      description
      published
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createFAQTag = /* GraphQL */ `
  mutation CreateFAQTag(
    $input: CreateFAQTagInput!
    $condition: ModelFAQTagConditionInput
  ) {
    createFAQTag(input: $input, condition: $condition) {
      id
      name
      description
      published
      questions {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateFAQTag = /* GraphQL */ `
  mutation UpdateFAQTag(
    $input: UpdateFAQTagInput!
    $condition: ModelFAQTagConditionInput
  ) {
    updateFAQTag(input: $input, condition: $condition) {
      id
      name
      description
      published
      questions {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteFAQTag = /* GraphQL */ `
  mutation DeleteFAQTag(
    $input: DeleteFAQTagInput!
    $condition: ModelFAQTagConditionInput
  ) {
    deleteFAQTag(input: $input, condition: $condition) {
      id
      name
      description
      published
      questions {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      questionAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      answerAuthorId
      answerAuthor {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      rewording
      description
      questionVideoUrl
      thumb
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      category {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      file
      answer {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      answerUrl
      answerContent
      answerVideoId
      answerVideoProvider
      status
      questionsSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      fAQConsultationQuestionsId
      faqConsultation {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userQuestionsId
      questionCategoryId
      questionAnswerId
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      questionAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      answerAuthorId
      answerAuthor {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      rewording
      description
      questionVideoUrl
      thumb
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      category {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      file
      answer {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      answerUrl
      answerContent
      answerVideoId
      answerVideoProvider
      status
      questionsSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      fAQConsultationQuestionsId
      faqConsultation {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userQuestionsId
      questionCategoryId
      questionAnswerId
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      questionAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      answerAuthorId
      answerAuthor {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      rewording
      description
      questionVideoUrl
      thumb
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      category {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      file
      answer {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      answerUrl
      answerContent
      answerVideoId
      answerVideoProvider
      status
      questionsSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      fAQConsultationQuestionsId
      faqConsultation {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userQuestionsId
      questionCategoryId
      questionAnswerId
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      description
      published
      commentQuestionId
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      commentAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userCommentsId
      questionCommentsId
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      description
      published
      commentQuestionId
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      commentAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userCommentsId
      questionCommentsId
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      description
      published
      commentQuestionId
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      commentAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userCommentsId
      questionCommentsId
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      userAnswersId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      description
      urlVideo
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      userAnswersId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      description
      urlVideo
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      userAnswersId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      description
      urlVideo
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createFAQConsultation = /* GraphQL */ `
  mutation CreateFAQConsultation(
    $input: CreateFAQConsultationInput!
    $condition: ModelFAQConsultationConditionInput
  ) {
    createFAQConsultation(input: $input, condition: $condition) {
      id
      title
      description
      status
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      consultationSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateFAQConsultation = /* GraphQL */ `
  mutation UpdateFAQConsultation(
    $input: UpdateFAQConsultationInput!
    $condition: ModelFAQConsultationConditionInput
  ) {
    updateFAQConsultation(input: $input, condition: $condition) {
      id
      title
      description
      status
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      consultationSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteFAQConsultation = /* GraphQL */ `
  mutation DeleteFAQConsultation(
    $input: DeleteFAQConsultationInput!
    $condition: ModelFAQConsultationConditionInput
  ) {
    deleteFAQConsultation(input: $input, condition: $condition) {
      id
      title
      description
      status
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      consultationSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      name
      description
      messages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      contributors {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      name
      description
      messages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      contributors {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      name
      description
      messages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      contributors {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createUserChatRoom = /* GraphQL */ `
  mutation CreateUserChatRoom(
    $input: CreateUserChatRoomInput!
    $condition: ModelUserChatRoomConditionInput
  ) {
    createUserChatRoom(input: $input, condition: $condition) {
      id
      userId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      chatRoomId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatRoomsId
      chatRoomContributorsId
    }
  }
`;
export const updateUserChatRoom = /* GraphQL */ `
  mutation UpdateUserChatRoom(
    $input: UpdateUserChatRoomInput!
    $condition: ModelUserChatRoomConditionInput
  ) {
    updateUserChatRoom(input: $input, condition: $condition) {
      id
      userId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      chatRoomId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatRoomsId
      chatRoomContributorsId
    }
  }
`;
export const deleteUserChatRoom = /* GraphQL */ `
  mutation DeleteUserChatRoom(
    $input: DeleteUserChatRoomInput!
    $condition: ModelUserChatRoomConditionInput
  ) {
    deleteUserChatRoom(input: $input, condition: $condition) {
      id
      userId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      chatRoomId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatRoomsId
      chatRoomContributorsId
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage(
    $input: CreateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    createChatMessage(input: $input, condition: $condition) {
      id
      message
      description
      published
      chatRoomMessagesId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatMessagesId
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage(
    $input: UpdateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      message
      description
      published
      chatRoomMessagesId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatMessagesId
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage(
    $input: DeleteChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      message
      description
      published
      chatRoomMessagesId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatMessagesId
    }
  }
`;
export const createUserCourses = /* GraphQL */ `
  mutation CreateUserCourses(
    $input: CreateUserCoursesInput!
    $condition: ModelUserCoursesConditionInput
  ) {
    createUserCourses(input: $input, condition: $condition) {
      id
      courseID
      userID
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const updateUserCourses = /* GraphQL */ `
  mutation UpdateUserCourses(
    $input: UpdateUserCoursesInput!
    $condition: ModelUserCoursesConditionInput
  ) {
    updateUserCourses(input: $input, condition: $condition) {
      id
      courseID
      userID
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const deleteUserCourses = /* GraphQL */ `
  mutation DeleteUserCourses(
    $input: DeleteUserCoursesInput!
    $condition: ModelUserCoursesConditionInput
  ) {
    deleteUserCourses(input: $input, condition: $condition) {
      id
      courseID
      userID
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const createQuestionTags = /* GraphQL */ `
  mutation CreateQuestionTags(
    $input: CreateQuestionTagsInput!
    $condition: ModelQuestionTagsConditionInput
  ) {
    createQuestionTags(input: $input, condition: $condition) {
      id
      fAQTagID
      questionID
      fAQTag {
        id
        name
        description
        published
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const updateQuestionTags = /* GraphQL */ `
  mutation UpdateQuestionTags(
    $input: UpdateQuestionTagsInput!
    $condition: ModelQuestionTagsConditionInput
  ) {
    updateQuestionTags(input: $input, condition: $condition) {
      id
      fAQTagID
      questionID
      fAQTag {
        id
        name
        description
        published
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const deleteQuestionTags = /* GraphQL */ `
  mutation DeleteQuestionTags(
    $input: DeleteQuestionTagsInput!
    $condition: ModelQuestionTagsConditionInput
  ) {
    deleteQuestionTags(input: $input, condition: $condition) {
      id
      fAQTagID
      questionID
      fAQTag {
        id
        name
        description
        published
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
