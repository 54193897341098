import {Card} from '../Card/Card'
import {Box} from '../Box/Box'
import ReactMarkDownChakra from '../ui/ReactMarkDownChakra'

type CardDescriptionProps = {
  userDesc: any
}

export const CardDescription = ({userDesc}: CardDescriptionProps) => {
  return (
    <Card
      title="Description"
      data={
        <Box variant={'contentText'}>
          <ReactMarkDownChakra>{userDesc}</ReactMarkDownChakra>
        </Box>
      }
    />
  )
}

export default CardDescription
