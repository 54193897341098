import {useEffect} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useUserDetailSchoolGroupId} from '../../../context/AuthContext'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types/hooks'
import {queryClient} from '../../config/react-query'

import {
  ListFAQCategoriesQueryVariables,
  ListQuestionTagsQueryVariables,
  ListFAQTagsQueryVariables,
  ListQuestionsQueryVariables,
  QuestionsBySchoolQueryVariables,
  QuestionsBySchoolTitleQueryVariables,
  QuestionsByAuthorQueryVariables,
  QuestionsByDateQueryVariables,
  QuestionsByConsultationQueryVariables,
} from '../../types/API'

import {
  createFAQCategory,
  createFAQTag,
  createQuestionTags,
  createQuestion,
  deleteFAQCategory,
  deleteFAQTag,
  deleteQuestionTags,
  deleteQuestion,
  getFAQCategories,
  getFAQCategoryById,
  getFAQTagById,
  getQuestionsTag,
  getQuestionTagsById,
  getFAQTags,
  getQuestionById,
  getQuestions,
  updateFAQCategory,
  updateFAQTag,
  updateQuestionTags,
  updateQuestion,
  getQuestionsByScoolId,
  getQuestionsByScoolIdtitle,
  updateComment,
  createComment,
  deleteComment,
  getQuestionsByAuthor,
  createQuestionAndTags,
  getQuestionsByStatusSortByDate,
  getQuestionsByConsultationId,
} from '../clients'
import {usePagination} from './pagination'

export const useQuestion = (questionId: string) => {
  const {isLoading, data} = useQuery(`getQuestionById-${questionId}`, () =>
    getQuestionById(questionId),
  )
  useGlobalLoading(isLoading)
  return {question: data}
}

export const useQuestions = (input?: ListQuestionsQueryVariables) => {
  const schoolGroupId = useUserDetailSchoolGroupId()
  const {isLoading, data} = useQuery(
    [`getQuestions`, input?.limit, input?.filter, input?.nextToken],
    () =>
      getQuestions({
        filter: {
          ...input?.filter,
          groupsCanAccess: {
            contains: `${schoolGroupId}`,
          },
        },
        limit: input?.limit,
        nextToken: input?.nextToken,
      }),
  )
  useGlobalLoading(isLoading)
  return {questions: data}
}

export const useQuestionsBySchool = (
  input: QuestionsBySchoolQueryVariables,
) => {
  const {isLoading, data} = useQuery(
    [
      `getQuestionsByScoolId`,
      input?.limit,
      input?.filter,
      input?.filter?.title,
      input?.status,
      input?.nextToken,
      input?.sortDirection,
    ],
    () => getQuestionsByScoolId(input),
  )
  useGlobalLoading(isLoading)
  return {questions: data}
}

export const useQuestionsByAuthor = (
  input: QuestionsByAuthorQueryVariables,
) => {
  const {
    nextToken,
    updateNextToken,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = usePagination()
  const {isLoading, data} = useQuery(
    [
      `getQuestionsByAuthor`,
      input?.limit,
      input?.filter,
      input?.questionAuthorId,
      input?.sortDirection,
      nextToken,
    ],
    () => getQuestionsByAuthor({...input, nextToken}),
  )
  useEffect(() => {
    updateNextToken(data?.nextToken)
  }, [data?.nextToken, updateNextToken])

  useGlobalLoading(isLoading)
  return {
    questions: data,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
    nextToken,
  }
}

export const useQuestionsBySchoolTitle = (
  input: QuestionsBySchoolTitleQueryVariables,
) => {
  const {isLoading, data} = useQuery(
    [
      `getQuestionsByScoolId`,
      input?.limit,
      input?.filter,
      input?.filter?.title,
      input?.title,
      input?.nextToken,
      input?.sortDirection,
    ],
    () => getQuestionsByScoolIdtitle(input),
  )
  useGlobalLoading(isLoading)
  return {questions: data}
}

export const useQuestionsByConsultationId = (
  input: QuestionsByConsultationQueryVariables,
) => {
  const {nextToken, hasNextPage, hasPrevPage, nextPage, prevPage, resetPages} =
    usePagination()
  const {isLoading, data} = useQuery(
    [
      `getQuestionsByConsultationId`,
      input?.limit,
      input?.filter,
      input?.sortDirection,
      input?.fAQConsultationQuestionsId,
      nextToken,
    ],
    () => getQuestionsByConsultationId({...input, nextToken}),
  )
  useGlobalLoading(isLoading)
  return {
    questionsConsultation: data,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
    nextToken,
  }
}

export const useQuestionsByStatusSortByDate = (
  input: QuestionsByDateQueryVariables,
) => {
  const {
    nextToken,
    updateNextToken,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = usePagination()
  const {isLoading, data} = useQuery(
    [
      `getQuestionsByStatusDate`,
      input?.limit,
      input?.filter,
      input?.filter?.status,
      input?.status,
      input?.sortDirection,
      nextToken,
    ],
    () => getQuestionsByStatusSortByDate({...input, nextToken}),
  )
  useEffect(() => {
    updateNextToken(data?.nextToken)
  }, [data?.nextToken, updateNextToken])

  useGlobalLoading(isLoading)
  return {
    questions: data,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
    nextToken,
  }
}

export const useCreateQuestion = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createQuestion, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getQuestions')
      queryClient.invalidateQueries('getQuestionsByAuthor')

      fetchEnd()
      setMessage('Votre question a été envoyée')
      onSuccess(data)
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation', error.errors[0])
      setGlobalError('Erreur lors de la création de la question')
      onError()
    },
  })
  return mutation
}

export const useUpdateQuestion = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateQuestion, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getQuestions')
      queryClient.invalidateQueries('getQuestionsByAuthor')
      queryClient.invalidateQueries('getConsultationById')
      // `getConsultationById-${consultationId}`,
      fetchEnd()
      setMessage('Votre question a été mise à jour')
      onSuccess(data)
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteQuestion = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteQuestion, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getQuestions')
      queryClient.invalidateQueries('getQuestionsByAuthor')
      queryClient.invalidateQueries('getQuestionsByStatusDate')
      fetchEnd()
      setMessage('Votre question a été suprimée')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

//FAQTAG

export const useFAQTag = (FAQTagId: string) => {
  const {isLoading, data} = useQuery(`getFAQTagById-${FAQTagId}`, () =>
    getFAQTagById(FAQTagId),
  )
  useGlobalLoading(isLoading)
  return {workspace: data}
}

export const useFAQTags = (input?: ListFAQTagsQueryVariables) => {
  const {
    nextToken,
    updateNextToken,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
  } = usePagination()
  const {isLoading, data} = useQuery(
    [`getFAQTags`, input?.limit, nextToken],
    () => getFAQTags({...input, nextToken}),
  )

  useEffect(() => {
    updateNextToken(data?.nextToken)
  }, [data?.nextToken, updateNextToken])
  useGlobalLoading(isLoading)

  return {
    FAQTags: data,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    nextToken,
  }
}

export const useCreateFAQTag = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createFAQTag, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQTags')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useUpdateFAQTag = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateFAQTag, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQTags')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteFAQTag = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteFAQTag, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQTags')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

//FAQCategory

export const useFAQCategory = (FAQCategoryId: string) => {
  const {isLoading, data} = useQuery(
    `getFAQCategoryById-${FAQCategoryId}`,
    () => getFAQCategoryById(FAQCategoryId),
  )
  useGlobalLoading(isLoading)
  return {workspace: data}
}

export const useFAQCategories = (input?: ListFAQCategoriesQueryVariables) => {
  const {
    nextToken,
    updateNextToken,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = usePagination()

  const {isLoading, data} = useQuery(
    [`getFAQCategories`, input?.limit, input?.filter, nextToken],
    () => getFAQCategories({...input, nextToken}),
  )
  useEffect(() => {
    updateNextToken(data?.nextToken)
  }, [data?.nextToken, updateNextToken])

  useGlobalLoading(isLoading)
  return {
    FAQCategories: data,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    nextToken,
    resetPages,
  }
}

export const useCreateFAQCategory = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createFAQCategory, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQCategories')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useUpdateFAQCategory = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateFAQCategory, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQCategories')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteFAQCategory = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteFAQCategory, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQCategories')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

//FAQTAGQuestions

export const useFAQTagQuestions = (FAQTagQuestionsId: string) => {
  const {isLoading, data} = useQuery(
    `getFAQTagQuestionsById-${FAQTagQuestionsId}`,
    () => getQuestionTagsById(FAQTagQuestionsId),
  )
  useGlobalLoading(isLoading)
  return {workspace: data}
}

export const useFAQTagQuestionss = (input?: ListQuestionTagsQueryVariables) => {
  const {isLoading, data} = useQuery(
    [`getFAQTagQuestionss`, input?.limit, input?.nextToken],
    () => getQuestionsTag(input),
  )
  useGlobalLoading(isLoading)
  return {FAQTagQuestionss: data, isLoading}
}

export const useCreateQuestionTags = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createQuestionTags, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQTagQuestionss')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useCreateQuestionAndCreateTags = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createQuestionAndTags, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQTagQuestionss')
      queryClient.invalidateQueries('getQuestionsByAuthor')
      fetchEnd()
      setMessage('Votre question a été envoyée')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useUpdateQuestionTags = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateQuestionTags, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQTagQuestionss')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteQuestionTags = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteQuestionTags, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getFAQTagQuestionss')
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}
// Comment

export const useCreateComment = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createComment, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      //@ts-ignore
      queryClient.invalidateQueries(`getQuestionById-${data?.question.id}`)
      fetchEnd()
      setMessage('Votre commentaire a été envoyée')
      onSuccess(data)
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation', error.errors[0])
      setGlobalError('Erreur lors de la création de la Comment')
      onError()
    },
  })
  return mutation
}

export const useUpdateComment = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateComment, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getComments')
      queryClient.invalidateQueries(
        `getQuestionById-${data?.commentQuestionId}`,
      )

      fetchEnd()
      setMessage('Votre commentaire a été mis à jour')
      onSuccess(data)
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteComment = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteComment, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getComments')

      queryClient.invalidateQueries(
        //@ts-ignore
        `getQuestionById-${data?.commentQuestionId}`,
      )
      fetchEnd()
      setMessage('Votre commentaire a été supprimé')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}
