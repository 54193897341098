import {GraphQLResult} from '@aws-amplify/api-graphql'
import {SignUpParams} from '@aws-amplify/auth'
import {Auth, API, graphqlOperation} from 'aws-amplify'
import {me} from '../../graphql/queries'
import {updateUser as updateUserMutation} from '../../graphql/mutations'
import {User, UpdateUserInput} from '../../types/API'
import {UserStatus} from '../../../types'
import {GraphQLUserCommonResult} from '../../types/graphQLCommonTypes'
// A VOIR SI LES AUTH. ça sert de les mettre ici AWS le fait déja

// TODO SignUpParams PAS DE TYPE SIGNIN ?
export const signIn = async (params: SignUpParams) => {
  return await Auth.signIn(params.username, params.password)
}
export const signUp = async (params: SignUpParams) => {
  return await Auth.signUp(params)
}
export const signOut = async () => {
  return Auth.signOut()
}

export const confirmSignUp = async (
  username: string,
  code: string,
  options: any,
) => {
  return await Auth.confirmSignUp(username, code, options)
}

export const resetPassword = async (email: string) => {
  return await Auth.forgotPassword(email)
}
export type ResetSubmitCode = {
  email: string
  code: string
  password: string
}
export const resetPasswordSubmitCode = async ({
  email,
  code,
  password,
}: ResetSubmitCode) => {
  return Auth.forgotPasswordSubmit(email, code, password, {
    forgotPasswordSubmit: 'true',
  })
}

//Promise<GraphQLResult<User>>
// TODO trouver le type des variables de graphQl
export const getMe = async (variables?: any) => {
  const data = await (API.graphql(graphqlOperation(me, {id: '_'})) as Promise<
    GraphQLResult<GraphQLUserCommonResult>
  >)
  return data?.data?.me
}

export const updateUserMe = async (user?: UpdateUserInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateUserMutation, {input: user}),
  ) as Promise<GraphQLResult<GraphQLUserCommonResult>>)

  return data?.data?.updateUser
}

export const updateUser = async (user?: UpdateUserInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateUserMutation, {input: user}),
  ) as Promise<GraphQLResult<GraphQLUserCommonResult>>)

  return data?.data?.updateUser
}
export const updateUserStatus = async ({
  user,
  status,
}: {
  user?: UpdateUserInput
  status?: UserStatus
}) => {
  if (user) {
    user.status = status
  }
  return await updateUser({id: user?.id ?? '', status: status})
}
export const updateAuthUser: any = async (user?: UpdateUserInput) => {
  Auth.currentAuthenticatedUser().then(authUser => {
    Auth.updateUserAttributes(authUser, {
      name: user?.username,
    }).then(dataUp => {
      return dataUp
    })
  })

  // const data = await API.graphql(graphqlOperation(updateUser, {input: user}))
  // return await data.data.updateUser
}
