import {
  useCreateQuestionTags,
  useCreateQuestion,
  useDeleteQuestion,
  useUpdateQuestion,
  useDeleteQuestionTags,
  useDeleteComment,
  useCreateQuestionAndCreateTags,
  useQuestions,
  useQuestionsByStatusSortByDate,
} from '../commons/api/hooks'
import {
  Comment,
  CreateQuestionInput,
  CreateQuestionTagsInput,
  DeleteQuestionTagsInput,
  ListQuestionsQueryVariables,
  Question,
  QuestionsByDateQueryVariables,
  UpdateQuestionInput,
} from '../commons/types/API'
import React, {useEffect, useMemo} from 'react'
import {modalYesNoPromise} from '../components/Modals/YesNo'
import {useDisclosure} from '@chakra-ui/react'

import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  ROUTE_ADMIN_FAQ_QUESTION_EDITOR,
  ROUTE_ADMIN_FAQ_QUESTION_PREVIEW,
  ROUTE_SCHOOL_FAQ_QUESTION,
  ROUTE_SCHOOL_FAQ_QUESTION_EDITOR,
} from '../constantes'
import {hasAnyRolesAdminOwner} from '../commons'
import {useAuth} from '../context/AuthContext'
import {usePagination} from '../commons/api/hooks/pagination'
import {EStatus, SearchType} from '../types'

/**
 * @deprecated ne pas utiliser ! Utiliser un vrai filtre graphQL
 * @param name
 * @returns
 */
export const useFilterQuestionsByCategory = (
  /*nom de la categorie*/ name = '',
) => {
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([])
  const {questions} = useQuestions()

  const filteredData = useMemo(() => {
    if (!questions || !questions.items) return []
    if (name === '') {
      return questions.items
    } else {
      return questions.items.filter(question => {
        if (question?.category && question.category.name === name) {
          return question
        }
      })
    }
  }, [questions, name])

  useEffect(() => {
    setFilteredQuestions(filteredData as Question[])
  }, [filteredData])

  return filteredQuestions
}

export const useSortQuestionsByDate = (data: any[], filterType: string) => {
  const [sortedQuestions, setSortedQuestions] = useState<any[]>([])

  useEffect(() => {
    if (!data) return
    const sortedItems = data.slice().sort((a, b) => {
      if (filterType === 'asc') {
        return Date.parse(b.createdAt) - Date.parse(a.createdAt)
      } else {
        return Date.parse(a.createdAt) - Date.parse(b.createdAt)
      }
    })
    setSortedQuestions(sortedItems)
  }, [data, filterType])

  return sortedQuestions
}

export const useQuestionsByFilter = (
  input?: Omit<ListQuestionsQueryVariables, 'nextToken'>,
) => {
  const {
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    nextToken,
    resetPages,
    updateNextToken,
  } = usePagination()

  const {questions} = useQuestions({
    limit: input?.limit,
    filter: input?.filter,
    nextToken: nextToken,
  })
  useEffect(() => {
    updateNextToken(questions?.nextToken)
  }, [questions?.nextToken, updateNextToken])

  return {
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
    nextToken,
    questions,
  }
}

export const useQuestionWithFilterAndStatusOrFilterOnly = (
  searchType: SearchType,
  input?: QuestionsByDateQueryVariables,
) => {
  const [status, setStatus] = useState<string>(input?.status as string)
  const [search, setSearch] = useState<string>(input?.filter?.title as string)

  useEffect(() => {
    if (
      status !== input?.status &&
      searchType === SearchType.STATUS_AND_FILTER
    ) {
      setStatus(input?.status as string)
    }
  }, [input?.status, status, searchType])

  useEffect(() => {
    if (search !== input?.filter?.title) {
      setSearch(input?.filter?.title as string)
    }
  }, [input?.filter?.title, search])

  const inputTypeFilterOnly =
    searchType === SearchType.FILTER_ONLY ? input?.filter : null
  const inputTypeStatusAndFilter =
    searchType === SearchType.STATUS_AND_FILTER ? input?.filter : null

  const {
    hasNextPage: hasNextFilterPage,
    hasPrevPage: hasPrevFilterPage,
    nextPage: nextFilterPage,
    prevPage: prevFilterPage,
    resetPages: resetFilterPage,
    questions: questionsFilter,
  } = useQuestionsByFilter({
    limit: input?.limit,
    filter: inputTypeFilterOnly,
  })

  const {
    hasNextPage: hasNextStatusPage,
    hasPrevPage: hasPrevStatusPage,
    nextPage: nextStatusPage,
    prevPage: prevStatusPage,
    resetPages: resetStatusPage,
    questions: questionsStatus,
  } = useQuestionsByStatusSortByDate({
    status: status,
    sortDirection: input?.sortDirection,
    limit: input?.limit,
    filter: inputTypeStatusAndFilter,
  })

  const {hasNext, hasPrev, prev, next, questions} = React.useMemo(() => {
    if (searchType === SearchType.STATUS_AND_FILTER) {
      if (
        hasPrevStatusPage &&
        (input?.status !== status || input?.filter?.title !== search)
      ) {
        resetStatusPage()
      }
      return {
        hasPrev: hasPrevStatusPage,
        hasNext: hasNextStatusPage,
        prev: prevStatusPage,
        next: nextStatusPage,
        questions: questionsStatus,
      }
    } else {
      if (hasPrevFilterPage && input?.filter?.title !== search) {
        resetFilterPage()
      }
      return {
        hasPrev: hasPrevFilterPage,
        hasNext: hasNextFilterPage,
        prev: prevFilterPage,
        next: nextFilterPage,
        questions: questionsFilter,
      }
    }
  }, [
    search,
    status,
    input?.filter,
    searchType,
    input?.status,
    resetStatusPage,
    resetFilterPage,
    hasPrevStatusPage,
    hasNextStatusPage,
    hasPrevFilterPage,
    hasNextFilterPage,
    prevStatusPage,
    nextStatusPage,
    prevFilterPage,
    nextFilterPage,
    questionsStatus,
    questionsFilter,
  ])

  return {
    hasNext,
    hasPrev,
    prev,
    next,
    questions,
  }
}

/**
 * Hook permettant de gerer le Save Question d'un formulaire
 * @param param0
 * @returns
 */
export const useSaveQuestion = ({onSuccess, isEdit}: any) => {
  const createTags = (tagsDataToAdd: any[], questionID: string) => {
    tagsDataToAdd?.map((tag: any) => {
      const tagsInput: CreateQuestionTagsInput = {
        //id: '_',
        fAQTagID: tag.value.id,
        questionID,
      }
      mutationCreateQuestionTags.mutate(tagsInput)
      return false
    })
  }
  const mutationCreateQuestion = useCreateQuestion({onSuccess: onSuccess})
  const mutationCreateQuestionAndTags = useCreateQuestionAndCreateTags({
    onSuccess: onSuccess,
  })

  const mutationUpdateQuestion = useUpdateQuestion({onSuccess: onSuccess})
  const mutationCreateQuestionTags = useCreateQuestionTags({
    onSuccess: onSuccess,
  })

  const mutationDeleteQuestionTags = useDeleteQuestionTags({
    onSuccess: onSuccess,
  })

  const handleSaveQuestion = (
    values: UpdateQuestionInput & CreateQuestionInput & {tagsData?: any[]},
  ) => {
    //console.log('handleModalSaveQuestion values', values)

    const {tagsData, ...createQuestionInput} = values
    const {id, ...updateQuestionInput} = createQuestionInput
    //console.log('tagsData tagsData', tagsData)
    if (isEdit) {
      mutationUpdateQuestion.mutate(createQuestionInput)

      tagsData?.[0]?.toDel?.map((questionTag: any) => {
        const tagsInput: DeleteQuestionTagsInput = {
          id: questionTag.id,
        }
        mutationDeleteQuestionTags.mutate(tagsInput)
        return false
      })
      createTags(tagsData?.[0]?.toAdd, createQuestionInput.id)
      // tagsData?.[0].toAdd?.map((tag: any) => {
      //   const tagsInput: CreateQuestionTagsInput = {
      //     //id: '_',
      //     fAQTagID: tag.value.id,
      //     questionID: createQuestionInput.id,
      //   }
      //   mutationCreateQuestionTags.mutate(tagsInput)
      //   return false
      // })
    } else {
      // Trois solution pour la creation de TAG a la volé lors de la creation
      //1. Faire un custom Query AppSync
      //2. Faire un client HTTP useCreateQuestionAndTag -> faire en serie les ajout
      //3. useCreateQuestion({onSuccess: createTag}) ;e onsucess retournera id de la qestion
      // ce qui nous permettra de creer les question TAG
      //mutationCreateQuestion.mutate(createQuestionInput)

      //option 2
      //array of all Tag input
      const tagsInput: CreateQuestionTagsInput[] = []

      tagsData?.[0]?.toAdd?.map((tag: any) => {
        const tagInput: CreateQuestionTagsInput = {
          //id: '_',
          fAQTagID: tag.value.id,
          questionID: createQuestionInput.id,
        }
        tagsInput.push(tagInput)
      })
      mutationCreateQuestionAndTags.mutate({
        question: createQuestionInput,
        tags: tagsInput,
      })
    }
  }
  return {
    handleSaveQuestion,
    isLoading:
      mutationCreateQuestion.isLoading ||
      mutationUpdateQuestion.isLoading ||
      mutationCreateQuestionAndTags.isLoading ||
      mutationCreateQuestionTags.isLoading,
    isSuccess:
      mutationCreateQuestion.isSuccess ||
      mutationUpdateQuestion.isSuccess ||
      mutationCreateQuestionAndTags.isSuccess ||
      mutationCreateQuestionTags.isSuccess,
  }
}
/**
 * Custom hook to manage popup Add or Update Question
 * @param questions
 * @returns
 */
export const useOpenQuestion = ({questions}: any) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const [selectedQuestion, setSelectedQuestion] = React.useState<Question>()
  const navigate = useNavigate()
  const {authUser} = useAuth()
  const isAdmin = hasAnyRolesAdminOwner(authUser)

  /**
   * @deprecated utiliser la page d'édition de question plûtot que la popup
   * @param name
   * @replaceBy handleOpenNewQuestionWithEditor
   */
  const handleOpenNewQuestion = React.useCallback(() => {
    setIsEdit(false)
    setSelectedQuestion(undefined)
    onOpen()
  }, [onOpen])

  const handleOpenNewQuestionWithEditor = React.useCallback(() => {
    isAdmin
      ? navigate(ROUTE_ADMIN_FAQ_QUESTION_EDITOR)
      : navigate(ROUTE_SCHOOL_FAQ_QUESTION_EDITOR)
  }, [navigate, isAdmin])

  /**
   * TODO: utiliser la page d'édition de question plûtot que la popup
   * */

  const handleOpenUpdateQuestion = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      setIsEdit(true)
      const element = questions?.items?.find(
        (elm: Question) => elm.id === value,
      )
      // onOpen()
      setSelectedQuestion(element)

      isAdmin
        ? navigate(`${ROUTE_ADMIN_FAQ_QUESTION_EDITOR}/${element?.id}`)
        : navigate(`${ROUTE_SCHOOL_FAQ_QUESTION_EDITOR}/${element?.id}`)
    },
    [navigate, isAdmin, questions],
  )

  const handleViewQuestion = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      setIsEdit(true)
      const element = questions?.items?.find(
        (elm: Question) => elm.id === value,
      )
      // onOpen()
      setSelectedQuestion(element)

      element.status === EStatus.PUBLISHED
        ? navigate(`${ROUTE_SCHOOL_FAQ_QUESTION}/${element?.id}`)
        : navigate(`${ROUTE_ADMIN_FAQ_QUESTION_PREVIEW}/${element?.id}`)
    },
    [navigate, questions],
  )

  return {
    isEdit,
    selectedQuestion,
    isOpen,
    onOpen,
    onClose,
    handleOpenNewQuestion,
    handleOpenNewQuestionWithEditor,
    handleOpenUpdateQuestion,
    handleViewQuestion,
  }
}
/**
 * @deprecated use commonAction instead
 * Attention ce hook s'utilise dans un composant contenant
 * <ModalContainer />
 * @param questions
 * @returns
 */
export const usePopupDeleteQuestion = ({questions}: any) => {
  const mutationDeleteQuestion = useDeleteQuestion()
  const handleOpenDeleteReponse = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      const data = questions?.items?.find((elm: any) => elm.id === value)
      modalYesNoPromise({
        title: 'Supprimer',
        header: data?.title,
        confirmLabel: 'Supprimer',
        data,
        labelOfType: 'prog',
        children: <div>Souhaitez vous vraiment supprimer la réponse</div>,
      })
        .then(ok => {
          mutationDeleteQuestion.mutate(value)
        })
        .catch(error => {})
    },
    [questions, mutationDeleteQuestion],
  )
  return {handleOpenDeleteReponse}
}

/**
 *
 * Attention ce hook s'utilise dans un composant contenant
 * <ModalContainer />
 * @param questions
 * @returns
 */
type popupDeleteCommentProps = {
  comments?: Array<Comment | null>
}
export const usePopupDeleteComment = ({comments}: popupDeleteCommentProps) => {
  const mutationDeleteComment = useDeleteComment()
  const handleOpenDeleteComment = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      //console.log('value', value)

      e.stopPropagation()
      const data = comments?.find((elm: any) => elm.id === value)
      //console.log('data', data)
      modalYesNoPromise({
        title: 'Supprimer',
        header: `Utilisateur ${data?.author.username}`,
        confirmLabel: 'Supprimer',
        infoMessage: 'Supprimer le commentaire ?',
        data,
        labelOfType: 'prog',
        children: (
          <>
            <div>
              <b>Commentaires : </b>
              {data?.description}
            </div>
          </>
        ),
      })
        .then(ok => {
          mutationDeleteComment.mutate(value)
        })
        .catch(error => {})
    },
    [comments, mutationDeleteComment],
  )
  return {handleOpenDeleteComment}
}
