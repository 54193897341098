import {Route, Routes} from 'react-router-dom'
import {Error404} from './../../common/Error404'
import {AllTopicsPage} from './AllTopicsPage'
import {TopicByWorkspacePage} from './TopicByWorkspacePage'
import {TopicDetailPage} from './TopicDetailPage'
import {TopicEditor} from './TopicEditor'

export const TopicRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<AllTopicsPage />} />
      <Route path="/:workspaceId" element={<TopicByWorkspacePage />} />
      <Route path="/:workspaceId/:topicId" element={<TopicDetailPage />} />
      <Route path="/topic-editor" element={<TopicEditor />} />
      <Route path="/topic-editor/:questionId" element={<TopicEditor />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}
