import React from 'react'
import {Sound, useGlobalContext} from '../../context/GlobalContext'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {HStack, useColorMode} from '@chakra-ui/react'
import {displayNameForAvatar, getAvatar} from '../../commons'
import {Box, Text, Avatar} from '../ui'
import {a} from 'aws-amplify'
import {useNavigate} from 'react-router-dom'
import {ROUTE_MESSAGERIE_CONVERSATIONS} from '../../constantes'

const toastDuration = 5000

export const Toast = () => {
  const {
    isNewChatMessage,
    disableToast,
    openToastNewChatMessage,
    lastChatMessage,
    errorMessage,
    warningMessage,
    error,
    message,
    warning,
    clear,
    toastSates,
    emitSound,
  } = useGlobalContext()

  const navigate = useNavigate()

  const {colorMode} = useColorMode()
  // le colorMode marche pas et je comprends pas pourquoi???
  //console.log('colorMode', colorMode)

  // const [toastLevel, setToastLevel] = React.useState('success')

  const autoCloseNotification = React.useCallback(async (): Promise<void> => {
    setTimeout(function () {
      clear()
    }, toastDuration)
  }, [clear])

  React.useEffect(() => {
    if (error) {
      //  setToastLevel('error')
      toast.error(errorMessage, {
        theme: 'colored',
      })
      autoCloseNotification()
    }
  }, [autoCloseNotification, clear, error, errorMessage])

  React.useEffect(() => {
    if (message) {
      // setToastLevel('info')
      toast.info(message, {
        theme: 'colored',
      })
      autoCloseNotification()
    }
  }, [autoCloseNotification, clear, message, errorMessage])

  React.useEffect(() => {
    if (warning) {
      // setToastLevel('warning')
      toast.warning(warningMessage, {
        theme: 'colored',
      })
      autoCloseNotification()
    }
  }, [
    autoCloseNotification,
    clear,
    error,
    errorMessage,
    message,
    toastSates,
    warning,
    warningMessage,
  ])

  React.useEffect(() => {
    if (message) {
      //toast.info(message)
      autoCloseNotification()
    }
  }, [autoCloseNotification, clear, error, errorMessage, message, toastSates])

  React.useEffect(() => {
    if (openToastNewChatMessage && lastChatMessage && !disableToast) {
      toast.info(
        <a
          href={`${ROUTE_MESSAGERIE_CONVERSATIONS}/${lastChatMessage?.chatRoomMessagesId}`}
          onClick={e => {
            e.preventDefault()
            navigate(
              `${ROUTE_MESSAGERIE_CONVERSATIONS}/${lastChatMessage?.chatRoomMessagesId}`,
            )
          }}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar
              mr={4}
              size={'sm'}
              src={lastChatMessage?.user.image ?? ''}
              name={displayNameForAvatar(lastChatMessage?.user)}
              user={lastChatMessage?.user}
            />
            {/* <img
              src={lastChatMessage?.user.image ?? ''}
              height={30}
              width={30}
              alt="msg"
              style={{marginRight: '20px'}}
            /> */}
            <p>
              Nouveau message de <b>{lastChatMessage?.user.username}</b> :{' '}
              <br />
              <i>{lastChatMessage?.message}</i>
            </p>
          </div>
        </a>,
        {
          theme: 'colored',
          icon: false,
        },
      )

      emitSound(Sound.NOTIFICATION)
      autoCloseNotification()
    }
  }, [
    emitSound,
    autoCloseNotification,
    lastChatMessage,
    navigate,
    openToastNewChatMessage,
  ])

  // const ToastWrapper = styled(ToastContainer)`
  //   ${props => {
  //     //console.log('props', props)
  //     //https://fkhadra.github.io/react-toastify/how-to-style/
  //     return css`
  //       z-index: 99999;

  //       .Toastify__toast-theme--light {
  //         background: grey !important;
  //         color: white !important;
  //         text-align: left !important;
  //       }
  //       .Toastify__toast-theme--colored.Toastify__toast--success {
  //         background: green !important;
  //         color: white !important;
  //         text-align: left !important;
  //       }
  //     `
  //   }}
  // `

  //test pour multi
  // const ToastWrapper2 = styled(ToastContainer)`
  //   ${props => {
  //     //@ts-ignore
  //     //console.log('toastSates?.variant', toastSates)

  //     switch (toastSates.variant) {
  //       case 'success':
  //         return css`
  //           z-index: 99999;

  //           .Toastify__toast-theme--light {
  //             background: green !important;
  //             color: white !important;
  //             text-align: left !important;
  //           }
  //           .Toastify__toast-theme--colored.Toastify__toast--success {
  //             background: green !important;
  //             color: white !important;
  //             text-align: left !important;
  //           }
  //         `
  //       case 'error':
  //         return css`
  //           z-index: 99999;
  //           .Toastify__toast-theme--light {
  //             background: red !important;
  //             color: white !important;
  //             text-align: left !important;
  //           }
  //           .Toastify__toast-theme--colored.Toastify__toast--error {
  //             background: red !important;
  //             color: white !important;
  //             text-align: left !important;
  //           }
  //         `
  //       case 'warning':
  //         return css`
  //           z-index: 99999;
  //           .Toastify__toast-theme--light {
  //             background: orange;
  //             color: white !important;
  //             text-align: left !important;
  //           }
  //           .Toastify__toast-theme--colored.Toastify__toast--warning {
  //             background: orange;
  //             color: white !important;
  //             text-align: left !important;
  //           }
  //         `
  //       case 'info':
  //         return css`
  //           z-index: 99999;
  //           .Toastify__toast-theme--light {
  //             background: blue;
  //             color: white !important;
  //             text-align: left !important;
  //           }
  //           .Toastify__toast-theme--colored.Toastify__toast--info {
  //             background: blue;
  //             color: white !important;
  //             text-align: left !important;
  //           }
  //         `
  //       // default:
  //       //   return css``
  //     }
  //   }}
  // `
  const ToastWrapper =
    colorMode === 'light'
      ? styled(ToastContainer)`
          &.Toastify__toast-container {
            font-weight: medium;
          }
          .Toastify__progress-bar {
            background-color: white;
          }
          .Toastify__toast-theme--colored.Toastify__toast--success {
            background-color: #2f855a;
          }
          .Toastify__toast-theme--colored.Toastify__toast--error {
            background-color: #c53030;
          }
          .Toastify__toast-theme--colored.Toastify__toast--warning {
            background-color: #ea580c;
          }
          .Toastify__toast-theme--colored.Toastify__toast--info {
            background: #2b6cb0;
          }
        `
      : colorMode === 'dark'
      ? styled(ToastContainer)`
          &.Toastify__toast-container {
            font-weight: medium;
          }
          .Toastify__progress-bar {
            background-color: white;
          }
          .Toastify__toast-theme--colored.Toastify__toast--success {
            background-color: #2f855a;
          }
          .Toastify__toast-theme--colored.Toastify__toast--error {
            background-color: #c53030;
          }
          .Toastify__toast-theme--colored.Toastify__toast--warning {
            background-color: #ea580c;
          }
          .Toastify__toast-theme--colored.Toastify__toast--info {
            background: #2b6cb0;
          }
        `
      : styled(ToastContainer)`
          &.Toastify__toast-container {
            font-weight: medium;
          }
          .Toastify__progress-bar {
            background-color: white;
          }
          .Toastify__toast-theme--colored.Toastify__toast--success {
            background-color: #38a169;
          }
          .Toastify__toast-theme--colored.Toastify__toast--error {
            background-color: #e53e3e;
          }
          .Toastify__toast-theme--colored.Toastify__toast--warning {
            background-color: #f97316;
          }
          .Toastify__toast-theme--colored.Toastify__toast--info {
            background: #3182ce;
          }
        `

  return (
    <ToastWrapper
      position="top-right"
      autoClose={toastDuration}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
    />
  )
}
export default Toast
