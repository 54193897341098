import {useMutation, useQuery} from 'react-query'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types'
import {queryClient} from '../../config/react-query'
import {geSchoolById, updateSchoolById} from '../clients'

export const useSchool = (schoolId: string) => {
  const {isLoading, data} = useQuery(['getSchool', {id: schoolId}], () =>
    geSchoolById(schoolId),
  )
  useGlobalLoading(isLoading)
  return {school: data}
}

export const useUpdateSchool = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateSchoolById, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      //queryClient.invalidateQueries('getSchool')
      queryClient.setQueryData(['getSchool', {id: variables?.id}], data)
      fetchEnd()
      setMessage('Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      fetchEnd()
      setGlobalError('Erreur lors de la mise à jour')
    },
    onSettled: (data, error, variables, context) => {},
  })
  return mutation
}
