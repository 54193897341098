import * as React from 'react'
import {ChakraProvider} from '@chakra-ui/react'
import {useAppTheme} from '../context/ThemeContext'

type ThemeAppProviderTypes = {
  children: React.ReactNode
}
const ThemeAppProvider = ({children}: ThemeAppProviderTypes) => {
  const {theme} = useAppTheme()
  // eventuelle surcharge de chakra (lié aus users ou autres)
  // pour le reste, modifier ThemeContext et src/theme
  //console.log('chakra theme', theme)

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}
export {ThemeAppProvider}
