import {Select, SelectionProps} from '../ui'

export const SelectLangue = ({...rest}: SelectionProps) => {
  return (
    <>
      <Select {...rest}>
        <option value="">Selectionner une langue</option>
        <option value="fr">Français</option>
        <option value="es">Espagnol</option>
        <option value="en">Anglais</option>
      </Select>
    </>
  )
}

export default SelectLangue
