import UserFaqConsultationsList from '../../../components/FaqVideo/user-faq-consultations-list'
import {ContainerPage} from '../../../components/ui'

const FaqConsultationListPage = () => {
  return (
    <ContainerPage>
      <UserFaqConsultationsList />
    </ContainerPage>
  )
}
export {FaqConsultationListPage}
