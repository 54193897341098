import {
  // titleRotateColor,
  titleRotateDark,
  titleRotateLight,
} from '../app-constantes'

type Dict = Record<string, any>

const Heading = {
  variants: {
    classik: (props: Dict) => ({
      fontSize: '3xl',
      lineHeight: '9',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      cursor: 'default',
    }),
    rotate: (props: Dict) => ({
      textTransform: 'uppercase',
      writingMode: 'vertical-rl',
      fontWeight: '700',
      fontSize: '4xl',
      lineHeight: '8',
      letterSpacing: 'tight',
      fontFamily: 'Work Sans,system-ui,sans-serif',
      maxWidth: '48px',
      color: props.colorMode === 'light' ? titleRotateLight : titleRotateDark,
      cursor: 'default',
    }),
    title: (props: Dict) => ({
      fontSize: '2xl',
      fontWeight: 'semibold',
      lineHeight: '6',
    }),
    subTitle: (props: Dict) => ({
      fontSize: 'lg',
      fontWeight: 'light',
      color: 'gray.500',
      cursor: 'default',
    }),
    megaTitle: (props: Dict) => ({
      fontSize: '4xl',
      fontWeight: 'black',
      textTransform: 'uppercase',
      letterSpacing: 'tight',
    }),
    question: (props: Dict) => ({
      fontSize: 'xl',
      fontWeight: 'bold',
    }),
    subMenuTitle: (props: Dict) => ({
      fontSize: 'xl',
      fontWeight: 'black',
      textTransform: 'uppercase',
      letterSpacing: 'tight',
      color: 'gray.500',
    }),
    worspaceTitle: (props: Dict) => ({
      fontSize: '2xl',
      fontWeight: 'black',
      lineHeight: '8',
    }),
    classikSmall: (props: Dict) => ({
      fontSize: 'xl',
      lineHeight: '9',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      cursor: 'default',
    }),
  },
  defaultProps: {
    variant: 'classik',
  },
}

export default Heading
