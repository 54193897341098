import {extendTheme, withDefaultColorScheme} from '@chakra-ui/react'
import foundations from './foundations'
import styles from './styles'
import {
  Avatar,
  Badge,
  Box,
  Button,
  CloseButton,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Link,
  Menu,
  SimpleGrid,
  Stack,
  Table,
  Tag,
  Text,
  Th,
  Wrap,
  WrapItem,
} from './components'
const direction = 'ltr'

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
  cssVarPrefix: 'chakra',
}

export const theme = {
  direction,
  ...foundations,
  // components,
  components: {
    Avatar,
    Badge,
    Box,
    Button,
    CloseButton,
    Container,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Icon,
    Image,
    Link,
    Menu,
    SimpleGrid,
    Stack,
    Table,
    Tag,
    Text,
    Th,
    Wrap,
    WrapItem,
    // Other components go here
  },
  styles,
  ...config,
}
//export default theme
//Todo a revoir
export default extendTheme(
  theme,
  withDefaultColorScheme({
    colorScheme: 'blue',
    components: ['Button', 'Badge', 'Avatar', 'Image'],
  }),
  withDefaultColorScheme({
    colorScheme: 'red',
    components: ['Alert'],
  }),

  // withDefaultProps({
  //   defaultProps: theme,
  // }),
)
