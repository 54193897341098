import {CreateConsultationSteps} from '../../../types/screen'
import {StepAnswerConsultation} from './StepAnswerConsultation'
import {StepCreateConsultation} from './StepCreateConsultation'
import {StepPublishConsultation} from './StepPublishConsultation'
import {StepSelectQuestionConsultation} from './StepSelectQuestionConsultation'

type CreateConsultationProps = {
  step: CreateConsultationSteps
  consultationId?: string
}

export const CreateConsultation = ({
  step = CreateConsultationSteps.create,
  consultationId,
}: CreateConsultationProps) => {
  return (
    <>
      {step === CreateConsultationSteps.create && (
        <StepCreateConsultation consultationId={consultationId} />
      )}
      {step === CreateConsultationSteps.select_questions && (
        <StepSelectQuestionConsultation />
      )}
      {step === CreateConsultationSteps.answer && <StepAnswerConsultation />}
      {step === CreateConsultationSteps.publish && <StepPublishConsultation />}
    </>
  )
}
