import {
  Table as ChakraTable,
  TableProps,
  Thead as ChakraThead,
  Tbody as ChakraTbody,
  Tfoot as ChakraTfoot,
  Tr as ChakraTr,
  Th as ChakraTh,
  Td as ChakraTd,
  TableCaption as ChakraTableCaption,
  TableCellProps,
  useColorModeValue,
} from '@chakra-ui/react'

interface TdProps extends TableCellProps {
  util?: string //prop util optionnel (?) de type syting
  _position?: string //prop util optionnel (?) de type syting
  children?: any
}

export const Table = ({...props}: TableProps) => {
  return <ChakraTable {...props}></ChakraTable>
}

export const Thead = ({...props}: any) => {
  return <ChakraThead {...props}></ChakraThead>
}

export const Tbody = ({...props}: any) => {
  return <ChakraTbody {...props}></ChakraTbody>
}

export const Tfoot = ({...props}: any) => {
  return <ChakraTfoot {...props}></ChakraTfoot>
}

export const Tr = ({...props}: any) => {
  return <ChakraTr {...props}></ChakraTr>
}

export const Th = ({...props}: any) => {
  return <ChakraTh {...props}></ChakraTh>
}

export const Td = ({util, _position, children, ...props}: TdProps) => {
  const color = useColorModeValue('gray.500', 'gray.300')
  const borderColor = useColorModeValue('gray.100', 'gray.600')
  if (util === 'user' && _position === 'select') {
    return (
      <ChakraTd borderColor={borderColor} w="24px" {...props}>
        {children}
      </ChakraTd>
    )
  }
  if (util === 'user' && _position === 'avatar') {
    return (
      <ChakraTd
        w="40px"
        px={1}
        // display={{base: 'none', md: 'table-cell'}}
        borderColor={borderColor}
        {...props}
      >
        {children}
      </ChakraTd>
    )
  }
  if (util === 'user' && _position === 'name') {
    return (
      <ChakraTd
        fontWeight="semibold"
        borderColor={borderColor}
        px={1}
        {...props}
      >
        {children}
      </ChakraTd>
    )
  }
  if (util === 'user' && _position === 'domaine') {
    return (
      <ChakraTd
        color={color}
        fontWeight="light"
        borderColor={borderColor}
        // display={{base: 'none', md: 'table-cell'}}
        {...props}
      >
        {children}
      </ChakraTd>
    )
  }
  if (util === 'user' && _position === 'actions') {
    return (
      <ChakraTd borderColor={borderColor} {...props}>
        {children}
      </ChakraTd>
    )
  }
  return (
    <ChakraTd borderColor={borderColor} {...props}>
      {children}
    </ChakraTd>
  )
}

export const TableCaption = ({...props}: any) => {
  return <ChakraTableCaption {...props}></ChakraTableCaption>
}

export default Table
