import React from 'react'
import {useDisclosure} from '@chakra-ui/react'
import {
  useCreateConsultation,
  useUpdateConsultation,
} from '../commons/api/hooks'
import {
  CreateFAQConsultationInput,
  FAQConsultation,
  UpdateFAQConsultationInput,
} from '../commons/types/API'
/**
 * Hook permettant de gerer le Save Question d'un formulaire
 * @param param0
 * @returns
 */
export const useSaveConsultation = ({onSuccess, isEdit}: any) => {
  const mutationCreate = useCreateConsultation({
    onSuccess: onSuccess,
  })
  const mutationUpdate = useUpdateConsultation({
    onSuccess: onSuccess,
  })

  const handleSaveConsultation = (
    values: UpdateFAQConsultationInput & CreateFAQConsultationInput,
  ) => {
    if (isEdit) {
      mutationUpdate.mutate(values)
    } else {
      mutationCreate.mutate(values)
    }
  }
  return {
    handleSaveConsultation,
    isLoading: mutationCreate.isLoading || mutationUpdate.isLoading,
  }
}

/**
 * Custom hook to manage popup Add or Update Question
 * @param questions
 * @returns
 */
export const useOpenConsultation = ({
  consultations,
}: {
  consultations?: {items: [FAQConsultation]}
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const [selectedConsultation, setSelectedConsultation] =
    React.useState<FAQConsultation>()

  const handleOpenNewConsultation = React.useCallback(() => {
    setSelectedConsultation(undefined)
    setIsEdit(false)
    onOpen()
  }, [onOpen])

  const handleOpenUpdateConsultation = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      //console.log('value', value)

      const element = consultations?.items?.find(elm => elm.id === value)
      setSelectedConsultation(element)
      setIsEdit(true)
      onOpen()
    },
    [consultations, onOpen],
  )

  return {
    isEdit,
    selectedConsultation,
    isOpen,
    onOpen,
    onClose,
    handleOpenNewConsultation,
    handleOpenUpdateConsultation,
  }
}
