import {useParams} from 'react-router-dom'
import UserTopicEditor from '../../../components/Topics/userTopicEditor'
import {ContainerPage} from '../../../components/ui'

const TopicEditor = () => {
  const {questionId} = useParams()
  return (
    <ContainerPage>
      <UserTopicEditor topicId={questionId} />
    </ContainerPage>
  )
}
export {TopicEditor}
