import {Center, Spinner} from '@chakra-ui/react'

const Loading = () => {
  return (
    <Center height="full">
      <Spinner size="xl" />
    </Center>
  )
}
export default Loading
