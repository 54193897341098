import {Auth} from 'aws-amplify'
import * as React from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {
  confirmSignUp as AuthConfirmSignUp,
  getMe,
  signIn,
  signUp,
} from '../commons/api/clients'
import {AuthUser, Eroles, IUser, SignupType, UserStatus} from '../types/auth'
import {useGlobalContext} from './GlobalContext'
import {
  Topic,
  Comment,
  Message,
  Question,
  FAQConsultation,
  ChatRoom,
} from '../commons/types/API'
import {
  canDeleteConsult,
  canDeleteMyQuestions,
  canReadChatRoom,
  canRemoveUserFromGroup,
  canUpdateComment,
  canUpdateMessage,
  canUpdateMyQuestions,
  canUpdateQuestion,
  canUpdateTopic,
} from '../commons'
import {useNavigate} from 'react-router-dom'
import {RegisterFormValues} from '../components/Forms/RegisterForm'

type RegisterType = {
  registerData: RegisterFormValues
  signupType: SignupType
  schoolName?: string
  history?: any
  isCreationBackOffice?: boolean
}
export type AuthContextStore = {
  authUser: AuthUser
  schoolId: string
  login: ({data, onError}: {data: IUser; onError: Function}) => void
  register: ({data, onError}: {data: RegisterType; onError: Function}) => void
  confirmSignUp: ({data, onError}: {data: any; onError: Function}) => void
  logout: () => void
  loading: boolean
  error: any
}
const AuthContext = React.createContext<AuthContextStore>({
  authUser: {authUser: '', roles: [Eroles.USER]},
  schoolId: '',
  login: () => {}, // To be implemented in provider
  register: () => {}, // To be implemented in provider
  confirmSignUp: () => {}, // To be implemented in provider
  logout: () => {}, // To be implemented in provider
  loading: false,
  error: null,
})

const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth() s'utilise avec <AuthContext.provider>")
  }
  return context
}

const useUserDetailSchoolGroupId = () => {
  const {schoolId} = useAuth()
  return `school-${schoolId}`
}

const useAuthUserId = () => {
  const {authUser} = useAuth()
  return authUser?.me?.cognitoId
}

const useAuthDetails = () => {
  const {authUser, schoolId} = useAuth()
  const roles = authUser.roles
  const uid = useAuthUserId()
  const schoolGroupId = useUserDetailSchoolGroupId()
  return {uid, schoolGroupId, schoolId, roles, authUser}
}

async function currentAuthenticatedUser() {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_MSW === 'true'
  ) {
    //return currentAuthenticated
  }
  let user: any = null
  try {
    const authUser = await Auth.currentAuthenticatedUser()
    //console.log('currentAuthenticatedUser', authUser)
    if (authUser) {
      const userDetail: any = await getMe()
      //console.log('userDetail me', userDetail)

      if (userDetail === null) {
        throw new Error('Aucun utilisateur dans Appsync (Me)')
      }
      if (userDetail.status !== UserStatus.CREATED) {
        throw new Error('Utilisateur désactivé')
      }
      user = {
        authUser,
        me: userDetail,
        roles:
          authUser?.signInUserSession?.accessToken?.payload['cognito:groups'],
        username: authUser?.attributes?.email,
      }
    }
  } catch (error) {
    // user not authenticated
    console.warn('user not authenticated', error)
  }
  //console.log('currentAuthenticatedUser', user)

  return user
}

const AuthProvider = (props: React.PropsWithChildren<{}>) => {
  const queryClient = useQueryClient()
  const [authUser, setAuthUser] = React.useState<any | null>()
  const [schoolId, setSchoolId] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(!authUser)
  const [error, setError] = React.useState<string | null>(null)
  const {isFetching, data} = useQuery('currentAuthenticatedUser', () =>
    currentAuthenticatedUser(),
  )
  const {setError: setGlobalError, setMessage} = useGlobalContext()

  React.useEffect(() => {
    setAuthUser(data)
    //@ts-ignore
    setSchoolId(data?.me?.userSchoolId)
  }, [data])

  React.useEffect(() => {
    setLoading(isFetching)
  }, [isFetching])

  const catchError = React.useCallback(
    (error: Error) => {
      //console.log('catchError', setGlobalError)

      setLoading(false)
      setError(error.message)
      setGlobalError(error.message)
    },
    [setGlobalError],
  )

  const login = React.useCallback(
    ({data, onError}) => {
      setError(null)
      //setGlobalError()
      setLoading(true)
      signIn(data)
        .then(async authUser => {
          //console.log('authUser', authUser)

          const userDetail: any = await getMe()
          // if (!userDetail) {
          //   return new Error('me undefined')
          // }
          if (userDetail === null) {
            throw new Error('Aucun utilisateur dans Appsync (Me)')
          }
          if (userDetail.status !== UserStatus.CREATED) {
            throw new Error('Utilisateur désactivé')
          }
          setLoading(false)
          setSchoolId(userDetail.userSchoolId)
          setAuthUser({
            authUser,
            me: userDetail,
            username: data.username,
            roles:
              authUser?.signInUserSession?.accessToken?.payload[
                'cognito:groups'
              ],
          })
        })
        .catch(error => {
          //console.log('login error', error)
          if (error.errors) {
            catchError({message: error.errors[0]?.message, name: 'error API'})
            onError(error)
          } else {
            catchError(error)
            onError(error)
          }
        })
    },
    [catchError],
  )

  const register = React.useCallback(
    ({data, onError}) => {
      //console.log('register', data)
      setLoading(true)
      const signupType: SignupType = data.signupType
      const attributes: any = {
        name: data.registerData.name,
        // eslint-disable-next-line no-useless-computed-key
        ['custom:signUpType']: signupType,
      }
      if (signupType === SignupType.STUDIENT) {
        attributes['custom:schoolId'] = data.registerData.schoolId
        attributes['custom:courses'] = data.registerData.courses
      }
      if (signupType === SignupType.OWNER) {
        attributes['custom:schoolName'] = data.schoolName
      }
      //console.log('attributes', attributes)

      signUp({
        username: data.registerData.username,
        password: data.registerData.password,
        attributes,
      })
        .then(result => {
          setLoading(false)
          setMessage('Utilsateur créé')
          if (result) {
            const navigate = data?.history
            if (navigate) {
              navigate('/confirm-signup', {
                state: {email: data.registerData.username},
              })
            }
            if (data.isCreationBackOffice === true) {
              //to avoid error when user is created from backoffice
              logout()
            }
          }
        })
        .catch(error => {
          catchError(error)
          onError(error)
        })
    },
    [catchError, setMessage],
  )

  const logout = React.useCallback(() => {
    console.log('logout')

    Auth.signOut().then(() => {
      setAuthUser(null)
      queryClient.invalidateQueries()
      //navigate('/login', {state: {from: '/'}, replace: true})
    })
  }, [queryClient])

  const confirmSignUp = React.useCallback(
    ({data, onError}) => {
      setLoading(true)
      AuthConfirmSignUp(data.username, data.pin, {
        forceAliasCreation: false,
      })
        .then(result => {
          setLoading(false)
          // setMessage('Utilsateur créé')
          if (result) {
            const navigate = data.navigate
            navigate(`/login?email=${data.username}`)
          }
        })
        .catch(error => {
          catchError(error)
          onError(error)
        })
    },
    [catchError],
  )

  const value = {
    authUser,
    schoolId,
    login,
    register,
    confirmSignUp,
    logout,
    loading,
    error,
  }

  // if (isFetching) {
  //   return <Loading />
  // }

  return <AuthContext.Provider value={value} {...props} />
}
//permission

const useCanUpdateTopic = (topic: Topic) => {
  const {authUser} = useAuth()
  return canUpdateTopic(authUser, topic)
}

const useCanUpdateComment = (comment: Comment) => {
  const {authUser} = useAuth()
  return canUpdateComment(authUser, comment)
}

const useCanUpdateMessage = (message: Message) => {
  const {authUser} = useAuth()
  return canUpdateMessage(authUser, message)
}

const useCanUpdateQuestion = (message: Question) => {
  const {authUser} = useAuth()
  return canUpdateQuestion(authUser, message)
}

const useCanUpdateMyQuestions = (question: Question) => {
  const {authUser} = useAuth()
  return canUpdateMyQuestions(authUser, question)
}

const useCanDeleteMyQuestions = (question: Question) => {
  const {authUser} = useAuth()
  return canDeleteMyQuestions(authUser, question)
}

const useCanDeleteConsultation = (consultation: FAQConsultation) => {
  const {authUser} = useAuth()
  return canDeleteConsult(authUser, consultation)
}
const useCanReadChatRoom = (chatRoom: ChatRoom) => {
  const {authUser} = useAuth()
  return canReadChatRoom(authUser, chatRoom)
}
const useCanRemoveUserFromGroup = () => {
  const {authUser} = useAuth()
  return canRemoveUserFromGroup(authUser)
}

export {
  AuthContext,
  useAuth,
  useAuthDetails,
  AuthProvider,
  useAuthUserId,
  useCanUpdateTopic,
  useCanUpdateComment,
  useUserDetailSchoolGroupId,
  useCanUpdateMessage,
  useCanUpdateQuestion,
  useCanUpdateMyQuestions,
  useCanDeleteMyQuestions,
  useCanDeleteConsultation,
  useCanReadChatRoom,
  useCanRemoveUserFromGroup,
}
