import {useMutation, useQuery} from 'react-query'
import {useUserDetailSchoolGroupId} from '../../../context/AuthContext'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types'
import {queryClient} from '../../config/react-query'
import {
  ListCoursesQueryVariables,
  ListUserCoursesQueryVariables,
} from '../../types/API'
import {
  geCourseById,
  getCoursesByScoolId,
  updateCourseById,
  createCourse,
  deleteCourse,
  getCourses,
  getUserCourses,
} from '../clients'

export const useCoursesBySchoolId_ = (schoolId: string) => {
  const {isLoading, data} = useQuery(
    ['getCoursesBySchoolIdold', {id: schoolId}],
    () => getCoursesByScoolId(schoolId),
  )
  useGlobalLoading(isLoading)
  return {courses: data}
}

export const useCoursesBySchoolId = (input?: ListCoursesQueryVariables) => {
  // //console.log('useCoursesBySchoolId input', input)
  const schoolGroupId = useUserDetailSchoolGroupId()
  // input.filter = ''
  const {isLoading, data} = useQuery(
    ['getCoursesBySchoolId', input?.filter, input?.limit, input?.nextToken],
    () =>
      //toDo change for getCoursesByScoolId
      getCourses({
        filter: {
          ...input?.filter,
          groupsCanAccess: {
            contains: `${schoolGroupId}`,
          },
        },
        limit: input?.limit,
        nextToken: input?.nextToken,
        // ...input,
        // filter: {
        //   ...input?.filter,
        //   groupsCanAccess: {
        //     contains: `${schoolGroupId}`,
        //   },
        // },

        // limit: input?.limit,
        // nextToken: input?.nextToken,
      }),
  )
  useGlobalLoading(isLoading)
  return {courses: data, isLoading}
}
//getCoursesByScoolId
export const useCoursesBySchoolIdtemps = (
  input?: ListCoursesQueryVariables,
) => {
  // //console.log('useCoursesBySchoolId input', input)
  const schoolGroupId = useUserDetailSchoolGroupId()
  // input.filter = ''
  const {isLoading, data} = useQuery(
    ['getCoursesBySchoolId', input?.filter, input?.limit, input?.nextToken],
    () =>
      //toDo change for getCoursesByScoolId
      getCourses({
        filter: {
          ...input?.filter,
          groupsCanAccess: {
            contains: `${schoolGroupId}`,
          },
        },
        limit: input?.limit,
        nextToken: input?.nextToken,
        // ...input,
        // filter: {
        //   ...input?.filter,
        //   groupsCanAccess: {
        //     contains: `${schoolGroupId}`,
        //   },
        // },

        // limit: input?.limit,
        // nextToken: input?.nextToken,
      }),
  )
  useGlobalLoading(isLoading)
  return {courses: data, isLoading}
}

export const useCourseById = (id: string) => {
  const {isLoading, data} = useQuery(['getCoursesById', {id}], () =>
    geCourseById(id),
  )
  useGlobalLoading(isLoading)
  return {course: data}
}

export const useUpdateCourse = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateCourseById, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: (data, variables) => {
      //Invalidation getCoursesBySchoolId because canont update all courses cache
      queryClient.invalidateQueries([
        'getCoursesBySchoolId',
        //  {id: variables?.courseSchoolId},
      ])
      queryClient.invalidateQueries(['getCoursesById', {id: variables?.id}])

      // Two  options :
      // 1. invalidateQueries getCoursesById
      //    queryClient.invalidateQueries(['getCoursesById', {id: variables?.id}])
      // 2. Update cache Data for current course
      queryClient.setQueryData(['getCoursesById', {id: variables?.id}], data)

      fetchEnd()
      setMessage('Formation Mise à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useCreateCourse = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createCourse, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getCoursesBySchoolId')
      fetchEnd()
      setMessage('Formation créée')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteCourse = () => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteCourse, {
    onMutate: variables => {
      fetchStart()
    },
    // onMutate
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(`getCoursesBySchoolId`)
      fetchEnd()
      setMessage('Formation supprimée')
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation getSchool', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
    },
  })
  return mutation
}

//userCourse
export const useUserCourses = (input?: ListUserCoursesQueryVariables) => {
  const {isLoading, data} = useQuery(
    [`getUserCourses`, input?.limit, input?.filter, input?.nextToken],
    () => getUserCourses(input),
  )
  useGlobalLoading(isLoading)
  return {userCourses: data, isLoading}
}
