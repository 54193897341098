import {GraphQLResult} from '@aws-amplify/api-graphql'
import {API, graphqlOperation} from 'aws-amplify'
import {
  getTopic,
  topicsByWorkspace as getTopicsByWorkspaceIdQuerie,
  topicsBySchool,
  topicsByWorkspace,
} from '../../graphql/queries'
import {
  createMessage as createMessageMutation,
  updateTopic,
  deleteTopic,
  createTopic as createTopicMutation,
  deleteMessage,
  updateMessage,
} from '../../graphql/mutations'
import {
  CreateMessageInput,
  CreateTopicInput,
  Message,
  Topic,
  TopicsBySchoolQueryVariables,
  TopicsByWorkspaceQueryVariables,
  UpdateMessageInput,
  UpdateWorkspaceInput,
  WorkspacesBySchoolQueryVariables,
} from '../../types/API'
import {
  getTopicCustom,
  topicsBySchoolCustom,
  topicsByWorkspaceCustom,
} from '../../graphql/custom/queries'

interface GraphQLTopicCommonResult {
  getTopicsByWorkspaceId?: [Topic]
  topicsBySchool?: {items: [Topic]; nextToken: any}
  topicsByWorkspace?: {items: [Topic]; nextToken: any}
  getTopic?: Topic
  updateUser?: Topic
  createUser?: Topic
  deleteUser?: Topic

  deleteTopic?: Topic
  createTopic?: Topic
  createMessage?: Message
  deleteMessage?: Message
  updateMessage?: Message
}

export const getTopicId = async (id: string | undefined) => {
  const custom = true
  const data = await (API.graphql(
    graphqlOperation(custom ? getTopicCustom : getTopic, {id: id}),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)
  return data?.data?.getTopic
}

/**
 * @deprecated prefere use of getTopicsByWorkspaceId
 * @param workspaceId
 * @returns
 */
export const getTopicByWorkspaceId = async (workspaceId: string) => {
  const data = await (API.graphql(
    graphqlOperation(getTopicsByWorkspaceIdQuerie, {workspaceId}),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)
  return data?.data?.getTopicsByWorkspaceId
}

export const getTopicBySchoolId = async (
  input: TopicsBySchoolQueryVariables,
  custom: boolean,
) => {
  const data = await (API.graphql(
    graphqlOperation(custom ? topicsBySchoolCustom : topicsBySchool, input),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)
  return data?.data?.topicsBySchool
}

export const getTopicsByWorkspaceId = async ({
  input,
  custom,
}: {
  input: TopicsByWorkspaceQueryVariables
  custom: boolean
}) => {
  const data = await (API.graphql(
    graphqlOperation(
      custom ? topicsByWorkspaceCustom : topicsByWorkspace,
      input,
    ),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)
  return data?.data?.topicsByWorkspace
}

export const updateTopicById = async (topic?: UpdateWorkspaceInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateTopic, {input: topic}),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)

  return data?.data?.updateUser
}

export const deleteTopicById = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteTopic, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)

  return data?.data?.deleteTopic
}

export const createTopic = async (topic?: CreateTopicInput) => {
  delete topic?.id
  const data = await (API.graphql(
    graphqlOperation(createTopicMutation, {
      input: topic,
    }),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)

  return data?.data?.createTopic
}

export const createMessage = async (message?: CreateMessageInput) => {
  const data = await (API.graphql(
    graphqlOperation(createMessageMutation, {
      input: message,
    }),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)

  return data?.data?.createMessage
}
export const deleteMessageById = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteMessage, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)

  return data?.data?.deleteMessage
}
export const updateMessageById = async (message?: UpdateMessageInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateMessage, {input: message}),
  ) as Promise<GraphQLResult<GraphQLTopicCommonResult>>)

  return data?.data?.updateUser
}
