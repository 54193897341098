import {Box, Container, Flex, Heading} from './../ui'

type CardProps = {
  title?: string
  isTitleOnMobile?: boolean
  data: any
  data2?: any
  bgImage?: string
  bgSize?: string
  h?: string
}
export const Card = ({
  title,
  isTitleOnMobile = true,
  data,
  data2,
  bgImage,
  h,
}: CardProps) => {
  if (title) {
    // isTitleOnMobile ?  display={{base: 'flex', md: 'none'}} : null
    return (
      <Container variant="cardSection" maxW="full" p={4}>
        {isTitleOnMobile ? (
          <>
            <Flex justifyContent={'space-between'} w="full">
              <Box pr={2} w="full">
                {data}
              </Box>
              <Heading pl={2} variant="rotate">
                {title}
              </Heading>
            </Flex>
            <Box mt={2} w="full">
              {data2}
            </Box>
          </>
        ) : (
          <>
            <Flex
              justifyContent={'space-between'}
              w="full"
              display={{base: 'none', md: 'flex'}}
            >
              <Box pr={2} w="full">
                {data}
              </Box>
              <Heading pl={2} variant="rotate">
                {title}
              </Heading>
            </Flex>
            <Box mt={2} w="full">
              {data2}
            </Box>
          </>
        )}
      </Container>
    )
  } else {
    if (bgImage) {
      return (
        <Container
          variant="bgComplet"
          bgImage={bgImage}
          bgSize="cover"
          bgPosition="center"
          maxW="full"
          h={h}
          position="relative"
        >
          {data}
        </Container>
      )
    } else {
      return (
        <Container variant="cardSection" maxW="full">
          {data}
        </Container>
      )
    }
  }
}

export default Card
