import React, {useState} from 'react'
import {
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Stack,
  Tag,
  Text,
} from '../ui'
import ReactTable from '../ui/ReactTable'
import {
  FAQConsultation,
  ModelQuestionConnection,
  ModelSortDirection,
  Question,
} from '../../commons/types/API'
import {HStack, useBreakpoint} from '@chakra-ui/react'
import {dateFormat_ddMMyyyyHHmm} from '../../commons/constantes'
import {useFAQCategories} from '../../commons/api/hooks'
import {EStatus} from '../../types/graphQL'
import {format} from 'date-fns'
import {fr} from 'date-fns/locale'
import {Link as DomRouterLink, useNavigate} from 'react-router-dom'
import Pagination from '../Table/Pagination'
import TagStatus from '../ui/TagStatus'
import {
  ROUTE_SCHOOL_CONSULTATIONS,
  ROUTE_SCHOOL_FAQ_QUESTION,
  ROUTE_SCHOOL_FAQ_QUESTION_EDITOR,
  ROUTE_SCHOOL_USER,
} from '../../constantes'
import {CardVideo} from './CardVideo'
import HeaderTable from '../Table/HeaderTable'
import {View} from '../../types/screen'
import ListQuestionResultsSkeleton from '../Skeletons/ListQuestionResultsSkeleton'
import {questionTitleOrReword} from '../../commons/helpers/screen'
import {markdownToText} from '../../commons/helpers/dataHelper'
import {AlertInfo} from '../ui/Alert'

type FAQesponsesTableProps = {
  view?: string | null
  setSearchParams: Function
  questions?: ModelQuestionConnection
  next: () => void
  prev: () => void
  nextToken: string | null | undefined
  hasNext: boolean
  hasPrev: boolean
  reset: Function
  setSortDirection: Function
  setCategoryId: (value: string) => void
  categoryId?: string
  setSearchTerm: (value: string) => void
  currentConsultation?: FAQConsultation
  isLoading?: boolean
}
export const FAQResponsesTable = ({
  view: viewParam,
  setSearchParams,
  questions,
  next,
  prev,
  nextToken,
  hasNext,
  hasPrev,
  reset,
  setSortDirection,
  setCategoryId,
  categoryId,
  setSearchTerm,
  currentConsultation,
  isLoading,
}: FAQesponsesTableProps) => {
  const [buttonSort, setButtonSort] = useState(false)
  const [hiddenCol, setHiddenCol] = React.useState([''])
  const {FAQCategories} = useFAQCategories()
  const [view, setView] = React.useState(View.list)

  // lors du clic sur le boutton du header, switch la vue et change url param
  const handleSwitchView = (value: View) => {
    setSearchParams({view: value})
  }
  const handleSort = (column: any) => {
    if (column.id === 'createdAt') {
      setButtonSort(!buttonSort)
    }
  }

  // si url param change (view) on switch de vue
  React.useEffect(() => {
    if (viewParam === View.grid || viewParam === View.list) {
      setView(viewParam)
    }
  }, [viewParam])

  const navigate = useNavigate()

  React.useEffect(() => {
    if (buttonSort) {
      setSortDirection(ModelSortDirection.ASC)
    } else {
      setSortDirection(ModelSortDirection.DESC)
    }
  }, [buttonSort, setSortDirection])

  const datas = React.useMemo(
    () => [...((questions?.items as Array<Question>) ?? [])],
    [questions?.items],
  )

  const breakpoint = useBreakpoint()

  React.useEffect(() => {
    const contain = ['base', 'sm'].includes(breakpoint as string)
    contain === true
      ? setHiddenCol([
          'faqConsultation.title',
          'category.name',
          'consultation.name',
          'createdAt',
        ])
      : setHiddenCol([])
  }, [breakpoint])

  const rowProps = (row: any) => ({})

  const columns = React.useMemo(
    () => [
      {
        Header: 'Question',
        accessor: 'title',
        Cell: ({row}: any) => {
          //console.log('row', row)
          const questionTitle = questionTitleOrReword(row.original)
          return (
            <Box>
              <Link
                as={DomRouterLink}
                to={`${ROUTE_SCHOOL_FAQ_QUESTION}/${row.original.id}?view=list`}
              >
                <Stack>
                  <Text
                    sx={{
                      '&::first-letter': {
                        textTransform: 'uppercase',
                      },
                    }}
                    variant="label"
                    cursor="pointer"
                  >
                    {questionTitle}
                  </Text>

                  <Text
                    variant={'contentText'}
                    noOfLines={1}
                    textTransform="lowercase"
                    maxW={{
                      base: 'calc(100vw - 100px)',
                      sm: 'calc(80vw - 80px)',
                      md: '500px',
                    }}
                  >
                    {markdownToText(row.original.description)}
                  </Text>
                </Stack>
              </Link>
            </Box>
          )
        },
      },
      {
        Header: '',
        accessor: 'username',
        Cell: ({row}: any) => {
          //console.log('row', row)
          return (
            <Flex align="center">
              <Link
                as={DomRouterLink}
                to={`${ROUTE_SCHOOL_USER}/${row.original.author?.id}`}
              >
                <Box>
                  <Avatar
                    user={row.original.author}
                    src={row.original.author?.image}
                    name={row.original.author?.username}
                    mr={6}
                    display={{base: 'none', sm: 'flex'}}
                    variant={'userNoBoxBg'}
                    size="sm"
                  />
                </Box>
                {row.values.username}
              </Link>
            </Flex>
          )
        },
      },
      {
        Header: 'Categorie',
        accessor: 'category.name',
        Cell: ({row}: any) => {
          //console.log('row', row)
          return (
            <Flex w="full" justifyContent={'center'}>
              {row.original.category?.name && (
                <Tag variant="consultationTag" mr={1}>
                  {row.original.category?.name}
                </Tag>
              )}
            </Flex>
          )
        },
      },

      {
        Header: 'Consultation',
        accessor: 'consultation.name',
        Cell: ({row}: any) => {
          //console.log('row', row)
          return (
            <Flex w="full" justifyContent={'start'}>
              {row.original.faqConsultation?.title && (
                <Link
                  as={DomRouterLink}
                  to={`${ROUTE_SCHOOL_CONSULTATIONS}/${row.original.faqConsultation?.id}?view=grid`}
                >
                  <Tag mr={1}>{row.original.faqConsultation?.title}</Tag>
                </Link>
              )}
            </Flex>
          )
        },
      },

      {
        Header: 'Créé le ',
        accessor: 'createdAt',
        isSortable: true,
        // sortDescFirst: true,
        Cell: ({row}: any) => {
          //console.log('row', row)
          return (
            <Link
              as={DomRouterLink}
              to={`${ROUTE_SCHOOL_FAQ_QUESTION}/${row.original.id}`}
            >
              <Box>
                <Text variant="dateCardTopic">
                  {format(
                    new Date(row.values.createdAt),
                    dateFormat_ddMMyyyyHHmm,
                    {
                      locale: fr,
                    },
                  )}
                </Text>
              </Box>
            </Link>
          )
        },
      },
    ],
    [],
  )

  const cellProps = (cellInfo: any) => {
    switch (cellInfo.column.id) {
      case 'selection':
        return {
          _position: 'select',
          util: 'user',
        }
      case 'username':
        return {
          _position: 'name',
          util: 'user',
        }
      case 'email':
        return {
          _position: 'domaine',
          util: 'user',
        }
      case 'id':
        return {
          _position: 'actions',
          util: 'user',
        }
    }
  }

  const handleOpenQuestionEditor = () => {
    navigate(ROUTE_SCHOOL_FAQ_QUESTION_EDITOR)
  }
  const hasQuestions = !!questions
  return (
    <>
      <Container variant="generalBg" maxW="full">
        <Stack spacing={4}>
          <HeaderTable
            util="questionsPage"
            onAdd={handleOpenQuestionEditor}
            onSearch={setSearchTerm}
            view={view}
            setView={handleSwitchView}
            buttonCategoryId={categoryId}
            setButtonCategoryId={setCategoryId}
            setButtonSort={setButtonSort}
            buttonSort={buttonSort}
            dataMapButton={FAQCategories}
          />
          {isLoading && <ListQuestionResultsSkeleton />}
          {!isLoading && !hasQuestions && (
            <Flex p={4} alignItems="center" justifyContent="center" w={'100%'}>
              <AlertInfo
                title={'Aucune question trouvée'}
                description={
                  'Personne n’a encore posé de question dans cette catégorie. Soyez le premier à poser une question !'
                }
              ></AlertInfo>
            </Flex>
          )}
          <HStack hidden={!currentConsultation}>
            <Heading fontSize={{base: 'medium', md: '3xl'}}>
              {currentConsultation?.title}
            </Heading>
            <TagStatus
              status={currentConsultation?.status as EStatus}
              minWidth={'inherit'}
            />
          </HStack>
          {!isLoading && hasQuestions && view === View.list ? (
            <>
              <Box>
                <ReactTable
                  rowProps={rowProps}
                  cellProps={cellProps}
                  columns={columns}
                  data={datas}
                  controlledPageCount={0}
                  controlledPageIndex={0}
                  isLoading={false}
                  isFetching={false}
                  onFetchData={function (options: any): void {
                    // console.log('onFetchData')
                  }}
                  setPageIndex={function (pageIndex: number): void {
                    //throw new Error('Function not implemented.')
                  }}
                  setSelectedRowsId={function (selectedRowIds: any): void {
                    // throw new Error('Function not implemented.')
                  }}
                  totalCount={0}
                  labelData={'réponses'}
                  identifier={'id'}
                  controlledHiddenColumns={hiddenCol}
                  initialSortBy={[]}
                  hideHeader={false}
                  handleSort={handleSort}
                />
              </Box>

              <Pagination
                next={next}
                prev={prev}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
            </>
          ) : null}

          {!isLoading && hasQuestions && view === View.grid ? (
            <Box>
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                gap={{base: 5, md: 10}}
                mb={4}
                flexDirection={{base: 'column', md: 'row'}}
              >
                {datas?.length > 0 ? (
                  datas?.map((question, index) => (
                    <CardVideo key={index} question={question} />
                  ))
                ) : (
                  <Flex
                    p={4}
                    alignItems="center"
                    justifyContent="center"
                    w={'100%'}
                    mb={4}
                  >
                    <AlertInfo
                      title={'Aucune question trouvée'}
                      description={
                        'Personne n’a encore posé de question dans cette catégorie. Soyez le premier à poser une question !'
                      }
                    ></AlertInfo>
                  </Flex>
                )}
              </Box>
              <Pagination
                next={next}
                prev={prev}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
            </Box>
          ) : null}
        </Stack>
      </Container>
    </>
  )
}
export default FAQResponsesTable
