import {Hide, useColorMode} from '@chakra-ui/react'
import {getEStatusDetails} from '../../../commons/helpers/screen'
import {
  primaryColor,
  primaryTextColorRevert,
} from '../../../theme/app-constantes'
import {EStatus} from '../../../types/graphQL'
import {Box, Flex, Grid, GridItem, Heading, Tag, Text} from '../../ui'

type HeaderStepProps = {
  title: string
  consultation?: any
  questions?: any
  step?: number
}

export const HeaderStep = ({
  title,
  step,
  consultation,
  questions,
}: HeaderStepProps) => {
  const {colorMode} = useColorMode()
  const secondaryColor = primaryTextColorRevert({colorMode})
  return (
    <>
      <Flex w="full" justifyContent="space-between" pb={8} align={'center'}>
        <Heading variant="title">Créez votre Consultation</Heading>
        <Box>
          {consultation ? (
            <Tag
              bgColor={getEStatusDetails(consultation?.status as EStatus).value}
              color={'white'}
              fontWeight={'semibold'}
              mr={1}
            >
              {consultation?.status}
            </Tag>
          ) : (
            <Tag
              bgColor={getEStatusDetails(EStatus.NEW).value}
              color={'white'}
              fontWeight={'semibold'}
              mr={1}
            >
              EN COURS DE CREATION
            </Tag>
          )}
        </Box>
      </Flex>
      <Grid templateColumns="repeat(13, 1fr)" px={{base: 2, sm: 16}} pb={2}>
        <GridItem colSpan={1}>
          <Flex w="full" justifyContent={'center'}>
            <Box
              px={3}
              py={1}
              border="2px"
              borderColor={primaryColor({colorMode})}
              rounded="full"
              bgColor={primaryColor({colorMode})}
            >
              <Text color={secondaryColor} fontSize={'xl'} fontWeight="bold">
                1
              </Text>
            </Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex align="center" h="full">
            <Box
              w="full"
              h="2px"
              bgColor={
                step && step > 1 ? primaryColor({colorMode}) : 'gray.500'
              }
            ></Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex w="full" justifyContent={'center'}>
            <Box
              px={3}
              py={1.5}
              border="2px"
              borderColor={primaryColor({colorMode})}
              bgColor={
                step && step > 1 ? primaryColor({colorMode}) : 'transparent'
              }
              rounded="full"
            >
              <Text
                color={
                  step && step > 1 ? secondaryColor : primaryColor({colorMode})
                }
                fontSize={'xl'}
                fontWeight="bold"
              >
                2
              </Text>
            </Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex align="center" h="full">
            <Box
              w="full"
              h="2px"
              bgColor={
                step && step > 2 ? primaryColor({colorMode}) : 'gray.500'
              }
            ></Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex w="full" justifyContent={'center'}>
            <Box
              px={3}
              pt={1}
              pb={1.5}
              border="2px"
              borderColor={primaryColor({colorMode})}
              bgColor={
                step && step > 2 ? primaryColor({colorMode}) : 'transparent'
              }
              rounded="full"
            >
              <Text
                style={{fontVariantNumeric: 'normal'}}
                color={
                  step && step > 2 ? secondaryColor : primaryColor({colorMode})
                }
                fontSize={'xl'}
                fontWeight="bold"
              >
                3
              </Text>
            </Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex align="center" h="full">
            <Box
              w="full"
              h="2px"
              bgColor={
                step && step > 3 ? primaryColor({colorMode}) : 'gray.500'
              }
            ></Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex w="full" justifyContent={'center'}>
            <Box
              px={3}
              pt={1}
              pb={1.5}
              border="2px"
              borderColor={primaryColor({colorMode})}
              bgColor={
                step && step > 3 ? primaryColor({colorMode}) : 'transparent'
              }
              rounded="full"
            >
              <Text
                style={{fontVariantNumeric: 'normal'}}
                color={
                  step && step > 3 ? secondaryColor : primaryColor({colorMode})
                }
                fontSize={'xl'}
                fontWeight="bold"
              >
                4
              </Text>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
      <Hide below="sm">
        <Grid templateColumns="repeat(7, 1fr)" px={8} pb={2}>
          <GridItem colSpan={1}>
            <Text
              textAlign={'center'}
              color={primaryColor({colorMode})}
              variant="label"
            >
              Création
            </Text>
          </GridItem>
          <GridItem colSpan={1}></GridItem>
          <GridItem colSpan={1}>
            <Text
              textAlign={'center'}
              color={step && step > 1 ? primaryColor({colorMode}) : 'gray.500'}
              variant="label"
            >
              Questions
            </Text>
          </GridItem>
          <GridItem colSpan={1}></GridItem>
          <GridItem colSpan={1}>
            <Text
              textAlign={'center'}
              color={step && step > 2 ? primaryColor({colorMode}) : 'gray.500'}
              variant="label"
            >
              Réponses
            </Text>
          </GridItem>
          <GridItem colSpan={1}></GridItem>
          <GridItem colSpan={1}>
            <Text
              textAlign={'center'}
              color={step && step > 3 ? primaryColor({colorMode}) : 'gray.500'}
              variant="label"
            >
              Publication
            </Text>
          </GridItem>
        </Grid>
      </Hide>
      {/* <Heading>{title}</Heading> */}
      <Flex w="full" justifyContent={'space-between'} py={4}>
        <Flex>
          <Text mt={1} mr={1}>
            Titre actuel :{' '}
          </Text>
          <Heading variant="question">{consultation?.title ?? 'AUCUN'}</Heading>
        </Flex>
        <Text>
          avec {questions.length ?? '0'} question
          {questions.length >= 2 ? 's' : ''}
        </Text>
      </Flex>
    </>
  )
}
