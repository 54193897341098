import {AvatarGroup, Box, useMediaQuery} from '@chakra-ui/react'
import {User, UserChatRoom} from '../../commons/types/API'
import {Avatar, Flex} from '../ui'
import {displayNameForAvatar} from '../../commons'

export function AvatarMessage({
  user, // l'avatar principal
  contributors, // tous les avatars
  max = 1,
  maxMobile = 1,
}: {
  user?: User
  contributors?: (UserChatRoom | null)[]
  max?: number
  maxMobile?: number
}) {
  //les autre avatars sans le principal
  const contributorsExcludeMain = contributors?.filter(
    u => u?.userId !== user?.id,
  )
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const maxToShow = isMobile ? maxMobile : max

  return (
    <Box w={'25%'} minW={'90px'} justifyContent={'flex-start'}>
      {(contributorsExcludeMain?.length ?? 0) >= 2 ? (
        <AvatarGroup max={maxToShow} size={'md'}>
          {/* avatar principal */}
          <Avatar
            isChakraAvatar={true}
            size={'lg'}
            name={displayNameForAvatar(user)}
            src={user?.image ?? ''}
            user={user as User} //ajoute un decalage : todo a fixé
          />
          {/* avatars autres */}
          {contributorsExcludeMain?.map((b, index) => {
            return (
              <Avatar
                isChakraAvatar={true}
                key={index}
                name={displayNameForAvatar(b?.user as User)}
                src={b?.user?.image ?? ''}
                user={b?.user as User}
              />
            )
          })}
        </AvatarGroup>
      ) : (
        <Avatar
          isChakraAvatar={true}
          size={'lg'}
          name={displayNameForAvatar(user)}
          src={user?.image ?? ''}
          user={user as User}
        />
      )}
    </Box>
  )
}

export const AvatarMessageIcon = ({user}: {user?: User | null}) => {
  return (
    <Flex w={'min-content'} minW={'40px'} justifyContent={'center'}>
      <Avatar
        size={'sm'}
        name={displayNameForAvatar(user as User)}
        src={user?.image ?? ''}
        user={user as User}
      />
    </Flex>
  )
}
