import {DownloadIcon} from '@chakra-ui/icons'
import {useFormik} from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import {UpdateSchoolInput} from '../../commons/types/API'
import {useDropzone} from 'react-dropzone'
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormInput,
  FormTextArea,
  Heading,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
} from '../ui'

import {MAX_REZISE_IMAGE} from '../../commons/constantes'
import {useGlobalContext} from '../../context/GlobalContext'
import {
  ImageObjectType,
  IResizeImageOptions,
  resizeImage,
  uploadFile,
} from '../../commons/helpers/FilesHelper'
import {useUserDetailSchoolGroupId} from '../../context/AuthContext'
import SelectLangue from '../Selects/SelectLangue'
import {Alert, AlertIcon, Progress} from '@chakra-ui/react'

export const gridRight = {
  xs: 12,
  sm: 12,
  md: 8,
  lg: 8,
  xl: 8,
  xxl: 8,
}
export const gridLeft = {
  xs: 12,
  sm: 12,
  md: 4,
  lg: 4,
  xl: 4,
  xxl: 4,
}

type SchoolEditFormProps = {
  initialValues?: UpdateSchoolInput
  onSubmit: (formValue: UpdateSchoolInput) => void
  isLoading?: boolean
}

export const SchoolEditForm = ({
  initialValues,
  onSubmit,
  isLoading,
}: SchoolEditFormProps) => {
  const [uploadingImg, setUploadingImg] = React.useState(false)
  const [mustSaveImage, setMustSaveImage] = React.useState(false)
  const SignInSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(300, 'Maximum 300 caractères')
      .required('Champ obligatoire'),
    emailsupport: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    message: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(300, 'Maximum 300 caractères')
      .required('Champ obligatoire'),
    //image: Yup.string().required('Insérez une image'),
  })

  const {setError} = useGlobalContext()
  const schoolGroupId = useUserDetailSchoolGroupId()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      description: initialValues?.description ?? '',
      domain: initialValues?.domain ?? '',
      emailsupport: initialValues?.emailsupport ?? '',
      lang: initialValues?.lang ?? '',
      message: initialValues?.message ?? '',
      name: initialValues?.name ?? '',
      url: initialValues?.url ?? '',
      welcomeMessage: initialValues?.welcomeMessage ?? '',
      image: initialValues?.image ?? '',
      owner: initialValues?.owner ?? '',
      editors: initialValues?.editors ?? [],
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
    },
    onSubmit: (values: UpdateSchoolInput) => {
      setMustSaveImage(false)
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  const handleChangeImage = () => {
    // TODO A FAIRE
  }
  const handleDeleteImage = () => {
    // TODO A FAIRE
  }
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles: any) => {
      const fileOriginal = acceptedFiles[0]
      setUploadingImg(true)
      const fileToresize: IResizeImageOptions = {
        maxSize: MAX_REZISE_IMAGE,
        file: fileOriginal,
      }
      resizeImage(fileToresize)
        .then(async newFiles => {
          formik.setFieldValue('image', URL.createObjectURL(newFiles.blob))
          await uploadFileAndSubmit(newFiles)
        })
        .catch(err => {
          console.error(err)
          setUploadingImg(false)
          setError(`Erreur durant le transfert de l'image`)
          formik.setFieldValue('image', initialValues?.image)
        })
    },
  })

  const uploadFileAndSubmit = async (file: any) => {
    const image = await uploadFile(
      file,
      ImageObjectType.school,
      initialValues?.id as string,
    )
    formik.setFieldValue('image', image)
    setUploadingImg(false)
    setMustSaveImage(true)
    // formik.handleSubmit()
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={4}>
        <SimpleGrid columns={2} gap={4}>
          <Flex
            justifyContent={{base: 'flex-start', md: 'center'}}
            align="center"
          >
            <Heading>École</Heading>
          </Flex>
          <Flex justifyContent="center" align="center">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              {uploadingImg && <Progress size="xs" isIndeterminate />}
              <Avatar
                variant="prog"
                src={formik.values?.image as string}
                border={formik.values?.image ? '' : '2px solid #FC8181'}
                boxShadow={
                  formik.values?.image ? '' : 'box-shadow: 0 0 0 1px #fc8181;'
                }
              />
              <Box>
                <IconButton
                  onClick={() => handleChangeImage()}
                  variant="solid"
                  aria-label=""
                  size="sm"
                  mt={-12}
                  ml={20}
                  icon={<DownloadIcon />}
                >
                  Change photo
                </IconButton>
                <Text w={'100%'} textAlign="center" color="red">
                  {formik?.errors?.image}
                </Text>
              </Box>
            </div>
          </Flex>
        </SimpleGrid>
        {mustSaveImage && (
          <Alert status="warning">
            <AlertIcon />
            Veuillez sauvegarder
          </Alert>
        )}
        <SimpleGrid columns={{base: 1, md: 2}} gap={4}>
          <Stack spacing={2}>
            <FormInput
              placeholder="name"
              label="Nom de l'école"
              name="name"
              id="name"
              onChange={formik.handleChange}
              value={formik.values?.name}
              errorMessage={formik?.errors?.name as string}
            />
            <FormTextArea
              placeholder="Laissez une courte description de votre école..."
              label="Description de l'école"
              name="description"
              id="description"
              onChange={formik.handleChange}
              value={formik.values?.description as string}
              errorMessage={formik?.errors?.description as string}
            />
            <FormInput
              placeholder="domain"
              label="Domaine"
              name="domain"
              id="domain"
              onChange={formik.handleChange}
              value={formik.values?.domain as string}
              errorMessage={formik?.errors?.domain as string}
            />
            <FormInput
              placeholder="emailsupport"
              type="emailsupport"
              label="Emailsupport"
              name="emailsupport"
              id="emailsupport"
              onChange={formik.handleChange}
              value={formik.values?.emailsupport as string}
              errorMessage={formik?.errors?.emailsupport as string}
            />
          </Stack>
          <Stack spacing={2}>
            <SelectLangue
              label="Selectionner une langue"
              name="lang"
              id="lang"
              onChange={formik.handleChange}
              value={formik.values?.lang as string}
              errorMessage={formik?.errors?.lang as string}
            />
            <FormTextArea
              placeholder="Laissez un message"
              label="Message"
              name="message"
              id="message"
              onChange={formik.handleChange}
              value={formik.values?.message as string}
              errorMessage={formik?.errors?.message as string}
            />
            <FormInput
              label="url"
              name="url"
              id="url"
              onChange={formik.handleChange}
              value={formik.values?.url as string}
              errorMessage={formik?.errors?.url as string}
            />
          </Stack>
        </SimpleGrid>
        <Flex justifyContent="flex-end" w="full" my={4}>
          <Button
            type="submit"
            variant="solid"
            isLoading={isLoading}
            disabled={uploadingImg}
          >
            Mettre à jour
          </Button>
        </Flex>
      </Stack>
    </form>
  )
}
export default SchoolEditForm
