import SidebarLink from './SidebarLink'
import {RoutesConfig} from '../../routes/config'
import {haveRouteRequiredRole} from '../../commons'
import {useAuth} from '../../context/AuthContext'
import React from 'react'
import {Eroles} from '../../types'

type NavProps = {
  menuRoleFilter: Eroles
}

export const Nav = ({menuRoleFilter}: NavProps) => {
  const {authUser} = useAuth()

  const leftMenuAdminConfig = RoutesConfig.find(
    menu => menu.id === 'leftMenuAdmin',
  )?.menu

  const leftMenuStudentConfig = RoutesConfig.find(
    menu => menu.id === 'leftMenuStudent',
  )?.menu

  const menuConfig =
    menuRoleFilter === Eroles.SCHOOLOWNER
      ? leftMenuAdminConfig
      : leftMenuStudentConfig

  return (
    <>
      {/* {LinkItems.map((link, idx) => (
        <SidebarLink key={idx} content={link}></SidebarLink>
      ))} */}
      {menuConfig
        ?.filter(item => haveRouteRequiredRole(item?.roles, authUser))
        .map((navItem, idx) => (
          <React.Fragment key={idx}>
            <SidebarLink key={idx} content={navItem}></SidebarLink>
            {/* {idx !== 0 && <Divider></Divider>}
            {navItem.children && (
              <>
                {navItem.children.map((childMenu, idx) => (
                  <SidebarLink key={idx} content={childMenu}></SidebarLink>
                ))}
              </>
            )} */}
            {/* support children : simple div pour le moment */}
            {/* {navItem.children && (
              <>
                {navItem.children.map((childMenu, idx) => (
                  <SidebarLink
                    key={idx}
                    content={childMenu}
                    isSubItem={true}
                  ></SidebarLink>
                ))}
              </>
            )} */}
          </React.Fragment>
        ))}
    </>
  )
}

export default Nav
