import React from 'react'
import {QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {AuthProvider} from './AuthContext'
import {GlobalContextProvider} from './GlobalContext'
import {ThemeAppProvider} from '../components/ThemeAppProvider'
import {ThemeProvider} from './ThemeContext'
// COMMON
import {queryClient} from '../commons/config/react-query'
//Amplify
import {Amplify} from 'aws-amplify'
import awsconfig from '../aws-exports'
import {Toast} from '../components/Toast'
//AppSync

Amplify.configure(awsconfig)

type AppProvidersProps = {
  children: React.ReactNode
}
const AppProviders = ({children}: AppProvidersProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ThemeAppProvider>
          <AuthProvider>
            <GlobalContextProvider>
              <>
                {children} <Toast />
              </>
            </GlobalContextProvider>
          </AuthProvider>
        </ThemeAppProvider>
      </ThemeProvider>

      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  )
}

export {AppProviders}
