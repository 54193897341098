/**
 * Page de creation ou d'edition d'une question pour un admin
 */
import {Box, Flex, Heading} from '@chakra-ui/react'
import React from 'react'
import {useQuestion} from '../../commons/api/hooks'
import {
  CreateQuestionInput,
  Question,
  UpdateQuestionInput,
} from '../../commons/types/API'
import {useAuthUserId} from '../../context/AuthContext'
import QuestionForm from '../Forms/QuestionForm'
import ModalContainer from 'react-modal-promise'
import {Container, Stack} from '../ui'
import {modalYesNoPromise} from '../Modals/YesNo'
import {useSaveQuestion} from '../../hooks/questionsActions'
import {useNavigate, useParams} from 'react-router-dom'
import {ROUTE_ADMIN_FAQ_QUESTIONS} from '../../constantes'

type AdminFaqProps = {
  consultationId?: string
}

export const AdminFaqMyQuestion = ({consultationId}: AdminFaqProps) => {
  const {questionId} = useParams()
  const {question} = useQuestion(questionId ?? 'empty')
  const uid = useAuthUserId()
  const isEdit = questionId ? true : false

  const navigate = useNavigate()
  const {handleSaveQuestion, isLoading, isSuccess} = useSaveQuestion({
    isEdit,
  })

  React.useEffect(() => {
    if (isSuccess) {
      navigate(`${ROUTE_ADMIN_FAQ_QUESTIONS}`)
    }
  }, [isSuccess, navigate])

  const handleSubmit = (values: UpdateQuestionInput & CreateQuestionInput) => {
    openPopupConfirmSave(values)
  }
  const handleClose = () => {
    navigate(-1)
  }

  const openPopupConfirmSave = React.useCallback(
    (values: UpdateQuestionInput & CreateQuestionInput) => {
      //todo : gerer le libellé : create / update
      modalYesNoPromise({
        title: 'Confirmer',
        confirmLabel: 'Sauvegarder',
        children: (
          <Flex flexDirection={'column'}>
            <Heading>Envoyer la question</Heading>
            <hr />
            <Box>
              Souhaitez-vous vraiment envoyer la question <br />
              <strong>{values.title}</strong> <br />
              Vous ne pourrez plus la modifier par la suite.
            </Box>
          </Flex>
        ),
      })
        .then(ok => {
          handleSaveQuestion(values)
        })
        .catch(error => {
          console.error(error)
        })
    },
    [handleSaveQuestion],
  )

  return (
    <>
      <Container variant="generalBg">
        <Stack spacing={4} mb={5}>
          <Heading>
            {isEdit ? 'Modifier ma question' : 'Poser une question'}
          </Heading>
        </Stack>

        <QuestionForm
          isEdit={isEdit}
          onSubmit={handleSubmit}
          onClose={handleClose}
          initialValues={{
            questionCategoryId: question?.category?.id,
            questionAnswerId: question?.answer?.id,
            tagsData: question?.tags?.items,
            ...(question as Question),
            //this pattern or inside the form ?
            questionAuthorId: (question?.author?.id as string) ?? uid,
            fAQConsultationQuestionsId: question?.faqConsultation?.id,
          }}
          isLoading={isLoading}
          isModeConsultationStep={false}
        />
      </Container>
      <ModalContainer />
    </>
  )
}

export default AdminFaqMyQuestion
