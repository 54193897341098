import {Consultations} from '../../../components/Consultations'
import {ContainerPage} from '../../../components/ui'
import {useParams} from 'react-router-dom'

export const ConsultationQuestionByCategoriePage = () => {
  const {categoryId} = useParams()
  return (
    <ContainerPage>
      <Consultations tabIdx={5} categoryId={categoryId}></Consultations>
    </ContainerPage>
  )
}
