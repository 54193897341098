import {Heading} from '@chakra-ui/react'
import ModalContainer from 'react-modal-promise'
import {Container, Stack} from '../ui'
import {TopicForm} from '../Forms/TopicForm'
import {useSaveTopic} from '../../hooks/topicsActions'
import {useTopic} from '../../commons/api/hooks'
import {useCanUpdateTopic} from '../../context/AuthContext'
import {useNavigate} from 'react-router-dom'
import {ROUTE_SCHOOL_TOPIC} from '../../constantes'
import Restricted from '../../routes/common/Restricted'
import {Topic} from '../../commons/types/API'
import {useEffect} from 'react'
type userTopicProp = {
  topicId?: string
}

const UserTopicEditor = ({topicId}: userTopicProp) => {
  const navigate = useNavigate()
  const isModeWorkspace = !topicId
  const isEdit = topicId ? true : false
  const {topic} = useTopic(topicId ?? 'empty')
  const canUpdateTopic = useCanUpdateTopic(topic as Topic)
  const {handleSaveTopic, isLoading: isLoadingSaveTopic} = useSaveTopic({
    isEdit,
    onSuccess: () => {
      navigate(-1)
    },
  })
  const handleCancelBack = () => {
    navigate(-1)
  }
  // useEffect(() => {
  //   console.log(canUpdateTopic, 'canUpdateTopic')
  // }, [canUpdateTopic])

  if (topic && !canUpdateTopic) {
    return (
      <Restricted message="Vous ne pouvez pas modifier ce sujet"></Restricted>
    )
  }
  return (
    <>
      <Container variant="generalBg">
        <Stack spacing={4} mb={5}>
          <Heading>{isEdit ? 'Modifier le sujet' : 'Nouveau sujet'}</Heading>
        </Stack>
        <TopicForm
          isModeWorkspace={!isModeWorkspace}
          initialValues={{
            ...(topic as Topic),
            workspaceTopicsId: topic?.workspace?.id ?? '',
            userTopicsId: topic?.user?.id,
            description: topic?.description,
            name: topic?.name,
          }}
          onSubmit={handleSaveTopic}
          onClose={handleCancelBack}
          isLoading={isLoadingSaveTopic}
          isEdit={isEdit}
        />
      </Container>
      <ModalContainer />
    </>
  )
}

export default UserTopicEditor
