import React from 'react'
import {useConsultationsBySchool} from '../../commons/api/hooks'
import {PAGINATION_CONSULTATIONS_DISPLAY} from '../../commons/constantes'
import {
  buildFilterBySchool,
  buildFilterSearchQuestion,
} from '../../commons/helpers/graphQLHelper'
import {
  useAuthDetails,
  useUserDetailSchoolGroupId,
} from '../../context/AuthContext'
import {EStatus} from '../../types/graphQL'
import {Box, Stack, Text} from '../ui'
import ConsultationCard from '../Consultations/consultationCard'
import HeaderTable from '../Table/HeaderTable'
import {ROUTE_SCHOOL_FAQ_QUESTION_EDITOR} from '../../constantes'
import {useNavigate} from 'react-router-dom'
import {Pagination} from '../Table/Pagination'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import {ModelSortDirection} from '../../commons/types/API'
import {AlertInfo} from '../ui/Alert'

type FAQesponsesTableProps = {
  questionQueryParam?: string | null
}

export const UserFaqConsultationsList = ({
  questionQueryParam,
}: FAQesponsesTableProps) => {
  const {schoolId} = useAuthDetails()
  const schoolGroupId = useUserDetailSchoolGroupId()
  const [searchTerm, setSearchTerm] = React.useState<string>(
    questionQueryParam ?? '',
  )

  const {
    isLoading: isLoadingConsultations,
    consultations,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = useConsultationsBySchool({
    sortDirection: ModelSortDirection.DESC,
    limit: searchTerm ? 10000 : PAGINATION_CONSULTATIONS_DISPLAY,
    consultationSchoolId: schoolId,
    filter: {
      status: {eq: EStatus.PUBLISHED},
      ...buildFilterSearchQuestion(searchTerm),
      ...buildFilterBySchool(schoolGroupId),
    },
  })

  const hasConsultations =
    !!consultations?.items?.length || hasNextPage || hasPrevPage

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    resetPages()
  }

  React.useEffect(() => {
    setSearchTerm(questionQueryParam ?? '')
  }, [questionQueryParam])

  const navigate = useNavigate()

  const handleOpenQuestionEditor = () => {
    navigate(ROUTE_SCHOOL_FAQ_QUESTION_EDITOR)
  }

  return (
    <Box>
      <Stack>
        <Box mb={4}>
          <HeaderTable
            util="reponses"
            onAdd={handleOpenQuestionEditor}
            onSearch={handleSearch}
          />
        </Box>
        {isLoadingConsultations && <TextRowSkeleton />}
        {!isLoadingConsultations && !hasConsultations && (
          <Box my={2}>
            <AlertInfo
              title={'Aucune consultation trouvée'}
              description={
                'Vous devez attendre que votre coach reponde à des questions des membres de votre école.'
              }
            ></AlertInfo>
          </Box>
        )}
        {!isLoadingConsultations && hasConsultations && (
          <>
            {consultations?.items?.map((consult, idx) => (
              <Box key={idx}>
                <ConsultationCard
                  idx={idx}
                  consultation={consult}
                  withActionButton={false}
                />
              </Box>
            ))}
            <Pagination
              next={nextPage}
              prev={prevPage}
              hasNext={hasNextPage}
              hasPrev={hasPrevPage}
            />
          </>
        )}
      </Stack>
    </Box>
  )
}
export default UserFaqConsultationsList
