import {GraphQLResult} from '@aws-amplify/api-graphql'
import {API, graphqlOperation} from 'aws-amplify'
import {getSchool} from '../../graphql/queries'
import {updateSchool} from '../../graphql/mutations'
import {School, UpdateSchoolInput} from '../../types/API'

interface GraphQLSchoolCommonResult {
  getSchool?: School
  updateUser?: School
  createUser?: School
  updateSchool?: School
}

export const geSchoolById = async (schoolId: string | undefined) => {
  const data = await (API.graphql(
    graphqlOperation(getSchool, {id: schoolId}),
  ) as Promise<GraphQLResult<GraphQLSchoolCommonResult>>)
  return data?.data?.getSchool
}

export const updateSchoolById = async (school?: UpdateSchoolInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateSchool, {input: school}),
  ) as Promise<GraphQLResult<GraphQLSchoolCommonResult>>)

  return data?.data?.updateSchool
}
