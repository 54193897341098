import {SkeletonText} from '@chakra-ui/react'
import {Box} from '../ui'

export const SimpleRowSkeleton = () => {
  return (
    <Box padding="6" boxShadow="lg">
      <SkeletonText mt="4" noOfLines={8} spacing="4" />
    </Box>
  )
}
export default SimpleRowSkeleton
