import {Topic, User} from '../types/API'

export const extractUsersFromTopic = (topic: Topic) => {
  const users = topic.messages?.items.reduce((users, message) => {
    if (!users.find(user => user.id === message?.user.id)) {
      users.push(message?.user as User)
    }
    return users
  }, [] as User[])
  return users
}

export const extractUsersFromTopicExcludeUser = (
  topic: Topic,
  usersExclude: User[],
) => {
  const extractedUsers = extractUsersFromTopic(topic)
  const result = extractedUsers?.filter(user =>
    usersExclude.some(fUser => fUser.id !== user.id),
  )
  return result
}
