import {
  Tag as ChakraTag,
  TagProps,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react'
import {
  primaryColor,
  primaryTextColor,
  primaryTextColorRevert,
} from '../../theme/app-constantes'

// type TagExtendProps = TagProps & {
//   invert?: boolean
// }

export const Tag = ({...props}: TagProps) => {
  const {colorMode} = useColorMode()
  let bgColor
  let textColor
  let rounded = 'md'
  const pinkColor = useColorModeValue('pink.500', 'pink.200')
  const greenColor = useColorModeValue('green.500', 'green.200')
  const _textColor = primaryTextColor({colorMode})
  const textColorInverted = primaryTextColorRevert({colorMode})

  textColor = _textColor
  switch (props.variant) {
    case 'consultationTag':
      bgColor = primaryColor({colorMode})
      textColor = textColorInverted
      break
    case 'categoryTag':
      bgColor = pinkColor
      break
    case 'tagTag':
      bgColor = greenColor
      rounded = 'full'
      break
    default:
      break
  }

  return (
    <ChakraTag
      bgColor={bgColor}
      color={textColor}
      fontWeight="semibold"
      p={2}
      rounded={rounded}
      {...props}
    ></ChakraTag>
  )
}
export default Tag
