import {Box, Flex, Text} from '.'
import React from 'react'
import {
  useAsyncDebounce,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import {Table as TableUi, Tbody, Td, Th, Thead, Tr} from './Table'
import {Spinner, useColorModeValue} from '@chakra-ui/react'
import {AlertInfo} from './Alert'
export type SortOrder = 'asc' | 'desc'

type TableProps = {
  columns: Array<any>
  data: Array<Object>
  controlledPageCount: number
  controlledPageIndex: number
  isLoading: boolean
  isFetching: boolean
  onFetchData: (options: any) => void
  setPageIndex: (pageIndex: number) => void
  setSelectedRowsId: (selectedRowIds: any) => void
  initialSortBy?: Array<any>
  controlledHiddenColumns?: Array<any>
  totalCount: number
  labelData: string
  identifier: string
  disableNoDataText?: boolean
  disablePagination?: boolean
  rowProps?: any
  cellProps?: any
  variantTable?: string
  hideHeader: boolean
  handleSort?: (column: any) => void
}

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  onFetchData,
  controlledPageCount,
  controlledPageIndex,
  controlledHiddenColumns,
  totalCount,
  labelData,
  identifier,

  isLoading,
  isFetching,
  setPageIndex,
  setSelectedRowsId,
  initialSortBy,
  disableNoDataText,
  disablePagination,
  variantTable = 'simple',
  hideHeader = false,
  rowProps = () => {},
  cellProps = () => {},
  handleSort = () => {},
}) => {
  const {
    //  base
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // others
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    setPageSize,
    state: {pageIndex, pageSize, sortBy, selectedRowIds},
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      // autoResetSelectedRows: false,
      pageCount: controlledPageCount,
      initialState: {
        sortBy: initialSortBy,
      },
      getRowId: React.useCallback((row: any) => row[identifier], []),

      useControlledState: state => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: controlledPageIndex,
            hiddenColumns: controlledHiddenColumns,
          }),
          [state, controlledPageIndex, controlledHiddenColumns],
        )
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
  )
  //console.log('sortBy', sortBy)

  const bgColor = useColorModeValue('gray.100', 'gray.800')
  const grayColor = useColorModeValue('gray.100', 'gray.500')

  const pagination = {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    pageIndex,
    gotoPage: setPageIndex,
    setPageSize,
    pageSize,
    labelData,
    totalCount,
  }
  //console.log('page', pageCount)

  // Debounce our onFetchData call for 100ms
  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 400)

  React.useEffect(() => {
    onFetchDataDebounced({pageSize, sortBy})
  }, [onFetchDataDebounced, pageSize, sortBy])

  React.useEffect(() => {
    setSelectedRowsId(selectedRowIds)
  }, [selectedRowIds])

  const convertAlignToFlex = (textAlign?: string) => {
    if (textAlign) {
      if (textAlign === 'left') {
        return 'flex-start'
      }
      if (textAlign === 'center') {
        return 'center'
      }
      if (textAlign === 'right') {
        return 'flex-end'
      }
    } else {
      return 'flex-start'
    }
  }
  if (isLoading) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    )
  }

  if (!data.length) {
    return (
      <Flex
        p={4}
        alignItems="center"
        justifyContent="center"
        // position="relative"
      >
        {!disableNoDataText && (
          <AlertInfo
            title={`Aucun(e) ${labelData} trouvé(e)`}
            description={'Relancer la recherche pour obtenir des résultats'}
          ></AlertInfo>
        )}
      </Flex>
    )
  }
  const isColumnSortable = columnId => {
    const column = columns.find(col => col.accessor === columnId)
    return column?.isSortable || false
  }
  return (
    <div>
      {isFetching && <div>loading</div>}
      <TableUi mt={4} variant={variantTable} {...getTableProps()}>
        <Thead bgColor={bgColor}>
          {hideHeader !== true &&
            headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={e => {
                      handleSort(column)
                    }}
                    style={{
                      paddingRight: '8px',
                      paddingLeft: '8px',
                      // cursor:
                      //   column.id !== 'selection' && column.id !== 'actions'
                      //     ? 'pointer'
                      //     : 'initial',
                      cursor: isColumnSortable(column.id)
                        ? 'pointer'
                        : 'initial',
                    }}
                    {...column.getHeaderProps()}
                    key={index}
                  >
                    <Flex
                      cursor={
                        isColumnSortable(column.id) ? 'pointer' : 'initial'
                      }
                      w="full"
                      justifyContent={
                        column.id === 'actions' || column.id === 'link'
                          ? 'flex-end'
                          : 'flex-start'
                      }
                    >
                      <Flex>
                        <Text
                          cursor={
                            isColumnSortable(column.id) ? 'pointer' : 'initial'
                          }
                        >
                          {column.render('Header')}
                        </Text>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Box display={'inline-block'} ml={2} mt={0.5}>
                              {/* ↓ */}
                            </Box>
                          ) : (
                            <Box display={'inline-block'} ml={2} mt={0.5}>
                              {/* ↑ */}
                            </Box>
                          )
                        ) : null}
                      </Flex>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              //temp hack warning key ; Math.random()

              <Tr
                {...row.getRowProps(rowProps(row))}
                key={`${Math.random()}` + row.id}
                borderTop="1px"
                borderColor={grayColor}
              >
                {row.cells.map((cell, i) => (
                  <Td
                    {...cell.getCellProps(cellProps(cell))}
                    style={{
                      paddingRight: '8px',
                      paddingLeft: '8px',
                    }}
                    key={'tbody_td' + i}
                    border="none"
                  >
                    <Flex
                      justifyContent={convertAlignToFlex(
                        //@ts-ignore
                        cell?.column?.textAlign,
                      )}
                    >
                      {cell.render('Cell')}
                    </Flex>
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </TableUi>
      {!disablePagination /* && <Pagination {...pagination} />*/}
    </div>
  )
}

export default Table
