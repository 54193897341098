import React, {useEffect} from 'react'
import {Alert, AlertIcon, Flex, useColorMode} from '@chakra-ui/react'
import {useMemo} from 'react'
import {sortByDate} from '../../commons/helpers/dataHelper'
import {
  ChatMessage,
  ModelChatMessageConnection,
  ModelSortDirection,
  UserChatRoom,
} from '../../commons/types/API'
import {customScrollbar} from '../../theme/app-constantes'
import {MessageView} from './MessageView'
import {
  customMinHeightPage,
  localStorageLastMessage,
} from '../../commons/constantes'
import {useGlobalContext} from '../../context/GlobalContext'

// Permet de savoir quand afficher les infos avec la liste des messages
const getShowInfos = (arrayMessages: ChatMessage[]): boolean[] => {
  return arrayMessages.reduce((isShowArray, message, index) => {
    if (
      index > 0 &&
      message.userMessagesId !== arrayMessages[index - 1].userMessagesId
    ) {
      isShowArray[index] = true
    }
    if (index === 0) {
      isShowArray[index] = true
    }
    return isShowArray
  }, Array(arrayMessages.length).fill(false))
}

export default function MessageList({
  listChatMessages,
  usersChatRoom,
  isLastChatRoom,
}: {
  listChatMessages?: ModelChatMessageConnection
  usersChatRoom?: UserChatRoom[]
  isLastChatRoom?: boolean
}) {
  const refListMsg = React.useRef<HTMLDivElement>(null)

  const {setNewChatMessage} = useGlobalContext()

  const sortedListchatMessages = sortByDate({
    data: listChatMessages?.items,
    direction: ModelSortDirection.DESC,
  }) as ChatMessage[]

  //gestion des messages non lu

  if (isLastChatRoom) {
    //on stock le dernier message lu
    localStorage.setItem(
      localStorageLastMessage,
      sortedListchatMessages?.[sortedListchatMessages.length - 1]?.id,
    )
  }
  //end gestion des messages non lu

  React.useEffect(() => {
    if (refListMsg.current) {
      refListMsg.current.scrollTo({
        top: refListMsg.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [listChatMessages])

  //dans la derniere room, on met isNew a false, car on a forcement lu le dernier msg
  useEffect(() => {
    if (isLastChatRoom) {
      setNewChatMessage({
        isNew: false,
        lastMessage: undefined,
        openToastNewChatMessage: false,
        disableToast: true,
      })
    }
    return () => {
      //console.log('on quitte la room')
      setNewChatMessage({
        isNew: false,
        lastMessage: undefined,
        openToastNewChatMessage: true,
        disableToast: false,
      })
    }
  }, [isLastChatRoom, setNewChatMessage])

  // useEffect(() => {
  //   // Cela s'exécute après le premier rendu seulement

  //   return () => {
  //     // Cela s'exécute lors du démontage du composant
  //     console.log('on quitte la room')
  //     setNewChatMessage({
  //       isNew: false,
  //       lastMessage: undefined,
  //       openToastNewChatMessage: true,
  //     })
  //   }
  // }, []) // Notez le tableau vide comme deuxième argument

  const {colorMode} = useColorMode()
  const scrollbarCss = customScrollbar({colorMode})

  const isShowInfosArray = useMemo(
    () => getShowInfos(sortedListchatMessages),
    [sortedListchatMessages],
  )

  return (
    <Flex
      flexDir={'column'}
      gap={2}
      overflowY={'scroll'}
      p={2}
      css={scrollbarCss}
      ref={refListMsg}
      maxH={{
        base: customMinHeightPage('250px'),
        md: customMinHeightPage('245px'),
        lg: customMinHeightPage('200px'),
        xl: customMinHeightPage('180px'),
      }}
    >
      {sortedListchatMessages?.length === 0 && <NoMessage />}
      {sortedListchatMessages?.map((e, index) => (
        <Flex w={'100%'} key={index} flexDir={'column'}>
          <MessageView
            chatMessage={e}
            usersChatRoom={usersChatRoom}
            isShowInfo={isShowInfosArray[index]}
          />
        </Flex>
      ))}
    </Flex>
  )
}

const NoMessage = () => {
  return (
    <Alert status="info">
      <AlertIcon />
      Il n'y a pas de messages pour le moment !
    </Alert>
  )
}
