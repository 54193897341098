import * as React from 'react'
import {
  useCreateMessage,
  useTopic,
  useUpdateMessage,
} from '../../commons/api/hooks/topic'
import {sortByDate} from '../../commons/helpers/dataHelper'
import {
  CreateMessageInput,
  ModelSortDirection,
  UpdateMessageInput,
} from '../../commons/types/API'
import {Stack, Box} from '../ui'
import {Card} from '../Card/Card'
import TopicListSkeleton from '../Skeletons/TopicListSkeleton'
import TopicCommentCard from './TopicCommentCard'
import {usePopupDeleteMessage} from '../../hooks/topicsActions'
import ModalContainer from 'react-modal-promise'
import {TopicMessageForm} from '../Forms/TopicMessageForm'

type TopicDetailProps = {
  topicId?: string
}

const TopicDetail: React.FC<TopicDetailProps> = ({topicId}) => {
  const {topic} = useTopic(topicId as string)
  const mutation = useCreateMessage(topicId as string)
  const mutationUpdateMessage = useUpdateMessage()

  const {handleOpenDeleteMessage} = usePopupDeleteMessage({
    messages: topic?.messages?.items,
  })

  const handleUpdateMessage = (message: UpdateMessageInput) => {
    mutationUpdateMessage.mutate(message)
  }

  const handleCreateMessage = (message: CreateMessageInput) => {
    mutation.mutate(message)
  }

  const messagesSorted = sortByDate({
    data: topic?.messages?.items,
    direction: ModelSortDirection.DESC,
  })

  if (!topic) {
    return <TopicListSkeleton></TopicListSkeleton>
  }
  return (
    <Stack spacing={4}>
      {messagesSorted.map((item, index) => (
        <Box key={index}>
          <TopicCommentCard
            item={item}
            topicId={topicId}
            onDeleteMessage={handleOpenDeleteMessage}
            onUpdateMessage={handleUpdateMessage}
            isLoading={mutationUpdateMessage.isLoading}
          />
        </Box>
      ))}
      <Card
        data={
          <Box px={4} pt={4} mb={-2}>
            <TopicMessageForm
              resetForm={true}
              onSubmit={e => handleCreateMessage(e as CreateMessageInput)}
              isLoading={mutation.isLoading}
              initialValues={{
                message: '',
                topicMessagesId: topicId,
              }}
            ></TopicMessageForm>
          </Box>
        }
      />
      <ModalContainer />
    </Stack>
  )
}
export {TopicDetail}
