import React, {ReactNode} from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'
interface SuspenseProps {
  children: ReactNode
}

const AppSuspense: React.FC<SuspenseProps> = ({children}) => {
  return (
    <React.Suspense fallback={<TopBarProgress />}>{children}</React.Suspense>
  )
}

export default AppSuspense
