import {GraphQLResult} from '@aws-amplify/api-graphql'
import {API, graphqlOperation} from 'aws-amplify'

import {
  getCourse,
  coursesBySchool,
  listCourses,
  listUserCourses,
} from '../../graphql/queries'
import {
  updateCourse,
  createCourse as createCourseMutation,
  deleteCourse as deleteCourseMutation,
} from '../../graphql/mutations'
import {
  CreateCourseInput,
  ListCoursesQueryVariables,
  ListUserCoursesQueryVariables,
  UpdateCourseInput,
} from '../../types/API'
import {handleUserCourseError} from '../../helpers/graphQLHelper'
import {
  ErrorMessages,
  GraphQLCourseCommonResult,
} from '../../types/graphQLCommonTypes'

export const geCourseById = async (courseId: string) => {
  const data = await (API.graphql(
    graphqlOperation(getCourse, {id: courseId}),
  ) as Promise<GraphQLResult<GraphQLCourseCommonResult>>)
  return data?.data?.getCourse
}

export const getCoursesByScoolId = async (schoolId: string) => {
  const data = await (API.graphql(
    graphqlOperation(coursesBySchool, {schoolId}),
  ) as Promise<GraphQLResult<GraphQLCourseCommonResult>>)
  return data?.data?.getCoursesBySchoolId
}

export const getCourses = async (input: ListCoursesQueryVariables) => {
  //console.log('getCourses input', input)

  const data = await (API.graphql(
    graphqlOperation(listCourses, input),
  ) as Promise<GraphQLResult<GraphQLCourseCommonResult>>)
  return data?.data?.listCourses
}

export const updateCourseById = async (course?: UpdateCourseInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateCourse, {input: course}),
  ) as Promise<GraphQLResult<GraphQLCourseCommonResult>>)

  return data?.data?.updateCourse
}

export const createCourse = async (course?: CreateCourseInput) => {
  delete course?.id
  const data = await (API.graphql(
    graphqlOperation(createCourseMutation, {input: course}),
  ) as Promise<GraphQLResult<GraphQLCourseCommonResult>>)

  return data?.data?.updateCourse
}

export const deleteCourse = async (id?: string) => {
  const data = await (API.graphql(
    graphqlOperation(deleteCourseMutation, {input: {id}}),
  ) as Promise<GraphQLResult<GraphQLCourseCommonResult>>)

  return data?.data?.deleteCourse
}

export const getUserCourses = async (input?: ListUserCoursesQueryVariables) => {
  try {
    const data = await (API.graphql(
      graphqlOperation(listUserCourses, input),
    ) as Promise<GraphQLResult<GraphQLCourseCommonResult>>)
    return data?.data?.listUserCourses
  } catch (err) {
    // traitement des erreurs a laisser passer
    return handleUserCourseError(err, [ErrorMessages.ReturnNotNull])
  }
}
