import {Box, useColorMode} from '@chakra-ui/react'
import {AddIcon} from '@chakra-ui/icons'
import Searchbar from '../SearchBar/Searchbar'
import {Icon, Menu, MenuButton, MenuItem, MenuList} from '../ui'
import MessagerieActions from './MessagerieActions'
import React, {Dispatch, SetStateAction} from 'react'
import {primaryColor, textButtonColor} from '../../theme/app-constantes'

interface DiscussionHeaderProps {
  searchDiscussion?: string
  setSearchDiscussion: Dispatch<SetStateAction<string>>
}

export default function DiscussionHeader({
  setSearchDiscussion,
  searchDiscussion,
}: DiscussionHeaderProps) {
  const [modalForm, setModalForm] = React.useState<string | null>(null)
  const onCloseModalForm = () => setModalForm(null)

  const {colorMode} = useColorMode()
  const bgIcon = primaryColor({colorMode})
  const iconColor = textButtonColor({colorMode})

  return (
    <Box position={'relative'}>
      <Menu>
        <Searchbar
          placeholder="Rechercher un membre/groupe"
          onChange={e => setSearchDiscussion(e)}
          value={searchDiscussion}
          marginT={'0'}
        />
        <MenuButton
          position={'absolute'}
          right={'4px'}
          top={'50%'}
          transform={'translateY(-50%)'}
          width={'auto'}
        >
          <Icon
            rounded={'md'}
            bg={bgIcon}
            p={2}
            as={AddIcon}
            color={iconColor}
            boxSize={8}
          />
        </MenuButton>

        <MenuList p={2}>
          <MenuItem onClick={() => setModalForm('private')}>
            Contacter un membre
          </MenuItem>
          <MenuItem onClick={() => setModalForm('group')}>
            Créer un groupe
          </MenuItem>
        </MenuList>
      </Menu>
      <MessagerieActions
        modalForm={modalForm}
        onCloseModalForm={onCloseModalForm}
      />
    </Box>
  )
}
