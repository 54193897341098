import React from 'react'
import {useNavigate} from 'react-router-dom'
import Card from '../Card/Card'
import {Box, Container, Grid, GridItem, Stack, ContainerPage, Text} from '../ui'
import {Workspace, ModelSortDirection} from '../../commons/types/API'
import {useTopicsQuery} from '../../commons/api/hooks/topic'
import CardTopic from './CardTopic'
import {TopicForm} from '../Forms/TopicForm'
import {ModalForm} from '../Modals'
import {useColorModeValue} from '@chakra-ui/react'
import {useAuth} from '../../context/AuthContext'
import ModalContainer from 'react-modal-promise'
import {
  defaultWorkspaceImageDark,
  defaultWorkspaceImageLight,
  PAGINATION_TOPIC_DISPLAY,
} from '../../commons/constantes'
import {PrincipalHeading} from '../Heading/PrincipalHeading'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import TopicAction from './TopicAction'
import WorkspaceDesc from './WorkspaceDesc'
import Pagination from '../Table/Pagination'
import {useOpenTopic, useSaveTopic} from '../../hooks/topicsActions'
import {ROUTE_SCHOOL_TOPIC} from '../../constantes'
import {useWorkspace} from '../../commons/api/hooks'
import {AlertInfo} from '../ui/Alert'

type TopicListProps = {
  workspaceId?: Workspace['id']
}

const TopicList = ({workspaceId}: TopicListProps) => {
  const {workspace, isLoading: isLoadingWorkspace} = useWorkspace(
    workspaceId ?? '',
  )

  const [shouldResetPagination, setShouldResetPagination] =
    React.useState(false)

  React.useEffect(() => {
    setShouldResetPagination(true)
  }, [workspace])

  const [searchTerm, setSearchTerm] = React.useState('')

  const {schoolId} = useAuth()
  const topicsQueryBaseParams = workspaceId
    ? {workspaceTopicsId: workspaceId}
    : {topicSchoolId: schoolId}
  const {
    topics,
    isLoading: isLoadingTopics,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = useTopicsQuery({
    ...topicsQueryBaseParams,
    sortDirection: ModelSortDirection.DESC,
    limit: searchTerm.length > 0 ? 1000 : PAGINATION_TOPIC_DISPLAY,
    filter: {name: {contains: searchTerm.toLowerCase()}},
  })
  const hasTopics = !!topics?.length || hasNextPage || hasPrevPage

  const isModeWorkspace = !!workspaceId
  const workspaceName = isLoadingWorkspace
    ? 'Chargement...'
    : workspace?.name ?? 'Tous les forums'
  const colorImage = useColorModeValue(
    defaultWorkspaceImageDark,
    defaultWorkspaceImageLight,
  )
  const image = workspace?.course?.image ?? colorImage

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [topics])

  React.useEffect(() => {
    if (shouldResetPagination) {
      resetPages()
      setShouldResetPagination(false)
    }
  }, [shouldResetPagination, resetPages])

  const navigate = useNavigate()

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    resetPages()
  }
  const {
    isEdit,
    selectedTopic,
    isOpen,
    onOpen,
    onClose,
    handleOpenNewTopic,
    handleOpenUpdateTopic,
  } = useOpenTopic({topics})

  const {handleSaveTopic, isLoading: isLoadingSaveTopic} = useSaveTopic({
    onSuccess: onClose,
    isEdit,
  })

  const handleSelect = (value: string) => {
    navigate(`${ROUTE_SCHOOL_TOPIC}/${value}`)
  }
  const bcItem1 = {label: 'Espaces de travail', to: ROUTE_SCHOOL_TOPIC}
  const bcItem2 = {
    label: workspaceName,
    to: `${ROUTE_SCHOOL_TOPIC}/${workspaceId ?? ''}`,
  }

  return (
    <ContainerPage>
      <PrincipalHeading
        title={bcItem1.label}
        bcItem1={bcItem1}
        bcItem2={bcItem2}
      />
      <Grid templateColumns={{xl: 'repeat(6, 1fr)'}} gap={4}>
        <GridItem colSpan={4}>
          <Card
            title="Espace de travail"
            data={
              <Stack spacing={4}>
                <Box
                  w="full"
                  bgImage={image}
                  h={{
                    base: '170px',
                    md: '240px',
                    sm: '200px',
                    lg: '260px',
                    xl: '230px',
                  }}
                  bgSize={{base: 'cover', '2xl': 'contain'}}
                  bgRepeat={'no-repeat'}
                  bgPosition={'center'}
                ></Box>
              </Stack>
            }
            data2={
              <Box display={{xl: 'none'}}>
                <Grid
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                  }}
                  gap={4}
                >
                  <GridItem colSpan={1}>
                    <WorkspaceDesc
                      workspace={workspace}
                      selectWorkspace={handleSelect}
                      isLoading={isLoadingWorkspace}
                    />
                  </GridItem>
                  <GridItem colSpan={1} mt={1}>
                    <TopicAction
                      workspaceId={workspaceId}
                      onAdd={handleOpenNewTopic}
                      onSearch={handleSearch}
                    />
                  </GridItem>
                </Grid>
              </Box>
            }
          />

          {isLoadingTopics && <TextRowSkeleton />}
          {!isLoadingTopics && !hasTopics && (
            <Box my={2}>
              <AlertInfo
                title={'Aucun sujet créé pour le moment'}
                description={
                  'Vous pouvez créer un nouveau sujet en cliquant sur le bouton "Créer un sujet"'
                }
              ></AlertInfo>
            </Box>
          )}
          {!isLoadingTopics && hasTopics && (
            <>
              {topics?.length ? (
                topics?.map(topic => (
                  <Box key={topic.id} my={2}>
                    <CardTopic
                      topic={topic}
                      onUpdateTopic={handleOpenUpdateTopic}
                    />
                  </Box>
                ))
              ) : (
                <Box h={2}></Box>
              )}
              <Pagination
                next={nextPage}
                prev={prevPage}
                hasNext={hasNextPage}
                hasPrev={hasPrevPage}
              />
            </>
          )}
        </GridItem>
        <GridItem display={{base: 'none', xl: 'initial'}} colSpan={{xl: 2}}>
          <Stack spacing={4}>
            <Container variant="generalBg">
              <WorkspaceDesc
                workspace={workspace}
                selectWorkspace={handleSelect}
                isLoading={isLoadingWorkspace}
              />
            </Container>
            <Container variant="generalBg">
              <TopicAction
                onAdd={handleOpenNewTopic}
                onSearch={handleSearch}
                workspaceId={workspaceId}
              />
            </Container>
          </Stack>
        </GridItem>
      </Grid>
      <ModalForm
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        title="Creation de Forum"
        size="xl"
      >
        <TopicForm
          isModeWorkspace={isModeWorkspace}
          initialValues={{
            ...selectedTopic,
            userTopicsId: selectedTopic?.user?.id,
            workspaceTopicsId: selectedTopic?.workspace?.id ?? '',
          }}
          onSubmit={handleSaveTopic}
          onClose={onClose}
          isLoading={isLoadingSaveTopic}
        />
      </ModalForm>
      <ModalContainer />
    </ContainerPage>
  )
}

export {TopicList}
