import {useColorMode} from '@chakra-ui/react'
import {
  useCreateComment,
  useQuestion,
  useUpdateComment,
  useFAQTag,
} from '../../commons/api/hooks'
import {
  Avatar,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Stack,
  Tag,
  Text,
} from '../ui'
import {
  bgColorDark,
  bgColorLight,
  bgSidebarDark,
  bgSidebarLight,
  primaryColor,
  primaryTextColorRevert,
} from '../../theme/app-constantes'
import {Link as DomRouterLink} from 'react-router-dom'
import {Card} from '../Card/Card'
import CardCommentQuestion from './CardCommentQuestion'
import QuestionDetailSkeleton from '../Skeletons/QuestionDetailSkeleton'
import PrincipalHeading from '../Heading/PrincipalHeading'
import {
  CreateCommentInput,
  ModelSortDirection,
  UpdateCommentInput,
  User,
} from '../../commons/types/API'
import {ReponseVideo} from '../Player'
import {usePopupDeleteComment} from '../../hooks/questionsActions'
import ModalContainer from 'react-modal-promise'
import {formatDate, sortByDate} from '../../commons/helpers/dataHelper'
import {CommentForm} from '../Forms/CommentForm'
import {
  ROUTE_SCHOOL_CONSULTATIONS,
  ROUTE_SCHOOL_FAQ_QUESTION,
  ROUTE_SCHOOL_USER,
} from '../../constantes'
import {dateFormat_M_MMM_yyyy_date} from '../../commons/constantes'
import ReactMarkDownChakra from '../ui/ReactMarkDownChakra'
import {questionTitleOrReword} from '../../commons/helpers/screen'
import {displayNameForAvatar, displayUserNameAndName} from '../../commons'

type QuestionByIdProps = {
  questionId?: string
  initialValues?: UpdateCommentInput & CreateCommentInput
}

export const QuestionById = ({
  questionId,
  initialValues,
}: QuestionByIdProps) => {
  const {colorMode} = useColorMode()
  const {question} = useQuestion(questionId ?? 'empty')

  /* const {FAQTags, isLoading} = useFAQTags({
    filter: {
      id: {
        between: question?.tags?.items.map(e => e?.id) as (string | null)[],
      },
    },
  })*/

  const mutationCreateComment = useCreateComment()
  const mutationUpdateComment = useUpdateComment()
  const colorTextRevert = primaryTextColorRevert({colorMode})
  const colorTitle = primaryColor({colorMode})

  const getImage = (question: any) => {
    return question?.author?.image ? question?.author?.image : ''
  }

  const getAuthorImage = (question: any) => {
    return question?.answerAuthor?.image ? question?.answerAuthor?.image : ''
  }

  const NbComment = ({
    size = '',
    suffix = '',
    prefix = '',
    color = '',
    isBold = false,
    getNumber = true,
  }) => {
    const textSize = size === 'sm' ? {base: '12px', md: '16px'} : '20px'
    return (
      <Text color={color} fontSize={textSize}>
        <span style={{fontWeight: 'bold'}}>
          {prefix} {getNumber ? commentsSorted.length : ''}
        </span>{' '}
        <span style={{fontWeight: isBold ? '900' : ''}}>
          {commentsSorted.length > 1 ? 'Commentaires' : 'Commentaire'}
          {suffix}
        </span>
      </Text>
    )
  }
  const {handleOpenDeleteComment} = usePopupDeleteComment({
    comments: question?.comments?.items,
  })

  const handleUpdateComment = (
    comment: UpdateCommentInput & CreateCommentInput,
  ) => {
    mutationUpdateComment.mutate(comment)
  }

  const handleCreateComment = (
    comment: UpdateCommentInput & CreateCommentInput,
  ) => {
    mutationCreateComment.mutate(comment)
  }

  const bcItem1 = {
    label: `${question?.faqConsultation?.title ?? 'Non publié '}`,
    to: `${ROUTE_SCHOOL_CONSULTATIONS}/${
      question?.faqConsultation?.id ?? ''
    }?view=grid`,
  }
  const questionTitle = questionTitleOrReword(question)
  const bcItem2 = {
    label: `${questionTitle}`,
    to: `${ROUTE_SCHOOL_FAQ_QUESTION}/${question?.id}`,
  }

  const TagMapById = ({tag}) => {
    const {workspace} = useFAQTag(tag?.fAQTagID)
    return (
      <Tag color={colorTitle} variant="subtle" size="sm">
        {workspace?.name}
      </Tag>
    )
  }

  const CatAndTags = () => {
    const tags = question?.tags?.items || []
    const limitTag = 4
    const displayedTags =
      tags.length > limitTag ? tags.slice(0, limitTag) : tags
    return (
      <Flex gap={2} flexWrap={'wrap'}>
        <Tag bgColor={colorTitle} color={colorTextRevert} size="sm">
          {question?.category?.name ?? 'Non définie'}
        </Tag>
        {displayedTags.map((tag, index) => {
          return <TagMapById key={index} tag={tag} />
        })}
        {tags.length > limitTag && (
          <Tag color={colorTitle} variant="subtle" size="sm">
            {'...'}
          </Tag>
        )}
      </Flex>
    )
  }

  const UserAnswer = () => {
    const isAuthorHere = question?.answerAuthor?.id
    return (
      <Flex justifyContent={'flex-start'}>
        <Box
          bgColor={{
            base: colorMode === 'light' ? bgSidebarLight : bgSidebarDark,
            md: colorMode === 'light' ? bgColorLight : bgColorDark,
          }}
          p={{base: 2, lg: 3}}
          borderRadius={10}
          boxShadow={{base: 'md', md: 'none'}}
        >
          {isAuthorHere ? (
            <Link
              as={DomRouterLink}
              to={`${ROUTE_SCHOOL_USER}/${question?.answerAuthor?.id}`}
              display={'flex'}
              alignItems={'center'}
              gap={2}
            >
              <Avatar
                size={{base: 'sm', lg: 'md'}}
                name={displayNameForAvatar(question?.answerAuthor as User)}
                src={getAuthorImage(question)}
                user={question?.answerAuthor as User}
              />

              <Box>
                <Text fontSize={{base: '12px', lg: '16px'}} variant="label">
                  <span style={{fontWeight: 'bold'}}>Réponse de : </span>
                </Text>
                <Text
                  fontSize={{base: '13px', lg: '16px'}}
                  variant="contentText"
                  textTransform={'capitalize'}
                >
                  {displayUserNameAndName(question?.answerAuthor)}
                </Text>
              </Box>
            </Link>
          ) : (
            <Text fontSize={{base: '12px', lg: '16px'}} variant="label">
              <span style={{fontWeight: 'bold'}}>Réponse</span>
            </Text>
          )}
        </Box>
      </Flex>
    )
  }
  const UserPublish = () => {
    const isAuthorHere = question?.author?.id
    return (
      <>
        <Flex justifyContent={'flex-start'}>
          <Box
            bgColor={{
              base: colorMode === 'light' ? bgSidebarLight : bgSidebarDark,
              md: colorMode === 'light' ? bgColorLight : bgColorDark,
            }}
            p={{base: 2, lg: 3}}
            borderRadius={10}
            boxShadow={{base: 'md', md: 'none'}}
          >
            {isAuthorHere ? (
              <Link
                as={DomRouterLink}
                to={`${ROUTE_SCHOOL_USER}/${question?.author?.id}`}
                display={'flex'}
                alignItems={'center'}
                gap={2}
              >
                <Avatar
                  size={{base: 'sm', lg: 'md'}}
                  name={displayNameForAvatar(question?.author)}
                  src={getImage(question)}
                  user={question?.author}
                />

                <Box>
                  <Text fontSize={{base: '12px', lg: '16px'}} variant="label">
                    <span style={{fontWeight: 'bold'}}>
                      Question posée par :{' '}
                    </span>
                  </Text>
                  <Text
                    fontSize={{base: '13px', lg: '16px'}}
                    variant="contentText"
                    textTransform={'capitalize'}
                  >
                    {displayUserNameAndName(question?.author)}
                  </Text>
                </Box>
              </Link>
            ) : (
              <Text fontSize={{base: '12px', lg: '16px'}} variant="label">
                <span style={{fontWeight: 'bold'}}>Question</span>
              </Text>
            )}
          </Box>
        </Flex>
      </>
    )
  }
  //console.log('video question', question)

  const QuestionDateInfo = () => {
    return (
      <>
        <Flex
          flexDirection={{base: 'row', md: 'row', lg: 'row', xl: 'column'}}
          justifyContent={{base: 'flex-start', md: 'space-between'}}
          alignItems={'flex-start'}
          minW={{base: 'auto', md: '280px', lg: '310px', xl: 'auto'}}
          gap={5}
        >
          <Flex flexDirection={'column'} gap={2}>
            <Text
              fontSize={{base: '12px', md: '14px', lg: '16px'}}
              color={colorTitle}
            >
              <span style={{fontWeight: 'bold'}}>Question posée le </span>
            </Text>
            <Text fontSize={{base: '12px', md: '14px', lg: '16px'}}>
              {formatDate(
                question?.createdAt ?? '',
                dateFormat_M_MMM_yyyy_date,
              )}
            </Text>
          </Flex>
          <Flex
            flexDirection={{
              base: 'column',
              md: 'column',
              lg: 'column',
              xl: 'column',
            }}
            gap={2}
          >
            <Text
              fontSize={{base: '12px', md: '14px', lg: '16px'}}
              color={colorTitle}
            >
              <span style={{fontWeight: 'bold'}}>Mise à jour</span>
            </Text>
            <Text fontSize={{base: '12px', md: '14px', lg: '16px'}}>
              {formatDate(
                question?.updatedAt ?? '',
                dateFormat_M_MMM_yyyy_date,
              )}
            </Text>
          </Flex>
        </Flex>
      </>
    )
  }
  const QuestionDetail = () => {
    return (
      <>
        <Flex
          flexDirection={{base: 'row', md: 'row', xl: 'column'}}
          alignItems={{md: 'center', xl: 'flex-start'}}
          gap={5}
          mr={-4}
        >
          <QuestionDateInfo />
          <CatAndTags />
        </Flex>
      </>
    )
  }

  const commentsSorted = sortByDate({
    data: question?.comments?.items,
    direction: ModelSortDirection.DESC,
  })

  const DesktopTopBreadC = () => {
    return (
      <Box display={{base: 'none', sm: 'block'}}>
        <PrincipalHeading title={''} bcItem1={bcItem1} bcItem2={bcItem2} />
      </Box>
    )
  }

  const VideoDesktop = () => {
    return (
      <Box display={{base: 'none', md: 'inline'}}>
        <Box>
          <Flex
            alignItems={'center'}
            gap={5}
            justifyContent={'space-between'}
            pr={5}
          >
            <Box>
              <UserAnswer />
            </Box>
            <Box display={{xl: 'none'}}>
              <QuestionDetail />
            </Box>
          </Flex>
          <ReponseVideo br={10} question={question} />
        </Box>
      </Box>
    )
  }

  const CardMobile = () => {
    return (
      <Flex
        display={{base: 'flex', md: 'none'}}
        gap={3}
        flexDirection={'column'}
      >
        <UserPublish />
        <Card
          title="Question"
          isTitleOnMobile={true}
          data={
            <>
              <Stack spacing={4}>
                <Box>
                  <Flex gap={2} flexDirection={'column'}>
                    <Heading variant="question" textTransform={'uppercase'}>
                      {questionTitle}
                    </Heading>
                    {/* <Text variant="question"> */}
                    <ReactMarkDownChakra>
                      {question?.description ?? ''}
                    </ReactMarkDownChakra>
                    {/* </Text> */}
                    <Box mt={-4} mb={-6}>
                      <ReponseVideo
                        br={10}
                        question={question}
                        videoFor={'answer'}
                      />
                    </Box>
                  </Flex>
                </Box>
              </Stack>
            </>
          }
        />
        <UserAnswer />
        <Card
          title="Réponse"
          isTitleOnMobile={true}
          data={
            <>
              <Stack>
                <Box mt={-4} mb={-6}>
                  <ReponseVideo br={10} question={question} />
                </Box>
              </Stack>
            </>
          }
        />
        <Flex flexDirection={'column'} alignItems={'felx-start'} gap={5}>
          <QuestionDateInfo />
          <CatAndTags />
        </Flex>
      </Flex>
    )
  }
  const CardDesktopQuestion = () => {
    return (
      <GridItem
        colSpan={{base: 1, xl: 6}}
        display={{base: 'none', md: 'inline'}}
      >
        <Card
          title="Question"
          data={
            <>
              <Stack spacing={4}>
                <Box>
                  <Flex gap={2} flexDirection={'column'}>
                    <UserPublish />
                    <Heading variant="question" textTransform={'uppercase'}>
                      {questionTitle}
                    </Heading>
                    {/* <Text variant="question"> */}

                    <ReactMarkDownChakra>
                      {question?.description ?? ''}
                    </ReactMarkDownChakra>

                    {/* </Text> */}
                    <ReponseVideo
                      br={10}
                      question={question}
                      videoFor={'answer'}
                    />
                  </Flex>
                </Box>
              </Stack>
            </>
          }
        />
      </GridItem>
    )
  }

  const CardDesktopAnswer = () => {
    return (
      <GridItem
        colSpan={{base: 1, xl: 6}}
        display={{base: 'none', md: 'inline'}}
      >
        <Card
          title="Réponse"
          data={
            <>
              <VideoDesktop />
            </>
          }
        />
      </GridItem>
    )
  }
  const InfoDesktop = () => {
    return (
      <GridItem colSpan={2} display={{base: 'none', xl: 'inline'}}>
        <Card
          title="Récap"
          data={
            <Flex
              flexDirection={'column'}
              alignItems={'flex-start'}
              gap={5}
              mr={-4}
            >
              <QuestionDetail />
              <NbComment size={'sm'} color={colorTitle} />{' '}
            </Flex>
          }
        />
      </GridItem>
    )
  }
  if (!question) {
    return <QuestionDetailSkeleton></QuestionDetailSkeleton>
  }
  return (
    <>
      <DesktopTopBreadC />
      <CardMobile />
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          xl: 'repeat(8, 1fr)',
        }}
        gap={4}
      >
        <GridItem colSpan={{base: 1, xl: 6}}>
          <CardDesktopQuestion />
          <Box mt={5}>
            <CardDesktopAnswer />
          </Box>
        </GridItem>
        <InfoDesktop />
      </Grid>
      <Heading mt={6} mb={4} variant="worspaceTitle">
        <NbComment suffix={' :'} getNumber={false} isBold />{' '}
      </Heading>
      <Stack spacing={4}>
        {commentsSorted.map((comment, idx) => (
          <Box key={idx}>
            <CardCommentQuestion
              comment={comment}
              onDeleteComment={handleOpenDeleteComment}
              onUpdateComment={handleUpdateComment}
              isLoading={mutationUpdateComment.isLoading}
            />
          </Box>
        ))}
        <CommentForm
          resetForm={true}
          question={question}
          onSubmit={handleCreateComment}
          isLoading={mutationCreateComment.isLoading}
        ></CommentForm>
        <ModalContainer />
      </Stack>
    </>
  )
}
