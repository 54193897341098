import {
  linkSidebarColorRevert,
  linkSidebarDark,
  linkSidebarLight,
  bgSidebarDark,
  bgSidebarLight,
  primaryColorDark,
  primaryColorLight,
} from './../app-constantes'
// C'est ici que je ferais les principales mises en forme de composants ici car ce composant est customisable à mort
const Container = {
  variants: {
    // Container de l'appli
    appContainer: {
      maxW: 'full',
      maxH: '100vh',
      px: 0,
    },
    // container de menu grand écran
    sidebarContainer: (props: any) => ({
      display: {base: 'none', lg: 'inline-block'},
      minW: {md: '2xs'},
      maxW: {md: '2xs'},
      p: {base: 0, md: 4},
    }),
    // Container du menu mobile
    menuMobile: (props: any) => ({
      rounded: 'md',
      px: 8,
      pb: 8,
      //   position: '-webkit-sticky',
      position: 'absolute',
      maxW: '311px',
      zIndex: 'banner',
      top: {base: '60px', md: '80px'},
      right: {base: 0, md: 'inherit'},
      left: 0,
      border: '2px solid',
      bgColor: linkSidebarColorRevert(props.colorMode),
      boxShadow: 'lg',
      borderColor:
        props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      display: {lg: 'none'},
    }),
    // Container élements Menu Général
    sidebarLink: (props: any) => ({
      mt: '15px',
      w: 'full',
      h: '54px',
      fontSize: 'xl',
      px: 4,
      py: 3,
      rounded: 'md',
      fontWeight: 'semibold',
      color: 'gray.500',
      cursor: 'pointer',
      _hover: {
        bg: props.colorMode === 'dark' ? linkSidebarLight : linkSidebarDark,
        color: props.colorMode === 'dark' ? linkSidebarDark : linkSidebarLight,
      },
      _activeLink: {
        bg: props.colorMode === 'dark' ? linkSidebarLight : linkSidebarDark,
        color: props.colorMode === 'dark' ? linkSidebarDark : linkSidebarLight,
      },
      display: 'flex',
    }),
    sidebarSubLink: (props: any) => ({
      mt: '12px',
      ml: '18px',
      w: 'full',
      h: '54px',
      fontSize: 's',
      px: 4,
      py: 3,
      rounded: 'md',
      fontWeight: 'semibold',
      color: 'gray.500',
      cursor: 'pointer',
      _hover: {
        bg: props.colorMode === 'dark' ? linkSidebarLight : linkSidebarDark,
        color: props.colorMode === 'dark' ? linkSidebarDark : linkSidebarLight,
      },
      _activeLink: {
        bg: props.colorMode === 'dark' ? linkSidebarLight : linkSidebarDark,
        color: props.colorMode === 'dark' ? linkSidebarDark : linkSidebarLight,
      },
      display: 'flex',
    }),
    // Container Général de contenu de page (fond blanc et ombre portée)
    generalBg: (props: any) => ({
      rounded: 'md',
      p: 4,
      maxWidth: 'full',
      boxShadow: 'md',
      bgColor: props.colorMode === 'dark' ? bgSidebarDark : bgSidebarLight,
    }),
    // Container CardUser et cards ProfilUser
    cardSection: (props: any) => ({
      rounded: 'md',
      px: 0,
      boxShadow: 'md',
      bgColor: props.colorMode === 'dark' ? bgSidebarDark : bgSidebarLight,
      pb: 6,
    }),
    // Container bande bleu dans CardUser
    headerUser: (props: any) => ({
      maxW: 'full',
      h: '64px',
      bgColor:
        props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      borderTopRadius: 'md',
    }),
    // Container cards programme
    bgComplet: (props: any) => ({
      rounded: 'md',
      px: 0,
      boxShadow: 'md',
      bgColor: props.colorMode === 'dark' ? bgSidebarDark : bgSidebarLight,
      h: '200px',
    }),
    // Container hover cards programme
    hoverCardProg: (props: any) => ({
      bgColor:
        props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      color: props.colorMode === 'dark' ? linkSidebarDark : linkSidebarDark,
      p: 4,
      position: 'absolute',
      bottom: '0px',
      borderBottomRadius: 'md',
    }),
    // Container footer
    containerFooter: (props: any) => ({
      mt: 4,
      rounded: 'md',
      p: 4,
      boxShadow: 'md',
      minW: 'full',
      bgColor: props.colorMode === 'dark' ? bgSidebarDark : bgSidebarLight,
    }),
  },
}

export default Container
