import MultiSelect, {MultiSelectProps, MultiSelectType} from '../ui/MultiSelect'
import {FormControl, FormHelperText, Spinner} from '@chakra-ui/react'
import {User} from '../../commons/types/API'
import {useAuth, useAuthDetails, useAuthUserId} from '../../context/AuthContext'
import {useUsersBySchoolId} from '../../commons/api/hooks'
import {Eroles, UserStatus} from '../../types'
import {useMemo} from 'react'

const MultiSelectUsers = ({
  label,
  value,
  onChange,
  isMulti,
  //users,
  excludeMe = false,
  helperText,
  errorMessage,
  excludedUsers,
  onlyAdmin,
  ...rest
}: MultiSelectProps & {
  //users: User[]
  excludeMe?: boolean
  helperText?: string
  errorMessage?: string
  excludedUsers?: (string | undefined)[]
  onlyAdmin?: boolean
}) => {
  const uid = useAuthUserId()
  const {schoolId} = useAuthDetails()
  const {users} = useUsersBySchoolId({
    limit: 1000,
    userSchoolId: schoolId,
    filter: {status: {eq: UserStatus.CREATED}},
  })
  const handleChange = (data: any) => {
    onChange?.(data)
  }

  const userFiltered = useMemo(() => {
    let filtered = users?.items ?? []

    if (excludeMe && users) {
      filtered = filtered.filter(u => u?.id !== uid)
    }
    if (excludedUsers) {
      filtered = filtered.filter(u => !excludedUsers.includes(u?.id))
    }
    if (onlyAdmin) {
      filtered = filtered.filter(u => u?.roles?.includes(Eroles.SCHOOLOWNER))
    }
    return filtered
  }, [users, excludeMe, excludedUsers, onlyAdmin, uid])

  if (!userFiltered) return <Spinner></Spinner>
  return (
    <FormControl>
      <MultiSelect
        mb={5}
        value={value}
        data={userFiltered}
        label={label}
        randomColor={true}
        onChange={handleChange}
        isMulti={isMulti}
        type={MultiSelectType.USERS}
        {...rest}
      />
      <FormHelperText>{helperText}</FormHelperText>
      <FormHelperText color="red.500" role="alert">
        {errorMessage}
      </FormHelperText>
    </FormControl>
  )
}

export default MultiSelectUsers
