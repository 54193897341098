import {Consultations} from '../../../components/Consultations'
import {ContainerPage} from '../../../components/ui'

export const ConsultationPage = () => {
  return (
    <ContainerPage>
      <Consultations tabIdx={1}></Consultations>
    </ContainerPage>
  )
}
