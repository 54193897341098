import {useMutation, useQuery} from 'react-query'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types'
import {
  createChatMessage,
  createChatRoom,
  deleteChatMessage,
  deleteChatRoom,
  getChatMessageById,
  getChatRoomById,
  getListChatMessages,
  getListChatRooms,
  updateChatMessage,
  updateChatRoom,
  getUserChatRoomsById,
  createUserChatRooms,
  // updateUserChatRooms,
  deleteUserChatRooms,
  getListUserChatRooms,
  getChatMessagesByUser,
  getMeChatRooms,
  onCreateChatMessage,
} from '../clients/messagerie'
import {queryClient} from '../../config/react-query'
import {
  ListChatRoomsQueryVariables,
  ListChatMessagesQueryVariables,
  GetChatMessageQueryVariables,
  ListUserChatRoomsQueryVariables,
  OnCreateChatMessageSubscriptionVariables,
  ChatMessage,
  ChatRoom,
} from '../../types/API'
import {AwsIDObject, filterDuplicateItemsSub} from '../../helpers/graphQLHelper'
import {Auth} from 'aws-amplify'
import {useAuth, useAuthUserId} from '../../../context/AuthContext'

type ErrorCustom = {
  errors: unknown[]
}

// Chat Room
export const useChatRoomById = (chatRoomId: string) => {
  const enabled = chatRoomId ? true : false
  const {isLoading, data} = useQuery(
    [`getChatRoomById`, {id: chatRoomId}],
    () => getChatRoomById({id: chatRoomId}),
    {
      enabled, // Cela désactive la requête
    },
  )
  useGlobalLoading(isLoading)
  return {chatRoom: data, isLoading}
}
/**
 * preferer utilisation de useGetMeChatRooms
 * @param input
 * @returns
 */
export const useListChatRooms = (input?: ListChatRoomsQueryVariables) => {
  const {isLoading, data} = useQuery(
    [`getListChatRooms`, input?.filter, input?.limit, input?.nextToken],
    () => getListChatRooms(input),
  )
  useGlobalLoading(isLoading)
  return {listchatRooms: data}
}

export const useGetMeChatRooms = ({
  disabled,
  noCache,
}: {
  disabled?: boolean
  noCache?: boolean
}) => {
  const enabled = !disabled
  //console.log('useGetMeChatRooms enabled x', enabled)
  const {isLoading, data} = useQuery(
    [`getMeChatRooms`],
    () => getMeChatRooms(),
    {
      staleTime: noCache ? 0 : 1000 * 60 * 5, // 5 minutes
      refetchInterval: 1000 * 60 * 5, // Refetch toutes les 5 minutes
      enabled,
    },
  )
  //wordkaround after logout login issue
  if (disabled) {
    queryClient.invalidateQueries('getMeChatRooms')
  }
  useGlobalLoading(isLoading)
  filterDuplicateItemsSub(data as {items: AwsIDObject[]}, 'chatRoom')
  //const extractChatRooms = data?.items?.map(item => item.chatRoom)
  //if (data?.items) data.items = extractChatRooms
  return {chatRooms: data, isLoading}
}

export const useCreateChatRoom = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createChatRoom, {
    onMutate: () => {
      fetchStart()
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('getChatRoomById')
      queryClient.invalidateQueries('getListChatRooms')
      queryClient.invalidateQueries('getMeChatRooms')
      setTimeout(() => {
        queryClient.invalidateQueries('getMeChatRooms')
      }, 1000) // Exécuter après un délai d'une seconde (1000 ms)
      setTimeout(() => {
        queryClient.invalidateQueries('getMeChatRooms')
      }, 5000) // Exécuter après un délai d'une seconde (1000 ms)

      fetchEnd()
      setMessage('Groupe de discussion créer')
      onSuccess(data)
    },
    onError: (error: ErrorCustom) => {
      console.error(
        'erreur lors de la mutation getChatRoomById',
        error.errors[0],
      )
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useUpdateChatRoom = ({
  onSuccess = () => {},
  onError = () => {},
  disableSuccessMessage = false,
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateChatRoom, {
    onMutate: () => {
      fetchStart()
    },
    onSuccess: data => {
      queryClient.invalidateQueries('getChatRoomById')
      queryClient.invalidateQueries('getListChatRooms')
      queryClient.invalidateQueries('getMeChatRooms')

      fetchEnd()
      if (!disableSuccessMessage) {
        setMessage('Groupe de discussion mis à jour')
      }
      onSuccess(data)
    },
    onError: (error: ErrorCustom) => {
      console.error(
        'erreur lors de la mutation getChatRoomById',
        error.errors[0],
      )
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteChatRoom = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteChatRoom, {
    onMutate: () => {
      fetchStart()
    },
    onSuccess: () => {
      queryClient.invalidateQueries('getChatRoomById')
      queryClient.invalidateQueries('getListChatRooms')

      fetchEnd()
      setMessage('Groupe de discussion supprimé')
      onSuccess()
    },
    onError: (error: ErrorCustom) => {
      console.error(
        'erreur lors de la mutation getChatRoomById',
        error.errors[0],
      )
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

// Chat Message

export const useChatMessageById = (
  chatMessageId: GetChatMessageQueryVariables,
) => {
  const {isLoading, data} = useQuery(
    ['getChatMessageById', {id: chatMessageId}],
    () => getChatMessageById(chatMessageId),
  )
  useGlobalLoading(isLoading)
  return {chatMessages: data}
}

export const useListChatMessages = (input?: ListChatMessagesQueryVariables) => {
  const {isLoading, data} = useQuery(
    [`getListChatMessages`, input?.filter, input?.limit, input?.nextToken],
    () => getListChatMessages(input),
  )
  useGlobalLoading(isLoading)
  return {listchatMessages: data}
}

export const useCreateChatMessage = ({
  onSuccess = () => {},
  onError = () => {},
  disableSuccessMessage = false,
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createChatMessage, {
    onMutate: () => {
      fetchStart()
    },
    onSuccess: () => {
      queryClient.invalidateQueries('getChatMessageById')
      queryClient.invalidateQueries('getListChatMessages')
      queryClient.invalidateQueries('getChatRoomById')
      // queryClient.invalidateQueries('getMeChatRooms')
      // setTimeout(() => {
      //   queryClient.invalidateQueries('getMeChatRooms')
      // }, 1000) // Exécuter après un délai d'une seconde (1000 ms)
      // setTimeout(() => {
      //   queryClient.invalidateQueries('getMeChatRooms')
      // }, 5000) // Exécuter après un délai d'une seconde (1000 ms)
      fetchEnd()
      if (!disableSuccessMessage) {
        setMessage('Message envoyé')
      }
      onSuccess()
    },
    onError: (error: ErrorCustom) => {
      console.error(
        'erreur lors de la mutation getChatMessageById',
        error.errors[0],
      )
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useUpdateChatMessage = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateChatMessage, {
    onMutate: () => {
      fetchStart()
    },
    onSuccess: data => {
      queryClient.invalidateQueries('getChatMessageById')
      queryClient.invalidateQueries('getListChatMessages')
      queryClient.invalidateQueries('getChatRoomById')
      fetchEnd()
      setMessage('Message modifié')
      onSuccess(data)
    },
    onError: (error: ErrorCustom) => {
      console.error(
        'erreur lors de la mutation getChatMessageById',
        error.errors[0],
      )
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useDeleteChatMessage = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteChatMessage, {
    onMutate: () => {
      fetchStart()
    },
    onSuccess: () => {
      queryClient.invalidateQueries('getChatMessageById')
      queryClient.invalidateQueries('getListChatMessages')
      queryClient.invalidateQueries('getChatRoomById')
      fetchEnd()
      setMessage('Message supprimé')
      onSuccess()
    },
    onError: (error: ErrorCustom) => {
      console.error(
        'erreur lors de la mutation getChatMessageById',
        error.errors[0],
      )
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

type useCreateChatMessageWithSubscriptionProps = {
  variables: OnCreateChatMessageSubscriptionVariables
}

export const useCreateChatMessageWithSubscription = ({
  variables,
}: useCreateChatMessageWithSubscriptionProps) => {
  const {setNewChatMessage} = useGlobalContext()
  const uid = useAuthUserId()
  const subscribe = () => {
    //console.log('subscribe  id', variables.filter?.chatRoomMessagesId)
    if (!variables.filter) return
    const newSubscription = onCreateChatMessage(variables).subscribe({
      next: ({provider, value}) => {
        const newMessage = value.data?.onCreateChatMessage as ChatMessage
        // console.log('newMessage received ', newMessage)
        if (uid !== newMessage?.userMessagesId) {
          setNewChatMessage({isNew: true, lastMessage: newMessage})
        }

        queryClient.setQueryData<ChatRoom | undefined>(
          ['getChatRoomById', {id: newMessage.chatRoom?.id}],
          old => {
            if (
              !old ||
              (old.messages &&
                old.messages.items.filter(item => item?.id === newMessage.id)
                  .length > 0)
            ) {
              return old
            }
            return {
              ...old,
              messages: old.messages
                ? {
                    ...old.messages,
                    items: old.messages?.items
                      ? [...old.messages.items, newMessage]
                      : [newMessage],
                  }
                : null,
            }
          },
        )
      },
      error(error: unknown) {
        console.log('Erreur lors de la souscription', error)
      },
    })
    return newSubscription
  }
  return {
    subscribe,
  }
}

// UserChatRoom

export const useGetUserChatRooms = (userChatRoomsId: string) => {
  const {isLoading, data} = useQuery(
    ['getUserChatRoomsById', {id: userChatRoomsId}],
    () => getUserChatRoomsById({id: userChatRoomsId}),
  )
  useGlobalLoading(isLoading)
  return {userChatRooms: data}
}

export const useListUserChatRooms = (
  input?: ListUserChatRoomsQueryVariables,
) => {
  const {isLoading, data} = useQuery(
    [`getListUserChatRooms`, input?.filter, input?.limit, input?.nextToken],
    () => getListUserChatRooms(input),
  )
  useGlobalLoading(isLoading)
  return {listUserChatRooms: data}
}

export const useAddUserToChatRoom = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(createUserChatRooms, {
    onMutate: () => {
      fetchStart()
    },
    onSuccess: data => {
      //console.log('useAddUserToChatRoom success ', data)
      queryClient.invalidateQueries('getUserChatRoomsById')
      queryClient.invalidateQueries('getListUserChatRooms')
      queryClient.invalidateQueries('getMeChatRooms')

      fetchEnd()
      setMessage(`Ajout de l'utilisateur dans le groupe de discussion`)
      onSuccess(data)
    },
    onError: (error: ErrorCustom) => {
      console.error(
        'erreur lors de la mutation getUserChatRoomsById/getListUserChatRooms',
        error.errors[0],
      )
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

// export const useUpdateUserToChatRoom = ({
//   onSuccess = () => {},
//   onError = () => {},
// }: queryMutationParams = {}) => {
//   const {
//     setError: setGlobalError,
//     setMessage,
//     fetchStart,
//     fetchEnd,
//   } = useGlobalContext()

//   const mutation = useMutation(updateUserChatRooms, {
//     onMutate: () => {
//       fetchStart()
//     },
//     onSuccess: data => {
//       queryClient.invalidateQueries('getUserChatRoomsById')
//       queryClient.invalidateQueries('getListUserChatRooms')

//       fetchEnd()
//       setMessage(`Groupe de discussion mise à jour`)
//       onSuccess(data)
//     },
//     onError: (error: ErrorCustom) => {
//       console.error(
//         'erreur lors de la mutation getUserChatRoomsById/getListUserChatRooms',
//         error.errors[0],
//       )
//       setGlobalError('Erreur lors de la mise à jour')
//       onError()
//     },
//   })
//   return mutation
// }
export const useDeleteUserToChatRoom = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(deleteUserChatRooms, {
    onMutate: () => {
      fetchStart()
    },
    onSuccess: () => {
      queryClient.invalidateQueries('getUserChatRoomsById')
      queryClient.invalidateQueries('getListUserChatRooms')
      queryClient.invalidateQueries('getMeChatRooms')
      queryClient.invalidateQueries('getChatRoomById')

      fetchEnd()
      setMessage(`Suppression de l'utilisateur`)
      onSuccess()
    },
    onError: (error: ErrorCustom) => {
      console.error(
        'erreur lors de la mutation getUserChatRoomsById/getListUserChatRooms',
        error.errors[0],
      )
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

// User Message

export const useChatMessagesByUser = (userId: string) => {
  const {isLoading, data} = useQuery(
    ['getChatMessagesByUser', {id: userId}],
    () =>
      getChatMessagesByUser({
        userMessagesId: userId,
      }),
  )
  useGlobalLoading(isLoading)
  return {chatMessages: data}
}
