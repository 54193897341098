import * as React from 'react'
import {Heading, Text, Box, Link} from '../../../components/ui'
import {useColorMode, Image} from '@chakra-ui/react'
import {Card} from '../../../components/Login/Card'
import {Link as DomRouterLink} from 'react-router-dom'
import {Logo} from '../../../Logo'
import {
  bgColor,
  primaryColor,
  hoverTransparentBg,
} from '../../../theme/app-constantes'
import {LINK_LOGO, multiSchool} from '../../../constantes'

export enum AuthPageType {
  login,
  register,
  pinConfirmation,
  reset,
}
export const AuthContainerSimple = ({
  label,
  pageType,
  children,
}: {
  label?: string
  pageType?: AuthPageType
  children: React.ReactNode
}) => {
  const {colorMode} = useColorMode()
  const colorModeLink = primaryColor({colorMode})
  const colorHover = hoverTransparentBg({colorMode})

  return (
    <Box
      bg={bgColor({colorMode})}
      minH="100vh"
      py="12"
      px={{base: '4', lg: '8'}}
    >
      <Box maxW="md" mx="auto">
        <Box
          maxW={'300px'}
          maxH={'200px'}
          ml={'auto'}
          mr={'auto'}
          p={10}
          position="relative"
        >
          {/* <Logo2 mx="auto" h="20" mb={{base: '10', md: '20'}} /> */}
          {!multiSchool ? (
            <Image
              src={LINK_LOGO}
              alt="logo"
              boxSize={'100%'}
              maxH={'130px'}
              objectFit={'contain'}
            />
          ) : (
            <Logo mx="auto" h="auto" mb={{base: '10', md: '20'}} />
          )}
        </Box>
        {/* <img src={Logo2} alt="React Logo" height="10" /> */}
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          {label ?? 'Connectez-vous à votre compte'}
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          {pageType === AuthPageType.reset && (
            <>
              <Text as="span">Vous possédez un compte ?</Text>
              <Link
                as={DomRouterLink}
                to="/login"
                marginStart="1"
                color={colorModeLink}
                _hover={{color: colorHover}}
                display={{base: 'block', sm: 'inline'}}
              >
                Se connecter
              </Link>
            </>
          )}

          {pageType === AuthPageType.register && (
            <>
              <Text as="span">Vous possédez un compte ?</Text>
              <Link
                as={DomRouterLink}
                to="/login"
                marginStart="1"
                color={colorModeLink}
                _hover={{color: colorHover}}
                display={{base: 'block', sm: 'inline'}}
              >
                Se connecter
              </Link>
            </>
          )}

          {pageType === AuthPageType.login && (
            <>
              <Text as="span">Mot de passe perdu ?</Text>
              <Link
                as={DomRouterLink}
                to="/reset-password"
                marginStart="1"
                color={colorModeLink}
                _hover={{color: colorHover}}
                display={{base: 'block', sm: 'inline'}}
              >
                Réinitialiser le mot de passe
              </Link>
            </>
          )}
        </Text>
        <Card>{children}</Card>
      </Box>
    </Box>
  )
}
export default AuthContainerSimple
