import styled from '@emotion/styled'
import {minHeightPage, sizes} from '../../commons/constantes'
import {Container as ChakaraContainer, ContainerProps} from '@chakra-ui/react'

export const ContainerPage = styled.div`
  max-width: ${sizes?.xl};
  width: 100%;
  margin: 0 auto;
  min-height: ${minHeightPage};
`

// export const ContainerPage = ({...rest}: ContainerProps) => {
//   return <ChakaraContainer maxWidth={sizes?.xl} {...rest}></ChakaraContainer>
// }
export const Container = (props: ContainerProps) => {
  return <ChakaraContainer {...props}></ChakaraContainer>
}
export default Container
