import {CheckCircleIcon, AttachmentIcon} from '@chakra-ui/icons'
import {Flex, IconButton, Input} from '@chakra-ui/react'
import React from 'react'
import {useState} from 'react'
import {
  useCreateChatMessage,
  useUpdateChatRoom,
} from '../../commons/api/hooks/messagerie'
import {useAuthDetails} from '../../context/AuthContext'
import {Box} from '../ui'
import {FormControl} from '../ui/Input'
import {useGlobalContext} from '../../context/GlobalContext'
import {ChatRoom} from '../../commons/types/API'

export const MessageInput = ({chatRoom}: {chatRoom: ChatRoom}) => {
  const {setMessage: setToastMessage, setError: setErrorMessage} =
    useGlobalContext()
  const [messageValue, setMessageValue] = useState('')

  const {uid: userId, schoolGroupId} = useAuthDetails()
  const mutationChatMessage = useCreateChatMessage({
    disableSuccessMessage: true,
  })
  const mutationUpdateChatRoom = useUpdateChatRoom({
    disableSuccessMessage: true,
  })

  const handleAddMessage = (chatRoomId: string) => {
    if (!messageValue) {
      setErrorMessage('Entrer un message')
      return
    }
    const editorsSet = new Set([...(chatRoom.editors ?? []), userId as string])
    mutationChatMessage.mutate({
      message: messageValue,
      chatRoomMessagesId: chatRoomId,
      userMessagesId: userId as string,
      groupsCanAccess: [schoolGroupId],
      owner: userId as string,
      editors: [...editorsSet],
    })
    setMessageValue('')
    // update chatRoom updatedAt pour faire remonter la conversation en haut de la liste si nouveau message
    mutationUpdateChatRoom.mutate({
      id: chatRoomId ?? '',
      updatedAt: new Date().toISOString(),
    })
  }

  const handleSubmit: React.FormEventHandler<HTMLDivElement> = event => {
    event.preventDefault()
    handleAddMessage(chatRoom.id)
  }
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setMessageValue(event.target.value)
  }
  return (
    <Flex flexDir={'row'} gap={2}>
      <Box w={'100%'} transition={'0.3s'}>
        <FormControl as="form" onSubmit={handleSubmit}>
          <Input
            placeholder={'Ecrire le message ici ...'}
            value={messageValue}
            onChange={handleChange}
          />
        </FormControl>
      </Box>
      <IconButton
        aria-label="envoyer le message"
        icon={<CheckCircleIcon />}
        onClick={() => handleAddMessage(chatRoom.id)}
      />

      <IconButton
        aria-label="envoyer le message"
        icon={<AttachmentIcon />}
        onClick={() =>
          setToastMessage(
            `N'en demande pas trop ! Nous sommes un petite équipe 😅`,
          )
        }
      />
    </Flex>
  )
}
