import {Routes, Route} from 'react-router-dom'
import {Dashboard} from './Dashboard'
import {SchoolSettings} from './School'
import {CourseList} from './Courses'
import {WorkspaceList} from './Workspaces'
import {StudentList} from './Students'
import AuthAdminAppTemplate from '../../components/Templates/AuthAdminAppTemplate'
import {AdminFaqRouter} from './Consultation/AdminFaqRouter'
import {Error404} from './../common/Error404'
export const AdminRouter = () => {
  return (
    <AuthAdminAppTemplate>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="settings" element={<SchoolSettings />} />
        <Route path="courses" element={<CourseList />} />
        <Route path="workspaces" element={<WorkspaceList />} />
        <Route path="students" element={<StudentList />} />
        <Route path="faq/*" element={<AdminFaqRouter />} />

        {/* <Route
          path="faq/create-consultation/:step/"
          element={<ConsultationCreatePage />}
        />
        <Route
          path="faq/create-consultation/:step/:consultationId"
          element={<ConsultationCreatePage />}
        /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </AuthAdminAppTemplate>
  )
}
