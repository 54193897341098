import {Container, Link, SimpleGrid, Text, GridItem} from './ui'

export const Footer = () => {
  return (
    <Container variant="containerFooter">
      <SimpleGrid columns={{base: 1, md: 2}}>
        <GridItem>
          <Text
            textAlign={{base: 'center', md: 'left'}}
            fontSize={{base: 'sm', md: 'md'}}
            noOfLines={1}
          >
            © Copyright {new Date().getFullYear()} MikeCodeur All Rights
            Reserved.
          </Text>
        </GridItem>
        <GridItem>
          <Text
            textAlign={{base: 'center', md: 'right'}}
            fontSize={{base: 'sm', md: 'md'}}
            noOfLines={1}
          >
            <Link
              isExternal
              variant="linkFooter"
              href="https://www.mikecodeur.com/conditions-generales-de-vente/"
            >
              Terms & Conditions
            </Link>{' '}
            |{' '}
            <Link
              isExternal
              variant="linkFooter"
              href="https://www.mikecodeur.com/politique-de-confidentialite/"
            >
              Privacy Policy
            </Link>{' '}
            |{' '}
            <Link
              isExternal
              variant="linkFooter"
              href="https://go.mikecodeur.com/lateam-report-issue"
            >
              Remonter une anomalie
            </Link>
          </Text>
        </GridItem>
      </SimpleGrid>
    </Container>
  )
}

export default Footer
