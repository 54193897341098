import React, {useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import {
  useConsultations,
  useQuestionsByConsultationId,
} from '../../../commons/api/hooks'
import {PAGINATION_TABLE_QUESTION} from '../../../commons/constantes'
import {buildFilterById} from '../../../commons/helpers/graphQLHelper'
import {ModelSortDirection} from '../../../commons/types/API'
import FAQResponsesTable from '../../../components/FaqVideo/user-faq-reponse-table'
import {ContainerPage} from '../../../components/ui'
import {EStatus} from '../../../types/graphQL'

const FaqByConsultationPage = () => {
  const {consultationId} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [sortDirection, setSortDirection] = React.useState<ModelSortDirection>(
    ModelSortDirection.DESC,
  )
  const questionQueryParam = searchParams.get('question')
  const viewQueryParam = searchParams.get('view')

  const [categoryId, setCategoryId] = useState('')

  const [searchTerm, setSearchTerm] = React.useState<string>(
    questionQueryParam ?? '',
  )

  const {
    questionsConsultation,
    isLoading,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
    nextToken,
  } = useQuestionsByConsultationId({
    limit: searchTerm ? 10000 : PAGINATION_TABLE_QUESTION,
    fAQConsultationQuestionsId: consultationId ? consultationId : 'default',
    sortDirection: sortDirection,
    filter: {
      title: {contains: searchTerm?.toLowerCase()},
      status: {eq: EStatus.PUBLISHED},
      ...(categoryId ? {questionCategoryId: {eq: categoryId}} : {}),
    },
  })

  const {consultations} = useConsultations({
    filter: {
      ...buildFilterById(consultationId),
    },
  })

  React.useEffect(() => {
    setSearchTerm(questionQueryParam ?? '')
  }, [questionQueryParam])

  return (
    <ContainerPage>
      <FAQResponsesTable
        view={viewQueryParam}
        setSearchParams={setSearchParams}
        questions={questionsConsultation}
        next={nextPage}
        prev={prevPage}
        nextToken={nextToken}
        hasNext={hasNextPage}
        hasPrev={hasPrevPage}
        reset={resetPages}
        setSortDirection={setSortDirection}
        setCategoryId={setCategoryId}
        categoryId={categoryId}
        setSearchTerm={setSearchTerm}
        currentConsultation={consultations?.items?.[0]}
        isLoading={isLoading}
      />
    </ContainerPage>
  )
}
export {FaqByConsultationPage}
