import {SearchIcon} from '@chakra-ui/icons'
import {
  InputGroup,
  InputLeftElement,
  Input,
  useColorMode,
  Tooltip,
} from '@chakra-ui/react'
import {useState} from 'react'
import {linkSidebarColorRevert} from '../../theme/app-constantes'

type SearchbarProps = {
  onChange?: (value: string) => void
  onSearch?: (value: string) => void
  placeholder?: string
  tooltipMessage?: string
  value?: string
  marginT?: string | undefined
}

export const Searchbar = ({
  onChange = () => {},
  onSearch = () => {},
  placeholder,
  tooltipMessage,
  marginT,
  value,
}: SearchbarProps) => {
  const {colorMode} = useColorMode()
  const [searchTerm, setSearchTerm] = useState(value ?? '')
  const handlerKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(searchTerm)
    }
  }
  const handleClick = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    onSearch(searchTerm)
  }
  return (
    <InputGroup /*maxW="200px"*/ mt={marginT ? marginT : 1} width="auto">
      <InputLeftElement>
        <Tooltip label={tooltipMessage} aria-label="A tooltip">
          <SearchIcon color="gray.500" onClick={handleClick} cursor="pointer" />
        </Tooltip>
      </InputLeftElement>
      <Input
        type="search"
        placeholder={placeholder ?? 'Rechercher'}
        onChange={e => {
          setSearchTerm(e.target.value)
          onChange(e.target.value)
          setSearchTerm(e.target.value)
        }}
        bgColor={linkSidebarColorRevert({colorMode})}
        onKeyDown={handlerKeyDown}
        value={searchTerm}
      />
    </InputGroup>
  )
}

export default Searchbar
