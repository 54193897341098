import * as React from 'react'
import {UserAccountForm} from '../../../components/Forms/UserAccountForm'
import {useUpdateMe} from '../../../commons/api/hooks/auth'
import {useAuth} from '../../../context/AuthContext'
import AuthAppTemplate from '../../../components/Templates/AuthAppTemplate'
import {updateAuthUser} from '../../../commons'
import {UserAccountDetailSkeleton} from '../../../components/Skeletons'
import {ContainerPage} from '../../../components/ui'
const AccountPage = () => {
  //authUser vient du context Api
  const {authUser} = useAuth()
  //tout le reste dans les Hookq
  const mutation = useUpdateMe()

  const handleSubmit = (data: any) => {
    //showMessage({open: true, variant: 'success', message: 'COOL ÇA MARCHE'})
    mutation.mutate(data)
    updateAuthUser(data)
  }

  if (!authUser) {
    return <UserAccountDetailSkeleton></UserAccountDetailSkeleton>
  }

  //todo : do not use Form here: call form from a COmponent and add Skeleton
  return (
    <AuthAppTemplate>
      <ContainerPage>
        <UserAccountForm
          onSubmit={handleSubmit}
          initialValues={authUser?.me}
          isLoading={mutation.isLoading}
        />
      </ContainerPage>
    </AuthAppTemplate>
  )
}
export {AccountPage}
