import {Heading, Stack, Text} from '../ui'
import SelectWorkspaces from '../Selects/select-workspace'
import {Workspace} from '../../commons/types/API'

type WorkspaceDescProps = {
  workspace?: Workspace
  selectWorkspace: (value: string) => void
  isLoading?: boolean
}

export const WorkspaceDesc = ({
  workspace,
  selectWorkspace,
  isLoading = false,
}: WorkspaceDescProps) => {
  return (
    <Stack spacing={4}>
      <Heading variant="worspaceTitle">
        {isLoading ? 'Chargement...' : workspace?.name ?? 'Tous les espaces'}
      </Heading>
      <Text my={2}>
        {isLoading
          ? 'Chargement...'
          : workspace?.description ?? `Espaces de travail de l'école`}
      </Text>
      <SelectWorkspaces
        label="Selectionner un espace de travail"
        name="topicWorkspaceId"
        id="topicWorkspaceId"
        onChange={e => selectWorkspace(e.target.value)}
        value={workspace?.id ?? ''}
        maxW="full"
        // errorMessage={
        //   formik?.errors?.topicWorkspaceId as string
        // }
      />
    </Stack>
  )
}

export default WorkspaceDesc
