import UserCourses from '../../../components/Courses/user-course'
import {ContainerPage} from '../../../components/ui'

const CoursesPage = () => {
  return (
    <ContainerPage>
      <UserCourses />
    </ContainerPage>
  )
}
export {CoursesPage}
