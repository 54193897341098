import {ContainerPage} from '../../../components/ui'
import {CourseList as CourseTable} from '../../../components/Courses'

export const CourseList = () => {
  return (
    <ContainerPage>
      <CourseTable />
    </ContainerPage>
  )
}
