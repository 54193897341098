import {ChatRoom, CreateUserChatRoomInput} from '../../commons/types/API'
import MultiSelectUsers from '../Selects/multiselect-users'
import {Button, Flex, HStack} from '../ui'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuthDetails} from '../../context/AuthContext'

type ChatRoomAddUserFormProps = {
  initialValues?: CreateUserChatRoomInput
  chatRoom?: ChatRoom
  onClose: () => void
  onSubmit: (formValue: CreateUserChatRoomInput) => void
  isLoading?: boolean
}

export const ChatRoomAddUserForm = ({
  initialValues,
  chatRoom,
  onSubmit,
  onClose,
  isLoading,
}: ChatRoomAddUserFormProps) => {
  const {schoolGroupId, uid} = useAuthDetails()

  const contributorsIDs = chatRoom?.contributors?.items.map(
    room => room?.userId,
  )

  const ChatRoomSchema = Yup.object().shape({
    userId: Yup.string().required('Champ obligatoire'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      chatRoomId: initialValues?.id ?? chatRoom?.id ?? '',
      userId: initialValues?.userId ?? '',
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? chatRoom?.editors, // ajout uid?
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      updatedAt: initialValues?.updatedAt ?? '',
      createdAt: initialValues?.createdAt ?? '',
    },
    onSubmit(values: CreateUserChatRoomInput) {
      onSubmit(values)
    },
    validationSchema: ChatRoomSchema,
  })

  const handleEditorsChange = (user: any) => {
    const editors = formik.values?.editors
    if (!editors?.includes(user.value.id)) {
      editors?.push(user.value.id)
    }
    formik.setFieldValue('editors', editors)
    formik.setFieldValue('userId', user.value.id)
    //on reprends les editors de la room et on ajoute le nouveau
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <MultiSelectUsers
        isMulti={false}
        my={3}
        label={'Membre'}
        placeholder="Sélectionnez le membre"
        onChange={handleEditorsChange}
        //users={filteredUsers as User[]}
        excludedUsers={contributorsIDs as string[]}
        excludeMe={true}
        errorMessage={formik.errors.userId}
      />

      <Flex justifyContent="flex-end" mt={5}>
        <HStack>
          <Button variant="outline" onClick={onClose}>
            Annuler
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Ajouter
          </Button>
        </HStack>
      </Flex>
    </form>
  )
}
