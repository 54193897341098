const Text = {
  baseStyle: {
    cursor: 'default',
  },
  // Là aussi je vais en rajouter pour pas mal de cas. Mais ici j'ai déjà intégrer les plus classiques
  variants: {
    contentText: (props: any) => ({
      color: props.colorMode === 'dark' ? 'gray.300' : 'gray.500',
      lineHeight: 'shorter',
      fontSize: 'md',
      fontWeight: 'light',
      textAlign: 'justify',
    }),
    questionText: (props: any) => ({
      color: props.colorMode === 'dark' ? 'white' : 'black',
      lineHeight: '160%',
      fontSize: 'md',
      fontWeight: 'light',
    }),
    textTitle: (props: any) => ({
      fontSize: 'xl',
      fontWeight: 'bold',
    }),
    nameMenu: (props: any) => ({
      color: props.colorMode === 'dark' ? 'gray.300' : 'gray.500',
      fontSize: 'l',
      fontWeight: 'semibold',
    }),
    label: (props: any) => ({
      fontSize: 'md',
      fontWeight: 'semibold',
    }),
    checkbox: (props: any) => ({
      fontWeight: 'regular',
    }),
    popup: (props: any) => ({
      fontWeight: 'medium',
      fontSize: 'xl',
      lineHeight: 1.2,
    }),
    titlePopup: (props: any) => ({
      fontWeight: 'extrabold',
      fontSize: 'xl',
    }),
    labelCompetance: (props: any) => ({
      color: props.colorMode === 'dark' ? 'gray.300' : 'gray.500',
      fontSize: 'md',
      fontWeight: 'semibold',
    }),
    nameFormation: (props: any) => ({
      fontSize: 'lg',
      fontWeight: 'semibold',
    }),
    subFormation: (props: any) => ({
      fontSize: 'xs',
      fontWeight: 'light',
      // textAlign: 'right',
    }),
    cardProg: (props: any) => ({
      fontSize: 'sm',
      fontWeight: 'bold',
    }),
    dateCardTopic: (props: any) => ({
      fontSize: 'xs',
      fontWeight: 'light',
      fontStyle: 'italic',
    }),
    authorItalic: (props: any) => ({
      fontWeight: 'regular',
      fontStyle: 'italic',
    }),
    subjectQuestion: (props: any) => ({
      fontWeight: 'bold',
      fontSize: 'md',
    }),
  },
  defaultProps: {
    variant: 'contentText',
  },
}

export default Text
