import {AspectRatio, Badge} from '@chakra-ui/react'
import {useState} from 'react'
import {Question} from '../../commons/types/API'
import {ReponseVideo} from '../Player'
import {Box, Text, Avatar, Link} from '../ui'
import {Link as DomRouterLink} from 'react-router-dom'
import {ROUTE_SCHOOL_FAQ_QUESTION, ROUTE_SCHOOL_USER} from '../../constantes'
import {questionTitleOrReword} from '../../commons/helpers/screen'
import {displayNameForAvatar} from '../../commons'

type CardVideoProps = {
  question: Question
}

export const CardVideo = ({question}: CardVideoProps) => {
  const date = new Date(question.updatedAt)
  const imagethumbail = question?.thumb || '/images/default-thumb.jpg'
  const formattedDate = date.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  const [isClicking, setIsClicking] = useState(false)

  // Si la reformulation est vide, on affiche le titre original de la question
  const questionTitle = questionTitleOrReword(question)
  return (
    <Box w={{base: '100%', md: 'calc(50% - 20px)'}}>
      <Box
        position={'relative'}
        cursor={`url('/images/curseur-play.png') 25 25, pointer`}
      >
        {question?.category?.name && (
          <Badge
            className="badgeVideosQuestions"
            position={'absolute'}
            zIndex={1}
            top={5}
            left={5}
            cursor={'default'}
            padding={1}
          >
            {question?.category?.name}
          </Badge>
        )}

        {isClicking ? (
          <ReponseVideo question={question} />
        ) : (
          <AspectRatio
            borderRadius={10}
            overflow={'hidden'}
            maxW="100%"
            ratio={1.7}
            my={4}
            padding={0}
            onClick={() => setIsClicking(true)}
          >
            <Box
              h={'100%'}
              w={'100%'}
              bgImage={imagethumbail}
              bgSize={'cover'}
              bgPos={'center'}
            ></Box>
          </AspectRatio>
        )}
      </Box>
      <Box display={'flex'}>
        <Box>
          <Link
            as={DomRouterLink}
            to={`${ROUTE_SCHOOL_USER}/${question?.author?.id}`}
          >
            <Avatar
              src={question?.author?.image ?? ''}
              name={displayNameForAvatar(question?.author)}
              user={question?.author}
              mr={6}
              variant={'userNoBoxBg'}
              size={'md'}
              display={{base: 'flex', sm: 'flex'}}
            />
          </Link>
        </Box>
        <Link
          to={`${ROUTE_SCHOOL_FAQ_QUESTION}/${question?.id}?view=grid`}
          as={DomRouterLink}
        >
          <Box cursor={'pointer'}>
            <Text
              sx={{
                '&::first-letter': {
                  textTransform: 'uppercase',
                },
              }}
              variant="subjectQuestion"
              cursor={'pointer'}
            >
              {questionTitle}
            </Text>
            <Text variant="dateCardTopic" cursor={'pointer'}>
              @{question.author.username} ● {formattedDate}
            </Text>
          </Box>
        </Link>
      </Box>
    </Box>
  )
}
