import React from 'react'
import {ContainerPage, Grid, GridItem} from '../../../components/ui'
import {StatsCard} from '../../../components/StatsCard'
import {LastQuestions} from '../../../components/Questions/LastQuestions'
import {BsPerson, BsFillPersonCheckFill} from 'react-icons/bs'
import {FiServer} from 'react-icons/fi'
import {GoLocation} from 'react-icons/go'
import {
  useCoursesBySchoolId,
  useQuestionsByStatusSortByDate,
  useUsersBySchoolId,
  useWorkspacesBySchoolId,
} from '../../../commons/api/hooks'
import {useAuth, useUserDetailSchoolGroupId} from '../../../context/AuthContext'
import {
  buildFilterByQuestionsSchoolId,
  buildFilterBySchool,
} from '../../../commons/helpers/graphQLHelper'
import TopicListSkeleton from '../../../components/Skeletons/TopicListSkeleton'
import {EStatus} from '../../../types/graphQL'
import {ModelSortDirection} from '../../../commons/types/API'
import {UserStatus} from '../../../types'

export const Dashboard = () => {
  const {schoolId} = useAuth()
  const schoolGroupId = useUserDetailSchoolGroupId()
  const {users} = useUsersBySchoolId({
    limit: 1000,
    userSchoolId: schoolId,
    filter: {status: {eq: UserStatus.CREATED}},
  })
  const {courses} = useCoursesBySchoolId({
    limit: 1000,
    filter: {
      ...buildFilterBySchool(schoolGroupId),
    },
  })
  const {workspaces} = useWorkspacesBySchoolId({
    input: {workspaceSchoolId: schoolId},
    custom: false,
  })

  const {questions, isLoading} = useQuestionsByStatusSortByDate({
    limit: 100,
    status: EStatus.NEW,
    sortDirection: ModelSortDirection.DESC,
    filter: {
      ...buildFilterByQuestionsSchoolId(schoolId),
    },
  })

  if (isLoading) {
    return <TopicListSkeleton></TopicListSkeleton>
  }

  return (
    <ContainerPage>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(3, 1fr)',
          xl: 'repeat(9, 1fr)',
        }}
        gap={2}
      >
        <GridItem colSpan={{base: 1, xl: 2}}>
          <StatsCard
            title={'Élèves'}
            stat={`${users?.items?.length ?? '...'} `}
            icon={<BsPerson size={'3em'} />}
            percentUpOrDown={'increase'}
            percent={33.4}
          />
        </GridItem>
        <GridItem colSpan={{base: 1, xl: 2}}>
          <StatsCard
            title={'Formations'}
            stat={`${courses?.items?.length ?? '...'}`}
            icon={<FiServer size={'3em'} />}
            percentUpOrDown={'decrease'}
            percent={5}
          />
        </GridItem>
        <GridItem colSpan={{base: 1, xl: 2}}>
          <StatsCard
            title={'Espaces de travail'}
            stat={`${workspaces?.items.length ?? '...'}`}
            icon={<GoLocation size={'3em'} />}
            percentUpOrDown={'decrease'}
            percent={3}
          />
        </GridItem>
        <GridItem colSpan={{base: 1, md: 3}}>
          <StatsCard
            title={'Questions'}
            stat={`${questions?.items?.length ?? '...'}`}
            icon={<BsFillPersonCheckFill size={'3em'} />}
            percentUpOrDown={'increase'}
            percent={45.4}
          />
        </GridItem>
      </Grid>
      <LastQuestions
        questions={questions?.items}
        isAdmin={true}
        isLoading={isLoading}
      />
    </ContainerPage>
  )
}
