import React from 'react'
import {Avatar, Box, Flex, Icon, IconButton, Stack} from '../ui'
import {
  CreateFAQCategoryInput,
  FAQCategory,
  UpdateFAQCategoryInput,
} from '../../commons/types/API'

import {Table as ReactTable} from '../ui/ReactTable'
import {HeaderTable} from '../Table/HeaderTable'
import {Pagination} from '../Table/Pagination'
import {MdDelete, MdEdit} from 'react-icons/md'

import {modalYesNoPromise} from '../Modals/YesNo'
import ModalContainer from 'react-modal-promise'
import {ModalForm} from '../Modals'
import {useDisclosure, Checkbox} from '@chakra-ui/react'
import {
  useAuthUserId,
  useUserDetailSchoolGroupId,
} from '../../context/AuthContext'
import {
  useCreateFAQCategory,
  useDeleteFAQCategory,
  useFAQCategories,
  useUpdateFAQCategory,
} from '../../commons/api/hooks'
import {FAQCategoriesForm} from '../Forms/FAQCatgoriesForm'
import {AvatarRow} from '../Table/rowsRenderers/AvatarRow'
import {LINK_IMG_CATEGORIE} from '../../constantes'
import {PAGINATION_ADMIN_CATEGORIES_DISPLAY} from '../../commons/constantes'
import {buildFilterName} from '../../commons/helpers/graphQLHelper'

// type FAQCategorysListProps = {
//   categories?: Array<FAQCategory> | []
// }

export const CategoriesTabPanel = () => {
  const [selectedCategory, setSelectedCategory] = React.useState<FAQCategory>()
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const limit = PAGINATION_ADMIN_CATEGORIES_DISPLAY
  const {
    FAQCategories,
    prevPage,
    nextPage,
    hasPrevPage,
    hasNextPage,
    resetPages,
  } = useFAQCategories({
    filter: {
      ...buildFilterName(searchTerm),
    },

    limit: searchTerm ? 10000 : limit,
  })
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const uid = useAuthUserId()
  const schoolGroupId = useUserDetailSchoolGroupId()
  const mutationDelete = useDeleteFAQCategory()
  const mutationCreate = useCreateFAQCategory({onSuccess: onClose})
  const mutationUpdate = useUpdateFAQCategory({onSuccess: onClose})
  const data = React.useMemo(
    () => [...((FAQCategories?.items as Array<FAQCategory> | []) ?? [])],
    [FAQCategories],
  )

  const handleModalSave = (
    values: UpdateFAQCategoryInput & CreateFAQCategoryInput,
  ) => {
    // //console.log('handleModalSave')

    values.groupsCanAccess = [schoolGroupId]
    values.owner = uid
    if (isEdit) {
      mutationUpdate.mutate(values)
    } else {
      mutationCreate.mutate(values)
    }
  }

  const handleDelete = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()

      modalYesNoPromise({
        title: 'Supprimer',
        confirmLabel: 'Supprimer',
        data: data.find(elm => elm.id === value),
        labelOfType: 'prog',
        children: <div>Souhaitez vous vraiment supprimer la catégorie ?</div>,
      })
        .then(ok => {
          mutationDelete.mutate(value)
        })
        .catch(error => {})
    },
    [data, mutationDelete],
  )

  const handleOpenUpdate = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      // //console.log('value', value)

      const course = data.find(elm => elm.id === value)
      setSelectedCategory(course)
      setIsEdit(true)
      onOpen()
    },
    [data, onOpen],
  )

  const handleOpenNew = React.useCallback(() => {
    setSelectedCategory(undefined)
    setIsEdit(false)
    onOpen()
  }, [onOpen])

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    resetPages()
  }

  const columns = React.useMemo(
    () => [
      // {
      //   id: 'selection',
      //   accessor: 'selection',
      //   // gestion de la checkbox du header : SelectAll ligne
      //   // The header can use the table's getToggleAllRowsSelectedProps method
      //   // to render a checkbox
      //   Header: ({getToggleAllRowsSelectedProps}: any) => {
      //     const {indeterminate, ...rest} = getToggleAllRowsSelectedProps()
      //     return <Checkbox isIndeterminate={indeterminate ? 1 : 0} {...rest} />
      //   },
      //   // checkbox seletion d'une ligne
      //   // The cell can use the individual row's getToggleRowSelectedProps method
      //   // to the render a checkbox
      //   Cell: ({row}: any) => {
      //     //to avoid warning on indeterminate boolean
      //     const {indeterminate, ...rest} = row.getToggleRowSelectedProps()
      //     return <Checkbox isIndeterminate={indeterminate ? 1 : 0} {...rest} />
      //   },
      // },
      {
        Header: 'image',
        accessor: 'image',
        Cell: ({row}: any) => {
          return (
            // <AvatarRow row={row} variant="tagList" />
            <Box>
              <Avatar src={LINK_IMG_CATEGORIE} mr={2} variant="progList" />
            </Box>
          )
        },
      },

      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'Supprimer',
        accessor: 'id',
        Cell: ({cell: {value}}: any) => (
          <Flex justifyContent="flex-end" w="full">
            <IconButton
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdEdit} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) => handleOpenUpdate(e, value)}
            />
            <IconButton
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdDelete} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) => handleDelete(e, value)}
            />
          </Flex>
        ),
      },
    ],
    [handleDelete, handleOpenUpdate],
  )

  //Props spécifiques pour chaque cellule <Td>
  const cellProps = (cellInfo: any) => {
    switch (cellInfo.column.id) {
      case 'selection':
        return {
          _position: 'select',
          util: 'user',
        }
      case 'image':
        return {
          _position: 'avatar',
          util: 'user',
        }
      case 'name':
        return {
          _position: 'name',
          util: 'user',
        }
      case 'id':
        return {
          _position: 'actions',
          util: 'user',
        }
    }
  }

  //Props pour chaque ligne <Tr>
  // const rowProps = (row: any) => ({})

  return (
    <>
      <Stack spacing={4}>
        <HeaderTable
          util="categories"
          onAdd={handleOpenNew}
          onSearch={handleSearch}
        />
        <ReactTable
          // rowProps={rowProps}
          cellProps={cellProps}
          columns={columns}
          data={data}
          controlledPageCount={0}
          controlledPageIndex={0}
          isLoading={false}
          isFetching={false}
          onFetchData={function (options: any): void {
            // throw new Error('Function not implemented.')
          }}
          setPageIndex={function (pageIndex: number): void {
            //throw new Error('Function not implemented.')
          }}
          setSelectedRowsId={function (selectedRowIds: any): void {
            //console.log('selectedRowIds', selectedRowIds)
          }}
          totalCount={0}
          labelData={'Catégories'}
          identifier={'id'}
          controlledHiddenColumns={[]}
          initialSortBy={[]}
          hideHeader={false}
        />
        <Pagination
          hidden={!hasPrevPage && !hasNextPage}
          next={nextPage}
          prev={prevPage}
          hasNext={hasNextPage}
          hasPrev={hasPrevPage}
        />
      </Stack>
      <ModalForm
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        title={isEdit ? 'Modifier une categorie' : 'Ajouter une categorie'}
      >
        <FAQCategoriesForm
          isEdit={isEdit}
          initialValues={{
            ...(selectedCategory as FAQCategory),
          }}
          onSubmit={handleModalSave}
          onClose={onClose}
          isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
        />
      </ModalForm>
      <ModalContainer />
    </>
  )
}

export default CategoriesTabPanel
