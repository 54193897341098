import {useParams} from 'react-router-dom'
import AdminFaqMyQuestion from '../../../components/FaqVideo/admin-faq-question-editor'
import {ContainerPage} from '../../../components/ui'

const AdminFaqQuestionEditor = () => {
  const {consultationId} = useParams()
  return (
    <ContainerPage>
      <AdminFaqMyQuestion consultationId={consultationId} />
    </ContainerPage>
  )
}
export {AdminFaqQuestionEditor}
