import {
  Avatar as ChakraAvatar,
  AvatarProps,
  Box,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import {getBgColorByUserName, isAdmin as isAdminFonction} from '../../commons'
import {LINK_IMG_TAG} from '../../constantes'
import {User} from '../../commons/types/API'
/**
 *
 * @param props
 * @returns
 */
export const Avatar = (
  props: AvatarProps & {user?: User; isChakraAvatar?: boolean},
) => {
  const {user, isChakraAvatar, ...rest} = props
  const isAdmin = isAdminFonction(props.user)

  // Bon ok j'ai fais des conditionnelles donc pas de hook à l'intérieur donc en plus des if y a des constantes... Bon ok pas vraiment fier du truc mais en vrai ce que fait ce composant ça tue
  const paddingAvatar = useBreakpointValue({base: 2})
  const breakPoint = useBreakpointValue({base: 'xl'})
  const bgReactIcon = '/images/default-user-icon.png'
  const bgUserLight = '/images/bgUserAvatarLight.png'
  const bgUserDark = '/images/bgUserAvatarDark.png'
  const bgProgLight = '/images/bgProgAvatarLight.png'
  const bgProgDark = '/images/bgProgAvatarDark.png'
  const bgUserAvatar = useColorModeValue(bgUserDark, bgUserLight)
  const bgProgAvatar = useColorModeValue(bgProgDark, bgProgLight)
  const colorText = useColorModeValue('white', 'gray.800')
  let borderRadius = 'full'
  let bgImage = 'none'
  let bgAvatarColor = 'transparent'
  let px = useBreakpointValue({base: 0})
  let py = useBreakpointValue({base: 0})
  let size = useBreakpointValue({base: 'sm'})
  let fallbackImageSrc: any = undefined
  let bgImg = bgReactIcon
  let bgSize = 'contain'
  let BgColor = getBgColorByUserName(props?.name ?? 'a')
  if (props.variant === 'progList') {
    borderRadius = '6px'
    fallbackImageSrc =
      'https://cdn.iconscout.com/icon/free/png-256/workspace-1467359-1240505.png'
    bgImg = ''
    bgSize = ''
    BgColor = ''
  }
  if (props.variant === 'tagList') {
    borderRadius = '6px'
    fallbackImageSrc = LINK_IMG_TAG
    bgImg = ''
    bgSize = ''
    BgColor = ''
  }
  if (props.variant === 'wsList') {
    borderRadius = '6px'
    fallbackImageSrc =
      'https://cdn.iconscout.com/icon/free/png-256/workspace-1467359-1240505.png'
    bgImg = ''
    bgSize = ''
    BgColor = ''
  }
  if (props.variant === 'prog') {
    borderRadius = '6px'
    size = breakPoint
    bgImage = bgProgAvatar
    px = paddingAvatar
    py = paddingAvatar
    fallbackImageSrc =
      'https://cdn.iconscout.com/icon/free/png-256/workspace-1467359-1240505.png'
    bgImg = ''
    bgSize = ''
    BgColor = ''
  }
  if (props.variant === 'user') {
    size = breakPoint
    bgImage = bgUserAvatar
    px = paddingAvatar
    bgImg = bgReactIcon
    bgSize = 'contain'
    BgColor = getBgColorByUserName(props?.name ?? 'a')
  }
  if (props.variant === 'userNoBoxBg') {
    size = breakPoint
    bgImage = ''
    px = paddingAvatar
    bgImg = bgReactIcon
    bgSize = 'contain'
    BgColor = getBgColorByUserName(props?.name ?? 'a')
  }
  //l'avatar chakra non custom
  if (props.isChakraAvatar) {
    return (
      <Box position={'relative'}>
        <ChakraAvatar
          // size={size}
          src={props.src ?? fallbackImageSrc}
          {...rest}
          className={isAdmin ? 'gradientAvatar' : ''}

          // color={colorText}
          // bgImage={bgImg}
          // bgSize={bgSize}
          // bgColor={BgColor}
        ></ChakraAvatar>
      </Box>
    )
  }
  if (props.variant === 'user' || props.variant === 'prog') {
    if (!props.src) {
      // //console.log('props', rest)
      bgAvatarColor = 'gray.400'
    }
    return (
      <Box
        bgImage={bgImage}
        bgPosition="center"
        bgSize="cover"
        px={px}
        py={py}
        position="relative"
      >
        <Box
          className={isAdmin ? 'gradientAvatar' : ''}
          w={'auto'}
          rounded={'full'}
        >
          <ChakraAvatar
            borderRadius={borderRadius}
            size={size}
            src={props.src ?? fallbackImageSrc}
            {...rest}
            color={colorText}
            bgImage={bgImg}
            bgSize={bgSize}
            bgColor={BgColor}
          ></ChakraAvatar>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box bgImage={bgImage} bgPosition="center" bgSize="cover" px={px} py={py}>
        <Box w={'auto'} rounded={'full'}>
          <ChakraAvatar
            borderRadius={borderRadius}
            className={isAdmin ? 'gradientAvatar' : ''}
            size={size}
            {...rest}
            color={colorText}
            bgImage={bgImg}
            bgSize={bgSize}
            bgColor={isAdmin ? '' : BgColor}
          ></ChakraAvatar>
        </Box>
      </Box>
    )
  }
}
export default Avatar
