import {ArrowDownIcon, ArrowUpIcon} from '@chakra-ui/icons'
import {useState} from 'react'
import {Box, Button, Flex, Select} from '../ui'

export default function NuageFiltre({
  setButtonCategoryId,
  buttonCategoryId,
  setButtonSort,
  buttonSort,
  dataMapButton,
}) {
  const [selectedItem, setSelectedItem] = useState('')
  const handleFilter = item => {
    setSelectedItem(item)
    setButtonCategoryId(item)
  }
  const handleSortTypeChange = e => {
    setButtonSort(!buttonSort)
  }
  return (
    <Box
      maxWidth={{base: '100%', md: '100%', xl: 'auto'}}
      overflowX={'scroll'}
      id="filterBoxScroll"
    >
      <Flex
        justifyContent={'flex-start'}
        flexDirection={'row'}
        flexWrap={{base: 'nowrap', md: 'wrap'}}
        gap={2}
        alignItems={'center'}
        width={'fit-content'}
      >
        <Button
          variant="outline"
          minH={'40px'}
          rightIcon={buttonSort ? <ArrowDownIcon /> : <ArrowUpIcon />}
          onClick={handleSortTypeChange}
        >
          Date
        </Button>
        <Button
          minH={'40px'}
          variant={buttonCategoryId ? 'outline' : 'solid'}
          onClick={() => handleFilter('')}
          display={{base: 'none', md: 'flex'}}
        >
          Tout
        </Button>

        {dataMapButton?.items.map((e, index) => (
          <Button
            minH={'40px'}
            variant={e.id === selectedItem ? 'solid' : 'outline'}
            key={index}
            onClick={() => handleFilter(e.id)}
            display={{base: 'none', md: 'flex'}}
          >
            {e.name}
          </Button>
        ))}

        <Select
          minH={'40px'}
          variant={'outline'}
          display={{base: 'block', md: 'none'}}
          onChange={e => handleFilter(e.target.value)}
        >
          <option value={''} key={'all-categorie'}>
            Tout
          </option>
          {dataMapButton?.items.map(item => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      </Flex>
    </Box>
  )
}
