import {Error500} from '../routes/common/Error500'
type ErrorFallbackTypes = {
  error: any
  resetErrorBoundary: any
}
function ErrorFallback({error, resetErrorBoundary}: ErrorFallbackTypes) {
  console.warn('error boundary ', error)

  const graphQLError = error?.errors?.[0]?.message

  return (
    <>
      <Error500
        error={error?.message}
        graphQlerrors={graphQLError}
        resetEr={resetErrorBoundary}
      />
      {/*}  <Box w="full">
      <Center mt={16} mb={8}>
        <Heading px={4} textAlign="center">
          Une erreur est survenue !
        </Heading>
      </Center>
      <Flex w="full" justifyContent="center">
        <Box w="full" maxW="1280px" p={4}>
          <Card
            title="error500"
            data={
              <HStack w="full">
                <Image
                  ml={-12}
                  mb={-20}
                  maxW={'320px'}
                  src="/images/astronote500.png"
                  display={{base: 'none', md: 'flex'}}
                />
                <Stack spacing={8} mr={-12} w="full">
                  <Center mt={4}>
                    <Icon as={FiAlertOctagon} w={8} h={8} color="red.500" />
                    <Heading
                      ml={2}
                      color="red.500"
                      fontSize={{base: 'xl', md: '2xl'}}
                    >
                      Erreur 500
                    </Heading>
                  </Center>
                  <Center>
                    <Text>{error.message}</Text>

                    {graphQLError && <Code>{graphQLError}</Code>}
                  </Center>
                  <Flex w="full" justifyContent={'flex-end'}>
                    <Link to="/" as={DomRouterLink}>
                      <Button onClick={resetErrorBoundary}>
                        Retour à l'Accueil
                      </Button>
                    </Link>
                  </Flex>
                </Stack>
              </HStack>
            }
          />
        </Box>
      </Flex>
      <Image
        zIndex={9999}
        // ml={-12}
        mt={-10}
        minH={'350px'}
        maxW={'330px'}
        src="/images/astronote500.png"
        display={{base: 'flex', md: 'none'}}
    </Box>
      />*/}
    </>
  )
}

export default ErrorFallback
