import {default as ReactMarkdownOriginal} from 'react-markdown'
import {ReactMarkdownOptions} from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {
  nightOwl,
  oneLight,
} from 'react-syntax-highlighter/dist/esm/styles/prism'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import {Text} from './Text'
import {useColorModeValue} from '@chakra-ui/react'

type ReactMarkDownProps = {
  noOfLines?: number
  hideCode?: boolean
  hideImg?: boolean
  isTextTheme?: boolean
  skipHtml?: boolean
} & ReactMarkdownOptions

export const ReactMarkDownChakra = ({
  noOfLines = 0,
  hideCode = false,
  hideImg = false,
  isTextTheme = false,
  skipHtml = false,
  children,
  ...props
}: ReactMarkDownProps) => {
  const codeTheme = useColorModeValue(oneLight, nightOwl)

  /* Text theme : No img, no code , only text*/
  const textTheme = {
    p: (props: any) => {
      const {children} = props
      return (
        <Text
          mb={2}
          noOfLines={noOfLines}
          cursor="pointer"
          variant={'questionText'}
        >
          {children}
        </Text>
      )
    },
    img(props: any) {
      return null
    },
    code({node, inline, className, children, ...props}: any) {
      return null
    },
    h1({children}: any) {
      return <p>{children}</p>
    },
    h2({children}: any) {
      return <p>{children}</p>
    },
    h3({children}: any) {
      return <p>{children}</p>
    },
    h4({children}: any) {
      return <p>{children}</p>
    },
    h5({children}: any) {
      return <p>{children}</p>
    },
    h6({children}: any) {
      return <p>{children}</p>
    },
  }

  const defaultTheme = {
    p: (props: any) => {
      const {children} = props
      return (
        <Text
          mb={2}
          noOfLines={noOfLines}
          cursor="pointer"
          variant={'questionText'}
        >
          {children}
        </Text>
      )
    },
    img(props: any) {
      return hideImg === true ? null : <img {...props} alt=""></img>
    },
    code({node, inline, className, children, ...props}: any) {
      const match = /language-(\w+)/.exec(className || '')
      if (hideCode === true) return null
      return !inline && match ? (
        <SyntaxHighlighter
          children={String(children).replace(/\n$/, '')}
          style={codeTheme}
          language={match[1]}
          PreTag="div"
          wrapLongLines={true}
          {...props}
        />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      )
    },
  }

  return (
    <ReactMarkdownOriginal
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      // @todo disable because chakra table compile error
      components={ChakraUIRenderer(
        isTextTheme === true ? textTheme : defaultTheme,
      )}
      skipHtml={skipHtml}
      {...props}
    >
      {children ?? ''}
    </ReactMarkdownOriginal>
  )
}

export default ReactMarkDownChakra
