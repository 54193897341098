import {
  textButtonColor,
  hoverTransparentBgLight,
  hoverTransparentBgDark,
  borderBottomColor,
  alertColor,
  primaryColorDark,
  primaryColorLight,
} from '../app-constantes'

type Dict = Record<string, any>
//https://chakra-ui.com/docs/theming/component-style
const Button = {
  // 1. We can update the base styles
  baseStyle: (props: Dict) => ({
    fontWeight: 'semibold', // Normally, it is "semibold"
    color: textButtonColor(props.colorMode),
    px: 4, // <-- these values are tokens from the design system
    py: 3, // <-- these values are tokens from the design system
  }),
  // 2. We can add a new button size or extend existing
  // sizes: {
  //   sm: {
  //     fontSize: 'sm',
  //     px: 3, // <-- px is short for paddingLeft and paddingRight
  //     py: 2, // <-- py is short for paddingTop and paddingBottom
  //   },
  //   md: {
  //     fontSize: 'md',
  //     px: 4, // <-- these values are tokens from the design system
  //     py: 3, // <-- these values are tokens from the design system
  //   },
  //   lg: {
  //     fontSize: 'lg',
  //     px: 5, // <-- these values are tokens from the design system
  //     py: 4, // <-- these values are tokens from the design system
  //   },
  // },
  // 3. We can add a new visual variant
  variants: {
    solid: (props: Dict) => ({}),
    outline: (props: Dict) => ({
      color: props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      borderColor:
        props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      _hover: {
        bgColor:
          props.colorMode === 'dark'
            ? hoverTransparentBgDark
            : hoverTransparentBgLight,
      },
    }),
    ghost: (props: Dict) => ({
      color: props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      px: 0,
      py: 0,
      _hover: {
        bgColor: 'transparent',
      },
    }),
    link: (props: Dict) => ({}),
    unstyled: (props: Dict) => ({}),
    ovale: (props: Dict) => ({
      rounded: 'full',
      bgColor: 'transparent',
      color: props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      border: '1px',
      borderColor:
        props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      _hover: {
        bgColor:
          props.colorMode === 'dark'
            ? hoverTransparentBgDark
            : hoverTransparentBgLight,
      },
    }),
    disable: (props: Dict) => ({
      bgColor: 'transparent',
      color: borderBottomColor(props.colorMode),
      border: '1px',
      borderColor: borderBottomColor(props.colorMode),
      cursor: 'not-allowed',
    }),
    isblock: (props: Dict) => ({
      bg: 'transparent',
      border: '1px',
      color: alertColor(props.colorMode),
      borderColor:
        props.colorMode === 'dark' ? primaryColorDark : primaryColorLight,
      _hover: {
        bgColor:
          props.colorMode === 'dark'
            ? hoverTransparentBgDark
            : hoverTransparentBgLight,
      },
    }),
    // T'as vu Micka, il y a plein de style qu'on peut custom au besoin ou en recréer d'autres
  },
}

export default Button
