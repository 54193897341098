import * as React from 'react'
import {Box, Button, Flex, Stack} from '../ui'
import {useAuthDetails, useAuthUserId} from '../../context/AuthContext'
import {
  CreateFAQConsultationInput,
  ModelSortDirection,
  Question,
  UpdateFAQConsultationInput,
} from '../../commons/types/API'
import ModalContainer from 'react-modal-promise'
import {useColorModeValue, useDisclosure} from '@chakra-ui/react'
import {ModalForm} from '../Modals'
import ConsultationForm from '../Forms/ConsultationForm'
import {useCreateConsultation} from '../../commons/api/hooks'
import {useDeleteQuestion} from '../../commons/api/hooks/questions'
import QuestionForm from '../Forms/QuestionForm'
import {QuestionCard} from './questionCard'
import {HeaderTable} from '../Table/HeaderTable'
import {PAGINATION_ADMIN_TABLE_QUESTION} from '../../commons/constantes'
import {EStatus} from '../../types/graphQL'
import Pagination from '../Table/Pagination'
import {useNavigate, useSearchParams} from 'react-router-dom'
import SelectStatus from '../Selects/select-status'
import {useOpenPopupDelete} from '../../hooks/commonActions'
import {
  useOpenQuestion,
  useQuestionWithFilterAndStatusOrFilterOnly,
  useSaveQuestion,
} from '../../hooks/questionsActions'
import {sortByDate} from '../../commons/helpers/dataHelper'
import {
  buildFilterByQuestionsSchoolId,
  buildFilterSearchQuestion,
} from '../../commons/helpers/graphQLHelper'
import {ROUTE_ADMIN_FAQ_CREATE_CONSULTATION} from '../../constantes'
import {SearchType} from '../../types'
import {stringToEStatus} from '../../commons/helpers/screen'
import {AlertInfo} from '../ui/Alert'

export const QuestionsTabPanel = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const statusQuery = searchParams.get('status')

  const {schoolId} = useAuthDetails()
  const borderBottomColor = useColorModeValue('gray.100', 'gray.500')
  const [filterStatus, setFilterStatus] = React.useState<string>(
    stringToEStatus(statusQuery ?? EStatus.NEW),
  )
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const limit = PAGINATION_ADMIN_TABLE_QUESTION
  const uid = useAuthUserId()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [sortDirection] = React.useState<ModelSortDirection>(
    ModelSortDirection.ASC,
  )

  const [searchType, setSearchType] = React.useState(
    SearchType.STATUS_AND_FILTER,
  )

  const {hasNext, hasPrev, prev, next, questions} =
    useQuestionWithFilterAndStatusOrFilterOnly(searchType, {
      status: filterStatus,
      limit: searchTerm ? 10000 : limit,
      filter: {
        ...buildFilterSearchQuestion(searchTerm),
        ...buildFilterByQuestionsSchoolId(schoolId),
      },
      sortDirection: sortDirection
        ? ModelSortDirection.DESC
        : ModelSortDirection.ASC,
    })

  const mutationCreate = useCreateConsultation({onSuccess: onClose})
  const mutationDeleteQuestion = useDeleteQuestion()

  const handleModalSaveConsultation = (
    values: UpdateFAQConsultationInput & CreateFAQConsultationInput,
  ) => {
    mutationCreate.mutate(values)
  }

  const questionsItems = React.useMemo(
    () => questions?.items,
    [questions?.items],
  )
  const {
    isEdit,
    selectedQuestion,
    isOpen: isOpenQuestion,
    onOpen: onOpenQuestion,
    onClose: onCloseQuestion,
    handleOpenUpdateQuestion,
    handleOpenNewQuestionWithEditor,
    handleViewQuestion,
  } = useOpenQuestion({questions})

  const {handleSaveQuestion, isLoading} = useSaveQuestion({
    onSuccess: onCloseQuestion,
    isEdit,
  })

  const handleRedirectNewConsultation = () => {
    navigate(`${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}`)
  }

  const handleDelete = useOpenPopupDelete({
    allData: questionsItems,
    mutationDelete: mutationDeleteQuestion,
    children: <div>Souhaitez vous vraiment supprimer la question</div>,
  })

  const handleStatusChange = (e: React.ChangeEvent<any>) => {
    setFilterStatus(e.target.value)
    if (!e.target.value && searchType === SearchType.STATUS_AND_FILTER) {
      setSearchType(SearchType.FILTER_ONLY)
    }
    if (e.target.value && searchType === SearchType.FILTER_ONLY) {
      setSearchType(SearchType.STATUS_AND_FILTER)
    }
  }
  React.useEffect(() => {
    setSearchParams({status: filterStatus})
  }, [filterStatus, setSearchParams])

  const handleSearchTerm = (value: string): void => {
    setSearchTerm(value)
  }

  const questionsSorted = sortByDate({data: questionsItems})

  return (
    <>
      <Box>
        <Stack spacing={4}>
          <HeaderTable
            util="questions-admin"
            onAdd={handleRedirectNewConsultation}
            onSearch={handleSearchTerm}
          />
          <SelectStatus
            label="Filtrer par status"
            name="status"
            id="status"
            onChange={handleStatusChange}
            value={filterStatus as string}
            // errorMessage={formik?.errors?.status}
          />
          {questions?.items?.length === 0 && (
            <AlertInfo
              title={`Pas de questions trouvées pour ce statu  ${filterStatus}`}
              description={`Vous pouvez essayer avec un autre statu ou enlever le filtre`}
            ></AlertInfo>
          )}
          {questionsSorted?.map((question, idx) => (
            <Box
              key={idx}
              borderBottom={'1px'}
              borderColor={borderBottomColor}
              _last={{borderBottom: 'none'}}
            >
              <QuestionCard
                question={question}
                onUpdateQuestion={handleOpenUpdateQuestion}
                onDeleteQuestion={handleDelete}
                onViewQuestion={handleViewQuestion}
              />
            </Box>
          ))}
          <Pagination
            next={next}
            prev={prev}
            hasNext={hasNext}
            hasPrev={hasPrev}
          />
        </Stack>
      </Box>
      <ModalForm
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        title={isEdit ? 'Modifier une consultation' : 'creer une consultation'}
      >
        <ConsultationForm
          isModeConsultationStep={false}
          onSubmit={handleModalSaveConsultation}
          onClose={onClose}
          isLoading={mutationCreate.isLoading}
        />
      </ModalForm>
      <ModalForm
        onOpen={onOpenQuestion}
        onClose={onCloseQuestion}
        isOpen={isOpenQuestion}
        title={isEdit ? 'Modifier une question' : 'Poser une question'}
      >
        <QuestionForm
          isModeConsultationStep={false}
          isEdit={isEdit}
          onSubmit={handleSaveQuestion}
          onClose={onCloseQuestion}
          //initialValues={selectedQuestion}
          initialValues={{
            questionCategoryId: selectedQuestion?.category?.id,
            questionAnswerId: selectedQuestion?.answer?.id,
            fAQConsultationQuestionsId: selectedQuestion?.faqConsultation?.id,
            //tagsData: selectedQuestion?.tags?.items?.map(q => q?.fAQTagID),
            tagsData: selectedQuestion?.tags?.items,
            ...(selectedQuestion as Question),
            //this pattern or inside the form ?
            questionAuthorId: (selectedQuestion?.author?.id as string) ?? uid,
          }}
          isLoading={isLoading}
        />
      </ModalForm>
      <ModalContainer />
      <Flex w="full" justifyContent={'flex-end'} mt={4}>
        <Button onClick={onOpen}>
          Creer une simple consultation (sans questions)
        </Button>
      </Flex>
      {/* <Flex w="full" justifyContent={'flex-end'} mt={4}>
        <Button onClick={handleRedirectNewConsultation}>
          Creer consultation via le process
        </Button>
      </Flex> */}
    </>
  )
}
