import {Divider} from '@chakra-ui/react'
import {Card} from '../Card/Card'
import {Text, Stack, Box} from './../ui'
import {UserCourses} from '../../commons/types/API'

type CardCoursProps = {
  userName?: any
  userCours?: (UserCourses | null)[] | undefined
}

export const CardCours = ({userName, userCours}: CardCoursProps) => {
  //console.log('userCours', userCours)
  return (
    <Card
      title="Cours suivis"
      data={
        <Stack spacing={4} w="full">
          <Text mb={2}>
            <b> {userName} </b>suit actuellement <b>{userCours?.length}</b>{' '}
            cours
          </Text>
          {userCours?.map((cour: UserCourses | null, idx: any) => (
            <Box key={idx} w="full">
              <Text variant="nameFormation">
                {cour?.course?.name ?? 'Pas de nom'}
              </Text>
              <Text variant="subFormation" mt={-1}>
                Formation proposée par <i>Mike Codeur</i>
              </Text>
              <Divider mt={4} />
            </Box>
          ))}
        </Stack>
      }
    />
  )
}

export default CardCours
