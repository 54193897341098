import * as Yup from 'yup'
import * as React from 'react'
import {
  CreateFAQCategoryInput,
  UpdateFAQCategoryInput,
} from '../../commons/types/API'
import {Button, FormInput, Stack, HStack, Flex} from '../ui'
import {useFormik} from 'formik'
import {
  useAuthDetails,
  useUserDetailSchoolGroupId,
} from '../../context/AuthContext'
import {AlertHiddenFields} from './AlertHiddenFields'

type FAQCategoriesFormProps = {
  initialValues: UpdateFAQCategoryInput
  onSubmit: (formValue: UpdateFAQCategoryInput & CreateFAQCategoryInput) => void
  onClose: () => void
  isLoading?: boolean
  isEdit?: boolean
}

export const FAQCategoriesForm: React.FC<FAQCategoriesFormProps> = ({
  initialValues,
  onClose,
  onSubmit,
  isLoading,
  isEdit,
}) => {
  // //console.log('log initialValues', initialValues)
  const {schoolGroupId, uid} = useAuthDetails()
  const SignInSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères!')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères!')
      .required('Champ obligatoire'),
    groupsCanAccess: Yup.array()
      .min(1, 'Il manque groupsCanAccess')
      .required('Champ obligatoire'),
    editors: Yup.array()
      .min(1, 'Il manque editor')
      .required('Champ obligatoire'),
    owner: Yup.string().test('owner', 'Il manque owner', value => !!value),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      name: initialValues?.name ?? '',
      // image: initialValues?.image ?? '',
      description: initialValues?.description ?? '',
      // courseSchoolId: initialValues?.courseSchoolId ?? '',
      // urlProduct: initialValues?.urlProduct ?? '',
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (values: UpdateFAQCategoryInput & CreateFAQCategoryInput) => {
      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  //console.log('ormik.values?.topicWorkspaceId', formik.values?.topicWorkspaceId)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing="2">
        <FormInput
          placeholder="Générale"
          type="name"
          label="Nom de la categorie"
          name="name"
          id="name"
          onChange={formik.handleChange}
          value={formik.values?.name}
          errorMessage={formik?.errors?.name}
        />
        <FormInput
          placeholder="description ..."
          type="description"
          label="Description"
          name="description"
          id="description"
          onChange={formik.handleChange}
          value={formik.values?.description}
          errorMessage={formik?.errors?.description}
        />
        <Flex justifyContent="flex-end" w="full">
          <HStack>
            <Button variant="outline" onClick={onClose}>
              Annuler
            </Button>
            <Button type="submit" fontSize="md" isLoading={isLoading}>
              {isEdit ? 'Modifier' : 'Valider'}
            </Button>
          </HStack>
        </Flex>
      </Stack>
      <AlertHiddenFields
        formik={formik}
        fields={['owner', 'editors', 'groupsCanAccess']}
      />
    </form>
  )
}
