import React from 'react'
import {Avatar, Box, Flex, Icon, IconButton, Stack} from '../ui'
import {
  CreateFAQCategoryInput,
  FAQTag,
  UpdateFAQCategoryInput,
} from '../../commons/types/API'

import {Table as ReactTable} from '../ui/ReactTable'
import {HeaderTable} from '../Table/HeaderTable'
import {Pagination} from '../Table/Pagination'
import {MdDelete, MdEdit} from 'react-icons/md'

import {modalYesNoPromise} from '../Modals/YesNo'
import ModalContainer from 'react-modal-promise'
import {ModalForm} from '../Modals'
import {useDisclosure, Checkbox} from '@chakra-ui/react'
import {
  useCreateFAQTag,
  useDeleteFAQTag,
  useFAQTags,
  useUpdateFAQTag,
} from '../../commons/api/hooks'
import {FAQTagsForm} from '../Forms/FAQTagsForm'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import {PAGINATION_ADMIN_TABLE_TAGS} from '../../commons/constantes'
import {LINK_IMG_TAG} from '../../constantes'
//pagination https://dev.to/onlybakam/implementing-pagination-with-aws-appsync-5d08
export const TagsTabPanel = () => {
  const [selectedTag, setSelectedTag] = React.useState<FAQTag>()
  //pagination

  const {
    isLoading: isLoadingTags,
    FAQTags,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    nextToken,
  } = useFAQTags({limit: PAGINATION_ADMIN_TABLE_TAGS})

  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const mutationDelete = useDeleteFAQTag()
  const mutationCreate = useCreateFAQTag({onSuccess: onClose})
  const mutationUpdate = useUpdateFAQTag({onSuccess: onClose})

  const data = React.useMemo(
    () => [...((FAQTags?.items as Array<FAQTag> | []) ?? [])],
    [FAQTags],
  )

  const handleModalSave = (
    values: UpdateFAQCategoryInput & CreateFAQCategoryInput,
  ) => {
    if (isEdit) {
      mutationUpdate.mutate(values)
    } else {
      mutationCreate.mutate(values)
    }
  }

  const handleDelete = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()

      modalYesNoPromise({
        title: 'Supprimer',
        confirmLabel: 'Supprimer',
        data: data.find(elm => elm.id === value),
        labelOfType: 'prog',
        children: <div>Souhaitez vous vraiment supprimer le tag ?</div>,
      })
        .then(() => {
          mutationDelete.mutate(value)
        })
        .catch(() => {})
    },
    [data, mutationDelete],
  )

  const handleOpenUpdate = React.useCallback(
    (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      //console.log('value', value)

      const course = data.find(elm => elm.id === value)
      setSelectedTag(course)
      setIsEdit(true)
      onOpen()
    },
    [data, onOpen],
  )

  const handleOpenNew = React.useCallback(() => {
    setSelectedTag(undefined)
    setIsEdit(false)
    onOpen()
  }, [onOpen])

  const columns = React.useMemo(
    () => [
      // {
      //   id: 'selection',
      //   accessor: 'selection',
      //   // gestion de la checkbox du header : SelectAll ligne
      //   // The header can use the table's getToggleAllRowsSelectedProps method
      //   // to render a checkbox
      //   Header: ({getToggleAllRowsSelectedProps}: any) => {
      //     const {indeterminate, ...rest} = getToggleAllRowsSelectedProps()
      //     return <Checkbox isIndeterminate={indeterminate ? 1 : 0} {...rest} />
      //   },
      //   // checkbox seletion d'une ligne
      //   // The cell can use the individual row's getToggleRowSelectedProps method
      //   // to the render a checkbox
      //   Cell: ({row}: any) => {
      //     //to avoid warning on indeterminate boolean
      //     const {indeterminate, ...rest} = row.getToggleRowSelectedProps()
      //     return <Checkbox isIndeterminate={indeterminate ? 1 : 0} {...rest} />
      //   },
      // },
      {
        Header: 'image',
        accessor: 'image',
        Cell: ({row}: any) => {
          return (
            <Box>
              <Avatar
                src={row.values.image || LINK_IMG_TAG}
                mr={2}
                variant="tagList"
              />
            </Box>
          )
        },
      },

      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'Supprimer',
        accessor: 'id',
        Cell: ({cell: {value}}: any) => (
          <Flex justifyContent="flex-end" w="full">
            <IconButton
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdEdit} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) => handleOpenUpdate(e, value)}
            />
            <IconButton
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdDelete} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) => handleDelete(e, value)}
            />
          </Flex>
        ),
      },
    ],
    [handleDelete, handleOpenUpdate],
  )

  //Props spécifiques pour chaque cellule <Td>
  const cellProps = (cellInfo: any) => {
    switch (cellInfo.column.id) {
      case 'selection':
        return {
          _position: 'select',
          util: 'user',
        }
      case 'image':
        return {
          _position: 'avatar',
          util: 'user',
        }
      case 'name':
        return {
          _position: 'name',
          util: 'user',
        }
      case 'id':
        return {
          _position: 'actions',
          util: 'user',
        }
    }
  }

  //Props pour chaque ligne <Tr>
  // const rowProps = (row: any) => ({})

  return (
    <>
      <Stack spacing={4}>
        <HeaderTable util="tags" onAdd={handleOpenNew} />

        {!isLoadingTags ? (
          <ReactTable
            // rowProps={rowProps}
            cellProps={cellProps}
            columns={columns}
            data={data}
            controlledPageCount={0}
            controlledPageIndex={0}
            isLoading={false}
            isFetching={false}
            onFetchData={function (): void {
              // throw new Error('Function not implemented.')
            }}
            setPageIndex={function (): void {
              //throw new Error('Function not implemented.')
            }}
            setSelectedRowsId={function (selectedRowIds: any): void {
              //console.log('selectedRowIds', selectedRowIds)
            }}
            totalCount={0}
            labelData={'Consultations'}
            identifier={'id'}
            controlledHiddenColumns={[]}
            initialSortBy={[]}
            hideHeader={false}
          />
        ) : (
          <TextRowSkeleton />
        )}

        <Pagination
          next={nextPage}
          prev={prevPage}
          hasNext={hasNextPage}
          hasPrev={hasPrevPage}
        />
      </Stack>
      <ModalForm
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        title={isEdit ? 'Modifier un tag' : 'Ajouter un tag'}
      >
        <FAQTagsForm
          isEdit={isEdit}
          initialValues={{
            ...(selectedTag as FAQTag),
          }}
          onSubmit={handleModalSave}
          onClose={onClose}
          isLoading={mutationCreate.isLoading || mutationUpdate.isLoading}
        />
      </ModalForm>
      <ModalContainer />
    </>
  )
}

export default TagsTabPanel
