import * as React from 'react'
import {Box, Divider, Link, Stack, Text, Button} from '../ui'
import ModalContainer from 'react-modal-promise'
import {ModalForm} from '../Modals'
import ConsultationForm from '../Forms/ConsultationForm'
import {
  useConsultationsBySchool,
  useDeleteConsultation,
} from '../../commons/api/hooks'
import HeaderTable from '../Table/HeaderTable'
import {ConsultationCard} from './consultationCard'
import {PAGINATION_ADMIN_CONSULTATIONS_DISPLAY} from '../../commons/constantes'
import {
  Link as DomRouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import {useOpenPopupDelete} from '../../hooks/commonActions'
import {
  useOpenConsultation,
  useSaveConsultation,
} from '../../hooks/constulationActions'
import Pagination from '../Table/Pagination'
import {useAuthDetails} from '../../context/AuthContext'
import {buildFilterSearchQuestion} from '../../commons/helpers/graphQLHelper'
import SelectStatus from '../Selects/select-status'
import {EStatus} from '../../types/graphQL'
import {ModelSortDirection} from '../../commons/types/API'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import {AlertInfo} from '../ui/Alert'
import {stringToEStatus} from '../../commons/helpers/screen'

export const ConsultationsTab = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const statusQuery = searchParams.get('constationstatus')
  const {schoolId} = useAuthDetails()
  const mutationDelete = useDeleteConsultation()
  const [filterStatus, setFilterStatus] = React.useState<string>(
    stringToEStatus(statusQuery ?? EStatus.PUBLISHED),
  )
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const limit = PAGINATION_ADMIN_CONSULTATIONS_DISPLAY
  const filter = React.useMemo(() => {
    if (filterStatus) {
      return {
        status: {eq: filterStatus},
        ...buildFilterSearchQuestion(searchTerm),
      }
    } else {
      return {
        ...buildFilterSearchQuestion(searchTerm),
      }
    }
  }, [filterStatus, searchTerm])

  const {
    isLoading: isLoadingConsultations,
    consultations,
    hasNextPage,
    hasPrevPage,
    nextPage,
    prevPage,
    resetPages,
  } = useConsultationsBySchool({
    sortDirection: ModelSortDirection.DESC,
    limit: searchTerm ? 10000 : limit,
    consultationSchoolId: schoolId,
    filter,
  })
  const handleSearch = (value: string) => {
    setSearchTerm(value)
    resetPages()
  }

  const {
    isEdit,
    selectedConsultation,
    isOpen,
    onOpen,
    onClose,
    handleOpenNewConsultation,
    handleOpenUpdateConsultation,
  } = useOpenConsultation({consultations})

  const {handleSaveConsultation, isLoading: isLoadingMutation} =
    useSaveConsultation({
      isEdit,
      onSuccess: onClose,
    })

  const handleDelete = useOpenPopupDelete({
    allData: consultations?.items,
    mutationDelete,
    children: (
      <div>
        Souhaitez vous vraiment supprimer la consultion ? Attention, cela ne
        supprimera pas les questions lié a la consultations
      </div>
    ),
  })

  const handleStatusChange = (e: React.ChangeEvent<any>) => {
    setFilterStatus(e.target.value)
    if (hasPrevPage) {
      resetPages()
    }
  }
  // React.useEffect(() => {
  //   setSearchParams({constationstatus: filterStatus})
  // }, [filterStatus, setSearchParams])

  const hasConsultations = !!consultations?.items?.length

  return (
    <Box>
      <Stack>
        <Box mb={4}>
          <HeaderTable
            util="consultation"
            onAdd={handleOpenNewConsultation}
            onSearch={handleSearch}
          />
          <SelectStatus
            label="Filtrer par status"
            name="status"
            id="status"
            onChange={handleStatusChange}
            value={filterStatus as string}
          />
        </Box>
        {isLoadingConsultations && <TextRowSkeleton />}
        {!isLoadingConsultations && !hasConsultations && (
          <Box my={2}>
            <AlertInfo
              title={'Aucune consultation trouvé pour le moment'}
              description={
                'Vous pouvez créer une consultation sujet en cliquant sur le bouton "Créer une consultation"'
              }
            ></AlertInfo>
          </Box>
        )}
        {!isLoadingConsultations && hasConsultations && (
          <>
            {consultations?.items?.map((consult, idx) => (
              <Box key={idx}>
                <ConsultationCard
                  idx={idx}
                  showFirstQuestion={true}
                  consultation={consult}
                  onUpdateConsultation={handleOpenUpdateConsultation}
                  onDeleteConsultation={handleDelete}
                />
              </Box>
            ))}
          </>
        )}
        <Pagination
          next={nextPage}
          prev={prevPage}
          hasNext={hasNextPage}
          hasPrev={hasPrevPage}
        />
      </Stack>
      <ModalForm
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        title={isEdit ? 'Modifier une consultation' : 'creer une consultation'}
      >
        <>
          {isEdit && (
            <>
              <Button
                variant="solid"
                onClick={() => {
                  navigate(
                    `/school-admin/faq/create-consultation/create/${selectedConsultation?.id}`,
                  )
                }}
              >
                Modifier via le process de consultation
              </Button>
              <Divider mt={15} mb={5}></Divider>
            </>
          )}

          <ConsultationForm
            isEdit={isEdit}
            isModeConsultationStep={false}
            onSubmit={handleSaveConsultation}
            onClose={onClose}
            isLoading={isLoadingMutation}
            initialValues={isEdit ? undefined : selectedConsultation}
          />
        </>
      </ModalForm>

      <ModalContainer />
    </Box>
  )
}
