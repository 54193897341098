import {Container, ContainerPage} from '../../../components/ui'
import SchoolEditForm from '../../../components/Forms/SchoolEditForm'
import {useSchool, useUpdateSchool} from '../../../commons/api/hooks'
import {useAuth} from '../../../context/AuthContext'
import {SchoolDetailSkeleton} from '../../../components/Skeletons'

export const SchoolSettings = () => {
  const {authUser} = useAuth()

  const {school} = useSchool(authUser?.me?.userSchoolId as string)

  const mutation = useUpdateSchool()
  const handleSubmit = (data: any) => {
    mutation.mutate(data)
  }

  if (!school) {
    return <SchoolDetailSkeleton></SchoolDetailSkeleton>
  }
  return (
    <ContainerPage>
      <Container variant="generalBg" maxW="full">
        <SchoolEditForm
          onSubmit={handleSubmit}
          initialValues={school}
          isLoading={mutation.isLoading}
        />
      </Container>
    </ContainerPage>
  )
}
