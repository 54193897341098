import {VStack} from '@chakra-ui/react'
import {useCallback, useEffect, useState} from 'react'
import {displayNameForAvatar, displayNameInitial} from '../commons'
import {
  useAddUserToChatRoom,
  useCreateChatMessage,
  useCreateChatRoom,
  useDeleteUserToChatRoom,
} from '../commons/api/hooks/messagerie'
import {
  ChatRoom,
  CreateChatRoomInput,
  DeleteUserChatRoomInput,
  User,
  UserChatRoom,
} from '../commons/types/API'
import {modalYesNoPromise} from '../components/Modals/YesNo'
import {Avatar, Text} from '../components/ui'
import {useAuth} from '../context/AuthContext'

type useCreateChatRoomWithUserProps = {
  onSuccess: () => void
  userId: string
}
type CreateRoomUserInput = {
  values: CreateChatRoomInput
  message?: string
}
/**
 * @deprecated preferer useCreateRoomAndAddUsers
 * @param param0
 * @returns
 */
export const useCreateChatRoomWithUser = ({
  onSuccess,
  userId,
}: useCreateChatRoomWithUserProps) => {
  const mutationAddUserRoom = useAddUserToChatRoom({
    onSuccess: onSuccess,
  })

  const handleCreateSuccess = (data?: ChatRoom) => {
    mutationAddUserRoom.mutate({
      chatRoomId: data?.id ?? '',
      userId: userId as string,
      groupsCanAccess: [],
    })
  }

  const mutationCreateRoom = useCreateChatRoom({
    onSuccess: handleCreateSuccess,
  })

  const handleCreateChatRooWithUser = ({values}: CreateRoomUserInput) => {
    mutationCreateRoom.mutate(values)
  }
  return {
    handleCreateChatRooWithUser,
    isLoading: mutationCreateRoom.isLoading || mutationAddUserRoom.isLoading,
  }
}

type useAddToUsersRoomProps = {
  onSuccess: () => void
}
/**
 * @todo renommer cette fonction elle porte a confusion
 * elle ajoute pas seuelement des users a une room mais elle crée aussi la room
 * @param param0
 * @returns
 */
export const useCreateRoomAndAddUsers = ({
  onSuccess,
}: useAddToUsersRoomProps) => {
  const mutationAddRoom = useAddUserToChatRoom({
    onSuccess: onSuccess,
  })

  const handleCreateSuccess = (data?: ChatRoom) => {
    //ce n'est pas normal d'utiliser editor, ce n'est pas prevu pour cela
    // cependant on fait comme cela pour le moment
    data?.editors?.forEach(editor => {
      mutationAddRoom.mutate({
        chatRoomId: data?.id ?? '',
        userId: editor as string,
        groupsCanAccess: data?.groupsCanAccess,
        editors: data?.editors,
        owner: data?.owner,
      })
    })
  }

  const mutationCreateRoom = useCreateChatRoom({
    onSuccess: handleCreateSuccess,
  })

  const handleCreateChatRoomWithUser = ({values}: CreateRoomUserInput) => {
    mutationCreateRoom.mutate(values)
  }
  return {
    handleCreateChatRoomWithUser,
    isLoading: mutationCreateRoom.isLoading || mutationAddRoom.isLoading,
  }
}

type useCreateRoomAddUsersSendMessageProps = {
  onSuccess: () => void
}

export const useCreateRoomAddUsersSendMessage = ({
  onSuccess,
}: useCreateRoomAddUsersSendMessageProps) => {
  const [message, setMessage] = useState<string | undefined>('Bonjour !')
  const [createdChatRoom, setCreatedChatRoom] = useState<ChatRoom | undefined>()

  const onSuccessCreateRoom = (data?: ChatRoom) => {
    setCreatedChatRoom(data)
  }
  const mutationCreateMessage = useCreateChatMessage({
    onSuccess: onSuccess,
  })
  const mutationAddRoom = useAddUserToChatRoom()

  const handleSendMessage = useCallback(
    (chatRoom: ChatRoom) => {
      mutationCreateMessage.mutate({
        chatRoomMessagesId: chatRoom.id,
        message: message as string, //chatRoom.description as string,
        userMessagesId: chatRoom.owner as string,
        groupsCanAccess: chatRoom.groupsCanAccess,
        owner: chatRoom?.owner,
      })
    },
    [message, mutationCreateMessage],
  )
  const handleAddUserToRoom = useCallback(
    (data: ChatRoom) => {
      data?.editors?.forEach(editor =>
        mutationAddRoom.mutate(
          {
            chatRoomId: data?.id ?? '',
            userId: editor as string,
            groupsCanAccess: data?.groupsCanAccess,
            editors: data?.editors,
            owner: data?.owner,
          },
          {
            onSuccess: () => {
              handleSendMessage(data)
            },
          },
        ),
      )
    },
    [handleSendMessage, mutationAddRoom],
  )

  const mutationCreateRoom = useCreateChatRoom({
    onSuccess: onSuccessCreateRoom, //handleAddUserToRoom,
  })

  const handleCreateRoom = ({values, message}: CreateRoomUserInput) => {
    setMessage(message)
    mutationCreateRoom.mutate(values)
  }

  useEffect(() => {
    if (mutationCreateRoom.isSuccess) {
      mutationCreateRoom.reset()
      handleAddUserToRoom(createdChatRoom as ChatRoom)
    }
  }, [createdChatRoom, handleAddUserToRoom, mutationCreateRoom])

  return {
    handleCreateRoomAddUserSendMessage: handleCreateRoom,
    isLoading:
      mutationCreateRoom.isLoading ||
      mutationAddRoom.isLoading ||
      mutationCreateMessage.isLoading,
  }
}

type useRemoveUserFromRoomProps = {
  onSuccess: () => void
}
export const useRemoveUserFromRoom = ({
  onSuccess,
}: useRemoveUserFromRoomProps) => {
  const mutationRemoveUserFromRoom = useDeleteUserToChatRoom({
    onSuccess: onSuccess,
  })

  const handleRemoveUserFromRoom = (id: DeleteUserChatRoomInput) => {
    mutationRemoveUserFromRoom.mutate(id)
  }
  return {
    handleRemoveUserFromRoom,
    isLoading: mutationRemoveUserFromRoom.isLoading,
  }
}

type usePopupRemoveUserFromRoomProps = {
  onSuccess: () => void
}
export const usePopupRemoveUserFromRoom = ({
  onSuccess,
}: usePopupRemoveUserFromRoomProps) => {
  const {authUser} = useAuth()
  const {handleRemoveUserFromRoom, isLoading} = useRemoveUserFromRoom({
    onSuccess: onSuccess,
  })

  const handleQuitRoom = useCallback(
    (e: React.MouseEvent, userChatRoom: UserChatRoom) => {
      e.stopPropagation()

      const isAuthUser = authUser.me?.id === userChatRoom.userId

      modalYesNoPromise({
        title: 'messagerie',
        confirmLabel: 'Valider',
        infoMessage: isAuthUser ? 'Confirmation' : 'Suppression',
        children: (
          <VStack gap={1}>
            <Avatar
              variant={'prog'}
              src={userChatRoom?.user?.image ?? ''}
              name={displayNameForAvatar(userChatRoom?.user as User)}
              user={userChatRoom.user as User}
            />
            <Text>
              {isAuthUser
                ? 'Voulez vous quitter le groupe ?'
                : `Voulez vous supprimer l'utilisateur ${displayNameInitial(
                    userChatRoom?.user as User,
                  )}?`}
            </Text>
          </VStack>
        ),
      })
        .then(() => {
          handleRemoveUserFromRoom({
            id: userChatRoom.id,
          })
        })
        .catch((error: unknown) => {
          if (!error) {
            return
          }
          throw new Error(`Une erreur est survenue:${error}`)
        })
    },
    [handleRemoveUserFromRoom, authUser],
  )

  return {
    handleQuitRoom,
    isLoading,
  }
}
