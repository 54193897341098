import {CheckIcon, CloseIcon, DeleteIcon, EditIcon} from '@chakra-ui/icons'
import {useEditableControls, Flex, IconButton} from '@chakra-ui/react'

export const MessageAction = ({
  onDeleteMessage,
}: {
  onDeleteMessage: () => void
}) => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls()

  return isEditing ? (
    <Flex justifyContent="center" gap={1}>
      <IconButton
        aria-label="btn"
        icon={<CheckIcon />}
        size={'sm'}
        {...getSubmitButtonProps()}
      />
      <IconButton
        aria-label="btn"
        icon={<CloseIcon />}
        size={'sm'}
        colorScheme={'red'}
        {...getCancelButtonProps()}
      />
    </Flex>
  ) : (
    <Flex justifyContent="center" gap={1}>
      <IconButton
        aria-label="btn"
        icon={<DeleteIcon />}
        size={'sm'}
        onClick={() => onDeleteMessage()}
      />
      <IconButton
        aria-label="btn"
        size="sm"
        icon={<EditIcon />}
        {...getEditButtonProps()}
      />
    </Flex>
  )
}
