import React from 'react'
import {
  Avatar,
  Box,
  Container,
  Flex,
  Grid,
  Icon,
  IconButton,
  Link,
  Stack,
} from '../ui'
import ReactTable from '../ui/ReactTable'
import {User} from '../../commons/types/API'
import {HeaderTable} from '../Table/HeaderTable'
import {MdBlock, MdDelete, MdVisibility} from 'react-icons/md'
import {
  useUpdateUser,
  useCreateUser,
  useUpdateUserStatus,
  useUsersBySchoolId,
} from '../../commons/api/hooks/user'
import {modalYesNoPromise} from '../Modals/YesNo'
import ModalContainer from 'react-modal-promise'
import {useDisclosure, Checkbox, useBreakpoint} from '@chakra-ui/react'
import {ModalForm} from '../Modals'
import CardUser from '../Card/CardUser'
import {RegisterForm, RegisterFormValues} from '../Forms/RegisterForm'
import {usePagination} from '../../hooks/commonActions'
import {useAuth, useAuthDetails} from '../../context/AuthContext'
import {SignupType, UserActions, UserStatus} from '../../types/auth'
import {displayNameForAvatar, isAdminOrOwner} from '../../commons'
import {COURSES_PROD_DEFAULT, ROUTE_SCHOOL_USER} from '../../constantes'
import {Link as DomRouterLink} from 'react-router-dom'
import Pagination from '../Table/Pagination'
import {PAGINATION_ADMIN_USERS_DISPLAY} from '../../commons/constantes'
import {View} from '../../types/screen'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import {AlertInfo} from '../ui/Alert'
import {buildFilterSearchUser} from '../../commons/helpers/graphQLHelper'
import {useGlobalContext} from '../../context/GlobalContext'

export const StudentList = () => {
  const {uid, authUser, schoolId} = useAuthDetails()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [searchTerm, setSearchTerm] = React.useState('')
  const {next, prev, nextToken, setNextNextToken, hasNext, hasPrev, reset} =
    usePagination()
  const {users, isLoading} = useUsersBySchoolId({
    userSchoolId: schoolId,
    limit: searchTerm ? 10000 : PAGINATION_ADMIN_USERS_DISPLAY,
    nextToken: nextToken,
    filter: {
      ...buildFilterSearchUser(searchTerm),
      status: {eq: UserStatus.CREATED},
    },
  })

  React.useEffect(() => {
    setNextNextToken(users?.nextToken ?? null)
  }, [users, setNextNextToken])

  const filteredUsers = users?.items?.filter(
    user => user?.status !== UserStatus.DELETED,
  )

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    reset()
  }

  const mutationDelete = useUpdateUserStatus() //useDeleteUser()
  const mutationUpdate = useUpdateUser({onSuccess: onClose}) //Attention update le Current User ...
  const mutationCreate = useCreateUser({onSuccess: onClose}) //Attention update le Current User ...

  const [hiddenCol, setHiddenCol] = React.useState(['image'])

  const breakpoint = useBreakpoint()

  React.useEffect(() => {
    const contain = ['base', 'sm'].includes(breakpoint as string)
    contain === true
      ? setHiddenCol(['email', 'image'])
      : setHiddenCol(['image'])
  }, [breakpoint])

  const handleDisableEnable = React.useCallback(
    (e: React.MouseEvent, value: string, action: UserActions) => {
      e.stopPropagation()
      const isAdmin = isAdminOrOwner(authUser)
      const user = filteredUsers?.find(elm => elm?.id === value) as
        | User
        | undefined
      const dataUser = {name: user?.username, image: user?.image}

      const actionLabel =
        action === UserActions.DISABLE ? 'désactiver' : 'réactiver'
      modalYesNoPromise({
        title: `${actionLabel}`,
        confirmLabel: `${actionLabel}`,
        data: dataUser,
        labelOfType: 'user',
        children: (
          <div>⚠️ Souhaitez vous vraiment {actionLabel} cet utilisateur </div>
        ),
      })
        .then(ok => {
          // Mike todo : veux tu vraiment supprimer le user ?
          if (user?.id === uid) {
            throw new Error('Impossible de se supprimer')
          }
          if (!isAdmin) {
            throw new Error('Action réservé aux admin')
          }
          mutationDelete.mutate({
            user: user,
            status:
              action === UserActions.DISABLE
                ? UserStatus.DISABLED
                : UserStatus.CREATED,
          })
        })
        .catch(error => {
          console.error(error)
        })
    },
    [authUser, filteredUsers, uid, mutationDelete],
  )

  const handleDeleteUser = React.useCallback(
    (e: React.MouseEvent, value: string, action: UserActions) => {
      e.stopPropagation()
      const isAdmin = isAdminOrOwner(authUser)
      const user = filteredUsers?.find(elm => elm?.id === value) as
        | User
        | undefined
      const dataUser = {name: user?.username, image: user?.image}

      const actionLabel =
        action === UserActions.DISABLE ? 'supprimer' : 'réactiver'
      modalYesNoPromise({
        title: `${actionLabel}`,
        confirmLabel: `${actionLabel}`,
        data: dataUser,
        labelOfType: 'user',
        children: (
          <div>
            ❌❌❌ Souhaitez vous vraiment {actionLabel} cet utilisateur (action
            irreverssible) ❌❌❌
          </div>
        ),
      })
        .then(ok => {
          // Mike todo : veux tu vraiment supprimer le user ?
          if (user?.id === uid) {
            throw new Error('Impossible de se supprimer')
          }
          if (!isAdmin) {
            throw new Error('Action réservé aux admin')
          }
          mutationDelete.mutate({
            user: user,
            status:
              action === UserActions.DISABLE
                ? UserStatus.DELETED
                : UserStatus.CREATED,
          })
        })
        .catch(error => {
          console.error(error)
        })
    },
    [authUser, filteredUsers, uid, mutationDelete],
  )

  const [view, setView] = React.useState(View.grid)
  const {register, loading} = useAuth()
  const {setError: setGlobalError} = useGlobalContext()
  const handleModalSave = (data: RegisterFormValues) => {
    // Mike todo : ici je pense qu'on ne doit pas mettre à jour un user (enfin à toi de voir), mais surtout pouvoir créer un user, la fonction useCreateUser() dans hooks/user n'existait pas je l'ai créé vide...
    register({
      data: {
        registerData: {
          ...data,
          schoolId: schoolId ?? '',
          courses: COURSES_PROD_DEFAULT,
        },
        signupType: SignupType.STUDIENT,
        isCreationBackOffice: true,
      },
      onError: handleErrors,
    })
  }
  const handleErrors = (error: any) => {
    // console.log('handleErrors', error)
    setGlobalError(error.message ?? 'une erreur est survenue')
  }

  const columns = React.useMemo(
    () => [
      // {
      //   id: 'selection',
      //   accessor: 'selection',
      //   // gestion de la checkbox du header : SelectAll ligne
      //   // The header can use the table's getToggleAllRowsSelectedProps method
      //   // to render a checkbox
      //   Header: ({getToggleAllRowsSelectedProps}: any) => {
      //     const {indeterminate, ...rest} = getToggleAllRowsSelectedProps()
      //     return <Checkbox isIndeterminate={indeterminate ? 1 : 0} {...rest} />
      //   },
      //   // checkbox seletion d'une ligne
      //   // The cell can use the individual row's getToggleRowSelectedProps method
      //   // to the render a checkbox
      //   Cell: ({row}: any) => {
      //     //to avoid warning on indeterminate boolean
      //     const {indeterminate, ...rest} = row.getToggleRowSelectedProps()
      //     return <Checkbox isIndeterminate={indeterminate ? 1 : 0} {...rest} />
      //   },
      // },
      {
        Header: 'Nom',
        accessor: 'username',
        Cell: ({row}: any) => {
          return (
            <Flex align="center">
              <Avatar
                src={row.values.image}
                name={displayNameForAvatar(row.values)}
                mr={2}
                display={{base: 'none', md: 'flex'}}
              />
              {row.values.username}
            </Flex>
          )
        },
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'image',
        accessor: 'image',
      },
      {
        Header: 'actions',
        accessor: 'actions',
        Cell: ({row}: any) => (
          <Flex justifyContent="flex-end" w="full">
            {/* Lien vers UserProfil/id */}
            <Link
              as={DomRouterLink}
              to={`${ROUTE_SCHOOL_USER}/${row.original.id}`}
            >
              <IconButton
                size="sm"
                aria-label="Call Segun"
                variant="ghost"
                icon={
                  <Icon
                    variant="generalIcons"
                    as={MdVisibility}
                    color="gray.500"
                  />
                }
              />
            </Link>
            <IconButton
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon
                  variant="generalIcons"
                  as={MdBlock}
                  color={
                    row.original.status !== UserStatus.CREATED
                      ? 'red.500'
                      : 'gray.500'
                  }
                />
              }
              // Mike todo : Fonction bloquer User
              onClick={(e: React.MouseEvent) =>
                handleDisableEnable(
                  e,
                  row.original.id,
                  row.original.status === UserStatus.CREATED
                    ? UserActions.DISABLE
                    : UserActions.CREATE,
                )
              }
            />
            <IconButton
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdDelete} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) =>
                handleDeleteUser(e, row.original.id, UserActions.DISABLE)
              }
            />
          </Flex>
        ),
      },
    ],
    [handleDeleteUser, handleDisableEnable],
  )

  const cellProps = (cellInfo: any) => {
    switch (cellInfo.column.id) {
      case 'selection':
        return {
          _position: 'select',
          util: 'user',
        }
      case 'actions':
        return {
          _position: 'actions',
          util: 'user',
        }
      case 'email':
        return {
          _position: 'domaine',
          util: 'user',
        }
    }
  }
  const hasUsers = (users?.items?.length ?? 0) > 0
  //Props pour chaque ligne <Tr>
  // const rowProps = (row: any) => ({})
  return (
    <>
      <Container variant="generalBg" maxW="full">
        <Stack spacing={4}>
          <HeaderTable
            util="members"
            onAdd={onOpen}
            onSearch={handleSearch}
            view={view}
            setView={setView}
          />
          {isLoading && <TextRowSkeleton />}
          {!isLoading && !hasUsers && (
            <Flex p={4} alignItems="center" justifyContent="center" w={'100%'}>
              <AlertInfo
                title={'Aucun utilisateur trouvé'}
                description={'Aucun utilisateur trouvé !'}
              ></AlertInfo>
            </Flex>
          )}
          {!isLoading && hasUsers && view === View.list ? (
            <Box>
              <ReactTable
                // rowProps={rowProps}
                cellProps={cellProps}
                columns={columns}
                data={(filteredUsers ?? []) as User[]}
                controlledPageCount={0}
                controlledPageIndex={0}
                isLoading={isLoading}
                isFetching={false}
                onFetchData={function (options: any): void {
                  // throw new Error('Function not implemented.')
                }}
                setPageIndex={function (pageIndex: number): void {
                  //throw new Error('Function not implemented.')
                }}
                setSelectedRowsId={function (selectedRowIds: any): void {
                  // throw new Error('Function not implemented.')
                }}
                totalCount={0}
                labelData={'Eleves'}
                identifier={'id'}
                controlledHiddenColumns={hiddenCol}
                initialSortBy={[]}
                hideHeader={false}
              />
            </Box>
          ) : (
            <Box>
              <Grid
                templateColumns={{md: 'repeat(2,1fr)', xl: 'repeat(3, 1fr)'}}
                gap={4}
                mt={4}
              >
                {((filteredUsers ?? []) as User[]).map((user, idx) => (
                  <CardUser
                    key={idx}
                    user={user}
                    domaine={'dev FrontEnd'}
                    suppUser={(e: React.MouseEvent) =>
                      handleDeleteUser(e, user.id, UserActions.DISABLE)
                    }
                    disableUser={(e: React.MouseEvent) =>
                      handleDisableEnable(
                        e,
                        user.id,
                        user.status === UserStatus.CREATED
                          ? UserActions.DISABLE
                          : UserActions.CREATE,
                      )
                    }
                  />
                ))}
              </Grid>
            </Box>
          )}
          <Pagination
            next={next}
            prev={prev}
            current={nextToken}
            hasNext={hasNext}
            hasPrev={hasPrev}
            isLoading={false}
            hidden={!hasPrev && !hasNext}
          />
        </Stack>
        <ModalForm
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          title="Creation d'un user"
        >
          <RegisterForm
            initialValues={{
              name: '',
              username: '',
              password: 'Azerty123',
              passwordConfirmation: 'Azerty123',
              // userSchoolId: selectedUser?.userSchoolId ?? schoolId,
              // ...(selectedUser as User),
            }}
            onSubmit={(formValue: RegisterFormValues) =>
              handleModalSave(formValue)
            }
            // Mike todo
            isLoading={loading}
          />
        </ModalForm>
        <ModalContainer />
      </Container>
    </>
  )
}
export default StudentList
