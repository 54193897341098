import {Icon as ChakraIcon, IconProps} from '@chakra-ui/react'
import {primaryColor} from './../../theme/app-constantes'
import {useColorMode} from '@chakra-ui/react'

export type CustomIconProps = IconProps & {
  variant?: string
  isActive?: boolean
}

export const Icon = ({...props}: any) => {
  const {colorMode} = useColorMode()
  const {isActive, ...rest} = props // rest vaut tous les props sauf isActive
  // en faisant ca on evite le warning
  // Warning: React does not recognize the `isActive` prop on a DOM element.

  let w = '1em'
  let h = '1em'
  let color = 'gray.500'
  const colorTheme = primaryColor({colorMode})
  if (rest.variant && rest.variant === 'generalIcons') {
    w = '1.5em'
    h = '1.5em'
    color = colorTheme
  }
  if (rest.variant && rest.variant === 'smallIcons') {
    w = '0.6em'
    h = '0.6em'
    color = colorTheme
  }
  if (rest.variant && rest.variant === 'listIcons') {
    w = '1.5em'
    h = '1.5em'
    if (isActive) {
      color = colorTheme
    }
  }
  if (rest.color) {
    color = rest.color
  }
  return <ChakraIcon w={w} h={h} color={color} {...rest}></ChakraIcon>
}
export default Icon
