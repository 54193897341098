import {
  Box,
  Flex,
  Heading,
  useBreakpointValue,
  useColorMode,
} from '@chakra-ui/react'
import {Dispatch, SetStateAction, useEffect, useRef} from 'react'
import {ChatRoom, UserChatRoom} from '../../../commons/types/API'

import DiscussionHeader from '../../../components/Messagerie/DiscussionHeader'
import DiscussionList from '../../../components/Messagerie/DiscussionList'
import MessagePage from '../../../components/Messagerie/MessagePage'
import {ContainerPage} from '../../../components/ui'
import {customScrollbar} from '../../../theme/app-constantes'
import {AlertInfo} from '../../../components/ui/Alert'
import {customMinHeightPage, minHeightPage} from '../../../commons/constantes'
import {useGlobalContext} from '../../../context/GlobalContext'

type MessageriePageProps = {
  userChatRooms?: (UserChatRoom | null)[]
  chatRoom?: ChatRoom
  searchDiscussion?: string
  setSearchDiscussion: Dispatch<SetStateAction<string>>
  isLoading?: boolean
}
export const MessageriePage = ({
  userChatRooms,
  chatRoom,
  setSearchDiscussion,
  searchDiscussion,
  isLoading,
}: MessageriePageProps) => {
  const {colorMode} = useColorMode()
  const scrollbarCss = customScrollbar({colorMode})
  const refListDisc = useRef<HTMLDivElement>(null)
  const isLastChatRoom = userChatRooms?.[0]?.chatRoom?.id === chatRoom?.id

  // const isMobile = useBreakpointValue({base: true, sm: false})
  // const {setHideFooter} = useGlobalContext()
  // useEffect(() => {
  //   setHideFooter(isMobile ?? false)
  //   return () => {
  //     setHideFooter(false)
  //   }
  // }, [isMobile, setHideFooter])

  if (!isLoading && userChatRooms?.length === 0) {
    return (
      <NoDiscussion
        setSearchDiscussion={setSearchDiscussion}
        searchDiscussion={searchDiscussion}
      ></NoDiscussion>
    )
  }

  return (
    <>
      <Heading h={'45px'}>Mes conversations</Heading>
      <Flex
        w={'100%'}
        flexDirection={{base: 'column', md: 'row'}}
        gap={5}
        maxH={customMinHeightPage('45px')}
      >
        <Flex
          w={{base: '100%', md: '35%'}}
          minW={{md: '250px', lg: '350px'}}
          flexDirection={'column'}
          position="relative"
        >
          <DiscussionHeader
            setSearchDiscussion={setSearchDiscussion}
            searchDiscussion={searchDiscussion}
          />
          <Flex
            flexDir="column"
            maxH={{
              base: customMinHeightPage('210px'),
              md: customMinHeightPage('145px'),
              lg: minHeightPage,
            }}
            overflowY="scroll"
            css={scrollbarCss}
            ref={refListDisc}
          >
            <DiscussionList
              userChatRooms={userChatRooms}
              refListDisc={refListDisc}
            />
          </Flex>
        </Flex>
        <Flex
          w={{base: '100%', md: '65%'}}
          flexDirection={'column'}
          display={{base: 'none', md: 'flex'}}
        >
          {chatRoom || (!chatRoom && isLoading) ? (
            <MessagePage chatRoom={chatRoom} isLastChatRoom={isLastChatRoom} />
          ) : (
            <NoConversationSelected />
          )}
        </Flex>
      </Flex>
    </>
  )
}
type DiscussionMobilePageProps = {
  userChatRooms?: (UserChatRoom | null)[]
  searchDiscussion?: string
  setSearchDiscussion: Dispatch<SetStateAction<string>>
}
export const DiscussionMobilePage = ({
  userChatRooms,
  setSearchDiscussion,
  searchDiscussion,
}: DiscussionMobilePageProps) => {
  const refListDisc = useRef<HTMLDivElement>(null)
  return (
    <Box minH={customMinHeightPage('45px')}>
      <Heading>Mes conversations</Heading>
      <Flex w={'100%'} flexDirection={{base: 'column', md: 'row'}} gap={5}>
        <Flex
          w={{base: '100%', md: '35%'}}
          minW={{md: '250px', lg: '400px'}}
          flexDirection={'column'}
          position="relative"
          gap={3}
        >
          <DiscussionHeader
            setSearchDiscussion={setSearchDiscussion}
            searchDiscussion={searchDiscussion}
          />
          <Flex flexDir="column" overflowY="scroll" ref={refListDisc}>
            <DiscussionList
              userChatRooms={userChatRooms}
              refListDisc={refListDisc}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
type DiscussionListMobileProps = {
  chatRoom?: ChatRoom
}
export const DiscussionListMobile = ({chatRoom}: DiscussionListMobileProps) => {
  return (
    <Flex w={'100%'} flexDirection={'column'}>
      <MessagePage chatRoom={chatRoom} />
    </Flex>
  )
}

type NoDiscusstionProps = {
  searchDiscussion?: string
  setSearchDiscussion?: Dispatch<SetStateAction<string>>
}
const NoDiscussion = ({
  setSearchDiscussion = () => {},
  searchDiscussion,
}: NoDiscusstionProps) => {
  return (
    <ContainerPage>
      <Heading>Mes conversations</Heading>
      <DiscussionHeader
        setSearchDiscussion={setSearchDiscussion}
        searchDiscussion={searchDiscussion}
      />
      <AlertInfo
        title="Vous n'avez pas de conversation pour le moment"
        description=" Cliquez sur le bouton + pour engager la conversation avec un membre ou
          créer un groupe"
      />
    </ContainerPage>
  )
}

const NoConversationSelected = () => {
  return (
    <ContainerPage>
      <Heading variant={'title'}>Selectionnez une conversation</Heading>

      <AlertInfo
        title="Selectionnez une conversation"
        description="Cliquez sur une conversation pour voir les messages"
      />
    </ContainerPage>
  )
}
