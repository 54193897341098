import * as React from 'react'
import {
  useChatRoomById,
  useCreateChatMessageWithSubscription,
  useGetMeChatRooms,
} from '../commons/api/hooks/messagerie'
import {sortByDate} from '../commons/helpers/dataHelper'
import {
  ChatMessage,
  ChatRoom,
  ModelSortDirection,
  ModelUserChatRoomConnection,
  UserChatRoom,
} from '../commons/types/API'
import {localStorageLastMessage} from '../commons/constantes'
import {useEffect} from 'react'
import {useAuth, useAuthUserId} from './AuthContext'

const soundNotificationFile = '/sounds/notification.mp3'
const soundDisconnectFile = '/sounds/disconnect.mp3'

type State = {
  isNewChatMessage: boolean
  lastChatMessage?: ChatMessage
  openToastNewChatMessage: boolean
  disableToast: boolean
  toastSates: ToastStates
  loading: boolean
  warning: boolean
  error: boolean
  errorMessage: string
  warningMessage: string
  message: string
  isMute: boolean
  hideFooter: boolean
}
export type ToastStates = {
  open: boolean
  message: string
  variant: 'error' | 'success' | 'warning' | 'info'
}

export enum Sound {
  NOTIFICATION = 'NOTIFICATION',
  DISCONNECT = 'DISCONNECT',
}

type Action =
  | {type: 'fetchStart'}
  | {type: 'fetchEnd'; payload: any}
  | {type: 'setError'; payload: any}
  | {type: 'setWarning'; payload: any}
  | {type: 'setMessage'; payload: any}
  | {
      type: 'setNewMessage'
      payload: {
        chatMessage?: ChatMessage
        isNew: boolean
        openToastNewChatMessage?: boolean
        disableToast?: boolean
      }
    }
  | {type: 'clear'}
  | {type: 'showMessage'; payload: ToastStates}
  | {type: 'setMute'}
  | {type: 'setHideFooter'; payload: {hideFooter: boolean}}

type Dispatch = (action: Action) => void

//type pout le contexte
export type GlobalContextStore = {
  isNewChatMessage: boolean
  lastChatMessage?: ChatMessage
  openToastNewChatMessage: boolean
  disableToast: boolean
  toastSates: ToastStates //@deprecated
  loading: boolean
  warning: boolean
  error: boolean
  errorMessage: string
  warningMessage: string
  message: string
  isMute: boolean
  hideFooter: boolean
  showMessage: (param: ToastStates) => void
  fetchStart: () => void
  fetchEnd: (message?: string) => void
  clear: () => void
  setError: (error?: string) => void
  setWarning: (warning?: string) => void
  setMessage: (error: string) => void
  setNewChatMessage: ({
    isNew,
    lastMessage,
    openToastNewChatMessage,
    disableToast,
  }: {
    isNew: boolean
    lastMessage?: ChatMessage
    openToastNewChatMessage?: boolean
    disableToast?: boolean
  }) => void
  setMute: () => void
  emitSound: (sound: Sound) => void
  dispatch: Dispatch
  setHideFooter: (hide: boolean) => void
}

/* Declaration du contexte*/
const GlobalContext = React.createContext<GlobalContextStore>({
  isNewChatMessage: false,
  lastChatMessage: undefined,
  openToastNewChatMessage: false,
  disableToast: false,
  toastSates: {
    //@deprecated
    open: false,
    message: '',
    variant: 'success',
  },
  loading: false,
  warning: false,
  error: false,
  errorMessage: '',
  warningMessage: '',
  message: '',
  isMute: false,
  hideFooter: false,
  showMessage: () => {}, // To be implemented in provider
  fetchStart: () => {}, // To be implemented in provider
  fetchEnd: () => {}, // To be implemented in provider
  clear: () => {}, // To be implemented in provider
  setError: () => {}, // To be implemented in provider
  setWarning: () => {}, // To be implemented in provider
  setMessage: () => {}, // To be implemented in provider
  setNewChatMessage: () => {}, // To be implemented in provider
  setMute: () => {}, // To be implemented in provider
  emitSound: () => {}, // To be implemented in provider
  setHideFooter: () => {}, // To be implemented in provider
  dispatch: () => {}, // To be implemented in provider
})

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'fetchStart':
      return {
        ...state,
        error: false,
        loading: true,
      }
    case 'fetchEnd':
      return {
        ...state,
        error: false,
        loading: false,
      }
    case 'showMessage':
      return {
        ...state,
        // A voir si tu veux que tes autres states suive le toast !!
        toastSates: action.payload,
      }
    case 'setError':
      console.log('setError reducer', action.payload)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      }
    case 'setWarning':
      return {
        ...state,
        warning: true,
        warningMessage: action.payload,
      }
    case 'setMessage':
      return {
        ...state,
        message: action.payload,
      }
    case 'setHideFooter':
      return {
        ...state,
        hideFooter: action.payload.hideFooter,
      }
    case 'setNewMessage':
      return {
        ...state,
        //si le toast disable (quand on est dans la messagerie) pas d'icone de new message
        isNewChatMessage: state.disableToast ? false : action.payload.isNew,
        openToastNewChatMessage:
          action.payload.openToastNewChatMessage ??
          state.openToastNewChatMessage,
        lastChatMessage: action.payload.chatMessage,
        disableToast: action.payload.disableToast ?? state.disableToast,
      }
    case 'setMute':
      return {
        ...state,
        isMute: !state.isMute,
      }
    case 'clear':
      return {
        ...state,
        warning: false,
        message: '',
        errorMessage: '',
        warningMessage: '',
        loading: false,
        error: false,
        openToastNewChatMessage: false,
        hideFooter: false,
        // disableToast: false,
        // isNewChatMessage: false,
        //lastChatMessage: undefined,
      }
    default:
      throw new Error('Action non supporté')
  }
}

const useGlobalContext = () => {
  const context = React.useContext(GlobalContext)
  if (!context) {
    throw new Error(
      "useGlobalContext() s'utilise avec <GlobalContext.provider>",
    )
  }
  return context
}

const useGlobalLoading = (isLoading: boolean) => {
  const {fetchStart, fetchEnd} = useGlobalContext()
  React.useEffect(() => {
    if (isLoading) {
      fetchStart()
    } else {
      fetchEnd()
    }
  }, [fetchEnd, fetchStart, isLoading])
}

/**
 * Un compsant sans enfant qui permet de souscrire à une room
 * Utile pour boocler sur le hook useCreateChatMessageWithSubscription
 * @param param0
 * @returns
 */
function RoomSubscription({chatRoom}: {chatRoom: UserChatRoom | null}) {
  const {subscribe} = useCreateChatMessageWithSubscription({
    variables: {
      filter: {
        chatRoomMessagesId: {
          contains: chatRoom?.id ?? '',
        },
      },
    },
  })

  useEffect(() => {
    if (!chatRoom) return
    subscribe()
    return () => {
      console.log('RoomSubscription unmount')
      // pas de unscription car on veut que la souscription dure tout le temps
      // subscription?.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoom])

  return <></>
}
const GlobalContextProvider = (props: React.PropsWithChildren<{}>) => {
  //check if new message
  const uid = useAuthUserId()

  const {authUser} = useAuth()
  //console.log('uid', uid)
  const {chatRooms} = useGetMeChatRooms({
    disabled: uid === undefined ? true : false,
  })
  const lastRoomId = getlastRoomId(chatRooms)
  const {chatRoom, isLoading} = useChatRoomById(lastRoomId ?? '')
  const {isNewMessage, lastMessage} = checkNewMessageFromRoom({
    chatRoom,
    isLoading,
  })
  const isMuteAction = checkMuteAction(authUser?.me?.settings ?? '')

  // const [isSubscribed, setIsSubscribed] = React.useState<boolean>(false)
  // const [
  //   multiSubscriptionHiddenComponents,
  //   setMultiSubscriptionHiddenComponents,
  // ] = React.useState<JSX.Element[]>([])

  //souscription to last Room Messages
  const {subscribe} = useCreateChatMessageWithSubscription({
    variables: {
      filter: {
        chatRoomMessagesId: {
          contains: chatRoom?.id ?? '',
        },
      },
      //owner: uid, // no uid in the global context
    },
  })

  useEffect(() => {
    if (!chatRoom) return
    const subscription = subscribe()
    return () => {
      subscription?.unsubscribe()
    }
  }, [chatRoom, subscribe])

  const [state, dispatch] = React.useReducer(reducer, {
    isNewChatMessage: isNewMessage, //used for header icon new msg
    lastChatMessage: lastMessage, // user for toast & header icon new msg
    openToastNewChatMessage: true, //used for toast
    disableToast: false,
    hideFooter: false,
    loading: false,
    error: false,
    warning: false,
    errorMessage: '',
    warningMessage: '',
    message: '',
    toastSates: {
      open: false,
      variant: 'success',
      message: '',
    },
    isMute: isMuteAction,
  })

  const showMessage = React.useCallback((toastSates: any) => {
    dispatch({
      type: 'showMessage',
      payload: toastSates,
    })
  }, [])
  const fetchStart = React.useCallback(() => {
    dispatch({
      type: 'fetchStart',
    })
  }, [])
  const fetchEnd = React.useCallback((message: any) => {
    dispatch({
      type: 'fetchEnd',
      payload: message,
    })
  }, [])
  const setError = React.useCallback((error: any) => {
    console.log('setError cb', error)
    dispatch({
      type: 'setError',
      payload: error,
    })
  }, [])
  const setWarning = React.useCallback((error: any) => {
    dispatch({
      type: 'setWarning',
      payload: error,
    })
  }, [])

  const setMessage = React.useCallback((message: any) => {
    dispatch({
      type: 'setMessage',
      payload: message,
    })
  }, [])

  const setHideFooter = React.useCallback((hide: boolean) => {
    dispatch({
      type: 'setHideFooter',
      payload: {hideFooter: hide},
    })
  }, [])

  const clear = React.useCallback(() => {
    dispatch({
      type: 'clear',
    })
  }, [])

  const setNewChatMessage = React.useCallback(
    ({
      isNew,
      lastMessage,
      openToastNewChatMessage,
      disableToast,
    }: {
      isNew: boolean
      lastMessage?: ChatMessage
      openToastNewChatMessage?: boolean
      disableToast?: boolean
    }) => {
      dispatch({
        type: 'setNewMessage',
        payload: {
          isNew,
          chatMessage: lastMessage,
          openToastNewChatMessage,
          disableToast,
        },
      })
    },
    [],
  )

  const setMute = React.useCallback(() => {
    dispatch({
      type: 'setMute',
    })
  }, [])

  const emitSound = React.useCallback(
    (sound: Sound) => {
      const soundType = createSound(sound)
      if (!state.isMute) {
        soundType.play().catch((error: unknown) => {
          //catch error when start app
        })
      }
    },
    [state.isMute],
  )

  // effet de bord pour la notification d'un nouveau message
  useEffect(() => {
    // on s'autonotifie pas (cas d'un message envoyé sur un autre device)
    if (uid === lastMessage?.userMessagesId) return
    setNewChatMessage({
      isNew: isNewMessage,
      lastMessage: lastMessage,
      openToastNewChatMessage: isNewMessage,
    })
  }, [isNewMessage, lastMessage, setNewChatMessage, showMessage, uid])

  //effet de bord pour la souscription aux rooms
  // useEffect(() => {
  //   // on souscris a toutes les room une fois démarrage
  //   if (!isSubscribed && (chatRooms?.items.length ?? 0) > 0) {
  //     const multiSubRoom = chatRooms?.items.map(room => (
  //       <RoomSubscription key={room?.id} chatRoom={room} />
  //     ))
  //     setIsSubscribed(true)
  //     setMultiSubscriptionHiddenComponents(multiSubRoom ?? [])
  //   }
  // }, [chatRooms, isSubscribed])

  const {
    isNewChatMessage,
    lastChatMessage,
    openToastNewChatMessage,
    disableToast,
    hideFooter,
    errorMessage,
    warningMessage,
    error,
    loading,
    message,
    warning,
    toastSates,
    isMute,
  } = state
  const value = React.useMemo(
    () => ({
      isNewChatMessage,
      lastChatMessage,
      openToastNewChatMessage,
      disableToast,
      hideFooter,
      toastSates,
      showMessage,
      errorMessage,
      warningMessage,
      error,
      loading,
      message,
      setMessage,
      fetchStart,
      fetchEnd,
      setError,
      clear,
      warning,
      setWarning,
      setNewChatMessage,
      isMute,
      setMute,
      emitSound,
      setHideFooter,
      dispatch,
    }),
    [
      isNewChatMessage,
      lastChatMessage,
      openToastNewChatMessage,
      disableToast,
      toastSates,
      hideFooter,
      showMessage,
      errorMessage,
      warningMessage,
      error,
      loading,
      message,
      setMessage,
      fetchStart,
      fetchEnd,
      setError,
      clear,
      warning,
      setWarning,
      setNewChatMessage,
      isMute,
      setMute,
      emitSound,
      setHideFooter,
    ],
  )

  return (
    <GlobalContext.Provider value={value} {...props}>
      <>
        {/* multiSubscriptionHiddenComponents : composant caché qui permet de soucrire aux room */}
        {/* {multiSubscriptionHiddenComponents} */}
        {props.children}
      </>
    </GlobalContext.Provider>
  )
}

/**
 *
 * @param chatRooms
 * @returns
 */
const getlastRoomId = (
  chatRooms: ModelUserChatRoomConnection | null | undefined,
) => {
  const chatRoomsSorted = sortByDate({
    data: chatRooms?.items,
    direction: ModelSortDirection.ASC,
    filedName: 'updatedAt',
    subLevelName: 'chatRoom',
  })

  const lastRoomId = chatRoomsSorted?.[0]?.chatRoom?.id
  return lastRoomId
}
/**
 * Est ce que dans la derniere room, le dernier message est different du dernier message en localstorage
 *
 * @param param0
 * @returns
 */
const checkNewMessageFromRoom = ({
  chatRoom,
  isLoading,
}: {
  chatRoom: ChatRoom | null | undefined
  isLoading: boolean
}) => {
  let isNewMessage = false
  const sortedListchatMessages = sortByDate({
    data: chatRoom?.messages?.items ?? [],
    direction: ModelSortDirection.DESC,
  }) as ChatMessage[]

  const lastMessage =
    sortedListchatMessages?.[sortedListchatMessages.length - 1]
  const lastMessageIdLocalStorage = localStorage.getItem(
    localStorageLastMessage,
  )
  if (
    !isLoading &&
    lastMessage &&
    lastMessage?.id !== lastMessageIdLocalStorage
  ) {
    isNewMessage = true
    //console.log('new message : different message')
  }
  return {isNewMessage, lastMessage}
  //end algo to check if new message
}

export type Settings = {
  isMute?: boolean
}
const checkMuteAction = (settingsString?: string) => {
  if (!settingsString) return false
  const settings: Settings = JSON.parse(settingsString)
  return !!settings?.isMute
}

const createSound = (sound: Sound) => {
  let soundType: string
  switch (sound) {
    case Sound.NOTIFICATION:
      soundType = soundNotificationFile
      break
    case Sound.DISCONNECT:
      soundType = soundDisconnectFile
      break
  }
  return new Audio(`${process.env.PUBLIC_URL}${soundType}`)
}

export {
  GlobalContext,
  useGlobalContext,
  GlobalContextProvider,
  useGlobalLoading,
}
