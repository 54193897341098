import {TagProps} from '@chakra-ui/react'
import {getEStatusDetails} from '../../commons/helpers/screen'
import {EStatus} from '../../types/graphQL'
import Tag from './Tag'

export const TagStatus = ({status, ...props}: {status: EStatus} & TagProps) => {
  return (
    <Tag
      bgColor={getEStatusDetails(status).value}
      color={'white'}
      fontWeight={'semibold'}
      {...props}
    >
      {getEStatusDetails(status).label}
    </Tag>
  )
}
export default TagStatus
