import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
} from '@chakra-ui/react'
import {object, string, number, date, InferType} from 'yup'
import {useNavigate} from 'react-router-dom'
import {useUpdateConsultation} from '../../../commons/api/hooks'
import {getEStatusDetails} from '../../../commons/helpers/screen'
import {Question} from '../../../commons/types/API'
import {useUserDetailSchoolGroupId} from '../../../context/AuthContext'
import {useCreateConsultationStepper} from '../../../context/CreateConsultationContext'
import {EStatus} from '../../../types/graphQL'
import {CreateConsultationSteps} from '../../../types/screen'
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Tag,
  Text,
} from '../../ui'
import {HeaderStep} from './HeaderStep'
import {
  ROUTE_ADMIN_FAQ_CREATE_CONSULTATION,
  ROUTE_SCHOOL_FAQ,
} from '../../../constantes'
import {ReponseVideo} from '../../Player'
import {validURL} from '../../../commons/helpers/dataHelper'

type StepPublishConsultationProps = {
  step?: CreateConsultationSteps
}

export const StepPublishConsultation = ({
  step = CreateConsultationSteps.create,
}: StepPublishConsultationProps) => {
  const {consultation, questions} = useCreateConsultationStepper()
  const [error, setError] = React.useState<string>()
  const handleGoToFrontFaq = () => {
    navigate(`${ROUTE_SCHOOL_FAQ}`)
  }
  const mutationUpdate = useUpdateConsultation({onSuccess: handleGoToFrontFaq})
  const navigate = useNavigate()
  const schoolGroupId = useUserDetailSchoolGroupId()

  const canPublish = !questions.find((q: Question) => q.status === EStatus.NEW)
  //console.log('canPublish', canPublish)

  const handleSaveConsultation = () => {
    if (!canPublish) {
      setError(`Certaines questions n'ont pas eu de réponses`)
      return
    }
    const updateConsultation = {
      id: consultation?.id ?? '',
      status: EStatus.PUBLISHED,
      groupsCanAccess: [schoolGroupId],
    }
    //updateConsultationInput.status = EStatus.PUBLISHED
    //updateConsultationInput.groupsCanAccess = [schoolGroupId]
    mutationUpdate.mutate(updateConsultation)
  }
  //todo remplace by YUP

  const questionUrlShema = object({
    answerUrl: string().url().nullable(),
  })

  // const validURL = async (str: any): Promise<boolean> => {
  //   const url = questionUrlShema.validate({
  //     answerUrl: str,
  //   })
  //   url
  //     .then(() => {
  //       //console.log('url ok', url)
  //       return Promise.reject(true)
  //     })
  //     .catch(() => {
  //       //console.log('url ko', url)
  //       return Promise.reject(false)
  //     })

  //   //return url === '' ? true : false
  //   return true
  // }
  //validate()

  React.useEffect(() => {
    if (!consultation) {
      navigate(
        `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.create}`,
      )
    }
  })

  return (
    <>
      <HeaderStep
        title="Publier la consultation"
        consultation={consultation}
        questions={questions}
        step={4}
      />
      <Container variant="generalBg" maxW="full">
        <Heading variant="question" pb={4}>
          Listes des Question ajoutées à la consultation en cours
        </Heading>
        <Accordion>
          {questions.map((question: any, idx: number) => (
            <Box key={idx}>
              <AccordionItem borderTop={'none'} py={1}>
                {/* <h2> */}
                <AccordionButton>
                  <Box w="full" textAlign="left">
                    <Grid templateColumns="repeat(7, 1fr)" gap={4}>
                      <GridItem colSpan={1}>
                        <Flex w="full" justifyContent={'center'}>
                          <Tag
                            bgColor={
                              getEStatusDetails(question?.status as EStatus)
                                .value
                            }
                            color={'white'}
                            fontWeight={'semibold'}
                            mr={1}
                          >
                            {question?.status}
                          </Tag>
                        </Flex>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <Text variant="label">{question.title}</Text>
                      </GridItem>
                    </Grid>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                {/* </h2> */}
                <AccordionPanel pb={4}>
                  {validURL(question.answerUrl) ? (
                    <ReponseVideo question={question} />
                  ) : (
                    <Box my={2}>Vous n'avez pas indiqué une URL valide</Box>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Box>
          ))}
        </Accordion>
        <Flex w="full" justifyContent={'flex-end'} mt={4}>
          <HStack>
            <Alert status="error" hidden={!error}>
              <AlertIcon />
              <AlertTitle mr={2}>Problème pour publier</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
            <Button
              onClick={() =>
                navigate(
                  `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.answer}`,
                )
              }
            >
              Précédent
            </Button>
            <Button
              onClick={handleSaveConsultation}
              colorScheme={'green'}
              isLoading={mutationUpdate.isLoading}
              loadingText={'Consultation en cours de publication'}
            >
              PUBLIER
            </Button>
          </HStack>
        </Flex>
      </Container>
    </>
  )
}
