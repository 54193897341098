/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Stack,
  Flex,
  Button,
  FormInput,
  HStack,
  FormTextArea,
  Badge,
} from '../ui'

import {useFormik} from 'formik'
import * as Yup from 'yup'
import {CreateQuestionInput, UpdateQuestionInput} from '../../commons/types/API'
import {EStatus} from '../../types/graphQL'
import {useAuthDetails} from '../../context/AuthContext'
import SelectCategories from '../Selects/select-categories'
import MultiSelectTags from '../Selects/multiselect-tags'
import {buildTagsObject} from '../../commons/helpers/dataHelper'
import {AlertHiddenFields} from './AlertHiddenFields'
import {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import MDEditor from '@uiw/react-md-editor'
import {Box, Fade, Switch, Text, useColorMode} from '@chakra-ui/react'
type QuestionFormProps = {
  initialValues?: UpdateQuestionInput & CreateQuestionInput & {tagsData?: any[]}
  onSubmit: (
    formValue: UpdateQuestionInput & CreateQuestionInput & {tagsData?: any[]},
  ) => void
  isLoading?: boolean
  onClose?: () => void
  onOpen?: () => void
  isOpen?: boolean
  isEdit?: boolean
  requireValidationByModal?: boolean
}

export const QuestionStudentForm = ({
  initialValues,
  onSubmit,
  isLoading,
  onClose,
  isEdit,
}: QuestionFormProps) => {
  const SignInSchema = Yup.object().shape({
    title: Yup.string()
      .min(15, 'Minimum 15 caractères')
      .max(170, 'Maximum 170 caractères')
      .required('Champ obligatoire'),
    description: Yup.string()
      .min(20, 'Minimum 20 caractères')
      .max(4000, 'Maximum 4000 caractères')
      .required('Champ obligatoire'),
    questionCategoryId: Yup.string()
      .min(1, 'Minimum 1 caractère')
      .max(2000, 'Maximum 2000 caractères')
      .required('Selectionnez une catégorie'),
    questionVideoUrl: Yup.string().url('Entrez une URL de vidéo valide'),
  })
  const {colorMode} = useColorMode()

  const originalTags = initialValues?.tagsData
  const {schoolGroupId, schoolId, uid} = useAuthDetails()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      title: initialValues?.title ?? '',
      rewording: initialValues?.rewording ?? initialValues?.title ?? '',
      description: initialValues?.description ?? '',
      status: initialValues?.status ?? EStatus.NEW,
      answerUrl: initialValues?.answerUrl ?? '',
      questionAuthorId: initialValues?.questionAuthorId ?? uid ?? '',
      questionsSchoolId: initialValues?.questionsSchoolId ?? schoolId,
      questionCategoryId: initialValues?.questionCategoryId ?? '',
      questionVideoUrl: initialValues?.questionVideoUrl ?? '',
      tagsData: initialValues?.tagsData ?? [],
      //access
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (
      values: UpdateQuestionInput & CreateQuestionInput & {tagsData?: any[]},
    ) => {
      values.groupsCanAccess = [schoolGroupId]
      //console.log('onsubmit ', values)

      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  /**
   * We need to know wich tags must be deleted and added to question
   * prepare object with the following shape
   * {tags, toDel, toAdd}
   * tags : Selected Tags (not enought to know if need to be added to question or not)
   * toDel : tags todel from the question
   * toAdd : tags need to add to question
   * @param tags
   */
  const handleTagsChange = (tags: any) => {
    //console.log('handleTagsChange', tags)
    //console.log('originalTags', originalTags)
    const data = buildTagsObject(tags, originalTags)
    formik.setFieldValue('tagsData', data)
  }

  const handleMarkdownChange = (value: string) => {
    formik.setFieldValue('description', value)
  }

  colorMode === 'light'
    ? document.documentElement.setAttribute('data-color-mode', 'light')
    : document.documentElement.setAttribute('data-color-mode', 'dark')
  const [isMarkdown, setIsMarkdown] = useState(false)

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing="2">
        <Flex gap={3} alignItems="center">
          <Switch
            size="md"
            onChange={() => setIsMarkdown(!isMarkdown)}
            isChecked={!isMarkdown}
          />

          <Text variant={'label'}>
            Détail de la question {!isMarkdown ? <Badge>MarkDown</Badge> : ''}
          </Text>
        </Flex>
        {isMarkdown ? (
          <FormTextArea
            placeholder="Détailler votre question ..."
            name="description"
            id="description"
            onChange={formik.handleChange}
            value={formik.values?.description}
            errorMessage={formik?.errors?.description}
            height="250px"
          />
        ) : (
          <>
            <MDEditor
              value={formik.values.description ?? '**ok**'}
              onChange={value => handleMarkdownChange(value ?? '')}
              preview="live"
              textareaProps={{
                placeholder: `Détailler votre question, n'hésitez pas à mettre en forme avec l'éditeur markdown`,
                name: 'description',
                id: 'description',
                value: formik.values.description || '',
                onChange: formik.handleChange,
              }}
            />
            {formik.touched.description && formik.errors.description && (
              <Text fontSize="sm" color="#e53e3e">
                {formik.errors.description}
              </Text>
            )}
          </>
        )}
        <FormInput
          placeholder="Comment trouver des clients ..."
          type="title"
          label="Récapitulatif de votre question"
          name="title"
          id="title"
          onChange={formik.handleChange}
          value={formik.values?.title?.toLocaleLowerCase()}
          errorMessage={formik?.errors?.title}
        />

        <SelectCategories
          selectFirstValue={true}
          label="Categorie"
          name="questionCategoryId"
          id="questionCategoryId"
          onChange={formik.handleChange}
          value={formik.values?.questionCategoryId as string}
          errorMessage={formik?.errors?.questionCategoryId}
        />

        <MultiSelectTags
          selectFirstValue={true}
          placeholder="Selectionner un ou plusieurs tag(s) ..."
          label="Tags de votre question"
          name="tags"
          id="tags"
          onChange={handleTagsChange}
          value={formik.values?.tagsData?.map(q => q?.fAQTagID)}
        />

        <FormInput
          placeholder="https://..."
          type="questionVideoUrl"
          label="Associer une video à votre question (optionel)"
          name="questionVideoUrl"
          id="questionVideoUrl"
          onChange={formik.handleChange}
          value={formik.values?.questionVideoUrl}
          errorMessage={formik?.errors?.questionVideoUrl}
        />

        <Flex justifyContent={'flex-end'} w="full">
          <HStack>
            <Button variant="outline" onClick={onClose}>
              Annuler
            </Button>
            <Button type="submit" fontSize="md" isLoading={isLoading}>
              {isEdit ? 'Modifier' : 'Valider'}
            </Button>
          </HStack>
        </Flex>
      </Stack>

      <AlertHiddenFields
        formik={formik}
        fields={['questionAuthorId', 'owner', 'editors', 'groupsCanAccess']}
      />
    </form>
  )
}
export default QuestionStudentForm
