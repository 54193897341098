import React, {useEffect, useState} from 'react'
import {Routes, useLocation} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {useGlobalContext} from '../context/GlobalContext'

// TopBarProgress.config({
//   barColors: {
//     '0': '#fff',
//     '1.0': '#fff',
//   },
//   shadowBlur: 50,
// })

export const CustomRoutes = ({children}: any) => {
  const {loading} = useGlobalContext()
  const [progress, setProgress] = useState(false)
  const [prevLoc, setPrevLoc] = useState('')
  const location = useLocation()

  React.useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0)
    }
  }, [location])

  useEffect(() => {
    setPrevLoc(location.pathname)
    setProgress(true)
    if (location.pathname === prevLoc) {
      setPrevLoc('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    setProgress(false)
  }, [prevLoc])

  return (
    <>
      {(loading || progress) && <TopBarProgress />}
      <Routes>{children}</Routes>
    </>
  )
}
