import {useFAQCategories} from '../../commons/api/hooks'
import {buildFilterBySchool} from '../../commons/helpers/graphQLHelper'
import {useUserDetailSchoolGroupId} from '../../context/AuthContext'
import {Select, SelectionProps} from '../ui'

export const SelectCategories = ({
  selectFirstValue,
  ...rest
}: SelectionProps & {selectFirstValue?: boolean}) => {
  const schoolGroupId = useUserDetailSchoolGroupId()
  const {FAQCategories} = useFAQCategories({
    filter: {...buildFilterBySchool(schoolGroupId)},
  })

  const defaultValue = FAQCategories?.items?.[0]?.id
  const value = selectFirstValue ? defaultValue : undefined
  return (
    <Select maxW="368px" value={value} {...rest}>
      <option value="">Selectionner une categorie</option>
      {FAQCategories?.items?.map((option, idx) => (
        <option key={idx} value={option.id}>
          {option.name}
        </option>
      ))}
    </Select>
  )
}

export default SelectCategories
