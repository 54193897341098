import {Heading, BreakcrumbList, Flex} from '../ui'

type ItemBreadCrumb = {
  label: string
  to: string
}
type PrincipalHeadingProps = {
  title: string
  bcItem1?: ItemBreadCrumb
  bcItem2?: ItemBreadCrumb
  bcItem3?: ItemBreadCrumb
}

export const PrincipalHeading = ({
  title,
  bcItem1,
  bcItem2,
  bcItem3,
}: PrincipalHeadingProps) => {
  const bcItems: ItemBreadCrumb[] = []

  bcItems.push(bcItem1 as ItemBreadCrumb)
  if (bcItem2) {
    bcItems.push(bcItem2)
  }
  if (bcItem3) {
    bcItems.push(bcItem3)
  }

  return (
    <Flex
      justifyContent={'space-between'}
      py={4}
      flexDirection={{base: 'column', md: 'row'}}
    >
      <Heading as="h1" display={title === '' ? 'none' : ''} mr={20}>
        {title}
      </Heading>
      <Heading variant="subTitle" mt={2}>
        <BreakcrumbList separator="->" bcItems={bcItems} />
      </Heading>
    </Flex>
  )
}

export default PrincipalHeading
