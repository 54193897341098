/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import {
  Stack,
  Heading,
  Flex,
  Box,
  Button,
  FormInput,
  PinInputField,
  PinInput,
  HStack,
  Text,
} from '../ui'
import {AiFillLinkedin, AiFillGoogleCircle} from 'react-icons/ai'
import {LinkIcon} from '@chakra-ui/icons'
import {Link} from 'react-router-dom'
import {useFormik, Formik} from 'formik'
import * as Yup from 'yup'
import {AlertHiddenFields} from './AlertHiddenFields'
import {RegisterPinFormValues} from '../../types'

export interface ResetPasswordFormValues {
  email?: string
  pin1?: string
  pin2?: string
  pin3?: string
  pin4?: string
  pin5?: string
  pin6?: string
  password?: string
  passwordConfirmation?: string
  pin?: RegisterPinFormValues
}

type RegisterFormProps = {
  initialValues?: ResetPasswordFormValues
  onSubmit: (formValue: ResetPasswordFormValues) => void
  initial: boolean
  isLoading?: boolean
}

export const ResetPasswordForm = ({
  initialValues,
  onSubmit,
  isLoading,
  initial,
}: RegisterFormProps) => {
  const SignInSchema = Yup.object().shape({
    email: Yup.string().email('Email invalide').required('Champ obligatoire'),
    pin1: !initial
      ? Yup.string().required('Code 1  absent')
      : Yup.string().notRequired(),
    pin2: !initial
      ? Yup.string().required('Code 2  absent')
      : Yup.string().notRequired(),
    pin3: !initial
      ? Yup.string().required('Code 3  absent')
      : Yup.string().notRequired(),
    pin4: !initial
      ? Yup.string().required('Code 4  absent')
      : Yup.string().notRequired(),
    pin5: !initial
      ? Yup.string().required('Code 5  absent')
      : Yup.string().notRequired(),
    pin6: !initial
      ? Yup.string().required('Code 6  absent')
      : Yup.string().notRequired(),
    password: !initial
      ? Yup.string()
          .min(2, 'Minimum 2 caractères')
          .max(50, 'Maximum 50 caractères')
          .required('Champ obligatoire')
      : Yup.string().notRequired(),
    passwordConfirmation: !initial
      ? Yup.string()
          .min(2, 'Minimum 2 caractères')
          .max(50, 'Maximum 50 caractères')
          .required('Champ obligatoire')
          .oneOf(
            [Yup.ref('password'), null],
            'Les mots de passe ne correspondent pas',
          )
      : Yup.string().notRequired(),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: initialValues?.email ?? '',
      pin1: initialValues?.pin?.[0],
      pin2: initialValues?.pin?.[1],
      pin3: initialValues?.pin?.[2],
      pin4: initialValues?.pin?.[3],
      pin5: initialValues?.pin?.[4],
      pin6: initialValues?.pin?.[5],
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: (values: ResetPasswordFormValues) => {
      //console.log('onSubmit', onSubmit)

      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })

  const distributInFields = React.useCallback(
    (code: string) => {
      formik.setValues({
        ...formik.values,
        pin1: code?.[0],
        pin2: code?.[1],
        pin3: code?.[2],
        pin4: code?.[3],
        pin5: code?.[4],
        pin6: code?.[5],
      })
    },
    [formik],
  )
  const pinToString = (pin?: RegisterPinFormValues) => {
    if (pin) {
      return `${pin.pin1}${pin.pin2}${pin.pin3}${pin.pin4}${pin.pin5}${pin.pin6}`
    }
  }

  React.useEffect(() => {
    if (initialValues) {
      distributInFields(pinToString(initialValues?.pin) ?? '')
    }
  }, [])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing="2">
        <FormInput
          placeholder="email"
          label="Adresse email"
          name="email"
          id="email"
          onChange={formik.handleChange}
          value={formik.values?.email}
          errorMessage={formik?.errors?.email}
        />
        <Text variant="label" mb={1} hidden={initial}>
          Code de confirmation
        </Text>
        <HStack spacing="10px" hidden={initial}>
          <PinInput
            size="lg"
            onComplete={code => distributInFields(code)}
            defaultValue={pinToString(initialValues?.pin)}
          >
            <PinInputField
              type="number"
              id="pin1"
              name="pin1"
              onChange={formik.handleChange}
              value={formik.values?.pin1}
              errorMessage={formik?.errors?.pin1}
            />
            <PinInputField
              type="number"
              id="pin2"
              name="pin2"
              onChange={formik.handleChange}
              value={formik.values?.pin2}
            />
            <PinInputField
              type="number"
              id="pin3"
              name="pin3"
              onChange={formik.handleChange}
              value={formik.values?.pin3}
            />
            <PinInputField
              type="number"
              id="pin4"
              name="pin4"
              onChange={formik.handleChange}
              value={formik.values?.pin4}
            />
            <PinInputField
              type="number"
              id="pin5"
              name="pin5"
              onChange={formik.handleChange}
              value={formik.values?.pin5}
            />
            <PinInputField
              type="number"
              id="pin6"
              name="pin6"
              onChange={formik.handleChange}
              value={formik.values?.pin6}
            />
          </PinInput>
        </HStack>

        <FormInput
          hidden={initial}
          type="password"
          label="Nouveau Mot de passe"
          name="password"
          id="password"
          onChange={formik.handleChange}
          value={formik.values?.password}
          errorMessage={formik?.errors?.password}
        />
        <FormInput
          hidden={initial}
          type="password"
          label="Confirmer le mot de passe"
          name="passwordConfirmation"
          id="passwordConfirmation"
          onChange={formik.handleChange}
          value={formik.values?.passwordConfirmation}
          errorMessage={formik?.errors?.passwordConfirmation}
        />

        <Button type="submit" size="lg" fontSize="md" isLoading={isLoading}>
          Reinitialiser le mot de passe
        </Button>
        <AlertHiddenFields
          formik={formik}
          fields={[
            'pin1',
            'pin2',
            'pin3',
            'pin4',
            'pin5',
            'pin6',
            'commentAuthorId',
            'userTopicsId',
            'owner',
            'editors',
            'groupsCanAccess',
          ]}
        />
      </Stack>
    </form>
  )
}
export default ResetPasswordForm
