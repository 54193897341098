import {AttachmentIcon, CheckCircleIcon} from '@chakra-ui/icons'
import {IconButton, Input} from '@chakra-ui/react'
import {useEffect, useMemo, useState} from 'react'
import {useCreateChatMessageWithSubscription} from '../../commons/api/hooks/messagerie'
import {customMinHeightPage} from '../../commons/constantes'
import {
  ChatRoom,
  ModelChatMessageConnection,
  UserChatRoom,
} from '../../commons/types/API'
import {useAuthDetails} from '../../context/AuthContext'
import HeaderConSkeleton from '../Skeletons/HeaderConvSkeleton'
import ListMessagesSkeleton from '../Skeletons/ListMessagesSkeleton'
import {Box, Flex} from '../ui'
import MessageHeader from './MessageHeader'
import {MessageInput} from './MessageInput'
import MessageList from './MessageList'

type MessagePageProps = {
  chatRoom?: ChatRoom
  isLastChatRoom?: boolean
}
// le chatRoom contient tous les message
// Todo faire une requete API pour avoir les messages de la conversation (pas de filter car pas optimiser)
//
export default function MessagePage({
  chatRoom,
  isLastChatRoom,
}: MessagePageProps) {
  const listChatMessages = useMemo(
    () => chatRoom?.messages,
    [chatRoom?.messages],
  )

  const usersChatRoom: (UserChatRoom | null)[] | undefined = useMemo(
    () => chatRoom?.contributors?.items,
    [chatRoom?.contributors],
  )

  const [messageValue, setMessageValue] = useState('')
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event =>
    setMessageValue(event.target.value)

  const {uid} = useAuthDetails()

  const roomID = chatRoom?.id ?? ''

  const {subscribe} = useCreateChatMessageWithSubscription({
    variables: {
      filter: {
        chatRoomMessagesId: {
          contains: roomID,
        },
      },
      owner: uid,
    },
  })
  useEffect(() => {
    if (!chatRoom) return
    const subscription = subscribe()
    return () => {
      subscription?.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!chatRoom?.id) {
    return (
      <Flex flexDirection={'column'} minH={'50vh'} gap={5}>
        <HeaderConSkeleton />
        <ListMessagesSkeleton />
        <Flex flexDir={'row'} gap={2}>
          <Box w={'100%'} transition={'0.3s'}>
            <Input
              placeholder={'message'}
              value={messageValue}
              onChange={handleChange}
              isDisabled
            />
          </Box>
          <IconButton
            aria-label="envoyer le message"
            icon={<CheckCircleIcon />}
            isDisabled
          />

          <IconButton
            aria-label="envoyer le message"
            icon={<AttachmentIcon />}
            isDisabled
          />
        </Flex>
      </Flex>
    )
  }

  return (
    <>
      <Flex
        flexDirection={'column'}
        gap={5}
        maxH={{
          base: customMinHeightPage('170px'),
          md: customMinHeightPage('120px'),
        }}
        minH={{
          base: customMinHeightPage('120px'),
          md: customMinHeightPage('100px'),
          lg: customMinHeightPage('30px'),
        }}
        justifyContent={'space-between'}
      >
        <Box>
          <MessageHeader chatRoom={chatRoom} />
          <MessageList
            listChatMessages={listChatMessages as ModelChatMessageConnection}
            usersChatRoom={usersChatRoom as UserChatRoom[]}
            isLastChatRoom={isLastChatRoom}
          />
        </Box>
        <MessageInput chatRoom={chatRoom} key={chatRoom?.id} />
      </Flex>
    </>
  )
}
