import {useEffect, useMemo, useState} from 'react'
import {PAGINATION_MESSAGERIE_CONVERSATION} from '../../commons/constantes'
import {ChatRoom, UserChatRoom} from '../../commons/types/API'
import Pagination from '../Table/Pagination'
import {DiscussionItem} from './DiscussionItem'
import {Spacer} from '@chakra-ui/react'
import {useSearchParams} from 'react-router-dom'
import {getDefaultPage} from '../../commons/helpers/dataHelper'

type DiscussionListProps = {
  userChatRooms?: (UserChatRoom | null)[]
  refListDisc: React.RefObject<HTMLDivElement>
}

function paginateArray<T>(data: T[], page: number, pageSize: number): T[] {
  return data.slice((page - 1) * pageSize, page * pageSize)
}

export default function DiscussionList({
  userChatRooms,
  refListDisc,
}: DiscussionListProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const pageQueryPage = searchParams.get('page')
  const defaultPage = getDefaultPage(pageQueryPage)

  const [page, setPage] = useState<number>(defaultPage ?? 1)
  const datas = userChatRooms as UserChatRoom[]

  const pageData = useMemo(() => {
    if (Number(pageQueryPage) > 0) {
      return paginateArray(datas, page, PAGINATION_MESSAGERIE_CONVERSATION)
    } else {
      return datas
    }
  }, [datas, page, pageQueryPage])

  const hasPrev = page > 1
  const hasNext = page < datas.length / PAGINATION_MESSAGERIE_CONVERSATION

  const handleNext = () => {
    const newPage = page + 1
    setPage(newPage)
    setSearchParams({page: `${newPage}`})
  }
  const handlePrev = () => {
    const newPage = page - 1
    setPage(newPage < 1 ? 1 : newPage)
    setSearchParams({page: `${newPage}`})
  }

  useEffect(() => {
    if (refListDisc.current) {
      refListDisc.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [page])
  return (
    <>
      {pageData?.map((e, index) => (
        <DiscussionItem key={index} chatRoom={e?.chatRoom as ChatRoom} />
      ))}
      <Spacer></Spacer>
      <Pagination
        next={handleNext}
        prev={handlePrev}
        hasNext={hasNext}
        hasPrev={hasPrev}
        light={true}
      />
    </>
  )
}
