import {Link, useNavigate} from 'react-router-dom'
import {Alert, AlertIcon, Code, useColorModeValue} from '@chakra-ui/react'
import {Box, Button, Text} from '../../../components/ui'
import Searchbar from '../../../components/SearchBar/Searchbar'
import {
  LINK_IMG_500_DARK,
  LINK_IMG_500_LIGHT,
  ROUTE_SCHOOL_FAQ,
} from '../../../constantes'
import {useAuthDetails} from '../../../context/AuthContext'

export const Error500 = ({error, graphQlerrors, resetEr}) => {
  const {authUser} = useAuthDetails()
  const navigate = useNavigate()
  const handleOnSearch = e => {
    navigate(`${ROUTE_SCHOOL_FAQ}?question=${e}`)
  }

  const imageUrl = useColorModeValue(LINK_IMG_500_LIGHT, LINK_IMG_500_DARK)
  return (
    <>
      {
        <Box
          bgSize="cover"
          minH="100vh"
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={5}
        >
          <img src={imageUrl} />
          <Text variant={'textTitle'}>Une erreur est survenue !</Text>
          <Alert status="warning" maxW={'800px'}>
            <AlertIcon />
            <Text>{error}</Text>
            {graphQlerrors && <Code>{graphQlerrors}</Code>}{' '}
          </Alert>
          <Alert status="info" maxW={'800px'}>
            <AlertIcon />
            <Text>Adresse: {window.location.href}</Text>
          </Alert>
          <Alert status="info" maxW={'800px'}>
            <AlertIcon />
            <Text>
              Utilisateur : {authUser?.me?.username ?? 'non défini'} (
              {authUser?.me?.id ?? 'non défini'})
            </Text>
          </Alert>
          <Box w={{base: '300px', md: '400px'}}>
            <Searchbar
              placeholder="Chercher une question..."
              onSearch={handleOnSearch}
              tooltipMessage="Cliquez ici pour rechercher"
            />
          </Box>
          <Link to="/">
            <Button onClick={resetEr}>Retour à l'Accueil</Button>
          </Link>
        </Box>
      }
    </>
  )
}
