// types généraux de l'application

export enum VideoType {
  YOUTUBE = 'YOUTUBE',
  DROPBOX = 'DROPBOX',
  GDRIVE = 'GDRIVE',
  VIMEO = 'VIMEO',
}

export interface RegisterPinFormValues {
  pin1: string
  pin2: string
  pin3: string
  pin4: string
  pin5: string
  pin6: string
}
