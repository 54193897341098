import {useParams} from 'react-router-dom'
import {QuestionById} from '../../../components/FaqVideo/QuestionById'
import {ContainerPage} from '../../../components/ui'

const QuestionByIdPage = () => {
  const {questionId} = useParams()
  return (
    <ContainerPage>
      <QuestionById questionId={questionId} />
    </ContainerPage>
  )
}
export {QuestionByIdPage}
