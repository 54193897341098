import {BellIcon} from '@chakra-ui/icons'
import {useColorMode, Flex, Text} from '@chakra-ui/react'
import {useMemo} from 'react'
import {useParams, Link, useSearchParams} from 'react-router-dom'
import {useUserById} from '../../commons/api/hooks'
import {
  getDefaultPage,
  sortByDate,
  timeDiff,
} from '../../commons/helpers/dataHelper'
import {findBestUidForAvatarChatRoom} from '../../commons/helpers/messagerieHelper'
import {ChatRoom, ModelSortDirection} from '../../commons/types/API'
import {ROUTE_MESSAGERIE_CONVERSATIONS} from '../../constantes'
import {useAuthDetails} from '../../context/AuthContext'
import {bgSidebarColor} from '../../theme/app-constantes'
import {AvatarMessage} from './AvatarMessage'
import {displayUserNameAndName} from '../../commons'
import {useChatRoomById} from '../../commons/api/hooks/messagerie'

type DiscussionItemProps = {
  chatRoom?: ChatRoom
}
export const DiscussionItem = ({chatRoom}: DiscussionItemProps) => {
  const [searchParams] = useSearchParams()
  const pageQueryPage = searchParams.get('page')
  const defaultPage = getDefaultPage(pageQueryPage)
  const {chatRoomId} = useParams()
  const {authUser} = useAuthDetails()
  const {colorMode} = useColorMode()
  const bgColorMode = bgSidebarColor({colorMode})
  const isSelected = chatRoomId === chatRoom?.id
  const isGroup =
    chatRoom?.contributors && chatRoom?.contributors?.items.length > 2

  const {chatRoom: chatRoomMessages, isLoading} = useChatRoomById(
    chatRoom?.id ?? '',
  )

  const uidAvatar = useMemo(() => {
    return findBestUidForAvatarChatRoom(chatRoom, authUser.me)
  }, [chatRoom, authUser.me])
  const {user} = useUserById(uidAvatar)

  const listchatMessages = chatRoomMessages?.messages
  const sortedListchatMessages = sortByDate({
    data: listchatMessages?.items,
    direction: ModelSortDirection.ASC,
  })

  const primaryItemLabel = isGroup
    ? chatRoom?.name
    : user
    ? displayUserNameAndName(user)
    : chatRoom?.name

  const secondaryItemLabel = isLoading
    ? '...'
    : sortedListchatMessages[0]?.message ??
      chatRoom?.name ??
      displayUserNameAndName(user)

  return (
    <>
      <Link
        to={`${ROUTE_MESSAGERIE_CONVERSATIONS}/${
          chatRoom?.id ?? 'new'
        }?page=${defaultPage}`}
      >
        <Flex
          flexDir={'row'}
          p={2}
          bg={isSelected ? bgColorMode : 'transparent'}
          minH={'90px'}
          boxShadow={isSelected ? 'xl' : 'none'}
          rounded={'lg'}
          alignItems={'center'}
        >
          <AvatarMessage
            user={user}
            contributors={chatRoom?.contributors?.items}
          />
          <Flex flexDir={'column'} w={'70%'}>
            <Flex flexDir={'row'} w={'100%'} justifyContent={'space-between'}>
              <Text variant={'label'}>{primaryItemLabel}</Text>
              <BellIcon color={'orange'} />
            </Flex>
            <Flex flexDir={'row'} w={'100%'} justifyContent={'space-between'}>
              <Text noOfLines={1}>{secondaryItemLabel}</Text>
              <Text>{timeDiff(chatRoom?.updatedAt ?? '')}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </>
  )
}
