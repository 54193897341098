import * as React from 'react'
import {ContainerPage} from '../../../components/ui'

const About = () => {
  return (
    <ContainerPage>
      <h1>A propos de ce site</h1>
    </ContainerPage>
  )
}
export default About
