import {Link, useNavigate} from 'react-router-dom'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  useColorModeValue,
} from '@chakra-ui/react'

import {Box, Button, Text} from '../../../components/ui'
import Searchbar from '../../../components/SearchBar/Searchbar'

type Error404Props = {
  message?: string
}
export const Error404 = ({message}: Error404Props) => {
  const navigate = useNavigate()
  const currentUrl = window.location.href
  const parts = currentUrl.split('/')
  const searchedTerm = parts[parts.length - 1]
  const handleOnSearch = e => {
    navigate(`/school/faq?question=${e}`)
  }
  const imageUrl = useColorModeValue(
    '/images/error-404-light.png',
    '/images/error-404-dark.png',
  )
  return (
    <>
      {
        <Box
          bgSize="cover"
          minH="70vh"
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={5}
        >
          <img src={imageUrl} />
          <Text variant={'textTitle'}>
            la page "<u>{searchedTerm}</u>" n'éxiste pas
          </Text>
          {message ? (
            <Alert status="warning" maxW={500}>
              <AlertIcon />
              <AlertTitle mr={2}>Attention</AlertTitle>
              <AlertDescription>{message}</AlertDescription>
            </Alert>
          ) : null}
          <Box w={{base: '300px', md: '400px'}}>
            <Searchbar
              placeholder="Chercher une question..."
              onSearch={handleOnSearch}
              tooltipMessage="Cliquez ici pour rechercher"
            />
          </Box>
          <Link to="/">
            <Button>Retour à l'Accueil</Button>
          </Link>
        </Box>

        /*
      <Box
         bgSize="cover"
        minH="500px"
        rounded="md"
        pb={8}
      >
        <Stack align="center" p={4}>
          <Heading variant="megaTitle" color={'red.500'} mt={8} px={4}>
            ERROR 404
          </Heading>
        </Stack>
        <Flex justifyContent="center" w="full" p={4}>
          <Box w="full" maxW="700px" p={4}>
            <Card
              title="Error404"
              data={
                <>
                  <HStack w="full">
                    <Image
                      ml={-12}
                      mt={-20}
                      maxW={'200px'}
                      src={imageUrl}
                      display={{base: 'none', md: 'flex'}}
                    />
                    <Stack spacing={8} mr={-12} w="full">
                      <Center mt={4}>
                        <Heading fontSize={{base: 'xl', md: '2xl'}}>
                          êtes vous perdu ?
                        </Heading>
                      </Center>
                      <Center>
                        <Text>L'Url n'existe peut-être plus..</Text>
                      </Center>
                      <Flex w="full" justifyContent={'flex-end'}>
                        <Link to="/">
                          <Button>Retour à l'Accueil</Button>
                        </Link>
                      </Flex>
                    </Stack>
                  </HStack>
                </>
              }
            />
          </Box>
        </Flex>
        <Image
          zIndex={9999}
          // ml={-12}
          mt={-24}
          minH={'350px'}
          maxW={'200px'}
          src="/images/astronote.png"
          display={{base: 'flex', md: 'none'}}
        />
            </Box>*/
      }
    </>
  )
}
