import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import {CloseButton} from '../ui'

type AlertHiddenFieldsProps = {
  fields?: string[]
  formik?: any
}
export const AlertHiddenFields = ({
  formik,
  fields = ['owner', 'editors', 'groupsCanAccess'],
}: AlertHiddenFieldsProps) => {
  return (
    <VStack mt={5}>
      {fields.map((field, idx) => (
        <React.Fragment key={idx}>
          {formik?.errors?.[field] && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>Erreur!</AlertTitle>
              <AlertDescription>{formik?.errors?.[field]}</AlertDescription>
              {/* <CloseButton position="absolute" right="8px" top="8px" /> */}
            </Alert>
          )}
        </React.Fragment>
      ))}
      {/* {formik?.errors?.workspaceTopicsId && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Erreur!</AlertTitle>
          <AlertDescription>
            {formik?.errors?.workspaceTopicsId}
          </AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      )}
      {formik?.errors?.userTopicsId && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Erreur!</AlertTitle>
          <AlertDescription>{formik?.errors?.userTopicsId}</AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      )} */}
    </VStack>
  )
}
