import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons'
import {useColorMode} from '@chakra-ui/react'
import {format} from 'date-fns'
import {fr} from 'date-fns/locale'
import React from 'react'
import {dateFormat_ddMMyyyyHHmm} from '../../commons/constantes'
import {Question} from '../../commons/types/API'
import {primaryColor} from '../../theme/app-constantes'
import {Box, Flex, HStack, IconButton, Link, Stack, Text} from '../ui'
import {Link as DomRouterLink} from 'react-router-dom'
import {ROUTE_ADMIN_FAQ_QUESTION_EDITOR} from '../../constantes'
import {questionTitleOrReword} from '../../commons/helpers/screen'

type ResumeReponseProps = {
  question: Question
  onUpdateReponse: (e: React.MouseEvent, value: string) => void
  onDeleteReponse: (e: React.MouseEvent, value: string) => void
}
export const ResumeReponse = ({
  question,
  onUpdateReponse,
  onDeleteReponse,
}: ResumeReponseProps) => {
  const [category, setCategory] = React.useState<string>('Non défini')
  const [createdAt, setCreatedAt] = React.useState<string>('Non défini')
  const [openVideo, setOpenVideo] = React.useState<Boolean>(false)
  const {colorMode} = useColorMode()

  React.useEffect(() => {
    const createdAtData = reponseDate(question?.createdAt)
    setCreatedAt(createdAtData)

    setCategory(question?.category?.name ?? 'Non défini')
  }, [question])

  const reponseDate = (date: any) => {
    let created = '01/01/2022'
    if (date) {
      created = format(new Date(date), dateFormat_ddMMyyyyHHmm, {
        locale: fr,
      })
    }
    return created
  }

  const viewVideo = () => {
    if (openVideo === false) {
      setOpenVideo(true)
    } else {
      setOpenVideo(false)
    }
  }

  // Si la reformulation est vide, on affiche le titre original de la question
  const questionTitle = questionTitleOrReword(question)

  return (
    <Box my={3}>
      <Flex justifyContent="space-between" align="center">
        <Stack>
          <HStack spacing={1}>
            <Text variant="subFormation">catégorie :</Text>
            <Text variant="subjectQuestion" color={primaryColor({colorMode})}>
              {category}
            </Text>
          </HStack>
          <Link
            as={DomRouterLink}
            to={`${ROUTE_ADMIN_FAQ_QUESTION_EDITOR}/${question.id}`}
          >
            <Text variant="popup" cursor={'pointer'}>
              {questionTitle}
            </Text>
          </Link>
        </Stack>
        <Stack>
          <Flex w="full" justifyContent={'flex-end'}>
            <IconButton
              icon={
                openVideo === false ? (
                  <TriangleDownIcon color="gray.500" />
                ) : (
                  <TriangleUpIcon color="gray.500" />
                )
              }
              aria-label="list"
              variant="ghost"
              mt={-3}
              onClick={e => {
                viewVideo()
              }}
            />
          </Flex>

          <Text>{createdAt}</Text>
        </Stack>
      </Flex>
    </Box>
  )
}

export default ResumeReponse
