import {ContainerPage} from '../../../components/ui/Container'
import {Stack} from '../../../components/ui'
import {TopicDetail} from '../../../components/Topics/TopicDetail'
import {useParams} from 'react-router-dom'
import CardPresentationTopic from '../../../components/Topics/CardPresentationTopic'
import {useTopic} from '../../../commons/api/hooks'
import {Topic} from '../../../commons/types/API'
import TopicListSkeleton from '../../../components/Skeletons/TopicListSkeleton'

const TopicDetailPage = () => {
  const {topicId} = useParams()
  //const navigate = useNavigate()
  //const {schoolId} = useAuth()
  //const {workspace} = useWorkspace(workspaceId as string)
  const {topic} = useTopic(topicId as string)
  //console.log('topicId params', topic)
  if (!topic) {
    return <TopicListSkeleton></TopicListSkeleton>
  }
  return (
    <ContainerPage>
      <Stack spacing={4}>
        <CardPresentationTopic topic={topic as Topic} />
        <TopicDetail topicId={topicId} />
      </Stack>
    </ContainerPage>
  )
}
export {TopicDetailPage}
