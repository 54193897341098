import * as React from 'react'
import QuestionCard from './questionCard'
import {
  useCreateConsultation,
  useDeleteQuestion,
  useQuestionsByStatusSortByDate,
} from '../../commons/api/hooks'
import {ModalForm} from '../Modals'
import {
  CreateFAQConsultationInput,
  Question,
  UpdateFAQConsultationInput,
  ModelSortDirection,
} from '../../commons/types/API'
import {useColorModeValue, useDisclosure} from '@chakra-ui/react'
import ConsultationForm from '../Forms/ConsultationForm'
import QuestionForm from '../Forms/QuestionForm'
import HeaderTable from '../Table/HeaderTable'
import {useAuthDetails, useAuthUserId} from '../../context/AuthContext'
import {
  buildFilterByQuestionsSchoolId,
  buildFilterSearchQuestion,
} from '../../commons/helpers/graphQLHelper'
import {PAGINATION_ADMIN_CONSULTATIONS_DISPLAY} from '../../commons/constantes'
import SelectStatus from '../Selects/select-status'
import {Box, Stack} from '../ui'
import Pagination from '../Table/Pagination'
import ModalContainer from 'react-modal-promise'
import {EStatus} from '../../types/graphQL'
import {useOpenPopupDelete, usePagination} from '../../hooks/commonActions'
import {useOpenQuestion, useSaveQuestion} from '../../hooks/questionsActions'

type caterogiesFilter = {
  filter: {
    id: string
    name: string
  }
}
export const FilteredCategoriesTab = ({filter}: caterogiesFilter) => {
  const [searchTerm, setSearchTerm] = React.useState<string>()
  const {schoolId} = useAuthDetails()
  const [filterStatus, setFilterStatus] = React.useState(EStatus.NEW)
  const borderBottomColor = useColorModeValue('gray.100', 'gray.500')
  const [sortDirection] = React.useState<ModelSortDirection>(
    ModelSortDirection.ASC,
  )
  const {next, prev, nextToken, setNextNextToken, hasNext, hasPrev} =
    usePagination()
  const limit = PAGINATION_ADMIN_CONSULTATIONS_DISPLAY
  const {questions} = useQuestionsByStatusSortByDate({
    status: filterStatus,
    sortDirection: sortDirection
      ? ModelSortDirection.DESC
      : ModelSortDirection.ASC,
    nextToken: nextToken,
    limit: searchTerm ? 10000 : limit,
    filter: {
      ...buildFilterSearchQuestion(searchTerm),
      ...buildFilterByQuestionsSchoolId(schoolId),
      questionCategoryId: {eq: filter?.id},
    },
  })
  React.useEffect(() => {
    setNextNextToken(questions?.nextToken ?? '')
  }, [questions, setNextNextToken])

  const {
    isEdit,
    selectedQuestion,
    isOpen: isOpenQuestion,
    onOpen: onOpenQuestion,
    onClose: onCloseQuestion,
    handleOpenUpdateQuestion,
  } = useOpenQuestion({questions})
  const {handleSaveQuestion, isLoading} = useSaveQuestion({
    onSuccess: onCloseQuestion,
    isEdit,
  })
  const uid = useAuthUserId()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const mutationCreate = useCreateConsultation({onSuccess: onClose})
  const mutationDeleteQuestion = useDeleteQuestion()
  const handleModalSaveConsultation = (
    values: UpdateFAQConsultationInput & CreateFAQConsultationInput,
  ) => {
    mutationCreate.mutate(values)
  }
  const handleStatusChange = (e: React.ChangeEvent<any>) => {
    setFilterStatus(e.target.value)
  }
  const handleDelete = useOpenPopupDelete({
    allData: questions?.items,
    mutationDelete: mutationDeleteQuestion,
    children: <div>Souhaitez vous vraiment supprimer la question</div>,
  })
  return (
    <>
      <Stack spacing={4}>
        <HeaderTable
          util="question-categories-filtered"
          onAdd={onOpenQuestion}
          onSearch={setSearchTerm}
          customTitle={filter?.name}
        />
        <SelectStatus
          label="Filtrer par status"
          name="status"
          id="status"
          onChange={handleStatusChange}
          value={filterStatus as string}
          // errorMessage={formik?.errors?.status}
        />
        <Box>
          {questions?.items.map((question, idx) => (
            <Box
              key={idx}
              _last={{borderBottom: 'none'}}
              borderBottom={'1px'}
              borderColor={borderBottomColor}
            >
              <QuestionCard
                question={question}
                onUpdateQuestion={handleOpenUpdateQuestion}
                onDeleteQuestion={handleDelete}
                onViewQuestion={onOpenQuestion}
              />
            </Box>
          ))}
        </Box>
        <Pagination
          next={next}
          prev={prev}
          current={nextToken}
          hasNext={hasNext}
          hasPrev={hasPrev}
          isLoading={false}
          hidden={!hasPrev && !hasNext}
        />
      </Stack>
      <ModalForm
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        title={isEdit ? 'Modifier une consultation' : 'creer une consultation'}
      >
        <ConsultationForm
          isModeConsultationStep={false}
          onSubmit={handleModalSaveConsultation}
          onClose={onClose}
          isLoading={mutationCreate.isLoading}
        />
      </ModalForm>
      <ModalForm
        onOpen={onOpenQuestion}
        onClose={onCloseQuestion}
        isOpen={isOpenQuestion}
        title={isEdit ? 'Modifier une question' : 'Poser une question'}
      >
        <QuestionForm
          isModeConsultationStep={false}
          isEdit={isEdit}
          onSubmit={handleSaveQuestion}
          onClose={onCloseQuestion}
          //initialValues={selectedQuestion}
          initialValues={{
            questionCategoryId: selectedQuestion?.category?.id,
            questionAnswerId: selectedQuestion?.answer?.id,
            fAQConsultationQuestionsId: selectedQuestion?.faqConsultation?.id,
            //tagsData: selectedQuestion?.tags?.items?.map(q => q?.fAQTagID),
            tagsData: selectedQuestion?.tags?.items,
            ...(selectedQuestion as Question),
            //this pattern or inside the form ?
            questionAuthorId: (selectedQuestion?.author?.id as string) ?? uid,
          }}
          isLoading={isLoading}
        />
      </ModalForm>
      <ModalContainer />
      {/* <Pagination /> */}
    </>
  )
}
