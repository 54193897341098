import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorMode,
} from '@chakra-ui/react'
import {useWorkspacesBySchoolId} from '../../commons/api/hooks/workspace'
import {useAuth} from '../../context/AuthContext'
import {Flex, Link, Text} from '../ui'
import {Link as DomRouterLink} from 'react-router-dom'
import {ROUTE_SCHOOL_TOPIC} from '../../constantes'
import TextRowSkeleton from '../Skeletons/TextRowSkeleton'
import {useLocation} from 'react-router-dom'
import {primaryTextColor} from '../../theme/app-constantes'
import {sortWorkspaces} from '../../commons/helpers/dataHelper'
export const NavForum = () => {
  const {schoolId} = useAuth()
  const {workspaces, isLoading} = useWorkspacesBySchoolId({
    input: {workspaceSchoolId: schoolId},
    custom: false,
  })
  const {colorMode} = useColorMode()
  // const bgLink = bgSidebarColor({colorMode})
  const {pathname} = useLocation()
  const activeWorkspaceId = pathname.split('/')[3]

  const textColorSelected = primaryTextColor({colorMode})

  //console.log('workspaces', workspaces)
  const hasWorkspace = workspaces && workspaces.items.length > 0
  // Pour reprendre comme la maquette il faudrait que je puisse faire un appel du type getWorkspacesByUserId(id) et comme ça j'affiche les worspaces sur lesquels il peut interargir, le Owner lui peut y accéder depuis onglet Workspaces dans l'autre partie du menu
  // if (role === 'Eleve') { PAS DE CUSTOM ELEVE
  sortWorkspaces(workspaces?.items)
  return (
    <>
      <Accordion
        allowToggle
        defaultIndex={[0]}
        my={4}
        p={4}
        borderRadius={'10px'}
        border={'0px solid transparent'}
      >
        <AccordionItem>
          <AccordionButton
            _expanded={{bg: 'transparent'}}
            _hover={{background: 'transparent'}}
            justifyContent={'space-between'}
          >
            <Text
              my={1}
              variant="nameMenu"
              cursor="pointer"
              color={textColorSelected}
            >
              Espaces de travail
            </Text>
            <AccordionIcon color={textColorSelected} />
          </AccordionButton>
          <AccordionPanel>
            {workspaces?.items
              .filter(workspace => !workspace?.courseWorkspacesId)
              .map((workspace, idx) => (
                <Link
                  key={idx}
                  to={`${ROUTE_SCHOOL_TOPIC}/${workspace?.id}`}
                  as={DomRouterLink}
                >
                  <Flex>
                    <Text
                      my={2}
                      variant="cardProg"
                      cursor="pointer"
                      opacity={workspace?.id === activeWorkspaceId ? 1 : 0.5}
                      color={textColorSelected}
                    >
                      {workspace?.name}
                    </Text>{' '}
                  </Flex>
                </Link>
              ))}

            {workspaces?.items
              .filter(workspace => workspace?.courseWorkspacesId)
              .map((workspace, idx) => (
                <Link
                  key={idx}
                  to={`${ROUTE_SCHOOL_TOPIC}/${workspace?.id}`}
                  as={DomRouterLink}
                >
                  <Flex flexDirection={'row'} w={'100%'} alignItems="center">
                    <Text
                      my={2}
                      variant="cardProg"
                      cursor="pointer"
                      opacity={workspace?.id === activeWorkspaceId ? 1 : 0.5}
                      color={textColorSelected}
                      w={'90%'}
                    >
                      {workspace?.name}
                    </Text>
                    <BookMarkIcon
                      width={'18px'}
                      color={colorMode === 'light' ? '#1a202c' : 'white'}
                      opacity={workspace?.id === activeWorkspaceId ? 1 : 0.5}
                    />
                  </Flex>
                </Link>
              ))}
          </AccordionPanel>
          {isLoading && <TextRowSkeleton />}
          {!isLoading && !hasWorkspace && (
            <Text my={4} variant="nameMenu">
              Aucun Espace
            </Text>
          )}
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default NavForum

export const BookMarkIcon = ({
  color = 'white',
  width = '24px',
  opacity = 1,
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        d="M6 4.79999C6 4.16347 6.25286 3.55302 6.70294 3.10294C7.15303 2.65285 7.76348 2.39999 8.4 2.39999H15.6C16.2365 2.39999 16.847 2.65285 17.2971 3.10294C17.7471 3.55302 18 4.16347 18 4.79999V21.6L12 18.6L6 21.6V4.79999Z"
        fill={color}
      />
    </svg>
  )
}
