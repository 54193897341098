import React from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  Center,
  VStack,
} from '@chakra-ui/react'
import {Question} from '../../commons/types/API'
import {Box, Text} from '../ui'
import ReactPlayer from 'react-player'
import VideoSkeleton from '../Skeletons/VideoQuestionSkeleton'
import {convertStructureURL} from '../../commons/helpers/dataHelper'
import {VideoType} from '../../types'

type ReponseVideoProps = {
  question?: Question
  br?: number
  videoFor?: string
}
export const ReponseVideo = ({
  question,
  br = 0,
  videoFor = 'question',
}: ReponseVideoProps) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<boolean>(false)
  //console.log('loading', loading)
  const urlValid = true //validURL(question?.answerUrl)
  const isVideoFor =
    videoFor === 'question'
      ? question?.answerUrl ?? ''
      : question?.questionVideoUrl ?? ''

  const questionUrl = convertStructureURL(isVideoFor, [VideoType.GDRIVE])

  return (
    <>
      {urlValid ? (
        <>
          {isVideoFor ? (
            <Box mt={2}>
              <AspectRatio
                maxW="100%"
                ratio={1.75}
                my={4}
                hidden={!error}
                style={{backgroundColor: '#00000020'}}
              >
                <Center>
                  <VStack>
                    <Text mb={10}>Impossible de charger la video</Text>
                  </VStack>
                </Center>
              </AspectRatio>
              <AspectRatio maxW="100%" ratio={1.75} my={4} hidden={!loading}>
                <VideoSkeleton />
              </AspectRatio>
              <AspectRatio
                maxW="100%"
                ratio={1.75}
                my={4}
                hidden={loading || error}
              >
                <Box borderRadius={br}>
                  <ReactPlayer
                    url={questionUrl ?? ''}
                    overflow={'hidden'}
                    //url={'https://vimeo.com/695528550'}
                    width="100%"
                    height="100%"
                    light={false}
                    // light="https://i.ytimg.com/vi/HS3Q_dKAHZk/hqdefault.jpg"
                    controls={true}
                    onReady={() => {
                      setLoading(false)
                    }}
                    onError={() => {
                      setLoading(false)
                      setError(true)
                    }}
                    config={{
                      youtube: {
                        //  embedOptions: {width: '1080', height: '1920'},
                        playerVars: {showinfo: 1},
                      },
                      facebook: {
                        appId: '12345',
                      },
                    }}
                  />
                </Box>
              </AspectRatio>
            </Box>
          ) : videoFor === 'question' ? (
            <Box>Pas encore de vidéo de réponse</Box>
          ) : (
            ''
          )}
        </>
      ) : (
        <Box mt={5}>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Problème pour afficher le Player : </AlertTitle>
            <AlertDescription>URL de vidéo non valide</AlertDescription>
          </Alert>
        </Box>
      )}
    </>
  )
}
