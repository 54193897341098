import * as React from 'react'
import {FAQConsultation, Question} from '../commons/types/API'
import {CreateConsultationSteps} from '../types/screen'
// https://kentcdodds.com/blog/how-to-use-react-context-effectively

type State = {
  consultation?: FAQConsultation
  questions: Question[]
  step: CreateConsultationSteps
}

type Action =
  | {type: 'addConsultation'; payload: FAQConsultation}
  | {type: 'addQuestion'; payload: Question}
  | {type: 'deleteQuestion'; payload: Question}
  | {type: 'clear'}

type Dispatch = (action: Action) => void

//type pout le contexte
export type CreateConsultationContextStore = {
  consultation?: FAQConsultation
  questions: Question[]
  addConsultation: (data: FAQConsultation) => void
  addQuestion: (data: Question) => void
  deleteQuestion: (data: Question) => void
  clear: () => void
  dispatch: Dispatch
}

/* Declaration du contexte*/
const CreateConsultationContext =
  React.createContext<CreateConsultationContextStore>({
    consultation: undefined,
    questions: [],
    addConsultation: () => {}, // To be implemented in provider
    addQuestion: () => {}, // To be implemented in provider
    deleteQuestion: () => {}, // To be implemented in provider
    clear: () => {}, // To be implemented in provider
    dispatch: () => {}, // To be implemented in provider
  })

const reducer = (state: State, action: Action) => {
  //console.log('state.consultation', state.consultation)

  switch (action.type) {
    case 'addConsultation':
      return {
        ...state,
        consultation: action.payload,
      }
    case 'addQuestion':
      //console.log('addquestion', action.payload)

      return {
        ...state,
        questions: [
          action.payload,
          ...state.questions.filter(
            question => question.id !== action.payload.id,
          ),
        ],
      }
    case 'deleteQuestion':
      return {
        ...state,
        questions: [
          ...state.questions.filter(
            question => question.id !== action.payload.id,
          ),
        ],
      }
    case 'clear':
      return {
        ...state,
        questions: [],
        consultation: undefined,
      }
    default:
      throw new Error('Action non supporté')
  }
}

const useCreateConsultationStepper = () => {
  const context = React.useContext(CreateConsultationContext)
  if (!context) {
    throw new Error(
      "useAdvanced() s'utilise avec <CreateConsultationContext.provider>",
    )
  }
  return context
}

const CreateConsultationProvider = (props: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, {
    questions: [],
    consultation: undefined,
    step: CreateConsultationSteps.create,
  })

  const addConsultation = React.useCallback(consultation => {
    //console.log('addConsultation', consultation)

    dispatch({
      type: 'addConsultation',
      payload: consultation,
    })
  }, [])
  const addQuestion = React.useCallback(question => {
    dispatch({
      type: 'addQuestion',
      payload: question,
    })
  }, [])
  const deleteQuestion = React.useCallback(question => {
    dispatch({
      type: 'deleteQuestion',
      payload: question,
    })
  }, [])
  const clear = React.useCallback(() => {
    dispatch({
      type: 'clear',
    })
  }, [])

  const {questions, consultation} = state
  const value = React.useMemo(
    () => ({
      consultation,
      questions,
      addConsultation,
      addQuestion,
      deleteQuestion,
      clear,
      dispatch,
    }),
    [
      addConsultation,
      addQuestion,
      deleteQuestion,
      clear,
      consultation,
      questions,
      dispatch,
    ],
  )
  return <CreateConsultationContext.Provider value={value} {...props} />
}

export {
  CreateConsultationContext,
  useCreateConsultationStepper,
  CreateConsultationProvider,
}
