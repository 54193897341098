import {useWorkspacesBySchoolId} from '../../commons/api/hooks/workspace'
import {sortWorkspaces} from '../../commons/helpers/dataHelper'
import {useAuth} from '../../context/AuthContext'
import {Select, SelectionProps} from '../ui'

export const SelectWorkspaces = ({...rest}: SelectionProps) => {
  const {schoolId} = useAuth()
  const {workspaces} = useWorkspacesBySchoolId({
    input: {workspaceSchoolId: schoolId},
    custom: false,
  })

  sortWorkspaces(workspaces?.items)

  return (
    <Select maxW="300px" {...rest}>
      <option value="">Selectionner un espace de travail</option>
      {workspaces?.items?.map((option, idx) => (
        <option key={idx} value={option?.id}>
          {option?.name}
        </option>
      ))}
    </Select>
  )
}

export default SelectWorkspaces
