import React from 'react'
import {
  CloseButton as ChakraCloseButton,
  CloseButtonProps,
} from '@chakra-ui/react'

export const CloseButton = (props: CloseButtonProps) => {
  return <ChakraCloseButton {...props}></ChakraCloseButton>
}
export default CloseButton
