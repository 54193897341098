import React from 'react'
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from '@emotion/styled'
import {FormInput, FormInputProps} from './Input'
//import {useAppTheme} from '../../context/ThemeContext'
import {css} from '@emotion/react'

const DatePickerElementWrapper = styled(DatePicker)`
  ${props => {
    //const {theme} = useAppTheme()
    return css``
  }}
`

// OMIT PARTIAL DIT ARRETE DE FAIRE CHIER AVEC CE TYPE EXCLUE LE
type DatePickerCustomProps = ReactDatePickerProps &
  Partial<Omit<FormInputProps, 'value'>>

export const DatePickerCustom = ({...props}: DatePickerCustomProps) => {
  //registerLocale('fr', fr)
  // FORMAT LA DATE DAFFICHAGE DANS LINPUT
  // const formatedDateValue =
  //   props.value && isValid(new Date(props.value))
  //     ? format(
  //         new Date(props.value),
  //         (props?.dateFormat as string) || 'dd/MM/yyyy',
  //       )
  //     : null
  return (
    <DatePickerElementWrapper
      locale="fr"
      dateFormat={props.dateFormat ? props.dateFormat : 'dd/MM/yyyy'}
      customInput={
        <FormInput label={props.label} errorMessage={props.errorMessage} />
      }
      {...props}
      value={'formatedDateValue' as string}
    ></DatePickerElementWrapper>
  )
}
export default DatePickerCustom
