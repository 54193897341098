import awsconfig from '../../aws-exports'
import {Storage} from 'aws-amplify'

export interface IResizeImageOptions {
  maxSize: number
  file: File
}
export interface IResizeImageReturn {
  blob: Blob
  name: string
}
export enum ImageObjectType {
  user = 'user',
  school = 'school',
  course = 'course',
  workspace = 'workspace',
  question = 'question',
}

export const resizeImage = (settings: IResizeImageOptions) => {
  const file = settings.file
  const maxSize = settings.maxSize
  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')
  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1])
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const max = bytes.length
    const ia = new Uint8Array(max)
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
    return new Blob([ia], {type: mime})
  }
  const resize = () => {
    let width = image.width
    let height = image.height

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width
        width = maxSize
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height
        height = maxSize
      }
    }

    canvas.width = width
    canvas.height = height
    canvas!.getContext('2d')!.drawImage(image, 0, 0, width, height)
    const dataUrl = canvas.toDataURL(file.type)
    const blob = dataURItoBlob(dataUrl)
    const fileData2 = {name: file.name, blob: blob}
    return fileData2
  }

  return new Promise<IResizeImageReturn>((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'))
      return
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize())
      image.src = readerEvent.target.result
    }
    reader.readAsDataURL(file)
  })
}

export const uploadFile = async (
  file: any,
  type: ImageObjectType,
  subDirectory: string,
) => {
  try {
    //throw new Error('Error gros')
    const mainDirectory = 'public'
    const imageName = `${type}/${subDirectory}/${file.name
      .replace(/[^\w\s-]/gi, '')
      .replace(/\s+/g, '-')}`

    const result = await Storage.put(imageName, file.blob, {
      //@ts-ignore
      level: 'public-read',
      contentType: 'image/png',
    })
    //console.log('result put S3', result)
    const s3Url = `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/${mainDirectory}/`
    return `${s3Url}${result?.key}`
  } catch (error) {
    console.error("Erreur lors de l'upload du fichier:", error)
    throw error
  }
}
