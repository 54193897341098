import React from 'react'
import {compareAsc, format, sub} from 'date-fns'
import {fr} from 'date-fns/locale'
import {Link as DomRouterLink, useNavigate} from 'react-router-dom'
import {
  Avatar,
  Container,
  Flex,
  Stack,
  Text,
  Tag,
  Icon,
  HStack,
  Link,
  Box,
} from '../ui'
import {RiQuestionAnswerLine} from 'react-icons/ri'
import {MdDelete, MdEdit, MdStarBorder} from 'react-icons/md'
import {
  dateFormat_ddMMyyyyHHmm,
  NB_DAYS_OLD_TOPICS,
} from '../../commons/constantes'
import {useDeleteTopic} from '../../commons/api/hooks/topic'
import ModalContainer from 'react-modal-promise'
import {Topic} from '../../commons/types/API'
import {useCanUpdateTopic} from '../../context/AuthContext'
import {useOpenPopupDelete} from '../../hooks/commonActions'
import ReactMarkDownChakra from '../ui/ReactMarkDownChakra'
import {
  ROUTE_SCHOOL_TOPIC,
  ROUTE_SCHOOL_TOPIC_EDITOR,
  ROUTE_SCHOOL_USER,
} from '../../constantes'
import {bgColor, textButtonColorAlwaysLight} from '../../theme/app-constantes'
import {
  getTopicRandomView,
  markdownToText,
} from '../../commons/helpers/dataHelper'

import {useWorkspace} from '../../commons/api/hooks'
import {TagLabel, useColorMode} from '@chakra-ui/react'
import {ViewIcon} from '@chakra-ui/icons'
import {LinkBox, LinkOverlay} from '@chakra-ui/react'
import {displayNameForAvatar, displayUserNameAndName} from '../../commons'

type CardTopicProps = {
  topic: Topic
  onUpdateTopic?: (e: React.MouseEvent, value: string) => void
}
export const CardTopic = ({topic}: CardTopicProps) => {
  const username = displayUserNameAndName(topic?.user)
  const {colorMode} = useColorMode()
  const image = topic?.user?.image ?? ''
  const topicName = topic?.name
  const topicDesc = topic?.description
  const colorButton = textButtonColorAlwaysLight
  const {workspace} = useWorkspace(topic?.workspace?.id ?? '')
  const imageWS = workspace?.course?.image ?? ''

  const canUpdateTopic = useCanUpdateTopic(topic)
  const navigate = useNavigate()
  const mutation = useDeleteTopic()

  const created = format(new Date(topic?.createdAt), dateFormat_ddMMyyyyHHmm, {
    locale: fr,
  })
  const result = sub(new Date(), {
    days: NB_DAYS_OLD_TOPICS,
  })
  const dayAgo = compareAsc(new Date(topic?.createdAt), result)
  const handleClick = () => {
    navigate(`${ROUTE_SCHOOL_TOPIC_EDITOR}/${topic?.id}`)
  }

  //console.log('date', dayAgo, new Date(topic?.createdAt), result)

  const handleDelete = useOpenPopupDelete({
    allData: [topic],
    mutationDelete: mutation,
    title: 'Confirmer',
    confirmLabel: 'Supprimer',
    header: `${topic.name}`,
    children: (
      <div>
        <ReactMarkDownChakra hideImg>{topicDesc ?? ''}</ReactMarkDownChakra>
      </div>
    ),
  })

  return (
    <Container variant="generalBg">
      <LinkBox>
        <LinkOverlay
          as={DomRouterLink}
          to={`${ROUTE_SCHOOL_TOPIC}/${topic?.workspace?.id ?? 'all'}/${
            topic?.id
          }`}
        />
        <Flex justifyContent={'space-between'}>
          <Flex>
            <Link
              cursor="pointer"
              as={DomRouterLink}
              to={`${ROUTE_SCHOOL_USER}/${topic?.user?.id}`}
            >
              <Avatar
                size="md"
                mr={4}
                name={displayNameForAvatar(topic?.user)}
                src={image}
                user={topic?.user}
              />
            </Link>
            <Flex align="center">
              <Stack w="full">
                <Text variant="label">{username}</Text>
                <Text variant="dateCardTopic" mt={-2}>
                  {created}
                </Text>
              </Stack>
            </Flex>
          </Flex>
          <Flex align="center">
            {dayAgo === 1 ? (
              <Tag
                variant="solid"
                size="lg"
                colorScheme="pink"
                color={colorButton}
              >
                Nouveau
              </Tag>
            ) : (
              <Tag
                variant="solid"
                size="lg"
                colorScheme="blue"
                color={colorButton}
              >
                + d'un mois
              </Tag>
            )}
          </Flex>
        </Flex>

        <Text
          sx={{
            '&::first-letter': {
              textTransform: 'uppercase',
            },
          }}
          mt={4}
          variant="label"
          cursor="pointer"
        >
          {topicName}
        </Text>
        <Text
          sx={{
            '&::first-letter': {
              textTransform: 'uppercase',
            },
          }}
          fontSize={'16px'}
          variant={'contentText'}
          noOfLines={2}
          textTransform="lowercase"
          style={{lineBreak: 'anywhere'}}
          maxW={'100%'}
        >
          {markdownToText(topicDesc ?? '')}
        </Text>
      </LinkBox>
      <Flex justifyContent={'space-between'} mt={4}>
        <HStack spacing={2}>
          <Link
            cursor="pointer"
            as={DomRouterLink}
            to={`${ROUTE_SCHOOL_TOPIC}/${topic?.workspace?.id ?? 'all'}/${
              topic?.id
            }`}
          >
            <Flex flexDirection={'row'}>
              <Icon w={6} h={6} mr={1} as={RiQuestionAnswerLine} />
              <Text>{topic?.messages?.items?.length ?? '?'}</Text>
            </Flex>
          </Link>
          <Flex flexDirection={'row'}>
            <Icon w={6} h={6} mr={1} as={ViewIcon} />
            <Text>{getTopicRandomView(topic.name ?? '')}</Text>
          </Flex>
          <Icon w={6} h={6} as={MdStarBorder} />
          {canUpdateTopic === true && (
            <>
              <Icon
                cursor={'pointer'}
                w={6}
                h={6}
                as={MdEdit}
                onClick={handleClick}
              />
              <Icon
                cursor={'pointer'}
                w={6}
                h={6}
                as={MdDelete}
                onClick={(e: React.MouseEvent) => handleDelete(e, topic.id)}
              />
            </>
          )}
        </HStack>
        <Link as={DomRouterLink} to={`${ROUTE_SCHOOL_TOPIC}/${workspace?.id}`}>
          <Tag
            size="sm"
            bgColor={bgColor({colorMode})}
            borderRadius="full"
            p={1}
            maxW={'200px'}
            _hover={{maxW: '450px'}}
            cursor={'pointer'}
            transition={'0.5s'}
          >
            <Box
              w={'30px'}
              h={'30px'}
              minW={'30px'}
              mr={2}
              bgImage={imageWS ? imageWS : '/images/default-thumb.jpg'}
              bgSize={'cover'}
              bgRepeat={'no-repeat'}
              bgPosition={'center'}
              borderRadius={'full'}
            />
            <TagLabel fontSize={'2xs'} mr={2}>
              {`${topic?.workspace?.name ?? ''} : ${
                topic?.workspace?.description ?? ''
              }` ?? ''}
            </TagLabel>
          </Tag>
        </Link>
      </Flex>
      <ModalContainer />
    </Container>
  )
}

export default CardTopic
