import {
  ChatMessage,
  ChatRoom,
  Course,
  ModelChatMessageConnection,
  ModelChatRoomConnection,
  ModelUserChatRoomConnection,
  User,
  UserChatRoom,
  UserCourses,
} from './API'

export interface GraphQLCourseCommonResult {
  getCourse?: Course
  getCoursesBySchoolId?: [Course]
  listCourses?: {items: [Course]; nextToken: any}
  updateCourse?: Course
  createCourse?: Course
  deleteCourse?: Course
  listUserCourses?: {items: [UserCourses]; nextToken: any}
}

export interface GraphQLChatRoomCommonResult {
  getChatRoom?: ChatRoom
  listChatRooms?: ModelChatRoomConnection
  createChatRoom?: ChatRoom
  updateChatRoom?: ChatRoom
  deleteChatRoom?: ChatRoom
}

export interface GraphQLChatMessageCommonResult {
  getChatMessage?: ChatMessage
  listChatMessages?: ModelChatMessageConnection
  createChatMessage?: ChatMessage
  updateChatMessage?: ChatMessage
  deleteChatMessage?: ChatMessage
}

export interface GraphQLUserChatRoomsCommonResult {
  getUserChatRoom?: [UserChatRoom]
  listUserChatRooms?: ModelUserChatRoomConnection
  createUserChatRoom?: UserChatRoom
  updateUserChatRoom?: UserChatRoom
  deleteUserChatRoom?: UserChatRoom
}

export interface GraphQLUserChatMessageCommonResult {
  getChatMessagesByUser?: ModelChatMessageConnection
}

export interface GraphQLUserCommonResult {
  me?: User
  updateUser?: User
  createUser?: User
  deleteUser?: User
}

export enum ErrorMessages {
  ReturnNotNull = 'Cannot return null for non-nullable type',
  OtherError = 'Cannot return null for non-nullable type',
}
