import {GraphQLResult} from '@aws-amplify/api-graphql'
import {API, graphqlOperation} from 'aws-amplify'

import {
  createChatMessage as createChatMessageMutation,
  createChatRoom as createChatRoomMutation,
  deleteChatMessage as deleteChatMessageMutation,
  deleteChatRoom as deleteChatRoomMutation,
  updateChatMessage as updateChatMessageMutation,
  updateChatRoom as updateChatRoomMutation,
  createUserChatRoom as createUserChatRoomsMutation,
  deleteUserChatRoom as deleteUserChatRoomsMutation,
} from '../../graphql/mutations'
import {
  chatMessagesByUser,
  getChatMessage,
  getChatRoom,
  getUserChatRoom,
  listChatMessages,
  listChatRooms,
  listUserChatRooms,
} from '../../graphql/queries'
import {onCreateChatMessage as onCreateChatMessageMutation} from '../../graphql/subscriptions'
import {getChatRoomCustom, meCustomChatRoom} from '../../graphql/custom/queries'
import {
  ChatMessagesByUserQueryVariables,
  CreateChatMessageInput,
  CreateChatRoomInput,
  CreateUserChatRoomInput,
  DeleteChatMessageInput,
  DeleteChatRoomInput,
  DeleteUserChatRoomInput,
  GetChatMessageQueryVariables,
  GetChatRoomQueryVariables,
  GetUserChatRoomQueryVariables,
  ListChatMessagesQueryVariables,
  ListChatRoomsQueryVariables,
  ListUserChatRoomsQueryVariables,
  ModelUserChatRoomConnection,
  OnCreateChatMessageSubscription,
  OnCreateChatMessageSubscriptionVariables,
  UpdateChatMessageInput,
  UpdateChatRoomInput,
  // UpdateUserChatRoomsInput,
} from '../../types/API'
import {
  GraphQLChatRoomCommonResult,
  GraphQLChatMessageCommonResult,
  GraphQLUserChatRoomsCommonResult,
  GraphQLUserChatMessageCommonResult,
  GraphQLUserCommonResult,
  ErrorMessages,
} from '../../types/graphQLCommonTypes'
import {handleUserChatRoomsError} from '../../helpers/graphQLHelper'
import {GraphQLSubscription} from '@aws-amplify/api'

// Chat Room
export const getChatRoomById = async (
  chatRoomId: GetChatRoomQueryVariables,
) => {
  const isCustom = true
  const operation = isCustom ? getChatRoomCustom : getChatRoom
  const data = await (API.graphql(
    graphqlOperation(operation, {id: chatRoomId.id}),
  ) as Promise<GraphQLResult<GraphQLChatRoomCommonResult>>)
  return data?.data?.getChatRoom
}

export const getListChatRooms = async (input?: ListChatRoomsQueryVariables) => {
  const data = await (API.graphql(
    graphqlOperation(listChatRooms, input),
  ) as Promise<GraphQLResult<GraphQLChatRoomCommonResult>>)
  return data?.data?.listChatRooms
}

export const createChatRoom = async (chatRoom: CreateChatRoomInput) => {
  delete chatRoom?.id
  const data = await (API.graphql(
    graphqlOperation(createChatRoomMutation, {input: chatRoom}),
  ) as Promise<GraphQLResult<GraphQLChatRoomCommonResult>>)
  return data?.data?.createChatRoom
}
export const updateChatRoom = async (chatRoom: UpdateChatRoomInput) => {
  const data = await (API.graphql(
    graphqlOperation(updateChatRoomMutation, {input: chatRoom}),
  ) as Promise<GraphQLResult<GraphQLChatRoomCommonResult>>)
  return data?.data?.updateChatRoom
}
export const deleteChatRoom = async (chatRoom: DeleteChatRoomInput) => {
  const data = await (API.graphql(
    graphqlOperation(deleteChatRoomMutation, {input: chatRoom}),
  ) as Promise<GraphQLResult<GraphQLChatRoomCommonResult>>)
  return data?.data?.deleteChatRoom
}

// Chat Message

export const getChatMessageById = async (
  chatMessageId: GetChatMessageQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(getChatMessage, {input: chatMessageId}),
  ) as Promise<GraphQLResult<GraphQLChatMessageCommonResult>>)
  return data?.data?.getChatMessage
}

export const getListChatMessages = async (
  input?: ListChatMessagesQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(listChatMessages, input),
  ) as Promise<GraphQLResult<GraphQLChatMessageCommonResult>>)
  return data?.data?.listChatMessages
}

export const createChatMessage = async (
  chatMessage: CreateChatMessageInput,
) => {
  delete chatMessage?.id
  const data = await (API.graphql(
    graphqlOperation(createChatMessageMutation, {input: chatMessage}),
  ) as Promise<GraphQLResult<GraphQLChatMessageCommonResult>>)
  return data?.data?.createChatMessage
}
export const onCreateChatMessage = (
  variables: OnCreateChatMessageSubscriptionVariables,
) => {
  const subscription = API.graphql<
    GraphQLSubscription<OnCreateChatMessageSubscription>
  >(graphqlOperation(onCreateChatMessageMutation, variables))
  return subscription
}

export const updateChatMessage = async (
  chatMessage: UpdateChatMessageInput,
) => {
  const data = await (API.graphql(
    graphqlOperation(updateChatMessageMutation, {input: chatMessage}),
  ) as Promise<GraphQLResult<GraphQLChatMessageCommonResult>>)
  return data?.data?.updateChatMessage
}

export const deleteChatMessage = async (
  chatMessage: DeleteChatMessageInput,
) => {
  const data = await (API.graphql(
    graphqlOperation(deleteChatMessageMutation, {input: chatMessage}),
  ) as Promise<GraphQLResult<GraphQLChatMessageCommonResult>>)
  return data?.data?.deleteChatMessage
}

// User ChatRoom

export const getUserChatRoomsById = async (
  userChatRoomsId: GetUserChatRoomQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(getUserChatRoom, userChatRoomsId),
  ) as Promise<GraphQLResult<GraphQLUserChatRoomsCommonResult>>)
  return data?.data?.getUserChatRoom
}

export const getListUserChatRooms = async (
  input?: ListUserChatRoomsQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(listUserChatRooms, input),
  ) as Promise<GraphQLResult<GraphQLUserChatRoomsCommonResult>>)
  return data?.data?.listUserChatRooms
}

export const createUserChatRooms = async (
  userChatRooms: CreateUserChatRoomInput,
) => {
  delete userChatRooms?.id
  const data = await (API.graphql(
    graphqlOperation(createUserChatRoomsMutation, {input: userChatRooms}),
  ) as Promise<GraphQLResult<GraphQLUserChatRoomsCommonResult>>)
  return data?.data?.createUserChatRoom
}

// export const updateUserChatRooms = async (
//   userChatRooms: UpdateUserChatRoomsInput,
// ) => {
//   const data = await (API.graphql(
//     graphqlOperation(updateUserChatRoomsMutation, {input: userChatRooms}),
//   ) as Promise<GraphQLResult<GraphQLUserChatRoomsCommonResult>>)
//   return data?.data?.updateUserChatRooms
// }

export const deleteUserChatRooms = async (
  userChatRooms: DeleteUserChatRoomInput,
) => {
  const data = await (API.graphql(
    graphqlOperation(deleteUserChatRoomsMutation, {input: userChatRooms}),
  ) as Promise<GraphQLResult<GraphQLUserChatRoomsCommonResult>>)
  return data?.data?.deleteUserChatRoom
}

// User ChatMessage

export const getChatMessagesByUser = async (
  chatMessages: ChatMessagesByUserQueryVariables,
) => {
  const data = await (API.graphql(
    graphqlOperation(chatMessagesByUser, {input: chatMessages}),
  ) as Promise<GraphQLResult<GraphQLUserChatMessageCommonResult>>)
  return data?.data?.getChatMessagesByUser
}

export const getMeChatRooms = async (variables?: any) => {
  let chatRooms
  try {
    const data = await (API.graphql(
      graphqlOperation(meCustomChatRoom, {id: '_'}),
    ) as Promise<GraphQLResult<GraphQLUserCommonResult>>)
    chatRooms = data?.data?.me?.chatRooms
    return chatRooms as ModelUserChatRoomConnection | null | undefined
  } catch (err) {
    //console.log(err)
    // traitement des erreurs a laisser passer
    chatRooms = handleUserChatRoomsError(err, [ErrorMessages.ReturnNotNull])

    return chatRooms as ModelUserChatRoomConnection | null | undefined
  }
}
