import {Stack as ChakraStack, StackProps} from '@chakra-ui/react'

export type CustomStackProps = StackProps & {
  spacing?: number
  util?: string
}

export const Stack = ({...props}: any) => {
  let spacing = 0
  let px = 0
  let mt = 0
  if ({...props}.spacing) {
    spacing = {...props.spacing}
  }
  if ({...props}.util && {...props}.util === 'cardUser') {
    spacing = 4
    px = 4
    mt = -12
  }
  return (
    <ChakraStack spacing={spacing} px={px} mt={mt} {...props}></ChakraStack>
  )
}
export default Stack
