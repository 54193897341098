import React from 'react'
import {MdDelete, MdEdit} from 'react-icons/md'
import {
  Comment,
  CreateCommentInput,
  UpdateCommentInput,
} from '../../commons/types/API'
import {format} from 'date-fns'
import {fr} from 'date-fns/locale'
import {dateFormat_ddMMyyyyHHmm} from '../../commons/constantes'
import {
  Avatar,
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
} from '../ui'
import {useCanUpdateComment} from '../../context/AuthContext'
import {CommentForm} from '../Forms/CommentForm'
import ReactMarkDownChakra from '../ui/ReactMarkDownChakra'
import {displayNameForAvatar, displayUserNameAndName} from '../../commons'

type CardCommentQuestionProps = {
  comment: Comment | null
  onDeleteComment?: (e: React.MouseEvent, value: string) => void
  onUpdateComment?: (formValue: UpdateCommentInput & CreateCommentInput) => void
  isLoading?: boolean
}
//{ handleOpenDeleteComment: (e: React.MouseEvent<Element, MouseEvent>, value: string) => void; }
//(e: MouseEvent<Element, MouseEvent>, value: string) => void'
export const CardCommentQuestion = ({
  comment,
  onDeleteComment,
  onUpdateComment,
  isLoading,
}: CardCommentQuestionProps) => {
  //console.log('comment', comment)
  //console.log('onDeleteComment', onDeleteComment)
  const canUpdateComment = useCanUpdateComment(comment as Comment)
  const [textAreaOpen, setTextAreaOpen] = React.useState<boolean>(false)
  const handleSubmit = (formValue: UpdateCommentInput & CreateCommentInput) => {
    onUpdateComment?.(formValue)
    //setTextAreaOpen(false)
  }
  React.useEffect(() => {
    setTextAreaOpen(false)
  }, [comment])
  return (
    <Flex w="full">
      <Avatar
        size={{base: 'sm', md: 'md'}}
        name={displayNameForAvatar(comment?.author)}
        user={comment?.author}
        // src={getImage(question)}
        src={comment?.author?.image as string}
        mr={4}
      />
      <Container variant="generalBg" w="full">
        <Flex>
          <Text variant="label" mr={2}>
            {displayUserNameAndName(comment?.author)}
          </Text>
          <Text fontSize="sm" mt={1}>
            le{' '}
            {format(
              new Date(comment?.createdAt as string),
              dateFormat_ddMMyyyyHHmm,
              {
                locale: fr,
              },
            )}
          </Text>
        </Flex>
        {/* <Text my={2}>{comment?.description}</Text> */}
        <ReactMarkDownChakra>{comment?.description ?? ''}</ReactMarkDownChakra>
        <Flex w="full" justifyContent="space-between">
          <HStack spacing={-1}>
            <IconButton
              hidden={!canUpdateComment}
              size="sm"
              aria-label="Call Segun"
              variant="ghost"
              icon={
                <Icon variant="generalIcons" as={MdEdit} color="gray.500" />
              }
              onClick={(e: React.MouseEvent) => setTextAreaOpen(!textAreaOpen)}
            />
            <Icon
              hidden={!canUpdateComment}
              cursor={'pointer'}
              w={6}
              h={6}
              as={MdDelete}
              onClick={(e: React.MouseEvent) =>
                onDeleteComment?.(e, comment?.id ?? '')
              }
            />
          </HStack>
          <Button variant="link">Répondre</Button>
        </Flex>
        {textAreaOpen && (
          <CommentForm
            resetForm={false}
            initialValues={{
              description: comment?.description,
              ...(comment as Comment),
            }}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          ></CommentForm>
        )}
      </Container>
    </Flex>
  )
}

export default CardCommentQuestion
