import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {
  CreateFAQConsultationInput,
  UpdateFAQConsultationInput,
} from '../../../commons/types/API'
import {useCreateConsultationStepper} from '../../../context/CreateConsultationContext'
import {CreateConsultationSteps} from '../../../types/screen'
import ConsultationForm from '../../Forms/ConsultationForm'
import {Button, Container, Flex, HStack} from '../../ui'
import {
  useConsultation,
  useCreateConsultation,
  useUpdateConsultation,
} from '../../../commons/api/hooks'
import {HeaderStep} from './HeaderStep'
import {
  ROUTE_ADMIN_FAQ_CONSULTATIONS,
  ROUTE_ADMIN_FAQ_CREATE_CONSULTATION,
} from '../../../constantes'

type StepCreateConsultationProps = {
  step?: CreateConsultationSteps
  consultationId?: string
}

export const StepCreateConsultation = ({
  step = CreateConsultationSteps.create,
  consultationId,
}: StepCreateConsultationProps) => {
  const {consultation, questions, addConsultation, addQuestion} =
    useCreateConsultationStepper()
  const navigate = useNavigate()

  const {consultation: consultationUpdate, isLoading} = useConsultation(
    consultationId ?? '',
  )

  //const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const mutationCreate = useCreateConsultation({onSuccess: addConsultation})
  const mutationUpdate = useUpdateConsultation({onSuccess: addConsultation})
  const isButtonDisabled = mutationCreate.isLoading || mutationUpdate.isLoading
  //addConsultation({id: '', title: 'consultation'})

  const isEdit = !!consultation
  //console.log('isEdit', isEdit)
  //console.log('consultation', consultation)

  const handleModalSave = (
    values: UpdateFAQConsultationInput & CreateFAQConsultationInput,
  ) => {
    if (isEdit) {
      mutationUpdate.mutate(values)
    } else {
      mutationCreate.mutate(values)
    }
  }

  React.useEffect(() => {
    if (consultationUpdate) {
      addConsultation(consultationUpdate)
    }
  }, [addConsultation, consultationUpdate])

  React.useEffect(() => {
    //console.log('consultation useffect', consultation)

    if (consultation?.questions?.items) {
      consultation.questions?.items.map((question: any) =>
        addQuestion(question),
      )
    }
  }, [addQuestion, consultation])

  const handleCancel = () => {
    navigate(`${ROUTE_ADMIN_FAQ_CONSULTATIONS}`)
  }

  return (
    <>
      <HeaderStep
        title="Création de la consultation (STEP 1)"
        step={1}
        consultation={consultation}
        questions={questions}
      />
      <Container variant="generalBg" maxW="full">
        <ConsultationForm
          isModeConsultationStep={true}
          onSubmit={handleModalSave}
          isEdit={isEdit}
          onClose={handleCancel}
          isLoading={isLoading}
          initialValues={consultation}
          isDisabled={isButtonDisabled}
        />
        <Flex w="full" justifyContent={'flex-end'} mt={2}>
          <HStack>
            <Button
              disabled={!isEdit}
              isDisabled={!isEdit}
              onClick={() =>
                navigate(
                  `${ROUTE_ADMIN_FAQ_CREATE_CONSULTATION}/${CreateConsultationSteps.select_questions}`,
                )
              }
            >
              Suivant
            </Button>
          </HStack>
        </Flex>
      </Container>
    </>
  )
}
