import {InfoIcon} from '@chakra-ui/icons'
import {
  Flex,
  IconButton,
  SkeletonCircle,
  SkeletonText,
  useColorMode,
} from '@chakra-ui/react'
import React from 'react'
import {bgSidebarColor} from '../../theme/app-constantes'

export default function HeaderConSkeleton() {
  const {colorMode} = useColorMode()
  const bgColorMode = bgSidebarColor({colorMode})
  return (
    <>
      <Flex
        flexDir={'row'}
        p={5}
        bg={bgColorMode}
        gap={2}
        alignItems={'center'}
        minH={'120px'}
        flexDirection={'row'}
      >
        <SkeletonCircle w={20} h={20} />
        <Flex flexDir={'column'}>
          <SkeletonText w={'200px'} noOfLines={2} />
        </Flex>
        <IconButton
          aria-label="user information"
          icon={<InfoIcon />}
          isDisabled
          ml={'auto'}
        />
      </Flex>
    </>
  )
}
