import {
  Box as ChakraBox,
  BoxProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react'

type BoxType = BoxProps & {
  variant?: ThemingProps<'Text'>['variant']
}

export const Box = (props: BoxType) => {
  const {variant, ...rest} = props
  const styles = useStyleConfig('Text', {variant})

  return <ChakraBox __css={variant ? styles : undefined} {...rest}></ChakraBox>
}
