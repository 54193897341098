import {useParams} from 'react-router-dom'
import {ContainerPage} from '../../../components/ui'
import {UserDetails} from '../../../components/UserDetails'

const UserDetailPage = () => {
  const {userId} = useParams()
  return (
    <ContainerPage>
      {/* <UserFaqVideo consultationId={consultationId} /> */}
      <UserDetails userId={userId as string} />
    </ContainerPage>
  )
}
export {UserDetailPage}
