import {ArrowBackIcon, InfoIcon} from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import {useMemo} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useUserById} from '../../commons/api/hooks'
import {
  useAddUserToChatRoom,
  useUpdateChatRoom,
} from '../../commons/api/hooks/messagerie'
import {findBestUidForAvatarChatRoom} from '../../commons/helpers/messagerieHelper'
import {
  ChatRoom,
  CreateUserChatRoomInput,
  UserChatRoom,
} from '../../commons/types/API'
import {
  ROUTE_MESSAGERIE,
  ROUTE_MESSAGERIE_CONVERSATIONS,
} from '../../constantes'
import {useAuthDetails} from '../../context/AuthContext'
import {usePopupRemoveUserFromRoom} from '../../hooks/messagerieActions'
import {
  bgSidebarColor,
  // primaryColor,
  // textButtonColor,
} from '../../theme/app-constantes'
import {ChatRoomAddUserForm} from '../Forms/ChatRoomAddUserForm'
import {ModalForm} from '../Modals'
import {AvatarMessage} from './AvatarMessage'
import MessageDrawer from './MessageDrawer'
import ModalContainer from 'react-modal-promise'
import {displayUserNameAndName} from '../../commons'

type MessageHeaderProps = {
  chatRoom?: ChatRoom
}
export default function MessageHeader({chatRoom}: MessageHeaderProps) {
  const isGroup =
    chatRoom?.contributors?.items && chatRoom?.contributors?.items.length > 2
  const {colorMode} = useColorMode()
  const bgColorMode = bgSidebarColor({colorMode})
  // const bgIcon = primaryColor({colorMode})
  // const iconColor = textButtonColor({colorMode})
  const {authUser} = useAuthDetails()
  const navigate = useNavigate()

  const uidAvatar = useMemo(() => {
    return findBestUidForAvatarChatRoom(chatRoom, authUser.me)
  }, [chatRoom, authUser.me])
  const {user} = useUserById(uidAvatar)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {
    isOpen: isOpenFormAddUser,
    onOpen: onOpenFormAddUser,
    onClose: onCloseFormAddUser,
  } = useDisclosure()

  //interdiction d'utiliser editors
  //const datas = [user?.username, chatRoom?.editors]

  //console.log(chatRoom?.editors, 'chatRoom')

  const mutationUpdateRoom = useUpdateChatRoom({
    onSuccess: () => {
      onCloseFormAddUser()
    },
  })

  /**
   * Merge les editors de la Room et les editors du formulaire en evitant les doublons
   * Le but : Avoir dans ChatRoom les editors de la room + editor de UserChatRoom
   * @param chatRoomEditors
   * @param dataEditors
   * @returns
   */
  const mergeEditors = (
    chatRoomEditors: (string | null)[],
    dataEditors: (string | null)[],
  ): (string | null)[] => {
    return [
      ...chatRoomEditors,
      ...dataEditors.filter(
        item => item !== null && !chatRoomEditors.includes(item),
      ),
    ]
  }

  const mutationAddUser = useAddUserToChatRoom({
    onSuccess: (data: UserChatRoom) => {
      //console.log(data, 'data useAddUserToChatRoom')
      mutationUpdateRoom.mutate({
        id: data.chatRoomId ?? '',
        updatedAt: new Date().toISOString(),
        editors: mergeEditors(chatRoom?.editors ?? [], data.editors ?? []), //doit contenir les editors de ChatRoom + editor de UserChatRoom
      })
    },
  })

  const handleAddUser = (values: CreateUserChatRoomInput) => {
    mutationAddUser.mutate({
      chatRoomId: values.chatRoomId,
      userId: values.userId,
      groupsCanAccess: values.groupsCanAccess,
      editors: values.editors, //doit contenir les editors de ChatRoom + editor de UserChatRoom
      owner: values.owner,
    })
  }
  const {handleQuitRoom} = usePopupRemoveUserFromRoom({
    onSuccess: () => {
      onClose()
      navigate(ROUTE_MESSAGERIE)
    },
  })

  const onQuitRoom = (e: React.MouseEvent, userId: string) => {
    const userChatRoomToDelete = chatRoom?.contributors?.items.find(
      contributor => contributor?.userId === userId,
    )
    handleQuitRoom(e, userChatRoomToDelete as UserChatRoom)
  }

  const labelHeader = isGroup
    ? `${chatRoom?.name}`
    : `Discussion avec ${displayUserNameAndName(user)}`
  const subLabelHeader = isGroup
    ? `${chatRoom?.contributors?.items.length} membre(s)`
    : `${chatRoom?.messages?.items.length} message(s)`

  return (
    <Flex
      flexDir={'row'}
      p={5}
      bg={bgColorMode}
      gap={2}
      alignItems={'center'}
      position={'sticky'}
      top={0}
    >
      <Link to={ROUTE_MESSAGERIE_CONVERSATIONS}>
        <IconButton
          display={{base: 'inline', md: 'none'}}
          aria-label="user information"
          icon={<ArrowBackIcon />}
        />
      </Link>
      <Box w={'auto'}>
        <AvatarMessage
          user={user}
          contributors={chatRoom?.contributors?.items}
          max={3}
          maxMobile={1}
        />
      </Box>
      <Flex flexDir={'column'} w={'100%'}>
        <Text variant={'label'} noOfLines={{base: 1, md: 2}}>
          {labelHeader}
        </Text>
        <Text noOfLines={1} /* 1 ligne sinon problème d'ui à 1024px  */>
          {subLabelHeader}
        </Text>
      </Flex>

      <IconButton
        aria-label="user information"
        icon={<InfoIcon />}
        onClick={onOpen}
      />
      <MessageDrawer
        onQuitRoom={onQuitRoom}
        onOpenFormAddUser={onOpenFormAddUser}
        isOpen={isOpen}
        onClose={onClose}
        isGroup={isGroup}
        chatRoom={chatRoom}
        contributors={chatRoom?.contributors?.items}
        user={authUser.me}
      />
      <ModalForm
        isOpen={isOpenFormAddUser}
        onClose={onCloseFormAddUser}
        onOpen={() => {}}
        title={'messagerie'}
      >
        <Box>
          <Stack spacing={4} mb={5}>
            <Heading>Ajouter un membre</Heading>
          </Stack>
          <ChatRoomAddUserForm
            onSubmit={handleAddUser}
            onClose={onCloseFormAddUser}
            chatRoom={chatRoom}
            isLoading={mutationAddUser.isLoading}
          />
        </Box>
      </ModalForm>
      <ModalContainer />
    </Flex>
  )
}
