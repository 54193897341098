import React from 'react'
import {Box, Icon, Link, Tag, Text} from '../ui'
import {useColorMode, Flex, Stack, useBreakpoint} from '@chakra-ui/react'
import {format} from 'date-fns'
import {fr} from 'date-fns/locale'
import {MdDelete, MdEdit, MdVisibility} from 'react-icons/md'
import {primaryColor} from '../../theme/app-constantes'
import {FAQConsultation, Question} from '../../commons/types/API'
import {getEStatusDetails} from '../../commons/helpers/screen'
import {EStatus} from '../../types/graphQL'
import {dateFormat_ddMMyyyyHHmm} from '../../commons/constantes'
import {Link as DomRouterLink, useNavigate} from 'react-router-dom'
import {
  useCanDeleteMyQuestions,
  useCanUpdateMyQuestions,
} from '../../context/AuthContext'
import ReactTable from '../ui/ReactTable'
import {usePagination} from '../../hooks/commonActions'
import Pagination from '../Table/Pagination'

type ListReponseProps = {
  _key?: number
  question?: Question
  questions?: Question[]
  consultation?: FAQConsultation
  onUpdateQuestion: (e: React.MouseEvent, value: string) => void
  onDeleteQuestion: (e: React.MouseEvent, value: string) => void
}

export const QuestionEditable = ({
  _key,
  question,
  questions,
  consultation,
  onUpdateQuestion,
  onDeleteQuestion,
}: ListReponseProps) => {
  const {colorMode} = useColorMode()
  const colorTitle = primaryColor({colorMode})

  const navigate = useNavigate()

  const {next, prev, nextToken, hasNext, hasPrev} = usePagination()

  const [hiddenCol, setHiddenCol] = React.useState([''])

  const breakpoint = useBreakpoint()

  React.useEffect(() => {
    const contain = ['base', 'sm'].includes(breakpoint as string)
    contain === true ? setHiddenCol(['createdAt']) : setHiddenCol([])
  }, [breakpoint])

  const rowProps = (row: any) => ({})

  const columns = React.useMemo(
    () => [
      {
        Header: 'Question',
        accessor: 'title',
        Cell: ({row}: any) => {
          //console.log('row', row)
          return (
            <Box>
              <Link
                as={DomRouterLink}
                to={
                  row.original.status === EStatus.PUBLISHED
                    ? `/school/faq/question/${row.original.id}`
                    : `/school/faq/question-editor/${row.original.id}`
                }
              >
                <Stack>
                  <Text variant="label" cursor="pointer" color={colorTitle}>
                    {row.original.title}
                  </Text>
                </Stack>
              </Link>
            </Box>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}: any) => {
          return (
            <Flex w="full" justifyContent={'center'}>
              {row.original.status && (
                <Tag
                  bgColor={
                    getEStatusDetails(row.original?.status as EStatus).value
                  }
                  color={'white'}
                  fontWeight={'semibold'}
                  mr={1}
                  justifyContent={'center'}
                >
                  {row.original.status}
                </Tag>
              )}
            </Flex>
          )
        },
      },
      {
        Header: 'Créé le ',
        accessor: 'createdAt',
        Cell: ({row}: any) => {
          return (
            <Link
              as={DomRouterLink}
              to={`/school/faq/question/${row.original.id}`}
            >
              <Text variant="dateCardTopic">
                {format(
                  new Date(row.values.createdAt as string),
                  dateFormat_ddMMyyyyHHmm,
                  {
                    locale: fr,
                  },
                )}
              </Text>
            </Link>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({row}: any) => {
          const canEditQuestion = useCanUpdateMyQuestions(row.original)
          const canDeleteQuestion = useCanDeleteMyQuestions(row.original)

          const navigateToQuestionOrEditor = () => {
            if (row.original.status === EStatus.PUBLISHED) {
              navigate(`/school/faq/question/${row.original.id}`)
            } else {
              navigate(`/school/faq/question-editor/${row.original.id}`)
            }
          }

          return (
            <Box display={'flex'} justifyContent={'end'}>
              {row.original.status === EStatus.PUBLISHED && (
                <Icon
                  as={MdVisibility}
                  w={6}
                  h={6}
                  onClick={navigateToQuestionOrEditor}
                  cursor="pointer"
                />
              )}
              {canEditQuestion && (
                <Icon
                  as={MdEdit}
                  w={6}
                  h={6}
                  onClick={(e: any) =>
                    onUpdateQuestion(e, row.original.id as string)
                  }
                  cursor="pointer"
                />
              )}
              {canDeleteQuestion && (
                <Icon
                  as={MdDelete}
                  w={6}
                  h={6}
                  onClick={(e: any) =>
                    onDeleteQuestion(e, row.original.id as string)
                  }
                  cursor="pointer"
                />
              )}
            </Box>
          )
        },
      },
    ],
    [colorTitle, onDeleteQuestion, onUpdateQuestion, navigate],
  )

  const cellProps = (cellInfo: any) => {
    switch (cellInfo.column.id) {
      case 'selection':
        return {
          _position: 'select',
          util: 'user',
        }
      case 'username':
        return {
          _position: 'name',
          util: 'user',
        }
      case 'email':
        return {
          _position: 'domaine',
          util: 'user',
        }
      case 'id':
        return {
          _position: 'actions',
          util: 'user',
        }
    }
  }

  return (
    <>
      <ReactTable
        rowProps={rowProps}
        cellProps={cellProps}
        columns={columns}
        data={questions || []}
        controlledPageCount={0}
        controlledPageIndex={0}
        isLoading={false}
        isFetching={false}
        onFetchData={function (options: any): void {
          // throw new Error('Function not implemented.')
        }}
        setPageIndex={function (pageIndex: number): void {
          //throw new Error('Function not implemented.')
        }}
        setSelectedRowsId={function (selectedRowIds: any): void {
          // throw new Error('Function not implemented.')
        }}
        totalCount={0}
        labelData={'réponses'}
        identifier={'id'}
        controlledHiddenColumns={hiddenCol}
        initialSortBy={[]}
        hideHeader={false}
      />

      {/* <Pagination /> */}
      <Pagination
        next={next}
        prev={prev}
        current={nextToken}
        hasNext={hasNext}
        hasPrev={hasPrev}
        isLoading={false}
        hidden={!hasPrev && !hasNext}
      />
    </>
  )
}

export default QuestionEditable
