// export const getTopicsByWorkspaceIdCustom = /* GraphQL */ `
//   query getTopicsByWorkspaceIdCustom($workspaceId: ID!) {
//     getTopicsByWorkspaceId(workspaceId: $workspaceId) {
//       id
//       name
//       description
//       published
//       workspace {
//         id
//         name
//         description
//         image
//         published
//         open
//         course {
//           description
//           id
//           image
//           name
//           published
//           urlProduct
//           groupsCanAccess
//           createdAt
//           updatedAt
//           owner
//         }
//         topics {
//           nextToken
//         }
//         createdAt
//         updatedAt
//       }
//       messages {
//         items {
//           id
//           message
//           description
//           published
//           createdAt
//           updatedAt
//           user {
//             id
//             username
//             image
//           }
//         }
//         nextToken
//       }
//       user {
//         id
//         cognitoId
//         userSchoolId
//         topics {
//           nextToken
//         }
//         messages {
//           items {
//             id
//             message
//             description
//             published
//             createdAt
//             updatedAt
//             user {
//               id
//               username
//               image
//             }
//           }
//           nextToken
//         }
//         address
//         firstname
//         lastname
//         username
//         birthday
//         company
//         contact
//         email
//         facebookId
//         image
//         instagramId
//         isFrequent
//         isStarred
//         label
//         linkedInId
//         notes
//         registered
//         twitterId
//         website
//         createdAt
//         updatedAt
//       }
//       createdAt
//       updatedAt
//     }
//   }
// `

// export const getWorkspacesBySchoolIdCustom = /* GraphQL */ `
//   query GetWorkspacesBySchoolIdCustom($schoolId: ID!) {
//     getWorkspacesBySchoolId(schoolId: $schoolId) {
//       id
//       name
//       description
//       image
//       published
//       open
//       course {
//         description
//         id
//         image
//         name
//         published
//         urlProduct
//         school {
//           id
//           description
//           domain
//           emailsupport
//           lang
//           message
//           name
//           owner
//           url
//           welcomeMessage
//           image
//           createdAt
//           updatedAt
//         }
//         workspaces {
//           nextToken
//         }
//         groupsCanAccess
//         createdAt
//         updatedAt
//         owner
//       }
//       topics {
//         items {
//           id
//           name
//           description
//           published
//           createdAt
//           updatedAt
//           user {
//             id
//             cognitoId
//             address
//             firstname
//             lastname
//             username
//             birthday
//             company
//             contact
//             email
//             facebookId
//             image
//             instagramId
//             isFrequent
//             isStarred
//             label
//             linkedInId
//             notes
//             registered
//             twitterId
//             website
//             createdAt
//             updatedAt
//           }
//           messages {
//             items {
//               id
//               message
//               description
//               published
//               createdAt
//               updatedAt
//               user {
//                 id
//                 username
//                 image
//               }
//             }
//             nextToken
//           }
//         }
//         nextToken
//       }
//       school {
//         id
//         description
//         domain
//         emailsupport
//         lang
//         message
//         name
//         owner
//         url
//         welcomeMessage
//         image
//         courses {
//           nextToken
//         }
//         workspaces {
//           nextToken
//         }
//         createdAt
//         updatedAt
//       }
//       owner
//       editors
//       groupsCanAccess
//       createdAt
//       updatedAt
//     }
//   }
// `
export const getTopicCustom = /* GraphQL */ `
  query getTopicCustom($id: ID!) {
    getTopic(id: $id) {
      id

      name
      description
      published
      workspace {
        id
        name
        description
        image
        published
        open
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          createdAt
          updatedAt
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          user {
            id
            username
            image
            roles
          }
        }
        nextToken
      }

      user {
        id
        cognitoId
        userSchoolId
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        facebookId
        image
        instagramId
        isFrequent
        isStarred
        label
        linkedInId
        notes
        registered
        twitterId
        website
        website2
        createdAt
        updatedAt
        roles
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`

export const listFAQConsultationsCustom = /* GraphQL */ `
  query ListFAQConsultationsCustom(
    $filter: ModelFAQConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        status
        questions {
          items {
            id
            author {
              id
              cognitoId
              userSchoolId
              firstname
              lastname
              username
              email
              image
              createdAt
              updatedAt
            }
            title
            rewording
            description
            answerUrl
            category {
              id
              name
              description
              published
            }
            tags {
              items {
                id
                fAQTagID
                questionID
                createdAt
                updatedAt
                owner
                editors
              }
              nextToken
            }
            thumb
            file
            answer {
              id
              title
              description
              urlVideo
              owner
              editors
              groupsCanAccess
              createdAt
              updatedAt
            }
            status
            faqConsultation {
              id
              title
              description
              status
              owner
              editors
              groupsCanAccess
              createdAt
              updatedAt
            }
            owner
            editors
            groupsCanAccess
            createdAt
            updatedAt
          }
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listQuestionsCustom = /* GraphQL */ `
  query ListQuestionsCustom(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author {
          id
          cognitoId
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          facebookId
          image
          instagramId
          isFrequent
          isStarred
          label
          linkedInId
          notes
          registered
          twitterId
          website
          website2
          createdAt
          updatedAt
        }
        title
        rewording
        description
        thumb
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          items {
            id
            fAQTagID
            questionID
            createdAt
            updatedAt
            owner
            editors
          }
          nextToken
        }
        file
        answer {
          id
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        status
        faqConsultation {
          id
          title
          description
          status
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getFAQConsultationCustom = /* GraphQL */ `
  query getFAQConsultationCustom($id: ID!) {
    getFAQConsultation(id: $id) {
      id
      title
      description
      status
      questions {
        items {
          id
          title
          rewording
          description
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
          author {
            id
            cognitoId
            userSchoolId
            address
            firstname
            lastname
            username
          }
          tags {
            items {
              id
              fAQTagID
              questionID
              createdAt
              updatedAt
              owner
              editors
            }
            nextToken
          }
          thumb
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`

export const workspacesBySchoolCustom = /* GraphQL */ `
  query WorkspacesBySchoolCustom(
    $workspaceSchoolId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesBySchool(
      workspaceSchoolId: $workspaceSchoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        image
        published
        open
        course {
          description
          id
          image
          name
          published
          urlProduct
          groupsCanAccess
          createdAt
          updatedAt
          owner
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        topics {
          items {
            id
            name
            description
            published
            createdAt
            updatedAt
            user {
              id
              cognitoId
              address
              firstname
              lastname
              username
              birthday
              company
              contact
              email
              facebookId
              image
              instagramId
              isFrequent
              isStarred
              label
              linkedInId
              notes
              registered
              twitterId
              website
              createdAt
              updatedAt
              roles
            }
            messages {
              items {
                id
                message
                description
                published
                createdAt
                updatedAt
                user {
                  id
                  username
                  image
                }
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
export const usersBySchoolCustom = /* GraphQL */ `
  query usersBySchoolCustom(
    $userSchoolId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBySchool(
      userSchoolId: $userSchoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const topicsBySchoolCustom = /* GraphQL */ `
  query TopicsBySchoolCustom(
    $topicSchoolId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicsBySchool(
      topicSchoolId: $topicSchoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        published
        workspace {
          id
          name
          description
          image
          published
          open
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        user {
          id
          cognitoId
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          createdAt
          updatedAt
          roles
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        messages {
          items {
            id
            message
            description
            published
            createdAt
            updatedAt
            user {
              id
              username
              image
              roles
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
export const topicsByWorkspaceCustom = /* GraphQL */ `
  query TopicsByWorkspaceCustom(
    $workspaceTopicsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicsByWorkspace(
      workspaceTopicsId: $workspaceTopicsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          items {
            id
            message
            description
            published
            createdAt
            updatedAt
            user {
              id
              username
              image
              roles
            }
          }
          nextToken
        }
        user {
          id
          cognitoId
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          createdAt
          updatedAt
          roles
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const consultationsBySchoolCustom = /* GraphQL */ `
  query ConsultationsBySchoolCustom(
    $consultationSchoolId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFAQConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationsBySchool(
      consultationSchoolId: $consultationSchoolId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        status
        questions {
          items {
            id
            author {
              id
              cognitoId
              userSchoolId
              firstname
              lastname
              username
              email
              image
              createdAt
              updatedAt
            }
            title
            rewording
            description
            answerUrl
            category {
              id
              name
              description
              published
            }
            tags {
              items {
                id
                fAQTagID
                questionID
                createdAt
                updatedAt
                owner
                editors
              }
              nextToken
            }
            file
            answer {
              id
              title
              description
              urlVideo
              owner
              editors
              groupsCanAccess
              createdAt
              updatedAt
            }
            status
            faqConsultation {
              id
              title
              description
              status
              owner
              editors
              groupsCanAccess
              createdAt
              updatedAt
            }
            owner
            editors
            groupsCanAccess
            createdAt
            updatedAt
          }
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getQuestionCustom = /* GraphQL */ `
  query getQuestionCustom($id: ID!) {
    getQuestion(id: $id) {
      id
      questionAuthorId
      author {
        cognitoId
        roles
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        owner
        createdAt
        updatedAt
      }
      title
      answerAuthorId
      answerAuthor {
        cognitoId
        roles
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      rewording
      description
      questionVideoUrl
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          commentAuthorId
          author {
            cognitoId
            roles
            id
            userSchoolId
            address
            firstname
            lastname
            username
            birthday
            company
            contact
            email
            image
            website
            instagramId
            facebookId
            linkedInId
            twitterId
            youtubeId
            googleId
            githubId
            isFrequent
            isStarred
            label
            notes
            registered
            website2
            owner
            createdAt
            updatedAt
          }
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      category {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      file
      answer {
        id
        userAnswersId
        author {
          cognitoId
          roles
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          owner
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      answerUrl
      answerContent
      answerVideoId
      answerVideoProvider
      status
      questionsSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      fAQConsultationQuestionsId
      faqConsultation {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userQuestionsId
      questionCategoryId
      questionAnswerId
      thumb
    }
  }
`
export const questionsByAuthorCustom = /* GraphQL */ `
  query QuestionsByAuthorCustom(
    $questionAuthorId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByAuthor(
      questionAuthorId: $questionAuthorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionAuthorId
        author {
          cognitoId
          roles
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          owner
          createdAt
          updatedAt
        }
        title
        rewording
        description
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          items {
            id
            fAQTagID
            questionID
            createdAt
            updatedAt
            owner
            editors
          }
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      nextToken
    }
  }
`
export const meCustomChatRoom = /* GraphQL */ `
  query meCustomChatRoom($id: ID!) {
    me(id: $id) {
      cognitoId
      roles
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          userChatRoomsId
          chatRoom {
            
            id
            name
            description
            owner
            editors
            groupsCanAccess
            createdAt
            updatedAt
            contributors {
              items {
                id
                userId
                userChatRoomsId
                createdAt
                updatedAt
                owner
                editors
                user {
                  cognitoId
                  id
                  firstname
                  lastname
                  username
                  birthday
                  company
                  contact
                  email
                  image
                  website
                  owner
                  editors
                  groupsCanAccess
                  roles
                  createdAt
                  updatedAt
                  roles
                }
              }
              nextToken
            }

          }
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      createdAt
      updatedAt
    }
  }
`;

export const getChatRoomCustom = /* GraphQL */ `
  query GetChatRoomCustom($id: ID!) {
    getChatRoom(id: $id) {
      id
      name
      description
      messages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
          user {
            cognitoId
            id
            username
            firstname
            lastname
            image
            roles
          }
        }
        nextToken
      }
      contributors {
        items {
          id
          userId
          userChatRoomsId
          createdAt
          updatedAt
          owner
          editors
          user {
              cognitoId
              id
              firstname
              lastname
              username
              birthday
              company
              contact
              email
              image
              website
              owner
              editors
              groupsCanAccess
              roles
              createdAt
              updatedAt
            }
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;


export const getUserByIdCustom = /* GraphQL */ `
  query GetUserByIdCustom($userId: ID!) {
    getUserById(userId: $userId) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          course {
            description
            id
            image
            name
            published
            urlProduct
            courseSchoolId
            school {
              id
              description
              domain
              emailsupport
              lang
              message
              name
              url
              welcomeMessage
              image
              owner
              editors
              groupsCanAccess
              createdAt
              updatedAt
            }
            workspaces {
              nextToken
            }
            users {
              nextToken
            }
            owner
            editors
            groupsCanAccess
            createdAt
            updatedAt
          }

          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      createdAt
      updatedAt
    }
  }
`;