import {
  Flex,
  Editable,
  EditablePreview,
  EditableInput,
  useColorMode,
  Text,
  BackgroundProps,
  ColorProps,
  useBreakpointValue,
} from '@chakra-ui/react'
import {useCallback, useState} from 'react'
import {displayNameInitial, displayUserNameAndName} from '../../commons'
import {
  useUpdateChatMessage,
  useUpdateChatRoom,
  useDeleteChatMessage,
} from '../../commons/api/hooks/messagerie'
import {formatDateTime, timeDiff} from '../../commons/helpers/dataHelper'
import {ChatMessage, UserChatRoom} from '../../commons/types/API'
import {useAuthDetails} from '../../context/AuthContext'
import {
  primaryColor,
  primaryTextColor,
  textButtonColor,
} from '../../theme/app-constantes'
import {AvatarMessageIcon} from './AvatarMessage'
import {MessageAction} from './MessageAction'
import {
  dateFormat_dMMMM_yyyy,
  dateFormat_dMMMM_yyyy_HHmm,
  dateFormat_ddMMyyyyHHmm,
} from '../../commons/constantes'

type PropsMessageColorType = (
  bgColorFunc: string,
  colorFunc: string,
) => {
  bgColor: BackgroundProps['bgColor']
  color: ColorProps['color']
}
const propsMessageColor: PropsMessageColorType = (bgColor, color) => {
  return {
    bgColor,
    color,
  }
}
export const MessageView = ({
  chatMessage,
  usersChatRoom,
  isShowInfo,
}: {
  chatMessage: ChatMessage
  usersChatRoom?: UserChatRoom[]
  isShowInfo: boolean
}) => {
  const {uid: userId} = useAuthDetails()
  const {colorMode} = useColorMode()
  const primaryText = primaryTextColor({colorMode})
  const {getMessageBgColor, getMessageColor} = useMessageColor()

  const mutationChatMessageUpdate = useUpdateChatMessage()
  const mutationUpdateChatRoom = useUpdateChatRoom()
  const mutationChatMessageDelete = useDeleteChatMessage()

  const [isTextHover, setTextHover] = useState(false)
  const [textId, setTextId] = useState<string>()
  const [editText, setEditText] = useState('')
  const isMobile = useBreakpointValue({base: true, sm: false})

  const handleUpdateMessage = () => {
    mutationChatMessageUpdate.mutate({
      id: chatMessage.id,
      message: editText,
    })
    // update chatRoom updatedAt pour faire remonter la conversation en haut de la liste si nouveau message
    mutationUpdateChatRoom.mutate({
      id: usersChatRoom?.[0].chatRoomId ?? '',
      updatedAt: new Date().toISOString(),
    })
  }
  const handleDeleteMessage = () => {
    mutationChatMessageDelete.mutate({
      id: chatMessage.id,
    })
  }
  const handleHover = (hover: boolean, index: string) => {
    setTextHover(hover)
    setTextId(index)
  }

  const checkIsMe = useCallback(
    () => userId === chatMessage?.userMessagesId,
    [chatMessage, userId],
  )
  const findUserInRoom = (id: string) => {
    return usersChatRoom?.find(userChat => userChat?.user?.id === id)?.user
  }

  const isQuitGroup =
    findUserInRoom(chatMessage.userMessagesId) === undefined ? true : false

  const displayName = isMobile
    ? chatMessage.user.username
    : displayUserNameAndName(chatMessage.user)
  const displayNameQuit = isQuitGroup
    ? `${displayName} (exclus)`
    : `${displayName}`
  const customProps = propsMessageColor(
    getMessageBgColor(checkIsMe()),
    getMessageColor(checkIsMe()),
  )
  const getTimeMessage = () => {
    const dateNow = new Date()
    const dateOld = new Date(chatMessage.createdAt)
    const dateDiff = dateNow.getTime() - dateOld.getTime()

    switch (true) {
      case dateDiff > 48 * 60 * 60 * 1000:
        return formatDateTime(
          chatMessage.createdAt,
          isMobile ? dateFormat_dMMMM_yyyy : dateFormat_dMMMM_yyyy_HHmm,
        )
      default:
        return timeDiff(chatMessage.createdAt)
    }
  }

  return (
    <>
      {isShowInfo ? (
        <Flex
          flexDir={checkIsMe() ? 'row-reverse' : 'row'}
          gap={2}
          alignItems={'center'}
        >
          <AvatarMessageIcon user={chatMessage?.user} />
          <Text color={primaryText} variant="subtle">
            {displayNameQuit}
          </Text>
          <Text>{getTimeMessage()}</Text>
        </Flex>
      ) : null}
      <Flex
        flexDir={checkIsMe() ? 'row-reverse' : 'row'}
        w={'100%'}
        onMouseEnter={() => handleHover(true, chatMessage.id)}
        onMouseLeave={() => handleHover(false, chatMessage.id)}
        alignItems={'center'}
        gap={2}
        pr={checkIsMe() ? '45px' : '0px'}
        pl={checkIsMe() ? '0px' : '45px'}
      >
        {isTextHover === true && checkIsMe() && chatMessage.id === textId ? (
          <>
            <Editable
              defaultValue={chatMessage?.message}
              onSubmit={() => handleUpdateMessage()}
              onChange={e => setEditText(e)}
              isPreviewFocusable={false}
            >
              <Flex
                flexDir={'row'}
                justifyContent="center"
                alignItems={'center'}
                gap={2}
              >
                <MessageAction onDeleteMessage={() => handleDeleteMessage()} />
                <Text>
                  <EditablePreview
                    rounded={'md'}
                    boxShadow={'base'}
                    m={0}
                    p={3}
                    w={'auto'}
                    {...customProps}
                  />
                </Text>
                <EditableInput
                  p={3}
                  rounded={'md'}
                  {...customProps}
                  w={'auto'}
                  boxShadow={'base'}
                  autoFocus={true}
                />
              </Flex>
            </Editable>
          </>
        ) : (
          <Text
            p={3}
            rounded={'md'}
            {...customProps}
            w={'auto'}
            boxShadow={'base'}
          >
            {chatMessage?.message}
          </Text>
        )}
      </Flex>
    </>
  )
}

const useMessageColor = () => {
  const {colorMode} = useColorMode()
  const bgMessage = primaryColor({colorMode})
  const colorMyMessage = textButtonColor({colorMode})

  const getMessageBgColor = (checkSelf: boolean) => {
    return checkSelf ? bgMessage : 'white'
  }
  const getMessageColor = (checkSelf: boolean) => {
    return checkSelf ? colorMyMessage : 'black'
  }

  return {
    getMessageBgColor,
    getMessageColor,
  }
}
