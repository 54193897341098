import {useGlobalContext} from '../../context/GlobalContext'
import {Eroles} from '../../types'
import {Footer} from '../Footer'
import Navbar from '../Nav/Navbar'
import Sidebar from '../Nav/Sidebar'
import {Flex, Box, Container} from '../ui'

type AuthAdminAppTemplateProps = {
  widthSubMenu?: boolean
  children: any
}
export const AuthAdminAppTemplate = (props: AuthAdminAppTemplateProps) => {
  const {children, widthSubMenu} = props
  const {hideFooter} = useGlobalContext()

  // Généralement j'utilise des templates comme ici. Une espèce de coquille où tout se passera dans containerPage
  return (
    <>
      <Container variant="appContainer">
        <Flex w="full" maxW="1536px" mx="auto">
          {/* Menu Latéral */}
          <Container variant="sidebarContainer">
            <Sidebar menuRoleFilter={Eroles.SCHOOLOWNER} />
          </Container>
          {/* Navbar */}
          <Box w="full" p={4}>
            <Navbar menuRoleFilter={Eroles.SCHOOLOWNER} />
            {/* <Component {...pageProps} /> */}
            <Box py={4} pl={{base: 0, md: 4}}>
              <Box>{children}</Box>
            </Box>
            {!hideFooter && <Footer />}
          </Box>
        </Flex>
        {/* <Footer /> */}
      </Container>
    </>
  )
}

export default AuthAdminAppTemplate
