import {ContainerPage} from '../../../components/ui'
import {StudentList as StudentTable} from '../../../components/Students'

export const StudentList = () => {
  return (
    <ContainerPage>
      <StudentTable />
    </ContainerPage>
  )
}
