/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import {Stack, Heading, Flex, Box, Button, FormInput} from '../ui'
import {AiFillLinkedin, AiFillGoogleCircle} from 'react-icons/ai'
import {LinkIcon} from '@chakra-ui/icons'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik, Formik} from 'formik'
import * as Yup from 'yup'
import {AlertHiddenFields} from './AlertHiddenFields'

export interface RegisterFormValues {
  name: string
  username: string
  password: string
  passwordConfirmation: string
  schoolId?: string
  courses?: string
}

type RegisterFormProps = {
  initialValues?: RegisterFormValues
  onSubmit: (formValue: RegisterFormValues) => void
  isLoading?: boolean
  mailQuery?: string | null
  userName?: string | null
}

export const RegisterForm = ({
  initialValues,
  onSubmit,
  isLoading,
  mailQuery,
  userName,
}: RegisterFormProps) => {
  const SignInSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    username: Yup.string()
      .email('Email invalide')
      .required('Champ obligatoire'),
    password: Yup.string()
      .min(6, 'Minimum 6 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire'),
    passwordConfirmation: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Champ obligatoire')
      .oneOf(
        [Yup.ref('password'), null],
        'Les mots de passe ne correspondent pas',
      ),
  })

  const formik = useFormik({
    initialValues: initialValues || {
      name: userName ?? '',
      username: mailQuery ?? '',
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: (values: RegisterFormValues) => {
      //console.log('onSubmit', onSubmit)

      onSubmit(values)
    },
    validationSchema: SignInSchema,
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing="2">
        <FormInput
          placeholder="Nom"
          label="Nom d'utilisateur"
          name="name"
          id="name"
          onChange={formik.handleChange}
          value={formik.values?.name}
          errorMessage={formik?.errors?.name}
          isDisabled={userName ? true : false}
        />
        <FormInput
          placeholder="john.doe@email.com"
          type="email"
          label="Email"
          name="username"
          id="username"
          onChange={formik.handleChange}
          value={formik.values?.username}
          errorMessage={formik?.errors?.username}
          isDisabled={mailQuery ? true : false}
        />
        <FormInput
          type="password"
          label="Mot de passe"
          name="password"
          id="password"
          onChange={formik.handleChange}
          value={formik.values?.password}
          errorMessage={formik?.errors?.password}
        />
        <FormInput
          type="password"
          label="Confirmer le mot de passe"
          name="passwordConfirmation"
          id="passwordConfirmation"
          onChange={formik.handleChange}
          value={formik.values?.passwordConfirmation}
          errorMessage={formik?.errors?.passwordConfirmation}
        />
        <Button type="submit" size="lg" fontSize="md" isLoading={isLoading}>
          S'inscrire
        </Button>
        <AlertHiddenFields
          formik={formik}
          fields={[
            'commentAuthorId',
            'userTopicsId',
            'owner',
            'editors',
            'groupsCanAccess',
          ]}
        />
      </Stack>
    </form>
  )
}
export default RegisterForm
