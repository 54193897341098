import {Skeleton, SkeletonText} from '@chakra-ui/react'
import {Box, Stack} from '../ui'

export const QuestionDetailSkeleton = () => {
  return (
    <>
      <Stack>
        <Box padding="6" boxShadow="lg">
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        </Box>
      </Stack>
    </>
  )
}
export default QuestionDetailSkeleton
