/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDepartement = /* GraphQL */ `
  subscription OnCreateDepartement(
    $filter: ModelSubscriptionDepartementFilterInput
  ) {
    onCreateDepartement(filter: $filter) {
      id
      name
      managerID
      manager {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      employees {
        items {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDepartement = /* GraphQL */ `
  subscription OnUpdateDepartement(
    $filter: ModelSubscriptionDepartementFilterInput
  ) {
    onUpdateDepartement(filter: $filter) {
      id
      name
      managerID
      manager {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      employees {
        items {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDepartement = /* GraphQL */ `
  subscription OnDeleteDepartement(
    $filter: ModelSubscriptionDepartementFilterInput
  ) {
    onDeleteDepartement(filter: $filter) {
      id
      name
      managerID
      manager {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      employees {
        items {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmployee = /* GraphQL */ `
  subscription OnCreateEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onCreateEmployee(filter: $filter) {
      id
      employeeDepartementId
      name
      age
      departement {
        id
        name
        managerID
        manager {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      projects {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmployee = /* GraphQL */ `
  subscription OnUpdateEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onUpdateEmployee(filter: $filter) {
      id
      employeeDepartementId
      name
      age
      departement {
        id
        name
        managerID
        manager {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      projects {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmployee = /* GraphQL */ `
  subscription OnDeleteEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onDeleteEmployee(filter: $filter) {
      id
      employeeDepartementId
      name
      age
      departement {
        id
        name
        managerID
        manager {
          id
          employeeDepartementId
          name
          age
          createdAt
          updatedAt
        }
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      projects {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmployeeProjects = /* GraphQL */ `
  subscription OnCreateEmployeeProjects(
    $filter: ModelSubscriptionEmployeeProjectsFilterInput
  ) {
    onCreateEmployeeProjects(filter: $filter) {
      id
      projectEmployeeId
      employeeProjectId
      employee {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmployeeProjects = /* GraphQL */ `
  subscription OnUpdateEmployeeProjects(
    $filter: ModelSubscriptionEmployeeProjectsFilterInput
  ) {
    onUpdateEmployeeProjects(filter: $filter) {
      id
      projectEmployeeId
      employeeProjectId
      employee {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmployeeProjects = /* GraphQL */ `
  subscription OnDeleteEmployeeProjects(
    $filter: ModelSubscriptionEmployeeProjectsFilterInput
  ) {
    onDeleteEmployeeProjects(filter: $filter) {
      id
      projectEmployeeId
      employeeProjectId
      employee {
        id
        employeeDepartementId
        name
        age
        departement {
          id
          name
          managerID
          createdAt
          updatedAt
        }
        projects {
          nextToken
        }
        createdAt
        updatedAt
      }
      project {
        id
        name
        employees {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($filter: ModelSubscriptionProjectFilterInput) {
    onCreateProject(filter: $filter) {
      id
      name
      employees {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($filter: ModelSubscriptionProjectFilterInput) {
    onUpdateProject(filter: $filter) {
      id
      name
      employees {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($filter: ModelSubscriptionProjectFilterInput) {
    onDeleteProject(filter: $filter) {
      id
      name
      employees {
        items {
          id
          projectEmployeeId
          employeeProjectId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSchool = /* GraphQL */ `
  subscription OnCreateSchool(
    $filter: ModelSubscriptionSchoolFilterInput
    $owner: String
  ) {
    onCreateSchool(filter: $filter, owner: $owner) {
      id
      description
      domain
      emailsupport
      lang
      message
      name
      url
      welcomeMessage
      image
      courses {
        items {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      consultations {
        items {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSchool = /* GraphQL */ `
  subscription OnUpdateSchool(
    $filter: ModelSubscriptionSchoolFilterInput
    $owner: String
  ) {
    onUpdateSchool(filter: $filter, owner: $owner) {
      id
      description
      domain
      emailsupport
      lang
      message
      name
      url
      welcomeMessage
      image
      courses {
        items {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      consultations {
        items {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSchool = /* GraphQL */ `
  subscription OnDeleteSchool(
    $filter: ModelSubscriptionSchoolFilterInput
    $owner: String
  ) {
    onDeleteSchool(filter: $filter, owner: $owner) {
      id
      description
      domain
      emailsupport
      lang
      message
      name
      url
      welcomeMessage
      image
      courses {
        items {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      consultations {
        items {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse(
    $filter: ModelSubscriptionCourseFilterInput
    $owner: String
  ) {
    onCreateCourse(filter: $filter, owner: $owner) {
      description
      id
      image
      name
      published
      urlProduct
      courseSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse(
    $filter: ModelSubscriptionCourseFilterInput
    $owner: String
  ) {
    onUpdateCourse(filter: $filter, owner: $owner) {
      description
      id
      image
      name
      published
      urlProduct
      courseSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse(
    $filter: ModelSubscriptionCourseFilterInput
    $owner: String
  ) {
    onDeleteCourse(filter: $filter, owner: $owner) {
      description
      id
      image
      name
      published
      urlProduct
      courseSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      workspaces {
        items {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWorkspace = /* GraphQL */ `
  subscription OnCreateWorkspace(
    $filter: ModelSubscriptionWorkspaceFilterInput
    $owner: String
  ) {
    onCreateWorkspace(filter: $filter, owner: $owner) {
      id
      name
      description
      image
      published
      open
      courseWorkspacesId
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaceSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkspace = /* GraphQL */ `
  subscription OnUpdateWorkspace(
    $filter: ModelSubscriptionWorkspaceFilterInput
    $owner: String
  ) {
    onUpdateWorkspace(filter: $filter, owner: $owner) {
      id
      name
      description
      image
      published
      open
      courseWorkspacesId
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaceSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWorkspace = /* GraphQL */ `
  subscription OnDeleteWorkspace(
    $filter: ModelSubscriptionWorkspaceFilterInput
    $owner: String
  ) {
    onDeleteWorkspace(filter: $filter, owner: $owner) {
      id
      name
      description
      image
      published
      open
      courseWorkspacesId
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      workspaceSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTopic = /* GraphQL */ `
  subscription OnCreateTopic(
    $filter: ModelSubscriptionTopicFilterInput
    $owner: String
  ) {
    onCreateTopic(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      workspaceTopicsId
      workspace {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      userTopicsId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      topicSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTopic = /* GraphQL */ `
  subscription OnUpdateTopic(
    $filter: ModelSubscriptionTopicFilterInput
    $owner: String
  ) {
    onUpdateTopic(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      workspaceTopicsId
      workspace {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      userTopicsId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      topicSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTopic = /* GraphQL */ `
  subscription OnDeleteTopic(
    $filter: ModelSubscriptionTopicFilterInput
    $owner: String
  ) {
    onDeleteTopic(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      workspaceTopicsId
      workspace {
        id
        name
        description
        image
        published
        open
        courseWorkspacesId
        course {
          description
          id
          image
          name
          published
          urlProduct
          courseSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        topics {
          nextToken
        }
        workspaceSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      userTopicsId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      topicSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $owner: String
  ) {
    onCreateMessage(filter: $filter, owner: $owner) {
      id
      message
      description
      published
      topicMessagesId
      topic {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $owner: String
  ) {
    onUpdateMessage(filter: $filter, owner: $owner) {
      id
      message
      description
      published
      topicMessagesId
      topic {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage(
    $filter: ModelSubscriptionMessageFilterInput
    $owner: String
  ) {
    onDeleteMessage(filter: $filter, owner: $owner) {
      id
      message
      description
      published
      topicMessagesId
      topic {
        id
        name
        description
        published
        workspaceTopicsId
        workspace {
          id
          name
          description
          image
          published
          open
          courseWorkspacesId
          workspaceSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        userTopicsId
        user {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        topicSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      settings
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      settings
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      cognitoId
      id
      userSchoolId
      topics {
        items {
          id
          name
          description
          published
          workspaceTopicsId
          userTopicsId
          topicSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          message
          description
          published
          topicMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      answers {
        items {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      courses {
        items {
          id
          courseID
          userID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      chatRooms {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      chatMessages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      address
      firstname
      lastname
      username
      birthday
      company
      contact
      email
      image
      website
      instagramId
      facebookId
      linkedInId
      twitterId
      youtubeId
      googleId
      githubId
      isFrequent
      isStarred
      label
      notes
      registered
      website2
      status
      owner
      editors
      groupsCanAccess
      roles
      settings
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFAQCategory = /* GraphQL */ `
  subscription OnCreateFAQCategory(
    $filter: ModelSubscriptionFAQCategoryFilterInput
    $owner: String
  ) {
    onCreateFAQCategory(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFAQCategory = /* GraphQL */ `
  subscription OnUpdateFAQCategory(
    $filter: ModelSubscriptionFAQCategoryFilterInput
    $owner: String
  ) {
    onUpdateFAQCategory(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFAQCategory = /* GraphQL */ `
  subscription OnDeleteFAQCategory(
    $filter: ModelSubscriptionFAQCategoryFilterInput
    $owner: String
  ) {
    onDeleteFAQCategory(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFAQTag = /* GraphQL */ `
  subscription OnCreateFAQTag(
    $filter: ModelSubscriptionFAQTagFilterInput
    $owner: String
  ) {
    onCreateFAQTag(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      questions {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFAQTag = /* GraphQL */ `
  subscription OnUpdateFAQTag(
    $filter: ModelSubscriptionFAQTagFilterInput
    $owner: String
  ) {
    onUpdateFAQTag(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      questions {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFAQTag = /* GraphQL */ `
  subscription OnDeleteFAQTag(
    $filter: ModelSubscriptionFAQTagFilterInput
    $owner: String
  ) {
    onDeleteFAQTag(filter: $filter, owner: $owner) {
      id
      name
      description
      published
      questions {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion(
    $filter: ModelSubscriptionQuestionFilterInput
    $owner: String
  ) {
    onCreateQuestion(filter: $filter, owner: $owner) {
      id
      questionAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      answerAuthorId
      answerAuthor {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      rewording
      description
      questionVideoUrl
      thumb
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      category {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      file
      answer {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      answerUrl
      answerContent
      answerVideoId
      answerVideoProvider
      status
      questionsSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      fAQConsultationQuestionsId
      faqConsultation {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userQuestionsId
      questionCategoryId
      questionAnswerId
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion(
    $filter: ModelSubscriptionQuestionFilterInput
    $owner: String
  ) {
    onUpdateQuestion(filter: $filter, owner: $owner) {
      id
      questionAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      answerAuthorId
      answerAuthor {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      rewording
      description
      questionVideoUrl
      thumb
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      category {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      file
      answer {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      answerUrl
      answerContent
      answerVideoId
      answerVideoProvider
      status
      questionsSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      fAQConsultationQuestionsId
      faqConsultation {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userQuestionsId
      questionCategoryId
      questionAnswerId
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion(
    $filter: ModelSubscriptionQuestionFilterInput
    $owner: String
  ) {
    onDeleteQuestion(filter: $filter, owner: $owner) {
      id
      questionAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      answerAuthorId
      answerAuthor {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      rewording
      description
      questionVideoUrl
      thumb
      comments {
        items {
          id
          description
          published
          commentQuestionId
          commentAuthorId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userCommentsId
          questionCommentsId
        }
        nextToken
      }
      category {
        id
        name
        description
        published
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      tags {
        items {
          id
          fAQTagID
          questionID
          createdAt
          updatedAt
          owner
          editors
        }
        nextToken
      }
      file
      answer {
        id
        userAnswersId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        description
        urlVideo
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      answerUrl
      answerContent
      answerVideoId
      answerVideoProvider
      status
      questionsSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      fAQConsultationQuestionsId
      faqConsultation {
        id
        title
        description
        status
        questions {
          nextToken
        }
        consultationSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userQuestionsId
      questionCategoryId
      questionAnswerId
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onCreateComment(filter: $filter, owner: $owner) {
      id
      description
      published
      commentQuestionId
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      commentAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userCommentsId
      questionCommentsId
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onUpdateComment(filter: $filter, owner: $owner) {
      id
      description
      published
      commentQuestionId
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      commentAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userCommentsId
      questionCommentsId
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment(
    $filter: ModelSubscriptionCommentFilterInput
    $owner: String
  ) {
    onDeleteComment(filter: $filter, owner: $owner) {
      id
      description
      published
      commentQuestionId
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      commentAuthorId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userCommentsId
      questionCommentsId
    }
  }
`;
export const onCreateAnswer = /* GraphQL */ `
  subscription OnCreateAnswer(
    $filter: ModelSubscriptionAnswerFilterInput
    $owner: String
  ) {
    onCreateAnswer(filter: $filter, owner: $owner) {
      id
      userAnswersId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      description
      urlVideo
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnswer = /* GraphQL */ `
  subscription OnUpdateAnswer(
    $filter: ModelSubscriptionAnswerFilterInput
    $owner: String
  ) {
    onUpdateAnswer(filter: $filter, owner: $owner) {
      id
      userAnswersId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      description
      urlVideo
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnswer = /* GraphQL */ `
  subscription OnDeleteAnswer(
    $filter: ModelSubscriptionAnswerFilterInput
    $owner: String
  ) {
    onDeleteAnswer(filter: $filter, owner: $owner) {
      id
      userAnswersId
      author {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      title
      description
      urlVideo
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFAQConsultation = /* GraphQL */ `
  subscription OnCreateFAQConsultation(
    $filter: ModelSubscriptionFAQConsultationFilterInput
    $owner: String
  ) {
    onCreateFAQConsultation(filter: $filter, owner: $owner) {
      id
      title
      description
      status
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      consultationSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFAQConsultation = /* GraphQL */ `
  subscription OnUpdateFAQConsultation(
    $filter: ModelSubscriptionFAQConsultationFilterInput
    $owner: String
  ) {
    onUpdateFAQConsultation(filter: $filter, owner: $owner) {
      id
      title
      description
      status
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      consultationSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFAQConsultation = /* GraphQL */ `
  subscription OnDeleteFAQConsultation(
    $filter: ModelSubscriptionFAQConsultationFilterInput
    $owner: String
  ) {
    onDeleteFAQConsultation(filter: $filter, owner: $owner) {
      id
      title
      description
      status
      questions {
        items {
          id
          questionAuthorId
          answerAuthorId
          title
          rewording
          description
          questionVideoUrl
          thumb
          file
          answerUrl
          answerContent
          answerVideoId
          answerVideoProvider
          status
          questionsSchoolId
          fAQConsultationQuestionsId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userQuestionsId
          questionCategoryId
          questionAnswerId
        }
        nextToken
      }
      consultationSchoolId
      school {
        id
        description
        domain
        emailsupport
        lang
        message
        name
        url
        welcomeMessage
        image
        courses {
          nextToken
        }
        workspaces {
          nextToken
        }
        topics {
          nextToken
        }
        consultations {
          nextToken
        }
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $owner: String
  ) {
    onCreateChatRoom(filter: $filter, owner: $owner) {
      id
      name
      description
      messages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      contributors {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $owner: String
  ) {
    onUpdateChatRoom(filter: $filter, owner: $owner) {
      id
      name
      description
      messages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      contributors {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom(
    $filter: ModelSubscriptionChatRoomFilterInput
    $owner: String
  ) {
    onDeleteChatRoom(filter: $filter, owner: $owner) {
      id
      name
      description
      messages {
        items {
          id
          message
          description
          published
          chatRoomMessagesId
          userMessagesId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatMessagesId
        }
        nextToken
      }
      contributors {
        items {
          id
          userId
          chatRoomId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
          userChatRoomsId
          chatRoomContributorsId
        }
        nextToken
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserChatRoom = /* GraphQL */ `
  subscription OnCreateUserChatRoom(
    $filter: ModelSubscriptionUserChatRoomFilterInput
    $owner: String
  ) {
    onCreateUserChatRoom(filter: $filter, owner: $owner) {
      id
      userId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      chatRoomId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatRoomsId
      chatRoomContributorsId
    }
  }
`;
export const onUpdateUserChatRoom = /* GraphQL */ `
  subscription OnUpdateUserChatRoom(
    $filter: ModelSubscriptionUserChatRoomFilterInput
    $owner: String
  ) {
    onUpdateUserChatRoom(filter: $filter, owner: $owner) {
      id
      userId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      chatRoomId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatRoomsId
      chatRoomContributorsId
    }
  }
`;
export const onDeleteUserChatRoom = /* GraphQL */ `
  subscription OnDeleteUserChatRoom(
    $filter: ModelSubscriptionUserChatRoomFilterInput
    $owner: String
  ) {
    onDeleteUserChatRoom(filter: $filter, owner: $owner) {
      id
      userId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      chatRoomId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatRoomsId
      chatRoomContributorsId
    }
  }
`;
export const onCreateChatMessage = /* GraphQL */ `
  subscription OnCreateChatMessage(
    $filter: ModelSubscriptionChatMessageFilterInput
    $owner: String
  ) {
    onCreateChatMessage(filter: $filter, owner: $owner) {
      id
      message
      description
      published
      chatRoomMessagesId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatMessagesId
    }
  }
`;
export const onUpdateChatMessage = /* GraphQL */ `
  subscription OnUpdateChatMessage(
    $filter: ModelSubscriptionChatMessageFilterInput
    $owner: String
  ) {
    onUpdateChatMessage(filter: $filter, owner: $owner) {
      id
      message
      description
      published
      chatRoomMessagesId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatMessagesId
    }
  }
`;
export const onDeleteChatMessage = /* GraphQL */ `
  subscription OnDeleteChatMessage(
    $filter: ModelSubscriptionChatMessageFilterInput
    $owner: String
  ) {
    onDeleteChatMessage(filter: $filter, owner: $owner) {
      id
      message
      description
      published
      chatRoomMessagesId
      chatRoom {
        id
        name
        description
        messages {
          nextToken
        }
        contributors {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      userMessagesId
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      owner
      editors
      groupsCanAccess
      createdAt
      updatedAt
      userChatMessagesId
    }
  }
`;
export const onCreateUserCourses = /* GraphQL */ `
  subscription OnCreateUserCourses(
    $filter: ModelSubscriptionUserCoursesFilterInput
    $owner: String
    $editors: String
  ) {
    onCreateUserCourses(filter: $filter, owner: $owner, editors: $editors) {
      id
      courseID
      userID
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const onUpdateUserCourses = /* GraphQL */ `
  subscription OnUpdateUserCourses(
    $filter: ModelSubscriptionUserCoursesFilterInput
    $owner: String
    $editors: String
  ) {
    onUpdateUserCourses(filter: $filter, owner: $owner, editors: $editors) {
      id
      courseID
      userID
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const onDeleteUserCourses = /* GraphQL */ `
  subscription OnDeleteUserCourses(
    $filter: ModelSubscriptionUserCoursesFilterInput
    $owner: String
    $editors: String
  ) {
    onDeleteUserCourses(filter: $filter, owner: $owner, editors: $editors) {
      id
      courseID
      userID
      course {
        description
        id
        image
        name
        published
        urlProduct
        courseSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        workspaces {
          nextToken
        }
        users {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      user {
        cognitoId
        id
        userSchoolId
        topics {
          nextToken
        }
        messages {
          nextToken
        }
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        comments {
          nextToken
        }
        courses {
          nextToken
        }
        chatRooms {
          nextToken
        }
        chatMessages {
          nextToken
        }
        address
        firstname
        lastname
        username
        birthday
        company
        contact
        email
        image
        website
        instagramId
        facebookId
        linkedInId
        twitterId
        youtubeId
        googleId
        githubId
        isFrequent
        isStarred
        label
        notes
        registered
        website2
        status
        owner
        editors
        groupsCanAccess
        roles
        settings
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const onCreateQuestionTags = /* GraphQL */ `
  subscription OnCreateQuestionTags(
    $filter: ModelSubscriptionQuestionTagsFilterInput
    $owner: String
  ) {
    onCreateQuestionTags(filter: $filter, owner: $owner) {
      id
      fAQTagID
      questionID
      fAQTag {
        id
        name
        description
        published
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const onUpdateQuestionTags = /* GraphQL */ `
  subscription OnUpdateQuestionTags(
    $filter: ModelSubscriptionQuestionTagsFilterInput
    $owner: String
  ) {
    onUpdateQuestionTags(filter: $filter, owner: $owner) {
      id
      fAQTagID
      questionID
      fAQTag {
        id
        name
        description
        published
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
export const onDeleteQuestionTags = /* GraphQL */ `
  subscription OnDeleteQuestionTags(
    $filter: ModelSubscriptionQuestionTagsFilterInput
    $owner: String
  ) {
    onDeleteQuestionTags(filter: $filter, owner: $owner) {
      id
      fAQTagID
      questionID
      fAQTag {
        id
        name
        description
        published
        questions {
          nextToken
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
      }
      question {
        id
        questionAuthorId
        author {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        answerAuthorId
        answerAuthor {
          cognitoId
          id
          userSchoolId
          address
          firstname
          lastname
          username
          birthday
          company
          contact
          email
          image
          website
          instagramId
          facebookId
          linkedInId
          twitterId
          youtubeId
          googleId
          githubId
          isFrequent
          isStarred
          label
          notes
          registered
          website2
          status
          owner
          editors
          groupsCanAccess
          roles
          settings
          createdAt
          updatedAt
        }
        title
        rewording
        description
        questionVideoUrl
        thumb
        comments {
          nextToken
        }
        category {
          id
          name
          description
          published
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        tags {
          nextToken
        }
        file
        answer {
          id
          userAnswersId
          title
          description
          urlVideo
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        answerUrl
        answerContent
        answerVideoId
        answerVideoProvider
        status
        questionsSchoolId
        school {
          id
          description
          domain
          emailsupport
          lang
          message
          name
          url
          welcomeMessage
          image
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        fAQConsultationQuestionsId
        faqConsultation {
          id
          title
          description
          status
          consultationSchoolId
          owner
          editors
          groupsCanAccess
          createdAt
          updatedAt
        }
        owner
        editors
        groupsCanAccess
        createdAt
        updatedAt
        userQuestionsId
        questionCategoryId
        questionAnswerId
      }
      createdAt
      updatedAt
      owner
      editors
    }
  }
`;
