import {useCoursesBySchoolId} from '../../commons/api/hooks/course'
import {Select, SelectionProps} from '../ui'

export const SelectCourses = ({...rest}: SelectionProps) => {
  const {courses} = useCoursesBySchoolId()

  return (
    <Select maxW="368px" {...rest}>
      <option value="">Selectionner une formation</option>
      {courses?.items?.map((option, idx) => (
        <option key={idx} value={option?.id}>
          {option?.name}
        </option>
      ))}
    </Select>
  )
}

export default SelectCourses
