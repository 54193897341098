import {Center, Modal, ModalContent, ModalOverlay} from '@chakra-ui/react'
import {PropsWithChildren} from 'react'
import {Avatar, Button, Flex, Grid, Heading, Stack, Text} from '../ui'
import {create} from 'react-modal-promise'
import {User} from '../../commons/types/API'

type ModalYesNoProps = PropsWithChildren<{
  title: string //titre a droite
  header: string //
  isOpen: boolean
  labelOfType: string
  data?: {
    name: string
    image: string
    author: User
    user: User
  }
  onResolve: () => void
  onReject: () => void
  confirmLabel: string
  infoMessage?: string
}>
/**
 * S'utilise avec  <ModalContainer /> sinon erreur
 * @param param0
 * @returns
 */
const ModalYesNo = ({
  children,
  isOpen,
  onResolve,
  onReject,
  title = 'modal',
  header,
  confirmLabel = 'OK',
  labelOfType = '',
  data,
  infoMessage = '',
}: ModalYesNoProps) => {
  const heading = header || data?.name
  const image = data?.image || data?.author?.image || data?.user?.image

  return (
    <Modal isOpen={isOpen} onClose={() => onReject(/*throw any error*/)}>
      <ModalOverlay />
      <ModalContent>
        <Grid autoFlow="column" gap={4} p={4} w="full">
          {/* <Stack minW={{base: '240px', sm: '368px'}} spacing={2}> */}
          <Stack spacing={4} w="full">
            {/* Attention children ne peut pas etre dans un Text */}
            <Text variant="popup">{infoMessage}</Text>

            <Center my={4}>
              <Heading variant="title">{heading ?? ''}</Heading>
            </Center>
            <Center>
              {image ? <Avatar variant={labelOfType} src={image} /> : <></>}
            </Center>
            <Center>{children}</Center>
          </Stack>

          {/* </Stack> */}
          <Heading variant="rotate">{title}</Heading>
        </Grid>
        <Flex mb={4} w="full" justifyContent={'flex-end'}>
          <Button
            variant="outline"
            mr={2}
            _focus={{outline: 'none'}}
            onClick={() => onReject(/*throw any error*/)}
          >
            Annuler
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => onResolve(/*pass any value*/)}
          >
            {confirmLabel}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

export const modalYesNoPromise = create<any>(ModalYesNo)
