import {useMutation, useQuery} from 'react-query'
import {
  useGlobalContext,
  useGlobalLoading,
} from '../../../context/GlobalContext'
import {queryMutationParams} from '../../../types/hooks'
import {queryClient} from '../../config/react-query'
import {usersBySchoolCustomQueryVariables} from '../../types/API'
import {
  getUserByUserId,
  getUsersBySchool,
  updateUser,
  updateUserStatus,
} from '../clients'

export const useUserById = (userId: string) => {
  const {isLoading, data} = useQuery(['getUserById', userId], () =>
    getUserByUserId(userId),
  )
  useGlobalLoading(isLoading)
  return {user: data}
}

export const useUsersBySchoolId = (
  input: usersBySchoolCustomQueryVariables,
) => {
  const {isLoading, data} = useQuery(
    [
      'getUsersBySchool',
      input.userSchoolId,
      input?.limit,
      input?.nextToken,
      input?.filter,
    ],
    () => getUsersBySchool(input),
  )
  useGlobalLoading(isLoading)
  return {users: data, isLoading}
}

export const useUpdateUser = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateUser, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getUsersBySchool')
      fetchEnd()
      setMessage('Votre profil a été mis à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation useUpdateMe', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useUpdateUserStatus = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  const {
    setError: setGlobalError,
    setMessage,
    fetchStart,
    fetchEnd,
  } = useGlobalContext()

  const mutation = useMutation(updateUserStatus, {
    onMutate: variables => {
      fetchStart()
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('getUsersBySchool')
      fetchEnd()
      setMessage('Votre profil a été mis à jour')
      onSuccess()
    },
    onError: (error: any) => {
      console.error('erreur lors de la mutation useUpdateMe', error.errors[0])
      setGlobalError('Erreur lors de la mise à jour')
      onError()
    },
  })
  return mutation
}

export const useCreateUser = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  // const {setError: setGlobalError, setMessage} = useGlobalContext()

  const mutation = ''
  return mutation
}

export const useDeleteUser = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  // const {setError: setGlobalError, setMessage} = useGlobalContext()
  //throw new Error('Not implemented')
  const mutation = ''
  return mutation
}

export const useChangerUserStatus = ({
  onSuccess = () => {},
  onError = () => {},
}: queryMutationParams = {}) => {
  // const {setError: setGlobalError, setMessage} = useGlobalContext()
  //throw new Error('Not implemented')
  const mutation = ''
  return mutation
}
