import React from 'react'
import {FormControl, FormLabel, FormHelperText} from './Input'
import {InputProps, FormControlProps} from '@chakra-ui/react'
import {SingleDatepicker, SingleDatepickerProps} from 'chakra-dayzed-datepicker'
type SelectionProps = InputProps &
  FormControlProps &
  SingleDatepickerProps & {
    text?: string
    errorMessage?: string
  }

export const DatePickerChakra = ({...props}: SelectionProps) => {
  const {errorMessage, ...rest} = props
  return (
    <FormControl id={props?.id}>
      <FormLabel>{props?.label}</FormLabel>
      <SingleDatepicker
        {...rest}
        configs={{
          dateFormat: 'dd/MM/yyyy',
          monthNames: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Aôut',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
          ],
          dayNames: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        }}
      />
      <FormHelperText>{props?.text}</FormHelperText>
      <FormHelperText color="red.500">{props?.errorMessage}</FormHelperText>
    </FormControl>
    // autre version de chakra datepicker
    // <DatePicker initialValue={new Date()} onDateChange={handleDateChange} />
  )
}
export default DatePickerChakra
