// CONSTANTES SPÉCIFIQUES PROJET // not used
export const test = 'tests'
export const multiSchool = false
export const SCHOOL_ID_MIKECODEUR_DEV = 'a4cbbc25-fc9b-486f-8b24-d4c67ec893e6'
export const SCHOOL_ID_MIKECODEUR_PREPROD =
  '19843bf6-9cf6-4303-827f-3a199b50b298'
export const SCHOOL_ID_MIKECODEUR_PROD = '34b96b37-304a-4c75-ab82-416ee5aed38e'
export const MIKE_ENV_SCHOOL_ID = [
  SCHOOL_ID_MIKECODEUR_DEV,
  SCHOOL_ID_MIKECODEUR_PREPROD,
  SCHOOL_ID_MIKECODEUR_PROD,
]
export const COURSES_PROD_DEFAULT =
  '32320fdf-76e8-4d8f-8c2f-1dea132a5108|3b6776f1-d999-4a5a-a503-c98a79c96f41|c5483014-22c1-44e8-9805-30bee3d0764e'

// Link
export const LINK_LOGO = '/images/lateam-logo.png'
export const LINK_IMG_500_LIGHT = '/images/error-500-light.png'
export const LINK_IMG_500_DARK = '/images/error-500-dark.png'
export const LINK_IMG_TAG = '/images/tag.png'
export const LINK_IMG_CATEGORIE = '/images/categories.png'
export const LINK_IMG_DEFAULT_COURSE =
  '/images/default-formation-background-image.jpg'
//ROUTES
export const ROUTE_BASE = '/'
//STUDENT
export const ROUTE_ACCOUNT = `${ROUTE_BASE}account`
export const ROUTE_SCHOOL = `${ROUTE_BASE}school`
export const ROUTE_MESSAGERIE = `${ROUTE_SCHOOL}/messagerie`
export const ROUTE_SCHOOL_FAQ = `${ROUTE_SCHOOL}/faq`
export const ROUTE_SCHOOL_USER = `${ROUTE_SCHOOL}/user`
export const ROUTE_SCHOOL_TOPIC = `${ROUTE_SCHOOL}/topic`
export const ROUTE_SCHOOL_TOPIC_EDITOR = `${ROUTE_SCHOOL_TOPIC}/topic-editor`
export const ROUTE_SCHOOL_COURSE = `${ROUTE_SCHOOL}/courses`
export const ROUTE_SCHOOL_DASHBOARD = `${ROUTE_SCHOOL}/dashboard`
export const ROUTE_SCHOOL_FAQ_QUESTION = `${ROUTE_SCHOOL_FAQ}/question`
export const ROUTE_MESSAGERIE_CONVERSATIONS = `${ROUTE_MESSAGERIE}/conversations`
export const ROUTE_SCHOOL_CONSULTATIONS = `${ROUTE_SCHOOL_FAQ}/consultations`
export const ROUTE_USER_FAQ_MYQUESTION = `${ROUTE_SCHOOL_FAQ}/my-questions`
export const ROUTE_SCHOOL_FAQ_QUESTION_EDITOR = `${ROUTE_SCHOOL_FAQ}/question-editor`

//ADMIN
export const ROUTE_SCHOOL_ADMIN = `${ROUTE_BASE}school-admin`
export const ROUTE_ADMIN_FAQ = `${ROUTE_SCHOOL_ADMIN}/faq`
export const ROUTE_ADMIN_MEMBERS = `${ROUTE_SCHOOL_ADMIN}/students`
export const ROUTE_ADMIN_WS = `${ROUTE_SCHOOL_ADMIN}/workspaces`
export const ROUTE_ADMIN_COURSES = `${ROUTE_SCHOOL_ADMIN}/courses`
export const ROUTE_ADMIN_SCHOOL_SETTINGS = `${ROUTE_SCHOOL_ADMIN}/settings`
export const ROUTE_ADMIN_DASHBOARDS = `${ROUTE_SCHOOL_ADMIN}/dashboard`
export const ROUTE_ADMIN_FAQ_QUESTIONS = `${ROUTE_ADMIN_FAQ}/questions`
export const ROUTE_ADMIN_FAQ_CATEGORIES = `${ROUTE_ADMIN_FAQ}/categories`
export const ROUTE_ADMIN_FAQ_TAGS = `${ROUTE_ADMIN_FAQ}/tags`
export const ROUTE_ADMIN_FAQ_LISTCATEGORIES = `${ROUTE_ADMIN_FAQ}/list-categories`
export const ROUTE_ADMIN_FAQ_CONSULTATIONS = `${ROUTE_ADMIN_FAQ}/consultations`
export const ROUTE_ADMIN_FAQ_QUESTION_EDITOR = `${ROUTE_ADMIN_FAQ}/question-editor`
export const ROUTE_ADMIN_FAQ_QUESTION_PREVIEW = `${ROUTE_ADMIN_FAQ}/question-preview`
export const ROUTE_ADMIN_FAQ_CREATE_CONSULTATION = `${ROUTE_ADMIN_FAQ}/create-consultation`
