import {Route, Routes} from 'react-router-dom'
import {ConsultationCreatePage} from './ConsultationCreatePage'
import {Error404} from './../../common/Error404'

export const ConsultationStepRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ConsultationCreatePage />} />
      <Route path="/:step" element={<ConsultationCreatePage />} />
      <Route
        path="/:step/:consultationId"
        element={<ConsultationCreatePage />}
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}
