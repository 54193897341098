import {primaryColor} from '../app-constantes'

type Dict = Record<string, any>

const Link = {
  baseStyle: (props: Dict) => ({
    _hover: {
      textDecoration: 'none',
    },
  }),
  variants: {
    linkFooter: (props: any) => ({
      color: 'gray.500',
      _hover: {
        color: primaryColor(props.colorMode),
        fontWeight: 'regular',
      },
    }),
  },
}
//  créer mais pas vraiment testé
export default Link
