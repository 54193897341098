import React from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
} from '@chakra-ui/react'
import {Link as DomRouterLink} from 'react-router-dom'

export const Breadcrumb = ({...props}: BreadcrumbProps) => {
  return <ChakraBreadcrumb {...props}></ChakraBreadcrumb>
}
export type ItemBreadCrumb = {
  label: string
  to: string
}
type BreakcrumbListProps = {
  bcItems?: ItemBreadCrumb[]
  separator?: string
}
export const BreakcrumbList = ({
  bcItems,
  separator = '->',
}: BreakcrumbListProps) => {
  const isLastItem = (
    bcItems: ItemBreadCrumb[],
    item: ItemBreadCrumb,
  ): boolean => {
    return bcItems[bcItems.length - 1] === item
  }

  return (
    <Breadcrumb separator={separator}>
      {bcItems?.map((item: ItemBreadCrumb, idx) => {
        return (
          <BreadcrumbItem
            key={idx}
            minW={{
              sm: isLastItem(bcItems, item) ? '' : 'fit-content',
            }}
            sx={{
              span: {
                w: 10,
                textAlign: 'center',
                m: 0,
              },
            }}
          >
            <BreadcrumbLink
              to={item?.to ?? ''}
              as={DomRouterLink}
              noOfLines={1}
            >
              {item?.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}
export default Breadcrumb
