import {
  CreateCommentInput,
  Question,
  UpdateCommentInput,
} from '../../commons/types/API'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAuthDetails} from '../../context/AuthContext'
import {Box, Button, Flex, FormTextArea} from '../ui'
import {AlertHiddenFields} from './AlertHiddenFields'

type CommentFormProps = {
  isEdit?: boolean
  initialValues?: UpdateCommentInput & CreateCommentInput
  question?: Question
  onSubmit?: (formValue: UpdateCommentInput & CreateCommentInput) => void
  isLoading?: boolean
  resetForm?: boolean
  onClose?: () => void
}

export const CommentForm = ({
  initialValues,
  question,
  onSubmit,
  isLoading,
  onClose,
  isEdit,
  resetForm,
}: CommentFormProps) => {
  const {schoolGroupId, uid} = useAuthDetails()

  const SignInSchema = Yup.object().shape({
    description: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(2000, 'Maximum 2000 caractères')
      .required('Champ obligatoire'),
    commentAuthorId: Yup.string()
      .min(2, 'Minimum 2 caractères')
      .max(50, 'Maximum 50 caractères')
      .required('Un auteur est obligatoire'),
    groupsCanAccess: Yup.array()
      .min(1, 'Il manque groupsCanAccess')
      .required('Champ obligatoire'),
    editors: Yup.array()
      .min(1, 'Il manque editor')
      .required('Champ obligatoire'),
    owner: Yup.string().test('owner', 'Il manque owner', value => !!value),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: initialValues?.id ?? '',
      description: initialValues?.description ?? '',
      commentQuestionId: initialValues?.commentQuestionId ?? question?.id ?? '',
      //this pattern or outside ?
      commentAuthorId: initialValues?.commentAuthorId ?? uid ?? '',
      //access
      groupsCanAccess: initialValues?.groupsCanAccess ?? [schoolGroupId],
      owner: initialValues?.owner ?? uid,
      editors: initialValues?.editors ?? [uid as string],
    },
    onSubmit: (values: UpdateCommentInput & CreateCommentInput) => {
      onSubmit?.(values)

      if (resetForm) {
        formik.resetForm()
      }
    },
    validationSchema: SignInSchema,
  })

  return (
    <>
      <Box px={4} pt={4} mb={-2}>
        <FormTextArea
          placeholder="Laissez votre commentaire ici..."
          label="Laisser un commentaire"
          name="description"
          id="description"
          onChange={formik.handleChange}
          value={formik.values?.description as string}
          errorMessage={formik?.errors?.description as string}
          onKeyPress={e => {
            if (e.key === 'Enter' && e.ctrlKey) {
              formik.submitForm()
            }
          }}
        />
        <Flex justifyContent={'flex-end'}>
          <Button
            //type="submit"
            onClick={formik.submitForm}
            mt={2}
            variant={'solid'}
            isLoading={isLoading}
          >
            Valider
          </Button>
        </Flex>
      </Box>
      <AlertHiddenFields
        formik={formik}
        fields={[
          'commentAuthorId',
          'userTopicsId',
          'owner',
          'editors',
          'groupsCanAccess',
        ]}
      />
    </>
  )
}
